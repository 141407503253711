import {
  AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Pipe,
  PipeTransform,
} from '@angular/core';
import {Router} from "@angular/router";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CreativemoduleService} from "./creativemodule.service";
import {CampaignService} from "../../../campaign.service";
import {forkJoin, Subscription} from "rxjs";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {ResponseBodyModel} from "../../../../../models/response-body.model";
import {MatDialog} from "@angular/material/dialog";

declare var $: any;
declare var toastr: any;

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export interface TemplateSubscriptionResult {
  selected: boolean;
  changed: boolean;
  template: string;
}

@Component({
  selector: 'app-creativemodule',
  templateUrl: './creativemodule.component.html'
})


export class CreativemoduleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() readMore: number;

  blockMultipleClick = false; // So that user does not trigger function more than one time

  noPreviewAvailable: boolean = false;

  hideExternalTracker: boolean = true;


  videoExtension: string;
  updatePreviewTimeout;

  height;
  width;

  templateGroup: FormGroup;

  selectedTemplate;


  // Cropper
  data: any;
  dataLogo: any;
  dataHorizontal: any;

  // Ad
  iframe;
  js;
  recommendedSize;

  // Image
  allowed_types = ["jpeg", "tiff", "png", "jpg", "bmp", "gif", "svg", "image/png", "image/jpeg", "image/tiff", "image/jpg", "image/bmp", "image/gif", "image/svg+xml"];
  allowed_types_video = ["mp4", "mov", "webm", "mkv", "vob", "ogv", "ogg", "avi", "qt", "wmv", "rm", "asf", "m4p", "mpg", "mp2", "mpeg", "mpe", "mpv", "m2v", "m4v", "3gp", "3g2", "f4v", "f4p", "f4a", "f4b", "swf"]; /*"flv"*/
  allowed_preview_video_types = ["mp4", "webm", "ogg", "ogv", "mpeg", "mpg", "avi"];

  selectedPreviewSite;
  adPreviews: any[];

  adName: string;

  /**
   * Format must be jpeg for the image quality to take affect, image quality is used for automatically decreasing the size of the image
   * Must use jpeg as format
   */
  pictureA =
    {
      recommended_size: '590x235',
      width: 590,
      height: 235,
      aspect_ratio: 118 / 47,
      format: 'jpeg',
      background_color: '#FFFFFF'
    };

  pictureB =
    {
      recommended_size: '360x270',
      width: 360,
      height: 270,
      aspect_ratio: 4 / 3,
      format: 'jpeg',
      addPadding: false,
      background_color: '#FFFFFF'
    };

  subObjective;
  selectedOperatingSystem;

  faces;
  selectedFace: number = 0;

  // Used for moving preview up, adformat selection forces the preview down.
  amountOfTemplates: number;


  updateCreatives: boolean = false;
  subscriptions: Subscription[] = [];

  bannerParams;

  updateAd: boolean = false;

  dynamic_ad: boolean = false;

  showAdCreation: boolean = false;
  catalogs;

  creatingAd: boolean = false;

  visible: boolean = true;

  feedOption: boolean = false;

  disableFeed: boolean = true;

  recommended_size: string;

  noDiff: boolean = false;

  optimizationOption: string;
  faceIndex: number;
  optimizationOptionControls =
    {
      clickLink: [],
      imageSrc: []
    };


  weatherOptions =
    [
      {
        option: 'sun',
        mapping: ['Weather.01', 'Weather.02', 'Weather.03', 'Weather.07']
      },
      {
        option: 'rain',
        mapping: ['Weather.05', 'Weather.06', 'Weather.09', 'Weather.11', 'Weather.12', 'Weather.23']
      },
      {
        option: 'cloud',
        mapping: ['Weather.04', 'Weather.15']
      },
      {
        option: 'snow',
        mapping: ['Weather.08', 'Weather.13', 'Weather.14']
      },
    ];

  loadCreativesUpload: boolean = false;

  constructor(
    private router: Router,
    public store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    private apiservice: ApiService,
    private _cd: ChangeDetectorRef,
    private _fb: FormBuilder,
    public creativeService: CreativemoduleService,
    public campaignService: CampaignService,
    public dialog: MatDialog
  ) {

    let width = 320;
    let height = 320;

    // Get image height from template module
    const templateParam = this.globalfunctionality.getModuleParams('templates');
    const options = templateParam.options;

    options.forEach(elm => {
      if (elm.size === this.store.selectedSize.size) {
        this.recommendedSize = elm.image_size;
        let size = this.recommendedSize.split("x");
        width = size[0];
        height = size[1];
      }
    });

    this.width = width;
    this.height = height;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(elm => elm.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.iframe = <HTMLIFrameElement>document.getElementById("iframe");
    const creatives = this.globalfunctionality.getModuleValues("creatives");
    this.noPreviewAvailable = creatives.noPreview;
    this.videoExtension = creatives.videoExtension;

    const previewIframe: any = document.getElementById("previewIframe");
    if (this.iframe !== null && !this.noPreviewAvailable) {
      this.iframe.onload = () => {
        if (creatives.isComplete) {
          if (previewIframe !== null) {
            previewIframe.style.display = "block";
          }
          const iframe: any = document.getElementById("iframe");
          if (iframe !== null) {
            iframe.style.display = "block";
          }
          this.updatePreview();
        }
      };
    } else {
      if (previewIframe !== null) {
        previewIframe.style.display = "block";
      }
      this.videoExtension = creatives.videoExtension;
    }
  }

  ngOnInit() {

    this.creativeService.loadComponentSubject.next(true);

    // Modules are modified when selecting a creative set, retrieve the modified modules.
    this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;
    this.amountOfTemplates = this.globalfunctionality.getModule('templates').param.options.length || 1;

    // Check if dynamic ad has been chosen
    this.dynamic_ad = this.globalfunctionality.getModuleValues('adforsize').dynamic_ad;
    if (this.campaignService.structure.get('objective').value.id === 2) {
      const adtype = this.globalfunctionality.getModuleValues('adtype');
      this.dynamic_ad = adtype.selectedType.toUpperCase() === 'RESPONSIVANNONS';
    }

    if (this.campaignService.structure.get('objective').value.id === 6) {
      this.feedOption = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective.id === 3;
      if (this.feedOption) {

        this.apiservice.getJSON(this.store.apiURL + "/ACMCatalogueServlet")
          .subscribe(res => {
            if (res.responseCode === 200) {
              this.showAdCreation = res.data ? res.data.catalogs.length : false;
              this._cd.detectChanges();
              this.initialize();
            }
          });

      } else {
        this.showAdCreation = true;
        this.initialize();
      }
    } else {
      this.showAdCreation = true;
      this.initialize();
    }


  }

  initialize(): void {
    this.creativeService.loadComponentSubject.next(false);
    this.addSubscriptions();
    this.setAdName();
    this.setAdPreview();

    // Special cases for these particular tracks
    this.handleCPCandBlocketSearchword();
  }

  addSubscriptions() {
    this.subscriptions.push(this.globalfunctionality.templateChange
      .subscribe((result: TemplateSubscriptionResult) => {
        if (result !== null) {
          if (result.selected) {
            this.selectedTemplate = result.template;
            this.selectedFace = 0;
            if(this.optimizationOption){
              this.optimizationOption = undefined;
              this.creativeService.resetWeatherOpt.next(true);
              $('#iframe').css('display', 'none');
            }

            if (result.changed) {
              $('#iframe').css('display', 'none');
              const creatives = this.globalfunctionality.getModuleValues('creatives');
              const obj =
                {
                  isComplete: false,
                  id: creatives.id
                };
              this.globalfunctionality.setModuleValue('creatives', obj);
            }

            this.setDefaultFormObj(result.changed);
            this.detectChange();
          }
        }
      }));
  }

  handleCPCandBlocketSearchword() {
    const type = this.store.productType.toUpperCase();
    const objectiveId = this.campaignService.structure.get('objective').value.id;
    if (objectiveId === 6 || type === 'FREETEXTANNONS') {
      if (type !== 'FREETEXTANNONS') {
        this.handleCPCCreativeFields();
      } else if (type === 'FREETEXTANNONS') {
        this.setBlocketDefaultData();
      }
    } else if (this.dynamic_ad) { // If context flow, use prisjakt sizes, else content display size
      this.pictureA.recommended_size = objectiveId === 1 ? '500x462' : '590x235';
      this.pictureA.width = objectiveId === 1 ? 500 : 590;
      this.pictureA.height = objectiveId === 1 ? 462 : 235;
      this.pictureA.aspect_ratio = this.pictureA.width / this.pictureA.height;
      this.pictureB.recommended_size = objectiveId === 1 ? '240x80' : '360x270';
      this.pictureB.width = objectiveId === 1 ? 240 : 360;
      this.pictureB.height = objectiveId === 1 ? 80 : 270;
      this.pictureB.aspect_ratio = this.pictureB.width / this.pictureB.height;
      this.pictureB.addPadding = true;
      this.pictureB.background_color = '#f6f8fa';

      const site = this.globalfunctionality.getModuleValues('context')?.contexts[0]?.context?.toUpperCase();

      this.store.showImageA = true && site !== 'BLOCKET' && site !== 'KLART.SE';
      this.store.showImageB = true;
    }
  }


  setDefaultFormObj(templateChanged: boolean) {
    this.templateGroup = this._fb.group(
      {
        adNameControl: this.creativeService.adNameControl,
        impTracker: this.creativeService.adImpTrackerControl
      }
    );

    if (this.campaignService.structure.get('objective').value.id === 6) {
      const feed_option: boolean = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective.id === 3;
      if (feed_option) {
        const values = this.globalfunctionality.getModuleValues('adforsize');
        const formatIndex = values.selected.findIndex(elm => elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format);
        const set = values['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];

        if (set.acm_data) {

          const optionControl: FormControl = this.creativeService.requiredFormControl;
          optionControl.setValue(set.acm_data.feed_option);
          this.templateGroup.addControl('feedOptionControl', optionControl);

          const selectedControl: FormControl = this.creativeService.requiredFormControl;
          selectedControl.setValue(set.acm_data.feed_selection);
          this.templateGroup.addControl('feedSelectedControl', selectedControl);

          this.creativeService.ACMLibrary = set.acm_data.media_library;
          this.creativeService.ACMRowId = set.acm_data.media_library_row_id;
          this.buildFormGroupFromLibraryColumns(false);
        } else {
          this.templateGroup.addControl('feedOptionControl', this.creativeService.requiredFormControl);
          this.templateGroup.addControl('feedSelectedControl', this.creativeService.requiredFormControl);

          this.apiservice.getJSON(this.store.apiURL + '/ACMLibServlet')
            .subscribe((res: ResponseBodyModel) => {
              if (res.responseCode === 200) {
                this.creativeService.ACMLibrary = res.data['libraries'].find(library => library.name === 'AdMarket - Driva trafik - Annons Data');
                if (this.creativeService.ACMLibrary) {
                  this.buildFormGroupFromLibraryColumns(true);
                }
              }
            });
        }
      } else {
        this.getAdTemplate(templateChanged);
      }
    } else {
      if (this.obtainTemplateName() === 'DISPLAY VIDEO') {
        this.templateGroup.addControl('useImage', this.creativeService.adSelectorControl);
        this.templateGroup.addControl('adParams', this.creativeService.nonRequiredFormControl);
      }
      this.getAdTemplate(templateChanged);
    }
  }

  buildFormGroupFromLibraryColumns(createRow: boolean = false) {
    this.apiservice.getJSON(this.store.apiURL + `/ACMColServlet?acmLibId=${this.creativeService.ACMLibrary.id}`)
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          this.creativeService.ACMColumns = res.data['columns'];
          this.creativeService.ACMColumns.forEach(column => {
            let control: FormControl = this.creativeService.requiredFormControl;
            if (column.type === 'clickLink' && !column.name.includes('statics')) {
              control = this.creativeService.adURLControl;
              control.setValue('https://');
            } else if (column.name.includes('product.background') || column.name.includes('statics')) {
              control = this.creativeService.nonRequiredFormControl;
            }
            this.templateGroup.addControl(column.name, control);
          });

          if (createRow) {
            this.createACMRowForAd();
          } else {
            this.getACMRow();
          }
          this._cd.detectChanges();
        }
      });
  }

  getACMRow() {
    this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${this.creativeService.ACMLibrary.id}&rowId=${this.creativeService.ACMRowId}`)
      .subscribe((res: ResponseBodyModel) => {
        res.data['cells'].forEach(row => {
          this.templateGroup.controls[row.columnName].setValue(row.value);
        });
      });
  }

  /**
   * Creates row in ACM that is used for one particular AD
   */
  createACMRowForAd() {
    const name = `${this.campaignService.structure.get('name').value}_${this.store.selectedSize.size}_${this.store.selectedSize.set.id}`.replace(/\s/g, '');
    this.apiservice.postJSON(this.store.apiURL + "/ACMRowServlet", {
      acmLibId: this.creativeService.ACMLibrary.id,
      name: name,
      cells: []
    }).subscribe((res: ResponseBodyModel) => {
      if (res.responseCode === 201) {
        const rowId = res.data['rowId'];
        this.creativeService.ACMRowId = rowId;
        this.disableFeed = false;
        this._cd.detectChanges();
      }
    });
  }

  /**
   * Gets ad template from BE, depending on face limit, will create x amount of formgroups depending on amount of faces
   */
  getAdTemplate(templateChanged: boolean) {
    this.templateGroup.addControl('facesArray', this._fb.array([]));

    if (this.store.productType.toUpperCase() !== 'VIDEOANNONS') {
      if (this.store.productType.toUpperCase() === 'FREETEXTANNONS') {
        this.selectedTemplate = 'Content Display';
      } else if (this.dynamic_ad) {
        this.selectedTemplate = 'Responsive Content Display';
      }
    } else {
      this.selectedTemplate = 'Video';
    }

    this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(this.selectedTemplate))
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.store.bannerDetail = res.data[0];
          const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
          const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");
          const facesArray = this.templateGroup.get('facesArray') as FormArray;
          const params = JSON.parse(this.store.bannerDetail.params);

          if (params) {
            const bannerData = this.store.bannerDetail.script.substring(start, end);
            this.store.bannerDetail.script = this.store.bannerDetail.script.replace(bannerData, JSON.stringify(params));
            this.bannerParams = JSON.parse(this.store.bannerDetail.params).bannerData.options.bannerBehavior.bannerOption;
            const creatives = this.globalfunctionality.getModuleValues('creatives');


            if (creatives.isComplete && !templateChanged) {
              let renderOptFields = false;
              for (let i = 0; i < creatives.selectedImages.length; i++) {
                renderOptFields = this.addFaceGroup(facesArray, false, i);
              }
              if (creatives.creative_data) {
                this.templateGroup.setValue(creatives.creative_data);
              } else {
                creatives.selectedImages.forEach(elm => {
                  if (elm['hasError'] === undefined) {
                    elm['hasError'] = false;
                  }
                });
                this.templateGroup.get('facesArray').setValue(creatives.selectedImages);
              }
              if(renderOptFields) {
                this.renderOptimizationElements('weather', this.templateGroup.get('facesArray')['controls'][0], 0);
              }

            } else {
              const facesArray = new FormArray([]);
              for (let i = 0; i < this.bannerParams.limit; i++) {
                this.addFaceGroup(facesArray);
              }

              if ((this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 1 || this.campaignService.structure.get('objective').value.id === 7)
                && (this.store.productType.toUpperCase() === 'BANNERANNONS' || this.store.productType.toUpperCase() === 'SPECIALANNONS')) {
                this.previewAdTemplate();
              }
            }

            this.detectChange();

            if (this.iframe !== null) {
              this.updateIframe("update");
            }
          }
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }


  previewAdTemplate() {

    // TODO: remove previewIframe?
    const previewIframe: any = document.getElementById("previewIframe");
    if (previewIframe !== null) {
      previewIframe.style.display = "block";
    }
    const iframe: any = document.getElementById("initialPreviewIframe");
    if (iframe !== null) {
      iframe.style.display = "block";
    }

    // getAdTemplate() already formats the selectedTemplate name
    this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(this.selectedTemplate))
      .subscribe(res => {
        if (res.responseCode === 200) {
          const banner = res.data[0];
          iframe.contentWindow.postMessage({
            html: banner.html,
            style: banner.style,
            script: banner.script,
            action: 'create',
            origin: banner.origin
          }, '*');
        }
      });

  }


  /**
   * Auto suggest ad name
   */
  setAdName() {
    const values = this.globalfunctionality.getModuleValues("creatives");
    const adName = values.adName;
    if (adName) {
      this.adName = adName;
    } else {
      this.adName = this.store.selectedSize.setIndex === 0 ? this.campaignService.structure.get('name').value :
        this.campaignService.structure.get('name').value + ' - ' + this.store.selectedSize.setIndex;
    }
    this.creativeService.adNameControl.setValue(this.adName);
  }

  /**
   * Get default preview site when not context flow
   * If context flow is selected preview site should be === selected site
   */
  setAdPreview() {
    const objective = this.campaignService.structure.get('objective').value;
    if (this.store.productType.toUpperCase() === 'VIDEOANNONS') {
      this.adPreviews = this.store.langJSON.component.objectives[objective.name]['video_previews'].filter(elm => elm.name.toUpperCase() === 'AFTONBLADET');
    } else {
      this.adPreviews = this.store.langJSON.component.objectives[objective.name]['ad_previews'];
    }

    if (objective.id === 1 && this.store.productType.toUpperCase() !== 'FREETEXTANNONS') {
      const site = this.globalfunctionality.getModuleValues('context').contexts[0].context.toUpperCase();
      this.adPreviews = this.adPreviews.filter(elm => elm.name.toUpperCase() === site);
    } else if (objective.id === 2 && this.store.productType.toUpperCase() === 'RESPONSIVANNONS') {
      this.adPreviews = this.adPreviews.filter(elm => elm.name.toUpperCase() !== 'TV.NU' && elm.name.toUpperCase() !== 'OMNI');
    }
    this.selectedPreviewSite = this.adPreviews[0];
  }


  setBlocketDefaultData() {
    this.pictureA.recommended_size = '360x270';
    this.pictureA.width = 360;
    this.pictureA.height = 270;
    this.pictureA.aspect_ratio = 360 / 270;

    this.pictureB.recommended_size = '300x60';
    this.pictureB.width = 300;
    this.pictureB.height = 60;
    this.pictureB.aspect_ratio = 300 / 60;
    this.pictureB.format = 'jpeg';
    this.pictureB.addPadding = false;

    this.store.showImageA = true;
    this.store.showImageB = true;

    this.adPreviews = this.adPreviews.filter(elm => elm.name.toLowerCase() === 'blocket');
  }

  /**
   * Check which fields to be available for input
   */
  handleCPCCreativeFields() {
    // Check which subobjective that has been selected
    this.subObjective = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective;
    if (this.subObjective.id === 2) {
      this.selectedOperatingSystem = this.globalfunctionality.getModuleValues('device').selectedOSTargetOption;
    }

    if (this.subObjective.id === 1) {
      if (this.globalfunctionality.getModuleValues('device')) {
        const selectedDevice = this.globalfunctionality.getModuleValues('device')['selectedTargetOption'];
        const selectedSites = this.globalfunctionality.getModuleValues('device').sites.filter(elm => elm.selected);
        const aux = [];
        this.adPreviews.forEach((preview) => {
          if (selectedSites.map(selected => selected.name.toUpperCase()).indexOf(preview.name.toUpperCase()) !== -1) {
            aux.push(preview);
          }
        });
        this.adPreviews = aux;

        // Check which image that is needed to be uploaded depending on selection
        this.checkMandatoryImage(selectedDevice);
      } else {
        this.store.showImageA = true;
        this.store.showImageB = true;
        this.adPreviews = this.adPreviews.filter(elm => elm.name.toUpperCase() === 'AFTONBLADET');
      }
    } else if (this.subObjective.id === 2) {
      this.store.showImageA = false;
      this.store.showImageB = true;
      this.pictureB.recommended_size = '120x120';
      this.pictureB.width = 120;
      this.pictureB.height = 120;
      this.pictureB.aspect_ratio = 1 / 1;
      this.adPreviews = this.adPreviews.filter(elm => elm.name.toUpperCase() === 'AFTONBLADET');
    }
  }

  checkMandatoryImage(selectedDevice: string) {
    this.store.showImageA = false;
    this.store.showImageB = false;
    const auxSites = this.adPreviews.map(elm => elm.name.toLowerCase());
    if (!selectedDevice) { // All devices
      this.store.showImageA = auxSites.indexOf('aftonbladet') !== -1 || auxSites.indexOf('svd') !== -1;
      this.store.showImageB = true;
    } else if (selectedDevice.toLowerCase() === 'mobiles') {
      this.store.showImageA = auxSites.indexOf('aftonbladet') !== -1;
      this.store.showImageB = true;
    } else {
      this.store.showImageA = auxSites.indexOf('svd') !== -1;
      this.store.showImageB = auxSites.indexOf('aftonbladet') !== -1;
    }
  }

  getIframe(callback) {

    if (!this.noPreviewAvailable) {
      this.iframe = <HTMLIFrameElement>document.getElementById("iframe");
      if (this.iframe !== null) {
        this.iframe.onload = () => {
          setTimeout(() => {
            this.updateIframe("create");
            callback();
          }, 300);

        };
      } else {
        this.updateIframe("create");
      }
    }
  }

  updateIframe(action, optimizationOption?) {
    if (this.campaignService.structure.get('objective').value.id !== 6 && this.store.productType !== 'FreeTextAnnons') {
      this.iframe = <HTMLIFrameElement>document.getElementById("iframe");
      if (this.iframe !== null) {
        if (this.iframe.contentWindow === null) {
          this.getIframe(() => {
            this.postMessageToIframe(action, optimizationOption);
          });
        } else {
          this.postMessageToIframe(action, optimizationOption);
        }
      } else {
        if (this.iframe !== null) {
          this.getIframe(() => {
            this.postMessageToIframe(action, optimizationOption);
          });
        }
      }
    }
  }


  postMessageToIframe(action, optimizationOption?) {
    if (this.iframe !== null) {
      if (this.store.bannerDetail !== undefined) {
        let script = this.store.bannerDetail.script;

        const facesArray = this.templateGroup.controls['facesArray'] as FormArray;
        const face: FormGroup = facesArray.at(this.faceIndex) as FormGroup;

        if (face?.get('optimization_option')?.value === 'weather' || optimizationOption) {
          const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
          const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");
          const bannerData = this.store.bannerDetail.script.substring(start, end);
          const auxBannerData = JSON.parse(bannerData);

          let clickField = 'default_clickLink';
          let imageField = 'default_imageSrc';
          const faces = [...this.templateGroup.get('facesArray').value];

          faces.forEach((face, index) => {
            if (face['optimization_option'] === 'weather') {
              if (optimizationOption) {
                const d = this.store.selectedSize;
                clickField = `${optimizationOption}.${d.size}_${index}.${d.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}.clickLink`;
                imageField = `${optimizationOption}.${d.size}_${index}.${d.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}.imageSrc`;
              }

             /* if (this.obtainTemplateName() === 'DISPLAY VIDEO') {
                /!* face['mainSrc'] = face[imageField] ? face[imageField] : face['default_imageSrc'];*!/
              }else{*/
                face['clickLink'] = face[clickField] ? face[clickField] : face['default_clickLink'];
                face['imageSrc'] = face[imageField] ? face[imageField] : face['default_imageSrc'];
              /*}*/
            }
          });

        /*  if (this.obtainTemplateName() === 'DISPLAY VIDEO') {
            auxBannerData.bannerData.elements.videos.video['mainSrc'] = face['mainSrc'];
          }else{*/
            auxBannerData.bannerData.media.faces = faces;
          /*}*/
          script = script.replace(bannerData, JSON.stringify(auxBannerData));
        }

        this.iframe.contentWindow.postMessage({
          html: this.store.bannerDetail.html,
          style: this.store.bannerDetail.style,
          script: script,
          action: action,
          origin: this.store.bannerDetail.origin
        }, '*');
      }
    }
  }


  removeFaceGroup(formArray, index) {
    formArray.removeAt(index);
    this.selectedFace = index === 0 ? 1 : index - 1;
    this.updatePreview();
  }

  addFaceGroup(facesArray, changeFace?, index?) {

    this.creativeService.clearControls();
    this.creativeService.adURLControl.setValue('https://');
    this.creativeService.appStoreURLControl.setValue('https://');
    this.creativeService.googlePlayURLControl.setValue('https://');

    let renderOptFields: boolean = false;
    let renderGroup;


    if (this.selectedTemplate.toLowerCase().includes('content display')) {

      const objectiveId = this.campaignService.structure.get('objective').value.id;
      let subObjectiveId;
      if (objectiveId === 6) {
        subObjectiveId = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective.id;
      }

      if (subObjectiveId === 1 || (objectiveId === 1 && this.store.productType.toUpperCase() === 'FREETEXTANNONS') || this.dynamic_ad) {

        const group: FormGroup = this._fb.group({
          clickLink: this.creativeService.adURLControl,
          subheader: this.creativeService.adSubHeaderControlDT,
          hasError: this.creativeService.hasErrorControl
        });

        if (subObjectiveId === 1 || (objectiveId === 1 && this.store.productType.toUpperCase() === 'FREETEXTANNONS') || this.store.productType.toUpperCase() === 'RESPONSIVANNONS') {
          group.addControl('nameText', this.creativeService.adAdvertiserNameDT);
        }

        if (!subObjectiveId) {
          const site = this.globalfunctionality.getModuleValues('context')?.contexts[0]?.context?.toUpperCase();

          // Only Prisjakt responsive format includes the call to action button ATM
          if (objectiveId === 1 && this.dynamic_ad) {
            if (site === 'PRISJAKT') {
              group.addControl('callToAction', this.creativeService.adCallToActionControl);
              group.addControl('title', this.creativeService.adTitleControlDT50);
            }
          }
          if (objectiveId === 1 && this.store.productType.toUpperCase() === 'FREETEXTANNONS') {
            group.addControl('title', this.creativeService.adTitleControlDT);
          }
        }

        if (this.store.showImageA) {
          group.addControl('imageSrc', this.creativeService.adMediaControl);
          group.addControl('imageSrcType', this.creativeService.adMediaTypeControl);
        }
        if (this.store.showImageB) {
          group.addControl('logoSrc', this.creativeService.adMediaLogoControl);
          group.addControl('logoSrcType', this.creativeService.adMediaLogoTypeControl);
        }
        facesArray.push(group);
      } else if (subObjectiveId === 2) {
        const device = this.globalfunctionality.getModuleValues('device');
        // TODO - use operating system id if there is one, instead of a string value
        this.selectedOperatingSystem = device ? device.selectedOSTargetOption : undefined;
        const group = this._fb.group({
          subheader: this.creativeService.adSubHeaderControlDT,
          nameText: this.creativeService.adAdvertiserNameDT,
          title: this.creativeService.adTitleControlDT,
          logoSrc: this.creativeService.adMediaLogoControl,
          logoSrcType: this.creativeService.adMediaLogoTypeControl,
          hasError: this.creativeService.hasErrorControl
        });


        switch (this.selectedOperatingSystem) {
          case 'iOS':
            group.addControl('iosLink', this.creativeService.appStoreURLControl);
            break;
          case 'Android':
            group.addControl('androidLink', this.creativeService.googlePlayURLControl);
            break;
          default:
            group.addControl('iosLink', this.creativeService.appStoreURLControl);
            group.addControl('androidLink', this.creativeService.googlePlayURLControl);
        }
        facesArray.push(group);
      }
    } else if (this.obtainTemplateName() === 'DISPLAY VIDEO') {
      const group: FormGroup = this._fb.group({
        clickLink: this.creativeService.adURLControl,
        mainSrc: this.creativeService.adMediaControl,
        optionalImageSrc: this.creativeService.adMediaControl,
        hasError: this.creativeService.hasErrorControl
      });
      facesArray.push(group);
    } else {
      const group: FormGroup = this._fb.group({
        clickLink: this.creativeService.adURLControl,
        imageSrc: this.creativeService.adMediaControl,
        hasError: this.creativeService.hasErrorControl
      });
      const creatives = this.globalfunctionality.getModuleValues('creatives');
      // If optimization values exists in faceArray, add those formcontrols so the UI can render those fields
      if (creatives.creative_data) {
        if (creatives.creative_data.facesArray[index]) {
          const face = creatives.creative_data.facesArray[index];
          if (face['image.macro'] && face['clickLink.macro']) {
            const d = this.store.selectedSize;
            const columnName = `${d.size}_${index}.${d.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}`;
            ['sun', 'rain', 'cloud', 'snow'].forEach(weather => {
              group.addControl(`${weather}.${columnName}.clickLink`, this.creativeService.optimizationClickLink);
              group.addControl(`${weather}.${columnName}.imageSrc`, this.creativeService.optimizationImage);
            });
            group.addControl(`clickLink.column.id`, this.creativeService.nonRequiredFormControl);
            group.addControl(`image.column.id`, this.creativeService.nonRequiredFormControl);
            group.addControl(`clickLink.macro`, this.creativeService.nonRequiredFormControl);
            group.addControl(`image.macro`, this.creativeService.nonRequiredFormControl);

            //  Will hold the default values, because original field will be overriden with the macro used for preview
            group.addControl(`default_clickLink`, this.creativeService.nonRequiredFormControl);
            group.addControl(`default_imageSrc`, this.creativeService.nonRequiredFormControl);

            // Holds which optimization option that was selected
            group.addControl(`optimization_option`, this.creativeService.nonRequiredFormControl);

            renderOptFields = true;
            renderGroup = group;
          }
        }
      }

      if (this.store.productType.toUpperCase() === 'SPECIALANNONS' && this.store.selectedSize.format.toUpperCase() === 'MOBILE') {
        group.addControl('horizontalImage', this.creativeService.adMediaHorizontalControl);
      }

      if (this.selectedTemplate === 'Video') {
        group.addControl('MMSCode', this.creativeService.adMMSControl);
        group.addControl('MMSDecision', this.creativeService.adMMSDecisionControl);
      }
      facesArray.push(group);
    }

    this.templateGroup.setControl('facesArray', facesArray);
    if (changeFace) {
      this.selectedFace = this.templateGroup.get('facesArray').value.length - 1;
    }

    return renderOptFields;
  }

  validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  updateAdData(facesArray, start, end) {
    const params = JSON.parse(this.store.bannerDetail.params);
    if (facesArray.value) {
      params.bannerData.media.faces = facesArray.value;
    }
    const bannerData = this.store.bannerDetail.script.substring(start, end);
    this.store.bannerDetail.script = this.store.bannerDetail.script.replace(bannerData, JSON.stringify(params));

    this.updateIframe("update");
  }


  updatePreview(faceIndex?: number) {
    if (faceIndex !== undefined) { // Can be 0
      this.faceIndex = faceIndex;
    }

    this.templateGroup.markAsTouched();
    const facesArray = this.templateGroup.controls['facesArray'] as FormArray;
    this.detectChange();
    const creativeValues = this.globalfunctionality.getModuleValues("creatives");

    if (!this.feedOption) {
      clearTimeout(this.updatePreviewTimeout);
      this.updatePreviewTimeout = setTimeout(() => {
        this.saveAdData(creativeValues, facesArray);

        if (!this.noPreviewAvailable) {
          if (this.store.bannerDetail !== undefined) {
            const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
            const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");

            if (facesArray.value.length > 0) {
              const bannerData = this.store.bannerDetail.script.substring(start, end);
              const bannerDataJson = JSON.parse(bannerData);
              const data = bannerDataJson.bannerData;

              if (this.obtainTemplateName() === 'DISPLAY VIDEO') {
                this.handleDisplayVideoParams(facesArray, creativeValues, data);
                this.updateBannerParams(bannerData, bannerDataJson);
              } else {
                const face: FormGroup = facesArray.at(this.faceIndex) as FormGroup;

                if (face?.get('optimization_option')?.value === 'weather') {
                  // Update default values in ACM
                  const face: FormGroup = facesArray.at(this.faceIndex) as FormGroup;
                    const libraryId = this.campaignService.structure.get('config').value['weatherLibId'];
                    const requests =
                      [
                        this.apiservice.putJSON(this.store.apiURL + `/ACMColServlet?acmLibId=${libraryId}&colId=${face.controls['clickLink.column.id'].value}`,
                          {defaultValue: face.controls['clickLink'].value}),
                        this.apiservice.putJSON(this.store.apiURL + `/ACMColServlet?acmLibId=${libraryId}&colId=${face.controls['image.column.id'].value}`,
                          {defaultValue: face.controls['imageSrc'].value})
                      ];
                    forkJoin(requests).subscribe(res => {
                      face.controls['default_clickLink'].setValue(face.controls['clickLink'].value);
                      face.controls['default_imageSrc'].setValue(face.controls['imageSrc'].value);
                     /* data.media.faces = this.handleOptimizationParams(data.media.faces);*/

                      data.media.faces = facesArray.value;
                      this.updateBannerParams(bannerData, bannerDataJson);
                    });

                } else {
                  data.media.faces = facesArray.value;
                  this.updateBannerParams(bannerData, bannerDataJson);
                }
              }

            }
          } else {
            this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(this.selectedTemplate))
              .subscribe(res => {
                this.store.bannerDetail = res.data[0];
                const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
                const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");

                if (facesArray.value.length > 0) {
                  this.updateAdData(facesArray, start, end);
                }
              });
          }
        } else {
          this.checkIfComplete();
        }
      }, 500);
    } else {
      creativeValues['creative_data'] = this.templateGroup.value;
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    }
  }

  updateBannerParams(bannerData, bannerDataJson) {
    this.store.bannerDetail.params = JSON.stringify(bannerDataJson);
    this.store.bannerDetail.script = this.store.bannerDetail.script.replace(bannerData, JSON.stringify(bannerDataJson));



    this.updateIframe("update");
    this.checkIfComplete();
  }

  /**
   * When using optimization on an ad this function will be called only when the default field is uploaded/entered
   * @param faces
   */
  handleOptimizationParams(faces) {
    const facesArray: FormArray = this.templateGroup.get('facesArray') as FormArray;
    const face: FormGroup = facesArray.at(this.faceIndex) as FormGroup;

    const obj = {}; // Used for saving all formcontrols created, they are used to build the UI
    Object.keys(face.controls).forEach(elm => {
      obj[elm] = face.controls[elm].value;
    });

    // Create faces array where imageSrc and clickLink is replaced with the macro
    if (face['optimization_option'] === 'weather') {
      faces = [...faces,
        {
          ...obj,
          imageSrc: face.controls['image.macro'].value,
          clickLink: face.controls['clickLink.macro'].value,
          hasError: false
        }];
    }
    return faces;
  }

  handleDisplayVideoParams(facesArray, values, data) {
    const faceValue = facesArray.value[0];
    if (values['videoMetadata']) {
      const {width, height, duration} = values['videoMetadata'];
      const videoAspectRatio = width / height;
      const formatWidth = parseInt(this.store.selectedSize.width.replace('px', ''));
      const formatHeight = parseInt(this.store.selectedSize.height.replace('px', ''));
      const newHeight = Math.round(formatWidth / videoAspectRatio); /*w1/h1 = w2/h2*/
      const newWidth = Math.round(formatHeight * videoAspectRatio);
      const imageHeight = Math.round(formatHeight - newHeight);
      const imageWidth = Math.round(formatWidth - newWidth);

      this.noDiff = imageWidth === 0 && imageWidth === 0; // Used for logic when video aspect ratio is the same as the format aspect ratio

      if (!this.templateGroup.get('useImage').touched) {
        if (data.behavior.dimension.dimension.adShape === 'vertical') {
          this.templateGroup.get('useImage').setValue(formatHeight - newHeight >= 50 ? 'true' : 'false');
        } else {
          this.templateGroup.get('useImage').setValue(formatWidth - newWidth >= 50 ? 'true' : 'false');
        }
      }
      const face = facesArray.controls[0];
      if (this.templateGroup.get('useImage').value === 'false') {
        face.get('optionalImageSrc').clearValidators();
      } else {
        face.get('optionalImageSrc').setValidators([Validators.required]);
      }
      face.get('optionalImageSrc').updateValueAndValidity();

      if (this.templateGroup.get('useImage').value === 'true') {
        if (data.behavior.dimension.dimension.adShape === 'vertical') {
          data.elements.videos.video['height'] = newHeight;
          data.elements.images.dynamic['height'] = imageHeight;
          data.elements.images.dynamic['width'] = formatWidth;
          this.recommended_size = `${formatWidth}x${imageHeight}`;
        } else {
          data.elements.videos.video['width'] = newWidth;
          data.elements.images.dynamic['width'] = imageWidth;
          data.elements.images.dynamic['height'] = formatHeight;
          this.recommended_size = `${imageWidth}x${formatHeight}`;
        }
        data.elements.images.dynamic['noImage'] = false;
        data.elements.images.dynamic['url'] = faceValue['optionalImageSrc'];
      } else {
        data.elements.videos.video['height'] = formatHeight;
        data.elements.videos.video['width'] = formatWidth;
        data.elements.images.dynamic['noImage'] = true;
      }
      data.elements.videos.video['mainSrc'] = faceValue['mainSrc'];
      data.elements.videos.video['length'] = duration;
      data.behavior.tracking.click['url'] = faceValue['clickLink'];
      this.templateGroup.get('adParams').setValue(data);
    }
  }


  updateACMMediaLibrary(column) {
    const columns = this.creativeService.ACMColumns;
    const obj =
      {
        acmLibId: this.creativeService.ACMLibrary.id,
        acmRowId: this.creativeService.ACMRowId,
        cells: undefined
      };

    const controls = this.templateGroup.controls;
    if (column === 'product_background') {
      let background = controls['product.background.image'].value;
      if (controls['product.background.option'].value === 'color') {
        background = controls['product.background.color'].value;
      }

      if (!background.type) {  // images being uploaded to formcontrol returns a image url as value not a object containing column type etc
        background =
          {
            value: background,
            type: 'image'
          };
      }

      // Workaround when background is not a object when custom background is uploaded
      obj.cells = [
        {
          type: 'text',
          value: controls['product.background.option'].value === 'custom' ? 'image' : controls['product.background.option'].value,
          columnId: columns.find(col => col.name === 'product.background.option').id
        },
        {
          type: background.type ? background.type : 'image',
          value: background.value,
          columnId: background.type === 'image' ? columns.find(col => col.name === 'product.background.image').id : columns.find(col => col.name === 'product.background.color').id
        }
      ];

    } else if (column === 'fallback') {
      if (controls['static.options.frequency'].value === 'off') {
        obj.cells = [
          {
            type: 'image',
            value: controls['fallback.image.url'].value,
            columnId: columns.find(col => col.name === 'fallback.image.url').id
          },
          {
            type: 'clickLink',
            value: controls['fallback.image.clickLink'].value,
            columnId: columns.find(col => col.name === 'fallback.image.clickLink').id
          }
        ];
      } else {
        // Only 1 image is uploaded (fallback.image.url) if frequency is selected use same image as static image (same with clicklink)
        obj.cells = [
          {
            type: 'image',
            value: controls['fallback.image.url'].value,
            columnId: columns.find(col => col.name === 'statics[0].image').id
          },
          {
            type: 'clickLink',
            value: controls['fallback.image.clickLink'].value,
            columnId: columns.find(col => col.name === 'statics[0].clickLink').id
          },
          {
            type: 'image',
            value: controls['fallback.image.url'].value,
            columnId: columns.find(col => col.name === 'fallback.image.url').id
          },
          {
            type: 'clickLink',
            value: controls['fallback.image.clickLink'].value,
            columnId: columns.find(col => col.name === 'fallback.image.clickLink').id
          },
          {
            type: 'text',
            value: controls['static.options.frequency'].value,
            columnId: columns.find(col => col.name === 'static.options.frequency').id
          }
        ];
      }

    } else {
      let value = controls[column].value;

      if (!value.type) { // images being uploaded to formcontrol returns a image url as value not a object containing column type etc
        value =
          {
            type: 'image',
            value: value
          };
      }
      obj.cells = [
        {
          type: value.type,
          value: value.value,
          columnId: columns.find(col => col.name === column).id
        }
      ];
    }
    this.updateACMRow(obj);
  }

  updateACMRow(obj) {
    this.apiservice.putJSON(this.store.apiURL + '/ACMRowServlet', obj).subscribe(res => {
      if (res.responseCode === 200) {
        this.globalfunctionality.updateACMPreviewOnly.next(true);
      }
    });
  }

  updateOptimizationRow(optimizationField: string, faceGroup: FormGroup) {

    this.creativeService.loadCreativeSaveBtnSubject.next(true);

    const facesArray = this.templateGroup.controls['facesArray'] as FormArray;
    const creativeValues = this.globalfunctionality.getModuleValues("creatives");
    this.saveAdData(creativeValues, facesArray);

    const imageColumnId: number = faceGroup.controls['image.column.id'].value;
    const clickLinkColumnId: number = faceGroup.controls['clickLink.column.id'].value;
    const imageValue = optimizationField.includes('imageSrc') ? faceGroup.controls[optimizationField].value : faceGroup.controls[optimizationField.replace('clickLink', 'imageSrc')].value;
    const clickLinkValue = optimizationField.includes('imageSrc') ? faceGroup.controls[optimizationField.replace('imageSrc', 'clickLink')].value : faceGroup.controls[optimizationField].value;
    const inputedWeatherOption = optimizationField.split('.')[0];
    const weatherRows = this.weatherOptions.find(option => option.option === inputedWeatherOption).mapping;

    // Get all rows
    this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${this.campaignService.structure.get('config').get('weatherLibId').value}`)
      .subscribe((res: ResponseBodyModel) => {
        // Check if rows already exists
        let exists: boolean = true && res.data['rows'].length > 0;

        if (res.data['rows'].length) {
          weatherRows.forEach(row => {
            exists = res.data['rows'].find(createdRow => createdRow.name === row) !== undefined;
          });
        }

        // If row exists, update row cell with new data, or create new cell depending on which column is uploaded
        // Same row can be used for different columns
        if (exists) {
          /*   För samma rad kan det vara så att ibland behövs cellen uppdateras eller skapas beroende på om kunden exempelvis
             väljer att köra väderoptimering på ett annat face, isåfall så ska samma rad användas men nya celler ska skapas för de nya kolumnerna.
               Om samma face uppdateras ska cellerna enbart uppdateras*/

          const libId = this.campaignService.structure.get('config').get('weatherLibId').value;
          const getRequests = [];
          weatherRows.forEach(row => {
            const rowObj = res.data['rows'].find(createdRow => createdRow.name === row);
            if (rowObj !== undefined) {
              getRequests.push(
                {
                  rowId: rowObj.id,
                  request: this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${libId}&rowId=${rowObj.id}`)
                }
              );
            }
          });

          // Get all row cells, check if cell for specific column exists, if it exists update that cell otherwise create new cell
          forkJoin(getRequests.reduce((prev, cur) => {
            prev.push(cur.request);
            return prev;
          }, [])).subscribe(res => {
            const putRequests = [];
            getRequests.forEach((req, index) => {
              // Check if column that is being manipulated exists as a cell in the row, if exists update values, otherwise create new cell array, containing old cells aswell
              if (res[index]['data'].cells.find(elm => elm.columnName === optimizationField.replace(`${inputedWeatherOption}.`, ''))) {
                res[index]['data'].cells.find(elm => elm.type === 'image')['value'] = imageValue;
                res[index]['data'].cells.find(elm => elm.type === 'clickLink')['value'] = clickLinkValue;
              } else {
                res[index]['data'].cells = [...res[index]['data'].cells, {
                  type: 'image',
                  value: imageValue,
                  columnId: imageColumnId
                },
                  {
                    type: 'clickLink',
                    value: clickLinkValue,
                    columnId: clickLinkColumnId
                  }];
              }
              putRequests.push(
                {
                  acmLibId: libId,
                  acmRowId: req.rowId,
                  rowId: req.rowId,
                  name: req.name,
                  cells: res[index]['data'].cells
                }
              );
            });

            const putObj = {
              acmLibId: this.campaignService.structure.get('config').get('weatherLibId').value,
              rowArray: putRequests
            };


            this.apiservice.putJSON(this.store.apiURL + '/ACMRowServlet', putObj).subscribe(res => {
              if (res.responseCode === 201) {
                this.creativeService.loadCreativeSaveBtnSubject.next(false);
                this.checkIfComplete();
              }
            });

          });

        } else { // if row does not exists, create rows and create corresponding cells for each column
          const postObj = {
            acmLibId: this.campaignService.structure.get('config').get('weatherLibId').value,
            rowArray: []
          };
          weatherRows.forEach(row => {
            postObj.rowArray.push(
              {
                name: row,
                cells: [
                  {
                    type: 'image',
                    value: imageValue,
                    columnId: imageColumnId
                  },
                  {
                    type: 'clickLink',
                    value: clickLinkValue,
                    columnId: clickLinkColumnId
                  }
                ]
              }
            );
          });

          this.apiservice.postJSON(this.store.apiURL + "/ACMRowServlet", postObj).subscribe((res: ResponseBodyModel) => {
            if (res.responseCode === 201) {
              this.creativeService.loadCreativeSaveBtnSubject.next(false);
              this.checkIfComplete();
              /*  this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${this.campaignService.structure.get('config').get('weatherLibId').value}&rowId=${res.data['rowId']}`)
                  .subscribe((res: ResponseBodyModel) => {
                    console.log(res);
                    /!*res.data['cells'].forEach(row => {
                      this.templateGroup.controls[row.columnName].setValue(row.value);
                    });*!/
                  });*/
            }
          });
        }
      });
  }

  saveAdData(creativeValues, facesArray) {
    creativeValues["selectedImages"] = facesArray.getRawValue();
    const templateValue = this.templateGroup.value;
    templateValue['facesArray'] = facesArray.getRawValue();
    creativeValues['creative_data'] = templateValue;
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }


  saveMaterialForSize() {
    if (!this.blockMultipleClick) {
      this.blockMultipleClick = true;
      let complete: boolean = this.templateGroup.valid;
      if (!this.templateGroup.valid) {
        this.templateGroup.markAllAsTouched();
      }
      if (!this.feedOption) {
        complete = this.validateFaces(complete, true);
      }
      if (complete) {
        this.creatingAd = true;
        this.creativeService.loadComponentSubject.next(true);
        let blocketSearchWord: boolean = false;
        if (this.globalfunctionality.getModuleValues('addecision')) {
          if (parseInt(this.globalfunctionality.getModuleValues('addecision').selected)) {
            blocketSearchWord = true;
          }
        }
        if ((this.campaignService.structure.get('objective').value.id === 6 || blocketSearchWord || this.dynamic_ad) && !this.feedOption) {
          this.checkCPCImages(complete);
        } else {
          this.continueSavingMaterial(complete);
        }
      } else {
        this.blockMultipleClick = false;
        this.creativeService.loadComponentSubject.next(false);
      }

    }
  }


  checkCPCImages(complete) {

    const facesArray = this.templateGroup.get('facesArray') as FormArray;
    const media = facesArray.controls[0] as FormGroup;
    const imageObj = {data: []};
    const logoObj = {data: []};
    const values = this.globalfunctionality.getModuleValues("creatives");
    const requests = [];
    const imageUploaded: boolean = this.store.showImageA;
    const logoUploaded: boolean = this.store.showImageB;

    if (imageUploaded) {
      imageObj.data.push(this.buildImagePostObj(media, 'imageSrc', 'imageSrcType'));
      requests.push(this.apiservice.POST(this.store.imageServerUrl, imageObj));
    }
    if (logoUploaded) {
      logoObj.data.push(this.buildImagePostObj(media, 'logoSrc', 'logoSrcType'));
      requests.push(this.apiservice.POST(this.store.imageServerUrl, logoObj));
    }


    forkJoin(requests).subscribe((response) => {
      response = response.map(elm => elm[0]['image']);
      if (imageUploaded && logoUploaded) {
        media.get('imageSrc').setValue(response[0]);
        media.get('logoSrc').setValue(response[1]);
      } else if (imageUploaded) {
        media.get('imageSrc').setValue(response[0]);
      } else if (logoUploaded) {
        media.get('logoSrc').setValue(response[0]);
      }

      // TODO: Remove selectedImages object
      /*values["selectedImages"] = facesArray.value;*/
      values["creative_data"]['facesArray'] = facesArray.value;
      this.detectChange();
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.continueSavingMaterial(complete && true);
    });

  }

  buildImagePostObj(media, src, type) {
    const obj = {};
    const fileType = media.controls[type].value.split(';')[0].split('/')[1];
    obj['base64'] = media.controls[src].value;
    obj['type'] = fileType;
    obj["key"] = "image";
    return obj;
  }

  createPostObj() {
    const obj = this.store.bannerDetail;

    const params = JSON.parse(obj.params);
    const faces = params?.bannerData?.media?.faces;
    if(faces) {
      faces.forEach(face => {
        if (face.optimization_option === 'weather') {
          face['clickLink'] = face['clickLink.macro'];
          face['imageSrc'] = face['image.macro'];
        }
      });
    }
    obj.params = JSON.stringify(params);

    obj['name'] = this.adName;
    obj['publisher'] = this.store.user.companies[0].acm_id;
    obj['tags'] = ['SSISP'];

    if (obj['vast'] === null) {
      delete obj['vast'];
    }
    if (obj['vpaid'] === null) {
      delete obj['vpaid'];
    }
    if (obj['form'] === null) {
      delete obj['form'];
    }

    if (obj['style'] === null) {
      delete obj['style'];
    }

    return obj;
  }


  continueSavingMaterial(complete) {
    // TODO: IF PRODUCTTYPE IS SPECIAL ANNONS AND THE DEVICE IS MOBILE CHECK FOR THE HORIZONTAL IMAGE ASWELL

    const creatives = this.globalfunctionality.getModuleValues('creatives');
    const validationRule: boolean = this.feedOption ? complete : complete && creatives.isComplete;

    if (validationRule) {
      creatives['productType'] = this.store.productType;
      creatives['isComplete'] = true;
      const adForSize = this.buildAdForSizeObj();
      this.detectChange();

      this.store.GUIFlow[this.store.page].forEach((elm) => {
        if (elm.type === 'creatives') {
          elm['module_values'].isComplete = true;
        }
      });

      const formatIndex = adForSize.selected.findIndex(elm => elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format);
      this.feedOption ? this.handleACMCreative(adForSize, formatIndex, creatives) : this.handleNonACMCreative(adForSize, formatIndex, creatives);
    } else {
      this.creativeService.loadComponentSubject.next(false);
      this.blockMultipleClick = false;
      this.detectChange();
    }
  }


  handleACMCreative(adForSize, formatIndex, creatives) {
    const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
    set['status'] = 'Pending';
    set['complete'] = true;
    set['acm_data'] = {
      feed_option: this.templateGroup.get('feedOptionControl').value,
      feed_selection: this.templateGroup.get('feedSelectedControl').value,
      media_library: this.creativeService.ACMLibrary,
      media_library_row_id: this.creativeService.ACMRowId
    };
    adForSize['selected'][formatIndex]['complete'] = true;
    this.store.creativesForSize = adForSize['selected'];
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    this.reset(true);
  }

  handleNonACMCreative(adForSize, formatIndex, creatives) {
    // Check if banner exists, then update banner instead of creating it
    let update = false;
    if (adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex]['banners']) {
      adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex]['banners'].forEach(elm => {
        update = true;
        this.store.bannerId = elm.bannerId;
      });
    }

    if (this.campaignService.structure.get('objective').value.id !== 6 && !this.dynamic_ad) {
      if (!update) {
        const obj = this.createPostObj();
        this.apiservice.POST(this.store.adServer + '/createBannerLEServlet', obj)
          .subscribe(res => {
            const data = JSON.parse(res['data']);
            this.saveCreative(adForSize, formatIndex, res, data);
            if (this.campaignService.structure.get('objective').value.id !== 6) {
              this.saveToArchieve(creatives, res);
            } else {
              this.reset(true);
            }
          });
      } else {
        const obj =
          {
            publisherId: 146,
            action: 'updateBanner',
            bannerId: this.store.bannerId
          };

        this.store.bannerDetail.name = this.adName;

        this.apiservice.GET(this.store.adServer + '/getBannerDetail?action=get&bannerId=' + this.store.bannerId)
          .subscribe(res => {
            this.store.bannerDetail.data = res.data;
            obj['bannerDetail'] = this.store.bannerDetail;
            if (obj['bannerDetail']['vast'] === null) {
              delete obj['bannerDetail']['vast'];
            }
            if (obj['bannerDetail']['vpaid'] === null) {
              delete obj['bannerDetail']['vpaid'];
            }
            if (obj['bannerDetail']['form'] === null) {
              delete obj['bannerDetail']['form'];
            }
            this.apiservice.POST(this.store.adServer + '/updateLEBanner', obj)
              .subscribe(res => {
                // TODO: check res
                this.updateCreative(adForSize, formatIndex);
              });
          });
      }
    } else {
      this.saveCreative(adForSize, formatIndex);
      this.reset(true);
    }
  }

  saveCreative(adForSize, formatIndex, res ?, data ?) {
    const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
    set['status'] = 'Pending';

    // Check if the specific creative set has any banners uploaded
    if (set['banners'] === undefined) {
      set['banners'] = [];
    }

    const obj = {};
    if (res) {
      obj['adScript'] = res.script;
      obj['bannerId'] = res.bannerId;
    }
    if (data) {
      obj['demoLink'] = data.demoUrl;
      obj['adsscoreUrl'] = data.adsscoreUrl;
    }

    obj['name'] = this.adName;
    obj['adFormatName'] = this.globalfunctionality.getModuleValues('templates').selectedTemplate;
    set['banners'].push(obj);

    // If updating creatives set creative statuses
    set['removed'] = false;
    if (set['complete']) {
      if (this.updateCreatives) {
        set['updated'] = true;
      }
    } else {
      set['added'] = true;
    }
    set['complete'] = true;

    // Remove existing tag if exists (user goes from 3rd party to own creative
    if (set['script']) {
      delete set['script'];
    }

    const tag = this.globalfunctionality.getModuleValues('existingtag');
    if (tag) {
      tag.script = '';
    }

    adForSize['selected'][formatIndex]['complete'] = true;
    this.store.creativesForSize = adForSize['selected'];
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  buildAdForSizeObj() {

    const creatives = this.globalfunctionality.getModuleValues('creatives');
    const adcreation = this.globalfunctionality.getModuleValues('adcreation');
    const templates = this.globalfunctionality.getModuleValues('templates');
    const adForSize = this.globalfunctionality.getModuleValues('adforsize');

    adForSize.selected.forEach((elm) => {
      if (elm.device === this.store.selectedSize.format) {
        if (elm.format === this.store.selectedSize.size) {
          // Ad creative for specific ad set
          elm['creative_set'][this.store.selectedSize.setIndex]['creatives'] = creatives;
          elm['creative_set'][this.store.selectedSize.setIndex]['adcreation'] = adcreation;
          elm['creative_set'][this.store.selectedSize.setIndex]['templates'] = templates;
          sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
        }
      }
    });
    return adForSize;
  }


  updateCreative(adForSize, formatIndex) {
    const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
    set['status'] = 'Pending';

    // Reset removed value and set the set to updated
    if (this.updateCreatives) {
      set['removed'] = false;
      set['updated'] = true;
    }

    // Remove existing tag if exists (user goes from 3rd party to own creative
    if (set['script']) {
      delete set['script'];
    }
    const tag = this.globalfunctionality.getModuleValues('existingtag');
    if (tag) {
      tag.script = '';
    }

    toastr.info('Material uppdaterad');
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.reset(true);
  }

  saveToArchieve(creatives, body ?) {

    const facesArray = this.templateGroup.get('facesArray') as FormArray;

    const templates = this.globalfunctionality.getModuleValues('templates');
    const adcreation = this.globalfunctionality.getModuleValues('adcreation');
    const libraryJsonObj = {};

    libraryJsonObj['creatives'] = creatives;
    libraryJsonObj['templates'] = templates;
    libraryJsonObj['adcreation'] = adcreation;
    libraryJsonObj['media'] = facesArray.value;
    if (body) {
      libraryJsonObj['script'] = body.script;
    }

    // Save created ad in the creatives library table
    const libraryObj = [
      {
        "companyId": this.campaignService.structure.get('company').value.id,
        "brandsId": this.campaignService.structure.get('brand').get('id').value,
        "size": this.store.selectedSize.size,
        "device": this.store.selectedSize.format,
        "json": libraryJsonObj
      }
    ];


    // SAVE AD IN ADLIBRARY AND UPDATE LIST OF ADS IN LIBRARY
    this.apiservice.putJSON(this.store.apiURL + '/CreativesServlet', libraryObj)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.reset(true);
        }
      });
  }

  reset(trigger ?) {
    $('.modal-backdrop').removeClass('is-active');
    const modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;
    this.blockMultipleClick = false;
    this.adName = '';

    const creationParam = this.globalfunctionality.getModule('adcreation');
    creationParam.param.output = '';
    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        elm.module_values['id'] = id;
      }
    });

    this.creativeService.clearModuleValues();
    /*this.creativeService.loadComponentSubject.next(false);
    this.detectChange();*/

    if (trigger) {
      toastr.info('Material sparat');
      this.globalfunctionality.adCreated.next(true);
    }
  }

  // TODO: Workaround to trigger ngonchanges
  updateVal(value) {
    this.noPreviewAvailable = value;
  }

  videoExtensionRetrieved(extension: string) {
    this.videoExtension = extension;
  }

  validateFaces(complete, setError: boolean = false): boolean {
    const facesArray = this.templateGroup.controls['facesArray'] as FormArray;
    let index: number = 0;
    let errorIndex: number;
    for (let facesArrayKey in facesArray.controls) {
      let faceComplete: boolean = true;
      for (let controlKey in facesArray.controls[facesArrayKey]['controls']) {
        if (controlKey !== 'hasError') { // hasErrorControl is used for setting error true/false for each face
          if (facesArray.controls[facesArrayKey]['controls'][controlKey].errors !== null) {
            complete = false;
            faceComplete = false;
            if (setError) {
              facesArray.controls[facesArrayKey]['controls'][controlKey].markAsTouched();
              if (errorIndex === undefined) {
                errorIndex = index;
                this.selectedFace = errorIndex;
              }
            }
          }
          if (setError || faceComplete) {
            facesArray.controls[facesArrayKey]['controls']['hasError'].setValue(!faceComplete);
          }
        }
      }
      index++;
    }
    this.detectChange();
    return complete;
  }

  checkIfComplete() {
    // Update formsarray, does not trigger ngonchanges otherwise
    this.templateGroup.setControl('facesArray', new FormArray(this.templateGroup.get('facesArray')['controls']));

    const values = this.globalfunctionality.getModuleValues("creatives");
    /*values['creative_data'] = this.templateGroup.value;*/
    let complete: boolean = true;

    // Check adname validator
    if (this.templateGroup.controls['adNameControl']) {
      if (this.templateGroup.controls['adNameControl'].errors !== null) {
        complete = false;
      }
    }
    complete = this.validateFaces(complete);
    values.isComplete = complete;

    this.store.GUIFlow[this.store.page].forEach((elm) => {
      if (elm.type === "creatives") {
        elm["module_values"].isComplete = complete;
      }
    });

    this.globalfunctionality.setModuleValue('creatives', values);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    this.globalfunctionality.creativesChecker.next(true);
  }

  gotoFeedCreation() {
    const dialogRef = this.dialog.open(WarningConfirmationDialogComponent, {
      panelClass: 'modal-size-sm',
      data: {
        text: 'Vill du bli omdirigerad?',
        desc: 'Du kommer bli omdirigerad till sidan där du kan ladda upp din produktfeed',
        abort_btn: 'Nej',
        confirm_btn: 'Ja',
        confirm_class: 'primary'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.store.isCompanyAdministrator.value) {
          this.reset(false);
          this.router.navigate(['dashboard/data-management/feed']);
        }
      }
    });
  }

  obtainTemplateName() {
    return this.selectedTemplate.replace(this.store.selectedSize.size, '').trim().toUpperCase();
  }

  /**
   * Triggered when optional image selector is changed
   * Used for display video format
   * @param value true or false
   * @param adGroup ad object
   */
  updateSelectorValue(value, adGroup: FormGroup) {
    adGroup.get('useImage').setValue(value);
    this.updatePreview();
  }

  /**
   * Builds an array for each field and optimization option combination
   * @param optimizationOption Decides which logic to run when updating ad params
   * @param formGroup Obtain controls that should be rendered in the UI
   * @param faceIndex Which unique controls that should be rendered
   */
  renderOptimizationElements(optimizationOption: string, formGroup: FormGroup, faceIndex: number) {
    if (optimizationOption) {
      this.optimizationOption = optimizationOption;
      this.faceIndex = faceIndex;
      ['clickLink', 'imageSrc'].forEach(type => {
        const searchKey = `${this.store.selectedSize.size}_${faceIndex}.${this.store.selectedSize.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}.${type}`;
        const fields = Object.keys(formGroup.controls).filter(key => key.includes(searchKey));
        this.optimizationOptionControls[type] = fields;
      });
    } else {
      this.optimizationOptionControls =
        {
          clickLink: [],
          imageSrc: []
        };

      this.optimizationOption = undefined;
      this.updatePreview();
    }
  }

  changePreviewOption(option: string) {
    this.updateIframe('update', option);
  }

  changeFace(index, faceGroup) {
    this.faceIndex = index;
    this.selectedFace = index;

    ['clickLink', 'imageSrc'].forEach(type => {
      const searchKey = `${this.store.selectedSize.size}_${index}.${this.store.selectedSize.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}.${type}`;
      const fields = Object.keys(faceGroup.controls).filter(key => key.includes(searchKey));
      this.optimizationOptionControls[type] = fields;
    });
    this.detectChange();
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

}

