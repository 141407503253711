import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {ApiService} from '../../services/api.service';
import {GlobalfunctionalityService} from '../../services/globalfunctionality.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

export class MenuOptionsModel {
  key: string;
}

@Component({
  selector: 'app-data-management',
  templateUrl: 'data-management.component.html'
})
export class DataManagementComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  selectedManagementTabIndex: number = 0;

  menuOptions: MenuOptionsModel[] =
    [
      {
        key: 'feed'
      },
      {
        key: 'target'
      }
    ];

  constructor(
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.globalfunctionality.highlightMenuItem('data_management');
    const key = this.router.url.split('/')[3];
    this.selectedManagementTabIndex = this.menuOptions.findIndex(elm => elm.key === key);
  }

  changeTab(e) {
    this.selectedManagementTabIndex = e;
    const elm = this.menuOptions[e];
    this.manageMenu(elm.key);
  }

  manageMenu(option) {
    switch (option) {
      case 'target':
        this.navigate('dashboard/data-management/target', undefined);
        break;
      case 'feed':
        this.navigate('dashboard/data-management/feed', undefined);
        break;
    }
  }

  navigate(location, params) {
    if (params === undefined) {
      this.router.navigate(['/' + location]);
    } else {
      this.router.navigate(['/' + location, params]);
    }
  }


}
