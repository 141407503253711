import {NgModule} from "@angular/core";
import {CreativemoduleComponent} from "./creativemodule.component";
import {CpcKlartPreviewComponent} from "./components/native-preview/cpc-klart-preview/cpc-klart-preview.component";
import {CpcAbPreviewComponent} from "./components/native-preview/cpc-ab-preview/cpc-ab-preview.component";
import {CpcBlocketPreviewComponent} from "./components/native-preview/cpc-blocket-preview/cpc-blocket-preview.component";
import {CpcSvdPreviewComponent} from "./components/native-preview/cpc-svd-preview/cpc-svd-preview.component";
import {MaterialModule} from "../../../../../utils/MaterialModule";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NativePreviewComponent} from "./components/native-preview/native-preview.component";
import {ImageCropperModule as imagecropper} from "ngx-image-cropper";
import {PipeModule} from "../../../pipes/pipe.module";
import {CustomPreviewComponent} from "./components/custom-preview/custom-preview.component";
import {NameComponent} from "./components/creative-form/name/name.component";
import {ImageDirectUploadComponent} from "./components/creative-form/image-direct-upload/image-direct-upload.component";
import {ImageCroppedUploadComponent} from "./components/creative-form/image-cropped-upload/image-cropped-upload.component";
import {ClicklinkComponent} from "./components/creative-form/clicklink/clicklink.component";
import {OperatingSystemComponent} from "./components/creative-form/operating-system/operating-system.component";
import {MmsFilmcodeComponent} from "./components/creative-form/mms-filmcode/mms-filmcode.component";
import {HeaderComponent} from "./components/creative-form/header/header.component";
import {MessageComponent} from "./components/creative-form/message/message.component";
import {AdvertiserComponent} from "./components/creative-form/advertiser/advertiser.component";
import {ImpTrackerComponent} from "./components/creative-form/imp-tracker/imp-tracker.component";
import {CreativemoduleService} from "./creativemodule.service";
import {DynamicPrisjaktPreviewComponent} from "./components/native-preview/dynamic-prisjakt-preview/dynamic-prisjakt-preview.component";
import {ProductPriceComponent} from "./components/creative-form/product-price/product-price.component";
import {CallToActionComponent} from "./components/creative-form/call-to-action/call-to-action.component";
import {CatalogSelectorComponent} from "./components/creative-form/feed/catalog-selector/catalog-selector.component";
import {CategorySelectorComponent} from "./components/creative-form/feed/category-selector/category-selector.component";
import {CategoryTreeComponent} from "./components/creative-form/feed/category-selector/category-tree/category-tree.component";
import {BackgroundColorComponent} from "./components/creative-form/background-color/background-color.component";
import {PaginationModule} from "../../../../../utils/pagination/pagination.module";
import {SelectorComponent} from "./components/creative-form/selector/selector.component";
import {OptimizationComponent} from "./components/optimization/optimization.component";

@NgModule({
  declarations: [
    CreativemoduleComponent,

    // Creative preview
    CpcKlartPreviewComponent,
    CpcAbPreviewComponent,
    CpcBlocketPreviewComponent,
    CpcSvdPreviewComponent,
    NativePreviewComponent,
    CustomPreviewComponent,
    DynamicPrisjaktPreviewComponent,

    // Creative form
    NameComponent,
    ImageDirectUploadComponent,
    ImageCroppedUploadComponent,
    ClicklinkComponent,
    OperatingSystemComponent,
    MmsFilmcodeComponent,
    HeaderComponent,
    MessageComponent,
    AdvertiserComponent,
    ImpTrackerComponent,
    ProductPriceComponent,
    CallToActionComponent,
    CatalogSelectorComponent,
    CategorySelectorComponent,
    CategoryTreeComponent,
    BackgroundColorComponent,
    SelectorComponent,

    OptimizationComponent

  ],
  exports: [
    CreativemoduleComponent,

    // Creative preview
    CpcKlartPreviewComponent,
    CpcAbPreviewComponent,
    CpcBlocketPreviewComponent,
    CpcSvdPreviewComponent,
    NativePreviewComponent,
    CustomPreviewComponent,
    DynamicPrisjaktPreviewComponent,

    // Creative form
    NameComponent,
    ImageDirectUploadComponent,
    ImageCroppedUploadComponent,
    ClicklinkComponent,
    OperatingSystemComponent,
    MmsFilmcodeComponent,
    HeaderComponent,
    MessageComponent,
    AdvertiserComponent,
    ImpTrackerComponent,
    ProductPriceComponent,
    CallToActionComponent,
    CatalogSelectorComponent,
    CategorySelectorComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    imagecropper,
    PaginationModule
  ],
  providers: [
    CreativemoduleService
  ]
})

export class CreativemoduleModule {

}
