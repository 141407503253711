import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";


@Component({
  selector: 'app-days-from-creation-view',
  templateUrl: 'days-from-creation-view.component.html'
})

export class DaysFromCreationViewComponent{
  @Input() element: any;

  constructor() {}

}



