import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {CampaignService} from "../../../../../../campaign.service";

@Component({
  selector: 'app-creative-imp-tracker',
  templateUrl: 'imp-tracker.component.html'
})

export class ImpTrackerComponent {

  @Input() adImpTrackerControl;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService,
              public campaignService: CampaignService){

  }


  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

}
