import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";

@Component({
  selector: 'app-creative-name',
  templateUrl: 'name.component.html'
})

export class NameComponent{

  @Input() adNameControl;
  @Output() triggerCheckIfComplete: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService){

  }


  updateAdName() {
    if (this.adNameControl.errors === null) {
      const module = this.globalfunctionality.getModule("creatives");
      module["module_values"]["adName"] = this.adNameControl.value;
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    }
    this.triggerCheckIfComplete.emit();
  }

}
