import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {ResponseBodyModel} from "../../../../../models/response-body.model";
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";

@Component({
  selector: 'app-manually-add-appnexusid',
  templateUrl: './manually-add-appnexusid.component.html'
})
export class ManuallyAddAppnexusidComponent {


  externalAppnexusFormControl: FormControl = new FormControl('', [Validators.required]);

  loadDialog: boolean = false;


  constructor(
    private dialogRef: MatDialogRef<ManuallyAddAppnexusidComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiservice: ApiService,
    private store: StoreService
  ) { }

  save(){

    this.loadDialog = true;
    if(this.externalAppnexusFormControl.valid){
      const obj = {
        appNexusCreativeId: this.data.set.appNexusCreativeId,
        status: 'APPROVED',
        externalAppNexusCreativeId: this.externalAppnexusFormControl.value
      };

      this.apiservice.postJSON(this.store.apiURL + '/AppNexusCreativeServlet', [obj])
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  close(){
    this.dialogRef.close(false);
  }

}
