import {AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import { DateAdapter } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import {BudgetSplitDialogComponent} from "./budget-split-dialog/budget-split-dialog.component";
import {FormcontrolService} from "../../../../../services/formcontrol/formcontrol.service";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {SearchwordObjModel} from "./searchword-obj.model";
import {DeliveryInfoDialogComponent} from "./delivery-info-dialog/delivery-info-dialog.component";
import {CampaignService} from "../../../campaign.service";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {pluck, filter, defaultIfEmpty, debounceTime} from "rxjs/operators";
import {from, of} from "rxjs";
import {ForecastResultObjModel} from "./models/forecast-result-obj.model";
import {ProductResponseModel} from "../../../models/product-response.model";

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-budgetmodule',
  templateUrl: './budgetmodule.component.html',
  styleUrls: ['./budgetmodule.component.css']
})

export class BudgetmoduleComponent implements OnInit, AfterViewInit {

  @ViewChild('startdate') startDateRef: MatDatepicker<Date>;
  @ViewChild('enddate') endDateRef: MatDatepicker<Date>;

  dayTranslate =
    {
      'Monday': 'Måndag',
      'Tuesday': 'Tisdag',
      'Wednesday': 'Onsdag',
      'Thursday': 'Torsdag',
      'Friday': 'Fredag',
      'Saturday': 'Lördag',
      'Sunday': 'Söndag',
    };

  weekDays =
    [
      "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag", "Söndag"
    ];
  timeout;
  sliderControl: FormControl = new FormControl();
  moduleParams;
  minBudget = 0;
  showTimescheduling: boolean = false;
  daysList = [];
  hoursList = [];
  startHourList = [];
  endHourList = [];
  selectedDays = [];
  selectedHours = [];
  hourSets =
    [
      {
        startHour: undefined,
        endHour: undefined,
      }
    ];
  freqOptions = [{name: "Dag", value: "day"}, {name: "Kampanjperiod", value: "life"}];
  selectedFreqOption;
  freqDecisionOption = "standard";
  freqAmount;
  frequencyList;
  Date;
  minStartDate = new Date();
  maxDate = new Date();
  initialStartDate = new Date();
  minEndDate = new Date();
  initialEndDate = new Date();
  startDateValue = new Date();
  endDateValue = new Date();
  budget: any;
  startDateParsed;
  endDateParsed;
  days;
  selectedOption = "Lifetime";
  module;
  values;
  Math: any;
  showForecast = false;
  timer;
  check = true;
  impressions;
  wrongStartDate = false;
  value = 10;
  geoValues;
  demograpihcValues;
  interestValues;
  selectedGeos;
  selectedFreetext;
  selectedCategory;
  selectedContext;

  productPrice: number;

// Context product min budget
  productMinBudget: number = 1000;

  adTypeValues;
  blocketSearchWord;

  // Context targeting booked
  targetingBooked: boolean = false;
  disableFrequency: boolean = false;

  desktopProductChosen = false;
  mobileProductChosen = false;
  contextHasError = false;
  maxDays = 120;
  soonAsPossible = false;

  searchwordObj: SearchwordObjModel;


  moment;
  hideFrequency: boolean = false;
  distributionOccured: boolean = false;

  searchwordSelectedOption;

  blocketSearchWordProduct;

  guaranteedDelivery: boolean = true;

  campaignDateModified: boolean = false;

  // Copy campaign
  removedInterest: string;


  forecastResultObj: ForecastResultObjModel = {
    max_budget: undefined,
    forecast_approved: false,
    percentage: {
      unavailable: undefined,
      available: undefined,
      booked: undefined
    },
    impressions: {
      available: 0,
      unavailable: 0,
      capacity: 0,
      selected: 0
    }
  };
  budgetControl = new FormControl();

  loadForecast: boolean = false;

  weekDates = [];

  weekNumberTimeout;

  selectedSite: string;

  showMore: boolean = false;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private _create: CreatecampaignComponent,
              private _dateAdapter: DateAdapter<Date>,
              public dialog: MatDialog,
              public form: FormcontrolService,
              public campaignService: CampaignService) {

    if (this.store.selectedLangCode !== undefined) {
      this._dateAdapter.setLocale(this.store.selectedLangCode);
    } else {
      this._dateAdapter.setLocale("en");
    }
    this.Math = Math;
    this.Date = Date;
    this.moment = moment;
  }

  ngAfterViewInit(): void {
    // ViewChild start date datepicker, subscribe to open event
    this.startDateRef.openedStream
      .subscribe((opened) => {
        this.getNextAndPrevDatepickerElements();
        this.getWeekNumbers();
        this.store.overrideTopNavZIndex = true;
        this.placeCustomButton(this.startDateRef);
      });

    this.endDateRef.openedStream.subscribe(() => {
      this.getNextAndPrevDatepickerElements();
      this.getWeekNumbers();
      this.store.overrideTopNavZIndex = true;
    });

    this.startDateRef.closedStream.subscribe(() => this.store.overrideTopNavZIndex = false);
    this.endDateRef.closedStream.subscribe(() => this.store.overrideTopNavZIndex = false);
  }


  ngOnInit() {

    this.initateValues();
    this.handleTrackSpecialCases();
    this.setDefaultValues();
    this.updateForecastValues();


    const obj = this.getSelectedFormats();

    if (this.campaignService.structure.get('objective').value.id === 1) {
      this.calculateAmountOfProducts();
      this.getFrequency();

    } else if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
      if (this.campaignService.structure.get('objective').value.id === 2) {
        this.setAudienceValues();
      }

      if(this.store.productType.toUpperCase() === 'RESPONSIVANNONS'){
        this.selectResponsivProduct();
      }else{
        this.getProductBySelectedFormats(obj);
      }
    }
  }

  getNextAndPrevDatepickerElements() {
    this.detectChange();
    const nextElm = document.getElementsByClassName("mat-calendar-next-button mat-icon-button mat-button-base");
    const prevElm = document.getElementsByClassName("mat-calendar-previous-button mat-icon-button mat-button-base");
    nextElm[0].addEventListener("click", this.getWeekNumbers);
    prevElm[0].addEventListener("click", this.getWeekNumbers);
  }

  getWeekNumbers() {

    clearTimeout(this.weekNumberTimeout);

    this.weekNumberTimeout = setTimeout(() => {

      // Retrieve year inview
      this.weekDates = [];
      const yearElm = document.getElementsByClassName("mat-calendar-period-button mat-button mat-button-base");
      const yearText = yearElm[0]['innerText'].split(' ')[1];
      const year = parseInt(yearText);

      // January 4 is always in week 1, start by creating an object containg January 4 as week 1
      const jan4 = new Date(year, 0, 4);
      let week: number = 1;
      this.weekDates.push({
        week: week,
        firstDate: moment(jan4).format('YYYY-MM-DD')
      });
      week += 1;

      // By the January 4th date we know the next week will be the next monday, calculate when the next monday from January 4 will be
      // Retrieve next monday date and place it in the object as week 2
      const dayOfWeek = jan4.getDay(); // 0 = Sunday, 1 = Monday etc
      const diff = (7 - dayOfWeek) + 1;
      const firstNextMonday = new Date(jan4.setDate(jan4.getDate() + diff));
      this.weekDates.push({
        week: week,
        firstDate: moment(firstNextMonday).format('YYYY-MM-DD')
      });
      week += 1;

      // When the first date for week 1 and 2 has been decided we now know that the next first date for each new week will be on the next monday
      // So we iterate through 53 weeks adding 7 days to obtain the date corresponding first date for each new week
      for (let i = week; i <= 53; i++) {
        const nextMonday = new Date(firstNextMonday.setDate(firstNextMonday.getDate() + 7));
        this.weekDates.push({
          week: i,
          firstDate: moment(nextMonday).format('YYYY-MM-DD')
        });
      }

      // weekDates will now contain each first date for each new week of the year

      // Retrieve month in view
      const monthElm = document.getElementsByClassName('mat-calendar-period-button mat-button mat-button-base');
      const monthText = monthElm[0]['innerText'].split(' ')[0];

      // Used for obtaining month number from text displayed in date picker
      const monthNameToNum =
        {
          'JAN.': 0,
          'FEB.': 1,
          'MARS': 2,
          'APR.': 3,
          'MAJ': 4,
          'JUNI': 5,
          'JULI': 6,
          'AUG.': 7,
          'SEP.': 8,
          'OKT.': 9,
          'NOV.': 10,
          'DEC.': 11
        };
      const monthNum = monthNameToNum[monthText];

      // Retrieve day, used for obtaining correct HTML element, retrieves each day for month/year inview
      const filteredDates = this.weekDates.filter(elm => new Date(elm.firstDate).getMonth() === monthNum && new Date(elm.firstDate).getFullYear() === year);
      const days = [];
      filteredDates.forEach(elm => {
        const day = parseInt(elm.firstDate.split('-')[2]);
        elm['day'] = day;
        days.push(day);
      });


      // Obtain all week day HTML elements
      const dayElm = document.getElementsByClassName('mat-calendar-body-cell-content');
      const firstDayElm = [];

      // Retrieve HTML elements which corresponds to a monday
      for (let i = 0; i < dayElm.length; i++) {
        if (days.indexOf(parseInt(dayElm[i]['innerText'])) !== -1) {
          firstDayElm.push(dayElm[i]);
        }
      }
      // Obtain HTML element firstDayElm will contain each HTML element corresponding a monday (new week)
      for (let i = 0; i < firstDayElm.length; i++) {
        const parent = firstDayElm[i].parentElement;
        const weekDiv = document.createElement('div');
        weekDiv.classList.add('mt-calendar-body-cell-content-weeknumber');
        weekDiv.innerText = filteredDates[i].week;
        parent.prepend(weekDiv);
      }
    }, 0);
  }


  updateForecastValues() {
    // Add days/hours values if timingscheduling should be applied
    if (this.showTimescheduling) {
      let days = Array.from(this.selectedDays, elm => elm.value);
      this.updateForecast('days', days);
    }
    this.updateForecast("start_date", this.startDateParsed);
    this.updateForecast("end_date", this.endDateParsed);

  }

  initateValues() {
    this.adTypeValues = this.globalfunctionality.getModuleValues('adtype');
    this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
    this.values = this.globalfunctionality.getModuleValues("budget");
    this.searchwordSelectedOption = this.globalfunctionality.getModuleValues('searchword') ?
      this.globalfunctionality.getModuleValues('searchword').selectedOption :
      null;
  }

  selectResponsivProduct(){
     this.apiservice.getJSON(this.store.apiURL + "/ProductsServlet?brand=" + this.campaignService.structure.get('brand').get('id').value
      + "&objective=" + this.campaignService.structure.get('objective').value.id)
      .subscribe((res: ProductResponseModel) => {
        if(res.responseCode === 200) {
          const responsiveProducts = res.data.filter(product => product['product_type'] === 'ResponsivAnnons');
          const adforsize = this.globalfunctionality.getModuleValues('adforsize');
          const filter = adforsize.selectedDeviceOption ? adforsize.selectedDeviceOption : 'all';
          const product = responsiveProducts.find(product => product['target_device'].toUpperCase() === filter.toUpperCase());
          this.productPrice = parseInt(product.pricing);
          this.campaignService.structure.get('products').setValue([product]);
          this.setProductAndFetchForecast(product['id']);
        }
      });
  }

  getProductBySelectedFormats(obj) {
    this.apiservice.postJSON(this.store.apiURL + '/DeviceServlet', obj)
      .subscribe(res => {

        this.campaignService.structure.get('products').setValue(res.data);

        let placements = [];
        res.data.forEach(elm => {
          placements = placements.concat(elm['product_placements']);
        });

        const products = res.data;
        this.store.forecast.placements = placements;
        const product = products[0];
        const pricing =
          {
            standard: product.standard_pricing,
            product: product.product_pricing
          };
        if (this.store.freeCampaign) {
          this.productPrice = pricing.standard ? pricing.standard : pricing.product;
        } else {
          this.productPrice = pricing.product;
        }

        this.setProductAndFetchForecast(product['product_id']);
      });
  }

  setProductAndFetchForecast(productId){
    this.campaignService.structure.get('selected_product').setValue(productId);
    this.store.forecast.products = [productId];
    sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
    this.updateImpression();
    this.getFrequency();
  }

  /**
   *  Get products depending on which subcontext that has been selected and depending on which format user has selected to go for
   */
  calculateAmountOfProducts() {
    const values = this.globalfunctionality.getModuleValues('context');
    // Get selected site
    values.contexts.filter(site => site.active).forEach(site => {
      site['amountOfProducts'] = 0;
      // Count amount of products selected per site to set a default budget
      site.sub_contexts.forEach(elm => {
        site['amountOfProducts'] += elm.products.filter(product => product.selected).length;
      });
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    });
  }

  setAudienceValues() {
    this.geoValues = this.globalfunctionality.getModuleValues('geo');
    this.demograpihcValues = this.globalfunctionality.getModuleValues('demographic');
    this.interestValues = this.globalfunctionality.getModuleValues('interestanddemographic');

    if (this.geoValues) {
      if (this.geoValues.selectedGeo) {
        if (this.geoValues.selectedGeo.length > 0) {
          this.selectedGeos = Array.from(this.geoValues.selectedGeo, elm => elm['name']).join("\n");
        }
      }
    }
  }

  /**
   * Will set special rules depending on track
   * e.g If takeover has been selected do not show frequency option
   */
  handleTrackSpecialCases() {
    if (this.campaignService.structure.get('objective').value.id === 1) {

      const selectedContext = this.globalfunctionality.getModuleValues('context').contexts[0];
      this.targetingBooked = selectedContext.targeting_booking;
      this.selectedSite = selectedContext.context.toUpperCase();

      // If only level 1 categories is selected enable custom frequency else disable
      if (this.targetingBooked) {
        this.disableFrequency = selectedContext.all_category_targets.filter(elm => elm.indeterminate).length > 0;
      }

      // If takeover
      if (this.adTypeValues) {
        if (this.adTypeValues.selectedOption === "2") {
          this.maxDays = 60;
          this.hideFrequency = true;
        }
      }

      this.blocketSearchWord = parseInt(this.globalfunctionality.getModuleValues('addecision').selected);
      if (this.blocketSearchWord) {
        if (this.values.guaranteedDelivery !== undefined) {
          this.guaranteedDelivery = this.values.guaranteedDelivery;
        }
        // Guanranteed delivery variable needs to be in the budget module so it can be displayed in the mail
        const budget = this.globalfunctionality.getModuleValues("budget");
        budget["guaranteedDelivery"] = this.guaranteedDelivery;
        this.globalfunctionality.setModuleValue('budget', budget);
        this.handleBlocketSearchword();
      }

    } else if (this.campaignService.structure.get('objective').value.id === 7) {
      const adforsize = this.globalfunctionality.getModuleValues("adforsize");
      const budget = this.globalfunctionality.getModuleValues("budget");

      this.guaranteedDelivery = adforsize.guaranteed;
      budget["guaranteedDelivery"] = this.guaranteedDelivery;
      this.globalfunctionality.setModuleValue('budget', budget);
    }
  }

  setDefaultValues() {
    this.check = false;
    this.minStartDate = new Date();
    this.maxDate = new Date('2020-12-31');
    this.initialStartDate = new Date();
    this.minEndDate = new Date();
    this.initialEndDate = new Date();
    this.startDateValue = new Date();
    this.endDateValue = new Date();

    // REFACTOR CODE

    this.showTimescheduling = this.values.showTimescheduling ? true : false;

    this.soonAsPossible = this.values.soon_as_possible ? true : false;

    this.store.noteText = this.values.billing_information ? this.values.billing_information : undefined;

    if (this.showTimescheduling) {
      this.selectedDays = this.values.selectedDays ? this.values.selectedDays : [];

      if (this.values.hoursSets) {
        this.hourSets = this.values.hoursSets;

        if (this.values.selected_hours) {
          this.updateForecast('hours', this.values.selected_hours);
        }
      }
    }

    this.setTimeschedulingValidation();

    if (this.values.trusted_takes_bill !== undefined) {
      this.store.trustedPartnerTakesBill = this.values.trusted_takes_bill;
    }

    if (this.values.billingPartner !== undefined) {
      this.store.billingPartner = this.values.billingPartner;
    }

    // Get module params to get the maximum and minimum values for the budget;
    this.moduleParams = this.globalfunctionality.getModuleParams("budget");

    if (this.moduleParams && (this.campaignService.structure.get('objective').value.id === 2)) {
      this.minBudget = this.moduleParams["minimum"];
    }

    // Calculate MIN budget for context RON
    if (this.campaignService.structure.get('objective').value.id === 7) {
      let adsforsizeValues = this.globalfunctionality.getModuleValues('adforsize');
      let amountOfFormats = adsforsizeValues.selected.filter(elm => elm.active).length;
      this.minBudget = 1000 * amountOfFormats;
    }

    if (this.moduleParams !== undefined) {
      const defaultValue = this.moduleParams.defaultValue;

      if (this.adTypeValues) {
        if (this.adTypeValues.selectedOption === "2") {
          defaultValue['endDateOffset'] = 9;
        }
      }
      this.minStartDate.setDate(this.minStartDate.getDate() + (defaultValue.minStartDate === undefined ? 3 : defaultValue.minStartDate));
      /*this.maxDate.setDate(this.maxDate.getDate() + (defaultValue.maxEndDate === undefined ? 30 : defaultValue.maxEndDate));*/

      this.initialStartDate.setDate(this.initialStartDate.getDate() + (defaultValue.startDateOffset === undefined ? 7 : defaultValue.startDateOffset));
      if (!this.store.freeCampaign) {
        if (!this.blocketSearchWord) {

          if (this.targetingBooked) {
            this.initialEndDate.setDate(this.initialEndDate.getDate() + 27);
          } else {
            this.initialEndDate.setDate(this.initialEndDate.getDate() + (defaultValue.endDateOffset === undefined ? 21 : defaultValue.endDateOffset));
          }

        } else {
          this.initialEndDate.setDate(this.initialStartDate.getDate() + 366);
        }
      } else {
        this.initialEndDate.setDate(this.initialEndDate.getDate() + 13);
      }

      if(this.values.start){
        const changeStartDate = moment(new Date(moment(this.values.start).format('YYYY-MM-DD'))).isAfter('2020-12-31');
        if(changeStartDate){
         this.values.start = new Date('2020-12-31');
        }
      }
      if(this.values.end){
        const changeEndDate = moment(new Date(moment(this.values.end).format('YYYY-MM-DD'))).isAfter('2020-12-31');
        if(changeEndDate){
          this.values.end = new Date('2020-12-31');
        }
      }

      this.minEndDate = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");
      this.endDateValue = this.values.end === undefined ? this.initialEndDate : moment(this.values.end).format("YYYY-MM-DD");
      this.startDateValue = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");
      this.startDateParsed = moment(this.startDateValue).format("YYYY-MM-DD");
      this.endDateParsed = moment(this.endDateValue).format("YYYY-MM-DD");
      this.days = moment(this.endDateValue).diff(moment(this.startDateValue), "days") + 1;

      // Get the default option if none was previously selected
      if (this.values.option !== undefined) {
        this.selectedOption = this.values.option;
      } else {
        this.selectedOption = defaultValue.option;
      }

      // Get the default budget if none was previously selected, depending on the option get corresponding default budget
      if (this.values.budget !== undefined && this.values.budget !== null) {
        this.budget = this.values.budget;
      } else {
        if (!this.store.freeCampaign) {
          if (this.campaignService.structure.get('objective').value.id === 2) {
            this.budget = defaultValue.budget;
          } else if (this.campaignService.structure.get('objective').value.id === 7) {
            let adsforsizeValues = this.globalfunctionality.getModuleValues('adforsize');
            let amountOfFormats = adsforsizeValues.selected.filter(elm => elm.active).length;
            this.budget = 1000 * amountOfFormats;
            this.minBudget = 1000 * amountOfFormats;
          }
        } else {
          this.budget = 3300;
        }
      }

      this.budgetControl.setValue(this.budget);
      this.sliderControl.setValue(this.budget);


      if (this.values.impressions !== undefined && this.values.impressions !== null) {
        this.impressions = this.values.impressions;
      }
    }
    this.minStartDate = this.campaignService.calculateMinStartDate(this.minStartDate);

    /**
     * If its a copied campaign check if dates needs to be adjusted or if any interest has been removed
     */
    if (this.campaignService.structure.get('config').get('copy').value) {
      this.handleCopyCampaign();
    }


    // Present selected values
    const adsforsizeValues = this.globalfunctionality.getModuleValues('adforsize');
    this.mobileProductChosen = adsforsizeValues.selected.filter(elm => elm.active && elm.device === 'Mobile').length > 0;
    this.desktopProductChosen = adsforsizeValues.selected.filter(elm => elm.active && elm.device === 'Desktop').length > 0;

  }


  handleCopyCampaign() {
    this.checkIfWrongStartDate();
    this.checkIfInterestStillExists();
  }

  checkIfWrongStartDate() {
    const wrongStartDate = (moment(new Date(this.startDateValue)).diff(this.minStartDate, 'days') < 0) ? true : false;
    if (wrongStartDate) {
      this.campaignDateModified = true;

      this.startDateValue = moment(this.initialStartDate).format("YYYY-MM-DD");
      this.endDateValue = moment(this.initialStartDate.setDate(this.initialStartDate.getDate() + Number(this.days - 1))).format("YYYY-MM-DD");
      this.startDateParsed = moment(this.startDateValue).format("YYYY-MM-DD");
      this.endDateParsed = moment(this.endDateValue).format("YYYY-MM-DD");

      if(this.startDateValue ){
        const changeStartDate = moment(new Date(moment( this.startDateValue ).format('YYYY-MM-DD'))).isAfter('2020-12-31');
        if(changeStartDate){
          this.startDateValue  = new Date('2020-12-31');
        }
      }
      if( this.endDateValue ){
        const changeEndDate = moment(new Date(moment(this.endDateValue).format('YYYY-MM-DD'))).isAfter('2020-12-31');
        if(changeEndDate){
          this.endDateValue = new Date('2020-12-31');
        }
      }
    }
  }

  checkIfInterestStillExists() {
    // Check if interest still exists
    if (this.campaignService.structure.get('objective').value.id === 2) {
      const selectedInterest = this.globalfunctionality.getModuleValues('interestanddemographic').selectedInterest;

      if (selectedInterest) {
        const targets = this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet?brand=" + this.campaignService.structure.get('brand').get('id').value);
        const interests = targets.pipe(
          pluck('data', 'interests')
        );

        interests.subscribe(interests => {
          from(interests).pipe(
            pluck('target_option'),
            filter(target_option => target_option === selectedInterest),
            defaultIfEmpty(false)
          ).subscribe(selected => {
              if (!selected) {
                // remove selectedInterest from module value/forecast, display to the user that interest has been removed.
                this.removedInterest = selectedInterest;
                let obj = {
                  isComplete: true,
                  selectedOption: '0'
                };

                this.globalfunctionality.setModuleValue('interestanddemographic', obj);
                sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

                this.interestValues = obj;
              }
            }
          );
        });
      }
    }
  }

  openDeliveryInfoModal() {
    this.dialog.open(DeliveryInfoDialogComponent,
      {
        panelClass: ['modal-size-md']
      });
  }

  handleBlocketSearchword() {
    const searchword = this.globalfunctionality.getModuleValues('searchword');
    this.searchwordObj = {};
    this.searchwordObj.freetext = searchword.freetext;
    this.searchwordObj.geography = searchword.geography;

    if (searchword.category) {
      this.searchwordObj.category = searchword.category.selectedCategories;
    }

    if (this.searchwordObj.geography) {
      if (this.searchwordObj.geography.selectedGeos) {
        if (this.searchwordObj.geography.selectedGeos.length) {
          this.selectedGeos = Array.from(this.searchwordObj.geography.selectedGeos, elm => elm['target_option']).join("\n");
        }
      }
    }

    if (this.searchwordObj.freetext) {
      if (this.searchwordObj.freetext.selectedFreeText) {
        if (this.searchwordObj.freetext.selectedFreeText.length) {
          this.selectedFreetext = Array.from(this.searchwordObj.freetext.selectedFreeText, elm => elm).join("\n");
        }
      }
    }

    // Get category structure for being able to print selected categories
    let selectedContentCategory = searchword.category;
    let categoryStructure = [];
    if (selectedContentCategory) {
      if (selectedContentCategory.selectedCategories) {
        selectedContentCategory.selectedCategories.forEach(cat => {
          cat['selected_subcategories'] = [];
          categoryStructure.push(cat);
        });
      }

      if (categoryStructure.length) {

        categoryStructure.forEach(cat => {
          selectedContentCategory.selectedSubCategories.forEach(sub => {
            if (cat.target_option === sub.categoryName) {
              sub['selected_productcategories'] = [];
              cat['selected_subcategories'].push(sub);
            }
          });
          if (cat['selected_subcategories'] && cat.children) {
            if (cat['selected_subcategories'].length !== cat.children.length) {
              cat['showSub'] = true;
              // cat['children'] = cat['selected_subcategories'];
            }
          }
        });

        categoryStructure.forEach(cat => {
          cat['selected_subcategories'].forEach(sub => {
            selectedContentCategory.selectedProductCategories.forEach(prod => {
              if (sub.target_option === prod.subCategoryName) {
                sub['selected_productcategories'].push(prod);
              }
            });

            if (sub.children) {
              if (sub['selected_productcategories'].length !== sub.children.length) {
                // sub['children'] = sub['selected_productcategories'];
                // cat['children'] = cat['selected_subcategories'];
                sub['showProduct'] = true;
                cat['showProduct'] = true;
                cat['showSub'] = true;
              }
            }
          });
        });
      }

      if (categoryStructure.length) {
        this.selectedCategory = '';
        categoryStructure.forEach(cat => {
          this.selectedCategory += cat.target_option;
          if (cat.selected_subcategories.length) {
            this.selectedCategory += ': \n';
            cat.selected_subcategories.forEach(sub => {
              this.selectedCategory += '\t' + sub.target_option;
              if (sub.selected_productcategories.length) {
                this.selectedCategory += '\n\t\t';
                sub.selected_productcategories.forEach((prod, index) => {
                  if (index) {
                    this.selectedCategory += ', ';
                  }
                  this.selectedCategory += prod.target_option;
                });
              }
              this.selectedCategory += '\n';
            });
          }
          this.selectedCategory += '\n\n';
        });
      }
    }


    this.maxDays = 367;
  }


  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.hour === o2.hour;
    }
  }

  getSelectedAdType(): string {
    let adtypeValues = this.globalfunctionality.getModuleValues('adtype');
    let type = 'BannerAnnons';
    if (adtypeValues) {
      if (adtypeValues.selectedType) {
        type = adtypeValues.selectedType;
      }
    }
    return type;
  }

  getSelectedFormats() {
    const values = this.globalfunctionality.getModuleValues('adforsize');
    const selectedFormats = [];
    values.selected.forEach(elm => {
      if ((elm.add_later || elm.complete) && elm.active) {
        selectedFormats.push(elm.format);
      }
    });
    const obj =
      {
        formats: selectedFormats,
        objectives: [this.campaignService.structure.get('objective').value.id],
        brand: [this.campaignService.structure.get('brand').get('id').value],
        freeCampaign: this.store.freeCampaign,
        guaranteed: this.guaranteedDelivery,
        productType: this.getSelectedAdType(),
        advertisementCategory: values.formatType
      };
    return obj;
  }

  getFrequency() {

    const values = this.globalfunctionality.getModuleValues("budget");

    // Get frequency values
    this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet")
      .subscribe(res => {
        this.frequencyList = res.data.frequency;
        if (res.data.days) {
          // Create days object
          res.data.days.forEach(elm => {
            let obj =
              {
                value: elm,
                selected: false
              };
            this.daysList.push(obj);
          });

          // If any has earlier been selected check them
          this.daysList.forEach(elm => {
            this.selectedDays.forEach(sel => {
              if (elm.value === sel.value) {
                elm.selected = true;
              }
            });
          });
          this.checkIfDaysShouldBeExcluded();
        }

        if (res.data.hours) {
          this.hoursList = res.data.hours;
          this.startHourList = [];
          this.endHourList = [];
          this.hoursList.forEach((elm, index) => {
            let sobj = {};
            let eobj = {};
            let aux = elm.split('-');
            let s = aux[0].trim();
            let e = aux[1].trim();
            sobj['hour'] = s;
            sobj['i'] = index;
            eobj['hour'] = moment(e, 'HH:mm').add(1, 'm').format('HH:mm') === '00:00' ? '24:00' : moment(e, 'HH:mm').add(1, 'm').format('HH:mm');
            eobj['i'] = index;
            this.startHourList.push(sobj);
            this.endHourList.push(eobj);
          });

          // If values has been selected check the corresponding value used for the forecast
          // Also check which hoursets that are complete, this function will set the disable attribute within a entry
          if (this.hourSets) {
            this.getHourValue(true);
            this.hourSets.forEach(elm => this.checkIfSetComplete(elm, true));

            // Set selected hours as occupied if any are
            this.setHoursAsOccupied();
          }
        }
        if (values.selectedFreqOption !== undefined) {
          this.selectedFreqOption = values.selectedFreqOption;
          this.freqAmount = values.freqAmount;
        } else {
          this.selectedFreqOption = this.freqOptions[0];
          this.freqAmount = this.frequencyList[this.selectedFreqOption.value][4];
        }
        if (values.freqDecisionOption !== undefined) {
          if (['2', '3'].indexOf('' + this.searchwordSelectedOption) > -1 &&
            values.freqDecisionOption !== 'standard'
          ) {
            this.freqDecisionOption = 'standard';
            this.changeFreqDecisionOption();
          } else {
            this.freqDecisionOption = values.freqDecisionOption;
          }
        }
        // If takeover selected set frequency to 1 per day
        if (this.campaignService.structure.get('objective').value.id === 1) {
          if (this.adTypeValues) {
            if (this.adTypeValues.selectedOption === "2") {
              this.freqAmount = this.frequencyList[this.selectedFreqOption.value][0];
            }
          }
        }
        values['selectedFreqOption'] = this.selectedFreqOption;
        values['freqDecisionOption'] = this.freqDecisionOption;
        values['freqAmount'] = this.freqAmount;
        this.globalfunctionality.setModuleValue('budget', values);

        if (this.freqDecisionOption === 'standard') {
          this.updateForecast("frequency", null);
        } else {
          this.updateForecast("frequency", this.freqAmount['key']);
        }

        this.detectChange();

        if (this.campaignService.structure.get('objective').value.id === 1) {
          // Sets default budget value
          const values = this.globalfunctionality.getModuleValues('context');
          values.contexts.filter(site => site.active).forEach(site => {
            // If total budget exists we know that this component has been intialized
            if (this.adTypeValues.selectedOption !== "2") {
              this.productMinBudget = 1000;
              if (!site.total_budget) {
                this.budget = this.productMinBudget * site['amountOfProducts'];
                site['min_budget'] = this.budget; // Min budget for site
              }
              site['min_product_budget'] = this.productMinBudget;
            } else if (this.adTypeValues.selectedOption === "2") {
              this.productMinBudget = 5000;
              if (!site.total_budget) {
                this.budget = this.productMinBudget * site['amountOfProducts'];
                site['min_budget'] = this.budget;
                site['min_product_budget'] = this.productMinBudget;
              }
            }
          });
          this.budgetControl.setValue(this.budget);
          this.globalfunctionality.setModuleValue('context', values);

          this.loadForecastAndSetPageToIncomplete();

          this.globalfunctionality.checkContextForecast((context) => {
            this.calculateTotalInventory(context, true);
          });

        } else if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
          this.triggerForecast();
        }
      });
  }

  loadForecastAndSetPageToIncomplete(){
    this.loadForecast = true;
    this.campaignService.page4Complete = false;
  }

  calculateTotalInventory(context, distributeBudget, byPass?) {

    let selectedProducts: number = 0;
    let completeProducts: number = 0;

    // Calc amount of selected products
    context.contexts.filter(elm => elm.active).forEach(site => {

      site.sub_contexts.filter(subContext => subContext.selected).forEach(subContext => {
        selectedProducts += subContext.products.filter(product => product.selected).length;
      });

      // Calc amount of completed products (forecast completed)
      site.sub_contexts.filter(subContext => subContext.selected).forEach(subContext => {
        completeProducts += subContext.products.filter(product => product.selected && product.forecastComplete).length;
      });

    });

    // When all forecasts are complete calculate budget
    if (selectedProducts === completeProducts || byPass) {
      // Reset forecast complete variable after count, so that if new forecast is made count starts from 0
      context.contexts.filter(elm => elm.active).forEach(site => {
        site.sub_contexts.filter(subContext => subContext.selected).forEach(subContext => {
          subContext.products.filter(product => product.selected && product.forecastComplete).forEach(product => {
            product.forecastComplete = false;
          });
        });
      });

      this.calculateImpressions(context, distributeBudget);
    }
  }


  calculateImpressions(context, distributeBudget) {

    this.loadForecast = false;

    context.contexts.filter(site => site.active).forEach(site => {
      site['amountOfProducts'] = 0;
      site.sub_contexts.forEach(elm => {
        site['amountOfProducts'] += elm.products.filter(product => product.selected).length;
      });

      if (!site.total_budget) {
        if (this.adTypeValues.selectedOption === "0") {
          this.budget = this.productMinBudget * site['amountOfProducts'];
          site['min_budget'] = this.budget; // Min budget for site
          site['min_product_budget'] = this.productMinBudget;
        } else if (this.adTypeValues.selectedOption === "2") {
          this.productMinBudget = 5000;
          this.budget = this.productMinBudget * site['amountOfProducts'];
          site['min_budget'] = this.budget; // Min budget for site
          site['min_product_budget'] = this.productMinBudget;
        }
        this.budgetControl.setValue(this.budget);
      }
    });

    let maxBudget: number = 0;
    context.contexts.filter(site => site.active).forEach(site => {
      site.sub_contexts.forEach(subContext => {
        subContext.products.filter(product => product.selected).forEach(product => {
          maxBudget += product['maxBudget'];
        });
      });
    });

    this.forecastResultObj.max_budget = maxBudget;

    if (this.blocketSearchWord === 1) {
      this.blocketSearchWordProduct = context.contexts[0].sub_contexts[0].products[0];
    }

    const validation = this.budgetControl.valid;
    if (validation && distributeBudget && !this.blocketSearchWord) {
      this.distributionOccured = true;
      context = this.globalfunctionality.getBudgets(context, this.budget, this.productMinBudget, {
        mobile: 0.5,
        desktop: 0.5
      }, 1);
    } else if (this.blocketSearchWord) { // Blocket searchword
      context.contexts[0].sub_contexts.forEach(sub => {
        const imps = Math.round(((this.budgetControl.value / this.blocketSearchWordProduct.pricing) * 1000));
        sub['total_impressions_for_subcontext'] = imps;
        sub['total_budget_for_subcontext'] = this.budgetControl.value;
        sub.products.forEach(prod => {
          prod['budget'] = this.budgetControl.value;
          prod['impressions'] = imps;
        });
      });
    } else {
      this.distributionOccured = false;
    }

    let available = 0;
    let capacity = 0;
    let totalBudget = 0;
    let totalImps = 0;
    maxBudget = 0;
    let minBudget = 0;



    context.contexts.filter(site => site.active).forEach(site => {
      this.selectedContext = site;
      minBudget = site['min_product_budget'];
      site.sub_contexts.filter(subContext => subContext.selected).forEach(subContext => {
        subContext.products.filter(product => product.selected).forEach(product => {
          product['budget'] = !product['budget'] ? minBudget : product['budget'];
          available += product['available'];
          capacity += product['capacity'];
          totalBudget += product['budget'];
          totalImps += (product['budget'] / parseInt(product['pricing'])) * 1000;
          maxBudget += product['maxBudget'];
          site['maxBudget'] = maxBudget;
        });
      });
      site['total_budget'] = totalBudget;
      this.minBudget = site['amountOfProducts'] * this.productMinBudget;
    });

    const availablePercentage = Math.max((available) / capacity, 0);
    const unavailablePercentage = 1 - availablePercentage;
    const bookedPercentage = totalImps / capacity;

    const values = this.globalfunctionality.getModuleValues('context');
    values['contexts'] = context['contexts'];
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

    this.forecastResultObj =
      {
        max_budget: maxBudget,
        forecast_approved: true,
        percentage: {
          unavailable: isNaN(Math.round(unavailablePercentage * 100)) ? 0 : Math.round(unavailablePercentage * 100),
          available: isNaN(Math.round(availablePercentage * 100)) ? 0 : Math.round(Math.round(availablePercentage * 100)),
          booked: isNaN(Math.round(bookedPercentage * 100)) ? 0 : Math.round(Math.round(bookedPercentage * 100))
        },
        impressions: {
          available: Math.floor(Math.max(available, 0)),
          unavailable: Math.floor(capacity - available),
          capacity: capacity,
          selected: Math.floor(totalImps)
        }
      };

    this.setBudgetValidation();
    this.checkIfErrorExists(context);
    this.checkIfComplete();
    this.detectChange();
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  checkIfErrorExists(context) {

    const selectedSubContexts = context.contexts.filter(elm => elm.active)[0].sub_contexts.filter(elm => elm.selected);
    this.contextHasError = false;
    selectedSubContexts.forEach(subcontext => {
      let hasError: boolean = false;
      subcontext.products.filter(prod => prod.selected).forEach(prod => {

        if (prod.maxBudget === 0) {
          // If any product got 0 max-budget, decrease the minimum budget
          this.minBudget -= this.productMinBudget;

          // Prevent min budget to be set to 0
          if (this.minBudget > 0) {
            this.budgetControl.clearValidators();

            /*IF NON GUARANTEED, REMOVE MAX VALIDATOR*/
            if (this.guaranteedDelivery) {
              this.budgetControl = new FormControl(this.budget, [
                Validators.required,
                Validators.min(this.minBudget),
                Validators.max(this.forecastResultObj.max_budget)
              ]);
            } else {
              this.budgetControl = new FormControl(this.budget, [
                Validators.required,
                Validators.min(this.minBudget)
              ]);
            }
          }

          prod['disabled'] = true;
          hasError = true;
          this.contextHasError = true;
        } else {
          prod['disabled'] = false;
        }
      });
      subcontext['hasError'] = hasError;
    });
  }

  changeBudgetSplit() {
    if (!this.budgetControl.errors) {
      const dialog = this.dialog.open(BudgetSplitDialogComponent,
        {
          panelClass: ['modal-size-xl', 'modal-height-control'],
          data: this.productMinBudget
        });
      dialog.afterClosed()
        .subscribe((save) => {
          if (save) {
            const context = this.globalfunctionality.getModuleValues('context');
            this.budget = context.contexts[0].total_budget;
            this.budgetControl.setValue(this.budget);
            this.calculateImpressions(context, false);
            this.checkIfComplete();
            this.detectChange();
          }
        });
    } else {
      this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'OBS!',
          desc: 'Det finns inte tillräckligt med annonsvisningar i lager för den valda perioden. Försök ändra kampanjperiod.',
          confirm_btn: 'OK'
        }
      });
      this.budgetControl.markAsTouched();
    }
  }

  placeCustomButton(startdate) {
    if (this.store.user.companies[0].status.toLowerCase() === 'approved') {
      let materialAdded = this.globalfunctionality.getModuleValues('adforsize')['isComplete'];
      let result = this.campaignService.setSoonAsPossibleButton(startdate, materialAdded);
      if (result.addBtn) {
        result.btnElm.onclick = () => {
          this.setSoonAsPossible(startdate);
        };
      }
    }
  }

  setSoonAsPossible(startdate) {
    this.soonAsPossible = true;
    this.startDateValue = this.minStartDate;
    let values = this.globalfunctionality.getModuleValues("budget");
    values['soon_as_possible'] = true;
    startdate.close();
    this.changeStartDate(true);
  }

  changeSlider() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.budget = Math.floor(this.sliderControl.value);
      this.budgetControl.setValue(this.budget);
      this.updateBudget();
    }, 50);
  }

  setBudgetValidation() {
    if (!this.store.freeCampaign) {
      if (this.guaranteedDelivery) {
        this.sliderControl.enable();
        this.budgetControl = new FormControl(this.budget, [
          Validators.required,
          Validators.min(this.minBudget),
          Validators.max(this.forecastResultObj.max_budget)
        ]);
      } else {
        this.budgetControl = new FormControl(this.budget, [
          Validators.required,
          Validators.min(this.minBudget)
        ]);
        this.sliderControl.disable();
      }

    } else {
      this.budgetControl = new FormControl({value: 3300, disabled: true}, [
        Validators.required,
        Validators.min(this.minBudget),
        Validators.max(this.forecastResultObj.max_budget)
      ]);
      this.sliderControl = new FormControl({value: 3300, disabled: true},);
    }

    /*this.budgetControl.updateValueAndValidity();*/
    this.budgetControl.markAsTouched();
  }


  checkFreqForecast(standard?) {

    if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
      this.store.forecast['frequency'] = standard ? [] : [this.freqAmount['key']];

      /**
       * Run forecast only if guaranteed delivery, set frequency in forecast object if user swaps choice
       */
      if (this.guaranteedDelivery) {
        this.loadForecastAndSetPageToIncomplete();
        this.globalfunctionality.checkForecast((res: ForecastResultObjModel) => {

          this.forecastResultObj = res;

          // Save forecast value in budget module for being able to decide which pages that are complete when reloading page
          const budgetModule = this.globalfunctionality.getModuleValues("budget");
          if (budgetModule) {
            budgetModule["forecast_approved"] = this.forecastResultObj.forecast_approved;
            sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
          }

          this.loadForecast = false;
          this.setBudgetValidation();
          this.checkIfComplete();
          this.detectChange();
          this.globalfunctionality.page4Checker.next(true);
        }, this.productPrice);
      }
    } else {

      if (this.freqDecisionOption === 'standard') {
        this.updateForecast("frequency", null);
      } else {
        this.updateForecast("frequency", this.freqAmount['key']);
      }

      /**
       * Run forecast only if guaranteed delivery, set frequency in forecast object if user swaps choice
       */
      if (this.guaranteedDelivery) {
        this.loadForecastAndSetPageToIncomplete();
        this.globalfunctionality.checkContextForecast((context) => {
          this.calculateTotalInventory(context, true);
        });
      }
    }
  }


  compareOptions(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.name === o2.name && o1.value === o2.value;
    }
  }

  compareAmounts(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.index === o2.index && o1.key === o2.key;
    }
  }


  selectFreqAmount() {
    let values = this.globalfunctionality.getModuleValues("budget");
    this.store.forecast['frequency'] = [this.freqAmount['key']];
    values['freqAmount'] = this.freqAmount;
    this.checkFreqForecast();
  }


  /**
   * ATM only used for blocket search word = uses context forecast
   */
  changeDeliveryOption() {
    const values = this.globalfunctionality.getModuleValues('budget');
    values['guaranteedDelivery'] = this.guaranteedDelivery;
    this.globalfunctionality.setModuleValue('budget', values);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

    if (this.guaranteedDelivery) {
      this.loadForecastAndSetPageToIncomplete();
      this.globalfunctionality.checkContextForecast((context) => {
        this.calculateTotalInventory(context, true);
      });
    } else {
      this.setBudgetValidation();
      this.checkIfComplete();
    }

  }

  changeFreqDecisionOption() {
    const values = this.globalfunctionality.getModuleValues("budget");
    if (this.freqDecisionOption === 'custom') {
      // Add frequency to forecast object
      this.store.forecast['frequency'] = [this.freqAmount['key']];
      values['freqDecisionOption'] = this.freqDecisionOption;
      values['selectedFreqOption'] = this.selectedFreqOption;
      values['freqAmount'] = this.freqAmount;
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.checkFreqForecast();
    } else {
      this.selectedFreqOption = this.freqOptions[0];
      this.freqAmount = this.frequencyList[this.selectedFreqOption.value][4];
      values['freqDecisionOption'] = this.freqDecisionOption;
      values['selectedFreqOption'] = this.selectedFreqOption;
      if (!this.blocketSearchWord) {
        values['freqAmount'] = this.freqAmount;
      }
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.checkFreqForecast(true);
    }

  }


  selectFreqOption() {
    let values = this.globalfunctionality.getModuleValues("budget");
    // Set same amount, or set the highest possible, e.g life 10 was chosen - switch to day and pick 5 days because 10 does not exist on days
    // e.g life 3 was chosen - switch to day and have the same amount chosen
    let setDefault = true;
    this.frequencyList[this.selectedFreqOption.value].forEach(elm => {
      if (elm.index === this.freqAmount.index) {
        this.freqAmount = elm;
        setDefault = false;
      }
    });
    if (setDefault) {
      let length = this.frequencyList[this.selectedFreqOption.value].length;
      this.freqAmount = this.frequencyList[this.selectedFreqOption.value][length - 1];
    }
    values['freqAmount'] = this.freqAmount;
    values['selectedFreqOption'] = this.selectedFreqOption;
    this.checkFreqForecast();
  }

  /**
   * Calculates impression value (local product) so that backend can calculate if the forecast succeds or not
   */
  updateImpression() {
    if (this.budget === undefined) {
      this.budget = 0;
    }
    this.updateForecast("impressions", Math.round(((this.budget / this.productPrice) * 1000)));
    this.forecastResultObj.impressions.selected = parseInt(this.store.forecast.impressions);
  }


  updateBudget() {

    if (isNaN(this.budgetControl.value)) {
      this.budgetControl.setValue(null);
    }

    this.budget = this.budgetControl.value;

    this.sliderControl.setValue(this.budget);

    if (!this.guaranteedDelivery) {
      if (this.budget === undefined) {
        this.budget = 0;
      }
      if (!this.blocketSearchWord) {
        this.updateForecast("impressions", Math.round(((this.budget / this.productPrice) * 1000)));
        this.forecastResultObj.impressions.selected = parseInt(this.store.forecast.impressions);
        this.checkIfComplete();
      } else {
        const context = this.globalfunctionality.getModuleValues('context');
        this.calculateTotalInventory(context, true);
      }
    } else {

      const validation: boolean = !this.budgetControl.hasError('min') && !this.budgetControl.hasError('max') && !this.budgetControl.hasError('required');

      if (validation) {
        if (this.timer !== undefined) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
            this.updateForecast("impressions", Math.round(((this.budget / this.productPrice) * 1000)));

            this.updateBookedData();

            this.checkIfComplete();
          } else if (this.campaignService.structure.get('objective').value.id === 1) {

            const context = this.globalfunctionality.getModuleValues('context');
            context.contexts[0].total_budget = this.budgetControl.value;
            this.calculateTotalInventory(context, true, true);
          }
        }, 100);
      } else {
        this.budgetControl.markAsTouched();
        this.checkIfComplete();
      }
    }
  }

  /**
   * Updates the UI with the new booked values, whenn budget changes the amount of impressions booked is changed
   */
  updateBookedData() {
    this.forecastResultObj.impressions.selected = parseInt(this.store.forecast.impressions);
    const {available, capacity} = this.forecastResultObj.impressions;
    const availablePercentage = Math.max((available) / capacity, 0);
    const unavailablePercentage = 1 - availablePercentage;
    const bookedPercentage = parseInt(this.store.forecast.impressions) / capacity;

    this.forecastResultObj.percentage.available = isNaN(Math.max(Math.round(availablePercentage * 100 - bookedPercentage * 100), 0)) ? 0 : Math.max(Math.round(availablePercentage * 100 - bookedPercentage * 100), 0);
    this.forecastResultObj.percentage.booked = isNaN(Math.round(Math.min(bookedPercentage * 100, 100 - (unavailablePercentage * 100)))) ? 0 : Math.round(Math.min(bookedPercentage * 100, 100 - (unavailablePercentage * 100)));
    this.detectChange();
  }


  openMailDialog() {
    this._create.openMailDialog();
  }

  checkIfComplete() {

    const values = this.globalfunctionality.getModuleValues('budget');
    if (values) {
      this.wrongStartDate = (moment(new Date(this.startDateValue)).diff(this.minStartDate, 'days') < 0) ? true : false;

      const validation: boolean = this.getValidationRules();

      if (validation) {
        if (!this.guaranteedDelivery) {
          this.forecastResultObj.forecast_approved = true;
        }
        if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {
          this.setValues(values, this.forecastResultObj.forecast_approved);
        }
      } else {
        this.setValues(values, false);
      }

      this.globalfunctionality.setModuleValue('budget', values);
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

      this.globalfunctionality.page4Checker.next(true);
    }
  }

  setValues(values, complete) {
    if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
      values.impressions = Math.round((this.budget / this.productPrice) * 1000);
    }
    values.budget = this.budget;
    values.start = moment(this.startDateParsed).format("YYYY-MM-DD hh:mm:ss");
    values.end = moment(this.endDateParsed).format("YYYY-MM-DD hh:mm:ss");
    values.option = this.selectedOption;
    values.days = this.days;
    values.isComplete = complete;


    if (this.store.GUIFlow[4]) {
      this.store.GUIFlow[4].forEach((elm) => {
        if (elm.type === "budget") {
          elm["module_values"].isComplete = complete;
        }
      });
    }

  }

  getValidationRules(): boolean {
    if (this.blocketSearchWord === 1 && !this.guaranteedDelivery) {
      return !this.budgetControl.hasError("min") &&
        !this.budgetControl.hasError("required") && this.startDateValue !== null && this.endDateValue !== null && ((this.days) <= this.maxDays
          && !this.wrongStartDate);
    } else {
      return !this.budgetControl.hasError("min") && !this.budgetControl.hasError("max") &&
        !this.budgetControl.hasError("required") && this.startDateValue !== null && this.endDateValue !== null && ((this.days) <= this.maxDays
          && !this.wrongStartDate);
    }
  }

  // TODO PLACE FUNCTIONS IN GLOBAL FUNCTIONALITY USED IN BUDGET COMPONENT ASWELL
  saveBillingInfo() {
    let budget = this.globalfunctionality.getModuleValues("budget");

    if (this.store.noteText === "") {
      this.store.noteText = undefined;
    }

    budget['billing_information'] = this.store.noteText;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  checkValue(value) {
    this.store.trustedPartnerTakesBill = value;
    let budget = this.globalfunctionality.getModuleValues("budget");
    budget['trusted_takes_bill'] = this.store.trustedPartnerTakesBill;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }


  setBillingPartner(value) {
    this.store.billingPartner = value;
    let budget = this.globalfunctionality.getModuleValues("budget");
    budget['billing_partner'] = this.store.billingPartner;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  // TODO PLACE FUNCTIONS IN GLOBAL FUNCTIONALITY USED IN BUDGET COMPONENT ASWELL

  updateForecast(field, value) {

    if (this.campaignService.structure.get('objective').value.id === 1) {
      switch (field) {
        case "start_date":
          this.store.contextForecast.date_from = value;
          break;
        case "end_date":
          this.store.contextForecast.date_to = value;
          break;
        case "frequency":
          this.store.contextForecast.frequency = value === null ? [] : [value];
          break;
        case "days":
          this.store.contextForecast.days = value;
          break;
        case "hours":
          this.store.contextForecast.hours = value;
          break;
        default:
      }
    } else if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
      switch (field) {
        case "start_date":
          this.store.forecast.date_from = value;
          break;
        case "end_date":
          this.store.forecast.date_to = value;
          break;
        case "impressions":
          this.store.forecast.impressions = value;
          break;
        case "frequency":
          this.store.forecast.frequency = value === null ? [] : [value];
          break;
        case "days":
          this.store.forecast.days = value;
          break;
        case "hours":
          this.store.forecast.hours = value;
          break;
        default:
      }
    }
  }

  changeStartDate(soonAsPossible?) {
    let values = this.globalfunctionality.getModuleValues("budget");
    if (!soonAsPossible) {
      // If any start date has been chose, reset soon as possible variable
      this.soonAsPossible = false;
      values['soon_as_possible'] = false;
    }
    if (this.startDateValue !== null && this.endDateValue !== null) {
      if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {
        this.minEndDate = this.startDateValue;
        if (moment(this.startDateValue).format("YYYY-MM-DD") > moment(this.endDateValue).format("YYYY-MM-DD")) {
          this.endDateValue = this.startDateValue;
          values.end = moment(this.endDateValue).format("YYYY-MM-DD");
          this.endDateParsed = moment(this.endDateValue).format("YYYY-MM-DD");
        }
        values.start = moment(this.startDateValue).format("YYYY-MM-DD hh:mm:ss");
        this.startDateValue = moment(this.startDateValue).format("YYYY-MM-DD");
        this.startDateParsed = this.startDateValue;

        // Forecast
        this.updateForecast('start_date', this.startDateParsed);
        this.updateForecast('end_date', this.endDateParsed);
        this.triggerForecast();

        this.calculateDays();
        this.saveToLocalStorage();
      }
    }

  }

  changeEndDate() {
    let module = this.globalfunctionality.getModule("budget");
    let values = module["module_values"];

    if (this.startDateValue !== null && this.endDateValue !== null) {
      if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {

        values.end = moment(this.endDateValue).format("YYYY-MM-DD hh:mm:ss");
        this.endDateValue = moment(this.endDateValue).format("YYYY-MM-DD");
        this.endDateParsed = this.endDateValue;

        // Forecast
        this.updateForecast('start_date', this.startDateParsed);
        this.updateForecast('end_date', this.endDateParsed);
        this.triggerForecast();
        this.calculateDays();
        this.saveToLocalStorage();
      }
    }
  }


  triggerForecast() {
    if (this.guaranteedDelivery) {
      // If timescheduling is active, send 'All' if no days has been selected
      if (this.showTimescheduling) {
        if (this.selectedDays.length === 0 || this.selectedDays.length === 7) {
          this.updateForecast('days', ['All']);
        }
      }

      if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {
        this.loadForecastAndSetPageToIncomplete();
        this.globalfunctionality.checkForecast((res: ForecastResultObjModel) => {
          this.forecastResultObj = res;
          this.loadForecast = false;
          this.setBudgetValidation();
          this.detectChange();
          this.checkIfComplete();
        }, this.productPrice);
      } else if (this.campaignService.structure.get('objective').value.id === 1) {
        this.loadForecastAndSetPageToIncomplete();
        this.globalfunctionality.checkContextForecast((context) => {
          this.calculateTotalInventory(context, true);
        });
      }
    } else {
      this.checkIfComplete();
      this.loadForecast = false;
    }
  }

  calculateDays() {
    let module = this.globalfunctionality.getModule("budget");
    let values = module["module_values"];
    const start = moment(this.startDateValue);
    const end = moment(this.endDateValue);
    this.days = end.diff(start, "days") + 1;
    // Trigger function when time scheduling is active
    if (this.showTimescheduling) {
      this.checkIfDaysShouldBeExcluded();
    }
    // Update impression when days has changed
    /*  this.updateImpression();*/
    values["days"] = this.days;
    this.checkIfComplete();
  }

  saveToLocalStorage() {
    this.detectChange();
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  checkWidth(className) {
    return $("." + className).width() < 27;
  }


  removeHourSet(index) {
    // Remove occupied intervals if interval is removed
    if (this.hourSets[index]['complete']) {
      let elm = this.hourSets[index];
      let sIndex = elm.startHour.i;
      let eIndex = elm.endHour.i;

      for (let i = sIndex; i <= eIndex; i++) {
        this.startHourList[i]['occupied'] = false;
        this.endHourList[i]['occupied'] = false;
      }
    }
    // remove all occupied if only 1 interval left
    if (this.hourSets.length === 2) {
      this.startHourList.forEach(elm => {
        elm['occupied'] = false;
        /* elm['disabled'] = false;*/
      });
      this.endHourList.forEach(elm => {
        elm['occupied'] = false;
        /* elm['disabled'] = false;*/
      });
    }
    this.hourSets.splice(index, 1);
    this.setTimeschedulingValidation();
    this.checkIfSetComplete(null, false);
  }

  /**
   * Will only set hours as occupied if there is more than 1 hour set
   */
  setHoursAsOccupied() {
    if (this.hourSets.length > 1) {
      this.hourSets.forEach(elm => {
        if (elm['complete']) {
          // When interval has been chosen, disable that particular interval
          let sIndex = elm.startHour.i;
          let eIndex = elm.endHour.i;
          for (let i = sIndex; i <= eIndex; i++) {
            this.startHourList[i]['occupied'] = true;
            this.endHourList[i]['occupied'] = true;
          }
        }
      });
    }
    // Remove all disable intervals, should be replaced by the occupied intervals
    this.startHourList.forEach(elm => {
      if (!elm.occupied) {
        elm.disabled = undefined;
      }
    });
  }

  setTimeschedulingValidation() {
    this.form.timeschedulingStarthours = new FormGroup({});
    this.form.timeschedulingEndhours = new FormGroup({});
    this.hourSets.forEach((elm, index) => {
      this.form.timeschedulingStarthours.addControl(index.toString(), new FormControl(elm.startHour, [
        Validators.required
      ]));
      this.form.timeschedulingEndhours.addControl(index.toString(), new FormControl(elm.endHour, [
        Validators.required
      ]));
    });
  }

  /**
   * Adds an empty hour set, and checks if any hours are occupied and if the set is complete
   */
  addHourSet() {
    // Add a hourSet, before setting previous as occupied
    this.hourSets.push(
      {
        startHour: undefined,
        endHour: undefined,
      }
    );
    this.setTimeschedulingValidation();
    this.setHoursAsOccupied();
  }


  toggleTimescheduling() {
    let values = this.globalfunctionality.getModuleValues('budget');
    this.showTimescheduling = !this.showTimescheduling;
    values['showTimescheduling'] = this.showTimescheduling;
    this.globalfunctionality.setModuleValue('budget', values);
    this.saveToLocalStorage();
    if (this.showTimescheduling) {
      if (this.selectedDays.length === 0) {
        this.daysList.forEach(elm => {
          elm.selected = elm.disabled ? false : true;
        });
        this.daysModified(true);
      } else {
        this.updateForecast('days', Array.from(this.selectedDays, elm => elm.value));
      }
      if (this.selectedHours.length === 0) {
        this.hourSets[0]['complete'] = true;
        this.hourSets[0].endHour = {hour: '24:00', i: 23};
        this.hourSets[0].startHour = {hour: '00:00', i: 0};


        this.setTimeschedulingValidation();
        this.getHourValue(true);
      } else {
        this.updateForecast('hours', this.selectedHours);
      }
      this.checkIfDaysShouldBeExcluded();
      this.triggerForecast();
    } else {
      this.updateForecast('hours', []);
      this.updateForecast('days', []);
      this.triggerForecast();
    }
  }

  daysModified(preventTrigger?) {
    let values = this.globalfunctionality.getModuleValues('budget');
    this.selectedDays = this.daysList.filter(elm => elm.selected);
    values['selectedDays'] = this.selectedDays;

    this.updateForecast('days', Array.from(this.selectedDays, elm => elm.value));
    // CHECK FORECAST
    if (!preventTrigger) {
      this.triggerForecast();
    }

    this.globalfunctionality.setModuleValue('budget', values);
    this.saveToLocalStorage();
  }


  /**
   *
   * @param set
   * @param preventTrigger
   * @param index which formcontrol that has been modified
   * @param decision wheter if its the start or end date that has been modified
   */
  checkIfSetComplete(set?, preventTrigger?, index?, decision?) {
    // Disable all endHours that is before startHour
    // Disable dates depending on dates

    if (index !== undefined) {
      if (decision === 'start') {
        set['startHour'] = this.form.timeschedulingStarthours.controls[index].value;
      }
      if (decision === 'end') {
        set['endHour'] = this.form.timeschedulingEndhours.controls[index].value;
      }

    }

    if (set) {
      if (set.startHour) {
        // Clear previous disables
        this.endHourList.forEach(elm => {
          elm['disabled'] = undefined;
        });

        // Disabled values that are previous then the start hour choice
        let prevIndexes = [];
        for (let i = 0; i < set.startHour.i; i++) {
          prevIndexes.push(i);
        }
        prevIndexes.forEach(elm => {
          this.endHourList[elm]['disabled'] = true;
        });

        // Disable the hours that comes after the first occupied hour until the first free hour
        let firstOccupiedIndex;
        this.endHourList.forEach((elm, index) => {
          if (index > set.startHour.i) {
            // Set first occupied index
            if (elm['occupied']) {
              if (firstOccupiedIndex === undefined) {
                firstOccupiedIndex = index;
              }
            }
          }
        });

        if (firstOccupiedIndex !== undefined) {
          for (let i = firstOccupiedIndex; i < this.endHourList.length; i++) {
            this.endHourList[i]['disabled'] = true;
          }
        }
      }
      if (set.endHour) {
        // Clear previous disables
        this.startHourList.forEach(elm => {
          elm['disabled'] = undefined;
        });

        // Disabled values that are ahead then the end hour choice
        let aheadIndexes = [];
        for (let i = set.endHour.i + 1; i <= this.startHourList.length; i++) {
          aheadIndexes.push(i);
        }


        aheadIndexes.forEach(elm => {
          if (this.startHourList[elm]) {
            this.startHourList[elm]['disabled'] = true;
          }
        });
        this.detectChange();
      }
    }


    this.hourSets.forEach(elm => {
      if (elm.endHour && elm.startHour) {
        elm['complete'] = true;
      }
    });

    let values = this.globalfunctionality.getModuleValues('budget');
    values['hoursSets'] = this.hourSets;

    this.getHourValue(preventTrigger); // CHANGED

    this.globalfunctionality.setModuleValue('budget', values);
    this.saveToLocalStorage();

  }

  getHourValue(preventTrigger?) {
    let hourIndexes = [];

    this.hourSets.forEach(elm => {
      if (elm['complete']) {
        let sIndex = elm.startHour.i;
        let eIndex = elm.endHour.i;
        // Add index range in array so it can be obtained from hours list containing the values used for forecast
        for (let i = sIndex; i <= eIndex; i++) {
          hourIndexes.push(i);
        }
      }
    });

    let uniqueIndexes = Array.from(new Set(hourIndexes));
    this.selectedHours = [];
    uniqueIndexes.forEach(elm => {
      this.selectedHours.push(this.hoursList[elm]);
    });

    let values = this.globalfunctionality.getModuleValues('budget');
    values['selected_hours'] = this.selectedHours;
    this.globalfunctionality.setModuleValue('budget', values);
    this.saveToLocalStorage();
    this.updateForecast('hours', this.selectedHours);

    if (!preventTrigger) {
      this.triggerForecast();
    }
  }

  checkIfDaysShouldBeExcluded() {
    // Check which dates that has been selected within the time period

    let excludeDays = this.days >= 7 ? false : true;

    if (excludeDays) {
      let dates = [];
      dates.push(this.startDateValue);
      let currDate = moment(new Date(this.startDateValue)).startOf('day');
      let lastDate = moment(new Date(this.endDateValue)).startOf('day');
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
      }
      dates.push(this.endDateValue);
      let validDays = [];
      dates.forEach(elm => {
        validDays.push(this.dayTranslate[moment(elm).format('dddd')]);
      });

      // Clear disabled field
      this.daysList.forEach(elm => elm['disabled'] = false);
      let excludedDays = Object.assign([], this.weekDays);

      // Get the invalid days
      validDays.forEach(elm => {
        excludedDays.forEach((elm1, index) => {
          if (elm === elm1) {
            excludedDays.splice(index, 1);
          }
        });
      });

      excludedDays.forEach(elm => {
        this.daysList.forEach(elm1 => {
          if (elm === elm1.value) {
            elm1.selected = false;
            elm1.disabled = true;
          }
        });
      });

      this.daysModified(true); // CHANGED
    } else {
      this.daysList.forEach(elm => elm.disabled = false);
    }
  }
}
