import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-campaign-remove-dialog',
  templateUrl: './campaign-remove-dialog.component.html',
  styleUrls: ['./campaign-remove-dialog.component.css']
})
export class CampaignRemoveDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CampaignRemoveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) { }

  ngOnInit() {
  }

  removeCampaign(){
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 2,
      };
    data.push(obj);
    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe(res => {
        this.dialogRef.close(true);
      });
  }

  abort(){
    this.dialogRef.close(false);
  }

}
