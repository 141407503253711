import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import { MatDialog } from "@angular/material/dialog";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.css']
})
export class DemographicComponent implements OnInit, OnDestroy {
  genders = [{target_option: "All"}];
  selectedGender;
  /*selectedGenderIndex = 0;*/
  ages = [
    {
      value: "All",
      disabled: false
    }
  ];

  selectedAge = [
    {
      value: "All",
      disabled: false
    }
  ];
  /* selectedAgeIndex = 0;*/
  check = true;


  ageFormControl = new FormControl();

  hideComponent: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              public dialog: MatDialog) {
  }

  ngOnDestroy() {
    this.check = false;
  }

  ngOnInit() {


    this.globalfunctionality.resetAndHideDemography
      .subscribe(resetAndHide => {
        if(resetAndHide){
          this.hideComponent = true;
          this.globalfunctionality.demographyReset.next(true);
        }
      });

    this.globalfunctionality.showDemography
      .subscribe(show => {
        if(show){
          this.hideComponent = false;
        }
      });


    this.ages = [
      {
        value: "All",
        disabled: true
      }
    ];
    this.check = true;


    this.globalfunctionality.demographyReset.subscribe((reset: boolean) => {
      if (reset) {
        this.selectedAge = [
          {
            value: "All",
            disabled: false
          }
        ];

        this.ageFormControl.setValue(this.selectedAge);
        this.selectedGender = "All";
        this.globalfunctionality.getModuleValues("demographic")["selectedGender"] = this.selectedGender;
        this.globalfunctionality.getModuleValues("demographic")["selectedAge"] = this.selectedAge;
        this.globalfunctionality.getModuleValues("demographic")["hide"] = true;

        this.store.forecast.genders = [];
        this.store.forecast.ages = [];

        this.globalfunctionality.checkForAudienceReach(this.hideComponent);
        this.checkIfComplete();
      }
    });

    /*
        this.globalfunctionality.initFlowComplete.subscribe((complete: boolean) => {
          if (complete && this.check) {*/

    this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet")
      .subscribe(res => {
        // Add all genders to list
        res.data["genders"].forEach((elm) => {
          this.genders.push(elm);
        });
        // Add all ages to list
        res.data["ages"].forEach((elm) => {
          let obj =
            {
              value: elm.target_option,
              disabled: false
            };
          this.ages.push(obj);
        });

        const values = this.globalfunctionality.getModuleValues("demographic");
        if (values) {
          if (values["selectedGender"] !== undefined) {
            this.selectedGender = values["selectedGender"];
            this.store.forecast.genders = [this.selectedGender];

          } else {
            this.selectedGender = "All";
            values["selectedGender"] = "All";
          }

          if (values["selectedAge"] !== undefined && values['selectedAge'].length > 0) {
            this.selectedAge = values["selectedAge"];
            this.ageFormControl.setValue(this.selectedAge);
            this.store.forecast.ages = Array.from(this.selectedAge, elm => elm.value);

          } else {
            this.selectedAge = [{
              value: "All",
              disabled: true
            }];
            values["selectedAge"] = this.selectedAge;
            values['hide'] = true;
            this.ageFormControl.setValue(this.selectedAge);

          }

        }

        this.checkIfComplete();

        if (!this._cd['destroyed']) {
          this._cd.detectChanges();
        }

      });
    /*    }
      });*/
  }


  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.value === o2.value;
    }
  }

  selectGender(e) {
    let gender = e.value;
    const values = this.globalfunctionality.getModuleValues("demographic");
    this.selectedGender = gender;
    values["selectedGender"] = this.selectedGender;
    this.store.forecast.genders = [];
    this.store.forecast.genders.push(this.selectedGender);
    this.store.showReachWarning = false;

    this.globalfunctionality.checkForAudienceReach();
    this.checkIfComplete();
    /*this.checkForChange();*/
  }

  selectAge(e) {

    // If more than 1 is selected and one is "all" remove the all choice

    // REFACTOR LATER

    let allExists = false;
    let allIndex;
    this.ageFormControl['value'].forEach((elm, index) => {
      if (elm.value === 'All') {
        allExists = true;
        allIndex = index;
      }
    });
    let values = this.globalfunctionality.getModuleValues("demographic");
    if (this.ageFormControl['value'].length > 1) {


      if (allExists) {
        this.ages[0]['disabled'] = true;
        this.ageFormControl['value'].splice(allIndex, 1);
        this.ageFormControl.setValue(this.ageFormControl['value']);
        values['hide'] = false;
      } else {
        this.ages[0]['disabled'] = true;
      }
    } else if (this.ageFormControl['value'].length > 0) {
      if (allExists) {
        this.ages[0]['disabled'] = true;
        values['hide'] = true;
      } else {
        values['hide'] = false;
      }

    } else {
      this.selectedAge = [{
        value: "All",
        disabled: true
      }];
      this.ageFormControl.setValue(this.selectedAge);
      values['hide'] = true;
    }


    this.selectedAge = this.ageFormControl['value'];
    values["selectedAge"] = this.selectedAge;
    this.store.forecast.ages = Array.from(this.selectedAge, elm => elm.value);
    this.store.showReachWarning = false;
    this.globalfunctionality.checkForAudienceReach();
    this.checkIfComplete();
    /* this.checkForChange();*/
  }


  checkIfComplete() {
    const demographicModule = this.globalfunctionality.getModuleValues("demographic");

    if (this.store.GUIFlow[2]) {
      this.store.GUIFlow[2].forEach(function (elm) {
        if (elm.type === "demographic") {
          elm["module_values"].isComplete = true;
          demographicModule.isComplete = true;
        }
      });
    }

    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

    this.globalfunctionality.demographyChange.next(true);
    this.globalfunctionality.page2Checker.next(true);

  }

}
