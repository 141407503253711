import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";


@Component({
  selector: 'app-company-approve-dialog',
  templateUrl: './company-approve-dialog.component.html',
  styleUrls: ['./company-approve-dialog.component.css']
})
export class CompanyApproveDialogComponent implements OnInit {


  active = true;

  showError = false;

  constructor(public dialogRef: MatDialogRef<CompanyApproveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) {
  }

  ngOnInit() {
  }

  submit() {
    this.active = false;
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 11
      };
    data.push(obj);
    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.active = true;
          this.dialogRef.close(true);
        } else {
          this.active = true;
          this.showError = true;
        }
      });
  }

  cancel() {

    this.dialogRef.close(false);
  }

}
