import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-geo-overlap-confirmation-dialog',
  templateUrl: './geo-overlap-confirmation-dialog.component.html'
})
export class GeoOverlapConfirmationDialogComponent implements OnInit {


  authorized = false;
  showFreeCampaignMessage = false;

  constructor(public dialogRef: MatDialogRef<GeoOverlapConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  ngOnInit() {

  }
  
  accept(){
    this.dialogRef.close(true);
  }
  cancel(){
    this.dialogRef.close(false);
  }
}
