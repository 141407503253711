import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { StoreService } from '../../services/store.service';
import { ApiService } from '../../services/api.service';
import { GlobalfunctionalityService } from '../../services/globalfunctionality.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: 'management.component.html'
})
export class ManagementComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  selectedManagementTabIndex: number = 0;
  constructor(
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.globalfunctionality.highlightMenuItem('management');
    this.store.appLevel = 0;

    this.store.managementMenu = this.store.managementMenu.filter(elm => elm.show);

    const key = this.router.url.split('/')[3];
    this.selectedManagementTabIndex = this.store.managementMenu.findIndex(elm => elm.key === key);
  }

  changeTab(e) {
    this.selectedManagementTabIndex = e;
    const elm = this.store.managementMenu[e];
    this.manageMenu(elm.key);
  }

  manageMenu(option) {
    switch (option) {
      case 'companies':
        this.navigate('dashboard/management/companies', undefined);
        break;
      case 'users':
        this.navigate('dashboard/management/users', undefined);
        break;
      case 'pin_brand':
        this.navigate('dashboard/management/pin_brand', undefined);
        break;
      case 'target':
        this.navigate('dashboard/management/target', undefined);
        break;
      case 'feed':
        this.navigate('dashboard/management/feed', undefined);
        break;
      case 'news':
        this.navigate('dashboard/management/news', undefined);
        break;
    }
  }

  navigate(location, params) {
    if (params === undefined) {
      this.router.navigate(['/' + location]);
    } else {
      this.router.navigate(['/' + location, params]);
    }
  }


}
