import {NgModule} from '@angular/core';
import {CampaignApproveDialogComponent} from "./dialogs/campaign-approve-dialog/campaign-approve-dialog.component";
import {CampaignRejectDialogComponent} from "./dialogs/campaign-reject-dialog/campaign-reject-dialog.component";
import {CreativeApproveDialogComponent} from "./dialogs/creative-approve-dialog/creative-approve-dialog.component";
import {CreativeRejectDialogComponent} from "./dialogs/creative-reject-dialog/creative-reject-dialog.component";
import {CompanyApproveDialogComponent} from "./dialogs/company-approve-dialog/company-approve-dialog.component";
import {CompanyRejectDialogComponent} from "./dialogs/company-reject-dialog/company-reject-dialog.component";
import {CampaignRebookDialogComponent} from "./dialogs/campaign-rebook-dialog/campaign-rebook-dialog.component";
import {InviteApproveDialogComponent} from "./dialogs/invite-approve-dialog/invite-approve-dialog.component";
import {InviteRejectDialogComponent} from "./dialogs/invite-reject-dialog/invite-reject-dialog.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppnexusAdvertiserTaskComponent} from "./components/appnexus-advertiser-task/appnexus-advertiser-task.component";
import {CreativeTaskComponent} from "./components/creative-task/creative-task.component";
import {CampaignTaskComponent} from "./components/campaign-task/campaign-task.component";
import {CompanyAndUserTaskComponent} from "./components/company-and-user-task/company-and-user-task.component";
import {InviteUserTaskComponent} from "./components/invite-user-task/invite-user-task.component";
import { CampaignRemoveDialogComponent } from './dialogs/campaign-remove-dialog/campaign-remove-dialog.component';
import {MaterialModule} from "../../utils/MaterialModule";
import {ManuallyAddAppnexusidComponent} from "./components/creative-task/manually-add-appnexusid/manually-add-appnexusid.component";
import {PipeModule} from "../campaign/pipes/pipe.module";


@NgModule({
  declarations: [
    CampaignApproveDialogComponent,
    CampaignRejectDialogComponent,
    CampaignRemoveDialogComponent,
    CreativeApproveDialogComponent,
    CreativeRejectDialogComponent,
    CompanyApproveDialogComponent,
    CompanyRejectDialogComponent,
    CampaignRebookDialogComponent,
    InviteApproveDialogComponent,
    InviteRejectDialogComponent,



    // components
    AppnexusAdvertiserTaskComponent,
    CreativeTaskComponent,
    CampaignTaskComponent,
    CompanyAndUserTaskComponent,
    InviteUserTaskComponent,
    ManuallyAddAppnexusidComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipeModule
  ],
  entryComponents: [
    CampaignApproveDialogComponent,
    CampaignRejectDialogComponent,
    CreativeApproveDialogComponent,
    CreativeRejectDialogComponent,
    CompanyApproveDialogComponent,
    CompanyRejectDialogComponent,
    CampaignRebookDialogComponent,
    InviteApproveDialogComponent,
    InviteRejectDialogComponent,
    CampaignRemoveDialogComponent,
    ManuallyAddAppnexusidComponent
  ],
  exports: [
    AppnexusAdvertiserTaskComponent,
    AppnexusAdvertiserTaskComponent,
    CreativeTaskComponent,
    CampaignTaskComponent,
    CompanyAndUserTaskComponent,
    InviteUserTaskComponent
]
})

export class TaskModule{}
