import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {MatSelectChange} from "@angular/material/select";
import {ResponseBodyModel} from "../../../../../../../models/response-body.model";
import {StoreService} from "../../../../../../../services/store.service";
import {ApiService} from "../../../../../../../services/api.service";
import {CampaignService} from "../../../../../campaign.service";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {forkJoin, Subscription} from "rxjs";
import {CreativemoduleService} from "../../creativemodule.service";


@Component({
  selector: 'app-optimization',
  templateUrl: './optimization.component.html'
})
export class OptimizationComponent implements OnInit, OnDestroy {
  @Output() renderUIElements = new EventEmitter();
  @Input() templateGroup: FormGroup;
  @Input() faceGroup: FormGroup;
  @Input() selectedTemplate: string;
  @Input() faceIndex: number;

  optimizationOption = 'none';
  optimizationOptions =
    [
      {
        value: 'none',
        name: 'Ingen optimering'
      },
      {
        value: 'weather',
        name: 'Väderoptimering'
      }
    ];

  subscription: Subscription;

  load: boolean = false;

  ValidateUrl(control: AbstractControl) {
    if (typeof control.value === "undefined" || control.value === null) {
      return null;
    }
    if ((control.value.startsWith('https://') || control.value.startsWith('http://'))) {
      return null;
    }
    return {validUrl: true};
  }

  ValidateUrlHttps(control: AbstractControl) {
    if (typeof control.value === "undefined" || control.value === null) {
      return null;
    }
    if ((control.value.startsWith('https://'))) {
      return null;
    }
    return {validUrl: true};
  }

  ValidateWholeUrl(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value)) {
      return null;
    }
    return {validWholeUrl: true};
  }

  constructor(public store: StoreService,
              private apiservice: ApiService,
              public campaignService: CampaignService,
              private creativeService: CreativemoduleService,
              private _fb: FormBuilder,
              private _cd: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.faceGroup.value['clickLink.macro'] !== undefined && this.faceGroup.value['image.macro'] !== undefined) {
      this.optimizationOption = 'weather';
    }

   this.subscription = this.creativeService.resetWeatherOpt.subscribe((reset: boolean) => {
      if(reset){
        this.optimizationOption = 'none';
      }
    });

  }

  optimizationOptionChange(option: MatSelectChange) {
    this.load = true;
    /* const creatives = this.globalfunctionality.getModule("creatives");
     if(creatives.module_values.creative_data.touched){
       const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
         panelClass: 'modal-size-sm',
         data: {
           text: 'OBS!',
           desc: 'Om du ändrar ditt val försvinner ditt uppladdade material och måste återuppladdas.',
           confirm_btn: 'Fortsätt',
           abort_btn: 'Avbryt'
         }
       });

       dialog.afterClosed().subscribe(change => {
         if (change) {
           this.handleOptimization(option.value);
         }
       });
     }else{
       this.handleOptimization(option.value);
     }*/

    this.handleOptimization(option.value);

  }

  handleOptimization(option: string) {

    if (option === 'weather') {
      const categoryId = 4;
      if (!this.campaignService.structure.get('config').value['weatherLibId']) {
        const campaignName = this.campaignService.structure.get('name').value + '_' + this.campaignService.structure.get('company').value['id'];
        this.apiservice.getJSON(this.store.apiURL + '/ACMLibServlet')
          .subscribe(res => {
            const libraries = res.data.libraries;
            const duplicateLibraries = libraries.filter(lib => lib.name.includes(campaignName));
            if (duplicateLibraries.length) {
              const prefix = '_xprefix_';
              const latestCreatedLibrary = duplicateLibraries[duplicateLibraries.length - 1];
              const prefixIndex = latestCreatedLibrary.name.indexOf(prefix);
              if (prefixIndex !== -1) {
                this.createLibrary(categoryId, option, (campaignName + prefix + (parseInt(latestCreatedLibrary.name[prefixIndex + prefix.length]) + 1)));
              } else {
                this.createLibrary(categoryId, option, (campaignName + prefix + '1'));
              }
            } else {
              this.createLibrary(categoryId, option, campaignName);
            }
          });
      } else {
        this.handleColumn(this.campaignService.structure.get('config').get('weatherLibId').value, option, categoryId);
      }

    } else {
      console.log('Depending on which format set default template object');
      console.log(this.selectedTemplate);

      const facesArray = this.templateGroup.controls['facesArray'] as FormArray;
      facesArray['controls'][this.faceIndex] = new FormGroup({
        clickLink: new FormControl(this.faceGroup.get('clickLink').value, [
          Validators.required,
          this.ValidateUrl,
          this.ValidateWholeUrl,
        ]),
        imageSrc: new FormControl(this.faceGroup.get('imageSrc').value, [
          Validators.required,
        ]),
        hasError: this.creativeService.hasErrorControl
      });

      facesArray['value'][this.faceIndex] =
        {
          clickLink: this.faceGroup.get('clickLink').value,
          imageSrc: this.faceGroup.get('imageSrc').value,
          hasError: false
        };
      this.renderUIElements.emit(null);
    }
  }

  createLibrary(categoryId: number, option: string, libraryName: string) {
    this.apiservice.postJSON(this.store.apiURL + "/ACMLibServlet", {
      name: libraryName,
      categoryId: categoryId
    }).subscribe((res: ResponseBodyModel) => {
      if (res.responseCode === 201) {
        this.campaignService.structure.get('config').get('weatherLibId').setValue(res.data['libraryId']);
        sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
        this.handleColumn(res.data['libraryId'], option, categoryId);
      }
    });
  }

  /**
   * Create a unique column name so that weather option can be used per unique format/set/face
   * Needs to create both image and click column per unique combination
   * @param libraryId
   * Which library the columns should be created on
   * @param option
   * Only weather option exists atm, later logic might change depending on option
   */
  handleColumn(libraryId: number, option: string, categoryId: number) {
    const d = this.store.selectedSize;
    // Format size, current face index, set id, template new. All these are needed to make unique columns
    const columnName = `${d.size}_${this.faceIndex}.${d.set.id}.${this.selectedTemplate.toLowerCase().replace(' ', '_')}`;
    this.apiservice.getJSON(this.store.apiURL + `/ACMColServlet?acmLibId=${libraryId}`)
      .subscribe((res: ResponseBodyModel) => {
        if (!res.data['columns'].filter(elm => elm.name.includes(columnName)).length) {


          console.log(this.selectedTemplate);

          const image = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
            {
              acmLibId: libraryId,
              name: `${columnName}.imageSrc`,
              type: "image",
              defaultValue: "https://mds.pliing.com/res/2020/06-16/48569560-806f-4cfb-ade0-5889c4b2c474.jpeg"
            });
          const clicklink = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
            {
              acmLibId: libraryId,
              name: `${columnName}.clickLink`,
              type: "clickLink",
              defaultValue: "https://"
            });
          forkJoin([image, clicklink])
            .subscribe(res => {
              this.apiservice.getJSON(this.store.apiURL + `/ACMColServlet?acmLibId=${libraryId}`)
                .subscribe((res: ResponseBodyModel) => {
                  this.getColumns(option, columnName, res, categoryId, libraryId);
                });
            });
        } else {
          this.getColumns(option, columnName, res, categoryId, libraryId);
        }
      });
  }

  /**
   * Function called twice just for breaking out function
   * @param option
   * Only weather option exists atm, later logic might change depending on option
   * @param columnName
   * Used for creating unique formcontrols
   * @param res
   * All created columns, filter out the columns which are specific for the unique columnName
   */
  getColumns(option: string, columnName: string, res: ResponseBodyModel, categoryId: number, libraryId: number) {
    this.addFormControls(option, columnName, res.data['columns'].filter(column => column.name.includes(columnName)).reduce((prev, curr) => {
      prev.push({id: curr.id, type: curr.type, macro: '${{' + categoryId + '.' + libraryId + '.' + curr.id + '}}'});
      return prev;
    }, []));
  }

  /**
   * For each created column create a formcontrol per weatherOption
   * Also create a formcontrol where the column id can be saved
   * The column id will be later used for saving the data on the correct column and it
   * will be used for retrieving the macro for that specific field the macro will be placed in the ad params
   * @param option
   *  Only weather option exists atm, later logic might change depending on option
   * @param columnName
   * Used for creating unique formcontrols
   * @param columns
   * Go thorugh the filtered columns and add a control where we can save the created/fetched column id
   */
  addFormControls(option: string, columnName: string, columns: [{ id: number; type: string; macro: string }]) {
    if (option === 'weather') {
      ['sun', 'cloud', 'rain', 'snow'].forEach(weather => {
        this.faceGroup.addControl(`${weather}.${columnName}.clickLink`, this.creativeService.optimizationClickLink);
        this.faceGroup.addControl(`${weather}.${columnName}.imageSrc`, this.creativeService.optimizationImage);
      });
      columns.forEach(column => {
        this.faceGroup.addControl(`${column.type}.column.id`, new FormControl(column.id));
        this.faceGroup.addControl(`${column.type}.macro`, new FormControl(column.macro));
      });

      // Will hold the default values, because original field will be overriden with the macro used for preview
      this.faceGroup.addControl(`default_clickLink`, new FormControl());
      this.faceGroup.addControl(`default_imageSrc`, new FormControl());

      // Holds which optimization option that was selected
      this.faceGroup.addControl(`optimization_option`, new FormControl(option));


      this.load = false;
      this.renderUIElements.emit(this.optimizationOption);
    }
  }

}
