import {Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";
import {Credentials} from "./models/credentials.model";
import {StoreService} from "../services/store.service";
import {Observable} from "rxjs";
import {LoginResponse} from "./models/login-response.model";
import {RegisterRequest} from "./models/register-request.model";
import {RegisterResponse} from "./models/register-response.model";


@Injectable()
export class StartpageService {
  constructor(
    private apiservice: ApiService,
    private store: StoreService
  ){

  }


  login(credentials: Credentials): Observable<LoginResponse>{
  return this.apiservice.postJSON(this.store.apiURL + "/LoginServlet", credentials);
  }


  register(request: RegisterRequest): Observable<RegisterResponse>{
    return this.apiservice.putJSON(this.store.apiURL + "/CreateUserServlet", request);
  }


}
