import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-invite-user-task',
  templateUrl: 'invite-user-task.component.html'
})


export class InviteUserTaskComponent{
  @Input() selectedTask: any;
  @Input() loadTask: any;
  @Input() userInfo: any;
  @Input() companyInfo: any;


  @Output() rejectInvitedUser: EventEmitter<any> = new EventEmitter();
  @Output() approveInvitedUser: EventEmitter<any> = new EventEmitter();


  handleReject(){
    this.rejectInvitedUser.emit();
  }
  handleApprove(){
    this.approveInvitedUser.emit();
  }
}

