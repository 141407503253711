import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {CampaignService} from "../../../campaign.service";

@Component({
  selector: 'app-addecision',
  templateUrl: './addecision.component.html',
  styleUrls: ['./addecision.component.css']
})
export class AddecisionComponent implements OnInit {
  selectedOption = '0';

  @Output() chooseAdType = new EventEmitter();
  @Output() restoreSearchwordIndicatorLoad = new EventEmitter();

  constructor(
    private globalfunctionality: GlobalfunctionalityService,
    public store: StoreService,
    private apiservice: ApiService,
    public campaignService: CampaignService,
  ) {

  }

  ngOnInit() {
    let decision = this.globalfunctionality.getModuleValues('addecision');
    if (decision) {
      if (decision.selected) {
        this.selectedOption = decision.selected;
      }


      this.checkIfComplete();
    }
  }

  optionChanged() {

    delete this.store.contextForecast['content_categories'];
    delete this.store.contextForecast['content_geo'];
    delete this.store.contextForecast['content_text'];


    // Clear adforsize and adtype values
    const adforsize = this.globalfunctionality.getModuleValues("adforsize");
    const adtype = this.globalfunctionality.getModuleValues("adtype");

    if(adtype){
      adtype['selectedOption'] = undefined;
    }

    if(adforsize) {
      adforsize['selected'] = undefined;
    }

    this.store.loadPage = true;
    if (this.selectedOption === '0') {
      this.restoreSearchwordIndicatorLoad.emit();
    }

    if (this.campaignService.structure.get('objective').value.id !== 7) {
      /*this.checkIfComplete();*/
      this.reset();
    } else {
      this.setContextFlow(true).then(() => {
        this.reset();
      });
    }

  }

  reset() {
    // Reset
    this.globalfunctionality.setModuleValue('context', {});
    this.globalfunctionality.setModuleValue('searchword', {});
    this.globalfunctionality.setModuleValue('budget', {});
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.store.loadPage = false;
    this.checkIfComplete();
  }


  async setContextFlow(build?) {
    let promise = new Promise(resolve => {

      this.globalfunctionality.disableVideoAdType.next(false);

      /*if (!this.store.selectedCompany) {
        this.globalfunctionality.restoreSelectedCompany();
      }*/
      let company_id = this.campaignService.structure.get('company').value.id;
      // Get CONTEXT modules
      this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + company_id)
        .subscribe(res => {
          if (res.responseCode === 200) {
            let auxObjective = res.data.filter(elm => elm.id === 1)[0];
            this.campaignService.structure.get('objective').setValue(auxObjective);
            sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));

            this.apiservice.getJSON(this.store.apiURL + "/ModulesServlet?values=" + JSON.stringify(auxObjective.modules))
              .subscribe(res => {
                res.forEach((elm) => {
                  elm.param = JSON.parse(elm.param);
                  // WHEN NOT MODULE VALUES IS RETRIEVED (WORKAROUND)
                  elm.module_values = {};
                });
                // Copy adtype and context module to new objective
                this.store.modules.forEach(oldModule => {
                  res.forEach(newModule => {
                    if (oldModule.type === newModule.type) {
                      newModule['module_values'] = oldModule['module_values'];
                    }
                  });
                });
                this.store.modules = res;
                sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
                if (build) {
                  this.buildFlow();
                }
                resolve(true);
              });
          }
        });
    });

    let res = await promise;
    return res;

  }


  buildFlow() {
    if (sessionStorage.getItem("modules") === null) {
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.store.GUIFlow = {};
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });
    } else {
      this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
      this.store.GUIFlow = {};
      // GET FLOW DEPENDING ON OBJECTIVE, USE FLOW TO POPULATE WORKFLOW
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });
    }
  }

  checkIfComplete() {

    // Set searchword module as complete to bypass logic that checks if all modules for a page is complete
    // objective id can be 7 if RON is selected ("alla schibsteds sajter")
    if (this.selectedOption === '0' && this.campaignService.structure.get('objective').value.id === 1) {

      const searchword = this.store.GUIFlow['2'].filter(elm => elm.type === 'searchword')[0];
      if(searchword){
        searchword['module_values']['isComplete'] = true;
      }
      this.globalfunctionality.setModuleValue('searchword', {isComplete: true});
    }

    this.globalfunctionality.setModuleValue('addecision', {selected: this.selectedOption, isComplete: true});

    if (this.store.GUIFlow['2']) {
      this.store.GUIFlow['2'].forEach((elm) => {
        if (elm.type === "addecision") {
          elm["module_values"].isComplete = true;
          elm["module_values"].selected = this.selectedOption;
        }
      });
    }

    this.globalfunctionality.page2Checker.next(true);
    this.chooseAdType.emit(this.selectedOption);
  }

}
