import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ApiService} from "../../../../../../../../../../services/api.service";
import {StoreService} from "../../../../../../../../../../services/store.service";
import {FormControl} from "@angular/forms";

/**
 * Node for to-do item
 */
export class ProductNode {
  children: ProductNode[];
  item: string;
  total: number;
  meta?: any;
}

/** Flat to-do item node with expandable and level information */
export class ProductFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  total: number;
  meta?: any;
  idFromSupplier?: any;
}

export class FormattedProductFlatNode {
  name: string;
  id: number;
  title: string;
  value: number;
}

@Component({
  selector: 'app-product-tree',
  templateUrl: 'category-tree.component.html'
})
export class CategoryTreeComponent implements OnInit, OnChanges {
  @Input() categories;
  @Input() catalogId: number;
  @Input() feedSelectedControl: FormControl;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ProductFlatNode, ProductNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ProductNode, ProductFlatNode>();

  treeControl: FlatTreeControl<ProductFlatNode>;

  treeFlattener: MatTreeFlattener<ProductNode, ProductFlatNode>;

  dataSource: MatTreeFlatDataSource<ProductNode, ProductFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ProductFlatNode>(true /* multiple */);

  dataObject;

  timeout;

  constructor(iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer,
              private apiservice: ApiService,
              private store: StoreService,
              private _cd: ChangeDetectorRef) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ProductFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    iconRegistry.addSvgIcon(
      'chevron-right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-right.svg'));
    iconRegistry.addSvgIcon(
      'chevron-down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-down.svg'));
  }


  ngOnChanges(): void {
    // Calls 2 times? check why
    this.initialize();
  }

  ngOnInit(): void {
    /*this.initialize();*/
  }

  initialize(): void {
    this.dataObject = {};
    this.categories.forEach(category => {
      if (category.idParent === undefined) {
        this.dataObject[category.id] = {};
      } else {
        const ids = category.lineage.split('/');
        ids.pop();
        let aux = this.dataObject;
        ids.forEach(id => {
          if (!aux[id]) {
            aux[id] = {};
          }
          aux = aux[id];
        });
        aux[category.id] = null;
      }
    });

    this.calculateAmountOfProductsPerCategory();


    const data = this.buildFileTree(this.dataObject, 0, this.categories, undefined, true);
    this.dataSource.data = data;

    if (this.feedSelectedControl.value) {
      this.treeControl.dataNodes.forEach(node => {
        if (this.feedSelectedControl.value['categories'].find(elm => elm.id === parseInt(node.item))) {
          this.checklistSelection.select(node);
          if (node.expandable) {
            this.treeControl.expand(node);
          }
        }
      });
    }
  }


  calculateAmountOfProductsPerCategory(){
    // Get amount of categories in tree
    let maxAmountOfCategories: number = 0;
    this.categories.forEach(elm => {
      if(elm.lineage) {
        const amountOfCategories =  elm.lineage.split('/').length;
        if(maxAmountOfCategories < amountOfCategories){
          maxAmountOfCategories = amountOfCategories;
        }
      }
    });

    // Start from the bottom node and calculate parents amount of products (bottom node has a "total" value which defines how many products exists for that node)
    for(let i = maxAmountOfCategories; i >= 0; i--){
      this.categories.forEach(elm => {
        if(elm.lineage) {
          if (elm.lineage.split('/').length === i) {
            elm['amount_of_products'] = !elm['amount_of_products'] ? elm.total : (elm['amount_of_products'] + elm.total);
            const parent = this.categories.find(cat => cat.id === elm.idParent);
            parent['amount_of_products'] = !parent['amount_of_products'] ? elm.amount_of_products : (parent['amount_of_products'] + elm.amount_of_products);
          }
        }else if(elm.total){ // workaround for categories which has no children
          elm['amount_of_products'] = elm.total;
        }
      });
    }
  }

  buildFileTree(obj: { [key: string]: any }, level: number, categoryArray, objectArray, categoryStructure: boolean = false): ProductNode[] {
    return Object.keys(obj).reduce<ProductNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new ProductNode();
      node.item = key;

      if (categoryStructure) {
        if (categoryArray.find(elm => elm.id === parseInt(node.item))) {
          node.meta = categoryArray.find(elm => elm.id === parseInt(node.item));
        }
      }


      if (value != null) {
        if (typeof value === 'object' && node.meta !== undefined) {
          if (node.meta.total !== undefined) {
            node.children = this.buildFileTree(value, level + 1, categoryArray, objectArray, categoryStructure);
          }
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  getLevel = (node: ProductFlatNode) => node.level;

  isExpandable = (node: ProductFlatNode) => node.expandable;

  getChildren = (node: ProductNode): ProductNode[] => node.children;

  hasChild = (_: number, _nodeData: ProductFlatNode) => _nodeData.expandable;

  /*hasNoContent = (_: number, _nodeData: ProductFlatNode) => _nodeData.item === '';*/

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ProductNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new ProductFlatNode();
    flatNode.item = node.item;
    flatNode.meta = node.meta;
    flatNode.total = node.total;
    flatNode.level = level;
    flatNode.expandable = node.children ? !!node.children.length : !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ProductFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    let descAllSelected: boolean = false;
    if (descendants.length) {
      descAllSelected = descendants.every(child =>
        this.checklistSelection.isSelected(child)
      );
    }
    node.meta['indeterminate'] = !descAllSelected;
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ProductFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    node.meta['indeterminate'] = result;
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ProductFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ProductFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ProductFlatNode): void {
    let parent: ProductFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }

    const allSelectedCategories = this.checklistSelection.selected.map((node: ProductFlatNode) => {
      return {
        idCategory: node.meta.idCategoryCatalog,
        id: node.meta.id,
        parent: node.meta.idParent ? node.meta.idParent : node.meta.id,
        allSelected: true
      };
    });

    this.treeControl.dataNodes.forEach((node: ProductFlatNode) => {
      this.descendantsAllSelected(node);
      this.descendantsPartiallySelected(node);
      if (node.meta.indeterminate) {
        allSelectedCategories.push(
          {
            idCategory: node.meta.idCategoryCatalog,
            id: node.meta.id,
            parent: node.meta.idParent ? node.meta.idParent : node.meta.id,
            allSelected: false
          }
        );
      }
    });

    this.feedSelectedControl.setValue(
      {
        categories: allSelectedCategories
      }
    );
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ProductFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ProductFlatNode): ProductFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}
