import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './containers/login/login.component';
import { RegisterComponent } from './containers/register/register.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { InviteExistingComponent } from './containers/invite-existing/invite-existing.component';
import { InviteComponent } from './containers/invite/invite.component';
import { UnsubscribeEmailComponent } from './containers/unsubscribe-email/unsubscribe-email.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StartpageService } from './startpage.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { InviteFormComponent } from './components/invite-form/invite-form.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {PrivacyDialogComponent} from "./dialogs/privacy-dialog/privacy-dialog.component";

const startpageRoutes: Routes = [
  {
    path: 'login',
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent }
    ]
  },
  { path: 'register', component: LoginComponent },
  { path: 'company/invite/:id', component: InviteExistingComponent },
  { path: 'invite/:id', component: InviteComponent },
  { path: 'unsubscribe/:id', component: UnsubscribeEmailComponent },
  { path: 'reset/:id', component: ResetPasswordComponent }
];

@NgModule({
  declarations: [
    // Container
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    InviteExistingComponent,
    InviteComponent,
    UnsubscribeEmailComponent,
    ResetPasswordComponent,

    // Components
    LoginFormComponent,
    ForgotPasswordFormComponent,
    RegisterFormComponent,
    ResetPasswordFormComponent,
    InviteFormComponent,

    // Dialogs
    PrivacyDialogComponent
  ],
  imports: [
    // Angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(startpageRoutes),
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    AngularSvgIconModule
  ],
  providers: [StartpageService],
  entryComponents: [PrivacyDialogComponent]
})
export class StartpageModule {}
