import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";
import {ResponseBodyModel} from "../../../../models/response-body.model";

@Component({
  selector: 'app-campaign-approve-dialog',
  templateUrl: './campaign-approve-dialog.component.html',
  styleUrls: ['./campaign-approve-dialog.component.css']
})
export class CampaignApproveDialogComponent implements OnInit {

  operativeOneID;
  /*active = true;*/

  showSubmit = false;

  load: boolean = false;

  constructor(public dialogRef: MatDialogRef<CampaignApproveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService,
              private _api: ApiService) {
  }

  ngOnInit() {

  }

  checkOperativeOneId() {
    if (this.operativeOneID !== undefined && this.operativeOneID !== '' && this.operativeOneID !== null) {
      this.showSubmit = true;
    } else {
      this.showSubmit = false;
    }
  }


  submit() {
    this.load = true;
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks.id,
        task_status: 11,
      };

    if (this.data.id_tasks.status.toLowerCase() === "error") {
      obj["operatives_one_id"] = this.operativeOneID;
    }
    data.push(obj);

    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
            this.dialogRef.close(true);
        }
      });
  }


  cancel() {
    this.dialogRef.close(false);
  }

}
