import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {ApiService} from "../../../../../../../../services/api.service";
import {FormControl} from "@angular/forms";
import {CreativemoduleService} from "../../../creativemodule.service";
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'app-selector',
  templateUrl: 'selector.component.html'
})

export class SelectorComponent {
  @Output() updateSelectorValue: EventEmitter<any> = new EventEmitter();
  @Input() adSelectorControl: FormControl;
  @Input() adMainSrcControl: FormControl;

  options =
    [
      {value: 'false', name: 'Ingen bild', key: 'no_image'},
      {value: 'true', name: 'Ladda upp bild', key: 'upload_image'}
    ];

  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private _creativeService: CreativemoduleService
  ) {}

  optionChanged(select: MatSelectChange): void {
    this.updateSelectorValue.emit(select.value);
  }

}
