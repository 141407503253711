import {Component, OnInit} from '@angular/core';
import {StoreService} from "./services/store.service";
import {Router} from "@angular/router";
import {GlobalfunctionalityService} from "./services/globalfunctionality.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService) {
  }

  ngOnInit() {

    this.globalfunctionality.getLanguages(() => {
      this.globalfunctionality.getLanguageList(() => {
        this.globalfunctionality.getLanguageJson(this.store.selectedLanguage, () => {
          if (this.store.user === undefined) {
            if (this.router.url.indexOf("invite") === -1 && this.router.url.indexOf("reset") === -1 && this.router.url.indexOf("unsubscribe") === -1 && this.router.url.indexOf("privacy-policy") === -1) {
              this.router.navigate(["/"]);
            }
          }
        });
      });
    });
  }




}
