import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../../../../services/api.service";
import {StoreService} from "../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";

@Component({
  selector: 'app-geography',
  templateUrl: './geography.component.html',
  styleUrls: ['./geography.component.css']
})
export class GeographyComponent implements OnInit {

  @Output() searchWordChange = new EventEmitter();
  @Input() set geo(geo: any){
    this.counties = geo;
      if(geo){
        this.checkGeos(this.searchWordObj);
      }
   }

  counties = [];
  selectedOption: string = '0';
  selectedGeos = [];

  searchWordObj;
  blockToggle:boolean = false;


  constructor(
    private apiservice: ApiService,
    private store: StoreService,
    private globalfunctionality: GlobalfunctionalityService
  ) { }

  ngOnInit() {

    this.searchWordObj = this.globalfunctionality.getModuleValues('searchword');
    if(this.searchWordObj.geography){
      this.selectedOption = this.searchWordObj.geography.selected;
    }
  }

  selectedCountyHasChildren(){
    if(this.selectedGeos.length){
      if(this.selectedGeos.filter(elm => (elm.children !== undefined) && elm.checked).length){
        return true;
      }
    }else{
      return false;
    }
  }

  getCountiesWithChildren(){
    return this.selectedGeos.filter(elm => elm.children !== undefined);
  }

  checkGeos(searchword){
    if(searchword.geography){
      if(searchword.geography.selectedGeos) {

        this.selectedGeos = searchword.geography.selectedGeos;

        this.counties.forEach(county => {
          searchword.geography.selectedGeos.forEach(selected => {
            if(county.target_option === selected.target_option){
              county.checked = true;
            }
          });
        });
      }
    }
  }

  allChildrenSelected(county): boolean{

    if(county.children.filter(elm => elm.checked).length === county.children.length){
      return true;
    }
    return false;
  }

  /*toggleCountyChildren(county){
    let val = !this.allChildrenSelected(county);
    county.children.forEach(elm => {
      elm.checked = val;
    });

    let searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    searchWordModuleObj['geography']['selectedGeos'] = this.selectedGeos;
    this.saveChange(searchWordModuleObj);
  }*/

  changeSelection(){
    // Save value in shared searchword module
    let searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    if(!searchWordModuleObj['geography']){
      searchWordModuleObj['geography'] = {};
    }
    searchWordModuleObj['geography']['selected'] = this.selectedOption;

    if(this.selectedOption === '0'){
      delete searchWordModuleObj['geography']['selectedGeos'];
      this.counties.forEach(elm => {
        if(elm.checked){
          elm.checked = false;
        }

        if(elm.children){
          elm.children.forEach(child => {
            if(child.checked){
              child.checked = false;
            }
          });
        }
      });

      this.saveChange(searchWordModuleObj);
    }else{

      this.selectedGeos = [];
      this.saveChange(searchWordModuleObj, true);
    }


  }

  /*toggleCity(county, city){
    city.checked = !city.checked;
    let searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    searchWordModuleObj['geography']['selectedGeos'] = this.selectedGeos;

    this.saveChange(searchWordModuleObj);
  }*/

  toggleGeo(county){

    if(!this.blockToggle){
    this.blockToggle = true;

    county.checked = !county.checked;
    if(this.selectedGeos.filter(elm => elm.target_option === county.target_option).length){

      this.selectedGeos.forEach((elm, index) => {
        if(county.target_option === elm.target_option){
          this.selectedGeos.splice(index, 1);
        }
      });

    }else{

      if(county.children){
        county.children.forEach(elm => {
          elm.checked = true;
        });
      }

      this.selectedGeos.push(county);
    }

    let searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');

    searchWordModuleObj['geography']['selectedGeos'] = this.selectedGeos;
    this.saveChange(searchWordModuleObj);
    this.blockToggle = false;
    }
  }


  saveChange(obj, preventTrigger?){
    this.globalfunctionality.setModuleValue('searchword', obj);
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));

    if(!preventTrigger) {
      this.searchWordChange.next(true);
    }
  }

}
