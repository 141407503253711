import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {Router} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateBrandDialogComponent} from "./create-brand-dialog/create-brand-dialog.component";

declare var toastr: any;

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})

export class BrandComponent implements OnInit {


  // Table variables
  type = "brand";
  isDesc: boolean = false;
  column: string = 'name';
  direction: number;
  show = false;
  searchText: string;
  selectedIndex: number;
  p: number = 1;
  brands = [];
  selectedBrand;
  limit: number = 20;
  limits = [5, 10, 20, 50];
  showErrorMsg = false;

  sharedBrands = [];


  companyList = [];

  org_num;
  billing_zip;
  loadBrands = true;

  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  orgNumFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(10),
    Validators.minLength(10)
  ]);

  billingZipFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(5),
    Validators.minLength(5)
  ]);


  shareBrandFormGroup: FormGroup;

  brandsToShare = [];

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              public dialog: MatDialog) {

  }

  ngOnInit() {

    this.shareBrandFormGroup = new FormGroup(
      {
        orgNumFormControl: this.orgNumFormControl,
        billingZipFormControl: this.billingZipFormControl
      }
    );

    this.globalfunctionality.initComplete.subscribe((value: boolean) => {
      if (value) {
        this.getBrands();
        if (!this._cd["destroyed"]) {
          this._cd.detectChanges();
        }

      }
    });

    this.globalfunctionality.highlightMenuItem("management");

  }


  getSharedBrands() {
    this.apiservice.getJSON(this.store.apiURL + "/ShareBrandsServlet")
      .subscribe(res => {
        this.sharedBrands = res.data.owned_brands;

        if (this.selectedBrand !== undefined) {
          this.resetCompanyList(this.selectedBrand);
        }

        if (!this._cd["destroyed"]) {
          this._cd.detectChanges();
        }
      });
  }

  getBrands() {
    let companies = this.store.user.companies;
    this.brands = [];

    companies.forEach(elm => {
      this.apiservice.getJSON(this.store.apiURL + "/CompaniesServlet?values=" + JSON.stringify([elm.id]))
        .subscribe(res => {
          this.apiservice.getJSON(this.store.apiURL + "/BrandsServlet?values=" + JSON.stringify(res.data[0].brands))
            .subscribe(res => {
              res.data.forEach(elm => {
                this.brands.push(elm);
              });

              this.getSharedBrands();

              this.loadBrands = false;
              if (!this._cd["destroyed"]) {
                this._cd.detectChanges();
              }
            });
        });
    });
  }


  resetCompanyList(brand) {
    this.companyList = [];
    this.sharedBrands.forEach(elm => {
      if (elm.brand_id === brand.id) {
        this.companyList = elm.brand_shared_with;
      }
    });
  }

  createBrand() {
    const dialogRef = this.dialog.open(CreateBrandDialogComponent, {width: '450px'});
    dialogRef.afterClosed().subscribe(result => {
      this.getBrands();

    });
  }

  save() {
    if (!this.nameFormControl.hasError('required')) {

      // Save name and share brands
      let obj = {
        brands_id: this.selectedBrand.id,
        brands_name: this.selectedBrand.name
      };
      let arr = [];
      arr.push(obj);

      this.apiservice.postJSON(this.store.apiURL + "/BrandsServlet", arr)
        .subscribe(res => {

          if (this.brandsToShare.length > 0) {
            this.apiservice.putJSON(this.store.apiURL + "/ShareBrandsServlet", this.brandsToShare)
              .subscribe(res => {
                toastr.info("Ändringar sparade");
                this.getSharedBrands();

              });
          }
        });
    }

  }


}
