import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
  FormGroup
} from '@angular/forms';
import {StoreService} from '../../../../services/store.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {ResponseBodyModel} from '../../../../models/response-body.model';
import { MatDialogRef } from '@angular/material/dialog';
import {CustomTargetService} from '../../../management/services/custom-target/custom-target.service';

declare var $: any;

@Component({
  selector: 'app-create-target',
  templateUrl: './create-target.component.html',
  styleUrls: ['./create-target.component.css']
})
export class CreateTargetComponent implements OnInit {
  searchBrand;
  filteredItems = [];

  loadBrandList: boolean = false;
  noResult: boolean = true;
  filteredOptions: Observable<string[]>;

  ATESegments: any[];

  form: FormGroup;

  @ViewChild('ateDropdown') ateDropdown;

  constructor(
    private _fb: FormBuilder,
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    private _api: ApiService,
    private router: Router,
    public dialogRef: MatDialogRef<CreateTargetComponent>,
    private targetService: CustomTargetService
  ) {
  }

  ngOnInit() {
    this.form = this._fb.group({
      brands: [[], Validators.required],
      /** TODO: Update to use form builder API after migration to Angular 7.1.0 or higher */
      target_option: new FormControl('', {
        validators: [Validators.required],
        asyncValidators: [this.isNameUnique.bind(this)],
        updateOn: 'blur'
      }),
      description: ['', [Validators.required]],
      mapped_option: ['', [Validators.required, this._matchSegment.bind(this)]],
      total: ['', [Validators.required]]
    });

    // Get ATE segment
    this._api
      .getJSON(this.store.apiURL + '/CustomTargetsServlet?segments=true')
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          this.ATESegments = res.data;
          this.filteredOptions = this.form
            .get('mapped_option')
            .valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  private _matchSegment(control: AbstractControl) {
    if (this.ATESegments) {
      if (typeof control.value === "undefined" || control.value === '') {
        return null;
      }


      if (this.ATESegments.filter(elm => elm.ate === control.value).length) {
        return null;
      }

      return {invalidSegment: true};
    }else{
      return null;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.ATESegments) {
      return this.ATESegments.filter(option =>
        option['name'].toLowerCase().includes(filterValue)
      );
    }
  }

  openATESegment() {
    this.form.controls.mapped_option.markAsUntouched();
    this.form.controls.mapped_option.setValue('');
    this._filter('');
  }

  isNameUnique(control: AbstractControl) {
    return this.targetService
      .isNameUnique(control.value)
      .pipe(map(unique => (unique ? null : {notUnique: true})));
  }

  submitForm(isValid: boolean) {
    if (isValid) {
      this.form.value['brands'] = this.form.value['brands'].map(elm => elm.brand.brands_id);

      let postObj = this.form.value;

      this._api
        .postJSON(this.store.apiURL + '/CustomTargetsServlet', postObj)
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  changeBrand(brand?, comp?): void {
    const brandsControl = this.form.get('brands');
    let exists =
      brandsControl.value.findIndex(
        currentBrand => currentBrand.brand.brands_id === brand.brands_id
      ) !== -1;

    if (!exists) {
      brandsControl.setValue([
        ...brandsControl.value,
        {
          brand: {brands_id: brand.brands_id, brands_name: brand.brands_name}
        }
      ]);
    }
  }

  deselectBrand(brand, index) {
    const brandsControl = this.form.get('brands');
    let brands = brandsControl.value;

    brands.splice(index, 1);

    brandsControl.setValue(brands);
  }

  filterItems(): void {
    this.loadBrandList = true;
    this.filteredItems = $.extend(true, [], this.store.companiesList);
    this.noResult = true;
    this.filteredItems.forEach(elm => {
      if (
        elm.companies_name
          .toLowerCase()
          .indexOf(this.searchBrand.toLowerCase()) !== -1
      ) {
        this.noResult = false;
        return;
      } else {
        elm.companyNotFound = true;
        elm.brands = elm.brands.filter(item => {
          if (
            item.brands_name !== undefined &&
            this.searchBrand !== undefined
          ) {
            return (
              item.brands_name
                .toLowerCase()
                .indexOf(this.searchBrand.toLowerCase()) !== -1
            );
          }
        });
      }
      if (elm.brands.length > 0) {
        this.noResult = false;
      }
    });
    this.loadBrandList = false;
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  triggerMenu(menuTrigger, brandInput): void {
    if (!menuTrigger.menuOpen) {
      this.searchBrand = '';
      menuTrigger.toggleMenu();
      brandInput.focus();
    }
    this.filterItems();
  }

  restoreBrand(): void {
    this.searchBrand = '';
  }
}
