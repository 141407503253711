import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../../../services/store.service";
import {ApiService} from "../../../../../../services/api.service";
import {CampaignService} from "../../../../campaign.service";

@Component({
  selector: 'app-addlater-dialog',
  templateUrl: './addlater-dialog.component.html',
  styleUrls: ['./addlater-dialog.component.css']
})
export class AddlaterDialogComponent implements OnInit {

  /*adFormats;*/

  showMinSelectionError = false;

  approved = false;
  selectedSite;
  selectedSubcontexts = [];
  targetingBooked: boolean = false;
  dynamicProduct: boolean = false;
  selectedDeviceOption: string = 'all';

  constructor(public dialogRef: MatDialogRef<AddlaterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public globalfunctionality: GlobalfunctionalityService,
              public store: StoreService,
              private apiservice: ApiService,
              public campaignService: CampaignService
  ) {
  }


  ngOnInit() {

    const adforsizeValues = this.globalfunctionality.getModuleValues('adforsize');
    if(adforsizeValues.selectedDeviceOption){
      this.selectedDeviceOption = adforsizeValues.selectedDeviceOption;
    }

    // If context flow obtain selected contexts
    if(this.campaignService.structure.get('objective').value.id === 1){
      if(adforsizeValues.dynamic_ad){
        this.dynamicProduct = true;
      }


      const contextValues = this.globalfunctionality.getModuleValues("context");
      this.selectedSite = contextValues.contexts.filter(elm => elm.active)[0].context;
      this.selectedSubcontexts = contextValues.contexts.filter(elm => elm.active)[0].sub_contexts.filter(elm => elm.selected);

      if(this.selectedSubcontexts.length){
        if(this.selectedSubcontexts[0].name.toLowerCase() === 'alla' || this.selectedSubcontexts[0].name.toLowerCase() === 'startsida' ){
          this.selectedSubcontexts = [];
        }else{
          this.selectedSubcontexts = this.selectedSubcontexts.map(elm => elm.name);
        }
      }else{
        this.selectedSubcontexts = this.selectedSubcontexts.map(elm => elm.name);
      }

      // Check if targeting booked site, in that case only show site choice in UI
      this.targetingBooked = contextValues.contexts[0].targeting_booking;
    }

  }


  saveSelection() {

    if (this.approved) {
      this.globalfunctionality.getModuleValues('adforsize')['selected'].forEach((elm) => {
        if (elm.complete && elm.active) {
        } else {
          elm.add_later = true;
        }
      });

      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.dialogRef.close(true);
    }
  }


  close(){
    this.dialogRef.close(false);
  }

}
