import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import {TandcDialogComponent} from "./tandc-dialog/tandc-dialog.component";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {Router} from "@angular/router";
import {CampaignChangeWarningComponent} from "../../../dialogs/campaign-change-warning/campaign-change-warning.component";
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {DomSanitizer} from "@angular/platform-browser";
import {CampaignService} from "../../../campaign.service";

declare var moment: any;

@Component({
  selector: 'app-reviewmodule',
  templateUrl: './reviewmodule.component.html',
  styleUrls: ['./reviewmodule.component.css']
})
export class ReviewmoduleComponent implements OnInit {
  reviewData: any = {};
  moduleObj;
  Math: any;

  showNoAdsMessage = false;
  showAds = false;

  banners = [];

  selectedContext;

  Object;
  JSON;
  showBudgetSplit: boolean = false;


  takeOverCrossChosen: boolean = false;

  addecision;

  selectedContentCategories;

  targetingBooked: boolean = false;

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.target_option,
      level: level,
      showSub: node.showSub,
      indeterminate: node.indeterminate,
      selected: node.selected,
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectedDeviceOption: string = 'all';

  deviceOptionNames = {
    desktop: 'Desktop',
    mobile: 'Mobil'
  }

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              public dialog: MatDialog,
              private router: Router,
              private _create: CreatecampaignComponent,
              public campaignService: CampaignService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    this.Math = Math;
    this.Object = Object;
    this.JSON = JSON;

    iconRegistry.addSvgIcon(
      'chevron-right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-right.svg'));
    iconRegistry.addSvgIcon(
      'chevron-down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-down.svg'));
  }

  showSub = (_: number, node: any) => node.showSub;
  hasChild = (_: number, node: any) => node.expandable;
  indeterminate = (_: number, node: any) => node.indeterminate;
  selected = (_: number, node: any) => node.selected;


  ngOnInit() {


    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    if(adforsize.selectedDeviceOption) {
      this.selectedDeviceOption = adforsize.selectedDeviceOption;
    }

    if (this.globalfunctionality.getModuleValues('addecision')) {
      this.addecision = parseInt(this.globalfunctionality.getModuleValues('addecision').selected);
    }

    this.reviewData = {};

    this.reviewData['campaign_name'] = JSON.parse(sessionStorage.getItem("campaign_structure")).name;

    const objective = JSON.parse(sessionStorage.getItem("campaign_structure")).objective;

    if (objective !== null) {
      if (this.campaignService.structure.get('company') !== undefined) {

        this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.campaignService.structure.get('company').value.id)
          .subscribe(res => {

            const {name} = res.data.filter(elm => elm.id === objective.id)[0];
            this.reviewData['objective_key'] = name;

            if (!this._cd["destroyed"]) {
              this._cd.detectChanges();
            }
          });
      }

      // BREAK OUT TO GLOBALFUNCTIONALITY FUNCTION USED IN SEVERAL PLACES
      this.moduleObj = JSON.parse(sessionStorage.getItem("modules"));

      if (this.moduleObj !== null && this.moduleObj !== undefined) {
        this.moduleObj.forEach((elm) => {

          if (elm.type === "searchword") {
            if (this.addecision) { // If blocket searchword
              this.reviewData["searchword"] = elm.module_values;

              // Build category tree structure
              this.selectedContentCategories = [];

              let selectedContentCategory = this.reviewData["searchword"].category;
              if (selectedContentCategory) {

                if (selectedContentCategory.selectedCategories) {
                  selectedContentCategory.selectedCategories.forEach(cat => {
                    cat['selected_subcategories'] = [];
                    this.selectedContentCategories.push(cat);


                  });

                  this.selectedContentCategories.forEach(cat => {
                    selectedContentCategory.selectedSubCategories.forEach(sub => {
                      if (cat.target_option === sub.categoryName) {
                        sub['selected_productcategories'] = [];
                        cat['selected_subcategories'].push(sub);
                      }
                    });
                    if (cat.children && cat['selected_subcategories'].length !== cat.children.length) {
                      cat['showSub'] = true;
                      cat['children'] = cat['selected_subcategories'];
                    } else {
                      cat['showSub'] = false;
                    }
                  });

                  this.selectedContentCategories.forEach(cat => {
                    cat['selected_subcategories'].forEach(sub => {
                      selectedContentCategory.selectedProductCategories.forEach(prod => {
                        if (sub.target_option === prod.subCategoryName) {
                          sub['selected_productcategories'].push(prod);
                        }
                      });

                      if (sub.children) {
                        if (sub['selected_productcategories'].length !== sub.children.length) {
                          sub['children'] = sub['selected_productcategories'];
                          cat['children'] = cat['selected_subcategories'];
                          sub['showProduct'] = true;
                          cat['showProduct'] = true;
                          cat['showSub'] = true;
                          sub['showSub'] = true;
                        } else {
                          sub['showSub'] = false;
                        }
                      }
                    });
                  });

                  this.selectedContentCategories.forEach(cat => {
                    if (!cat.showSub) {
                      delete cat['children'];
                    } else {
                      cat.children.forEach(sub => {
                        if (!sub.showProduct) {
                          delete sub['children'];
                        }
                      });
                    }
                  });
                }
                this.dataSource.data = this.selectedContentCategories;
              }
            }
          }

          if (elm.type === "geo") {
            this.reviewData["geo"] = elm.module_values.selectedGeo;
          }
          if (elm.type === "budget") {
            if (!this.reviewData["budget"]) {
              this.reviewData["budget"] = {};
            }

            this.reviewData["budget"]["value"] = elm.module_values.budget;
            this.reviewData["budget"]["option"] = elm.module_values.option;
            this.reviewData["budget"]["start"] = moment(elm.module_values.start).format("YYYY-MM-DD");
            this.reviewData["budget"]["end"] = moment(elm.module_values.end).format("YYYY-MM-DD");
            this.reviewData["budget"]["freqDecisionOption"] = elm.module_values.freqDecisionOption;
            if (elm.module_values.guaranteedDelivery !== undefined) {
              this.reviewData["budget"]["guaranteedDelivery"] = elm.module_values.guaranteedDelivery;
            }

            if (elm.module_values.impressions) {
              this.reviewData["budget"]["impressions"] = elm.module_values.impressions;
            }

            if (elm.module_values.freqAmount) {
              this.reviewData["budget"]["freqAmount"] = elm.module_values.freqAmount['index'];
            }

            if (elm.module_values.selectedFreqOption) {
              this.reviewData["budget"]["selectedFreqOption"] = elm.module_values.selectedFreqOption;
            }

            if (elm.module_values.soon_as_possible) {
              this.reviewData['budget']['soon_as_possible'] = elm.module_values.soon_as_possible;
            }

            if (elm.module_values.billing_information) {
              this.reviewData['budget']['billing_information'] = elm.module_values.billing_information;
              this.store.noteText = elm.module_values.billing_information;
            }
            if (elm.module_values.trusted_takes_bill !== undefined) {
              this.reviewData['budget']['trusted_takes_bill'] = elm.module_values.trusted_takes_bill;
              this.store.trustedPartnerTakesBill = elm.module_values.trusted_takes_bill;
            }
            if (elm.module_values.billing_partner !== undefined) {
              this.reviewData['budget']['billing_partner'] = elm.module_values.billing_partner;
              this.store.billingPartner = elm.module_values.billing_partner;
            }

            if (elm.module_values.showTimescheduling) {
              if (elm.module_values.selectedDays) {
                this.reviewData['budget']['selectedWeekDays'] = elm.module_values.selectedDays.filter(elm => elm.selected).map(elm => elm.value);
              }

              if (elm.module_values.hoursSets) {
                this.reviewData['budget']['hours'] = elm.module_values.hoursSets.filter(elm => elm.complete).sort((a, b) => {
                  if (a.startHour.i < b.startHour.i) {
                    return -1; // places infront a is less
                  }
                  if (a.startHour.i > b.startHour.i) {
                    return 1; // places behind a is greater
                  }
                  return 0;
                });
              }

            }
            this.reviewData['budget']['days'] = elm.module_values.days;
            this.reviewData['budget']['total_value'] = elm.module_values.budget;

            const objectiveId = this.campaignService.structure.get('objective').value.id;
            if (objectiveId === 6) {
              this.reviewData["budget"]["click_price"] = elm.module_values.click_price;
              this.reviewData["budget"]["clicks"] = elm.module_values.clicks;
            } else if (objectiveId === 2 || objectiveId === 7) {
              const product = this.campaignService.structure.get('products').value[0];
              this.reviewData['budget']['cpm'] = Math.round(product.product_pricing);
              this.reviewData['budget']['gross_cpm'] = Math.round(product.gross_product_pricing);
            }

          }

          if (elm.type === "adcreation") {
            if (elm.module_values.selectedMethod === "add_later") {
              this.showNoAdsMessage = true;
            } else {
              this.showNoAdsMessage = false;
            }
          }

          if (elm.type === "ads") {
            if (elm.module_values.isComplete) {
              this.banners = elm["module_values"]["banners"];
              this.showAds = true;
            } else {
              this.showAds = false;
            }
          }

          if (elm.type === "interestanddemographic") {
            if (elm.module_values !== undefined) {
              this.reviewData["interest"] = {};
              this.reviewData["interest"] = elm.module_values;
            }
          }

          if (elm.type === "demographic") {
            if (elm.module_values !== undefined) {
              this.reviewData["demographic"] = elm.module_values;
            }
          }


          if (elm.type === "context") {
            if (this.campaignService.structure.get('objective').value.id === 1) {
              // Filter out selected categories
              this.targetingBooked = elm.module_values.contexts[0].targeting_booking;
              if (this.targetingBooked) {
                const selectedCategories = elm.module_values.contexts[0].all_category_targets.filter(elm => elm.selected || elm.indeterminate);
                selectedCategories.forEach(cat => {
                  if (cat.children) {
                    cat.children = cat.children.filter(sub => sub.selected || sub.indeterminate);
                    cat.children.forEach(sub => {
                      if (sub.children) {
                        sub.children = sub.children.filter(prod => prod.selected || prod.indeterminate);
                      }
                    });
                  }
                });
                this.dataSource.data = selectedCategories;
                this.treeControl.expandAll();
              }


              if (elm.module_values !== undefined) {
                this.selectedContext = elm.module_values.contexts;
                this.selectedContext.forEach(context => {
                  context.sub_contexts = context.sub_contexts.filter(elm => elm.selected);
                });

                if (this.selectedContext[0].context.toLowerCase() === 'cross') {
                  this.takeOverCrossChosen = true;
                }

                if (!this.reviewData["budget"]) {
                  this.reviewData["budget"] = {};
                }
                this.reviewData["budget"]['impressions'] = 0;

                this.selectedContext[0].sub_contexts.forEach(elm => {
                  if (elm.total_impressions_for_subcontext) {
                    this.reviewData["budget"]['impressions'] += elm.total_impressions_for_subcontext;
                  }
                });
              }
            }
          }

          if (elm.type === 'adtype') {
            this.reviewData['hide_context_name'] = elm.module_values.selectedOption === '2';
          }


          if (elm.type === 'device') {
            this.reviewData['device'] = {};
            this.reviewData['sites'] = {};

            this.reviewData['sites'] = elm.module_values.sites.filter(elm => elm.selected);


            if (elm.module_values.selectedTargetOption) {
              switch (elm.module_values.selectedTargetOption.toLowerCase()) {
                case 'desktops & laptops':
                  this.reviewData['device'] = 'Desktop';
                  break;
                case 'mobiles':
                  this.reviewData['device'] = 'Mobil';
                  this.reviewData['device'] = 'Mobil';
                  break;
                case 'tablets':
                  this.reviewData['device'] = 'Desktop';
                  break;
              }
            } else {
              this.reviewData['device'] = 'Mobil & Desktop';
            }

            if (elm.module_values.selectedOSIndex !== undefined) {
              this.reviewData['os'] = elm.module_values.selectedOSIndex === 0 ? 'Android & iOS' : elm.module_values.selectedOSTargetOption;
            }


          }

          if (this.campaignService.structure.get('objective').value.id === 7) {
            if (elm.type === 'adforsize') {

              // Get active formats
              let activeFormats = elm.module_values.selected.filter(elm => elm.active);

              let auxSites = [];
              activeFormats.forEach(elm => {
                if (elm.format === '320x320') {
                  auxSites.push('Aftonbladet');
                  auxSites.push('Blocket');
                  auxSites.push('Klart.se');
                  auxSites.push('Omni');
                  auxSites.push('SvD');
                  auxSites.push('Tv.nu');
                }
                if (elm.format === '640x320') {
                  auxSites.push('Aftonbladet');
                }
                if (elm.format === '300x480') {
                  auxSites.push('Aftonbladet');
                  auxSites.push('SvD');
                }
                if (elm.format === '980x240' || elm.format === '250x600') {
                  auxSites.push('Aftonbladet');
                  auxSites.push('Blocket');
                  auxSites.push('Klart.se');
                  auxSites.push('SvD');
                  auxSites.push('Tv.nu');
                }
              });
                this.reviewData['ron_sites'] = Array.from(new Set(auxSites));
            }
          }


        });
      }
    }
  }

  noChildrenSelected(): boolean {
    return this.dataSource.data.filter(elm => elm.showSub || elm.showProduct).length === 0 ? false : true;
  }

  specificMunicipalitiesChosen(geo) {
    return geo.children.length !== geo.children.filter(elm => elm.checked).length;
  }

  changeRoute(link, param) {
    const dialogRef = this.dialog.open(CampaignChangeWarningComponent,
      {
        panelClass: 'modal-size-sm'
      }
    );

    dialogRef.afterClosed().subscribe(navigate => {
      if (navigate) {
        this.router.navigate([link, param]);
      }
    });

  }

  showDialog() {
    const dialogRef = this.dialog.open(TandcDialogComponent, {height: '70%', width: '80%'});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
