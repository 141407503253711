import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {sendRequest} from "selenium-webdriver/http";
import {FormBuilder, Validators} from "@angular/forms";


@Component({
  selector: 'app-forgot-password-form',
  templateUrl: 'forgot-password-form.html'
})

export class ForgotPasswordFormComponent implements OnInit {
  @Input() translation: any;
  @Output() sendRequest: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  form;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(){
    this.form = this._fb.group({
      username: ['', [Validators.required, Validators.email]]
    });
  }


  handleRequest(isValid: boolean) {
    if (isValid) {
      this.sendRequest.emit(this.form.value);
    }
  }

  handleBack(){
    this.back.emit();
  }

}



