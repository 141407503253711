import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StoreService} from "../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, OnDestroy {

  @Output() searchWordChange = new EventEmitter();
  @Input() categories;
  @Input() selectedOption: string;

  selectedCategories = new FormControl([]);
  selectedSubCategories = new FormControl({value: [], disabled: true});
  selectedProductCategories = new FormControl({value: [], disabled: true});

  /*categories = [];
  subCategories = [];
  productCategories = [];*/

  subscriptions: Subscription[] = [];
  searchword: any = {};

  constructor(
    private store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    private cd: ChangeDetectorRef
  ) {

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(elm => elm.unsubscribe());
  }

  ngOnInit() {
    this.searchword = this.globalfunctionality.getModuleValues('searchword');

    if (this.searchword.category) {
      if (this.searchword.category.selectedCategories) {
        this.selectedCategories.setValue(this.searchword.category.selectedCategories);
        if (this.searchword.category.selectedCategories.length) {
          this.selectedSubCategories.enable();
        }
      } else {
      }
      if (this.searchword.category.selectedSubCategories) {
        this.selectedSubCategories.setValue(this.searchword.category.selectedSubCategories);
        if (this.searchword.category.selectedSubCategories.length) {
          this.selectedProductCategories.enable();
        }
      } else {
      }
      if (this.searchword.category.selectedProductCategories) {
        this.selectedProductCategories.setValue(this.searchword.category.selectedProductCategories);
      } else {
      }
    } else {
      this.searchword['category'] = {};
    }

    this.createSubscriptions();
  }

  createSubscriptions() {
    this.subscriptions.push(this.selectedCategories.valueChanges.subscribe(val => {
      if (val) {

        if (val.length) {
          this.selectedSubCategories.enable();
        } else {
          this.selectedSubCategories.disable();
        }

        this.searchword = this.globalfunctionality.getModuleValues('searchword');
        this.searchword['category']['selectedCategories'] = this.selectedCategories.value;
        this.saveChange(this.searchword);
        this.searchWordChange.next(true);
      }
    }));

    this.subscriptions.push(this.selectedSubCategories.valueChanges.subscribe(val => {
      if (val) {
        if (val.length) {
          this.selectedProductCategories.enable();
        } else {
          this.selectedProductCategories.disable();
        }

        this.searchword = this.globalfunctionality.getModuleValues('searchword');

        this.searchword['category']['selectedSubCategories'] = this.selectedSubCategories.value;
        this.saveChange(this.searchword);
        this.searchWordChange.next(true);
      }
    }));

    this.subscriptions.push(this.selectedProductCategories.valueChanges.subscribe(val => {
      if (val) {

        this.searchword = this.globalfunctionality.getModuleValues('searchword');
        this.searchword['category']['selectedProductCategories'] = this.selectedProductCategories.value;
        this.saveChange(this.searchword);
        this.searchWordChange.next(true);
      }
    }));
  }


  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
  }

  toggleAllProductCategories(subcategory) {
    if (subcategory.children) {
      const alreadySelected = subcategory.children.some(s => {
        return !!this.selectedProductCategories.value.find(c => s.id === c.id);
      });

      if (alreadySelected) {
        const filteredProducts = this.selectedProductCategories.value.filter(s => {
          return !subcategory.children.find(c => c.id === s.id);
        });
        this.selectedProductCategories.setValue(filteredProducts);
      } else {
        const products = this.selectedProductCategories.value || [];
        this.selectedProductCategories.setValue(products.concat(subcategory.children));
      }
    }
  }

  toggleCategoryOption(category) {
    if (category.children) {
      const isSelecting = this.selectedCategories.value.find(c => category.id === c.id);

      if (!isSelecting) {
        const filteredSubcategories = this.selectedSubCategories.value.filter(sub => {
          return sub.categoryName !== category.target_option;
        });
        const filteredProducts = this.selectedProductCategories.value.filter(p => {
          return !!filteredSubcategories.find(sub => p.subCategoryName === sub.target_option);
        });
        this.selectedSubCategories.setValue(filteredSubcategories);
        this.selectedProductCategories.setValue(filteredProducts);
      } else {

        const subCats = this.selectedSubCategories.value || [];
        let productCats = this.selectedProductCategories.value || [];
        category.children.forEach(subCat => {
          if (subCat.children) {
            productCats = productCats.concat(subCat.children);
          }
        });

        this.selectedSubCategories.setValue(subCats.concat(category.children));
        this.selectedProductCategories.setValue(productCats);
      }
    }
  }


  chooseAllCategories() {
    if (this.selectedCategories.value &&
        this.selectedCategories.value.length === this.categories.length
    ) {
      this.selectedCategories.setValue([]);
      this.selectedSubCategories.setValue([]);
      this.selectedProductCategories.setValue([]);
      this.selectedSubCategories.disable();
      this.selectedProductCategories.disable();
    } else {
      let subcategories = [];
      let products = [];

      this.categories.forEach(c => c.children ? subcategories = subcategories.concat(c.children) : false);
      subcategories.forEach(c => c.children ? products = products.concat(c.children) : false);
      this.selectedSubCategories.enable();
      this.selectedProductCategories.enable();

      this.selectedCategories.setValue(this.categories);
      this.selectedSubCategories.setValue(subcategories);
      this.selectedProductCategories.setValue(products);
    }
  }

  chooseAllSubCategories(category) {
    const notAllSelected = category.children.some(c => {
      return !this.selectedSubCategories.value.find(s => s.id === c.id);
    })

    if (this.selectedSubCategories.value &&
        this.selectedSubCategories.value.length &&
        !notAllSelected
    ) {
      const filtered = this.selectedSubCategories.value.filter(s => {
        return !category.children.find(c => c.id === s.id);
      });
      let products = [];
      filtered.forEach(sub => sub.children ? products = products.concat(sub.children) : false);
      this.selectedSubCategories.setValue(filtered);
      this.selectedProductCategories.setValue(products);
    } else {
      let products = this.selectedProductCategories.value || [];
      category.children.forEach(sub => sub.children ? products = products.concat(sub.children) : false);
      const subcategories = this.selectedSubCategories.value.concat(category.children);
      this.selectedSubCategories.setValue(subcategories);
      this.selectedProductCategories.setValue(products);
    }
  }

  filterByCategory(category) {
    if (this.selectedSubCategories) {
      if (this.selectedSubCategories.value) {
        return this.selectedSubCategories.value.filter(elm => elm.categoryName === category.target_option).length;
      }
    }
  }

  chooseAllProductCategories(subCategory) {
    const notAllSelected = subCategory.children.some(c => {
      return !this.selectedProductCategories.value.find(s => s.id === c.id);
    })

    if (this.selectedProductCategories.value &&
        this.selectedProductCategories.value.length &&
        !notAllSelected
    ) {
      const filtered = this.selectedProductCategories.value.filter(s => {
        return !subCategory.children.find(c => c.id === s.id);
      });

      if (filtered.length !== this.selectedProductCategories.value.length) {
        this.selectedProductCategories.setValue(filtered);
      } else {
        const newVal = this.selectedProductCategories.value.concat(subCategory.children)
        this.selectedProductCategories.setValue(newVal);
      }
    } else {
      const newVal = this.selectedProductCategories.value.concat(subCategory.children)
      this.selectedProductCategories.setValue(newVal);
    }
  }

  filterBySubCategory(subCategory) {
    if (this.selectedProductCategories) {
      if (this.selectedProductCategories.value) {
        return this.selectedProductCategories.value.filter(elm => elm.subCategoryName === subCategory.target_option).length;
      }
    }
  }

  detectChange(){
    if(!this.cd['destroyed']){
      this.cd.detectChanges();
    }
  }

  saveChange(obj) {
    this.globalfunctionality.setModuleValue('searchword', obj);
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }
}
