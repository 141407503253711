import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {ApiService} from "../../../../../../../../services/api.service";
import {ResponseBodyModel} from "../../../../../../../../models/response-body.model";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";


@Component({
  selector: 'app-creative-clicklink',
  templateUrl: 'clicklink.component.html'
})

export class ClicklinkComponent implements OnInit {

  @Input() adURLControl;
  @Input() adCallToActionControl;
  /**
   * Use inside component will remove wrapper divs so because component will be placed inside a parent component which has those parent divs
   */
  @Input() useInsideComponent: boolean = false;
  @Input() optimizationOption: boolean = false;
  @Input() optimizationControlKey: string;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  urlValidatorTimeout;

  urlValidated: boolean = false;

  optimizationOptionValue;

  constructor(
    public store: StoreService,
    private apiservice: ApiService
  ) {

  }

  ngOnInit(): void {
    this.URLChange();

    if(this.optimizationOption) {
      this.optimizationOptionValue = this.optimizationControlKey.split('.')[0];
    }
  }

  URLChange(){

    this.adURLControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap( () => {
        return this.apiservice.getJSON(this.store.apiURL + "/UrlValidatorServlet?value=" + this.adURLControl.value);
      })
    ).subscribe((res: ResponseBodyModel) => {
      if(res.responseCode === 200) {
        this.urlValidated = res.data['validation'];
        this.triggerUpdatePreview.emit();
      }
    });
  }

  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

  translateOption(){
    switch (this.optimizationOptionValue) {
      case 'sun': return 'sol';
      case 'rain': return 'regn';
      case 'cloud': return 'moln';
      case 'snow': return 'snö';
    }
  }

}
