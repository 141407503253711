import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";

@Component({
  selector: 'app-creative-message',
  templateUrl: 'message.component.html'
})

export class MessageComponent {

  @Input() adSubHeaderControlDT;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService){

  }


  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

}
