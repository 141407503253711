import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {Router} from "@angular/router";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";
import {FormcontrolService} from "../../../../../services/formcontrol/formcontrol.service";
import {RedirectDialogComponent} from "./redirect-dialog/redirect-dialog.component";
import {CampaignService} from "../../../campaign.service";
import {pluck} from "rxjs/operators";

declare var $: any;

@Component({
  selector: 'app-objectivemodule',
  templateUrl: './objectivemodule.component.html',
  styleUrls: ['./objectivemodule.component.css']
})


export class ObjectivemoduleComponent implements OnInit, OnDestroy {

  @ViewChild('brandInput') brandInput: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Output() resetAdDesicion: EventEmitter<any> = new EventEmitter();
  @Output() setPage1Load: EventEmitter<Boolean> = new EventEmitter();

  selectedIndex;
  objectives = [];
  getLocations = true;
  searchBrand;
  filteredItems = [];
  load = false;

  timeout;


  // Used for displaying "more" data
  selectedObjective;

  check = true;
  loadBrandList = false;
  noResult;

  amountOfObjectives = 0;

  fetchingBrands: boolean = true;
  openMenu: boolean = false;

  selectedObjectiveId;

  updateCreatives: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              public apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private createcampaign: CreatecampaignComponent,
              public dialog: MatDialog,
              public form: FormcontrolService,
              public _create: CreatecampaignComponent,
              public campaignService: CampaignService) {

    this.globalfunctionality.checkAdBlock();

  }

  ngOnDestroy() {
    $("body").attr('id', '');
  }

  // TODO: CHECK WHY THIS FUNCTION IS TRIGGERED ON CAMAIGN EDIT
  ngOnInit() {

    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;

    $("body").attr('id', 'objectives');
    this.setPage1Load.emit(true);

    this.createcampaign.changeFlowPage(1, false);


    this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.store.user.companies[0].id)
      .subscribe(res => {
        if (res.responseCode === 200) {

          this.amountOfObjectives = res.data.length;
          // Hide RON objective from list
          res.data
            .filter(elm => elm.id === 7)
            .map(elm => {
              elm.hide = true; this.amountOfObjectives -= 1;
            });

          res.data.sort((a, b) => {
            return a.order_objectives - b.order_objectives;
          });

          this.objectives = res.data;

          this.objectives.forEach(elm => {
            if(this.campaignService.structure.get('objective').value) {
              if (elm.id === this.campaignService.structure.get('objective').value.id) {
                this.campaignService.structure.get('objective').setValue(elm);
                this.checkFlow(elm);
              }
            }
          });

          // Auto select audience track if no objective has been chosen
          if (this.campaignService.structure.get('objective').errors) {
            /*if (!this.store.editCampaign) {*/
              this.objectives.forEach((objective, index) => {
                if (objective.id === 2) {
                  this.selectObjective(objective, index);
                }
              });
            /*}*/
          }

          /**
           * If media agency/trusted partner
           * Wait for companieslist to be obtained
           */
          if (this.store.isMediaAgency.value || this.store.isTrustedPartner.value) {
            if (!this.store.companiesList.length) {
              this.apiservice.getJSON(this.store.apiURL + "/CampaignServlet?option=brands")
                .subscribe(res => {
                  if (res.responseCode === 200) {
                    this.store.companiesList = res.data;

                    this.store.companiesList.forEach((elm) => {
                      elm['showBrands'] = false;
                      elm.brands.forEach(brand => {
                        if (brand['write']) {
                          elm['showBrands'] = true;
                        }
                      });
                    });

                    this.buildBrandList();
                    this.setPage1Load.emit(false);
                  }
                });

            }
            /**
             * otheriwse pick default brand
             */
            else {
              this.buildBrandList();
              this.setPage1Load.emit(false);
            }
          } else {
            this.setDefaultBrand();
          }
        } else {
          this.router.navigate(["/login"]);
        }
      });


    // ??
    this.fetchingBrands = false;
    if (this.openMenu) {
      this.triggerMenu(this.menuTrigger, this.menuTrigger);
    }

    if (this.campaignService.structure.get('objective').value) {
      if (this.campaignService.structure.get('objective').value.id === 7) {
        this.selectedObjectiveId = 1;
      } else {
        this.selectedObjectiveId = this.campaignService.structure.get('objective').value.id;
      }
    }
  }

  setDefaultBrand() {
    let brandId = this.store.user.companies[0].default_brand.brands_id;
    this.apiservice.getJSON(this.store.apiURL + '/BrandsServlet?brand=' + brandId)
      .pipe(
        pluck('data')
      )
      .subscribe(brand => {
        const brandObj = brand[0];
        this.campaignService.structure.get('brand').get('name').setValue(brandObj.name);
        this.campaignService.structure.get('brand').get('id').setValue(brandObj.id);
        this.campaignService.structure.get('brand').get('city').setValue(brandObj.brand_city);
        sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));


        this.checkIfFreeCampaign();
      });
  }



  buildBrandList() {
    this.store.companiesList.forEach(elm => {
      const company = elm;
      if (company.companies_id === this.store.user.companies[0].id) {
        // Remove default brand from list
        elm.brands.forEach((brand, index) => {
          if (elm.companies_name === brand.brands_name) {
            elm.brands.splice(index, 1);
          }
        });
      }
    });
    this.checkIfFreeCampaign();
  }


  checkIfFreeCampaign() {
    if (this.store.freeCampaign) {
      this.freeCampaignPass();
    } else {
      this.setPage1Load.emit(false);
      this.checkIfComplete();
    }
  }

  setBrandValue(brand) {
    this.campaignService.structure.get('brand').get('name').setValue(brand.brands_name);
    this.campaignService.structure.get('brand').get('id').setValue(brand.brands_id);
    this.campaignService.structure.get('brand').get('city').setValue(brand.brands_city);
    sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
  }

  freeCampaignPass() {
    if (!this.updateCreatives) { // If creatives only to be updated do not run this method
        this.objectives.forEach((objective, index) => {
          if (objective.id === 2) {
            this.selectObjective(objective, index);
            this.checkIfComplete();
            this.setPage1Load.emit(false);
            this._create.continue();
          }
        });
    }
  }

  detectChange() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }


  openRedirectDialog() {
    const dialogRef = this.dialog.open(RedirectDialogComponent, {width: '500px'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.appLevel = 0;
        this.router.navigate(['dashboard/management/pin_brand']);
      }
    });
  }


  restoreBrand() {
    if (!this.campaignService.structure.get('brand').get('name').errors) {
      if (this.searchBrand !== this.campaignService.structure.get('brand').get('name').value) {
        this.searchBrand = this.campaignService.structure.get('brand').get('name').value;
      }
    }
  }

  checkFlow(elm) {
    if (sessionStorage.getItem("modules") === null) {
      this.getModules(elm.modules);
      this.detectChange();
    } else {
      this.buildFlow();
    }
  }


  /**
   * Checks for which pages are complete
   */
  checkIfFlowComplete() {
    if (this.store.GUIFlow["2"] !== undefined) {
      this.campaignService.page2Complete = this.store.GUIFlow["2"].every(elm => {
        return elm["module_values"].isComplete === true;
      });
    }

    let complete = false;
    let addLater = false;
    if (this.store.GUIFlow['3'] !== undefined) {
      this.store.GUIFlow['3'].forEach((elm) => {

        if (elm['type'] === 'addlater') {
          addLater = elm['module_values']['isComplete'];
        }
        if (elm["type"] === "adcreation") {
          complete = elm["module_values"]["isComplete"];
        }
        if (elm["type"] === "templates") {
          complete = elm["module_values"]["isComplete"];
        }
        if (elm["type"] === "creatives") {
          complete = elm["module_values"]["isComplete"];
        }

        if (elm["type"] === "adforsize") {
          complete = elm["module_values"]["isComplete"];
        }
      });


      this.campaignService.page3Complete = complete || addLater;
      this.detectChange();
      this._cd.markForCheck();
    }

    this.globalfunctionality.detectChange.next(true);
    /*this.globalfunctionality.initFlowComplete.next(true);*/


  }

  buildFlow() {
    if (sessionStorage.getItem("modules") === null) {
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.store.GUIFlow = {};
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });
    } else {
      this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
      this.store.GUIFlow = {};
      // GET FLOW DEPENDING ON OBJECTIVE, USE FLOW TO POPULATE WORKFLOW
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });


      this.checkIfFlowComplete();
    }
  }

  getModules(modulesList) {
    this.apiservice.getJSON(this.store.apiURL + "/ModulesServlet?values=" + JSON.stringify(modulesList))
      .subscribe(res => {
        res.forEach((elm) => {
          elm.param = JSON.parse(elm.param);
          // WHEN NOT MODULE VALUES IS RETRIEVED (WORKAROUND)
          elm.module_values = {};

        });

        this.store.modules = res;

        sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
        this.buildFlow();
        this.checkIfComplete();
      });
  }


  clear() {
    if (this.resetAdDesicion) {
      this.resetAdDesicion.emit();
    }

    this.store.showReachWarning = undefined;
    this.store.productType = undefined;
    /* this.store.campaignName = undefined;*/
    sessionStorage.removeItem('modules');
    sessionStorage.removeItem('campaign');
  }

  resetForecastObj() {
    this.store.forecast =
      {
        date_from: "",
        date_to: "",
        placements: [],
        geo_locations: [],
        genders: [],
        interests: [],
        ages: [],
        impressions: "",
        frequency: [],
        products: [],
        days: [],
        hours: [],
        custom_targets: []
      };
  }

  continueObjectiveSelection(change: boolean, objective) {
    if (change) {
      this.selectedObjectiveId = objective.id;

      this.resetForecastObj();
      this.clear();
      // When user has changed objective reset promp message should now be false untill user continues with objective (check createcampaign.component - func: continue())
      this.store.prompChangeMessage = false;
      this.campaignService.structure.get('objective').setValue(objective);

      sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
      this.globalfunctionality.objectiveChange.next(true);
      this.getModules(objective['modules']);

    }
  }


  selectObjective(objective, index) {
    if (this.store.modules !== null && this.store.prompChangeMessage) {
      let change = true;
      this.selectedIndex = index;
      if ((this.campaignService.structure.get('objective').value.id !== objective.id) && this.campaignService.structure.get('objective').value.id !== undefined) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'modal-size-xs',
          data: {type: 'objective'}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            change = result;
            if (change) {
              this.store.freeCampaign = false;
            }
            this.continueObjectiveSelection(change, objective);
          }
        });
      } else {
        this.continueObjectiveSelection(change, objective);
      }
    } else {
      this.continueObjectiveSelection(true, objective);
    }
  }


  checkIfComplete() {

    if (this.campaignService.structure.get('objective').value !== undefined) {
      const campaignObj = JSON.parse(sessionStorage.getItem("campaign"));
      if (campaignObj !== null) {
        if (campaignObj.objective !== undefined && campaignObj.objective !== "") {
          this.createcampaign.objectiveComplete.value = true;
        }
      } else {
        this.createcampaign.objectiveComplete.value = false;
      }
    }
    this.detectChange();

    sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
    this.globalfunctionality.page1Checker.next(true);

  }

  changeBrand(brand) {
    this.searchBrand = brand.brands_name;

    this.setBrandValue(brand);

    let userObj = JSON.parse(sessionStorage.getItem("userObj"));
    userObj["selected_brand"] = brand;


    sessionStorage.setItem("userObj", JSON.stringify(userObj));
    sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));


    // Triggers event that sets a name "automatically"
    this.globalfunctionality.objectiveChange.next(true);
  }


  triggerMenu(menuTrigger, brandInput) {
    this.openMenu = true;
    this.searchBrand = brandInput.value === undefined ? '' : brandInput.value;
    /*this.campaignService.structure.get('brand').setValue(this.searchBrand);*/
    if (!menuTrigger.menuOpen) {
      this.searchBrand = '';
      menuTrigger.toggleMenu();
      brandInput.focus();
    }
    this.filterItems();
  }


  filterItems() {

    this.loadBrandList = true;
    this.filteredItems = $.extend(true, [], this.store.companiesList);

    this.filteredItems.forEach(comp => {
      comp.brands = comp.brands.filter(brand => brand.write);
    });

    this.noResult = true;
    this.filteredItems.forEach(elm => {
      if (elm.companies_name.toLowerCase().indexOf(this.searchBrand.toLowerCase()) !== -1) {
        this.noResult = false;
        return;
      } else {
        elm.brands = elm.brands.filter(item => {
          if (item.brands_name !== undefined && this.searchBrand !== undefined) {
            return item.brands_name.toLowerCase().indexOf(this.searchBrand.toLowerCase()) !== -1;
          }
        });
      }
      if (elm.brands.length > 0) {
        this.noResult = false;
      }
    });


    this.loadBrandList = false;
    this.detectChange();
  }

  getItems(menuTrigger, brandInput) {
    this.triggerMenu(menuTrigger, brandInput);
    // Do not show error when searching new brand
    this.campaignService.structure.get('brand').get('name').clearValidators();

  }
}
