import {Component} from "@angular/core";


@Component({
  selector: 'app-not-found',
  template: `
    <div class="login-page" style="background-image: url('./assets/css/native-theme/img/login-bg.jpg');">

      <div class="login-logo">
        <img src="./assets/css/native-theme/img/logo-stacked-white.png">
      </div>

      <section class="login-content-container">

        <div style="display: flex;align-items: center;flex-direction: column;"
                        class="portlet-box login-content inner-trbl-xl">

          <div style="text-align: center" class="form-group">
            <h4>Sidan kunde inte hittas.</h4>
          </div>
          <button class="btn-primary" routerLink="/">Gå till startsida</button>
        </div>

      </section>

    </div>

  `
})


export class NotFoundComponent{


}
