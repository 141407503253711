import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-campaign-rebook-dialog',
  templateUrl: './campaign-rebook-dialog.component.html',
  styleUrls: ['./campaign-rebook-dialog.component.css']
})
export class CampaignRebookDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CampaignRebookDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: StoreService) {
  }

  ngOnInit() {
  }


  submit(){
    this.dialogRef.close(true);
  }

  cancel(){

    this.dialogRef.close(false);
  }

}


