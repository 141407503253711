import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";

@Component({
  selector: 'app-creative-operating-system',
  templateUrl: 'operating-system.component.html'
})

export class OperatingSystemComponent {

  @Input() subObjective;
  @Input() selectedOperatingSystem;
  @Input() appStoreURLControl;
  @Input() googlePlayURLControl;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService){

  }


  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

}
