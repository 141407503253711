import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {ApiService} from '../../services/api.service';
import {GlobalfunctionalityService} from '../../services/globalfunctionality.service';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare var moment: any;
declare var toastr: any;


import {CampaignApproveDialogComponent} from './dialogs/campaign-approve-dialog/campaign-approve-dialog.component';
import {CampaignRejectDialogComponent} from './dialogs/campaign-reject-dialog/campaign-reject-dialog.component';
import {CreativeRejectDialogComponent} from './dialogs/creative-reject-dialog/creative-reject-dialog.component';
import {CreativeApproveDialogComponent} from './dialogs/creative-approve-dialog/creative-approve-dialog.component';
import {CompanyRejectDialogComponent} from './dialogs/company-reject-dialog/company-reject-dialog.component';
import {CompanyApproveDialogComponent} from './dialogs/company-approve-dialog/company-approve-dialog.component';
import {InviteApproveDialogComponent} from './dialogs/invite-approve-dialog/invite-approve-dialog.component';
import {InviteRejectDialogComponent} from './dialogs/invite-reject-dialog/invite-reject-dialog.component';
import {CampaignRebookDialogComponent} from "./dialogs/campaign-rebook-dialog/campaign-rebook-dialog.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PreviewDialogComponent} from "../campaign/modules/creative_components/ad-for-size/preview-dialog/preview-dialog.component";
import {forkJoin} from "rxjs";
import {CampaignRemoveDialogComponent} from "./dialogs/campaign-remove-dialog/campaign-remove-dialog.component";
import {ResponseBodyModel} from "../../models/response-body.model";
import {TableMetadataModel} from "../../models/table-metadata.model";
import {CampaignService} from "../campaign/campaign.service";

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TaskComponent implements OnInit {

  taskIndex;
  columns: any = ['task_display_name', 'campaign_task', 'status', 'start_date_formatted', 'company', 'creation_date_formatted'];
  headers =
    {
      task_display_name: "Uppgift",
      campaign_task: "Kampanj-ID",
      status: "Status",
      start_date_formatted: "Startdatum",
      company: "Företagsnamn",
      creation_date_formatted: "Datum skapad"
    };


  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;

  // For user and company task
  userInfo;
  companyInfo;

  tasks = {data: []};

  completedTasks = {data: []};
  otherTasks = {data: []};
  creativeMissingCampaigns = {data: []};
  /* rejectedTasks = {data: []};*/

  taskType;
  adScript;
  showDemoLink = false;


  campaignName;
  objectiveId;
  objectiveKey;

  reviewData: any = {};
  days;


  // Table variables
  column: string = 'id';
  direction: number = -1;
  selectedIndex: number;
  p: number = 1;
  task;

  limit: number = 10;

  copyMsg;
  selectedObjective;
  material;

  taskTab = 'active';

  billingObj: any = {};

  loadTaskComponent = true;
  loadTask = false;
  expandedElement;
  selectedTask: any;
  auxTasks;
  productType;
  JSON;
  selectedTabIndex = 0;

  taskFilterValue;

  tableFilterTimeout;
  tableFilterLoad: boolean = false;
  showPaginator: boolean = false;

  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              public dialog: MatDialog,
              public campaignService: CampaignService) {
    this.dataSource = new MatTableDataSource();
    this.JSON = JSON;
  }


  ngOnInit() {

    this.globalfunctionality.highlightMenuItem("task");

    this.globalfunctionality.detectChange
      .subscribe((value: boolean) => {
        setTimeout(() => {
          if (value && !this._cd['destroyed']) {
            this._cd.detectChanges();
          }
        }, 10);

      });

    if (this.store.user === undefined) {
      this.globalfunctionality.checkUser(() => {
        this.loadTasks();
      });
    } else {
      this.loadTasks();
    }
  }


  loadTasks() {
    this.selectedIndex = undefined;
    this.getTasks();
    this.detectChange();

  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  changeTab(event) {


    this.showPaginator = false; // Must set variable to true so that paginator component gets reinitialized
    this.tableMetadata.page = 0;
    let index = event.index;
    this.selectedTabIndex = index;

    if (index === 0) {
      this.taskTab = 'active';
    }

    if (index === 1) {
      this.taskTab = 'creatives missing';
    }

    if (index === 2) {
      this.taskTab = 'completed';
    }

    if (index === 3) {
      this.taskTab = 'rejected';
    }

    this.getTasks();
    this.detectChange();

  }


  formatTaskEntry(elm) {
    elm['creation_date_formatted'] = moment(elm['creation_date']).format('YYYY-MM-DD');
    if (elm.review_date !== '') {
      elm['review_date_formatted'] = moment(elm['review_date']).format('YYYY-MM-DD');
    }

    if (elm['asap']) {
      if (moment(new Date(moment(elm.campaign_start_date).format('YYYY-MM-DD'))).isAfter(moment(new Date()))) {  /*.format('YYYY-MM-DD')*/
        elm['start_date_formatted'] = 'ASAP';
      } else {
        elm['start_date_formatted'] = moment(elm['campaign_start_date']).format('YYYY-MM-DD');
        elm['asap'] = false;
      }
    } else {
      elm['start_date_formatted'] = moment(elm['campaign_start_date']).format('YYYY-MM-DD');
    }

    if (elm.task_types_id === 'campaign_task') {
      elm['task_display_name'] = 'Kampanj';
      elm['task_display_name_color'] = 'campaign';
    }
    if (elm.task_types_id === 'creatives_task') {
      elm['task_display_name'] = 'Material';
      elm['task_display_name_color'] = 'material';
    }

    if (elm.task_types_id === 'company_and_user_task') {
      elm['task_display_name'] = 'Ny kund';
      elm['task_display_name_color'] = 'client';
    }

    if (elm.task_types_id === 'appnexus_advertiser_task') {
      elm['task_display_name'] = 'Ny Appnexus kund';
      elm['task_display_name_color'] = 'client';
    }

    if (elm.task_types_id === 'invite_user') {
      elm['task_display_name'] = 'Användare inbjuden';
      elm['task_display_name_color'] = 'client';
    }


    switch (elm.status.toLowerCase()) {
      case 'error':
        elm['status_display'] = 'Fel';
        elm['status_color'] = 'alert';
        break;
      case 'completed':
        elm['status_display'] = 'Klar';
        elm['status_color'] = 'accent';
        break;
      case 'pending':
        elm['status_display'] = 'Granska';
        elm['status_color'] = 'secondary';
        break;
      case 'schibsted review':
        elm['status_display'] = 'Bearbetas';
        elm['status_color'] = 'secondary';
        break;
    }

    return elm;
  }


  getTasks() {
    const tab = this.selectedTabIndex === 0 ? 'active' : this.selectedTabIndex === 1 ? 'material_missing' : this.selectedTabIndex === 2 ? 'completed' : 'rejected';

    let params = '';
    const obj = this.tableMetadata;
    params = params + '&filter=' + obj.filter;

    if (obj.page !== undefined) {
      params = params + '&pageNumber=' + obj.page;
    }
    if (obj.page_size !== undefined) {
      params = params + '&pageSize=' + obj.page_size;
    }
    if (obj.sort !== undefined && (obj.sort_order !== undefined && obj.sort_order !== '')) {
      params = params + '&sort=' + obj.sort + ':' + obj.sort_order;
    }

    this.apiservice.getJSON(this.store.apiURL + '/TaskManagerServlet?tab=' + tab + params)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.tableMetadata.length = res.pagination.maxResult;
          if(tab === 'active'){
            this.store.activeTasks = this.tableMetadata.length;
          }
          this.tasks.data = res.data;
          this.tasks.data.map(elm => this.formatTaskEntry(elm));
          this.loadTaskComponent = false;
          this.showPaginator = true;
          this.dataSource = new MatTableDataSource(this.tasks.data);
          this.setDataSourceFilterPredicate();
          this.detectChange();
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }


  setDataSourceFilterPredicate() {
    // Remove filterPredicate??
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data.task_display_name.toLowerCase().includes(filter) || (data.campaign_task ? data.campaign_task.toString().includes(filter) : undefined) || data.company.toLowerCase().includes(filter);
    };
  }


  paginatorChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.getTasks();
  }

  sortChange(event) {
    this.tableMetadata.sort = event.active;
    this.tableMetadata.sort_order = event.direction;
    this.getTasks();
  }


  copyScript(event) {

    let index = event.index;
    let formatIndex = event.formatIndex;
    let format = event.format;
    let element = event.element;

    const textarea: any = document.getElementById('script-' + index + '-' + formatIndex + '-' + format + '-' + element.id);
    const msg: any = document.getElementById('copyMsg-' + index + '-' + formatIndex + '-' + format + '-' + element.id);
    msg.style.display = 'block';

    textarea.select();
    try {
      let success = document.execCommand('copy');
      if (success) {
        this.copyMsg = 'Skript kopierad';
      } else {
        this.copyMsg = 'Kopiering misslyckades';
        msg.style.color = '#F44336';
      }
    } catch (err) {
      this.copyMsg = 'Unsupported browser!';
      msg.style.color = '#F44336';
    }
  }


  previewCreative(event) {

    const set = event.set;
    const format = event.format;

    set['size'] = format['format'];
    set['device'] = format['selected_device'] ? format['selected_device'] : format['device'];
    set['objectiveId'] = event['objectiveId'];
    set['objectiveName'] = this.selectedTask.objective_name;
    this.dialog.open(PreviewDialogComponent,
      {
        data: set,
        panelClass: 'modal-size-auto'
      }
    );
  }

  resetTask(message) {
    toastr.info(message);
    window.scroll(0, 0);
    this.loadTaskComponent = true;

    // Detect change on loadTaskComponent variable
    this.detectChange();
    this.getTasks();
  }


  rebookCampaign(task) {

    const dialogRef = this.dialog.open(CampaignRebookDialogComponent, {
        data: {id_tasks: this.task},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let arr = [task.id];
        this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet?option=rebook_campaign", arr)
          .subscribe(res => {
            let body = res;
            if (body.responseCode === 200) {
              this.resetTask('Kampanjen är ombokad');
            }
          });
      }
    });
  }

  approveCampaign() {
    const dialogRef = this.dialog.open(CampaignApproveDialogComponent, {
        data: {id_tasks: this.task},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Kampanjen håller på att bli godkänd, denna process kan upp till 10 min');
      }
    });
  }


  approveCreatives(event) {
    const dialogRef = this.dialog.open(CreativeApproveDialogComponent, {
        data: {
          id_tasks: this.task.id,
          material: this.material,
          status: 11,
          event: event
        },
        panelClass: 'modal-size-xs'
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Material hanterad');
      }
    });
  }

  missingCreatives(event) {
    const dialogRef = this.dialog.open(CreativeApproveDialogComponent, {
        data: {
          id_tasks: this.task.id,
          material: this.material,
          status: 9,
          event: event
        },
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Material hanterad');
      }
    });
  }

  rejectCreatives(event) {
    const dialogRef = this.dialog.open(CreativeApproveDialogComponent, {
        data: {
          id_tasks: this.task.id,
          material: this.material,
          status: 6,
          event: event
        },
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Material hanterad');
      }
    });
  }


  rejectCampaign() {
    const dialogRef = this.dialog.open(CampaignRejectDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Kampanj nekad');
      }
    });
  }

  disableCampaign(task) {
    const dialogRef = this.dialog.open(CampaignRemoveDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Kampanj borttagen');
      }
    });
  }


  approveInvitedUser() {
    const dialogRef = this.dialog.open(InviteApproveDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Inbjuden användare godkänd');
      }
    });
  }

  rejectInvitedUser() {
    const dialogRef = this.dialog.open(InviteRejectDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Inbjuden användare nekad');
      }
    });
  }

  approveCompany() {
    const dialogRef = this.dialog.open(CompanyApproveDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Företag godkänd');
      }
    });
  }

  rejectCompany() {
    const dialogRef = this.dialog.open(CompanyRejectDialogComponent, {
        data: {id_tasks: this.task.id},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetTask('Företag nekad');
      }
    });
  }


  getModuleValues(task, index, sync?) {
    this.taskType = this.task.task_types_id;

    this.globalfunctionality.getCampaign(task.campaign_task, (campaign) => {

      let campaignObj = campaign.data[0];

      this.campaignName = campaignObj.name;
      this.objectiveId = campaignObj.objective;

      this.billingObj['billing_holder'] = campaignObj.billing_holder;
      this.billingObj['client'] = campaignObj.client;
      this.billingObj['billing_information'] = campaignObj.billing_information;

      this.selectedObjective = task.objective_id;
      this.objectiveKey = task.objective_name;


      this.apiservice.getJSON(this.store.apiURL + "/CampaignServlet?orderLinesByCampaignId=" + task.campaign_task)
        .subscribe(orderLines => {

          const formattedOrderlines = [];
          orderLines.forEach(elm => {
            const obj = {id: elm};
            formattedOrderlines.push(obj);
          });

          // TODO: REFACTOR, FUNCTION ONLY NEEDS TO FETCH THE VALUES, DOES OTHER STUFF
          this.globalfunctionality.getCampaignValues(formattedOrderlines, () => {
            this.reviewData = {};
            let obj = {};
            this.store.modules.forEach((elm) => {
              obj = this.globalfunctionality.buildReviewDataObject(elm, this.selectedObjective, this.reviewData, this.adScript, this.showDemoLink, this.material, this.productType);
              this.reviewData = obj['reviewData'];
              this.adScript = obj['adScript'];
              this.showDemoLink = obj['showDemoLink'];
              this.material = obj['material'];
              this.productType = obj['productType'];
            });

            this.loadTask = false;
            this.detectChange();
          }, false, false);
        });

    });


  }


  getUserAndCompanyValues(task, index) {
    this.task = task;
    this.taskType = this.task.task_types_id;

    let arr = [task.user_task];

    // ASYNC CALLS, W8 FOR BOTH REQUESTS
    let user = this.apiservice.getJSON(this.store.apiURL + '/UserServlet?values=' + JSON.stringify(arr)); // this request already returns the company obj
    let company = this.apiservice.getJSON(this.store.apiURL + '/CompaniesServlet?values=' + JSON.stringify([this.task.company_task]));

    forkJoin([user, company]).subscribe(([user, company]) => {

      if (user.responseCode === 200) {
        user.data.forEach((elm) => {
          if (elm.id === this.task.user_task) {
            this.userInfo = elm;
            this.detectChange();
          }
        });
      }
      if (company.responseCode === 200) {
        this.companyInfo = company.data[0];
        this.detectChange();
      }
    });


    this.loadTask = false;

  }

  selectTask(task, index) {

    this.loadTask = true;
    if (task.task_types_id === 'creatives_task' || task.task_types_id === 'campaign_task') {
      this.copyMsg = '';
      this.adScript = '';
      this.task = task;
      this.getModuleValues(task, index);
    } else if (task.task_types_id === 'company_and_user_task' || task.task_types_id === 'invite_user' || task.task_types_id === 'appnexus_advertiser_task') {
      this.getUserAndCompanyValues(task, index);
    }
  }

  applyFilter(filterValue: string) {
    clearTimeout(this.tableFilterTimeout);
    this.tableFilterLoad = true;
    this.showPaginator = false;
    this.tableFilterTimeout = setTimeout(() => {
      this.tableFilterLoad = false;
      this.tableMetadata.filter = filterValue.trim().toLowerCase();
      this.getTasks();
      this.detectChange();
    }, 1000);


  }

  expandRow(element) {
    this.selectedTask = element;
    this.dataSource.data.forEach((elm, index) => {
      if (elm.id === element.id) {
        this.taskIndex = index;
      }
    });

    this.selectTask(element, this.taskIndex);

    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }

  }

  syncCampaign(event) {

    let task = event.task;
    let index = event.index;

    this.apiservice.getJSON(this.store.apiURL + '/TaskManagerServlet?sync=' + task.campaign_task)/*task.campaign_task*/
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.getModuleValues(task, index, true);
          toastr.info("Sync completed");
        } else {
          task['syncErrorMessage'] = res.responseMessage;
          toastr.warning('Sync error');

        }
        this.detectChange();
      });


  }

}

