import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../../../services/store.service";
import {ApiService} from "../../../../../../services/api.service";
import {CampaignService} from "../../../../campaign.service";
import {CreativemoduleService} from "../../creativemodule/creativemodule.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.css']
})
export class PreviewDialogComponent implements OnInit {

  script;
  creativeData;
  selectedPreviewIndex: number = 0;
  adPreviews: any[];
  selectedPreviewSite;

  vastPreviewURL: string;

  videoExtension: string;
  previewableAd: boolean = true;
  subObjective;
  selectedOperatingSystem;

  mmsCode;
  adops_created_mmscode: boolean = false;

  objective = {
    id: null,
    name: null
  }

  selectedPreviewScale =
    {
      scale: 100,
      width: 320,
      height: 300,
      value: '320x320'
    };

  adDimensions =
    [
      {
        name: 'Panorama 320x320',
        value: '320x320',
        width: 320,
        height: 320,
        scale: 100,
        type: 'mobile',
        disabled: false
      },
      {
        name: 'Panorama 980x240',
        value: '980x240',
        width: 980,
        height: 240,
        scale: 40,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Modul 640x320',
        value: '640x320',
        width: 640,
        height: 320,
        scale: 75,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Widescreen 250x600',
        value: '250x600',
        width: 250,
        height: 600,
        scale: 50,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Insider 300x480',
        value: '300x480',
        width: 300,
        height: 480,
        scale: 75,
        type: 'desktop',
        disabled: false
      },
    ];

  dynamicAd: boolean = false;

  constructor(public dialogRef: MatDialogRef<PreviewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public globalfunctionality: GlobalfunctionalityService,
              public store: StoreService,
              private apiservice: ApiService,
              public campaignService: CampaignService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer,
              private _cd: ChangeDetectorRef) {

    iconRegistry.addSvgIcon(
      'eye-slash',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/eye-slash.svg'));

  }

  ngOnInit() {
    // Set producttype if type is selected
    if(!this.store.productType) {
      const adtype = this.globalfunctionality.getModuleValues('adtype');
      if (adtype) {
        if (adtype.selectedType) {
          this.store.productType = adtype.selectedType;
        }
      }
    }


    if(this.campaignService.structure.get('objective').value?.id === 6 || this.data.objectiveId === 6){
      if(this.globalfunctionality.getModuleValues('subobjective')) {
        this.subObjective = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective;
        if (this.subObjective.id === 3) {
          const deviceValue = this.globalfunctionality.getModuleValues('device');
          if (deviceValue.selectedDeviceIndex !== 0) {
            const filter = deviceValue.selectedTargetOption === 'Desktops & Laptops' ? 'desktop' : 'mobile';
            this.adDimensions.filter(elm => elm.type !== filter).forEach(elm => elm.disabled = true);
            this.selectedPreviewScale = this.adDimensions.filter(elm => elm.type === filter)[0];
          }
        }
      }
    }

    this.objective['id'] =  this.data.objectiveId ?  this.data.objectiveId : this.campaignService.structure.get('objective').value.id;
    this.objective['name'] =  this.data.objectiveName ?  this.data.objectiveName : this.campaignService.structure.get('objective').value.name;

    if (this.data.creatives) {
      if (this.data.creatives.noPreview) {
        this.previewableAd = false;
        this.videoExtension = this.data.creatives.videoExtension;
      }
    }

    if(this.objective.id !== 6) {
      this.checkIfMMSCodeExists();
    }

    if (this.previewableAd) {
      if (this.data.banners && this.data.adcreation.selectedMethod !== 'existing_tag') {
        this.script = this.data.banners[0]['adScript'];
      } else if (this.data.script) {
        if (this.data.script.type === 'VAST') {
          const XMLparser = new DOMParser();
          const xmlDoc = XMLparser.parseFromString(this.data.script.script, 'text/xml');

          let mediaFiles = xmlDoc.getElementsByTagName('MediaFiles')[0];
          const patt = new RegExp("http(.*)mp4");
          if (mediaFiles) {
            for (let i = 0; i < mediaFiles.childNodes.length; i++) {
              if (mediaFiles.childNodes[i].childNodes.length > 0) {
                if (patt.test(mediaFiles.childNodes[i].childNodes[1].nodeValue)) {
                  this.vastPreviewURL = mediaFiles.childNodes[i].childNodes[1].nodeValue;
                  break;
                }
              }
            }
          }
        } else if (this.data.script.acm_script) {
          this.script = this.data.script.acm_script.replace('${CLICK_URL}', '');
        } else {
          this.script = this.data.script.script.replace('${CLICK_URL}', '');
        }
      } else if (this.data.archieveScript) {
        this.script = this.data.archieveScript;
      }
    }

    // Prisjakt responsiv format fix
    if (this.objective.id === 1) {
      const values = this.globalfunctionality.getModuleValues('adforsize');
      if (values['dynamic_ad']) {
        this.dynamicAd = true;
        this.store.productType = 'ResponsivAnnons';
      }
    }

    if (this.objective.id !== 6 && this.store.productType !== 'FreeTextAnnons' && this.store.productType !== 'ResponsivAnnons') {
      if (this.previewableAd) {
        this._cd.detectChanges();
        const iframe: any = document.getElementById('adPreviewIframe');
        if (iframe !== null) {
          iframe.srcdoc = this.script;
        }
      }
    } else {
      if(!this.data.acm_data) {
        const creatives = this.data.creatives;
        if (this.data.creatives.creative_data) {
          this.creativeData = creatives.creative_data.facesArray[0];
        } else if (creatives.selectedImages) {
          this.creativeData = creatives.selectedImages[0]; // Old module structure
        }
      }
    }

    // Used for CPC flow
    if (this.objective.id === 6) {
      this.adPreviews = this.store.langJSON.component.objectives[this.objective.name]['ad_previews'];
      this.subObjective = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective;
      const aux = [];
      if (this.subObjective.id === 1) {
        const deviceV = this.globalfunctionality.getModuleValues('device');
        if(deviceV) {
          const selectedSites = deviceV.sites.filter(elm => elm.selected);
          this.adPreviews.forEach(elm => {
            selectedSites.forEach(selected => {
              if (elm.name.toLowerCase() === selected.name.toLowerCase()) {
                aux.push(elm);
              }
            });
          });
        }else{ // backwards compatibility
          aux.push({name: this.subObjective.site, logo: this.subObjective.site.toLowerCase() + '.png'});
        }

      } else if(this.subObjective.id === 2) { // IF APPDOWNLOAD
        this.selectedOperatingSystem = this.globalfunctionality.getModuleValues('device').selectedOSTargetOption;
        this.adPreviews.forEach(elm => {
          if (elm.name.toLowerCase() === 'aftonbladet') {
            aux.push(elm);
          }
        });
      }else{
        this._cd.detectChanges();
        const iframe: any = document.getElementById('ACMPreviewIframe');
        if (iframe !== null) {
          const url = this.getGeneratedPageURL(this.data.acm_data.feed_option.ad.adTag);
          iframe.src = url;
        }
      }
      this.adPreviews = aux;
    } else if (this.store.productType === 'FreeTextAnnons') {
      this.adPreviews = this.store.langJSON.component.objectives[this.objective.name]['ad_previews'].filter(elm => elm.name.toLowerCase() === 'blocket');
    } else if (this.store.productType === 'ResponsivAnnons') {
      this.adPreviews = this.store.langJSON.component.objectives[this.objective.name]['ad_previews'].filter(elm => elm.name.toLowerCase() !== 'omni' && elm.name.toLowerCase() !== 'tv.nu');
    }
    if(this.adPreviews) {
      this.selectedPreviewSite = this.adPreviews[0];
    }
  }

  checkIfMMSCodeExists() {
    if (this.data.libraryMMSCode) {
      this.mmsCode = this.data.libraryMMSCode;
    } else if (this.data.script) {
      this.mmsCode = this.data.script.MMSCode;
      this.adops_created_mmscode = this.data.script.adops_created_mmscode;
    } else if (this.data.creatives) {
      this.mmsCode = this.data.creatives.creative_data?.facesArray[0].MMSCode;
      this.adops_created_mmscode = this.data.creatives.creative_data?.facesArray[0].adops_created_mmscode;
    }
  }

  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.value === o2.value;
    }
  }

  getGeneratedPageURL = (html) => {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], {type});
      return URL.createObjectURL(blob);
    };

    const source = `
    <html>
      <head>
        
      </head>
      <body>
        ${html || ''}
      </body>
    </html>
  `;
    return getBlobURL(source, 'text/html');
  }

  close() {
    this.dialogRef.close();
  }

}
