import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MAT_MENU_SCROLL_STRATEGY, MatMenuTrigger } from '@angular/material/menu';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTree } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime } from 'rxjs/operators';
import { GeoDataTreeService, ItemFlatNode, ItemNode, County, Locality, District } from '../../../../../../services/geo-data-tree.service';
import { Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => RepositionScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

@Component({
  selector: 'app-geo-data-tree',
  templateUrl: './geo-data-tree.component.html',
  styleUrls: ['./geo-data-tree.component.css'],
  providers: [
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]}
  ]
})
export class GeoDataTreeComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() counties: County[];
  @Input() localities: Locality[];
  @Input() districts: District[];

  @Input() placeholder: string;

  /** Emitter for selection change */
  @Output() public selectionChange = new EventEmitter();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: ItemFlatNode | null = null;

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  @ViewChild('filterInput') filterInput: ElementRef;
  @ViewChild('inputBtn') inputBtn: ElementRef;
  @ViewChild('matTree') matTree: MatTree<ItemFlatNode>;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  filterSub: Subscription;
  treeDataSub: Subscription;

  dataNodesMap: {[key:string]: ItemFlatNode } = {};
  dataNodes: ItemFlatNode[];

  countiesNodes: ItemFlatNode[];
  municipalitiesNodes: ItemFlatNode[];
  districtsNodes: ItemFlatNode[];

  showBrowse = true;

  constructor(
    private _treeDataSource: GeoDataTreeService,
    private _cd: ChangeDetectorRef
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.treeDataSub = this._treeDataSource.treData$.subscribe(data => {
       this.dataSource.data = data;
       if (!this.dataNodes || !this.dataNodes.length) {
          this.dataNodes = Array.from(this.nestedNodeMap.values())

          this.countiesNodes = this.dataNodes.filter(n => n.type === 'County')
          this.municipalitiesNodes = this.dataNodes.filter(n => n.type === 'Municipality')
          this.districtsNodes = this.dataNodes.filter(n => n.type === 'District')

          this.dataNodesMap = this.dataNodes.reduce(function(acc, node) {
            acc[node.value] = node;
            return acc;
          }, {});
       }
    });
  }

  ngAfterViewInit() {
    this.filterSub = fromEvent(this.filterInput.nativeElement, 'input').pipe(
      debounceTime(300)
    ).subscribe((inp: any) => {
      if (inp.target.value) {
        const searchString = inp.target.value;
        if (inp.target.value.length >= 2) {
          this.showMatched(searchString);
          this.matMenuTrigger.openMenu();
          this.showBrowse = true;
          this._cd.detectChanges();
        }
      } else {
        setTimeout(() => this.showAll(), 500);
        this.matMenuTrigger.closeMenu();
      }
    });
    this.matMenuTrigger._handleClick = function(e) { e.preventDefault() }
  }

  ngOnDestroy() {
    this.filterSub.unsubscribe();
    this.treeDataSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.counties && changes.counties.currentValue &&
      changes.localities && changes.localities.currentValue &&
      changes.districts && changes.districts.currentValue
    ) {
      this._treeDataSource.constructTree(
        changes.counties.currentValue,
        changes.localities.currentValue,
        changes.districts.currentValue
      )
    }
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node)
    let flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new ItemFlatNode();
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.name = node.name;
    flatNode.value = node.value;
    flatNode.filter_value = node.filter_value;
    flatNode.web = node.web;
    flatNode.type = node.type;
    flatNode.total = node.total;
    flatNode.childrenCount = node.childrenCount;
    flatNode.muleTargetingOptionsId = node.muleTargetingOptionsId;
    flatNode.muleTargetingId = node.muleTargetingId;
    flatNode.mobile = node.mobile;
    flatNode.expandable = !!(node.children && node.children.length);
    flatNode.parentName = node.parentName;
    if (!this.dataNodesMap[node.value]) {
      this.flatNodeMap.set(flatNode, node);
      this.nestedNodeMap.set(node, flatNode);
    }
    return flatNode;
  }

  getLevel = (node: ItemFlatNode) => node.level;
  isExpandable = (node: ItemFlatNode) => node.expandable;
  getChildren = (node: ItemNode): ItemNode[] => node.children;
  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;
  noResults = (i: number, node: ItemFlatNode) => node.name === 'Inga resultat';

  inputBtnClick() {
    if (!this.matMenuTrigger.menuOpen) {
      this.matMenuTrigger.openMenu();
    } else {
      if (this.showBrowse) {
        this.clearInput(false);
        this.showBrowse = false;
      } else {
        this.matMenuTrigger.closeMenu();
        this.showBrowse = true;
      }
    }
  }

  clearInput(showBrowse) {
    this.filterInput.nativeElement.value = '';
    setTimeout(() => this.showAll(), 500);
    this.showBrowse = showBrowse;
  }

  openMenu() {
    if (this.matMenuTrigger.menuOpen) {
      this.filterInput.nativeElement.value = '';
      this.showAll();
    } else {
      this.matMenuTrigger.openMenu();
    }
  }

  closeMenu() {
    this.matMenuTrigger.closeMenu();
  }

  menuOpened() {
    this.showBrowse = this.filterInput.nativeElement.value.length >= 2 ? true : false;
  }

  menuClosed() {
    this.clearInput(true);
  }

  showAll() {
    this._treeDataSource.reConstructTree(
      this.countiesNodes,
      this.municipalitiesNodes,
      this.districtsNodes
    )
    this.treeControl.collapseAll();
  }

  showMatched(searchString:string) {
    const counties = new Set;
    const municipalities = new Set;
    const districts = new Set;

    this.dataNodes.forEach(i => {
      if (this._normalize(i.name).indexOf(this._normalize(searchString)) !== -1) {
        switch (i.type) {
          case 'County':
            counties.add(i);
            break;
          case 'Municipality':
            municipalities.add(i);
            break;
          case 'District':
            districts.add(i);
            break;
          default:
            break;
        }
      }
    });

    if (counties.entries) {
      Array.from(counties).forEach((l: County) => {
        this.municipalitiesNodes.forEach(d => {
          if (d.parentName === l.name) {
            municipalities.add(d);
          }
        });
      })
    }

    if (municipalities.entries) {
      Array.from(municipalities).forEach((l: Locality) => {
        this.districtsNodes.forEach(d => {
          if (d.parentName === l.name) {
            districts.add(d);
          }
        });
      });
    }

    const machedNodes = Array.from(counties).concat(
      Array.from(municipalities),
      Array.from(districts)
    );

    if (machedNodes.length) {
      this._treeDataSource.reConstructTree(
        Array.from(counties),
        Array.from(municipalities),
        Array.from(districts),
        searchString
      );
      this.showBrowse = true;
    } else {
      // workaround to show no results message, without any glitches
      this._treeDataSource.reConstructTree(
        [{name: 'Inga resultat'}],
        [],
        []
      );
      this.showBrowse = true;
    }

    this.treeControl.expandAll();

    this.matTree.renderNodeChanges(this.treeControl.dataNodes);
  }

  _normalize(filter: string): string {
    return filter
      .trim()
      .replace(/[\u0300-\u036F]/g, '')
      .toLocaleLowerCase();
  }

  trackByName(node: ItemFlatNode) {
    return node.name
  }

  selectionChanged() {
    this.selectionChange.emit(this.checklistSelection.selected);
  }

  isSelected(dataNode: ItemFlatNode) {
    const node = this.dataNodesMap[dataNode.value]
    return this.checklistSelection.isSelected(node)
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(dataNode: ItemFlatNode): boolean {
    const node = this.dataNodesMap[dataNode.value]

    const descendants = this.getDescendants(node)
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(dataNode: ItemFlatNode): boolean {
    const node = this.dataNodesMap[dataNode.value]

    const descendants = this.getDescendants(node)
    const result = descendants.some(child => this.checklistSelection.isSelected(child));

    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  itemSelectionToggle(dataNode: ItemFlatNode, emitChange: boolean = true): void {
    const node = this.dataNodesMap[dataNode.value]

    this.checklistSelection.toggle(node);
    const descendants = this.getDescendants(node)

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);

    if (emitChange) {
      this.selectionChanged();
    }
  }

  getDescendants(dataNode: ItemFlatNode) {
    const node = dataNode ? this.dataNodesMap[dataNode.value] : undefined;

    const startIndex = this.dataNodes.indexOf(node);
    const results: ItemFlatNode[] = [];

    for (let i = startIndex + 1;
        i < this.dataNodes.length && this.getLevel(node) < this.getLevel(this.dataNodes[i]);
        i++) {
      results.push(this.dataNodes[i]);
    }
    return results;
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(dataNode: ItemFlatNode): void {
    const node = this.dataNodesMap[dataNode.value]

    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}
