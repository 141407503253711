import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild
} from '@angular/core';


import {Router} from "@angular/router";

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {TableMetadataModel} from "../../../models/table-metadata.model";

declare var toastr: any;

@Component({
  selector: 'app-pin-brand',
  templateUrl: 'pin-brand.component.html',
  styleUrls: ['pin-brand.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class PinBrandComponent implements OnInit {
  partners = [];
  selectedPartner;
  type = "partner";
  showPaginator: boolean = true;
  loadSync: boolean = false;

  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  // Table variables
  column: string = 'name';
  direction: number;
  show = false;
  selectedIndex: number;
  p: number = 1;

  limit: number = 20;

  showPinnedOnly = false;

  load = false;

  orginalData: any;

  filter: boolean = false;
  error: boolean = false;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columns: any = ['pinned', 'partnerName', 'partnerZipcode', 'partnerCity', 'partnerOrgNum'];
  headers =
    {
      pinned: "Välj annonsör",
      partnerName: "Annonsör",
      partnerZipcode: "Postnummer",
      partnerCity: "Ort",
      partnerOrgNum: "Org.nr."
    };

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              public globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getPartners();
    this.globalfunctionality.highlightMenuItem("management");
  }

  getPartners() {

    this.apiservice.getJSON(this.store.apiURL + "/CompaniesPartnersServlet")
      .subscribe(res => {
        let aux_partners;
        if (res.responseCode === 200) {
          aux_partners = res["data"];

          // Get pinned agencies
          this.apiservice.getJSON(this.store.apiURL + "/CompaniesServlet?companyId=" + this.store.user.companies[0].id)
            .subscribe(res => {
              if (res.responseCode === 200) {
                let agencies = res.data[0].agencies;

                let a_i = agencies.length;

                while (a_i--) {
                  let p_i = aux_partners.length;
                  while (p_i--) {
                    if (aux_partners[p_i]) {
                      if (agencies[a_i].agency_salesForceId === aux_partners[p_i].partnerSalesForceId) {
                        aux_partners[p_i]["pinned"] = true;
                        break;
                      }
                    }
                  }
                }
                this.partners = aux_partners;
                this.orginalData = Object.assign([], this.partners);

                this.decideItemsToBeShowed();
                this.dataSource.sort = this.sort;
                this.detectChange();
              }
            });
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }


  togglePinnedOnly() {
    this.showPinnedOnly = !this.showPinnedOnly;
    let arr = [];
    if (this.showPinnedOnly) {
      this.partners.forEach(elm => {
        if (elm.pinned) {
          arr.push(elm);
        }
      });
    } else {
      arr = this.partners;
    }
    this.dataSource = new MatTableDataSource(arr);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue ? true : false;

    this.showPaginator = false;
    this.detectChange();

    const data = Object.assign([], this.orginalData);
    const filtered = data.filter(elm => elm.partnerName.toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1 || elm.partnerOrgNum.toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1);

    const arr = [];
    for (let i = 0; i < this.tableMetadata.page_size; i++) {
      if (filtered[i]) {
        arr.push(filtered[i]);
      }
    }

    this.dataSource = new MatTableDataSource(arr);
    this.tableMetadata.length = filtered.length;
    this.tableMetadata.page = 0;

    this.showPaginator = true;
    this.detectChange();
  }

  detectChange() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }


  pinCompany(partner, index) {

    if (!this.load) {
      this.selectedIndex = index;
      this.selectedPartner = partner;
      this.load = true;
      this.apiservice.postJSON(this.store.apiURL + "/CompaniesPartnersServlet", [partner.partnerSalesForceId])
        .subscribe(res => {
          let body = res;

          if (body.responseCode !== 500) {
            this.globalfunctionality.getSharedCompaniesAndBrands(() => {

              if (partner["pinned"]) {
                partner["pinned"] = !partner["pinned"];
              } else {
                partner["pinned"] = true;
              }

              if (partner["pinned"]) {
                toastr.success(partner.partnerName + " " + this.store.langJSON.component.management.pin_brands.pinned_msg);
              } else {
                toastr.success(partner.partnerName + " " + this.store.langJSON.component.management.pin_brands.pinned_msg_removed);
              }

              this.load = false;
              this.detectChange();

              // Update brand list
              this.store.companiesList.forEach(comp => {
                if (comp.companies_id === this.store.user.companies[0].id) {
                  this.store.user.companies[0].brands = comp.brands;
                }
              });

            });
          } else {
            toastr.warning("Någonting gick fel");
            partner["pinned"] = false;
            this.load = false;
            this._cd.detectChanges();
          }
        });
    }
  }

  decideItemsToBeShowed() {
    const multiplier: number = (this.tableMetadata.page + 1);
    const amountToFetch: number = (this.tableMetadata.page_size * multiplier) - 1;
    const startNum: number = this.tableMetadata.page === 0 ? 0 : this.tableMetadata.page_size * this.tableMetadata.page;

    this.tableMetadata.length = this.partners.length;

    const displayElements = [];
    for (let i = startNum; i <= amountToFetch; i++) {
      if (this.partners[i]) {
        displayElements.push(this.partners[i]);
      }
    }
    this.dataSource = new MatTableDataSource(displayElements);
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.decideItemsToBeShowed();
  }

  sync() {
    this.loadSync = true;
    this.apiservice.getJSON(this.store.apiURL + "/CompaniesPartnersServlet?options=updatePartnerList")
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.error = false;
        } else {
          this.error = true;
        }
        this._cd.detectChanges();
        this.loadSync = false;
      });
  }

}
