import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {FormcontrolService} from "../../../../../services/formcontrol/formcontrol.service";
import {FormControl} from "@angular/forms";
import {CampaignService} from "../../../campaign.service";
import {Subscription} from "rxjs";

declare var moment: any;

@Component({
  selector: 'app-campaignnamemodule',
  templateUrl: './campaignnamemodule.component.html',
  styleUrls: ['./campaignnamemodule.component.css']
})
export class CampaignnamemoduleComponent implements OnInit, OnDestroy {
  campaignName;
  timeout;

  userHasModified = false;

  nameControl: FormControl;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              public form: FormcontrolService,
              public campaignService: CampaignService) {

  }

  objectiveChangeSubscription: Subscription;

  ngOnDestroy(): void {
    this.objectiveChangeSubscription.unsubscribe();
  }

  ngOnInit() {

    this.nameControl = this.campaignService.structure.get('name') as FormControl;

    this.objectiveChangeSubscription = this.globalfunctionality.objectiveChange.subscribe((changed: boolean) => {

      if (changed) {
        const objectiveName = this.campaignService.structure.get('objective').value !== null ? this.campaignService.structure.get('objective').value.name : undefined;
        if (!this.userHasModified && objectiveName && !this.store.freeCampaign) {
          let prefix = "";
          switch (this.campaignService.structure.get('objective').value.id) {
            case 1:
              prefix = "Sajtköp";
              break;
            case 2:
              prefix = "Målgrupp";
              break;
            case 6:
              prefix = "Driva trafik";
              break;
            case 7:
              prefix = "Sajtköp";
              break;
          }
          this.nameControl.setValue(`${prefix} - ${moment(new Date()).format("YYMMDD")}`);
        } else if (this.store.freeCampaign) {
          this.nameControl.setValue("Gratis kampanjerbjudande");
        }
        this.checkIfComplete();
      }
    });

    if (this.campaignService.structure.get('name').value) {
      this.nameControl.setValue(this.campaignService.structure.get('name').value);
    }

    this.checkIfComplete();
  }


  updateCampaignName() {
    if (!this.userHasModified) {
      this.userHasModified = true;
    }
    this.checkIfComplete();
  }


  checkIfComplete() {
    if (this.nameControl.value !== null) {
        sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
        this.globalfunctionality.page1Checker.next(true);
    }
  }
}
