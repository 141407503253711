import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {StoreService} from "../../../services/store.service";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";

import * as sha256 from "sha256";
import {Credentials} from "../../models/credentials.model";
import {StartpageService} from "../../startpage.service";
import {Subscription} from "rxjs";
import {LoginResponse} from "../../models/login-response.model";
import {Meta, Title} from "@angular/platform-browser";
import {ResponseBodyModel} from "../../../models/response-body.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class LoginComponent implements OnInit, OnDestroy {
  loginSubscription: Subscription;
  errorMsg: string;

  constructor(private startpage: StartpageService,
              private apiservice: ApiService,
              public store: StoreService,
              private router: Router,
              private globalfunctionality: GlobalfunctionalityService,
              private _cd: ChangeDetectorRef,
              private _meta: Meta,
              private _title: Title) {
  }

  ngOnDestroy() {
    if (this.loginSubscription !== undefined) {
      this.loginSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this._meta.updateTag({
      name: 'description',
      content: 'Välkommen till Schibsted AdMarket. Ange epostadress och lösenord för att logga in. ...eller skapa konto? Glömt lösenord?'
    });
    this._title.setTitle('Schibsted AdMarket | Logga in med epostadress och lösenord');
  }


  login(credentials: Credentials) {

    const obj: Credentials =
      {
        username: credentials.username.toLowerCase(),
        password: sha256(credentials.password)
      };

    this.loginSubscription = this.startpage.login(obj).subscribe(
      (res: LoginResponse) => {
        if (res.responseCode !== 200) {
          this.errorMsg = "Fel användarnamn eller lösenord";

          if (!this._cd["destroyed"]) {
            this._cd.detectChanges();
          }

        } else {
          sessionStorage.removeItem("free_campaign");
          const data = res.data;
          data.token = sha256(data.token);

          let default_brand = data['companies'][0].default_brand;
          this.store.selectedBrand = default_brand;

          this.store.userObj = data;
          sessionStorage.setItem("userObj", JSON.stringify(data));


          this.store.user = data;
          this.store.selectedMenuItem = 0;

          this.store.userCompany = this.store.user.companies[0];

          this.globalfunctionality.brandInit.next(true);
          if (!this._cd["destroyed"]) {
            this._cd.detectChanges();
          }
          this.globalfunctionality.clearVariables();

          this.globalfunctionality.checkUser(() => {

            this.router.navigate(['/dashboard/campaigns']);

          });

        }
      }
    );
  }


  clearErrorMsg() {
    this.errorMsg = "";
  }

  forgotPassword() {
    this.router.navigate(['login/forgotpassword']);
  }

  register() {
   /* this.router.navigate(['/register']);*/
    window.location.href = 'https://newadmarketbeta.schibsted.se/';
  }

}
