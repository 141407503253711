import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StoreService} from "../services/store.service";
import {GlobalfunctionalityService} from "../services/globalfunctionality.service";
import {ApiService} from "../services/api.service";
import {MailDialogComponent} from "./campaign/createcampaign/mail-dialog/mail-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CampaignLimitationDialogComponent} from "./dialog/campaign-limitation-dialog/campaign-limitation-dialog.component";
import {Meta, Title} from "@angular/platform-browser";
import {ResponseBodyModel} from "../models/response-body.model";
import {CampaignStatusNumbersModel} from "./campaign/models/campaign-status-numbers.model";
import {CampaignService} from "./campaign/campaign.service";
import {Subscription} from "rxjs";

declare var $: any;
declare var moment: any;
declare var toastr: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('companyFilterInput') companyFilterInput: ElementRef;

  showBookingOptions: boolean = false;
  companies;
  Moment;
  showEmailModal: boolean = false;
  showErrorList: boolean = false;
  selectedEmailSubject;
  emailMessage;
  readyToSend: boolean = false;

  loadNotification: boolean = false;

  emailSubjects =
    [
      "Kampanjbokning",
      "Materialhantering",
      "Fakturering och bokning",
      "Övriga frågor",
      "Feedback",
    ];


  companyList = [];
  companyForm: FormGroup;
  campaignStatusNumbers: CampaignStatusNumbersModel;
  warningCampaigns = [];
  showRevert: boolean = false;
  noCompaniesFound: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(public router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private _meta: Meta,
              private _title: Title,
              private fb: FormBuilder,
              public campaignService: CampaignService) {
    this.Moment = moment;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(elm => elm.unsubscribe());
  }

  ngOnInit() {

    this.subscriptions.push(this.globalfunctionality.openHelpPopup.subscribe((open: boolean) => {
      if(open) {
        this.selectedEmailSubject = 'Övriga frågor';
        this.showEmailModal = true;
      }
    }));

    this.subscriptions.push(this.globalfunctionality.brandChange.subscribe((value: boolean) => {
      if (value) {
        this.getCampaignStatusAmounts();
      }
    }));

    this.companyForm = this.fb.group({
      company: ['', [Validators.required]]
    });

    this._meta.updateTag({
      name: 'description',
      content: 'Du når 8 av 10 svenskar, varje vecka | Du väljer vilka som ska se din annons och kan följa dina kampanjer i realtid.'
    });
    this._title.setTitle('Schibsted AdMarket | Annonsera på Sveriges största sajter');

    this.globalfunctionality.detectChange
      .subscribe((value: boolean) => {
        if (value && !this._cd["destroyed"]) {
          setTimeout(() => {
            if (!this._cd['destroyed']) {
              this._cd.detectChanges();
            }
          }, 10);
        }
      });

    /**
     * This function will redirect to login page if no valid user is logged in
     */
    this.globalfunctionality.changeMenuLevel(0);

    if (this.store.user === undefined) {
      this.globalfunctionality.checkUser(() => {
        this.getDefaultData();
      });
    } else {
      this.getDefaultData();
    }
  }


  // REMOVE GETSHAREDCOMPANIES AND BRAND REQUEST, MOVE IT TO CAMPAIGN COMPONENT
  getDefaultData() {
    this.globalfunctionality.getLanguages(() => {
      this.globalfunctionality.getLanguageList(() => {
        this.globalfunctionality.getLanguageJson(this.store.selectedLanguage, () => {
          if (this.store.user === undefined) {
            this.router.navigate(["/login"]);
          } else {
            // Get error campaigns
            this.getCampaignStatusAmounts();

            if(this.store.isAdmin.value ||
              this.store.isAdminCampaignManager.value ||
              this.store.isAdminCompanyManager.value) {
              this.getNumberOfActiveTasks();
            }

            this.globalfunctionality.getSharedCompaniesAndBrands(() => {
              this.globalfunctionality.initComplete.next(true);
            }, true);
          }
        });
      });
    });
  }

  getNumberOfActiveTasks(){
    this.apiservice.getJSON(this.store.apiURL + `/TaskManagerServlet?tab=active&filter=&pageNumber=0&pageSize=25`)
      .subscribe(res => {
        if(res.responseCode === 200){
          this.store.activeTasks = res.pagination.maxResult;
          this.detectChange();
        }
      });
  }

  getCampaignStatusAmounts(): void {
    this.apiservice.getJSON(this.store.apiURL + '/CampaignServlet?countCampaigns=true' + this.getBrandParam())
      .subscribe((res: ResponseBodyModel) => {
        this.campaignStatusNumbers = res.data as CampaignStatusNumbersModel;
        this._cd.detectChanges();
      });
  }

  getCompanies() {
    // Close notifcation center if open
    this.showErrorList = false;
    this.showRevert = this.store.user.changed_company;
    this.showBookingOptions = !this.showBookingOptions;
    if (!this.showRevert) {
      if (this.companyList.length === 0) {
        this.apiservice.getJSON(this.store.apiURL + "/CompaniesServlet?profiles=true")
          .subscribe((res: ResponseBodyModel) => {
            if (res.responseCode === 200) {
              this.companyList = res.data;
              this.detectChange();
            } else if (res.responseCode === 450) {
              this.noCompaniesFound = true;
              this.detectChange();
            }

            this.companyFilterInput.nativeElement.focus();
          });
      }
    }
  }

  resetCompany() {
    this.showBookingOptions = false;
    this.apiservice.putJSON(this.store.apiURL + '/SchibstedBookingServlet', {})
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          sessionStorage.setItem("userObj", JSON.stringify(res.data));
          this.router.navigate(['dashboard/campaigns', 'refresh']);
        }
      });
  }

  selectCompany(company) {
    this.showBookingOptions = false;
    this.apiservice.postJSON(this.store.apiURL + '/SchibstedBookingServlet?company=' + company.id, {})
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          sessionStorage.setItem("userObj", JSON.stringify(res.data));
          this.router.navigate(['dashboard/campaigns', 'refresh']);
        }
      });
  }


  async setDefaultValues(campaign) {

    this.campaignService.structure.get('name').setValue(campaign.name);
    this.campaignService.structure.get('id').setValue(campaign.id);
    this.campaignService.structure.get('brand').get('id').setValue(campaign.brand);

    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }

    let promise = new Promise(resolve => {

      this.apiservice.getJSON(this.store.apiURL + "/CampaignServlet?orderLinesByCampaignId=" + campaign.id)
        .subscribe(res => {

          this.apiservice.getJSON(this.store.apiURL + "/OrderLinesServlet?values=" + JSON.stringify(res))
            .subscribe(res => {
              campaign['order_lines'] = res;
              this.campaignService.structure.get('order_lines').setValue(res);
              this.campaignService.structure.get('selected_product').setValue(res[0].product);

              this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.store.user.companies[0].id)
                .subscribe(res => {
                  res.data.forEach((elm) => {
                    if (elm.id === campaign.objective) {
                      this.campaignService.structure.get('objective').setValue(elm);
                      this.apiservice.getJSON(this.store.apiURL + "/ProductsServlet?values=" + JSON.stringify(elm.products)
                        + "&brand=" + this.campaignService.structure.get('brand').get('id').value + "&objective=" + elm.id)
                        .subscribe(res => {
                          this.campaignService.structure.get('products').setValue(res.data);
                          sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));
                          resolve(campaign);
                        });
                    }
                  });

                });
            });
        });

    });

    let res = await promise;
    return res;
  }

  getBrandParam(): string {
    let param = '';

    if (this.store.searchBrand.brands_id !== undefined && this.store.searchBrand.brands_name !== undefined) {
      if (this.store.searchBrand.brands_id !== 0 && (this.store.searchBrand.brands_name !== undefined ?
        this.store.searchBrand.brands_name.toLowerCase() !== 'alla annonsörer' :
        this.store.user.companies[0].default_brand.brands_name.toLowerCase() !== 'alla annonsörer')) {
        param = '&brand=' + this.store.searchBrand.brands_id;
      }
    }
    return param;
  }

  getCampaignData() {
    this.loadNotification = true;

    // Close company toggle menu
    this.showBookingOptions = false;
    this.showErrorList = !this.showErrorList;

    if (this.showErrorList) {
      this.apiservice.getJSON(this.store.apiURL + '/CampaignServlet?warningCampaigns=true' + this.getBrandParam())
        .subscribe((res: ResponseBodyModel) => {
          res.data.forEach(campaign => {
            if (campaign['combined_status'].status.toLowerCase() !== 'nekad') { // Show material review date
              if (campaign['tasks']) {
                campaign['tasks'].forEach(elm => {
                  if (elm.task_type.id === 2) {
                    campaign['days_from_creation'] = moment(new Date()).diff(
                      moment(elm['review_date'] !== '' ? elm['review_date'] : elm['creation_date']),
                      'days'
                    );
                  }
                });
              }
            } else { // Show campaign review date
              if (campaign['tasks']) {
                campaign['tasks'].forEach(elm => {
                  if (elm.task_type.id === 1) {
                    campaign['days_from_creation'] = moment(new Date()).diff(
                      moment(elm['review_date'] !== '' ? elm['review_date'] : elm['creation_date']),
                      'days'
                    );
                  }
                });
              }
            }
          });

          let campaignIds = res.data.map(elm => elm['id']);
          this.warningCampaigns = res.data;

          this.apiservice.getJSON(this.store.apiURL + '/ModulesValuesServlet?campaigns=' + JSON.stringify(campaignIds))
            .subscribe((res: ResponseBodyModel) => {
              if (res.responseCode === 200) {
                let filteredValues = res.data.filter(elm => elm['module_value_type'] === 'adforsize');
                this.warningCampaigns.forEach(elm => {

                  let value = filteredValues.find(val => elm.id === val['campaign_id']);
                  if (value) {
                    elm['selected_formats'] = JSON.parse(value['module_value_value']).selected;

                    if (elm['selected_formats']) {
                      elm['selected_formats'].forEach(elm => {
                        elm['creative_set'] = elm['creative_set'].filter(elm => elm.complete);
                      });
                    }
                  }
                });

                this.warningCampaigns.reverse();
                this.loadNotification = false;
                this.detectChange();
              }
            });
        });
    }
  }


  getCampaignRejectionMessage(campaign): string {
    if (campaign.tasks) {
      let campaignTask = campaign.tasks.filter(elm => elm.task_type['id'] === 1);
      if (campaignTask.length) {
        return JSON.parse(campaignTask[0].data)['rejection_reason'];
      }
    }
    return undefined;
  }


  uploadCreative(campaign) {

    const {selectedBrand, allowEdit} = this.setBrandAndEditRestriction(campaign);
    if (selectedBrand.write && allowEdit) {
      this.globalfunctionality.startAppLoad();
      this.setDefaultValues(campaign).then((campaign) => {
        this.globalfunctionality.getCampaignValues(campaign['order_lines'], "edit", true, false, ['dashboard/create', 'ads']);
      });
    } else {
      this.dialog.open(CampaignLimitationDialogComponent,
        {
          data: {
            write: selectedBrand.write,
            allowEdit: allowEdit
          },
          panelClass: 'modal-size-sm'
        });
    }
  }

  setBrandAndEditRestriction(campaign) {
    let allowEdit = true;
    if (campaign.legacy_campaign) {
      if (campaign.objective === 5 || campaign.objective === 1) {
        allowEdit = false;
      } else {
        if (campaign.combined_status_value.toLowerCase() === 'saknar mtrl' || campaign.combined_status_value.toLowerCase() === 'mtrl nekat') {
          allowEdit = true;
        } else {
          allowEdit = false;
        }
      }
    }
    // Check if the campaigns created brand has write access
    let selectedBrand;
    this.store.companiesList.forEach(elm => {
      if (elm.brands.filter(elm => campaign.brand === elm.brands_id).length) {
        selectedBrand = elm.brands.filter(elm => campaign.brand === elm.brands_id)[0];
      }
    });
    return {selectedBrand, allowEdit};
  }

  bookNewCampaign(campaign) {
    const {selectedBrand, allowEdit} = this.setBrandAndEditRestriction(campaign);
    if (selectedBrand.write && allowEdit) {
      this.globalfunctionality.startAppLoad();
      this.setDefaultValues(campaign).then((campaign) => {
        this.campaignService.structure.get('brand').get('id').setValue(campaign['brand']);
        this.globalfunctionality.getCampaignValues(campaign['order_lines'], "edit", true, true, ['dashboard/create', 'campaign']);
      });
    } else {
      this.dialog.open(CampaignLimitationDialogComponent,
        {
          data: {
            write: selectedBrand.write,
            allowEdit: allowEdit
          },
          panelClass: 'modal-size-sm'
        });
    }
  }


  detectChange() {
    this.globalfunctionality.initComplete.next(true);
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }


 /* openMailDialog() {
    const dialogRef = this.dialog.open(MailDialogComponent, {width: '500px'});
    dialogRef.afterClosed().subscribe(result => {
    });
  }*/


  navigate(location, params) {
    if (params === undefined) {
      this.router.navigate(["/" + location]);
    } else {
      this.router.navigate(["/" + location, params]);
    }
  }

  genericMenu(option) {
    this.store.appLevel = 0;
    switch (option) {
      case "home":
        this.globalfunctionality.highlightMenuItem("home");
        this._cd.detectChanges();
        this.navigate("dashboard/campaigns", undefined);
        break;
      case "campaigns":
        this.navigate("dashboard/campaigns", undefined);
        break;
      case "tasks":
        this.navigate("dashboard/task", undefined);
        break;
      case "settings":
        this.navigate("dashboard/settings", undefined);
        break;
      case "data_management":
        this.navigate("dashboard/data-management/feed", undefined);
        break;
      case "management":
        this.navigate("dashboard/management/users", undefined);
        break;
      case "users":
        this.navigate("dashboard/management/users", undefined);
        break;

      default:
        break;
    }
  }


  sendMail() {
    let send_to: string;
    switch (this.selectedEmailSubject) {
      case 'Kampanjbokning':
        send_to = 'admarket@schibsted.se';
        break;
      case 'Materialhantering':
        send_to = 'srv.sale.selfservice@schibsted.com';
        break;
      case 'Fakturering':
        send_to = 'salesops@schibsted.se';
        break;
      case 'Övriga frågor':
        send_to = 'admarket@schibsted.se';
        break;
      case 'Feedback':
        send_to = 'patrik.gudding@schibsted.com';
        break;
    }

    if (this.readyToSend) {
      let obj = {
        "mail_subject": this.selectedEmailSubject,
        "mail_html_body": "<div>Support request from: [user]</div><br><div>Message: \n [message]</div>",
        "mail_from": this.store.user.user_email,
        "send_to": send_to
      };
      obj.mail_html_body = obj.mail_html_body.replace("[message]", this.emailMessage);
      obj.mail_html_body = obj.mail_html_body.replace("[user]", this.store.user.user_email);

      this.apiservice.postJSON(this.store.apiURL + "/MailServlet", obj)
        .subscribe(res => {
          toastr.info(this.store.langJSON.component.mail_dialog.toastrmsg);
        });
    }
  }


  checkIfEmailReadyToBeSent() {
    let complete = false;
    if (this.selectedEmailSubject !== undefined && this.selectedEmailSubject !== "") {
      if (this.emailMessage !== undefined && this.emailMessage !== "") {
        complete = true;
      }
    }
    this.readyToSend = complete;
  }

  logout() {
    this.globalfunctionality.logoutUser();
  }
}
