import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchText: any, type: any, pinned?: boolean): any {
    if(!pinned) {
      if (searchText === null || searchText === undefined || type === undefined) {
        return value;
      }
    }

    // Add valdiation rules for deciding which fields that are searchable
    if (type === "task") {
      return value.filter(function (elm) {

        if(searchText !== undefined && searchText !== "") {
          if (elm.campaign_task) {
            return elm.campaign_task.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1;

          } else {
            return elm.task_display_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              || elm.id.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1
              || elm.status.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
          }
        }else{
          return elm;
        }
      });
    } else if (type === "user") {
      return value.filter(function (elm) {
        return elm.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          || elm.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          || elm.realname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    } else if (type === "company") {
      return value.filter(function (elm) {
          return elm.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
      );
    }else if(type === "brand"){
      return value.filter(function (elm) {
          return elm.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
      );
    }else if(type === "partner"){
      return value.filter(function (elm) {
        if(pinned){
          if(elm.pinned){
            if(searchText === undefined){
              searchText = "";
            }
            return elm.partnerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || elm.partnerOrgNum.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
          }
        }else{
          return elm.partnerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || elm.partnerOrgNum.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }

        }
      );
    }
  }
}
