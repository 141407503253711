import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../campaign/pipes/pipe.module';
import {MaterialModule} from "../../utils/MaterialModule";
import {CustomTargetService} from "../management/services/custom-target/custom-target.service";
import {CreateTargetComponent} from "./target/create-target/create-target.component";
import {CreateFeedDialogComponent} from "./feed/create-feed-dialog/create-feed-dialog.component";
import {DataManagementComponent} from "./data-management.component";
import {TargetComponent} from "./target/target.component";
import {FeedComponent} from "./feed/feed.component";
import {CommonModule} from "@angular/common";
import {PaginationModule} from "../../utils/pagination/pagination.module";


export const managementRoutes: Routes = [
  { path: 'target', component: TargetComponent },
  { path: 'feed', component: FeedComponent }
];

/*canActivate: [AuthGuard]*/

@NgModule({
  declarations: [
    DataManagementComponent,
    TargetComponent,
    FeedComponent,
    CreateTargetComponent,
    CreateFeedDialogComponent,
  ],

  imports: [
    RouterModule.forChild(managementRoutes),
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipeModule,
    PaginationModule
  ],
  exports: [RouterModule],
  providers: [CustomTargetService],
  entryComponents: [
    CreateTargetComponent,
    CreateFeedDialogComponent
  ]
})
export class DataManagementModule {}
