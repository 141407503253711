import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StoreService} from "../../../../../services/store.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {NewsPreviewDialogComponent} from "./news-preview.dialog/news-preview-dialog.component";
import {DateAdapter} from "@angular/material/core";

declare var toastr: any;

@Component({
  selector: 'app-create-news-dialog',
  templateUrl: './create-news-dialog.component.html'
})
export class CreateNewsDialogComponent {
  form: FormGroup;
  load: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateNewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private _dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog) {
    this.form = this._fb.group({
      title: ['', [Validators.required, Validators.maxLength(25)]],
      body: ['', [Validators.required, Validators.maxLength(200)]],
      image: [],
      type: ['news', [Validators.required]],
      schedule: ['', [Validators.required]]
    });
    this._dateAdapter.setLocale('sv');
  }

  close() {
    this.dialogRef.close();
  }


  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.name === o2.name;
    }
  }

  submitForm(isValid: boolean) {
    if (isValid) {
      this.load = true;

      const isoDateTime = new Date(this.form.get('schedule').value.getTime() - (this.form.get('schedule').value.getTimezoneOffset() * 60000)).toISOString();
      this.form.get('schedule').setValue(isoDateTime);
      this.dialogRef.close(this.form.value);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.form.get('image').setValue(this.croppedImage);
  }

  previewNews() {
    this.dialog.open(NewsPreviewDialogComponent, {
      panelClass: ['modal-size-md', 'modal-height-control'],
      data: this.form.value
    });
  }

}
