import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-reset-password-form',
  templateUrl: 'reset-password-form.component.html'
})

export class ResetPasswordFormComponent{

  @Input() expired: boolean;
  @Input() showPasswordMissmatch: boolean;
  @Input() translation: any;

  @Output() savePassword: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  form;

  constructor(private _fb: FormBuilder){
    this.form = this._fb.group(
      {
        password: ['', Validators.required],
        repassword: ['', Validators.required]
      }
    );
  }


  handleSave(isValid: boolean){
    if(isValid) {
      this.savePassword.emit(this.form.value);
    }
  }

  handleBack(){
    this.goBack.emit();
  }
}
