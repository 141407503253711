import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../../../services/store.service";
import {ApiService} from "../../../../../../services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";


@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css']
})
export class IframeDialogComponent implements OnInit {

  iframe;
  auxSelectedFormat =
    {
      name: undefined
    };

  timeout;

  // Used for obtaining value from language JSON
  replacedFormatName: string;


  constructor(
    public dialogRef: MatDialogRef<IframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private apiservice: ApiService,
    private _cd: ChangeDetectorRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'loader',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/loading-spinner.svg'));
  }


  ngOnInit() {
    this.auxSelectedFormat.name = this.data.selectedTemplate;
    this.iframe = <HTMLIFrameElement>document.getElementById("modalPreviewIframe");
    this.getAdPreview();
  }

  getAdPreview(){
    this.replacedFormatName = this.getReplacedFormatName();

    let templateName = this.data.selectedTemplate
    if (this.store.selectedSize.size !== '320x320') {
       templateName = this.data.selectedTemplate + " " + this.store.selectedSize.size;
    }

    this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(templateName))
      .subscribe(res => {
        if (res.responseCode === 200) {
          const banner = res.data[0];
          this.iframe.contentWindow.postMessage({
            html: banner.html,
            style: banner.style,
            script: banner.script,
            action: 'create',
            origin: banner.origin
          }, '*');
        }
      });
  }

  getReplacedFormatName(): string{
    return this.data.selectedTemplate.replace(/\s+/g, '_').toLowerCase();
  }

  changeVisibleFormat(getNext: boolean){

    clearTimeout(this.timeout);

    let index = this.data.templates.findIndex(elm => elm.name === this.data.selectedTemplate);
    if(getNext){
      const nextIndex = index === (this.data.templates.length - 1) ? 0 : index += 1;
      this.data.selectedTemplate = this.data.templates[nextIndex].name;
    }else{
      const nextIndex = index === 0 ? (this.data.templates.length - 1) : index -= 1;
      this.data.selectedTemplate = this.data.templates[nextIndex].name;
    }

    this.timeout = setTimeout(() => {
      this.getAdPreview();
    }, 500);

  }

  changeFormat(){
    this.dialogRef.close(this.data.selectedTemplate);
  }

  close(){
    this.data.selectedTemplate = this.auxSelectedFormat.name;
    this.dialogRef.close();
  }

}
