import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import { MatDialog } from "@angular/material/dialog";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {CampaignService} from "../../../campaign.service";

declare var moment: any;

@Component({
  selector: 'app-interestanddemographic',
  templateUrl: './interestanddemographic.component.html',
  styleUrls: ['./interestanddemographic.component.css']
})
export class InterestanddemographicComponent implements OnInit, OnDestroy {


  whiteSpace = / /g;
  interests = [];
  selectedInterestIndex;
  index;
  selectedInterest;
  formattedInterest = [];
  Math: any;
  selectedOption = "0";

  selectedSubinterest;

  customTargets: any[] = [];

  selectedCustomTargetIndex;
  selectedCustomTarget;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private createcampaign: CreatecampaignComponent,
              public dialog: MatDialog,
              private campaignService: CampaignService) {
    this.Math = Math;
  }


  ngOnDestroy() {
  }

  ngOnInit() {

    this.globalfunctionality.interestReset.subscribe((reset: boolean) => {

      if (reset) {
        // REFACTOR
        this.selectedInterestIndex = undefined;
        this.selectedInterest = undefined;
        this.selectedCustomTargetIndex = undefined;
        this.selectedCustomTarget = undefined;
        let interestModule =   this.globalfunctionality.getModuleValues("interestanddemographic");
        interestModule["selectedInterestIndex"] = undefined;
        interestModule["selectedInterest"] = undefined;
        interestModule["selectedCustomTarget"] = undefined;
        interestModule["selectedCustomTargetIndex"] = undefined;
        interestModule["selectedCustomTargetName"] = undefined;
        interestModule["selectedSubinterest"] = undefined;
        interestModule["selectedOption"] = '0';
        this.store.forecast.interests = [];
        this.store.forecast.custom_targets = [];
        this.selectedOption = '0';

        this.globalfunctionality.checkForAudienceReach();
        this.checkIfComplete();

      }

    });

    // Check if any value has earlier been selected
    const values = this.globalfunctionality.getModuleValues("interestanddemographic");

    if (values) {

      const values = this.globalfunctionality.getModuleValues("interestanddemographic");
      if (values['selectedOption']) {
        this.selectedOption = values['selectedOption'];
      }

      if (this.selectedOption === '1') {
        if (values["selectedInterestIndex"] !== undefined) {
          this.selectedInterestIndex = values["selectedInterestIndex"];
          this.selectedInterest = values["selectedInterest"];
          this.selectedSubinterest = values['selectedSubinterest'];
          this.store.forecast.interests = [];
          if(this.selectedSubinterest && this.selectedSubinterest !== 'all_categories'){
            this.store.forecast.interests.push(this.selectedSubinterest);
          }else{
            this.store.forecast.interests.push(this.selectedInterest);
          }
        }
      } else if (this.selectedOption === '2') {
        this.globalfunctionality.resetAndHideDemography.next(true);

        if (values['selectedCustomTargetIndex'] !== undefined) {
          this.selectedCustomTargetIndex = values["selectedCustomTargetIndex"];
          this.selectedCustomTarget = values["selectedCustomTarget"];
          this.store.forecast.custom_targets = [];
          this.store.forecast.custom_targets.push(this.selectedCustomTarget);
        }
      }
    }

    this.interests = [];

    /*if(!this.store.selectedBrand){
      this.globalfunctionality.restoreSelectedBrand();
    }*/

    this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet?brand=" + this.campaignService.structure.get('brand').get('id').value)
      .subscribe((res: ResponseBodyModel) => {

        if (res.responseCode === 200) {

          if (res.data['customTargets']) {
            if (res.data['customTargets'].length) {
              this.customTargets = res.data['customTargets'];
            }
          }


          this.interests = res.data["interests"];

          this.interests.forEach((elm) => {
            let obj = {};
            obj["value"] = elm.target_option;
            obj['key'] = this.replaceCharacters(elm);
            obj["key"] = obj["key"].replace(/ /g, '');

            if (elm.subInterests.length) {
              obj['subInterests'] = elm.subInterests;
              obj['subInterests'].unshift({target_option: 'all_categories'});
            }

            this.formattedInterest.push(obj);
          });


          this.checkIfComplete();
        }

      });
  }

  replaceCharacters(elm): string {
    if (elm.target_option.includes('&')) {
      return elm.target_option.replace("&", "_");
    } else if (elm.target_option.toLowerCase().includes('och')) {
      return elm.target_option.replace("och", "_");
    } else {
      return elm.target_option;
    }
  }


  selectCustomTarget(target, index) {
    const values = this.globalfunctionality.getModuleValues("interestanddemographic");
    if (index !== this.selectedCustomTargetIndex) {
      this.selectedCustomTargetIndex = index;
      this.selectedCustomTarget = target.id;

      values["selectedCustomTargetIndex"] = this.selectedCustomTargetIndex;
      values["selectedCustomTarget"] = this.selectedCustomTarget;
      values["selectedCustomTargetName"] = target.target_option;
    }

    this.store.showReachWarning = false;
    this.store.forecast.custom_targets = [];
    this.store.forecast.custom_targets.push(this.selectedCustomTarget);

    this.checkIfComplete();
  }

  selectInterest(interest, index) {
    const values = this.globalfunctionality.getModuleValues("interestanddemographic");
    if (index !== this.selectedInterestIndex) {
      this.selectedInterestIndex = index;
      this.selectedInterest = interest.value;
      this.selectedSubinterest = 'all_categories';

      values["selectedInterestIndex"] = this.selectedInterestIndex;
      values["selectedInterest"] = this.selectedInterest;
      values["selectedSubinterest"] = this.selectedSubinterest;
    }

    this.store.showReachWarning = false;
    this.store.forecast.interests = [];
    this.store.forecast.interests.push(this.selectedInterest);


    this.checkIfComplete();

  }

  subInterestChange() {
    const values = this.globalfunctionality.getModuleValues("interestanddemographic");
    this.store.forecast.interests = [];
    values["selectedSubinterest"] = this.selectedSubinterest;

    if (this.selectedSubinterest !== 'all_categories') {
      this.store.forecast.interests.push(this.selectedSubinterest);
    } else {
      this.store.forecast.interests.push(this.selectedInterest);
    }

    this.store.showReachWarning = false;

    this.globalfunctionality.setModuleValue('interestanddemographic', values);
    this.checkIfComplete();
  }

  changeSelection() {

    const values = this.globalfunctionality.getModuleValues("interestanddemographic");
    values['selectedOption'] = this.selectedOption;
    this.globalfunctionality.setModuleValue('interestanddemographic', values);

    if(this.selectedOption === '2'){
      this.globalfunctionality.resetAndHideDemography.next(true);
    }else{
      this.globalfunctionality.showDemography.next(true);
    }

    if (values) {
      this.selectedInterestIndex = undefined;
      this.selectedInterest = undefined;
      this.selectedCustomTargetIndex = undefined;
      this.selectedCustomTarget = undefined;
      delete values["displayValue"];
      delete values["selectedInterestIndex"];
      delete values["selectedCustomTargetIndex"];
      delete values["selectedCustomTarget"];
      delete values["selectedInterest"];
      delete values["selectedCustomTargetName"];
      delete values["selectedSubinterest"];

      this.store.forecast.interests = [];
      this.store.forecast.custom_targets = [];

    }


    this.checkIfComplete();
  }


  checkIfComplete() {
    let values = this.globalfunctionality.getModuleValues("interestanddemographic");

    if (values) {
      if (this.store.GUIFlow[2]) {
        this.store.GUIFlow[2].forEach(function (elm) {
          if (elm.type === "interestanddemographic") {
            elm["module_values"].isComplete = true;
            values.isComplete = true;
          }
        });
      }

      this.globalfunctionality.setModuleValue('interestanddemographic', values);

      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.globalfunctionality.interestChange.next(true);
      this.globalfunctionality.page2Checker.next(true);
    }
  }
}
