import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";


@Component({
  selector: 'app-company-reject-dialog',
  templateUrl: './company-reject-dialog.component.html',
  styleUrls: ['./company-reject-dialog.component.css']
})
export class CompanyRejectDialogComponent implements OnInit{
  rejectCompanyMessage;

  selectedRejection;
  rejectionMessages: any[];

  constructor(public dialogRef: MatDialogRef<CompanyRejectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) { }

  ngOnInit(): void{
    this.rejectionMessages = this.store.langJSON.component.task_manager.company_rejection_messages;
  }

  submit(){
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 6,
        rejection_reason: this.rejectCompanyMessage === undefined ? '' : this.rejectCompanyMessage
      };

    data.push(obj);
    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe(res => {
        this.dialogRef.close(true);
      });
  }
  cancel(){

    this.dialogRef.close(false);
  }

}
