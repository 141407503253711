import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { StoreService } from '../store.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private store: StoreService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add token to request header
    if (request.url.startsWith(environment.endpoint)) {
      let token;
      if (this.store.user === undefined) {
        if (sessionStorage.getItem('userObj')) {
          if (JSON.parse(sessionStorage.getItem('userObj'))) {
            token = JSON.parse(sessionStorage.getItem('userObj'))['token'];
          }
        }
      } else {
        token = this.store.user.token;
      }

      if (token) {
        request = request.clone({
          setHeaders: {
            token: token
          }
        });

        return next.handle(request);
      }
    }

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }
];
