import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {StoreService} from "../../../../../services/store.service";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {ResponseBodyModel} from "../../../../../models/response-body.model";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {CampaignService} from "../../../campaign.service";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-adtoox',
  templateUrl: './adtoox.component.html'

})

export class AdtooxComponent implements OnInit{

  adtooxGroup: FormGroup = new FormGroup(
    {
      adName: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      clickLink:  new FormControl('https://', [
        Validators.required,
        this.ValidateUrl,
        this.ValidateWholeUrl,
      ]),
      impTracker:   new FormControl('', [])
    }
  );

  hideImpAndClickTracker: boolean = true;
  urlValidated: boolean = false;
  updateCreatives: boolean = false;

  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private _cd: ChangeDetectorRef,
    private campaignService: CampaignService,
    public creativeService: CreativemoduleService
  ){

  }


  ngOnInit(): void {
    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;

    const moduleValue = this.globalfunctionality.getModuleValues("adtoox");

    this.setAdName(moduleValue.adName);

    if(moduleValue.clickLink){
      this.adtooxGroup.get('clickLink').setValue(moduleValue.clickLink);
    }

    if(moduleValue.impTracker){
      this.adtooxGroup.get('impTracker').setValue(moduleValue.impTracker);
    }

    this.adtooxGroup.get('clickLink').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        return this.apiservice.getJSON(this.store.apiURL + "/UrlValidatorServlet?value=" + this.adtooxGroup.get('clickLink').value);
      })
    ).subscribe((res: ResponseBodyModel) => {
      if(res.responseCode === 200) {
        this.urlValidated = res.data['validation'];
      }
    });

  }
  setAdName(name) {
    if (name) {
      this.adtooxGroup.get('adName').setValue(name);
    } else {
      this.adtooxGroup.get('adName').setValue(this.store.selectedSize.setIndex === 0 ? this.campaignService.structure.get('name').value :
        this.campaignService.structure.get('name').value + ' - ' + this.store.selectedSize.setIndex);
    }
  }


  ValidateUrl(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if ((control.value.startsWith('https://') || control.value.startsWith('http://'))) {
      return null;
    }
    return {validUrl: true};
  }


  ValidateWholeUrl(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value)) {
      return null;
    }
    return {validWholeUrl: true};
  }


  save() {

    if(this.adtooxGroup.valid) {
      this.checkIfComplete();

      const adtoox = this.globalfunctionality.getModuleValues('adtoox');

      if (adtoox.isComplete) {
        const adForSize = this.globalfunctionality.getModuleValues('adforsize');
        const adcreation = this.globalfunctionality.getModuleValues('adcreation');

        let formatIndex;
        adForSize.selected.forEach((elm, index) => {
          if (elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format) {
            formatIndex = index;
          }
        });

        adForSize['selected'][formatIndex]['complete'] = true;

        const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
        set['status'] = 'Pending';

        // If updating creatives set creative statuses
        if (set['complete']) {
          if (this.updateCreatives) {
            set['updated'] = true;
            toastr.info('Material uppdaterat');
          }
        } else {
          set['added'] = true;
          toastr.info('Material sparat');
        }

        // Remove creatives object if exists (user has first created own creative, then changed to third party)
        if (set['creatives']) {
          delete set['creatives'];
        }

        if (set['script']) {
          delete set['script'];
        }

        set['adcreation'] = adcreation;
        set['adtoox'] = adtoox;
        set['complete'] = true;
        sessionStorage.setItem('modules', JSON.stringify(this.store.modules));

        this.reset(true);
        this.detectChange();
      }
    }
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  reset(trigger?) {



    $('.modal-backdrop').removeClass('is-active');
    let modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;
    const creationParam = this.globalfunctionality.getModule('adcreation');
    creationParam.param.output = '';


    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = '';
        const id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
      }
    });

    this.clearModuleValues();
    this.detectChange();
    if (trigger) {
      this.globalfunctionality.adCreated.next(true);
    }

  }

  clearModuleValues() {
    this.store.modules.forEach((elm) => {
      if (elm.type === 'templates') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }
      if (elm.type === 'adcreation') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.param.output = '';
      }

      if (elm.type === 'creatives') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

      if (elm.type === 'existingtag') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

      if (elm.type === 'adtoox') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

    });
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }


  checkIfComplete(){
    const moduleValue = this.globalfunctionality.getModuleValues("adtoox");
    if(this.adtooxGroup.valid) {
      moduleValue.adName = this.adtooxGroup.get('adName').value;
      moduleValue.clickLink = this.adtooxGroup.get('clickLink').value;

      if(this.adtooxGroup.get('impTracker').value){
        moduleValue.impTracker = this.adtooxGroup.get('impTracker').value;
      }
      moduleValue.isComplete = true;

      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if(elm.type === "adtoox"){
          elm["module_values"].isComplete = true;
        }
      });
    }else{
      moduleValue.isComplete = false;
      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if(elm.type === "adtoox"){
          elm["module_values"].isComplete = false;
        }
      });
    }
    this.globalfunctionality.detectChange.next(true);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

}
