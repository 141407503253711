import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";

declare var moment: any;
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {


  @Input() data;

  notifications;
  stepper = {};

  showNotifications: boolean = false;

  constructor(
    private apiservice: ApiService,
    public store: StoreService,
    private globalfunctionality: GlobalfunctionalityService
  ) {
  }


  ngOnInit() {
    this.getNotifications(this.data.row.id);
  }


  getNotifications(campaignId) {
    this.apiservice.getJSON(`${this.store.apiURL}/NotificationServlet?campaignId=${campaignId}`)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.notifications = res.data
            .map(elm => {
              elm['isCreativeNotification'] = elm.notification_type.includes('creative');
              elm['isCampaignRejected'] = elm.notification_type.includes('campaign_rejection');
              elm['date_parsed'] = moment(elm.date).format('YYYY-MM-DD');
              elm['time'] = moment(elm.date).format('HH:mm');

              if (elm.creative_task) {
                if (elm.creative_task.adForSize) {
                  const formats = JSON.parse(elm.creative_task.adForSize)['selected'];
                  formats.forEach(elm => {
                    elm['activeSets'] = elm.creative_set.filter(elm => elm.visible && elm.complete).length;
                    if(elm.creatives_status) {
                      if (elm.creatives_status.toUpperCase() === 'APPROVED') {
                        elm['handled_count'] = elm.creative_set.filter(set => set.visible && set.complete && set.status.toUpperCase() === 'APPROVED').length;
                      } else if (elm.creatives_status.toUpperCase() !== 'PENDING') {
                        elm['handled_count'] = elm.creative_set.filter(set => set.visible && set.complete && (set.status.toUpperCase() === 'REJECTED' || set.status.toUpperCase() === 'PENDING')).length;
                      }
                    }
                  });
                  elm['formats'] = formats;
                }
              }

              if (elm.notification_type.includes('creative_rejection') || elm.notification_type.includes('campaign_rejection')) {
                elm['statusColor'] = 'alert';
              }

              switch (elm.notification_type) {
                case 'campaign_sent_for_booking':
                  elm['text'] = 'Kampanj inskickad';
                  elm['desc_text'] = `Kampanj "${elm.campaignName}" inskickad`;
                  this.stepper['campaign_sent_for_booking'] = elm;
                  break;
                case 'creative_sent_for_booking':
                  elm['text'] = 'Material inskickat';
                  elm['desc_text'] = 'Material har skickats in';
                  this.stepper['creative_sent_for_booking'] = elm;
                  break;
                case 'creative_updated_by_user':
                  elm['text'] = 'Material uppdaterad';
                  // If material has been updated go through all formats creative set and see if anything is pending
                  elm.formats.forEach(elm => {
                    let pending: boolean = false;
                    let pendingCount: number = 0;
                    elm.creative_set.forEach(set => {
                      if(set.status) {
                        if (set.status.toUpperCase() === 'PENDING') {
                          pending = true;
                          pendingCount += 1;
                        }
                      }
                    });

                    if(pending){
                      elm['creatives_status'] = 'Pending';
                      elm['handled_count'] =pendingCount;
                    }

                  });

                  break;
                case 'creatives_approved':
                  elm['text'] = 'Material godkänt';
                  elm['desc_text'] = 'Material godkänt';
                  break;
                case 'creative_rejection':
                  elm['text'] = 'Material nekat';
                  elm['desc_text'] = 'Material nekat';
                  break;
                case 'creative_reviewed':
                  elm['text'] = 'Material granskat';
                  elm['desc_text'] = 'Material granskat';
                  break;
                case 'campaign_approved':
                  elm['text'] = 'Kampanj godkänd';
                  elm['desc_text'] = 'Kampanjbokning godkänd';
                  break;
                case 'campaign_rejection':
                  elm['text'] = 'Kampanj nekad';
                  elm['desc_text'] = `Kampanj "${elm.campaignName}" har blivit nekad`;
                  break;
                case 'campaign_sync':
                  elm['text'] = 'Kampanj uppdaterad';
                  elm['desc_text'] = 'Kampanj uppdaterad';
                  break;
                case 'campaign_started':
                  elm['text'] = 'Kampanj startad';
                  elm['desc_text'] = 'Kampanj startad';
                  this.stepper['campaign_started'] = elm;
                  break;
                case 'campaign_ended':
                  elm['text'] = 'Kampanj avslutad';
                  elm['desc_text'] = 'Kampanj avslutad';
                  this.stepper['campaign_ended'] = elm;
                  break;
              }
              return elm;
            });

          const campaignStepper = this.notifications
            .filter(elm => elm.notification_type === 'campaign_approved' || elm.notification_type === 'campaign_rejection')
            .sort((a, b) => {
              return b.id - a.id;
            })[0];
          if (campaignStepper) {
            this.stepper['campaign'] = campaignStepper;
          }

          const creativesStepper = this.notifications
            .filter(elm => elm.notification_type === 'creatives_approved' || elm.notification_type === 'creative_rejection' || elm.notification_type === 'creative_updated_by_user')
            .sort((a, b) => {
              return b.id - a.id;
            })[0];
          if (creativesStepper) {
            this.stepper['creatives'] = creativesStepper;
          }

          // Sort notifications on date
          this.notifications = this.notifications.sort(function (a, b) {
            return b.id - a.id;
          });

          // If stepper contains anything rejected open detailed view
          if (Object.keys(this.stepper).filter(key => this.stepper[key].notification_type.includes('rejection')).length > 0) {
            this.showNotifications = true;
          }
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }

  parseRejectionReason(reason): string {
    return JSON.parse(reason)['rejection_reason'];
  }


}
