import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../../services/store.service';
import {ApiService} from '../../../services/api.service';
import {GlobalfunctionalityService} from '../../../services/globalfunctionality.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {MailDialogComponent} from './mail-dialog/mail-dialog.component';
import {FormcontrolService} from '../../../services/formcontrol/formcontrol.service';
import {AddlaterDialogComponent} from "../modules/creative_components/ad-for-size/addlater-dialog/addlater-dialog.component";
import {WarningConfirmationDialogComponent} from "../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {Subscription} from "rxjs";
import {FormArray, FormControl} from "@angular/forms";
import {CampaignService} from "../campaign.service";
import {DeliveryInfoDialogComponent} from "../modules/shared_components/budgetmodule/delivery-info-dialog/delivery-info-dialog.component";
import set = Reflect.set;

declare var toastr: any;
declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-createcampaign',
  templateUrl: './createcampaign.component.html',
  styleUrls: ['./createcampaign.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreatecampaignComponent implements OnInit, OnDestroy {


  campaignObj;

  creativesComplete = false;

  loadPage1: boolean = true;

  objectiveComplete = {value: false};


  showAds = false;
  getLocations = false;
  showAddLater = false;

  Math: any;
  Moment;


  blockMultipleClick = false; // So that user does not trigger function more than one time
  showMinFormatError: boolean = false;
  showMinSitesError: boolean = false;
  subscriptions: Subscription[] = [];


  // Blocket searchword
  indicatorLoading: boolean = false;
  addecision: string = '0';
  capacity: number;

  updateCreatives: boolean = false;
  minRequirement: boolean = false;


  // Used when updating creatives only
  // Check if creatives has been modified
  auxCreatives;

  readMore: number = 0;
  showMore: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              public globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              public form: FormcontrolService,
              public campaignService: CampaignService) {
    this.Moment = moment;
    this.Math = Math;
  }


  ngOnDestroy() {
    this.subscriptions.forEach(elm => {
      if (elm !== undefined) {
        elm.unsubscribe();
      }
    });
  }

  ngOnInit() {
    this.campaignService.buildFlow();

    if (sessionStorage.getItem('campaign_structure') === null) {
      sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));
    } else {
      this.campaignService.structure.setValue(JSON.parse(sessionStorage.getItem('campaign_structure')));
      this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;
    }

    if (this.updateCreatives) {
      this.minRequirement = this.globalfunctionality.checkCreativeMinRequirement(this.globalfunctionality.getModuleValues('adforsize').selected);
    }


    // GET MODULES
    if (this.store.modules === undefined) {
      this.store.modules = JSON.parse(sessionStorage.getItem('modules'));
    }

    // Set producttype if type is selected
    const adtype = this.globalfunctionality.getModuleValues('adtype');
    if(adtype){
      if(adtype.selectedType){
      this.store.productType = adtype.selectedType;
      }
    }

    /**
     * Check page status, important that this function is called before we subscribe on the route
     * Application will route depending on if a page is complete or not
     */
    this.checkPages();
    this.addSubscriptions();
  }


  addSubscriptions() {
    this.subscriptions.push(this.globalfunctionality.siteChange.subscribe((changed: boolean) => {
      if (changed) {
        const device = this.globalfunctionality.getModuleValues('device');
        if (device) {
          this.showMinSitesError = device.sites.filter(elm => elm.selected).length === 0;
          this.detectChange();
        }
      }
    }));

    this.subscriptions.push(this.globalfunctionality.formatChange.subscribe((changed: boolean) => {
      if (changed) {
        if (this.store.creativesForSize) {
          this.showMinFormatError = this.store.creativesForSize.filter(elm => elm.active).length === 0;
          this.detectChange();
        }
      }
    }));


    /**
     * TODO: For each page being navigated to check wether the mandatory pages is complete
     * ATM it only checks if the budget and creative page is complete
     * If user navigates to review page, check that budget page is complete
     */
    this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
      const page = params['page'];
      switch (page) {
        case 'campaign':
          this.store.page = 1;
          break;
        case 'audience':
          this.store.page = 2;
          break;
        case 'ads':
          this.store.page = 3;
          break;
        case 'budget':
          this.store.page = 4;
          break;
        case 'review':
          this.store.page = 5;
          break;
      }
      this.detectChange();
    }));


    // Page 1 checker
    this.subscriptions.push(this.globalfunctionality.page1Checker
      .subscribe((value: boolean) => {
        if (value && !this._cd['destroyed']) {
          if (value && !this._cd['destroyed']) {
            this.campaignService.page1Complete = !this.campaignService.structure.get('name').errors && !this.campaignService.structure.get('objective').errors;
          }
        }
      }));

    // Page 2 checker
    this.subscriptions.push(this.globalfunctionality.page2Checker
      .subscribe((value: boolean) => {
        if (value && !this._cd['destroyed']) {

          if (value && !this._cd['destroyed']) {
            if (this.store.GUIFlow['2'] !== undefined) {
              this.campaignService.page2Complete = this.store.GUIFlow['2'].every(elm => {
                return elm['module_values'].isComplete === true;
              });
            }

            if (this.campaignService.structure.get('objective').value) {
              if (this.campaignService.page2Complete && this.campaignService.structure.get('objective').value.id === 2) {
                this.globalfunctionality.targetDataIntialized.next(true);
              }
            }

            if (this.campaignService.structure.get('objective').value) {
              if (this.campaignService.structure.get('objective').value.id === 2) {
                if ((this.store.reachPosition < 4 || this.store.reachPosition > 22) || this.store.reachPosition === undefined || this.store.reachPosition === null) {
                  this.campaignService.page2Complete = false;
                }
              }
            }
          }
        }
      }));


    // Page 3 checker
    this.subscriptions.push(this.globalfunctionality.page3Checker
      .subscribe((value: boolean) => {
        if (value && !this._cd['destroyed']) {
          if (value && !this._cd['destroyed']) {
            let complete: boolean = false;
            if (this.store.GUIFlow['3'] !== undefined) {
              this.store.GUIFlow['3'].forEach(elm => {
                if (elm['type'] === 'adforsize') {
                  complete = elm['module_values'].isComplete;
                }
              });
            }
            this.campaignService.page3Complete = complete;
          }
        }
      }));

    // Page 4 checker
    this.subscriptions.push(this.globalfunctionality.page4Checker
      .subscribe((value: boolean) => {

        if (value && !this._cd['destroyed']) {
          if (this.store.GUIFlow['4'] !== undefined) {
            this.campaignService.page4Complete = this.store.GUIFlow['4'].every(elm => {
              return elm['module_values'].isComplete === true;
            });
          }
        }
      }));


    this.subscriptions.push(this.globalfunctionality.creativesChecker
      .subscribe((value: boolean) => {
        if (value && !this._cd['destroyed']) {
          if (value && !this._cd['destroyed']) {
            let complete: boolean = false;
            if (this.store.GUIFlow['3'] !== undefined) {
              this.store.GUIFlow['3'].forEach((elm) => {
                if (elm['type'] === 'adcreation') {
                  complete = elm['module_values']['isComplete'];
                }
                if (elm['type'] === 'templates') {
                  complete = elm['module_values']['isComplete'];
                }
                if (elm['type'] === 'creatives') {
                  complete = elm['module_values']['isComplete'];
                }
              });
            }
            this.creativesComplete = complete;
          }
        }
      }));


    this.subscriptions.push(this.globalfunctionality.detectChange
      .subscribe((value: boolean) => {
        if (value && !this._cd['destroyed']) {
          this._cd.detectChanges();
        }
      }));

  }


  setPage1Load(value) {
    this.loadPage1 = value;
  }

  resetPageComplete() {
    this.campaignService.page4Complete = false;
  }

  resetAdType() {
    this.addecision = '0';
  }

  setAdType(adtype) {
    this.addecision = adtype;
  }

  restoreSearchwordIndicatorLoad() {
    this.indicatorLoading = false;
  }

  searchWordIndicatorLoading(e) {
    this.indicatorLoading = e;
    this.detectChange();
  }


  readMoreTriggered(e) {
    this.readMore = e;
  }

  /**
   * This method is used for uploading creatives for different sizes
   * This method is triggered when a specific ad format (size) has been selected
   * @param size
   * TODO: REFACTOR
   */
  changeSize(format) {
    this.store.modules = JSON.parse(sessionStorage.getItem('modules'));
    // Clear old values
    this.showAddLater = false;
    this.reset();

    const modal_area: any = document.getElementById('modal-area');
    modal_area.classList.add('is-active');

    // Set the selected size and device
    this.store.selectedSize = format;

    // Check if any values has been entered for specific size
    const adForSize = this.globalfunctionality.getModuleValues('adforsize');

    if (adForSize.selected) {

      adForSize.selected.forEach(elm => elm['device'] = this.globalfunctionality.getDeviceByFormat(elm));
      adForSize.selected
        .filter(elm => elm.device === this.store.selectedSize.format && elm.format === this.store.selectedSize.size)
        .forEach(elm => {

          // Get right creative set
          elm.creative_set.filter(elm => elm.id === this.store.selectedSize.set.id).forEach(elm => {
            // Extract data from adforsize module
            if (elm.complete) {

              // Check if any creation method was earlier defined else choose default one (use_ad_template)
              if (elm.adcreation !== undefined) {
                if (elm.adcreation.selectedMethod !== undefined) {
                  this.setMethod(elm.adcreation.selectedMethod);
                } else {
                  this.setMethod('use_ad_template');
                }
              }

              if (elm.templates !== undefined || elm.template) {
                if (elm.template) {
                  elm.templates = elm.template;
                }
                const values = this.globalfunctionality.getModuleValues('templates');
                if (elm.templates.isComplete) {
                  elm.templates['id'] = values['id'];
                  this.globalfunctionality.setModuleValue('templates', elm.templates);
                }
              }


              // Check if any creatives has been entered for this specific size
              if (elm.creatives !== undefined) {
                const values = this.globalfunctionality.getModuleValues('creatives');
                if (elm.creatives.isComplete) {
                  elm.creatives['id'] = values['id'];
                  this.globalfunctionality.setModuleValue('creatives', elm.creatives);
                }
              }

              // Check selected script (existing tag)
              if (elm.script || elm.acm_script) {
                const existingtagValues = this.globalfunctionality.getModuleValues('existingtag');
                if (elm.script) {
                  if (elm.script.isComplete) {
                    elm.script['id'] = existingtagValues['id'];
                    this.globalfunctionality.setModuleValue('existingtag', elm.script);
                  }
                }

                if (elm.acm_script) {
                }
              }

              if (elm.adtoox) {
                const adtoox = this.globalfunctionality.getModuleValues('adtoox');
                if (elm.adtoox.isComplete) {
                  elm.adtoox['id'] = adtoox['id'];
                  this.globalfunctionality.setModuleValue('adtoox', elm.adtoox);
                }
              }
            } else {
              this.setMethod('use_ad_template');
            }
          });
        });
    }
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.creativeModalAppend();
    this.detectChange();
  }


  /**
   *  Used for setting ad creation method for a specific size if any was selected
   * @param value - equals to the selected method can be empty to reset values if none was selected
   */
  setMethod(value) {

    const adcreation = this.globalfunctionality.getModuleValues('adcreation');
    adcreation['selectedMethod'] = value;
    this.store.selectedMethod = value;
    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = value;
      }
    });
  }


  checkPages() {
    this.store.modules = JSON.parse(sessionStorage.getItem('modules'));
    const modules = this.store.modules;
    if (modules !== null) {
      const page2Elements = modules.filter(elm => elm.page === 2);
      const page4Elements = modules.filter(elm => elm.page === 4);

      this.campaignService.page1Complete = !this.campaignService.structure.get('name').errors && !this.campaignService.structure.get('objective').errors;
      this.campaignService.page2Complete = page2Elements.every(elm => elm['module_values'].isComplete === true);


      /*START PAGE 3 CHECK*/
      const addLaterValues = this.globalfunctionality.getModuleValues('addlater');
      const adForSizeValues = this.globalfunctionality.getModuleValues('adforsize');

      if (adForSizeValues && addLaterValues) {
        this.campaignService.page3Complete = adForSizeValues.isComplete || addLaterValues.isComplete;
      }

      if (this.campaignService.page3Complete === undefined) {
        this.campaignService.page3Complete = false;
      }
      /*END PAGE 3 CHECK*/

      /*START PAGE 4 CHECK*/
      if (page4Elements.length > 0) {
        page4Elements.forEach((elm) => {
          if (elm.type === 'complete') {
            if (elm.module_values.isComplete) {
              this.campaignService.page4Complete = true;
            }
          }
        });
      }
      /*END PAGE 4 CHECK*/
    }
    this.detectChange();
  }


  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }


  genericFlow(step, navigate) {
    this.store.page = parseInt(step);
    this.detectChange();
    switch (step) {
      case '1':
        this.changeFlowPage(1, navigate);
        break;
      case '2':
        if (this.campaignService.page1Complete) {
          this.changeFlowPage(2, navigate);
        }
        break;
      case '3':
        if ((this.campaignService.page2Complete && this.campaignService.page1Complete)) {
          this.changeFlowPage(3, navigate);
        }
        break;
      case '4':
        if ((this.campaignService.page1Complete && this.campaignService.page2Complete && this.campaignService.page3Complete)) {
          this.changeFlowPage(4, navigate);
        }
        break;
      case '5':
        if ((this.campaignService.page1Complete && this.campaignService.page2Complete && this.campaignService.page3Complete && this.campaignService.page4Complete)) {
          this.changeFlowPage(5, navigate);
        }
        break;
    }
  }


  checkIfAdsIsComplete(complete): boolean {
    const values = this.globalfunctionality.getModuleValues('adforsize');
    if (values.selected !== undefined) {
      values.selected.filter(elm => elm.active).forEach((elm) => {
          if (!elm.complete) {
            complete = false;
          }
      });
      values['isComplete'] = complete;
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    }
    return complete;
  }


  abortCreativesUpdate() {
    const dialogRef = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data:
          {
            text: "Vill du avbryta?",
            desc: "Dina ändringar kommer att försvinna.",
            abort_btn: "Nej",
            confirm_btn: "Ja",
            confirm_class: "alert"
          }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        sessionStorage.removeItem('aux_creatives');
        this.router.navigate(['dashboard/campaigns']);
      }
    });
  }

  closeCreativesUpdate() {
    // If any format was rejected, clear that status
    const formats = this.globalfunctionality.getModuleValues('adforsize');
    formats.selected.forEach(elm => {
      if (elm.creatives_status === 'Rejected') {
        delete elm.creatives_status;
      }
    });

    sessionStorage.removeItem('aux_creatives');
    this.updateCampaign(true);
  }

  checkIfCreativesChanged(): boolean {
    let changed: boolean = false;

    if (!this.auxCreatives) {
      if (!sessionStorage.getItem('aux_creatives')) {
        if (this.globalfunctionality.getModuleValues('adforsize')) {
          if (this.globalfunctionality.getModuleValues('adforsize').selected) {
            const selectedFormats = this.globalfunctionality.getModuleValues('adforsize').selected.filter(elm => elm.active);
            this.auxCreatives = JSON.parse(JSON.stringify(selectedFormats));
            sessionStorage.setItem('aux_creatives', JSON.stringify(this.auxCreatives));
          }
        }
      } else {
        this.auxCreatives = JSON.parse(sessionStorage.getItem('aux_creatives'));
      }
    } else {
      const creatives = JSON.stringify(this.globalfunctionality.getModuleValues('adforsize').selected.filter(elm => elm.active));
      changed = creatives !== JSON.stringify(this.auxCreatives);
    }
    return changed;

  }

  checkIfCreativesAdded() {
    if (!this.showMinFormatError && !this.showMinSitesError) {
      let complete: boolean = true;
      complete = this.checkIfAdsIsComplete(complete);
      if (complete) {
        this.continue();
      } else {
        const dialogRef = this.dialog.open(AddlaterDialogComponent, {panelClass: 'modal-size-md', autoFocus: false});
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.continue();
          }

        });
      }
    }
  }


  continue() {
    this.showMore = false;

    // Scroll to top of the page when user goes to a different step of the flow
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (this.store.page === 1) { /*---------------PAGE 1 VALIDATION--------------*/
      let complete: boolean = true;

      if (this.campaignService.structure.get('name').errors) {
        complete = false;
        this.campaignService.structure.get('name').markAsTouched();
      }

      if (typeof (this.campaignService.structure.get('objective').value.id) === 'undefined') {
        complete = false;
      }

      if (this.store.isMediaAgency.value || this.store.isTrustedPartner.value) {
        if (this.campaignService.structure.get('brand').get('name').errors !== null) {
          complete = false;
          this.campaignService.structure.get('brand').get('name').markAsTouched();
        }
      }

      if (complete) {
        // If user has continued with a objective, next time user wants to change objective a warning prompt should appear.
        this.store.prompChangeMessage = true;
        this.store.page += 1;
        this.changeFlowPage(this.store.page, true);
      }
    } else if (this.store.page === 2) {  /*---------------PAGE 2 VALIDATION--------------*/

      if (this.campaignService.structure.get('objective').value.id === 2) {
        // SEND MESSAGE TO AUDIENCE COMPONENTS APPLY WARNING CLASS
        if ((this.store.reachPosition < 4 || this.store.reachPosition > 22) || this.store.reachPosition === undefined || this.store.reachPosition === null) {
          this.store.showReachWarning = true;
        } else {
          this.store.showReachWarning = false;
        }
      } else {
        this.store.showReachWarning = false;
      }

      if (this.campaignService.page2Complete && !this.store.showReachWarning) {
        this.store.page += 1;
        this.detectChange();
        this.changeFlowPage(this.store.page, true);
      } else {


        let blocketSearchWord = 0;
        if (this.globalfunctionality.getModuleValues('addecision')) {
          blocketSearchWord = parseInt(this.globalfunctionality.getModuleValues('addecision').selected);
        }


        // Prompt message when user tries to continue to next page without selecting a site (error handling)
        if (this.campaignService.structure.get('objective').value.id === 1) {

          this.dialog.open(WarningConfirmationDialogComponent, {
              panelClass: 'modal-size-sm',
              data:
                {
                  text: !blocketSearchWord ? 'Ingen sajt vald' : 'Målgruppen är för liten',
                  desc: !blocketSearchWord ? 'Du måste välja en sajt för att fortsätta' : 'Lägg till/ ta bort val för att uttöka din valda målgrupp',
                  /* abort_btn: "Nej",*/
                  confirm_btn: "OK",
                  /* confirm_class: "ac"*/
                }
            }
          );
        }
      }
    } else if (this.store.page === 3) {  /*---------------PAGE 3 VALIDATION--------------*/
      const values = this.globalfunctionality.getModuleValues("adforsize");
      const addLaterCount: number =  values.selected.filter(elm => elm.add_later).length;
      const selectedCount: number = values.selected.filter(elm => elm.active).length;
      if (addLaterCount > 0) {
        this.globalfunctionality.getModuleValues('addlater')['isComplete'] = true;
      }
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      if (values.isComplete || (addLaterCount > 0 && selectedCount > 0)) {
        this.campaignService.page3Complete = true;
        this.store.page += 1;
        this.changeFlowPage(this.store.page, true);
      }

    } else if (this.store.page === 4) {  /*---------------PAGE 4 VALIDATION--------------*/
      let complete: boolean = true;
      if (this.campaignService.structure.get('objective').value.id !== 6) {
        const values = this.globalfunctionality.getModuleValues('budget');
        if (values.showTimescheduling) {  // Move timescheduling check to budget page?
         complete = this.checkTimeschedulingControl(complete);
        }
      }

      if (this.campaignService.page4Complete && complete) {
        this.store.page += 1;
        this.changeFlowPage(this.store.page, true);
      }
    }

    // If user has continued with a objective, next time user wants to change objective a warning prompt should appear.
    this.store.prompChangeMessage = true;
  }

  checkTimeschedulingControl(complete: boolean): boolean{
    for (let i in this.form.timeschedulingStarthours.controls) {
      this.form.timeschedulingStarthours.controls[i].markAsTouched();
      if (this.form.timeschedulingStarthours.controls[i].errors) {
        complete = false;
      }
    }
    for (let i in this.form.timeschedulingEndhours.controls) {
      this.form.timeschedulingEndhours.controls[i].markAsTouched();
      if (this.form.timeschedulingEndhours.controls[i].errors) {
        complete = false;
      }
    }
    return complete;
  }

  back() {
    this.store.page -= 1;
    this.changeFlowPage(this.store.page, true);
  }

  changeFlowPage(step, navigate) {
    this.detectChange(); // detect that store.page has been updated

    if (step === 1) {
      if (navigate) {
        this.router.navigate(['dashboard/create', 'campaign']);
      }
    } else if (step === 2) {
      if (this.campaignService.page1Complete) {
        if (navigate) {
          this.store.page = 2;
          this.detectChange();
          this.router.navigate(['dashboard/create', 'audience']);
        }
      }
    } else if (step === 3) {

      if (this.campaignService.page2Complete) {
        if (navigate) {
          this.store.page = 3;
          this.detectChange();
          this.router.navigate(['dashboard/create', 'ads']);
        }
      }
    } else if (step === 4) {
      if (navigate) {
        this.store.page = 4;
        this.detectChange();
        this.router.navigate(['dashboard/create', 'budget']);
      }
    } else if (step === 5) {
      if (navigate) {
        this.store.page = 5;
        this.detectChange();
        this.router.navigate(['dashboard/create', 'review']);
      }
    }
  }


  placeOrder() {

    this.globalfunctionality.startLoad();

    const module = this.globalfunctionality.getModule('complete');
    module['module_values']['isComplete'] = true;

    const update = this.campaignService.structure.get('config').get('update_campaign').value;
    const copy = this.campaignService.structure.get('config').get('copy').value;

    if (update && !copy) {
      this.updateCampaign(true);
    } else {
      if (this.campaignService.structure.get('objective').value.id !== 1) {
        this.createCampaign();
      } else {
        this.createMultipleProductCampaign();
      }
    }
  }

  exit() {
    sessionStorage.removeItem('modules');
    sessionStorage.removeItem('campaign');
    this.router.navigate(['dashboard/campaigns']);
  }


  openMailDialog() {
    this.dialog.open(MailDialogComponent, {width: '500px'});
  }

  reset(trigger?) {

    $('.modal-backdrop').removeClass('is-active');
    const modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;
    this.blockMultipleClick = false;

    const creationParam = this.globalfunctionality.getModule('adcreation');
    creationParam.param.output = '';


    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;

      }
    });

    this.globalfunctionality.clearModuleValues();
    this.minRequirement = this.globalfunctionality.checkCreativeMinRequirement(this.globalfunctionality.getModuleValues('adforsize').selected);
    this.detectChange();
    if (trigger) {
      this.globalfunctionality.adCreated.next(true);
    }
  }

  openDeliveryInfoModal() {
    this.dialog.open(DeliveryInfoDialogComponent,
      {
        panelClass: ['modal-size-md']
      });
  }

  saveAndExit() {

    const update = this.campaignService.structure.get('config').get('update_campaign').value;
    const copy = this.campaignService.structure.get('config').get('copy').value;

    if (update && !copy) {
      this.updateCampaign(true);
    } else {

      const complete = this.globalfunctionality.getModuleValues('complete');
      complete.isComplete = false;
      this.globalfunctionality.setModuleValue('complete', complete);

      if (this.campaignService.structure.get('objective').value.id !== 1) {
        this.createCampaign();
      } else {
        this.createMultipleProductCampaign();
      }
    }
  }


  createMultipleProductCampaign() {
    const postObj = {};
    postObj['name'] = this.campaignService.structure.get('name').value;
    postObj['objective'] = this.campaignService.structure.get('objective').value.id;
    postObj['brand'] = this.campaignService.structure.get('brand').get('id').value;
    postObj['billing_information'] = this.store.noteText === undefined ? undefined : this.store.noteText;
    postObj['order_lines'] = [];

    if (this.store.isTrustedPartner.value) {
      postObj['bill_agency'] = this.getBillingParam();
    }

    if (this.store.user.companies[0].company_type === 'Customer') {
      postObj['billing_partner'] = this.getBillingPartner();
    }

    const selectedProducts = [];

    if (this.campaignService.structure.get('objective').value.id === 1) {
      const contextValues = this.globalfunctionality.getModuleValues('context');
      if (contextValues['contexts']) {
        contextValues['contexts'].filter(context => context.active).forEach(context => {
          context.sub_contexts.forEach(sub => {
             selectedProducts.push(...sub.products.filter(prod => prod.selected));
            });
        });
      }
    }
    this.setIsCompleteToFalse();

    // Build order line obj for each selected product
    selectedProducts.forEach(elm => {
      const obj = {
        product: elm.id_products,
        module_values: this.store.modules
      };
      postObj['order_lines'].push(obj);
    });

    const aux = [];
    aux.push(postObj);
    this.apiservice.putJSON(this.store.apiURL + '/CampaignServlet', aux)
      .subscribe(res => {
        this.clear();
        this.globalfunctionality.detectChange.next(true);
        this.router.navigate(['dashboard/campaigns']);
      });
  }

  updateCampaign(route) {

    const campaign = this.campaignService.structure;
    const campaignObj = [];
    const obj = {};

    obj['name'] = this.campaignService.structure.get('name').value;
    obj['objectives'] = campaign.get('objective').value.id;
    obj['brands'] = campaign.get('brand').value.id;
    obj['order_lines'] = campaign.get('order_lines').value;
    obj['id'] = campaign.get('id').value;
    obj['freeCampaign'] = this.store.freeCampaign;

    obj['billing_information'] = this.store.noteText === undefined ? undefined : this.store.noteText;
    if (this.store.isTrustedPartner.value) {
      obj['bill_agency'] = this.getBillingParam();
    }

    if (this.store.user.companies[0].company_type === 'Customer') {
      obj['billing_partner'] = this.getBillingPartner();
    }

    // Set all isComplete to false if no value exists
    // Extract all module values and place them in the order line
    const moduleValues = [];
    this.store.modules.forEach((elm) => {
      this.setReviewIsCompleteValue(elm);

      const obj = {};
      obj['id'] = elm['module_values']['id'];
      obj['value'] = elm['module_values'];
      moduleValues.push(obj);
    });

    obj['order_lines'].forEach(elm => {
      elm['module_values'] = moduleValues;
    });

    campaignObj.push(obj);

    this.apiservice.postJSON(this.store.apiURL + '/CampaignServlet', campaignObj)
      .subscribe(res => {
        if (route) {
          this.clear();
          this.globalfunctionality.detectChange.next(true);
          this.router.navigate(['dashboard/campaigns']);
        }
      });
  }

  setReviewIsCompleteValue(elm) {
    if (elm['module_values']['isComplete'] === undefined) {
      // SET REVIEW TO TRUE IF NOTHING HAS BEEN SELECTED (CHECK WHY?)
      if (elm['type'] !== 'review') {
        elm['module_values']['isComplete'] = false;
      } else {
        elm['module_values']['isComplete'] = true;
      }
    }
    if (elm['type'] === 'review') {
      elm['module_values']['isComplete'] = true;
    }
  }


  /**
   * If the user is a 'trusted partner' the bill can be sent to either the agency the campaign is being booked for or the trusted partner.
   * This decision is made in the review page
   * @returns {string}
   */
  getBillingParam() {
    if (this.store.trustedPartnerTakesBill) {
      return true;
    } else {
      return false;
    }
  }

  getBillingPartner() {
    if (this.store.billingPartner) {
      return undefined;
    } else {
      return this.store.user.companies[0].billing_partner.id;
    }
  }

  /**
   * Set all isComplete to false if no value exists
   */
  setIsCompleteToFalse() {
    this.store.modules.forEach((elm) => {
      this.setReviewIsCompleteValue(elm);
    });
  }

  createCampaign() {

    const campaignObj = [];
    const obj = {};

    const brandId = this.campaignService.structure.get('brand').get('id').value;

    obj['name'] = this.campaignService.structure.get('name').value;

    obj['brand'] = brandId;
    obj['objective'] = this.campaignService.structure.get('objective').value.id;
    if (this.campaignService.structure.get('products').value.length > 0) {
      obj['product'] = this.campaignService.structure.get('selected_product').value;
    }
    obj['billing_information'] = this.store.noteText === undefined ? undefined : this.store.noteText;
    obj['freeCampaign'] = this.store.freeCampaign ? true : false;

    if (this.store.isTrustedPartner.value) {
      obj['bill_agency'] = this.getBillingParam();
    }

    if (this.store.user.companies[0].company_type === 'Customer') {
      obj['billing_partner'] = this.getBillingPartner();
    }

    this.setIsCompleteToFalse();

    obj['modules'] = this.store.modules;
    campaignObj.push(obj);

    this.apiservice.putJSON(this.store.apiURL + '/CampaignServlet', campaignObj)
      .subscribe(res => {
        this.clear();

        // Set "alla varumärken" as the selected brand so that all campaigns get showed.
        this.store.selectedBrand = this.store.companiesList[0].brands[0];

        this.globalfunctionality.detectChange.next(true);
        this.router.navigate(['dashboard/campaigns']);
      });

  }

  clear() {
    sessionStorage.removeItem('modules');
    sessionStorage.removeItem('campaign');
    sessionStorage.removeItem('campaign_structure');
    $('.modal-backdrop').removeClass('is-active');
    $('#modal-area').removeClass('is-active');

    this.store.noteText = undefined;
    this.store.billingPartner = 1;
    this.store.trustedPartnerTakesBill = 1;
    this.store.freeCampaign = false;
    this.store.user.companies[0].free_campaign = false;
    this.campaignService.structure.reset();
    this.store.page = undefined;

    sessionStorage.setItem("userObj", JSON.stringify(this.store.user));
    this.globalfunctionality.stopLoad();
    this.globalfunctionality.stopAppLoad();
  }


  /* * * * * * * * * * MODAL APPEND * * * * * * * * */
  creativeModalAppend() {
    $('.creative-modal').appendTo('#modal-append-container');
    $('.modal-backdrop').addClass('is-active');
    $('#modal-area').addClass('is-active');
    $('.creative-modal').css({display: 'block'});

  }
}
