import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-creative-reject-dialog',
  templateUrl: './creative-reject-dialog.component.html',
  styleUrls: ['./creative-reject-dialog.component.css']
})
export class CreativeRejectDialogComponent implements OnInit {

  rejectCreativesMessage;

  selectedRejection;
  rejectionMessages: any[];

  constructor(public dialogRef: MatDialogRef<CreativeRejectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) {
  }

  ngOnInit(): void {
    this.rejectionMessages = this.store.langJSON.component.task_manager.creative_rejection_messages;
  }


  submit() {

    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 6,
        rejection_reason: this.rejectCreativesMessage === undefined ? '' : this.rejectCreativesMessage
      };

    if(!this.data.ignoreUpdate) {
      let data = [];
      data.push(obj);

      this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
        .subscribe(res => {
          this.dialogRef.close(true);
        });
    }else{
      this.dialogRef.close(obj);
    }
  }

  cancel() {

    this.dialogRef.close(false);
  }

}
