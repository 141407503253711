import {Component, Input, OnInit} from "@angular/core";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";


@Component({
  selector: 'app-dynamic-prisjakt-preview',
  templateUrl: 'dynamic-prisjakt-preview.component.html'
})

export class DynamicPrisjaktPreviewComponent implements OnInit {

  @Input() data: any;

  showMobile: boolean = true;
  showDesktop: boolean = true;

  constructor(private globalfunctionality: GlobalfunctionalityService) {
  }

  ngOnInit(): void {

  }
}
