import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {Validators} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {CreativemoduleService} from "../../../creativemodule.service";
import {WarningConfirmationDialogComponent} from "../../../../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ReadmoreDialogComponent} from "../../../../../../dialogs/readmore-dialog/readmore-dialog.component";

@Component({
  selector: 'app-creative-mms-filmcode',
  templateUrl: 'mms-filmcode.component.html',
  styleUrls: ['mms-filmcode.component.css']
})

export class MmsFilmcodeComponent implements OnInit{

  @Input() adMMSControl;
  @Input() adMMSDecisionControl;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  device: string;

  constructor(
    public store: StoreService,
    public creativeService: CreativemoduleService,
    private dialog: MatDialog
  ){

  }

  ngOnInit(): void {

    if(this.store.selectedSize) {
      this.device = this.store.selectedSize.format.toUpperCase();
    }

    this.adMMSDecisionControl.valueChanges.subscribe(option => {
      if(option === 'inputed'){
        this.adMMSControl.setValidators([Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10)]);
      }else{
        this.adMMSControl.setValidators([]);
        this.adMMSControl.setValue('');
      }

      this.updatePreview();
    });

    this.adMMSControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(code => {
      this.updatePreview();
    });

  }

  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

  readMore(){
    this.dialog.open(ReadmoreDialogComponent, {
      panelClass: 'modal-size-sm'
    });
  }

}
