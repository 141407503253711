import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-invite-reject-dialog',
  templateUrl: './invite-reject-dialog.component.html',
  styleUrls: ['./invite-reject-dialog.component.css']
})
export class InviteRejectDialogComponent implements OnInit {
  rejectCompanyMessage;

  constructor(public dialogRef: MatDialogRef<InviteRejectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) { }

  ngOnInit() {

  }

  submit(){
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 6,
        rejection_reason: this.rejectCompanyMessage
      };

    data.push(obj);
    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe(res => {
        this.dialogRef.close(true);
      });
  }
  cancel(){

    this.dialogRef.close(false);
  }

}
