import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {Router} from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
declare var toastr: any;
@Component({
  selector: 'app-admin-info-dialog',
  templateUrl: './admin-info-dialog.component.html',
  styleUrls: ['./admin-info-dialog.component.css']
})
export class AdminInfoDialogComponent implements OnInit {

  data;
  approvedCampaigns;
  firstTimeCompanies;
  registeredCompanies;
  returningCompanies;
  totalBudget;
  loading = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              public dialogRef: MatDialogRef<AdminInfoDialogComponent>) {
  }

  ngOnInit() {
    this.globalfunctionality.initComplete
      .subscribe((complete: boolean) => {
        if(complete) {
          this.getAdminInfo();
        }

      });


  }

  getAdminInfo() {
    this.apiservice.getJSON(this.store.apiURL + "/SchibstedAdminInfoServlet")
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.data = res["data"];
          this.data = Object.assign([], res["data"]);
          this.setObjects();
        }
        if (!this._cd["destroyed"]) {
          this._cd.detectChanges();
        }
      });
  }
  setObjects() {
    this.approvedCampaigns = this.data.approvedCampaigns;
    this.firstTimeCompanies = this.data.firstTimeCompanies;
    this.registeredCompanies = this.data.registeredCompanies;
    this.returningCompanies = this.data.returningCompanies;
    this.totalBudget = this.data.totalBudget;
  }

  sync(){
    this.loading = true;
    this.apiservice.getJSON(this.store.apiURL + "/SchibstedAdminInfoServlet?options=update_company_salesforce")
      .subscribe(res => {
        if(res.responseCode === 200){
          toastr.success("Synchronization succeded, company information fetched from SalesForce.");
        }else{
          toastr.error("Synchronization failed.");
        }
        this.loading = false;
      });
  }

  close(){
    this.dialogRef.close();
  }

}
