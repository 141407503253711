import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {GlobalfunctionalityService} from '../../../../services/globalfunctionality.service';
import {StoreService} from '../../../../services/store.service';
import {ApiService} from '../../../../services/api.service';
import {FormControl, Validators} from "@angular/forms";
import {CampaignService} from "../../campaign.service";
import {ResponseBodyModel} from "../../../../models/response-body.model";


declare var moment: any;

@Component({
  selector: 'app-copy-campaign',
  templateUrl: './copy-campaign.component.html'
})
export class CopyCampaignComponent implements OnInit, OnDestroy {



  constructor(
    public dialogRef: MatDialogRef<CopyCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalfunctionality: GlobalfunctionalityService,
    public store: StoreService,
    private apiservice: ApiService,
    public campaignService: CampaignService

  ) {

  }

  ngOnDestroy() {
    // this.store.blockEditCampaign = false;
  }


  ngOnInit() {


  }


  continue() {
    if(this.campaignService.structure.get('name').errors === null){

      this.campaignService.structure.get('objective').setValue({id: this.data.objective});


      const company = this.store.user.companies[0];
      this.campaignService.structure.get('company').setValue(company);

      this.apiservice.getJSON(this.store.apiURL + '/BrandsServlet?brand=' + this.data.brand)
        .subscribe((res: ResponseBodyModel) => {
          let brand = res.data[0];
          this.campaignService.structure.get('brand').get('id').setValue(brand['id']);
          this.campaignService.structure.get('brand').get('name').setValue(brand['name']);
          this.campaignService.structure.get('brand').get('city').setValue(brand['name']);

          this.globalfunctionality.getCampaignValues(
            this.data.order_lines,
            'copy',
            true,
            true,
            ['dashboard/create', 'budget']
          );

          this.dialogRef.close();

        });
    }
  }

  close() {
    this.dialogRef.close();
  }



}
