import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-adblock-dialog',
  templateUrl: './adblock-dialog.component.html',
  styleUrls: ['./adblock-dialog.component.css']
})
export class AdblockDialogComponent implements OnInit, AfterViewInit {


  ngAfterViewInit(): void {
    if(this.store.checkOnce) {
      this.store.checkOnce = false;
      let adblockExists = true;

      this.apiservice.GETAdBlock("/assets/js/showads.js")
        .subscribe(res => {
          adblockExists = false;
        });

      const _t = this;
      setTimeout(function () {
        if (adblockExists) {
          if (_t.store.showAdblockWarningOnce) {
            _t.store.showAdblockWarningOnce = false;
            const dialogRef = _t.dialog.open(AdblockDialogComponent, {panelClass: 'modal-size-xs', disableClose: true});
            dialogRef.afterClosed().subscribe(result => {

            });
          }
        }
      }, 2500);

    }
  }

  constructor(public store: StoreService,
              public apiservice: ApiService,
              public dialog: MatDialog,
              public dialogRef:MatDialogRef<AdblockDialogComponent>) {
  }

  ngOnInit() {
    }


    close() {
    this.dialogRef.close();
    }

}
