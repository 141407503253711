export const environment = {
  production: false,
  endpoint: 'https://test.pliing.com/APP-BACKEND-SSISP-web',
  template_endpoint:
    'https://test.pliing.com/APP-BACKEND-SSISP-web/GetAdDataServlet?name=',
  adServer: 'https://admin.pliing.com/BanGen',
  imageServerUrl: 'https://admin.pliing.com/AdAssetsService-web/assets',
  mapsKey: 'AIzaSyCsC_BDAzy8xNiR6aJKIP5jDWyvYXbl8IQ',
  ACMFeedTransformerFormatId: 113
};
