import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../services/store.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {ApiService} from "../../../services/api.service";
import {FormControl, Validators} from "@angular/forms";
import * as sha256 from "sha256";
import {StartpageService} from "../../startpage.service";
import {LoginResponse} from "../../models/login-response.model";
import {Credentials} from "../../models/credentials.model";
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
data;
id;
expired = false;

password;
confirm_password;

showPasswordMissmatch = false;



  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private _startpage: StartpageService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params["id"];
      this.apiservice.GET(this.store.apiURL + "/ForgotPasswordServlet?verification="+params['id'])
        .subscribe(res => {
          this.data = res;
          if(this.data.responseCode !== 200){
            this.expired = true;
          }
        });
    });
  }

  backToLogin(){
    this.router.navigate(['/login']);
  }


  savePassword(event){

      this.password = event.password;
      this.confirm_password = event.repassword;

      if (this.password === this.confirm_password) {

        let obj = {
          password: sha256(this.password)
        };

        this.apiservice.postJSON(this.store.apiURL + "/ForgotPasswordServlet?verification=" + this.id, obj)
          .subscribe(res => {

            if(res.responseCode === 200){
              const loginObj: Credentials =
                {
                  username: res.data.email,
                  password: sha256(this.password)
                };

              this._startpage.login(loginObj)
                .subscribe((res: LoginResponse) => {
                  if (res.responseCode === 200) {
                    const data = res.data;
                    data["token"] = sha256(data["token"]);
                    sessionStorage.setItem("userObj", JSON.stringify(data));
                    this.store.user = data;
                    this.store.selectedMenuItem = 0;
                    this.router.navigate(['/dashboard/campaigns']);
                  }
                });
            }

          });
      } else {
        this.showPasswordMissmatch = true;
      }


  }

}
