import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-device-option',
  templateUrl: './device-option.component.html'
})
export class DeviceOptionComponent implements OnInit {

  selectedDevice: string = 'all';

  deviceOptions = [
    {
      name: 'Alla',
      value: 'all'
    },
    {
      name: 'Desktop',
      value: 'desktop'
    },
    {
      name: 'Mobil',
      value: 'mobile'
    }
  ];

  constructor(
    private globalfunctionality: GlobalfunctionalityService,
    private store: StoreService,
    private _cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    if(adforsize['selectedDeviceOption']){
      this.selectedDevice = adforsize['selectedDeviceOption'];
      this._cd.detectChanges();
      this.globalfunctionality.deviceOptionChange.next(this.selectedDevice);
    }else{
      // Used for obtaining correct product, intially backend returns the mobile product when it should return the "all" product
      this.globalfunctionality.deviceOptionChange.next(this.selectedDevice);
    }
  }

  deviceChange(value) {
    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    adforsize['selectedDeviceOption'] = value;
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.globalfunctionality.deviceOptionChange.next(value);
  }
}
