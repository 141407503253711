import {NgModule} from '@angular/core';
import {MaterialModule} from "../../utils/MaterialModule";
import {GeomoduleComponent} from "./modules/audience_track/geomodule/geomodule.component";
import {BudgetmoduleComponent} from "./modules/shared_components/budgetmodule/budgetmodule.component";
import {ObjectivemoduleComponent} from "./modules/shared_components/objectivemodule/objectivemodule.component";
import {AdcreationmoduleComponent} from "./modules/creative_components/adcreationmodule/adcreationmodule.component";
import {AdtemplatemoduleComponent} from "./modules/creative_components/adtemplatemodule/adtemplatemodule.component";
import {ReviewmoduleComponent} from "./modules/shared_components/reviewmodule/reviewmodule.component";
import {IframeDialogComponent} from "./modules/creative_components/adtemplatemodule/iframe-dialog/iframe-dialog.component";
import {CampaignnamemoduleComponent} from "./modules/shared_components/campaignnamemodule/campaignnamemodule.component";
import {ExistingtagmoduleComponent} from "./modules/creative_components/existingtagmodule/existingtagmodule.component";
import {TandcDialogComponent} from "./modules/shared_components/reviewmodule/tandc-dialog/tandc-dialog.component";
import {AdForSizeComponent} from "./modules/creative_components/ad-for-size/ad-for-size.component";
import {InterestanddemographicComponent} from "./modules/audience_track/interestanddemographic/interestanddemographic.component";
import {ReviewAdsComponent} from "./modules/shared_components/reviewmodule/review-ads/review-ads.component";
import {CampaignChangeWarningComponent} from "./dialogs/campaign-change-warning/campaign-change-warning.component";
import {RemoveCreativeSetWarningComponent} from "./dialogs/remove-creative-set-warning/remove-creative-set-warning.component";
import {ProductDescriptionDialogComponent} from "./modules/context_track/context/product-description-dialog/product-description-dialog";
import {DeviceComponent} from "./modules/cpc_track/device/device.component";
import {GeoOverlapDialogComponent} from "./modules/audience_track/geomodule/dialog/geo-overlap-dialog/geo-overlap-dialog.component";
import {GeoOverlapConfirmationDialogComponent} from "./modules/audience_track/geomodule/dialog/geo-overlap-confimation-dialog/geo-overlap-confirmation-dialog.component";
import {AddecisionComponent} from "./modules/context_track/addecision/addecision.component";
import {SearchwordComponent} from "./modules/context_track/searchword/searchword.component";
import {GeoDataTreeComponent} from "./modules/audience_track/geomodule/geo-data-tree/geo-data-tree.component";
import {DeliveryInfoDialogComponent} from "./modules/shared_components/budgetmodule/delivery-info-dialog/delivery-info-dialog.component";
import {MailDialogComponent} from "./createcampaign/mail-dialog/mail-dialog.component";
import {ConfirmationDialogComponent} from "./modules/shared_components/objectivemodule/confirmation-dialog/confirmation-dialog.component";
import {AdblockDialogComponent} from "../dialog/adblock-dialog/adblock-dialog.component";
import {RedirectDialogComponent} from "./modules/shared_components/objectivemodule/redirect-dialog/redirect-dialog.component";
import {AddlaterDialogComponent} from "./modules/creative_components/ad-for-size/addlater-dialog/addlater-dialog.component";
import {BudgetSplitDialogComponent} from "./modules/shared_components/budgetmodule/budget-split-dialog/budget-split-dialog.component";
import {PreviewDialogComponent} from "./modules/creative_components/ad-for-size/preview-dialog/preview-dialog.component";
import {AdtypeComponent} from "./modules/shared_components/adtype/adtype.component";
import {ContextComponent} from "./modules/context_track/context/context.component";
import {DemographicComponent} from "./modules/audience_track/demographic/demographic.component";
import {TargetIndicatorComponent} from "./modules/audience_track/target-indicator/target-indicator.component";
import {SubobjectiveComponent} from "./modules/cpc_track/subobjective/subobjective.component";
import {AdArchiveComponent} from "./modules/creative_components/ad-archive/ad-archive.component";
import {DisplayContentBudgetComponent} from "./modules/cpc_track/display-content-budget/display-content-budget.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PipeModule} from "./pipes/pipe.module";
/*import {AgmCoreModule} from "@agm/core";*/
import {environment} from "../../../environments/environment";
/*import {CurrencyMaskModule} from "ng2-currency-mask";*/
import {SearchwordModule} from "./modules/context_track/searchword/searchword.module";
import {CreativemoduleModule} from "./modules/creative_components/creativemodule/creativemodule.module";
import {CampaignService} from "./campaign.service";
import {AdtooxComponent} from "./modules/creative_components/adtoox/adtoox.component";
import {ReadmoreDialogComponent} from "./dialogs/readmore-dialog/readmore-dialog.component";
import {NotificationComponent} from "./dialogs/edit-campaign/notifcation/notification.component";
import {CreativeOverviewComponent} from "./shared-components/creative-overview/creative-overview.component";
import {TargetCategorySelectionDialogComponent} from "./modules/context_track/context/target-category-selection-dialog/target-category-selection-dialog.component";
import {PaginationModule} from "../../utils/pagination/pagination.module";
import {DeviceOptionComponent} from "./modules/shared_components/device-option/device-option.component";


@NgModule({
  declarations: [
    GeomoduleComponent,
    BudgetmoduleComponent,
    ObjectivemoduleComponent,
    AdcreationmoduleComponent,
    AdtemplatemoduleComponent,
    ReviewmoduleComponent,
    IframeDialogComponent,
    CampaignnamemoduleComponent,
    ExistingtagmoduleComponent,
    TandcDialogComponent,
    AdForSizeComponent,
    InterestanddemographicComponent,
    ReviewAdsComponent,
    CampaignChangeWarningComponent,
    RemoveCreativeSetWarningComponent,
    ProductDescriptionDialogComponent,
    DeviceComponent,
    DeviceOptionComponent,
    GeoOverlapDialogComponent,
    GeoOverlapConfirmationDialogComponent,
    AddecisionComponent,
    SearchwordComponent,
    GeoDataTreeComponent,
    DeliveryInfoDialogComponent,
    AdblockDialogComponent,
    AddlaterDialogComponent,
    BudgetSplitDialogComponent,
    PreviewDialogComponent,
    AdtypeComponent,
    ContextComponent,
    DemographicComponent,
    TargetIndicatorComponent,
    SubobjectiveComponent,
    AdArchiveComponent,
    DisplayContentBudgetComponent,
    AdtooxComponent,
    ReadmoreDialogComponent,
    NotificationComponent,
    CreativeOverviewComponent,
    TargetCategorySelectionDialogComponent,
    RedirectDialogComponent,
    MailDialogComponent,
    ConfirmationDialogComponent
  ],
  exports: [
    GeomoduleComponent,
    BudgetmoduleComponent,
    ObjectivemoduleComponent,
    AdcreationmoduleComponent,
    AdtemplatemoduleComponent,
    ReviewmoduleComponent,
    CampaignnamemoduleComponent,
    ExistingtagmoduleComponent,
    AdForSizeComponent,
    InterestanddemographicComponent,
    ReviewAdsComponent,
    CampaignChangeWarningComponent,
    RemoveCreativeSetWarningComponent,
    DeviceComponent,
    DeviceOptionComponent,
    AddecisionComponent,
    SearchwordComponent,
    GeoDataTreeComponent,
    AdtypeComponent,
    ContextComponent,
    DemographicComponent,
    TargetIndicatorComponent,
    SubobjectiveComponent,
    AdArchiveComponent,
    DisplayContentBudgetComponent,
    CreativemoduleModule,
    AdtooxComponent,
    NotificationComponent,
    CreativeOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipeModule,
   /* AgmCoreModule.forRoot({
      apiKey: environment.mapsKey
    }),*/
   /* CurrencyMaskModule,*/

    // Custom
    SearchwordModule,
    CreativemoduleModule,
    PaginationModule

  ],
  entryComponents: [
    IframeDialogComponent,
    GeomoduleComponent,
    MailDialogComponent,
    TandcDialogComponent,
    ConfirmationDialogComponent,
    AdblockDialogComponent,
    RedirectDialogComponent,
    AddlaterDialogComponent,
    BudgetSplitDialogComponent,
    PreviewDialogComponent,
    CampaignChangeWarningComponent,
    RemoveCreativeSetWarningComponent,
    ProductDescriptionDialogComponent,
    DeliveryInfoDialogComponent,
    ReadmoreDialogComponent,
    TargetCategorySelectionDialogComponent
  ],
  providers: [CampaignService]
})
export class CampaignModule {
}
