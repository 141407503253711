import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {Router} from "@angular/router";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {CampaignService} from "../../../campaign.service";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";

declare var $: any;

@Component({
  selector: 'app-adcreationmodule',
  templateUrl: './adcreationmodule.component.html',
  styleUrls: ['./adcreationmodule.component.css']
})
export class AdcreationmoduleComponent implements OnInit, OnDestroy {
  @ViewChild('methodBtn') methodBtn: ElementRef;
  method;
  module;
  index;

  creationMethods = [];

  visible: boolean = false;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              public campaignService: CampaignService,
              public creativeService: CreativemoduleService
  ) {
  }


  ngOnDestroy() {
    this.destroyPopup();
  }

  ngOnInit() {
    this.globalfunctionality.adcreationIndexChange.subscribe(index => {
      this.index = index;
    });
    this.module = this.globalfunctionality.getModule("adcreation");

    if (this.globalfunctionality.getModuleValues('adtype')) {
      const type = this.globalfunctionality.getModuleValues('adtype').selectedType;

      if (type.toUpperCase() === 'VIDEOANNONS') {
        this.creationMethods = this.module.param.options;
      } else {
        if (this.campaignService.structure.get('objective').value.id === 1) {
          let addecision = this.globalfunctionality.getModuleValues('addecision');
          // Workaround for old campaigns which dont have addecsion module
          if (addecision === undefined) {
            addecision = {
              'selected': '0'
            };
          }
          if (type.toUpperCase() === 'SPECIALANNONS' || addecision.selected === '1') {
            this.creationMethods = this.module.param.options.filter(elm => elm.value === 'use_ad_template');
          } else {
            const dynamicAd: boolean = this.globalfunctionality.getModuleValues('adforsize').dynamic_ad;
            this.setMethod(dynamicAd);
          }
        } else {
          this.setMethod(type.toUpperCase() === 'RESPONSIVANNONS');
        }
      }
    } else {
      this.creationMethods = this.module.param.options;
    }



    if (this.module["module_values"].selectedMethod !== undefined) {
      this.method = this.module["module_values"].selectedMethod;
      this.store.selectedMethod = this.method;
      this.index = this.creationMethods.findIndex(method => method.value === this.method);
      this.appendPopup(this.method);
      this.store.GUIFlow[this.store.page].forEach((elm) => {
        if (elm.type === "adcreation") {
          elm.param.output = this.method;
          elm["module_values"].isComplete = true;
          elm["module_values"]["selectedMethod"] = this.method;
        }
      });
    }
    this._cd.detectChanges();
  }

  setMethod(createOnly: boolean) {
    this.creationMethods = createOnly ?
      this.module.param.options.filter(elm => elm.value !== 'existing_tag' && elm.value !== 'adtoox' && elm.value !== 'ad_archive') :
      this.module.param.options.filter(elm => elm.value !== 'adtoox');
  }


  reset() {
    this.store.selectedSize = undefined;

    $('body').removeClass("modal-open");
    $(".modal-backdrop").removeClass("is-active");
    let modal_area: any = document.getElementById("modal-area");
    modal_area.classList.remove("is-active");

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;


    const creationParam = this.globalfunctionality.getModule("adcreation");
    creationParam.param.output = "";

    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === "adcreation") {
        elm.param.output = "";
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values["id"] = id;

      }
    });
    this.clearModuleValues();


    this.clearModuleValues();

    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }

    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    this.campaignService.page3Complete = false;

  }

  clearModuleValues() {

    this.store.showImageError = false;
    this.store.showHorizontalImageError = false;

    this.store.modules.forEach((elm) => {
      if (elm.type === 'templates') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }
      if (elm.type === 'adcreation') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.param.output = '';
      }

      if (elm.type === 'creatives') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

    });

    this.globalfunctionality.page3Checker.next(true);
  }

  saveSelection(method) {
    this.appendPopup(method);
    this.store.selectedMethod = method;

    if (this.store.selectedMethod === "use_ad_template") {
      let module = this.globalfunctionality.getModuleValues("addlater");
      if (module) {
        module["isComplete"] = false;
      }
    }

    const module = this.globalfunctionality.getModule("adcreation");
    module.param.output = method;
    module["module_values"].isComplete = true;
    module["module_values"].selectedMethod = method;


// Give the GUIFLOW the new value so that the html can open the corresponding module
    this.store.GUIFlow[this.store.page].forEach((elm) => {
      if (elm.type === "adcreation") {
        elm.param.output = method;
        elm["module_values"].isComplete = true;
        elm["module_values"].selectedMethod = method;
      }

    });

    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
    this.globalfunctionality.page3Checker.next(true);

  }

  destroyPopup() {
    $('body').removeClass("modal-open");
    $($('#modal-append-container')[0].childNodes).each((index, elm) => {
      if (!$(elm).hasClass('modal-backdrop')) {
        $(elm).remove();
      }
    });
  }

  appendPopup(method) {

    let modal = document.getElementById("creative-modal");
    if (modal !== null) {
      if (method === "existing_tag") {
        modal.classList.add("ssi-creative-adscript");
      } else {
        modal.classList.remove("ssi-creative-adscript");
      }
    }


    $("#" + 'creative-modal').appendTo("#modal-append-container");
    $(".modal-backdrop").addClass("is-active");
    $('#modal-area').addClass('is-active');
    $('body').addClass("modal-open");
    /*$("#" + id).css({display: "block"});*/
  }

  changeTab(e) {
    this.index = e.index;
    this.creationMethod(this.module.param.options[this.index].value);
  }

  creationMethod(method) {

    this.appendPopup(method);

    if (this.store.selectedMethod === undefined) {
      this.saveSelection(method);
    } else {
      if (this.store.selectedMethod !== method) {
        this.store.selectedMethod = method;
        this.saveSelection(method);
      } else {
//        console.log("Same method, do nothing.");
      }
    }


  }


}
