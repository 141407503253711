import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-freecampaign-dialog',
  templateUrl: './freecampaign-dialog.component.html',
  styleUrls: ['./freecampaign-dialog.component.css']
})
export class FreecampaignDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FreecampaignDialogComponent>,
              private globalfunctionality: GlobalfunctionalityService,
              public store: StoreService,
              private apiservice: ApiService) {
  }

  ngOnInit() {
  }


  accept(){
    this.store.loadObjectiveComponenentOnce = true;
    sessionStorage.setItem("free_campaign", "true");
    this.dialogRef.close(true);
  }
  decline(){
    sessionStorage.setItem("free_campaign", "false");
    this.dialogRef.close(false);
  }

  cancel(){
    this.dialogRef.close();
  }

}
