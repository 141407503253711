import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class ItemNode {
  id: number;
  level: number;
  name: string;
  type: string;
  children?: ItemNode[];
  parentName?: string;
  value?: string;
  filter_value?: string;
  web?: number;
  total?: number;
  muleTargetingOptionsId?: number;
  muleTargetingId?: number;
  mobile?: number;
  county?: string;
  municipality?: string;
  idGeoCounty?: number;
  idGeoLocalities?: number;
  idGeoDistricts?: number;
  childrenCount?: number;
}

export class ItemFlatNode {
  id: number;
  level: number;
  name: string;
  type: string;
  expandable: boolean;
  parentName?: string;
  value?: string;
  filter_value?: string;
  web?: number;
  total?: number;
  muleTargetingOptionsId?: number;
  muleTargetingId?: number;
  mobile?: number;
  childrenCount?: number;
  children?: any;
}

export interface County {
  idGeoCounty: number;
  name: string;
  type: string;
}

export interface District {
  idGeoDistricts: number;
  name: string;
  type: string;
  municipality: string;
  parentName?: string;
}

export interface Locality {
  idGeoLocalities: number;
  name: string;
  type: string;
  county: string;
  parentName?: string;
}

@Injectable()
export class GeoDataTreeService {

  _treeData = new BehaviorSubject([]);
  treData$ = this._treeData.asObservable();

  initialCounties: ItemNode[];
  initialLocalities: ItemNode[];
  initialDistricts: ItemNode[];

  sortAlphabetically(items: ItemNode[]) {
    items.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  sortstartsWith(items: ItemFlatNode[], searchString: string) {
    items.sort((a, b) => {
      const aNorm = this._normalize(a.name);
      const bNorm = this._normalize(b.name);
      const searchNorm = this._normalize(searchString);

      if (aNorm.startsWith(searchNorm) && !bNorm.startsWith(searchNorm)) {
        return -1;
      } else if (!aNorm.startsWith(searchNorm) && bNorm.startsWith(searchNorm)) {
        return 1;
      } else {
        if (aNorm > bNorm) {
          return 1;
        } else if (aNorm < bNorm) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  _normalize(filter: string): string {
    return filter
      .trim()
      // .normalize('NFKD')
      .replace(/[\u0300-\u036F]/g, '')
      .toLocaleLowerCase();
  }

  constructTree(
    counties: ItemNode[],
    localities: ItemNode[],
    districts: ItemNode[]
  ): void {

    let hashTable: { [key:string]: ItemNode} = {};
    let treeData = [];

    counties = counties.reduce((acc, e) => {
      if (e && e.name) {
        const temp = {
          ...e,
          children: [],
          childrenCount: 0,
          level: 0,
          id: e.idGeoCounty
        };
        hashTable[e.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    localities = localities.reduce((acc, e) => {
      if (e && e.name && e.county) {
        const temp = {
          ...e,
          children: [],
          childrenCount: 0,
          level: 0,
          parentName: e.county,
          id: e.idGeoLocalities
        };
        hashTable[e.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    districts = districts.reduce((acc, e) => {
      if (e && e.name && e.municipality) {
        const temp = {
          ...e,
          level: 0,
          parentName: e.municipality,
          id: e.idGeoDistricts
        };
        hashTable[e.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    this.sortAlphabetically(counties);
    this.sortAlphabetically(localities);
    this.sortAlphabetically(districts);

    const data = counties.concat(localities, districts);

    data.forEach((d: ItemNode) => {
      if (d.parentName && hashTable[d.parentName]) {
        hashTable[d.name].level = 1 + hashTable[d.parentName].level;
        hashTable[d.parentName].children.push(hashTable[d.name]);
        hashTable[d.parentName].childrenCount += 1;
      } else {
        treeData.push(hashTable[d.name]);
      }
    });

    this.initialCounties = this.initialCounties ? this.initialCounties : counties;
    this.initialLocalities = this.initialLocalities ? this.initialLocalities : localities;
    this.initialDistricts = this.initialDistricts ? this.initialDistricts : districts;

    this._treeData.next(treeData);
  }

  reConstructTree(
    counties: any,
    localities: any,
    districts: any,
    searchString?: string
  ): void {

    let hashTable: { [key:string]: ItemNode} = {};
    let treeData = [];

    counties = counties.reduce((acc, e) => {
      if (e && e.name) {
        const temp = {
          ...e,
          children: []
        };
        hashTable[temp.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    localities = localities.reduce((acc, e) => {
      if (e && e.name) {
        const temp = {
          ...e,
          children: []
        };
        hashTable[temp.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    districts = districts.reduce((acc, e) => {
      if (e && e.name) {
        const temp = {
          ...e
        };
        hashTable[temp.name] = temp;
        acc.push(temp);
      }
      return acc;
    }, []);

    const data = counties.concat(localities, districts);

    data.forEach((d: ItemNode) => {
      if (d.parentName) {
        if (hashTable[d.parentName]) {
          hashTable[d.parentName].children.push(hashTable[d.name]);
        } else if (d.parentName && !hashTable[d.parentName]) {
          treeData.push(d);
        }
      } else {
        treeData.push(hashTable[d.name]);
      }
    });

    if (searchString) {
      this.sortstartsWith(treeData, searchString);
    }
    this._treeData.next(treeData);
  }
}
