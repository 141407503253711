import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {TableMetadataModel} from "../../../../models/table-metadata.model";
import {MatDialog} from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {ApiService} from "../../../../services/api.service";
import {CreateNewsDialogComponent} from "./create-news-dialog/create-news-dialog.component";
import {NewsPreviewDialogComponent} from "./create-news-dialog/news-preview.dialog/news-preview-dialog.component";
import {DateAdapter} from "@angular/material/core";
import {ImageCroppedEvent} from "ngx-image-cropper";

declare var toastr: any;
declare var moment: any;

export interface NewsCreationModel {
  title: string;
  body: string;
  image: string;
  type: string;
  schedule: string;
}


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class NewsComponent implements OnInit {

  headers =
    {
      id: "ID",
      news_title: "Rubrik",
      display_date: "Schemaläggning",
      type: "Typ",
      status: "Status"
    };
  columns: any = ['id', 'news_title', 'display_date', 'type', 'status'];
  expandedElement: any;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;

  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  filterTimeout;
  filterLoad: boolean = false;
  showPaginator: boolean = true;
  filtered: boolean = false;
  originalData;

  loadComponent: boolean = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(public dialog: MatDialog,
              public store: StoreService,
              private apiservice: ApiService,
              private _dateAdapter: DateAdapter<Date>,
              private _cd: ChangeDetectorRef) {
    this._dateAdapter.setLocale('sv');
  }

  ngOnInit(): void {
    this.getNewsList();
  }

  getNewsList() {
    this.apiservice.getJSON(this.store.apiURL + "/NewsServlet")
      .subscribe(res => {
        if (res) {
          if (res.responseCode === 200) {
            if (res.data) {

              res.data.forEach(elm => {
                elm.data = JSON.parse(elm.data);
                elm['display_date'] = moment(elm.activeTs).format("YYYY-MM-DD");
                elm['news_title'] = elm.data.title;
              });

              res.data = res.data.reverse();
              this.originalData = res.data;
              this.dataSource = new MatTableDataSource(res.data);
            } else {
              this.dataSource = new MatTableDataSource([]);
            }
            this.decideItemsToBeShowed(res.data);
            if (!this._cd['destroyed']) {
              this._cd.detectChanges();
            }
          } else {
            this.dataSource = new MatTableDataSource([]);
          }
          this.loadComponent = false;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.showPaginator = false;
    this.filterLoad = true;

    const data = Object.assign([], this.originalData);
    const filtered = data.filter(elm =>
      elm.id.toString().indexOf(filterValue.trim()) !== -1 ||
      elm.data.title?.toLowerCase().indexOf(filterValue.trim()) !== -1
    );

    const arr = [];
    for (let i = 0; i < this.tableMetadata.page_size; i++) {
      if (filtered[i]) {
        arr.push(filtered[i]);
      }
    }

    this.dataSource = new MatTableDataSource(arr);
    this.tableMetadata.length = filtered.length;
    this.tableMetadata.page = 0;

    this.showPaginator = true;
    this.filterLoad = false;
  }

  expandRow(element) {
    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }

    if (this.expandedElement) {
      this.croppedImage = undefined;
      if (element.data.image) {
        this.croppedImage = element.data.image;
      }
    }
  }

  addNews() {
    const dialogRef = this.dialog.open(CreateNewsDialogComponent, {
      panelClass: ['modal-size-md', 'modal-height-control']
    });
    dialogRef.afterClosed().subscribe((data: NewsCreationModel) => {
      if (data) {

        if(data.image) {
          const base64 = data.image;
          const fileType = data.image.split(';')[0].split('/')[1];
          const obj =
            {
              base64: base64,
              type: fileType,
              key: 'image'
            };
          this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
            .subscribe(res => {
              const image = res[0]["image"];
              data.image = image;
              const obj =
                {
                  data:
                    JSON.stringify({
                      title: data.title,
                      body: data.body,
                      image: data.image
                    }),
                  type: data.type,
                  activeTs: data.schedule
                };

              this.apiservice.putJSON(this.store.apiURL + '/NewsServlet', obj)
                .subscribe(res => {
                  this.getNewsList();
                  toastr.info('Inlägget är tillagd');
                });
            });
        }else{
          const obj =
            {
              data:
                JSON.stringify({
                  title: data.title,
                  body: data.body,
                }),
              type: data.type,
              activeTs: data.schedule
            };
          this.apiservice.putJSON(this.store.apiURL + '/NewsServlet', obj)
            .subscribe(res => {
              this.getNewsList();
              toastr.info('Inlägget är tillagd');
            });
        }
      }
    });
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.decideItemsToBeShowed(this.originalData);
  }


  decideItemsToBeShowed(data) {
    const multiplier: number = (this.tableMetadata.page + 1);
    const amountToFetch: number = (this.tableMetadata.page_size * multiplier) - 1;
    const startNum: number = this.tableMetadata.page === 0 ? 0 : this.tableMetadata.page_size * this.tableMetadata.page;

    this.tableMetadata.length = data.length;

    const displayElements = [];
    for (let i = startNum; i <= amountToFetch; i++) {
      if (data[i]) {
        displayElements.push(data[i]);
      }
    }
    this.dataSource = new MatTableDataSource(displayElements);
  }

  previewNews(news) {
    news.data['type'] = news.type; // Workaround because create form has type in the same object hierarchy
    this.dialog.open(NewsPreviewDialogComponent, {
      width: '1200px',
      data: news.data
    });
  }

  saveChanges(news) {
    const obj =
      {
        id: news.id,
        data:
          JSON.stringify({
            title: news.data.title,
            body: news.data.body,
            image: news.data.image
          }),
        type: news.type,
        activeTs: news.activeTs
      };
    this.apiservice.postJSON(this.store.apiURL + '/NewsServlet', [obj])
      .subscribe(res => {
        this.getNewsList();
        toastr.info('Inlägget är uppdaterad');
      });
  }

  fileChangeEvent(event: any, imageObj): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent, news) {
    this.croppedImage = event.base64;
    const base64 = this.croppedImage;
    const fileType = this.croppedImage.split(';')[0].split('/')[1];
    const obj =
      {
        base64: base64,
        type: fileType,
        key: 'image'
      };
    this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
      .subscribe(res => {
        news.data.image = res[0].image;
      });
  }

  changeDateFormat(news) {
    const isoDateTime = new Date(news.activeTs.getTime() - (news.activeTs.getTimezoneOffset() * 60000)).toISOString();
    news.activeTs = isoDateTime;
    news['display_date'] = moment(isoDateTime).format("YYYY-MM-DD");
  }

  toggleNewsStatus(news){
    const obj =
      {
        id: news.id,
        status: news.status?.toUpperCase() === 'APPROVED' ? 'Disabled' : 'Approved'
      };

    this.apiservice.postJSON(this.store.apiURL + '/NewsServlet', [obj])
      .subscribe(res => {
        this.getNewsList();
        toastr.info(`Inlägget är ${news.status?.toUpperCase() === 'APPROVED' ? 'inaktiverat' : 'aktiverat'}`);
      });
  }


}
