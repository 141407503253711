
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {StoreService} from "./store.service";
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Router} from "@angular/router";
import { catchError } from 'rxjs/operators';

import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
@Injectable()
export class ApiService {

  constructor(
    private _http: HttpClient,
    private storeservice: StoreService,
    private router: Router) { }



  GET(url: string): Observable<any> {
      return this._http.get(url)
        .catch(this.handleError);
  }

  GETAdBlock(url: string): Observable<any> {
      return this._http.get(url);
  }




  getJSON(url: string): Observable<any> {

    let token;
    if(!this.storeservice.user){
      if(sessionStorage.getItem('userObj')){
        if(JSON.parse(sessionStorage.getItem('userObj'))) {
          token = JSON.parse(sessionStorage.getItem('userObj'))['token'];
        }
      }
    }else{
      token = this.storeservice.user.token;
    }


    if(token !== undefined) {
      let headers = new HttpHeaders();
      headers = headers.set("token", token);
      return this._http.get(url,{headers: headers})
        .catch(this.handleError);
    }else{
      return this._http.get(url)
        .catch(e => {
          if(e.status === 401){
            return observableThrowError('Unauthorized');
          }
          sessionStorage.removeItem("userObj");
          this.router.navigate(['login']);
        });
    }
  }


  deleteJSON(url: string, body?): Observable<any> {

    let token;
    if(this.storeservice.user === undefined){
      if(sessionStorage.getItem('userObj')) {
        if (JSON.parse(sessionStorage.getItem('userObj'))) {
          token = JSON.parse(sessionStorage.getItem('userObj'))['token'];
        }
      }
    }else{
      token = this.storeservice.user.token;
    }

    if(token !== undefined) {
      let headers = new HttpHeaders();
      headers = headers.set("token", token);
      return this._http.delete(url,{headers: headers})
        .catch(this.handleError);
    }else{
      return this._http.delete(url)
        .do(data => JSON.stringify(data))
        .catch(this.handleError);
    }
  }



  putJSON(url: string, body: Object): Observable<any> {


    let token;
    if(this.storeservice.user === undefined){
      if(sessionStorage.getItem('userObj')) {
        if (JSON.parse(sessionStorage.getItem('userObj'))) {
          token = JSON.parse(sessionStorage.getItem('userObj'))['token'];
        }
      }
    }else{
      token = this.storeservice.user.token;
    }

    if(token !== undefined){
      let headers = new HttpHeaders();
      headers = headers.set("token", token);
      return this._http.put(url, JSON.stringify(body), {headers: headers})
        .catch(this.handleError);
    }else{
      return this._http.put(url, JSON.stringify(body))
        .catch(this.handleError);
    }
  }

  POST(url: string, body: Object): Observable<any> {
      return this._http.post(url, JSON.stringify(body))
        .catch(this.handleError);
  }

    postJSON(url: string, body: Object): Observable<any> {

      let token;
      if(this.storeservice.user === undefined){
        if(sessionStorage.getItem('userObj')) {
          if (JSON.parse(sessionStorage.getItem('userObj'))) {
            token = JSON.parse(sessionStorage.getItem('userObj'))['token'];
          }
        }
      }else{
        token = this.storeservice.user.token;
      }

    if(token !== undefined){
      let headers = new HttpHeaders();
      headers = headers.set("token", token);
      return this._http.post(url, JSON.stringify(body), {headers: headers})
        .catch(this.handleError);
    }else{
      return this._http.post(url, JSON.stringify(body))
        .catch(this.handleError);
    }
  }


  private handleError(error) {
    console.error(error);
    /*this.storeservice.user = undefined;*/
   /* sessionStorage.removeItem("userObj");
    this.router.navigate(['login']);*/

    return observableThrowError(error || 'Server error');
  }
}


/*
getJSON(url: string, addHeader = true): Observable<any> {
let header = new HttpHeaders();
if (this.storeservice.user && this.storeservice.user.token) {
  header = header.set("token", this.storeservice.user.token);
}
return this._http.get(url, addHeader ? { headers: header } : {})
  .catch(e => {
    if(e.status === 401){
      this.logoutUser();
      return Observable.throw('Unauthorized');
    }
  });
}*/
