import {NgModule, ModuleWithProviders} from '@angular/core';
import {StoreService} from "./store.service";
import {ApiService} from "./api.service";
import {GlobalfunctionalityService} from "./globalfunctionality.service";
import {DomService} from "./dom.service";
import {FormcontrolService} from "./formcontrol/formcontrol.service";
import {WarningConfirmationDialogComponent} from './dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component';
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [
    WarningConfirmationDialogComponent
  ],
  entryComponents: [WarningConfirmationDialogComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [StoreService, ApiService, GlobalfunctionalityService, DomService, FormcontrolService]
    };
  }
}
