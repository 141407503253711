import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../../../services/store.service";
import set = Reflect.set;
import {WarningConfirmationDialogComponent} from "../../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-target-category-selection',
  templateUrl: './target-category-selection-dialog.component.html',
  styleUrls: ['./target-category-selection-dialog.css'],
})
export class TargetCategorySelectionDialogComponent implements AfterViewInit, OnInit {

  disableAnimation = true;
  copiedData;
  category;
  context;

  constructor(public dialogRef: MatDialogRef<TargetCategorySelectionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: StoreService,
              public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  ngOnInit(): void {

    this.category = JSON.parse(JSON.stringify(this.data.category));
    this.context = JSON.parse(JSON.stringify(this.data.context));

    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
  }

  underCategoryChanged(underCategory) {
    underCategory['indeterminate'] = false;
    if (underCategory.selected) {
      if (underCategory.children) {
        underCategory.children.forEach(elm => elm.selected = true);
        underCategory['amount_selected'] = underCategory.children.length;
      }
    } else {
      if (underCategory.children) {
        underCategory.children.forEach(elm => elm.selected = false);
        underCategory['amount_selected'] = 0;
      }
    }
  }


  openPage(category) {
    const key = JSON.parse(category.mapped_option)[0].value;
    if(category.context.toUpperCase() === 'PRISJAKT') {
      window.open(`https://www.prisjakt.nu/kategori.php?k=${key}`, '_blank');
    }else if(category.context.toUpperCase() === 'KLART.SE'){
      window.open(`https://www.klart.se/se/${category.target_option.replace(' ', '-').toLowerCase()}`, '_blank');
    }
  }

  productCategoryChanged(underCategory) {

    // Settimeout needed for angular bug, not updating state correctly without it
    setTimeout(() => {
      underCategory['selected'] = underCategory.children.length === underCategory.children.filter(elm => elm.selected).length;
      if (!underCategory['selected']) {
        underCategory['indeterminate'] = underCategory.children.filter(elm => elm.selected).length > 0;
      } else {
        underCategory['indeterminate'] = false;
      }
      underCategory['amount_selected'] = underCategory.children.filter(elm => elm.selected).length;
    });
  }


  close() {
    if(!this.noChange()) {
      const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-xs',
        data: {
          text: 'Vill du spara dina ändringar?',
          confirm_btn: 'Ja, Spara ändringarna',
          abort_btn: 'Nej, Stäng utan att spara'
        }
      });

      dialog.afterClosed().subscribe(save => {
        if(save){
          this.save();
        }else if(save ===  false){
          this.abort();
        }
      });
    }else{
      this.abort();
    }
  }

  noChange():boolean {
    return JSON.stringify(this.category) === JSON.stringify(this.data.category) && JSON.stringify(this.context) === JSON.stringify(this.data.context);
  }

  abort() {
    this.dialogRef.close(false);
  }

  save() {

    const index = this.context.all_category_targets.findIndex(cat => cat.id === this.category.id);
    this.context.all_category_targets[index] = this.category;

    this.dialogRef.close(
      {
      category: this.category,
      context: this.context
      }
      );
  }

}
