import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-appnexus-advertiser-task',
  templateUrl: 'appnexus-advertiser-task.component.html'
})


export class AppnexusAdvertiserTaskComponent{

  @Input() selectedTask: any;
  @Input() userInfo: any;
  @Input() companyInfo: any;
  @Output() rejectCompany: EventEmitter<any> = new EventEmitter();
  @Output() approveCompany: EventEmitter<any> = new EventEmitter();


  handleReject(){
    this.rejectCompany.emit();
  }

  handleApprove(){
    this.approveCompany.emit();
  }

}
