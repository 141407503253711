import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {CampaignStatusNumbersModel} from "../../campaign/models/campaign-status-numbers.model";
import {ApiService} from "../../../services/api.service";
import {StoreService} from "../../../services/store.service";
import {ChangeDetectorRef} from "@angular/core";



export class BrandDataSourceComponent extends DataSource<string | undefined> {

  _length: number;
  private _pageSize = 100;
  _cachedData;
  private _fetchedPages = new Set<number>();
  _dataStream;
  private _subscription = new Subscription();

  private _option: string;
  private _filter: string;

  brands;
  timeout;

  constructor(private apiservice: ApiService,
              private store: StoreService,
              private report: boolean,
              private filter: string,
              private _cd: ChangeDetectorRef) {
    super();

    this._option = this.report ? 'brandsReportList' : 'brandsList';
    const limit: number = filter !== undefined ? this._pageSize : 0;
    this._filter = filter === undefined ? '' : filter;

    this.apiservice.getJSON(this.store.apiURL + `/BrandsServlet?option=${this._option}&limit=${limit}&offset=0&filter=${this._filter}`)
      .subscribe((res: ResponseBodyModel) => {
        this._length = res.data['maxCount'];
        this._cachedData = Array.from<string>({length: this._length});
        this._dataStream = new BehaviorSubject<(string | undefined)[]>(this._cachedData);

        setTimeout(() => {
          this.store.brandListLoading = false;
          if(!this._cd['destroyed']) {
            this._cd.detectChanges();
          }
        }, 500);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<(string | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this._getPageForIndex(range.start);
      const endPage = this._getPageForIndex(range.end - 1);

      for (let i = startPage; i <= endPage; i++) {
        this._fetchPage(i);
      }
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  private _fetchPage(page: number) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this._fetchedPages.has(page)) {
        return;
      }
      this._fetchedPages.add(page);

      const from: number = page * this._pageSize;

      this.apiservice.getJSON(this.store.apiURL + `/BrandsServlet?option=${this._option}&limit=${this._pageSize}&offset=${from}&filter=${this._filter}`)
        .subscribe((res: ResponseBodyModel) => {

          let items = [];
          if((page * this._pageSize) + this._pageSize > this._cachedData.length){
            const diff = ((page * this._pageSize) + this._pageSize) - this._cachedData.length;
            const itemsToFetch = this._pageSize - diff;
            items = Array.from({length: itemsToFetch});
          }else{
             items = Array.from({length: this._pageSize});
          }

          this._cachedData.splice((page * this._pageSize) > this._length ? this._length : (page * this._pageSize), this._pageSize,
            ...items
              .map((_, i) => {
                if(res.data['brands'][i]) {
                  return res.data['brands'][i];
                }
              }));
          this._dataStream.next(this._cachedData);
        });
    }, 200);
  }
}
