import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ResponseBodyModel} from "../../../../models/response-body.model";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";
import {FlatTreeControl} from "@angular/cdk/tree";
import { MatIconRegistry } from "@angular/material/icon";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import {DomSanitizer} from "@angular/platform-browser";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";

declare var toastr: any;

@Component({
  selector: 'app-campaign-task',
  templateUrl: 'campaign-task.component.html',
  styleUrls: ['campaign-task.component.css']
})


export class CampaignTaskComponent implements OnChanges {
  @Input() selectedTask: any;
  @Input() loadTask: boolean;
  @Input() campaignName: string;
  @Input() reviewData: any;
  @Input() objectiveKey: any;
  @Input() billingObj: any;
  @Input() selectedObjective: any;
  @Input() task: any;
  @Input() days: any;
  @Input() translation: any;
  @Input() taskIndex: any;

  @Output() rejectCampaign: EventEmitter<any> = new EventEmitter();
  @Output() rebookCampaign: EventEmitter<any> = new EventEmitter();
  @Output() approveCampaign: EventEmitter<any> = new EventEmitter();
  @Output() syncCampaign: EventEmitter<any> = new EventEmitter();
  @Output() disableCampaign: EventEmitter<any> = new EventEmitter();
  @Output() resetTasks: EventEmitter<any> = new EventEmitter();

  showMaterialUploadedOutsideBtn: boolean = false;
  blockClick: boolean = false;

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.target_option,
      level: level
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private apiservice: ApiService,
    private store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'chevron-right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-right.svg'));
    iconRegistry.addSvgIcon(
      'chevron-down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-down.svg'));
    iconRegistry.addSvgIcon(
      'loader',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/loading-spinner.svg'));
  }

  hasChild = (_: number, node: any) => node.expandable;


  ngOnChanges(): void {

    if(this.reviewData.contexts){
      if(this.reviewData.contexts.selected_categories){
        this.dataSource.data = this.reviewData.contexts.selected_categories;
        this.treeControl.expandAll();
      }
    }

    if (this.reviewData.searchword) {
      if (this.reviewData.searchword.category) {
        if (this.reviewData.searchword.category.formatted) {
          this.dataSource.data = this.reviewData.searchword.category.formatted;
        }
      }
    }

    if (this.selectedTask) {
      if (this.selectedTask.campaign_creative_status.toLowerCase() === 'draft') {
        this.showMaterialUploadedOutsideBtn = true;
      }
    }

  }


  specificMunicipalitiesChosen(geo) {
    return geo.children.length !== geo.children.filter(elm => elm.checked).length;
  }

  handleReject() {
    this.rejectCampaign.emit();
  }

  handleApprove() {
    this.approveCampaign.emit();
  }

  handleRebook(task) {
    this.rebookCampaign.emit(task);
  }

  handleDisable(task) {
    this.disableCampaign.emit(task);
  }


  handleSync(task, taskIndex) {
    const obj = {
      task: task,
      index: taskIndex
    };
    this.syncCampaign.emit(obj);
  }


  uploadMaterialOutside() {
    if (!this.blockClick) {
      this.blockClick = true;

      this.apiservice.getJSON(this.store.apiURL + '/ModulesValuesServlet?campaigns=' + JSON.stringify([this.selectedTask.campaign_task]))
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            const adforsize = res.data.filter(elm => elm['module_value_type'] === 'adforsize')[0];
            const values = JSON.parse(adforsize['module_value_value']);

            // Check if no creatives has been uploaded, in that case mark all as uploaded outside of admarket
            // If a creative has been uploaded dont mark any creative and open task so that adops can handle the inputed creative

            const creativeUploaded: boolean = values.selected.filter(elm => elm.complete).length > 0;

            if(!creativeUploaded) {
              // Set each selected format as uploaded outside
              values.selected.forEach(elm => {
                elm['uploaded_outside'] = true;
              });

              const obj =
                {
                  isComplete: true,
                  selected: values.selected,
                };

              const postObj =
                {
                  id: adforsize['module_value_id'],
                  value: JSON.stringify(obj)
                };

              this.apiservice.postJSON(this.store.apiURL + '/ModulesValuesServlet', [postObj])
                .subscribe((res: ResponseBodyModel) => {
                  if (res.responseCode === 200) {
                    this.setTaskStatus('approve_creatives', 'Materialet är nu markerat som hanterad utanför AdMarket');

                  }
                });
            }else{
              this.setTaskStatus('creatives_pending', `Ett eller flera format är redan uppladdat i Admarket – vänligen kontrollera creative tasken med ID ${this.selectedTask.campaign_task}`);
            }
          }
        });
    }
  }

  setTaskStatus(param: string, message: string): void{
    this.apiservice.getJSON(`${this.store.apiURL}/TaskManagerServlet?campaign=${this.selectedTask.campaign_task}&option=${param}`)
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          this.blockClick = false;
          toastr.info(message);
          this.resetTasks.emit();
        }
      });
  }


}

