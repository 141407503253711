import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {ApiService} from "../../../../../../../../services/api.service";
import {FormControl} from "@angular/forms";
import {CreativemoduleService} from "../../../creativemodule.service";


@Component({
  selector: 'app-background-color',
  templateUrl: 'background-color.component.html'
})

export class BackgroundColorComponent implements OnInit {
  @Input() backgroundColor: FormControl;
  @Input() backgroundImage: FormControl;
  @Input() backgroundOption: FormControl;
  @Output() updateACMMediaLibrary: EventEmitter<any> = new EventEmitter();

  selectedOption = 'white';
  column;

  uploadNewImage: boolean = false;

  options =
    [
      {key: 'white', 'display_name': 'Vit', value: '#FFFFFF', option: 'color', type: 'text'},
      {
        key: 'grey',
        'display_name': 'Grå',
        value: 'https://mds.pliing.com/res/2020/05-25/591b88eb-f13d-4835-81c7-081d8913db70.jpg',
        option: 'image',
        type: 'image'
      },
      {key: 'custom', 'display_name': 'Ladda upp egen', value: 'custom', option: 'custom'}
    ];

  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private _creativeService: CreativemoduleService
  ) {

  }

  ngOnInit(): void {

  }

  optionChanged(): void {
    const option = this.options.find(elm => elm.key === this.selectedOption);
    this.backgroundOption.setValue(option.option);
    this._creativeService.resetImageUpload.next(true);
    if(option.option === 'color'){
      this.backgroundColor.setValue(option);
      this.updateACMMediaLibrary.emit();
    }else if(option.option === 'image'){
      this.backgroundImage.setValue(option);
      this.updateACMMediaLibrary.emit();
    }else{
      /*console.log('custom option');*/
    }
  }

}
