import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {ApiService} from "../../../../services/api.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ResponseBodyModel} from "../../../../models/response-body.model";
import {forkJoin} from "rxjs";

declare var toastr: any;

@Component({
  selector: 'app-create-feed-dialog',
  templateUrl: './create-feed-dialog.component.html'
})
export class CreateFeedDialogComponent {
  form: FormGroup;
  load: boolean = false;

  feed_options = [
    {
      name: 'Google- eller Facebook-URL',
      url: true,
      disabled: false
    },
    {
      name: 'Butiks-ID från Prisjakt (kommer snart)',
      url: true,
      disabled: true
    }
  ];

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateFeedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private apiservice: ApiService) {
    this.form = this._fb.group({
      name: ['', [Validators.required]],
      feed_option: [this.feed_options.find(elm => elm.name === 'Google- eller Facebook-URL'), [Validators.required]],
      feed_url: ['', [Validators.required]]
    });
  }

  close() {
    this.dialogRef.close();
  }

  chooseFeedOption() {

  }

  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.name === o2.name;
    }
  }

  submitForm(isValid: boolean) {
    if (isValid) {
      this.load = true;
      this.apiservice.getJSON(this.store.apiURL + '/ACMLibServlet')
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            if (!res.data['libraries'].filter(library => library.name === 'AdMarket - Driva trafik - Annons Data').length) {
              this.apiservice.postJSON(this.store.apiURL + "/ACMLibServlet", {
                name: 'AdMarket - Driva trafik - Annons Data',
                categoryId: 2
              }).subscribe((res: ResponseBodyModel) => {
                if (res.responseCode === 201) {
                  this.addColumns(res.data['libraryId']);
                }
              });
            } else {
              this.createCatalog();
            }
          }

        });
    }
  }

  createCatalog() {
    const {name, feed_url: feedUrl} = this.form.value;

    this.dialogRef.close(this.apiservice.postJSON(this.store.apiURL + "/ACMCatalogueServlet", {name, feedUrl, type: 1}));

    /*this.apiservice.postJSON(this.store.apiURL + "/ACMCatalogueServlet", {name, feedUrl, type: 1})
      .subscribe((res: ResponseBodyModel) => {
        this.load = false;
        if (res.responseCode === 201) {
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close(false);
        }
      });*/
  }

  addColumns(libraryId: number) {
    const logoImage = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "logo.image.url",
        type: "image",
        defaultValue: "https://mds.pliing.com/res/2020/06-16/23046260-43b5-413e-a09b-5f5fb1a12c97.png"
      });
    const fallbackImage = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "fallback.image.url",
        type: "image",
        defaultValue: "https://mds.pliing.com/res/2020/06-16/48569560-806f-4cfb-ade0-5889c4b2c474.jpeg"
      });
    const fallbackImageClickLink = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "fallback.image.clickLink",
        type: "clickLink",
        defaultValue: "https://"
      });
    const productBgImage = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "product.background.image",
        type: "image",
        defaultValue: "https://mds.pliing.com/res/2020/05-05/Schibsted/defaultProductBackground.jpg"
      });
    const productBgColor = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "product.background.color",
        type: "text",
        defaultValue: "#fff"
      });
    const productBgOption = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "product.background.option",
        type: "text",
        defaultValue: "color"
      });
    const staticImage = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "statics[0].image",
        type: "image",
        defaultValue: "https://mds.pliing.com/res/2020/06-16/48569560-806f-4cfb-ade0-5889c4b2c474.jpeg"
      });
    const staticImageClickLink = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "statics[0].clickLink",
        type: "clickLink",
        defaultValue: "https://"
      });
    const StaticImageFrequency = this.apiservice.POST(this.store.apiURL + '/ACMColServlet',
      {
        acmLibId: libraryId,
        name: "static.options.frequency",
        type: "text",
        defaultValue: "off"
      });

    forkJoin([logoImage, fallbackImage, fallbackImageClickLink, productBgImage, productBgColor, productBgOption, staticImage, staticImageClickLink, StaticImageFrequency])
      .subscribe(res => {
        if (res.every(elm => elm.responseCode === 201)) {
          this.apiservice.postJSON(this.store.apiURL + "/ACMRowServlet", {
            acmLibId: libraryId,
            name: 'default',
            cells: []
          })
            .subscribe((res: ResponseBodyModel) => {
              if (res.responseCode === 201) {
                this.createCatalog();
              }
            });
        }
      });
  }

}
