import {
  ChangeDetectorRef, Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild,
  ElementRef
} from '@angular/core';
import {Router} from "@angular/router";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {IframeDialogComponent} from "./iframe-dialog/iframe-dialog.component";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {PlatformLocation} from "@angular/common";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {startWith} from "rxjs-compat/operator/startWith";
import {pairwise} from "rxjs-compat/operator/pairwise";
import {CampaignService} from "../../../campaign.service";

@Component({
  selector: 'app-adtemplatemodule',
  templateUrl: './adtemplatemodule.component.html',
  styleUrls: ['./adtemplatemodule.component.css']
})
export class AdtemplatemoduleComponent implements OnInit, AfterViewInit {

  @ViewChild('templateBtn') templateBtn: ElementRef;
  @ViewChild('expandedBox') expandedBox: ElementRef;
  @Output() readMoreHandler = new EventEmitter();
  selectedTemplate;
  module;
  auxSelectedTemplate= {
    value: undefined
  };

  readMore: boolean = false;
  replacedFormatName: string;

  visible: boolean = false;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private _cd: ChangeDetectorRef,
              public dialog: MatDialog,
              private createcampaign: CreatecampaignComponent,
              private _location: PlatformLocation,
              public creativeService: CreativemoduleService,
              private campaignService: CampaignService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {

    // When user hits browser back button close the creative dialog
    _location.onPopState(() => {
      this.createcampaign.reset();
    });

    iconRegistry.addSvgIcon(
      'eye',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/view_blue.svg'));
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.module = this.globalfunctionality.getModule("templates");
    const templates = this.globalfunctionality.getModuleValues("templates");

    if(templates.selectedTemplate || templates.id){ // Workaround for backwardscompability is to check if template module has the ID set, in that case pick the default template as the one selected (in that period only single image was availble)
      let template: string = 'Single Image';
      if (this.campaignService.structure.get('objective').value.id === 6) {
        template = 'Content Display';
      }
      this.selectedTemplate = templates.selectedTemplate ? templates.selectedTemplate : template;
      this.continueDecision();
    }else{
      this.selectedTemplate = this.module.param.options[0].name;
      this.chooseTemplate();
    }

    this.auxSelectedTemplate.value = this.selectedTemplate;
  }


  readMoreTriggered(){
    this.readMore = !this.readMore;
    this.detectChange();
    if(this.readMore){
      this.readMoreHandler.emit(this.expandedBox.nativeElement.clientHeight);
    }else{
      this.readMoreHandler.emit(0);
    }

  }

  getReplacedFormatName(): string{
    return this.selectedTemplate.replace(/\s+/g, '_').toLowerCase();
  }

  chooseTemplate() {

    if(this.module.param.options.length > 1 && this.store.productType === 'Bannerannons'){
      const creatives = this.globalfunctionality.getModule("creatives");
      if(creatives.module_values?.creative_data){

        const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
          panelClass: 'modal-size-sm',
          data: {
            text: 'OBS!',
            desc: 'Om du ändrar ditt val försvinner ditt uppladdade material och måste återuppladdas.',
            confirm_btn: 'Fortsätt',
            abort_btn: 'Avbryt'
          }
        });

        dialog.afterClosed().subscribe(change => {
          if (change) {
            this.continueDecision(true);
          } else {
            this.selectedTemplate = this.auxSelectedTemplate.value;
            this._cd.detectChanges();
          }
        });

      }else{
        this.continueDecision();
      }
    }else{
      this.continueDecision();
    }
  }

  continueDecision(changed: boolean = false){
    // Reset readmore box if template changed
    if(this.readMore) {
      this.readMoreTriggered();
    }

    this.auxSelectedTemplate.value = this.selectedTemplate;
    let template = this.selectedTemplate;

    if(template.includes(this.store.selectedSize.size)) {
      template = template.replace(this.store.selectedSize.size, '').trim();
      this.selectedTemplate = template;
    }

    this.replacedFormatName = this.getReplacedFormatName();

    const module = this.globalfunctionality.getModule("templates");
    module.param.output = template;

    if(this.store.selectedSize.size !== '320x320'){
        template = template + ' ' + this.store.selectedSize.size;
    }

    module["module_values"].isComplete = true;
    module["module_values"]["selectedTemplate"] = template;
    this._cd.detectChanges();

    this.store.GUIFlow[this.store.page].forEach((elm) => {
      if (elm.type === "templates") {
        elm["module_values"].isComplete = true;
      }
    });

    this.globalfunctionality.page3Checker.next(true);
    this.globalfunctionality.templateChange.next(
      {
        selected: true,
        changed: changed,
        template: template
      });

    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.detectChange();
  }


  openDialog() {

    const dialogRef = this.dialog.open(
      IframeDialogComponent,
      {
        panelClass:['modal-size-xl', 'modal-height-control'],
        data: {
          selectedTemplate: this.selectedTemplate,
          templates: this.module.param.options
        },
      }
    );

    dialogRef.afterClosed().subscribe(template => {
      if(template){
        this.selectedTemplate = template;
        this.chooseTemplate();
      }
    });
  }

  detectChange() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }

}


