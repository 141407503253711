/**
 * Created by adssets on 2017-09-19.
 */
import { NgModule } from '@angular/core';
import { SafePipe } from '../modules/creative_components/creativemodule/creativemodule.component';
import { FilterPipe } from '../../../services/pipes/filter.pipe';
import { OrderByPipe } from './order-by.pipe';
import { NumberSpacesPipe } from './number-spaces.pipe';
import {RemoveTextSuffix} from "./remove-prisjakt-suffix";

@NgModule({
  declarations: [SafePipe, OrderByPipe, NumberSpacesPipe, RemoveTextSuffix],
  imports: [],
  exports: [SafePipe, OrderByPipe, NumberSpacesPipe, RemoveTextSuffix],
  providers: []
})
export class PipeModule {}
