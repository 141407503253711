import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {StoreService} from "../../../../../../../services/store.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {CreativemoduleService} from "../../creativemodule.service";
import {CampaignService} from "../../../../../campaign.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-custom-preview',
  templateUrl: 'custom-preview.component.html'
})

export class CustomPreviewComponent implements OnChanges{

  @Input() adPreviews;
  @Input() optimizationOption: string;
  @Input() noPreviewAvailable;
  @Input() videoExtension;
  @Input() facesArray;

  @Output() changePreviewOptionEmitter = new EventEmitter();

  selectedPreviewIndex: number = 0;
  selectedPreviewSite;
  group: FormGroup;

  showSafeArea: boolean = true;

  selectedOption: string = 'av';

  weatherOptions =
    [
      {
        option: 'av',
        value: undefined
      },
      {
        option: 'sun',
        value: 'sun'
      },
      {
        option: 'cloud',
        value: 'cloud'
      },
      {
        option: 'rain',
        value: 'rain'
      },
      {
        option: 'snow',
        value: 'snow'
      }
    ];

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              public creativeService: CreativemoduleService,
              public campaignService: CampaignService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon('eye-slash', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/eye-slash.svg'));

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.adPreviews) {
      if(!this.selectedPreviewSite) {
        this.selectedPreviewSite = this.adPreviews[0];
      }
    }
    if(this.facesArray) {
      if (this.facesArray.controls) {
        this.group = this.facesArray.controls[0] as FormGroup;
      }
    }
  }

  changePreviewOption(option: string){
    this.selectedOption = option;
    this.changePreviewOptionEmitter.emit(option);
  }
}
