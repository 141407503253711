import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {StoreService} from "../../../../../../services/store.service";
import {ResponseBodyModel} from "../../../../../../models/response-body.model";
import {ApiService} from "../../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {CampaignService} from "../../../../campaign.service";

declare var moment: any;

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.css']
})
export class IndicatorComponent implements OnInit, OnChanges {

  @Input() searchWordObj;
  @Output() indicatorLoading = new EventEmitter();
  @Output() triggerResetPage = new EventEmitter();
  @Output() capacityChangeTo = new EventEmitter<number>();


  product;

  forecastObj = {
    date_from: moment(new Date()).format('YYYY-MM-DD'),
    date_to: moment(new Date().setDate(new Date().getDate() + 30)).format('YYYY-MM-DD'),
    content_categories: [],
    content_geo: [],
    content_text: [],
    products: []
  };

  forecastTimeout;
  capacity: number = 0;

  indicatorPosition: string = '0%';

  load: boolean = false;

  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private _cd: ChangeDetectorRef,
    private campaignService: CampaignService
  ) {
  }


  ngOnChanges(): void {

    clearTimeout(this.forecastTimeout);

    this.load = true;
    this.indicatorLoading.emit(true);

    this.forecastObj.content_categories = [];
    this.forecastObj.content_geo = [];
    this.forecastObj.content_text = [];

    if (this.searchWordObj) {
      if (this.searchWordObj.category) {
        if (Object.keys(this.searchWordObj.category).length > 1) { // selected key will be there

          let content_categories = [];
          let categories = this.searchWordObj.category;
          let productsAdded: boolean = false;

          categories.selectedSubCategories.forEach(sub => {
            // If certain products has been select, add them to the forecast
            if (sub) {
              if (sub.children) {

                if (sub.children.length !== categories.selectedProductCategories.filter(prod => prod.subCategoryName === sub.target_option).length) {
                  productsAdded = true;
                  sub['productsAdded'] = true;

                  categories.selectedProductCategories.filter(prod => prod.subCategoryName === sub.target_option).forEach(prod => {
                    content_categories.push(prod.id);
                  });
                }
              }
            }

            // Set var if product has been chosen, this will affect logic that select objects to be sent to the forecast
            categories.selectedCategories.filter(elm => elm.target_option === sub.categoryName)[0]['productsAdded'] = productsAdded;

          });

          categories.selectedCategories.forEach(cat => {
            if (cat.productsAdded) { // If certain products has been added we know that atleast 1 category has been changed, the subcategory ids + product ids must be sent for that category

              categories.selectedSubCategories.forEach(sub => {
                if (sub.categoryName === cat.target_option) {
                    if (sub.children) {
                      if (sub.children.length === categories.selectedProductCategories.filter(prod => prod.subCategoryName === sub.target_option).length) {
                        content_categories.push(sub.id);
                      }
                    } else {
                      content_categories.push(sub.id);
                    }
                }
              });

            } else { // If no certain products has been selected we can check wether all subcategories has been chosen, in that case send the category id, otherwise the specific subcategory ids
              if (cat.children) {
                if (cat.children.length === categories.selectedSubCategories.filter(sub => sub.categoryName === cat.target_option).length) {
                  if (content_categories.indexOf(cat.id) === -1) {
                    content_categories.push(cat.id);
                  }
                } else {
                  categories.selectedSubCategories.forEach(sub => {
                    if (sub.categoryName === cat.target_option) {
                      if (content_categories.indexOf(sub.id) === -1) {
                        content_categories.push(sub.id);
                      }
                    }
                  });
                }
              }
            }

          });


          this.forecastObj.content_categories = content_categories;
        }
      }


      if (this.searchWordObj.geography) {
        if (this.searchWordObj.geography.selectedGeos) {
          let content_geo = [];
          this.searchWordObj.geography.selectedGeos.forEach(elm => {
            if (elm.children) {
              if (elm.children.filter(elm => elm.checked).length) {
                if (elm.children.length === elm.children.filter(elm => elm.checked).length) {
                  content_geo.push(elm.id);
                } else {
                  elm.children.filter(elm => elm.checked).forEach(elm => {
                    content_geo.push(elm.id);
                  });
                }
              } else {
                content_geo.push(elm.id);
              }
            } else {
              content_geo.push(elm.id);
            }
          });

          this.forecastObj.content_geo = content_geo;
        } else {
          // If nothing selected reset forecast object, user can have selected then switched option, in that case the forecast object needs to be cleared
          this.forecastObj.content_geo = [];
        }
      }

      if (this.searchWordObj.freetext) {
        this.forecastObj.content_text = this.searchWordObj.freetext.selectedFreeText;
      }
    }

    this.triggerResetPage.emit();

    this.forecastTimeout = setTimeout(() => {
      this.getForecast();
    }, 1000);

  }

  ngOnInit() {

    /*if (!this.store.selectedBrand) {
      this.globalfunctionality.restoreSelectedBrand();
    }*/
    this.apiservice.getJSON(this.store.apiURL + '/ContextServlet?productType=FreeTextAnnons&brand=' + this.campaignService.structure.get('brand').get('id').value)
      .subscribe((res: ResponseBodyModel) => {

        // Obtain product
        this.product = res.data[0]['contexts'][0]['products'][0];

        // BUILD CONTEXT MODULE
        let contexts = res.data[0];
        contexts['contexts'].forEach(site => {
          site['active'] = true;
          site['sub_contexts'] = [];

          site.products.forEach(elm => elm.selected = true);

          let obj =
            {
              name: 'Alla',
              selected: true,
              products: site.products,
              product_type: 'FreeTextAnnons'
            };
          delete site['products'];
          site['sub_contexts'].push(obj);

        });

        const contextValue = this.globalfunctionality.getModuleValues("context");
        contextValue["contexts"] = contexts['contexts'];
        contextValue["isComplete"] = true;

        sessionStorage.setItem("modules", JSON.stringify(this.store.modules));


        this.forecastObj.products.push(this.product.id_products);
        this.getForecast();

      });

  }

  getForecast() {
    this.apiservice.postJSON(this.store.apiURL + "/ForecastServlet", this.forecastObj)
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          if (res.data) {
            if (res.data['data']) {
              this.capacity = 0;
              res.data['data'].forEach(elm => {
                this.capacity += elm[1];
              });

              this.searchWordObj = this.globalfunctionality.getModuleValues('searchword');
              if (this.searchWordObj) {
                this.searchWordObj['forecast'] = this.forecastObj;
                this.searchWordObj['capacity'] = this.capacity;
                this.globalfunctionality.setModuleValue('searchword', this.searchWordObj);
                sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
              }


              if (this.capacity < 1000) {
                this.indicatorPosition = (0 + ((this.capacity - 0) / 1000) * (14 - 0)) + '%';
              } else if (this.capacity >= 1000 && this.capacity <= 5000) {
                this.indicatorPosition = (14 + ((this.capacity - 1000) / 4000) * (28 - 14)) + '%';
              } else if (this.capacity > 5000 && this.capacity <= 25000) {
                this.indicatorPosition = (28 + ((this.capacity - 5000) / 20000) * (43 - 28)) + '%';
              } else if (this.capacity > 25000 && this.capacity <= 100000) {
                this.indicatorPosition = (43 + ((this.capacity - 25000) / 75000) * (60 - 43)) + '%';
              } else if (this.capacity > 100000 && this.capacity <= 500000) {
                this.indicatorPosition = (60 + ((this.capacity - 100000) / 400000) * (77 - 60)) + '%';
              } else if (this.capacity > 500000 && this.capacity <= 1000000) {
                this.indicatorPosition = (77 + ((this.capacity - 500000) / 500000) * (100 - 77)) + '%';
              } else {
                this.indicatorPosition = '100%';
              }

              if (!this._cd['destroyed']) {
                this._cd.detectChanges();
              }

              this.load = false;
              this.indicatorLoading.emit(false);
              this.capacityChangeTo.emit(this.capacity);

              this.checkIfComplete();
            }
          }

          // TODO: REMOVE WORKAROUND TO BYPASS FORECAST
          /*this.capacity = 2000;
          this.checkIfComplete();
*/
        }
      });
  }


  checkIfComplete() {
    let values = this.globalfunctionality.getModuleValues("searchword");
    let complete: boolean = this.capacity >= 100;

    this.store.GUIFlow[2].forEach(function (elm) {
      if (elm.type === "searchword") {
        elm["module_values"].isComplete = complete;
        values.isComplete = complete;
      }
      if (elm.type === "context") {
        elm["module_values"].isComplete = complete;
      }
    });

    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    this.globalfunctionality.page2Checker.next(true);


  }

}
