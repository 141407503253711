import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-warning-confirmation-dialog',
  templateUrl: './warning-confirmation-dialog.component.html',
  styleUrls: ['./warning-confirmation-dialog.component.css']
})
export class WarningConfirmationDialogComponent{

  constructor(
    public dialogRef:MatDialogRef<WarningConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  close(){
    this.dialogRef.close();
  }
  abort(){
    this.dialogRef.close(false);
  }

  approve(){
    this.dialogRef.close(true);
  }
}
