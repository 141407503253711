import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {BrandDataSourceComponent} from "./brand-data-source.component";
import {ApiService} from "../../../services/api.service";


@Component({
  selector: 'app-table-header',
  templateUrl: 'table-header.component.html'
})

export class TableHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('brandDiv') brandDiv: ElementRef;

  @Input() column;
  @Input() headers;
  @Input() statuses;
  @Input() filter: boolean;
  @Input() sort: boolean;
  @Input() report: boolean;
  @Output() sortChange = new EventEmitter();
  @Output() filterChange = new EventEmitter();

  subscriptions: Subscription[] = [];

  hoveredColumn;

  filterInp;

  timeout;

  filterObj = {};

  brandFiltered: boolean = false;
  statusFiltered: boolean = false;
  loadBrandList: boolean = true;
  ds;

  constructor(
    public store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    private _cd: ChangeDetectorRef,
    private _apiService: ApiService
  ) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(elm => elm.unsubscribe());
  }

  ngOnInit(): void {

    if (this.filter) {
      this.subscriptions.push(this.globalfunctionality.clearColumnFilterSelection.subscribe(clear => {
        if (clear) {
          if (this.column === 'brand_name') {
            this.ds = new BrandDataSourceComponent(this._apiService, this.store, this.report, undefined, this._cd);
            this.filterInp = '';
          }
        }
      }));
    }

    if (this.column === 'brand_name') {
      this.store.brandListLoading = true;
      this.ds = new BrandDataSourceComponent(this._apiService, this.store, this.report, undefined, this._cd);
    }
  }

  clearFilter(): void {
    this.filterInp = '';
    if (this.column === 'brand_name') {
        this.ds._dataStream.value.forEach(brand => {
          if (brand.checked) {
            brand.checked = false;
          }
        });
        this.applyFilter('');
        this.selectBrand();
    }

    if (this.column === 'combined_status_value') {
      this.statuses.filter(elm => elm.checked).forEach(elm => {
        elm.checked = false;
      });
      this.selectStatus();
    }
  }

  applyFilter(value: string): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.store.brandListLoading = true;
      this._cd.detectChanges();
      this.ds = new BrandDataSourceComponent(this._apiService, this.store, this.report, value, this._cd);
    }, 500);
  }

  selectBrand(): void {
    const selected = [];

    this.ds._dataStream.value.filter(elm => elm.checked).forEach(elm => {
      selected.push(elm.id);
    });

    // Check if object key already exists
    if (this.filterObj['columnKey'] === 'campaign_brand_id') {
      this.filterObj['values'] = selected;
    } else {
      this.filterObj =
        {
          columnKey: 'campaign_brand_id',
          values: selected
        };
    }
    this.brandFiltered = selected.length > 0;
    this.filterChange.emit(this.filterObj);
  }

  selectStatus(): void {
    const selected = [];
    this.statuses.filter(elm => elm.checked).forEach(elm => {
      selected.push(elm.name);

      if (elm.name === 'Godkänd') {
        selected.push('Godkänd med varning');
      }
    });

    // Check if object key already exists
    if (this.filterObj['columnKey'] === 'combined_status') {
      this.filterObj['values'] = selected;
    } else {
      this.filterObj =
        {
          columnKey: 'combined_status',
          values: selected
        };
    }
    this.statusFiltered = selected.length > 0;
    this.filterChange.emit(this.filterObj);
  }

  getIcon(column: string): string {
    if (column === 'brand_name') {
      if (this.brandFiltered) {
        return 'filter-inactive';
      }
    } else if (column === 'combined_status_value') {
      if (this.statusFiltered) {
        return 'filter-inactive';
      }
    }
    return 'filter-active';
  }

}
