import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {ApiService} from "../../../../services/api.service";
import {FormControl, Validators} from "@angular/forms";
declare var toastr: any;
@Component({
  selector: 'app-create-brand-dialog',
  templateUrl: './create-brand-dialog.component.html',
  styleUrls: ['./create-brand-dialog.component.css']
})
export class CreateBrandDialogComponent implements OnInit {

  brand_name;

  brandNameFormControl = new FormControl('', [
    Validators.required
  ]);
  constructor(
    public dialogRef: MatDialogRef<CreateBrandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private apiservice: ApiService) { }

  ngOnInit() {
  }


  createBrand(){

    if(!this.brandNameFormControl.hasError("required")){

      let obj =
        {
        companies_id: this.store.user.companies[0].id,
        brands_name: this.brand_name
        };

      let arr = [];
      arr.push(obj);

      this.apiservice.putJSON(this.store.apiURL + "/BrandsServlet", arr)
        .subscribe(res => {
          const _body = res;
          if(_body.responseCode === 200) {
            toastr.info("Varumärke " + this.brand_name + " är skapad");
            this.dialogRef.close();
          }
        });

    }else{
      this.brandNameFormControl.markAsTouched();
    }

  }

}
