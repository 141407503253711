import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserInviteResponse} from "../../models/user-invite-response.model";
import {RegisterInvite} from "../../models/register-invite.model";
const ATOZ_REGEX = /(^[a-zA-ZéåäöÅÄÖÉ_ -]+)[^-]$/;
const MOBILE_REGEX = /(^\+?(46|0)7\d{8}$)/;

@Component({
  selector: 'app-invite-form',
  templateUrl: 'invite-form.component.html'
})

export class InviteFormComponent implements OnInit{
  @Input() showExpiredMsg: boolean;
  @Input() translation: any;
  @Input() data: UserInviteResponse;

  @Output() registerInvite: EventEmitter<RegisterInvite> = new EventEmitter();
  @Output() routeToStartPage: EventEmitter<any> = new EventEmitter();


  form: FormGroup;


  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {

    this.form = this._fb.group(
      {
        password: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(50)]],
        firstname: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern(ATOZ_REGEX)]],
        lastname: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern(ATOZ_REGEX)]],
        mobilenumber: ['', [Validators.required, Validators.pattern(MOBILE_REGEX)]],
      }
    );
  }

  handleRegisterInvite(isValid: boolean){
    if(isValid) {
      this.registerInvite.emit(this.form.value);
    }
  }

  handleGoBack(){
    this.routeToStartPage.emit();
  }

}
