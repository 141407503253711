import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CustomTargetService {
  private url = `${environment.endpoint}/CustomTargetsServlet`;

  constructor(private http: HttpClient) {}

  isNameUnique(name: string): Observable<boolean> {
    const params = new HttpParams().set('validate_target_option', `${name}`);

    return this.http
      .get<boolean>(this.url, { params })
      .pipe(map(response => response['data']['unique']));
  }
}
