import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-geo-overlap-dialog',
  templateUrl: './geo-overlap-dialog.component.html'
})
export class GeoOverlapDialogComponent implements OnInit {


  authorized = false;
  showFreeCampaignMessage = false;

  constructor(public dialogRef: MatDialogRef<GeoOverlapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  ngOnInit() {

  }


  override(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }


}
