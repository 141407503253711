import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-remove-creative-set-warning',
  templateUrl: './remove-creative-set-warning.component.html',
  styleUrls: ['./remove-creative-set-warning.component.css']
})
export class RemoveCreativeSetWarningComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RemoveCreativeSetWarningComponent>
  ) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close();
  }
}
