import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'newsType'
})
export class NewsTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
   switch (value.toLowerCase()) {
     case 'news': return 'Nyhet';
     case 'warning': return 'Varning';
     case 'offer': return 'Erbjudande';
     default: return value;
   }
  }
}
