import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeTextSuffix'
})
export class RemoveTextSuffix implements PipeTransform {
  transform(value: any, args: any): any {
    return value.replace(`_${args}`, '');
  }
}
