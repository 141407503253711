import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-invite-approve-dialog',
  templateUrl: './invite-approve-dialog.component.html',
  styleUrls: ['./invite-approve-dialog.component.css']
})
export class InviteApproveDialogComponent implements OnInit {

  showErrorMsg = false;
  constructor(public dialogRef: MatDialogRef<InviteApproveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) { }

  ngOnInit() {
  }

  submit(){
    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: 11
      };
    data.push(obj);
    this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.dialogRef.close(true);
        } else {
          this.showErrorMsg = true;
        }
      });
  }

  cancel(){
    this.dialogRef.close(false);
  }

}
