import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-invite-existing',
  templateUrl: './invite-existing.component.html',
  styleUrls: ['./invite-existing.component.css']
})
export class InviteExistingComponent implements OnInit {
 data;

 error = false;
 success = false;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {

      this.apiservice.GET(this.store.apiURL + "/UserInviteServlet?verification="+params['id'])
        .subscribe(res => {
          this.data = res;
          if(!this._cd["destroyed"]){
            this._cd.detectChanges();
          }


          let obj = {};

          this.apiservice.putJSON(this.store.apiURL + "/UserInviteServlet?existing=true&verification=" + params['id'], obj)
            .subscribe(res => {
              const _body = res;
              if(_body.responseCode === 200){
                this.success = true;
                this.error = false;
              }else{
                this.success = false;
                this.error = true;
              }

            });


        });
    });
  }
  routeToLoginPage(){
    this.router.navigate(["/login"]);
  }
}
