import {Component, Input, OnInit} from "@angular/core";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";
import {FormGroup} from "@angular/forms";
import {StoreService} from "../../../../../../../../services/store.service";
import {CampaignService} from "../../../../../../campaign.service";
import {CreativemoduleService} from "../../../creativemodule.service";


@Component({
  selector: 'app-cpc-ab-preview',
  templateUrl: 'cpc-ab-preview.component.html'
})

export class CpcAbPreviewComponent implements OnInit {

  @Input() data: any;
  @Input() subObjective: any;
  @Input() operatingSystem: any;
  @Input() dynamic_ad: boolean;

  showMobile: boolean = false;
  showDesktop: boolean = false;

  constructor(private globalfunctionality: GlobalfunctionalityService,
              private _creativeService: CreativemoduleService,
              private store: StoreService,
              public _campaignService: CampaignService,) {
  }

  ngOnInit(): void {
    const obj = this._creativeService.decidePreviewDevice();
    this.showMobile = obj.showMobile || this.dynamic_ad;
    this.showDesktop = obj.showDesktop || this.dynamic_ad;
  }
}
