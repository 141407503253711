import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";

const ATOZ_REGEX = /(^[a-zA-ZéåäöÅÄÖÉ_ -]+)[^-]$/;
const ORG_NUM_REGEX = /^\d{10}$/;
const ZIP_REGEX = /(^\d{5})$/;
@Component({
  selector: 'app-register-form',
  templateUrl: 'register-form.component.html'
})

export class RegisterFormComponent{

  @Input() translation: any;
  @Input() showComp: boolean;
  @Input() errorMsg: string;

  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() openPrivacyDialog: EventEmitter<any> = new EventEmitter();
  @Output() openTermsOfUseDialog: EventEmitter<any> = new EventEmitter();


  form;

  constructor(
    private _fb: FormBuilder
  ){
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      firstName: ['', [ Validators.required, Validators.pattern(ATOZ_REGEX), Validators.minLength(1), Validators.maxLength(50)]],
      lastName: ['', [ Validators.required, Validators.pattern(ATOZ_REGEX), Validators.minLength(1), Validators.maxLength(50)]],
      mobileNumber: ['', [ Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      orgName: ['', [ Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      orgNum: ['', [ Validators.required, Validators.pattern(ORG_NUM_REGEX)]],
      billingAddress: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      billingZip: ['', [Validators.required, Validators.pattern(ZIP_REGEX)]],
      billingCity: ['', [ Validators.required, Validators.pattern(ATOZ_REGEX), Validators.minLength(2), Validators.maxLength(30)]],

    });
  }

  handleRegister(isValid: boolean){
    if(isValid) {
      this.register.emit(this.form.value);
    }else{
      this.form.markAsTouched();
    }
  }

  handlePrivacyDialog(){
    this.openPrivacyDialog.emit();
  }

  handleTermsOfUseDialog(){
    this.openTermsOfUseDialog.emit();
  }

}
