import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { StoreService } from '../../../../../services/store.service';
import { ApiService } from '../../../../../services/api.service';
import { GlobalfunctionalityService } from '../../../../../services/globalfunctionality.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
/*import { AgmMap, AgmDataLayer } from '@agm/core';*/
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';

import {County, Locality, District, ItemFlatNode } from '../../../../../services/geo-data-tree.service';
import { GeoDataTreeComponent } from './geo-data-tree/geo-data-tree.component';
const styleConfig = [
  {
    "elementType": "labels.text",
    "stylers": [
      {
        "color": "#30568c"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#30568c"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#f8f6f5"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#eae5e0"
      },
      {
        "visibility": "on"
      },
      {
        "weight": 1.5
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#c5dbf4"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

@Component({
  selector: 'app-geomodule',
  templateUrl: './geomodule.component.html',
  styleUrls: ['./geomodule.component.css']
})
export class GeomoduleComponent implements OnInit, OnDestroy {
  /*@ViewChild(AgmMap) agmMap: AgmMap;*/
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('dataTreeComp') dataTreeComp: GeoDataTreeComponent;
  lat = 62.8788648;
  lng = 12.3281875;

  disableDefaultUI = true;
  scrollwheel = false;
  mapDraggable = false;
  zoomControl = false;
  streetViewControl = false;
  zoom = 4.2;
  styles = styleConfig;
  selectedOption = '0';
  geoData = [];
  filteredItems = [];
  location;
  searchLocation;
  loadGeo: boolean = true;
  moduleParams;
  loaded = false;
  showMap = true;

  _selectedGeo = new BehaviorSubject<any>([]);

  openOnce = true;

  counties: County[];
  localities: Locality[];
  districts: District[];

  locationsToShow = [];

  check = true;
  constructor(
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    public dialog: MatDialog,
  ) {}

  mapLoaded(event?) {
    this.loaded = true;
  }

  set selectedGeo(selected) {
    this._selectedGeo.next(selected);
  }
  get selectedGeo() {
    return this._selectedGeo.getValue();
  }

  get layers$() {
    return this._selectedGeo.pipe(
      map(selectedGeo => selectedGeo.map(geo => JSON.parse(geo.coordinates)))
    );
  }

  updateBounds() {
    const layers = this.selectedGeo.map(geo => JSON.parse(geo.coordinates));

    // workaround for a bug when unselected all locations and map does not zoom out properly
    if (!this.selectedGeo.length) {
      this.showMap = false;
      setTimeout(() => {
        this.showMap = true;
        this._cd.detectChanges();
      }, 0)
    }

    if (layers.length) {
      let coordinates = [];

      const findCoords = function(c) {
        if (c[0][0] instanceof Array) {
          c.forEach(cc => findCoords(cc))
        } else {
          coordinates = coordinates.concat(c);
        }
      }

      layers.forEach(layer => {
        layer.geometry.coordinates.forEach(c => findCoords(c));
      });

      const lats = coordinates.map(coord => coord[1]);
      const lngs = coordinates.map(coord => coord[0]);

      /*this.agmMap.fitBounds = {
        north: Math.max(...lats),
        east: Math.max(...lngs),
        south: Math.min(...lats),
        west: Math.min(...lngs)
      };*/
      /*this.agmMap.triggerResize();*/
      this.styleFunc();
    }
  }

  styleFunc() {
    return ({
      stroke: '3px',
      strokeColor: '#30588c',
      fillColor: '#30588c',
      fillOpacity: '0.5'
    });
  }

  ngOnDestroy() {
    this.check = false;
    this.store.overrideTopNavZIndex = false;
  }

  ngOnInit() {
    this.store.overrideTopNavZIndex = true;
    this.check = true;
    this.store.forecast.geo_locations = [];

    this.globalfunctionality.geoReset.subscribe((reset: boolean) => {
      if (reset) {
        this.selectedGeo = [];
        this.globalfunctionality.getModuleValues('geo')['selectedGeo'] = [];
        this.dataTreeComp.checklistSelection.clear();
        this.store.forecast.geo_locations = [];
        this.selectedOption = '0';
        this.globalfunctionality.checkForAudienceReach();
        this.checkIfComplete();
      }
    });

    this.moduleParams = this.globalfunctionality.getModuleParams('geo');

    this.apiservice
      .getJSON(this.store.apiURL + '/GeoTargetingServlet')
      .subscribe(res => {
        if (res.responseCode === 200) {
          if (res.data) {
            this.counties = res.data['geo_counties'];
            this.localities = res.data['geo_localities'];
            this.districts = res.data['geo_districts'];
          }
          this.orderLocations();
          this.checkIfComplete();
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }

  changeSelection() {
    if (this.selectedOption === '0') {
      this.selectedGeo = [];
      this.dataTreeComp.checklistSelection.clear();
    }

    this.checkIfComplete();
  }

  orderLocations() {
    this.geoData.forEach(elm => {
        elm['filter_value'] = elm.name.trim();
    });

    this.geoData.sort(function(a, b) {
      if (a['filter_value'] < b['filter_value']) {
        return -1;
      } else if (a['filter_value'] > b['filter_value']) {
        return 1;
      } else {
        return 0;
      }
    });

    this.assignCopy();
    this.loadGeo = false;

    this.detectChange();

    const values = this.globalfunctionality.getModuleValues('geo');
    if (values) {
      if (values['selectedGeo'] !== undefined) {
        this.selectedGeo = values['selectedGeo'];
        if (this.selectedGeo.length > 0) {
          this.selectedOption = '1';

          this.selectedGeo.forEach(g => {
            let node = this.dataTreeComp.dataNodes.find(node => node.name === g.name);
            if (node) {
              this.dataTreeComp.itemSelectionToggle(node, false);
            }
          });
          this.updateBounds();
          this.detectChange();
        }

        // If the map already has been initialized and user navigates between different pages, add the geo json and fittobounds when user navigates to geo module
        this.checkIfComplete();
      } else {
        this.selectedGeo = [];
        this.dataTreeComp.checklistSelection.clear();
      }
    }
  }

  assignCopy() {
    this.filteredItems = Object.assign([], this.geoData);
  }

  selectionChange(locations: ItemFlatNode[]) {

    // Take children of Lan (County) and Kommun (Municipality) only if not all of them are selected
    const filteredChilds = locations.reduce((acc, curr, i, arr) => {
      if (curr.parentName) {
        const parent = arr.find(n => n.name === curr.parentName);
        if (parent) {
          const childs = arr.reduce((acc, c) => {
            acc = c.parentName === curr.parentName ? acc += 1 : acc;
            return acc;
          }, 0)
          if (parent.childrenCount === childs) {
            return acc;
          } else {
            acc = acc.concat(curr);
          }
        } else {
          acc = acc.concat(curr);
        }
      } else {
        acc = acc.concat(curr);
      }
      return acc;
    }, [])

    // Used to store the order of requests, so when they come back we know which locations ordered to be shown by the last request
    this.locationsToShow = filteredChilds.map(p => p.name)

    this.store.showReachWarning = false;

    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }

    let maximumEntries = 50;

    // Pick the maximum amount of entries from the module params value (backend)
    if (this.moduleParams !== undefined) {
      if (this.moduleParams['maxEntries'] !== undefined) {
        maximumEntries = this.moduleParams['maxEntries'];
      }
    }


    forkJoin(filteredChilds.map(l => {
        // If already selected do not call GeoTargetingServlet
        const index = this.selectedGeo.findIndex(geo => geo.name === l.name);
        if (index !== -1) {
          return of(null);
        }
        return this.apiservice.getJSON(
          this.store.apiURL +
          '/GeoTargetingServlet?values=' +
          JSON.stringify([l.value])
        ).pipe(
          map(d => {
            return { location: l, coords: d.data[0].coords };
          }),
          catchError(err => {
            console.error(`Error in GeoTargetingServlet for ${l.name}`);
            return of({ location: l, coords: null });
          })
        );
      })).pipe(
        tap((res: any) => {
          res.forEach(d => {
            if (d && d.coords) {
              // If already selected do not select again
              // Also check whether this result came from the last request
              const selectedIndex = this.selectedGeo.findIndex(geo => geo.name === d.location.name);
              const toSelectIndex = this.locationsToShow.findIndex(name => name === d.location.name);
              if (selectedIndex === -1 && toSelectIndex !== -1) {
                d.location['coordinates'] = d.coords;
                this.selectedGeo.push(d.location);
              }
            } else if (d) {
              const nullIndex = filteredChilds.findIndex(l => l.name === d.location.name);
              filteredChilds.splice(nullIndex, 1);
              console.error(`No coords for ${d.location.name}`);
            }
          });
        }),
        finalize(() => {
          if (!filteredChilds.length) {
            this.selectedGeo = [];
          } else {
            this.selectedGeo = this.selectedGeo.filter(g => {
              return !!filteredChilds.find(l => l.name === g.name);
            });
          }
          this.updateBounds();
          this.detectChange();
          this.checkIfComplete();
        })
      ).subscribe();

  }

  detectChange(){
    if(!this._cd['destroyed']){
      this._cd.detectChanges();
    }
  }

  deselectLocation(location: ItemFlatNode, index: number) {
    this.selectedGeo.splice(index, 1);
    this.dataTreeComp.itemSelectionToggle(location, false);

    this.updateBounds();
    this.checkIfComplete(true);
    this.detectChange();
  }

  checkIfComplete(checkTarget?) {
    const geoModule = this.globalfunctionality.getModuleValues('geo');

    if (geoModule) {
      if (this.selectedGeo.length > 0) {
        this.store.forecast.geo_locations = [];
        this.selectedGeo.forEach(elm => {
          this.store.forecast.geo_locations.push(elm.value);
        });
      } else {
        this.store.forecast.geo_locations = [];
      }

      if (this.store.GUIFlow[2]) {
        this.store.GUIFlow[2].forEach(function(elm) {
          if (elm.type === 'geo') {
            elm['module_values'].isComplete = true;
            geoModule.isComplete = true;
          }
        });
      }

      if (checkTarget) {
        this.globalfunctionality.checkForAudienceReach();
      }

      geoModule['selectedGeo'] = this.selectedGeo;
      sessionStorage.setItem('modules', JSON.stringify(this.store.modules));

      this.globalfunctionality.geoChange.next(true);
      this.globalfunctionality.page2Checker.next(true);
    }
  }
}
