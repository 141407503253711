import {Injectable} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {StoreService} from "../../services/store.service";

declare var moment: any;
@Injectable()
export class CampaignService{

  constructor(
    private store: StoreService){

  }

  page1Complete = false;
  page2Complete = false;
  page3Complete = false;
  page4Complete = true;

  structure: FormGroup = new FormGroup({
    id: new FormControl(),
    brand: new FormGroup({
      name: new FormControl('', {
        validators: Validators.required,
        updateOn: 'submit'
      }),
      id: new FormControl(),
      city: new FormControl()
    }),
    company: new FormControl(),
    name: new FormControl('', [Validators.required, this.restrictQuotes, Validators.maxLength(60)]),
    objective: new FormControl('', [Validators.required]),
    products: new FormControl([], [Validators.required]),
    order_lines: new FormControl([], [Validators.required]),
    selected_product: new FormControl('', [Validators.required]),
    config: new FormGroup({
      copy: new FormControl(),
      update_campaign:  new FormControl(false),
      update_creatives:  new FormControl(false),
      weatherLibId: new FormControl()
    }),
  });


  restrictQuotes(control: AbstractControl) {
    if (control.value !== null) {
      if (control.value.includes('"') || control.value.includes('/') || control.value.indexOf('\\') !== -1 || control.value.includes('*')) {
        return {illegalChar: true};
      }
    }
    return null;
  }


  calculateMinStartDate(auxDate): Date{

    let today = new Date();
    let day = moment(today).format("dddd").toLowerCase();

    if(day === 'wednesday' || day === 'thursday' || day === 'friday'){
      return new Date(auxDate.setDate(auxDate.getDate() + 2));
    }else if(day === 'saturday' || day === 'sunday'){
      return new Date(auxDate.setDate(auxDate.getDate() + 1));
    }else{
      return auxDate;
    }
  }

  setSoonAsPossibleButton(startdate, materialAdded: boolean){
    let calendar: any = document.getElementsByTagName('mat-datepicker-content');
    let soonAsBtn: any = document.createElement('div');
    let soonAsText: any = document.createElement('div');
    soonAsBtn.innerText = "Så snart som möjligt";
    soonAsBtn.setAttribute("id", "soon_as_button");
    soonAsBtn.classList.add("mt-datepicker-btn-asap");
    soonAsText.classList.add("mt-datepicker-info");

    if (materialAdded) {
      soonAsText.innerText = 'Max 3 arbetsdagar, men vi ska göra allt vi kan för att din kampanj ska starta så fort som möjligt.';
    } else {
      soonAsBtn.classList.add("inactive-item");
      soonAsText.innerText = 'För att kunna starta kampanjen så snart som möjligt behöver du först ladda upp ditt annonsmaterial.';
    }
    if (calendar[0]) {
      calendar[0].append(soonAsBtn);
      calendar[0].append(soonAsText);
    }

    return {
      addBtn: materialAdded,
      btnElm: soonAsBtn
    };
  }


  buildFlow() {

    if (sessionStorage.getItem("modules")){
      if (sessionStorage.getItem("modules") === null) {
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.store.GUIFlow = {};
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });
    } else {
      this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
      this.store.GUIFlow = {};
      // GET FLOW DEPENDING ON OBJECTIVE, USE FLOW TO POPULATE WORKFLOW
      this.store.modules.forEach((elm) => {
        if (this.store.GUIFlow[elm.page] === undefined) {
          this.store.GUIFlow[elm.page] = [];
          this.store.GUIFlow[elm.page].push(elm);
        } else {
          this.store.GUIFlow[elm.page].push(elm);
        }
      });
    }
    }
  }

}
