import {Component} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: 'privacy-dialog.component.html',
  styleUrls: ['privacy-dialog.component.css']
})

export class PrivacyDialogComponent {

  constructor( public dialogRef: MatDialogRef<PrivacyDialogComponent>){

  }

  close(){
    this.dialogRef.close();
  }

}

