/**
 * Created by adssets on 2017-09-19.
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignComponent } from './campaign/campaign.component';
import { CreatecampaignComponent } from './campaign/createcampaign/createcampaign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from '../dashboard/dashboard.component';
import {SafePipe} from './campaign/modules/creative_components/creativemodule/creativemodule.component';
import { MyDateAdapter } from '../services/dateadapter';
import { TaskComponent } from './task/task.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManagementComponent } from './management/management.component';
import { PipeModule } from './campaign/pipes/pipe.module';
import { UserChangeDialogComponent } from './management/user/user-change-dialog/user-change-dialog.component';
import { AdminInfoDialogComponent } from './dialog/admin-info-dialog/admin-info-dialog.component';
import { RedirectDialogComponent } from './campaign/modules/shared_components/objectivemodule/redirect-dialog/redirect-dialog.component';
import { RemovecampaignDialogComponent } from './campaign/dialogs/removecampaign-dialog/removecampaign-dialog.component';
import { FreecampaignDialogComponent } from './campaign/dialogs/freecampaign-dialog/freecampaign-dialog.component';
import { EditCampaignComponent } from './campaign/dialogs/edit-campaign/edit-campaign.component';
import { CampaignLimitationDialogComponent } from './dialog/campaign-limitation-dialog/campaign-limitation-dialog.component';
import { TaskModule } from './task/task.module';
import { FilterPipe } from '../services/pipes/filter.pipe';
import {MaterialModule} from "../utils/MaterialModule";
import { GeoDataTreeService } from '../services/geo-data-tree.service';
import {FormatDetailedViewComponent} from "./components/format-detailed-view/format-detailed-view.component";
import {DaysFromCreationViewComponent} from "./components/days-from-creation-view/days-from-creation-view.component";
import {CampaignModule} from "./campaign/campaign.module";
import {CopyCampaignComponent} from "./campaign/dialogs/copy-campaign/copy-campaign.component";
import {ManagementModule} from "./management/management.module";
import {AuthGuard} from "../auth/auth.guard";
import {AuthModule} from "../auth/auth.module";
import {TableHeaderComponent} from "./components/table-header.component/table-header.component";
import {DataManagementModule} from "./data-management/data-management.module";
import {DataManagementComponent} from "./data-management/data-management.component";
import {PaginationModule} from "../utils/pagination/pagination.module";
import {NewsComponent} from "./news/news.component";
import {NewsPreviewDialogComponent} from "./news/management/news/create-news-dialog/news-preview.dialog/news-preview-dialog.component";

const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'campaigns',
        component: CampaignComponent
      },
      {
        path: 'news',
        component: NewsComponent
      },
      {
        path: 'campaigns/:refresh',
        component: CampaignComponent
      },
      { path: 'create/:page', canActivate: [AuthGuard], component: CreatecampaignComponent},

      {
        path: 'task',
        component: TaskComponent,
        loadChildren: () => import('./task/task.module').then(m => m.TaskModule)
      },
      {
        path: 'management',
        component: ManagementComponent,
        loadChildren: () => import('./management/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'data-management',
        component: DataManagementComponent,
        loadChildren: () => import('./data-management/data-management.module').then(m => m.DataManagementModule)
      }
    ]
  }
];
/*{ path: 'create/:page',canActivate: [AuthGuard], component: CreatecampaignComponent},
canLoad: [AuthGuard],
  canLoad: [AuthGuard],*/

@NgModule({
  declarations: [
    AdminInfoDialogComponent,
    TaskComponent,
    FilterPipe,
    FormatDetailedViewComponent,
    DaysFromCreationViewComponent,
    EditCampaignComponent,
    CopyCampaignComponent,
    CampaignComponent,
    FreecampaignDialogComponent,
    CreatecampaignComponent,
    UserChangeDialogComponent,
    CampaignLimitationDialogComponent,
    TableHeaderComponent,
    RemovecampaignDialogComponent,
    NewsComponent,
    NewsPreviewDialogComponent
  ],

  imports: [
    BrowserModule,
    RouterModule.forChild(dashboardRoutes),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    PipeModule,
    MaterialModule,
    CampaignModule,
    TaskModule,
    ManagementModule,
    DataManagementModule,
    AuthModule,
    PaginationModule
  ],
  exports: [RouterModule, FilterPipe, FormatDetailedViewComponent, DaysFromCreationViewComponent, TableHeaderComponent],
  entryComponents: [
    UserChangeDialogComponent,
    AdminInfoDialogComponent,
    RedirectDialogComponent,
    RemovecampaignDialogComponent,
    FreecampaignDialogComponent,
    EditCampaignComponent,
    CopyCampaignComponent,
    CampaignLimitationDialogComponent,
    NewsPreviewDialogComponent
  ],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }, SafePipe, GeoDataTreeService]
})
export class DashboardModule {}
