import {ChangeDetectorRef, Injectable} from '@angular/core';
import {StoreService} from "./store.service";
import {Router} from "@angular/router";
import {ApiService} from "./api.service";
import {BehaviorSubject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {AdblockDialogComponent} from "../dashboard/dialog/adblock-dialog/adblock-dialog.component";
import {ResponseBodyModel} from "../models/response-body.model";
import {CampaignService} from "../dashboard/campaign/campaign.service";

declare var moment: any;

@Injectable()
export class GlobalfunctionalityService {

  detectChange = new BehaviorSubject(null);
  initComplete = new BehaviorSubject(null);
  initFlowComplete = new BehaviorSubject(null);
  page1Checker = new BehaviorSubject(null);
  page2Checker = new BehaviorSubject(null);
  page3Checker = new BehaviorSubject(null);
  page4Checker = new BehaviorSubject(null);
  localeChange = new BehaviorSubject(null);
  creativesChecker = new BehaviorSubject(null);

  // Open "help" popup and preselect "övriga frågor"
  openHelpPopup = new BehaviorSubject(null);


  // Campaigname module subscribes for setting a default campaign name
  objectiveChange = new BehaviorSubject(null);

  // Target-indicator module subscribes for displaying selected values
  targetDataIntialized = new BehaviorSubject(null);

  // Trigger event when geo, interest, demo has changed so the target indicator gets updated
  geoChange = new BehaviorSubject(null);
  interestChange = new BehaviorSubject(null);
  demographyChange = new BehaviorSubject(null);

  // Trigger event when trying to clear target indicator component values
  geoReset = new BehaviorSubject(null);
  interestReset = new BehaviorSubject(null);
  demographyReset = new BehaviorSubject(null);


  // Trigger event when user changes ad type option, used in the context component
  adtypeChange = new BehaviorSubject(null);


  // Trigger event when ad has been created so list of uploaded material gets refreshed
  adCreated = new BehaviorSubject(null);

  // Trigger event when user select the "copy" ad archive choice, the index of the selection option shall then be changed
  adcreationIndexChange = new BehaviorSubject(null);

  deviceChange = new BehaviorSubject(null);

  deviceOptionChange = new BehaviorSubject(null);

  resetAdforsize = new BehaviorSubject(null);

  siteChange = new BehaviorSubject(null);

  brandChange = new BehaviorSubject(null);

  templateChange = new BehaviorSubject(null);

  formatChange = new BehaviorSubject(null);

  brandSelected = new BehaviorSubject(null);

  resetAndHideDemography = new BehaviorSubject(null);

  showDemography = new BehaviorSubject(null);

  brandInit = new BehaviorSubject(null);

  // Trigger event so videoad gets disabled
  disableVideoAdType = new BehaviorSubject(null);

  clearColumnFilterSelection = new BehaviorSubject(null);

  // Trigger event for updating ACM ad
  updateACMPreview = new BehaviorSubject(null);

  updateACMPreviewOnly = new BehaviorSubject(null);

  Math: any;

  constructor(public store: StoreService,
              private router: Router,
              private apiservice: ApiService,
              private dialog: MatDialog,
              private campaignService: CampaignService) {
    this.Math = Math;
  }


  createCampaign() {

    // Reset prompmessage variable prompt should default be false
    this.store.prompChangeMessage = false;

    // Change menu
    this.store.modules = undefined;
    this.store.appLevel = 1;

    // Clear previous values
    sessionStorage.removeItem("modules");
    sessionStorage.removeItem("campaign");


    this.campaignService.structure.get('objective').reset();

    this.router.navigate(['dashboard/create', "campaign"]);

  }


  validateToken() {
    if (this.store.user !== undefined) {
      this.apiservice.getJSON(this.store.apiURL + "/UpdateSessionServlet")
        .subscribe(res => {
          if (res.responseCode !== 200) {
            this.logoutUser();
          }
        });
    }
  }

  resetUserRoles() {
    this.store.isAdmin.value = false;
    /*this.store.isCompanyAdministrator.value = false;*/
    this.store.isAdminCampaignManager.value = false;
    this.store.isAdminCompanyManager.value = false;
    this.store.isUser.value = false;

  }


  checkUser(callback) {

    if (this.store.user === undefined) {
      if (sessionStorage.getItem("userObj") !== undefined) {
        this.store.user = JSON.parse(sessionStorage.getItem(("userObj")));
      }
    }
    if (this.store.user === null) {
      this.store.user = undefined;
    }

    if (this.store.user === undefined) {
      this.router.navigate(["/login"]);
    } else {
      const _t = this;
      this.resetUserRoles();

      this.store.user.user_roles.forEach(function (elm) {
        if (elm.toLowerCase() === 'SCHIBSTED_ADMIN'.toLowerCase()) {
          _t.store.isAdmin.value = true;
        } else if (elm.toLowerCase() === 'SCHIBSTED_CAMPAIGN_MANAGER'.toLowerCase()) {
          _t.store.isAdminCampaignManager.value = true;
        } else if (elm.toLowerCase() === 'SCHIBSTED_COMPANY_MANAGER'.toLowerCase()) {
          _t.store.isAdminCompanyManager.value = true;
        } else if (elm.toLowerCase() === 'SCHIBSTED_SALESREP'.toLowerCase()) {
          _t.store.isSalesRep.value = true;
        } else if (elm.toLowerCase() === 'SCHIBSTED_BOOKING'.toLowerCase()) {
          _t.store.isBooking.value = true;
        } else {
          _t.store.isUser.value = true;
        }
      });

      if (this.store.user.user_roles.length < 1) {
        this.store.isUser.value = true;
      }

      // Check if logged in user is a company admin
      // Check admin when no company has yet been selected, the "all brands" will otherwise set that the user is no admin

      this.store.user.companies_admin.forEach(elm => {
        // Checks if user is admin for company
        // If a company has been selected choose that company
        if (this.store.userCompany) {
          if (elm.id === this.store.userCompany.id) {
            this.store.isCompanyAdministrator.value = true;
            this.store.isUser.value = false;
          }
        } else { // Otherwise pick the first company that exists for the user and check if user is admin for that company
          this.store.user.companies.forEach(elm1 => {
            if (elm.id === elm1.id) {
              this.store.isCompanyAdministrator.value = true;
              this.store.isUser.value = false;
            }
          });
        }
      });

      // Check company type
      if (this.store.user.companies[0].company_type) {
        let type = this.store.user.companies[0].company_type.toLowerCase();
        if (type === "partner") {
          this.store.isTrustedPartner.value = true;
        } else if (type === "agency") {
          this.store.isMediaAgency.value = true;
        } else if (type === "company") {
          this.store.isAgency.value = true;
        }
      }


      if (!this.store.isTrustedPartner.value && !this.store.isMediaAgency.value) {

        this.store.selectedBrand = this.store.user.companies[0].default_brand.brands_id;

        let auxFreeCampaign = sessionStorage.getItem('free_campaign');
        if (auxFreeCampaign !== null) {
          if (auxFreeCampaign === "true") {
            if (this.router.url.includes('home')) {
              this.store.loadObjectiveComponenentOnce = true;
            }
            this.checkFreeCampaign();
          } else {
            if (this.router.url.includes('home')) {
              this.store.loadObjectiveComponenentOnce = true;
              this.checkFreeCampaign();
            } else {
              if (!this.router.url.includes('audience') && !this.router.url.includes('ads') && !this.router.url.includes('review')) {
                this.store.freeCampaign = false;
              }
            }
          }
        } else {
          this.checkFreeCampaign();
        }
      } else {
        // Media agency and trusted partner should not have possibility to create a free campaign.
        this.store.freeCampaign = false;
      }

      this.buildMenu();
      callback();
    }
  }


  checkFreeCampaign() {
    if (this.store.user.companies[0].free_campaign) {
      this.store.freeCampaign = true;
    } else {
      if (!this.router.url.includes('audience') && !this.router.url.includes('ads') && !this.router.url.includes('review')) {
        this.store.freeCampaign = false;
      }
    }
  }

  buildMenu() {
    /*  isAdmin = Schibsted admin,
        isAdminCampaignManager = Schibsted campaign manager,
        isAdminCompanyManager = Schibsted company manager
        isUser = "regular user"
        isCompanyAdministrator = "regular user's" company admin
    */

    this.store.managementMenu = [
      {
        "name": "Users",
        "icon": "assets/icons/flat-icons/single-user.svg",
        "function": "users",
        "key": "users",
        "show": this.store.isAdmin.value || this.store.isCompanyAdministrator.value || this.store.isAdminCompanyManager.value
      },
      {
        "name": "Brand",
        "icon": "assets/icons/flat-icons/tag.svg",
        "function": "pin_brand",
        "key": "pin_brand",
        "show": this.store.isTrustedPartner.value
      },
      {
        "name": "Companies",
        "icon": "assets/icons/flat-icons/building 4.svg",
        "function": "companies",
        "key": "companies",
        "show": this.store.isAdmin.value || this.store.isAdminCompanyManager.value
      },
      {
        name: 'News',
        icon: 'assets/icons/flat-icons/cubes.svg',
        function: 'news',
        key: 'news',
        show: this.store.isAdmin.value
      }
    ];


    this.store.mainMenu = [
      {
        "id": 1,
        "name": "Campaigns",
        "icon": "assets/icons/flat-icons/megaphone.svg",
        "function": "campaigns",
        "key": "campaigns",
        "show": true
      },
      {
        id: 5,
        name: 'Data Management',
        icon: 'assets/icons/flat-icons/megaphone.svg',
        function: 'data_management',
        key: 'data_management',
        show: true
      },
      {
        "id": 4,
        "name": "Management",
        "icon": "assets/icons/flat-icons/suitcase.svg",
        "function": "management",
        "key": "management",
        "show": this.store.isAdmin.value || this.store.isCompanyAdministrator.value || this.store.isTrustedPartner.value || this.store.isAdminCompanyManager.value
      },
      {
        "id": 3,
        "name": "Tasks",
        "icon": "assets/icons/flat-icons/checked.svg",
        "function": "tasks",
        "key": "task",
        "show": false
        /* "show": this.store.isAdmin.value || this.store.isAdminCampaignManager.value || this.store.isAdminCompanyManager.value*/
      }
    ];
  }

  getSharedCompaniesAndBrands(callback, getDefaultBrand?) {

    this.apiservice.getJSON(this.store.apiURL + "/CampaignServlet?option=brands")
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.store.companiesList = res.data;

          if (this.store.companiesList.length < 1) {
            this.initComplete.next(true);
          }

          let amountOfCampaigns = 0;
          let amountOfReportCampaigns = 0;
          this.store.companiesList.forEach((elm) => {
            elm['showBrands'] = false;
            elm['number_of_campaigns'] = 0;
            elm['number_of_report_campaigns'] = 0;
            elm.brands.forEach(brand => {
              amountOfCampaigns += brand.number_of_campaigns;
              amountOfReportCampaigns += brand.number_of_report_campaigns;
              elm['number_of_campaigns'] += brand.number_of_campaigns;
              elm['number_of_report_campaigns'] += brand.number_of_report_campaigns;

              if (brand['write']) {
                elm['showBrands'] = true;
              }
            });
            this.sortBrandPerCompany(elm);
          });


          this.store.companiesList.sort(function (a, b) {
            if (a.companies_name.toLowerCase() < b.companies_name.toLowerCase()) {
              return -1;
            }
            if (a.companies_name.toLowerCase() > b.companies_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          this.store.companiesList.sort(function (a, b) {
            if (a.number_of_campaigns > b.number_of_campaigns) {
              return -1;
            }
            if (a.number_of_campaigns < b.number_of_campaigns) {
              return 1;
            }
            return 0;
          });


          // Check if any brand or company is selected, if not pick a default one
          let userObj = JSON.parse(sessionStorage.getItem("userObj"));
          if (userObj !== null) {
            this.store.companiesList.unshift({
              companies_id: 0,
              companies_name: "All",
              companies_salesRep: "None",
              brands: [{
                "brands_city": "",
                "brands_discount": 0,
                "brands_id": 0,
                "brands_name": "Alla annonsörer",
                "brands_owner_companies_id": 0,
                "brands_owner_companies_name": "All",
                "read": true,
                "write": true,
                "number_of_campaigns": amountOfCampaigns,
                "number_of_report_campaigns": amountOfReportCampaigns
              }]
            });

            this.store.userCompany = this.store.user.companies[0];


          } else {
            this.logoutUser();
          }
        } else {
          this.logoutUser();
        }
        callback();
      });

  }


  sortBrandPerCompany(company) {
    company.brands.sort(function (a, b) {
      if (a.brands_name.toLowerCase() < b.brands_name.toLowerCase()) {
        return -1;
      }
      if (a.brands_name.toLowerCase() > b.brands_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });


    company.brands.sort(function (a, b) {
      if (a.number_of_campaigns > b.number_of_campaigns) {
        return -1;
      }
      if (a.number_of_campaigns < b.number_of_campaigns) {
        return 1;
      }
      return 0;
    });
  }


  clearVariables() {

    this.campaignService.structure.get('brand').reset();
    this.campaignService.structure.get('company').reset();

    this.store.freeCampaign = undefined;
    this.resetVariables();
    this.detectChange.next(true);
  }


  checkContextForecast(callback) {
    const context = this.getModuleValues('context');
    const addecision = this.getModuleValues('addecision');

    // If blocket searchword selected, add the searchword targeting to the forecast
    if (addecision.selected === '1') {
      const searchword = this.getModuleValues('searchword');
      this.store.contextForecast['content_categories'] = searchword.forecast.content_categories;
      this.store.contextForecast['content_geo'] = searchword.forecast.content_geo;
      this.store.contextForecast['content_text'] = searchword.forecast.content_text;
    }

    if (context) {
      if (context.contexts) {
        context.contexts.forEach(elm => {

          if (addecision.selected !== '1') {
            // If site is targeting booked, add selected content_categories
            if (elm.targeting_booking) {
              this.store.contextForecast['content_categories'] = elm.content_categories.reduce((prev, cur) => {
                prev.push(cur.id);
                return prev;
              }, []);
            } else {
              delete this.store.contextForecast['content_categories'];
            }
          }

          elm.sub_contexts.forEach(subcontext => {
            if (subcontext.selected) {
              subcontext.products.forEach(prod => {

                if (prod.selected) {
                  this.store.contextForecast['products'] = [prod.id_products];
                  this.apiservice.postJSON(this.store.apiURL + "/ForecastServlet", this.store.contextForecast)
                    .subscribe(res => {
                      const _body = res;
                      if (res.responseCode !== 400) {
                        const data = _body.data.data;
                        let available = 0;
                        let capacity = 0;
                        if (data !== undefined && data !== null) {
                          data.forEach((elm) => {
                            available += elm[2];
                            capacity += elm[1];
                          });
                          prod.available = available;
                          prod.capacity = capacity;
                          prod.maxBudget = Math.round((available * prod.pricing) / 1000);
                          prod['forecastComplete'] = true;
                          sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
                          callback(context);
                        }
                      }
                    });
                }
              });
            }
          });
        });
      }
    }
  }

  checkForAudienceReach(hide?) {
    // Check if anything has been selected.
    let aux = this.store.forecast;
    if (!hide) {
      this.store.showReachWarning = (aux['ages'].filter(elm => elm !== 'All').length < 1 && aux['genders'].filter(elm => elm !== 'All').length < 1 && aux['interests'].length < 1 && aux['geo_locations'].length < 1);
    } else {
      this.store.showReachWarning = false;
    }

    if (this.store.showReachWarning) {
      this.store.reachPosition = null;
    }
  }


  getProductNameBySize(format) {
    switch (format) {
      case "1x1":
        return 'Content display';
      case "320x320":
        return 'Panorama';
      case "320x400":
        return 'Panorama XL';
      case "640x320":
        return 'Modul';
      case "980x240":
        return 'Panorama';
      case "980x120":
        return 'Panorama';
      case "250x600":
        return 'Widescreen';
      case "300x480":
        return 'Insider';
      case "1920x1080":
        return 'Takeover';
      case "1600x900":
        return 'Wallpaper';
      case "640x1000":
        return 'Takeover';
      case "1135x530":
        return 'Takeover';
      case "320x160":
        if (this.campaignService.structure.get('objective').value.id === 6) {
          return 'Content display';
        } else {
          return 'Panorama';
        }
      case "320x100":
        if (this.campaignService.structure.get('objective').value.id === 6) { // WORKAROUND FOR BLOCKET SEARCH
          const feed_option: boolean = this.getModuleValues('subobjective').selectedSubobjective.id === 3;
          return feed_option ? 'Responsiv feedannons' : 'Content display';
        } else if (this.store.productType === 'FreeTextAnnons') {
          return 'Content display';
        }
    }
  }

  checkCreativeMinRequirement(creatives): boolean {
    let minRequirement: boolean = true;

    creatives.filter(elm => elm.active).forEach(elm => {
      if (elm.complete || elm.uploaded_outside) {
        if (!elm.uploaded_outside) {
          if (elm.creative_set.filter(set => set.visible && set.complete).length === 0) {
            minRequirement = false;
          }
        }
      } else {
        minRequirement = false;
      }
    });
    return minRequirement;
  }


  /*  CHECK WHICH OBJECTIVES THAT ARE USING THIS FUNCTION*/
  checkForecast(callback, productPrice) {
    if (this.campaignService.structure.get('objective').value.id === 2 || this.campaignService.structure.get('objective').value.id === 7) {

      if (this.campaignService.structure.get('objective').value.id === 2) {
        const geoV = this.getModuleValues('geo');
        const demoV = this.getModuleValues('demographic');
        const interestV = this.getModuleValues('interestanddemographic');


        if (geoV.selectedGeo) {
          this.store.forecast.geo_locations = [];
          geoV.selectedGeo.forEach(elm => {
            this.store.forecast.geo_locations.push(elm.value);
          });
        }

        if (demoV.selectedGender !== 'All') {
          this.store.forecast.genders = [];
          this.store.forecast.genders.push(demoV.selectedGender);
        } else {
          this.store.forecast.genders = [];
        }

        if (demoV.selectedAge !== 'All') {
          this.store.forecast.ages = [];
          let auxArray = Array.from(demoV.selectedAge, elm => elm['value']);
          auxArray.forEach((elm, index) => {
            if (elm === 'All') {
              auxArray.splice(index, 1);
            }
          });
          this.store.forecast.ages = auxArray;
        }

        if (interestV.selectedInterest) {
          this.store.forecast.interests = [];
          this.store.forecast.interests.push(interestV.selectedInterest);
        } else if (interestV.selectedCustomTarget) {
          this.store.forecast.custom_targets = [];
          this.store.forecast.custom_targets.push(interestV.selectedCustomTarget);
        }
      }
    }

    if (this.store.forecast.date_to !== ""
      && this.store.forecast.date_from !== ""
      && parseInt(this.store.forecast.impressions)
    ) {

      this.apiservice.postJSON(this.store.apiURL + "/ForecastServlet", this.store.forecast)
        .subscribe(res => {
          if (res.responseCode !== 400) {
            const data = res.data.data;
            let available = 0;
            let capacity = 0;
            let forecastApproved: boolean = false;

            if (data !== undefined && data !== null) {
              data.forEach((elm) => {
                available += elm[2];
                capacity += elm[1];
              });

              available = Math.max((available), 0);
              forecastApproved = Math.floor(available) >= parseInt(this.store.forecast.impressions);
            }


            // Calculate inventory for displaying it in the "progress bar" in budget component
            const availablePercentage = Math.max((available) / capacity, 0);
            const unavailablePercentage = 1 - availablePercentage;
            const bookedPercentage = parseInt(this.store.forecast.impressions) / capacity;


            const obj =
              {
                max_budget: (this.Math.max((available), 0) * productPrice) / 1000,
                forecast_approved: forecastApproved,
                percentage: {
                  unavailable: isNaN(Math.round(unavailablePercentage * 100)) ? 0 : Math.round(unavailablePercentage * 100),
                  available: isNaN(Math.max(Math.round(availablePercentage * 100 - bookedPercentage * 100), 0)) ? 0 : Math.max(Math.round(availablePercentage * 100 - bookedPercentage * 100), 0),
                  booked: isNaN(Math.round(Math.min(bookedPercentage * 100, 100 - (unavailablePercentage * 100)))) ? 0 : Math.round(Math.min(bookedPercentage * 100, 100 - (unavailablePercentage * 100)))
                },
                impressions: {
                  available: Math.round(Math.max(available, 0)),
                  unavailable: capacity - available,
                  capacity: capacity,
                  selected: parseInt(this.store.forecast.impressions)
                }
              };

            this.detectChange.next(true);
            callback(obj);
          }
        });
    }
  }

  highlightMenuItem(key) {

    this.store.mainMenu.forEach((elm, index) => {
      if (elm.key === key) {
        this.store.selectedMenuItem = index;
      }
    });
  }

  getLanguages(callback) {
    this.apiservice.getJSON(this.store.apiURL + "/LocaleListServlet")
      .subscribe(res => {
        if (res["responseCode"] === 200) {
          const languages = res["data"];
          this.store.allLanguages = languages;
        }
        callback();
      });
  }

  getLanguageList(callback) {
    this.store.languageList = [];
    const _localThis = this;
    this.store.allLanguages.forEach(function (elm) {
      _localThis.store.languageList.push(elm["selection"]);
    });
    callback();
  }

  getLanguageJson(language, callback) {
    const obj =
      {
        id: language
      };
    this.apiservice.postJSON(this.store.apiURL + "/LocaleListServlet", obj)
      .subscribe(res => {
        const body = res;
        this.store.langJSON = JSON.parse(body["data"]);
        this.detectChange.next(true);
        this.localeChange.next(true);
        callback();
      });
  }

  changeMenuLevel(level) {
    this.store.appLevel = level;
    this.detectChange.next(true);
  }

  changeSelectedMenuItem(item) {
    this.store.selectedMenuItem = item;
    this.detectChange.next(true);
  }


  /**
   *
   * @param module (key/type)
   */
  getModule(type) {
    let module;
    /* this.store.modules.forEach((elm) => {*/
    if (this.store.modules !== null) {
      this.store.modules.forEach((elm) => {
        if (elm.type === type) {
          module = elm;
        }
      });
    }

    return module;
  }

  // This function overrides the whole module values, do not use this when modifying part of the module values, only for replacing the whole object
  setModuleValue(type, value) {
    // TODO:  ADD sessionStorage.setItem("modules", JSON.stringify(this.store.modules)); AND REMOVE IT FROM EVERYWHERE ELSE

    if (this.store.modules !== null && this.store.modules !== undefined) {
      this.store.modules.forEach((elm) => {
        if (elm.type === type) {
          elm.module_values = value;
        }
      });
    }

  }

  getModuleValues(type) {
    let value;
    if (this.store.modules !== null && this.store.modules !== undefined) {
      this.store.modules.forEach((elm) => {
        if (elm.type === type) {
          value = elm.module_values;
        }
      });
      return value;
    }

  }

  getModuleParams(type) {
    let value;
    if (this.store.modules !== null && this.store.modules !== undefined) {
      this.store.modules.forEach((elm) => {
        if (elm.type === type) {
          value = elm.param;
        }
      });
    }

    return value;
  }

  isComplete(type) {
    let values = this.getModuleValues(type);
    if (values.isComplete) {
      return true;
    } else {
      return false;
    }
  }


  startAppLoad() {
    this.store.showApplication = false;
  }

  stopAppLoad() {
    this.store.showApplication = true;
  }


  startLoad() {
    this.store.showLoadingIcon = true;
    this.store.showComponent = false;
    this.detectChange.next(true);
  }

  stopLoad() {
    this.store.showLoadingIcon = false;
    this.store.showComponent = true;
    this.detectChange.next(true);
  }


  /**
   * Build review data obj from module values
   */

  buildReviewDataObject(elm, selectedObjective, reviewData, adScript, showDemoLink, material, productType): any {

    if (elm.type === "searchword") {
      if (parseInt(this.getModuleValues('addecision').selected)) { // If blocket searchword
        reviewData["searchword"] = elm.module_values;

        // Build category tree structure
        let selectedContentCategories = [];

        let selectedContentCategory = reviewData["searchword"].category;
        if (selectedContentCategory) {
          selectedContentCategory.selectedCategories.forEach(cat => {
            cat['selected_subcategories'] = [];
            selectedContentCategories.push(cat);


          });

          selectedContentCategories.forEach(cat => {
            selectedContentCategory.selectedSubCategories.forEach(sub => {
              if (cat.target_option === sub.categoryName) {
                sub['selected_productcategories'] = [];
                cat['selected_subcategories'].push(sub);
              }
            });
            if (cat['selected_subcategories'].length !== cat.children.length) {
              cat['showSub'] = true;
              cat['children'] = cat['selected_subcategories'];
            }
          });

          selectedContentCategories.forEach(cat => {
            cat['selected_subcategories'].forEach(sub => {
              selectedContentCategory.selectedProductCategories.forEach(prod => {
                if (sub.target_option === prod.subCategoryName) {
                  sub['selected_productcategories'].push(prod);
                }
              });

              if (sub.children) {
                if (sub['selected_productcategories'].length !== sub.children.length) {
                  sub['children'] = sub['selected_productcategories'];
                  cat['children'] = cat['selected_subcategories'];
                  sub['showProduct'] = true;
                  cat['showProduct'] = true;
                  cat['showSub'] = true;
                }
              }
            });
          });

          selectedContentCategories.forEach(cat => {
            if (!cat.showSub) {
              delete cat['children'];
            } else {
              cat.children.forEach(sub => {
                if (!sub.showProduct) {
                  delete sub['children'];
                }
              });
            }
          });

          reviewData["searchword"]['category']['formatted'] = selectedContentCategories;
        }
      }
    }


    if (elm.type === 'geo') {
      reviewData['geo'] = elm.module_values.selectedGeo;
    }

    if (elm.type === 'budget') {
      if (!reviewData["budget"]) {
        reviewData["budget"] = {};
      }
      reviewData['budget']['value'] = elm.module_values.budget;
      reviewData['budget']['start'] = moment(elm.module_values.start).format('YYYY-MM-DD');
      reviewData['budget']['end'] = moment(elm.module_values.end).format('YYYY-MM-DD');
      reviewData['budget']['option'] = elm.module_values.option;
      reviewData["budget"]["freqDecisionOption"] = elm.module_values.freqDecisionOption;

      reviewData['budget']['amount_of_days'] = elm.module_values.days;

      if (elm.module_values.guaranteedDelivery !== undefined) {
        reviewData["budget"]["guaranteedDelivery"] = elm.module_values.guaranteedDelivery;
      }

      if (elm.module_values.impressions) {
        reviewData['budget']['impressions'] = elm.module_values.impressions;
      }

      if (elm.module_values.selectedFreqOption) {
        reviewData["budget"]["selectedFreqOption"] = elm.module_values.selectedFreqOption;
      }

      if (elm.module_values.freqAmount) {
        reviewData["budget"]["freqAmount"] = elm.module_values.freqAmount['index'];
      }

      if (elm.module_values.soon_as_possible) {
        if (moment(new Date(moment(elm.module_values.start).format('YYYY-MM-DD'))).isAfter(moment(new Date()))) {  /*.format('YYYY-MM-DD')*/
          reviewData['budget']['soon_as_possible'] = true;
        } else {
          reviewData['budget']['soon_as_possible'] = false;
        }
      }

      if (elm.module_values.billing_information) {
        reviewData['budget']['billing_information'] = elm.module_values.billing_information;
        this.store.noteText = elm.module_values.billing_information;
      }
      if (elm.module_values.trusted_takes_bill !== undefined) {
        reviewData['budget']['trusted_takes_bill'] = elm.module_values.trusted_takes_bill;
        this.store.trustedPartnerTakesBill = elm.module_values.trusted_takes_bill;
      }


      if (elm.module_values.clicks) {
        reviewData['budget']['clicks'] = elm.module_values.clicks;
      }
      if (elm.module_values.click_price) {
        reviewData['budget']['click_price'] = elm.module_values.click_price;
      }

      if (elm.module_values.showTimescheduling) {
        if (elm.module_values.selectedDays) {
          reviewData['budget']['days'] = elm.module_values.selectedDays.map(elm => elm.value);
        }

        if (elm.module_values.hoursSets) {
          reviewData['budget']['hours'] = elm.module_values.hoursSets.filter(elm => elm.complete).sort((a, b) => {
            if (a.startHour.i < b.startHour.i) {
              return -1; // places infront a is less
            }
            if (a.startHour.i > b.startHour.i) {
              return 1; // places behind a is greater
            }
            return 0;
          });
        }
      }

    }

    // GET EXISTING TAG VALUES
    if (elm.type === 'existingtag') {
      if (elm.module_values['isComplete']) {
        if (elm.module_values.script !== undefined) {
          adScript = elm.module_values['script'];
        }
      }
    }


    if (elm.type === 'ads') {
      if (elm.module_values['isComplete']) {
        const banner = elm.module_values['banners'][0];
        adScript = banner.adScript;
      }
    }

    // Depending on which creation method is used show demo link
    if (elm.type === 'adcreation') {
      if (elm.module_values.selectedMethod === 'use_ad_template') {
        showDemoLink = true;
      } else {
        showDemoLink = false;
      }
    }

    if (elm.type === 'interestanddemographic') {
      if (elm.module_values !== undefined) {
        if (elm.module_values.selectedCustomTargetName !== undefined || elm.module_values.selectedSubinterest !== undefined) {
          reviewData['interest'] = {};
          reviewData['interest'] = elm.module_values;
        }
      }
    }

    if (elm.type === 'demographic') {
      if (elm.module_values !== undefined) {
        reviewData['demographic'] = {};
        reviewData['demographic']['selectedGender'] = elm.module_values.selectedGender;
        reviewData['demographic']['selectedAge'] = elm.module_values.selectedAge;
      }
    }


    if (elm.type === 'adforsize') {

      if (elm['module_values'] !== undefined) {
        if (elm['module_values']['selected'] !== undefined) {
          material = elm['module_values']['selected'];
        }
      }
    }
    if (elm.type === 'adtype') {
      if (elm['module_values'] !== undefined) {
        productType = Number(elm['module_values']['selectedOption']);
      }
    }

    if (elm.type === 'device') {
      if (elm.module_values.sites) {
        reviewData['sites'] = {};
        reviewData['sites'] = elm.module_values.sites.filter(elm => elm.selected);
      }

      if (elm.module_values.selectedTargetOption) {
        reviewData['device'] = {};
        switch (elm.module_values.selectedTargetOption.toLowerCase()) {
          case 'desktops & laptops':
            reviewData['device'] = 'Desktop';
            break;
          case 'mobiles':
            reviewData['device'] = 'Mobil';
            break;
          case 'tablets':
            reviewData['device'] = 'Desktop';
            break;
          default:
            reviewData['device'] = 'Mobil & Desktop';
            break;
        }

        if (elm.module_values.selectedOSIndex !== undefined) {
          reviewData['os'] = elm.module_values.selectedOSIndex === 0 ? 'Android & iOS' : elm.module_values.selectedOSTargetOption;
        }

      }
    }

    if (elm.type === 'context') {

      if (selectedObjective === 1) {


        // Filter out selected categories
        const targetingBooked = elm.module_values.contexts[0].targeting_booking;
        let selectedCategories;
        if (targetingBooked) {
          selectedCategories = elm.module_values.contexts[0].all_category_targets.filter(elm => elm.selected || elm.indeterminate);
          selectedCategories.forEach(cat => {
            if (cat.children) {
              cat.children = cat.children.filter(sub => sub.selected || sub.indeterminate);
              cat.children.forEach(sub => {
                if (sub.children) {
                  sub.children = sub.children.filter(prod => prod.selected || prod.indeterminate);
                }
              });
            }
          });
        }


        if (!reviewData["budget"]) {
          reviewData["budget"] = {};
        }
        reviewData["budget"]['impressions'] = 0;
        elm.module_values.contexts[0].sub_contexts.forEach(elm => {
          if (elm.total_impressions_for_subcontext) {
            reviewData["budget"]['impressions'] += elm.total_impressions_for_subcontext;
          }
        });

        let selectedProducts = [];
        elm.module_values.contexts.forEach(context => {
          context.sub_contexts.forEach(sub => {
            sub.products.forEach(product => {
              if (product.budget) {
                selectedProducts.push(product);
              }
            });
          });
        });
        reviewData['contexts'] = {};
        reviewData['contexts']['selected'] = selectedProducts;
        reviewData['contexts']['selected_categories'] = selectedCategories;
      }
    }

    return {
      reviewData: reviewData,
      adScript: adScript,
      showDemoLink: showDemoLink,
      material: material,
      productType: productType
    };
  }

  /**
   *
   * @param orderLines
   * @param option Can either be an option to decide wheter to update the campaign or a callback to retrieve data for a campaign
   */
  getCampaignValues(orderLines, option, updateCreatives, updateCampaign, param?) {

    this.apiservice.getJSON(this.store.apiURL + "/OrderLinesServlet?values=" + JSON.stringify(orderLines.map(elm => elm.id)))
      .subscribe(res => {
        /* this.campaignService.structure.get('order_lines').setValue(res);*/
        if (res.length > 0) {
          this.apiservice.getJSON(this.store.apiURL + "/ModulesValuesServlet?values=" + JSON.stringify(res[0]["module_values"]))
            .subscribe(res => {
              this.store.moduleValues = res;
              const moduleList = [];

              res.forEach((elm) => {
                moduleList.push(elm.module);
              });

              this.apiservice.getJSON(this.store.apiURL + "/ModulesServlet?values=" + JSON.stringify(moduleList))
                .subscribe(res => {
                  this.store.modules = res;
                  this.store.modules.forEach((module) => {
                    this.store.moduleValues.forEach((moduleValue) => {
                      if (moduleValue["module"] === module["id"]) {
                        module["module_values"] = JSON.parse(moduleValue["value"]);
                        module["module_values"]["id"] = moduleValue["id"];
                      }
                    });
                  });

                  this.store.modules.forEach((elm) => {
                    elm["param"] = JSON.parse(elm["param"]);
                  });

                  this.campaignService.structure.get('config').value.update_campaign = updateCampaign;
                  this.campaignService.structure.get('config').value.update_creatives = updateCreatives && !updateCampaign;

                  if (option === 'edit' || option === 'copy') {
                    this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.store.user.companies[0].id)
                      .subscribe(res => {
                        if (res.responseCode === 200) {

                          const objective = res.data.find(elm => elm.id === this.campaignService.structure.get('objective').value.id);
                          this.campaignService.structure.get('objective').setValue(objective);

                          if (option === 'copy') {
                            this.campaignService.structure.get('config').get('copy').setValue(true);
                            this.removeCreativeStatuses(param);
                          }

                          sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
                          sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));
                          this.detectChange.next(true);

                          this.stopAppLoad();

                          this.store.GUIFlow = {}; // Clear so authguard does not trigger
                          this.router.navigate(param);


                        }
                      });
                  } else {
                    option();
                  }

                });
            });
        }
      });
  }

  /**
   *     Remove creative statuses when copying campaign
   */
  removeCreativeStatuses(param) {
    const module = this.store.modules.filter(elm => elm.type === 'adforsize');
    module[0].module_values.selected.forEach((format, formatIndex) => {
      delete format['creatives_handled'];
      delete format['creatives_status'];
      delete format['uploaded_outside'];
      delete format['all_rejected'];

      format.creative_set.forEach((set, index) => {
        if (set.complete) {
          if (set.creatives.creative_data.facesArray.find(elm => elm.optimization_option === 'weather')) {

            const adForSize = this.getModuleValues('adforsize');
            adForSize['selected'][formatIndex]['creative_set'][index] =
              {
                complete: false,
                id: set.id,
                status: "Pending",
                visible: true
              };

            this.setModuleValue('adforsize', adForSize);
          } else {

            let selectedTemplate = set.templates.selectedTemplate;
            if (format.product_type.toUpperCase() !== 'VIDEOANNONS') {
              if (this.campaignService.structure.get('objective').value.id !== 6) {
                if (format.format !== '320x320') {
                  if (!selectedTemplate.includes(format.format)) {
                    selectedTemplate = selectedTemplate + " " + format.format;
                  }
                }
              }
            } else {
              selectedTemplate = 'Video';
            }

            this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(selectedTemplate))
              .subscribe(res => {
                this.store.bannerDetail = res.data[0];

                const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
                const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");

                const params = JSON.parse(this.store.bannerDetail.params);

                if (this.obtainTemplateName(selectedTemplate, format.format) !== 'DISPLAY VIDEO') {
                  params.bannerData.media.faces = set.creatives.creative_data.facesArray;
                } else {
                  params.bannerData = set.creatives.creative_data.adParams;
                }

                const bannerData = this.store.bannerDetail.script.substring(start, end);
                this.store.bannerDetail.script = this.store.bannerDetail.script.replace(bannerData, JSON.stringify(params));
                this.store.bannerDetail.params = JSON.stringify(params);
                this.createAd(format.format, format.device, index, param);
              });
          }
        }
      });
    });
  }


  createAd(size, device, setIndex, param) {
    const obj = this.createPostObj();
    const adForSize = this.getModuleValues('adforsize');
    this.apiservice.POST(this.store.adServer + '/createBannerLEServlet', obj)
      .subscribe(res => {
        const body = res;
        const data = JSON.parse(body['data']);
        const formatIndex = adForSize.selected.findIndex(elm => elm.format === size && elm.device === device);
        const set = adForSize['selected'][formatIndex]['creative_set'][setIndex];
        const obj =
          {
            adScript: body.script,
            demoLink: data.demoUrl,
            bannerId: body.bannerId,
            adsscoreUrl: data.adsscoreUrl,
            name: this.campaignService.structure.get('name').value,
            adFormatName: set.templates.selectedTemplate,
            selectedSize: this.store.selectedSize

          };

        set['banners'] = [obj];

        this.store.creativesForSize = adForSize['selected'];


        // Save the adscript in ad for size module
        adForSize.selected.forEach((elm) => {
          if (elm.device === device) {
            if (elm.format === size) {
              elm['creative_set'][setIndex]['creatives']['script'] = body.script;
              delete elm['creative_set'][setIndex]['script'];
            }
          }
        });


        delete set['rejection_message'];
        delete set['reviewed_by'];
        delete set['reviewed_date'];
        delete set['status_log'];
        set['status'] = 'Pending';

        sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      });
  }

  createPostObj() {
    const obj = this.store.bannerDetail;

    obj['name'] = this.campaignService.structure.get('name').value;
    obj['publisher'] = 146;
    obj['tags'] = ['SSISP'];

    obj['name'] = obj['name'] ? obj['name'] : ' ';

    if (obj['vast'] === null) {
      delete obj['vast'];
    }
    if (obj['vpaid'] === null) {
      delete obj['vpaid'];
    }
    if (obj['form'] === null) {
      delete obj['form'];
    }
    if (obj['style'] === null) {
      delete obj['style'];
    }
    return obj;
  }

  obtainTemplateName(selectedTemplate, size) {
    if (selectedTemplate.indexOf(size) !== -1) {
      return selectedTemplate.replace(size, '').trim().toUpperCase();
    } else {
      return selectedTemplate.toUpperCase();
    }
  }


  getCampaign(id, callback) {
    let tmpArray = [];
    tmpArray.push(id);

    this.apiservice.getJSON(this.store.apiURL + "/CampaignServlet?values=" + JSON.stringify(tmpArray))
      .subscribe(res => {
        if (res.responseCode === 200) {
          callback(res);
        } else {
          this.logoutUser();
        }
      });
  }

  clearModuleValues() {
    this.store.modules.forEach((elm) => {
      if (elm.type === 'templates') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }
      if (elm.type === 'adcreation') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.param.output = '';
      }

      if (elm.type === 'creatives') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

      if (elm.type === 'existingtag') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

    });

    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }

  logoutUser() {
    this.changeSelectedMenuItem(0);
    this.resetVariables();
    sessionStorage.removeItem("userObj");
    sessionStorage.removeItem("free_campaign");
    this.router.navigate(['login']);
  }


  checkAdBlock() {
    if (this.store.checkOnce) {
      this.store.checkOnce = false;
      let adblockExists = true;

      this.apiservice.GETAdBlock("/assets/js/showads.js")
        .subscribe(res => {
          adblockExists = false;
        });

      setTimeout(() => {
        if (adblockExists) {
          if (this.store.showAdblockWarningOnce) {
            this.store.showAdblockWarningOnce = false;
            const dialogRef = this.dialog.open(AdblockDialogComponent, {
              panelClass: 'modal-size-xs',
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {

            });
          }
        }
      }, 2500);

    }
  }


  getDeviceByFormat(format) {
    if (this.store.productType.toUpperCase() !== 'VIDEOANNONS') {
      if (format['device'] === 'All') {
        switch (format.format) {
          case "640x320":
            return 'Desktop';
            break;
          case "980x240":
            return 'Desktop';
            break;
          case "250x600":
            return 'Desktop';
            break;
          case "300x480":
            return 'Desktop';
            break;
          case "1920x1080":
            return 'Desktop';
            break;
          case "1280x720":
            return 'Desktop';
            break;
          case "320x320":
            return 'Mobile';
            break;
          default:
            return 'All';
        }
      } else {
        return format['device'];
      }
    } else {
      return format['device'];
    }

    /* else{
       if(format.format.toLowerCase().includes('mobile')){
         return 'Mobile';
       }else if(format.format.toLowerCase().includes('desktop')){
         return 'Desktop';
       }
     }*/
  }

  resetVariables() {
    this.store.user = undefined;
    this.store.isAdmin = {value: false};
    this.store.isSalesRep = {value: false};
    this.store.isAdminCompanyManager = {value: false};
    this.store.isAdminCampaignManager = {value: false};
    this.store.isUser = {value: false};
    this.store.isCompanyAdministrator = {value: false};
    this.store.isTrustedPartner = {value: false};
    this.store.isMediaAgency = {value: false};
    this.store.isAgency = {value: false};
    this.store.isBooking = {value: false};
    this.store.noteText = "";
    this.store.companiesList = [];
    this.store.freeCampaign = false;
    /*this.store.errorCampaigns = undefined;*/
  }


  getBudgets(data, totalBudget, minimumBudgePerItem, splitByDevice, inventoryLimit) {
    /*
        data - Json coming from module values
        totalBudget - Total amount for the order
        minimumBudgePerItem - Minimum amount per product within the subcategories to be considered usable
        splitByDevice - Json with the expected proportion of mobile and desktop budgets (has to add up to 1)
        inventoryLimit - Proportion of the maximum budget that can be used.
     */


    var prod_mobile = data.contexts.map(function (elem) {
      return elem.sub_contexts;
    }).reduce(function (acc, val) {
      return acc.concat(val);
    }).filter(function (elem) {
      return elem.selected === true;
    }).map(function (elem) {
      return elem.products;
    });

    if (prod_mobile.length === 0) {
      prod_mobile = [];
    } else {
      prod_mobile = prod_mobile.reduce(function (acc, val) {
        return acc.concat(val);
      }).filter(function (elem) {
        return elem.target_device === "Mobile" && elem.selected;
      });
    }

    var prod_desktop = data.contexts.map(function (elem) {
      return elem.sub_contexts;
    }).reduce(function (acc, val) {
      return acc.concat(val);
    }).filter(function (elem) {
      return elem.selected === true;
    }).map(function (elem) {
      return elem.products;
    });
    if (prod_desktop.length === 0) {
      prod_desktop = [];
    } else {
      prod_desktop = prod_desktop.reduce(function (acc, val) {
        return acc.concat(val);
      }).filter(function (elem) {
        return ((elem.target_device === "Desktop" || elem.target_device === "All") && elem.selected);
      });
    }


    // Set to 0 all the selected budgets

    var tmp = data.contexts.map(function (currContext, indContext) {
      currContext.sub_contexts.map(function (currSubcontext, indSubcontext) {
        if (currSubcontext.selected === true) {
          // Set subcontext budget to 0

          data.contexts[indContext].sub_contexts[indSubcontext].total_budget_for_subcontext = 0;
          data.contexts[indContext].sub_contexts[indSubcontext].total_impressions_for_subcontext = 0;
          data.contexts[indContext].sub_contexts[indSubcontext].total_budget_percent_for_subcontext = 0;

          // Set the products of the selected subcontexts to 0
          currSubcontext.products.map(function (currProduct, indProduct) {
            data.contexts[indContext].sub_contexts[indSubcontext].products[indProduct].budget = 0;
            data.contexts[indContext].sub_contexts[indSubcontext].products[indProduct].impressions = 0;
            data.contexts[indContext].sub_contexts[indSubcontext].products[indProduct].budget_percent = 0;

            return currProduct;
          });
        }

        return currSubcontext;

      });
    });


    // Calculate the total capacity per device including only the ones that contain at least the minimum budget (takes into account the inventory limit)
    var totalBugetMobile = prod_mobile.map(function (elem) {
      return (elem.available * inventoryLimit / 1000) * elem.pricing;
    }).filter(function (elem) {
      return elem >= minimumBudgePerItem;
    });
    if (totalBugetMobile.length === 0) {
      totalBugetMobile = 0;
    } else {
      totalBugetMobile = Math.round(totalBugetMobile.reduce(function (acc, a) {
        return acc + a;
      }));

    }


    var totalBugetDesktop = prod_desktop.map(function (elem) {
      return (elem.available * inventoryLimit / 1000) * elem.pricing;
    }).filter(function (elem) {
      return elem >= minimumBudgePerItem;
    });
    if (totalBugetDesktop.length === 0) {
      totalBugetDesktop = 0;
    } else {
      totalBugetDesktop = Math.round(totalBugetDesktop.reduce(function (acc, a) {
        return acc + a;
      }));

    }

    // At this point you you know that all budgets have at least minimumBudgePerItem * product available for booking capacity


    // Product (external_product_id,potentialBudget,budget)


    if (totalBudget > (totalBugetMobile + totalBugetDesktop)) {
      // There is no capacity to fullfill the request
      return data;
    }

    var budgetUpdated = {mobile: false, desktop: false};

    // Set total budget according to desired distribution
    splitByDevice.mobileBudget = totalBudget * splitByDevice.mobile;
    splitByDevice.desktopBudget = totalBudget * splitByDevice.desktop;


    // Minimum Budget per bloc forced balance

    // Num products in mobile with enought invertory to be used to allocate budget
    var availableMobileProducts = prod_mobile.map(function (elem) {
      return (elem.available * inventoryLimit / 1000) * elem.pricing;
    }).filter(function (elem) {
      return elem >= minimumBudgePerItem;
    }).length;

    if (availableMobileProducts * minimumBudgePerItem > totalBudget * splitByDevice.mobileBudget) {

      splitByDevice.mobileBudget = prod_mobile.map(function (elem) {
        return (elem.available * inventoryLimit / 1000) * elem.pricing;
      }).filter(function (elem) {
        return elem >= minimumBudgePerItem;
      }).length * minimumBudgePerItem;
      splitByDevice.desktopBudget = totalBudget - splitByDevice.mobileBudget;
      budgetUpdated.mobile = true;
    }

    // Num products in mobile with enought invertory to be used to allocate budget
    var availableDesktopProducts = prod_desktop.map(function (elem) {
      return (elem.available * inventoryLimit / 1000) * elem.pricing;
    }).filter(function (elem) {
      return elem >= minimumBudgePerItem;
    }).length;

    if (availableDesktopProducts * minimumBudgePerItem > totalBudget * splitByDevice.desktop) {
      splitByDevice.desktopBudget = prod_desktop.map(function (elem) {
        return (elem.available * inventoryLimit / 1000) * elem.pricing;
      }).filter(function (elem) {
        return elem >= minimumBudgePerItem;
      }).length * minimumBudgePerItem;
      splitByDevice.mobileBudget = totalBudget - splitByDevice.desktopBudget;
      budgetUpdated.desktop = true;
    }

    // Insuficient budget for mobile with desired balance
    if (splitByDevice.mobileBudget > totalBugetMobile) {
      splitByDevice.desktopBudget = splitByDevice.desktopBudget + (splitByDevice.mobileBudget - totalBugetMobile);
      splitByDevice.mobileBudget = totalBugetMobile;
      budgetUpdated.desktop = true;
    }

    // Insuficient budget for desktop with desired balance
    if (splitByDevice.desktopBudget > totalBugetDesktop) {
      splitByDevice.mobileBudget = splitByDevice.mobileBudget + (splitByDevice.desktopBudget - totalBugetDesktop);
      splitByDevice.desktopBudget = totalBugetDesktop;
      budgetUpdated.mobile = true;
    }

    // At this point budget has been splited as good as possible trying to respect the distribution and minimizing the difference.


    var prod_mobile = allocateBudget(splitByDevice.mobileBudget, totalBugetMobile, minimumBudgePerItem, inventoryLimit, availableMobileProducts, totalBudget, prod_mobile);

    var prod_desktop = allocateBudget(splitByDevice.desktopBudget, totalBugetDesktop, minimumBudgePerItem, inventoryLimit, availableDesktopProducts, totalBudget, prod_desktop);


    // Update product budgets in data structure

    // Update mobile products
    data = updateBudgets(data, prod_mobile, totalBudget);
    // Update desktop products
    data = updateBudgets(data, prod_desktop, totalBudget);


    // Auxiliar functions

    // Distributes the budget in a set of products
    function allocateBudget(budget, maxBudget, minimumBudgePerItem, inventoryLimit, numProductsAvailable, globalBudget, products) {

      // Calculate correction

      var diffPrice = 0.0;

      products.forEach(function (product) {
        var potentialProductBudget = (product.available * inventoryLimit / 1000) * product.pricing;
        if (potentialProductBudget >= minimumBudgePerItem) {
          // If budget is over the minimum split the rest on the products
          if (maxBudget > (numProductsAvailable * minimumBudgePerItem)) {
            diffPrice = diffPrice + ((budget - (numProductsAvailable * minimumBudgePerItem)) * ((potentialProductBudget - minimumBudgePerItem)
              /
              (maxBudget - (numProductsAvailable * minimumBudgePerItem)))) % 1;

          }
        }
      });

      diffPrice = Math.round(diffPrice);

      // Update

      products = products.map(function (product) {
        var potentialProductBudget = (product.available * inventoryLimit / 1000) * product.pricing;
        if (potentialProductBudget >= minimumBudgePerItem) {

          product.budget = minimumBudgePerItem;
          // If budget is over the minimum split the rest on the products
          if (maxBudget > (numProductsAvailable * minimumBudgePerItem)) {
            product.budget = Math.floor(product.budget + (budget - (numProductsAvailable * minimumBudgePerItem)) * ((potentialProductBudget - minimumBudgePerItem)
              /
              (maxBudget - (numProductsAvailable * minimumBudgePerItem))));
            if ((product.budget + diffPrice) <= (numProductsAvailable * minimumBudgePerItem)) {
              product.budget = product.budget + diffPrice;
              diffPrice = 0;
            }
          }

          product.impressions = Math.floor((product.budget / product.pricing) * 1000);
          product.budget_percent = Math.floor((product.budget / globalBudget) * 100);
        }
        return product;
      });
      return products;
    }

    function updateBudgets(data, products, budget) {

      var productMap = products.reduce(function (map, product) {
        map[product.id_products] = product;
        return map;
      }, {});

      data.contexts = data.contexts.map(function (currContext, indContext) {
        // Here would go the Context calculations but there is none at the moment.

        currContext.sub_contexts = currContext.sub_contexts.map(function (currSubcontext, indSubcontext) {
          if (currSubcontext.selected == true) {
            // Set subcontext budget to 0

            data.contexts[indContext].sub_contexts[indSubcontext].total_budget_for_subcontext = 0;
            data.contexts[indContext].sub_contexts[indSubcontext].total_impressions_for_subcontext = 0;
            data.contexts[indContext].sub_contexts[indSubcontext].total_budget_percent_for_subcontext = 0;

            // Set the products of the selected subcontexts to 0
            currSubcontext.products.map(function (currProduct) {
              if (typeof productMap[currProduct.id_products] != "undefined") {
                currProduct.budget = productMap[currProduct.id_products].budget;
                currProduct.impressions = productMap[currProduct.id_products].impressions;
                currProduct.budget_percent = productMap[currProduct.id_products].budget_percent;
              } else {

              }

              data.contexts[indContext].sub_contexts[indSubcontext].total_budget_for_subcontext = data.contexts[indContext].sub_contexts[indSubcontext].total_budget_for_subcontext + currProduct.budget;
              data.contexts[indContext].sub_contexts[indSubcontext].total_impressions_for_subcontext = data.contexts[indContext].sub_contexts[indSubcontext].total_impressions_for_subcontext + currProduct.impressions;
              data.contexts[indContext].sub_contexts[indSubcontext].total_budget_percent_for_subcontext = Math.floor(data.contexts[indContext].sub_contexts[indSubcontext].total_budget_for_subcontext * 100 / budget);


              return currProduct;
            });
          }

          return currSubcontext;

        });
        return currContext;
      });
      return data;
    }


    // Add missing budget to any context
    const selectedContext = data.contexts[0];
    const totalAllocated: number = selectedContext.sub_contexts.filter(sub => sub.selected)
      .reduce((prev, cur) => {
        prev += cur.total_budget_for_subcontext;
        return prev;
      }, 0);
    const allocatedPercentage: number = selectedContext.sub_contexts.filter(sub => sub.selected)
      .reduce((prev, cur) => {
        prev += cur.total_budget_percent_for_subcontext;
        return prev;
      }, 0);

    if (totalBudget > totalAllocated) {

      const diff: number = totalBudget - totalAllocated;
      selectedContext.total_budget += diff;

      const firstSubContext = selectedContext.sub_contexts.filter(elm => elm.selected)[0];
      firstSubContext.total_budget_for_subcontext += diff;
      firstSubContext.total_budget_percent_for_subcontext += 1;

      // Increase budget with the diff, add 1 percent to the selected product
      const firstProduct = firstSubContext.products.filter(elm => elm.selected)[0];
      firstProduct.budget += diff;
      firstProduct.budget_percent += 1;

    } else if (allocatedPercentage < 100) {
      const diff: number = 100 - allocatedPercentage;
      const firstSubContext = selectedContext.sub_contexts.filter(elm => elm.selected)[0];
      firstSubContext.total_budget_percent_for_subcontext += diff;
    }

    return data;
  }


  updateBudgetSubContext(data, subcontextName, newBudget, minimumBudgePerItem, splitByDevice, inventoryLimit, oldbudget) {
    data.contexts = data.contexts.map(function (currContext, indContext) {
      // Here would go the Context calculations but there is none at the moment.

      currContext.sub_contexts = currContext.sub_contexts.map(function (currSubcontext, indSubcontext) {
        if (currSubcontext.selected == true && currSubcontext.name == subcontextName) {

          //  var oldbudget = currSubcontext.total_budget_for_subcontext,
          var variation = newBudget - oldbudget;

          // Set subcontext budget to 0
          currSubcontext.total_budget_for_subcontext = newBudget;
          currSubcontext.total_impressions_for_subcontext = 0;


          var acc = 0;

          // Set the products of the selected subcontexts to 0
          currSubcontext.products.map(function (currProduct) {

            currProduct.budget = Math.floor(currProduct.budget + (variation * (currProduct.budget / oldbudget)));
            acc = acc + currProduct.budget;
            if (newBudget - acc < 20 && acc !== newBudget) {
              currProduct.budget = currProduct.budget + (newBudget - acc);
            }

            currProduct.impressions = Math.floor((currProduct.budget / currProduct.pricing) * 1000);

            currSubcontext.total_impressions_for_subcontext = currSubcontext.total_impressions_for_subcontext + currProduct.impressions;

            return currProduct;
          });
        }

        return currSubcontext;

      });
      return currContext;
    });


    data = this.refreshBudgetPercentages(data);

    return data;

  }

  updateBudgetProduct(data, productId, newBudget, oldbudget) {
    data.contexts = data.contexts.map(function (currContext, indContext) {
      // Here would go the Context calculations but there is none at the moment.

      currContext.sub_contexts = currContext.sub_contexts.map(function (currSubcontext, indSubcontext) {
        if (currSubcontext.selected == true) {

          // Set subcontext budget to 0
          currSubcontext.total_impressions_for_subcontext = 0;

          // Set the products of the selected subcontexts to 0
          currSubcontext.products.map(function (currProduct) {

            if (currProduct.id_products == productId) {
              // Add diferential to subcontext
              currSubcontext.total_budget_for_subcontext = currSubcontext.total_budget_for_subcontext + (newBudget - oldbudget);
              // Update product
              currProduct.budget = newBudget;
              currProduct.impressions = Math.floor((newBudget / currProduct.pricing) * 1000);


            }

            currSubcontext.total_impressions_for_subcontext = currSubcontext.total_impressions_for_subcontext + currProduct.impressions;

            return currProduct;
          });
        }

        return currSubcontext;

      });
      return currContext;
    });

    data = this.refreshBudgetPercentages(data);
    return data;
  }


  updateBudgetContext(data, contextName, newBudget) {
    // Pending

  }

  refreshBudgetPercentages(data) {
    var budget = data.contexts.map(function (elem) {
      return elem.sub_contexts;
    }).reduce(function (acc, val) {
      return acc.concat(val);
    }).filter(function (elem) {
      return elem.selected == true;
    }).map(function (elem) {
      return elem.total_budget_for_subcontext;
    }).reduce(function (acc, a) {
      return acc + a;
    });

    data.contexts = data.contexts.map(function (currContext, indContext) {
      // Here would go the Context calculations but there is none at the moment.

      currContext.sub_contexts = currContext.sub_contexts.map(function (currSubcontext, indSubcontext) {
        if (currSubcontext.selected == true) {

          currSubcontext.total_budget_percent_for_subcontext = Math.floor((currSubcontext.total_budget_for_subcontext * 100) / budget);

          // Set the products of the selected subcontexts to 0
          currSubcontext.products.map(function (currProduct) {
            currProduct.budget_percent = Math.floor((currProduct.budget * 100) / budget);
            return currProduct;
          });
        }
        return currSubcontext;
      });
      return currContext;
    });
    return data;
  }


}
