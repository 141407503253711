import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import * as sha256 from "sha256";
import {RegisterUser} from "../../models/register-user.model";
import {RegisterCompany} from "../../models/register-company.model";
import {RegisterRequest} from "../../models/register-request.model";
import {Subscription} from "rxjs";
import {StartpageService} from "../../startpage.service";
import {RegisterResponse} from "../../models/register-response.model";
import {Credentials} from "../../models/credentials.model";
import {LoginResponse} from "../../models/login-response.model";
import {Meta, Title} from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import {PrivacyDialogComponent} from "../../dialogs/privacy-dialog/privacy-dialog.component";
import {TandcDialogComponent} from "../../../dashboard/campaign/modules/shared_components/reviewmodule/tandc-dialog/tandc-dialog.component";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit, OnDestroy {

  registerSubscription: Subscription;
  loginSubscription: Subscription;
  showComp: boolean = true;
  errorMessage: string;

  conversionLink: string;

  constructor(
              public store: StoreService,
              private _startpage: StartpageService,
              private _cd: ChangeDetectorRef,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private _meta: Meta,
              private _title: Title,
              private dialog: MatDialog
             ) {
  }


  ngOnDestroy() {
    if (this.loginSubscription !== undefined) {
      this.loginSubscription.unsubscribe();
    }
    if (this.registerSubscription !== undefined) {
      this.registerSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    this._meta.updateTag({name: 'description', content: 'Fyll i dina uppgifter för att boka din första kampanj. Skapa ditt Schibsted AdMarket-konto här. Schibsted AdMarket.'});
    this._title.setTitle('Skapa ett AdMarket-konto för att komma igång | Schibsted AdMarket');
  }

  openPrivacyDialog(){
    this.dialog.open(PrivacyDialogComponent, {panelClass: 'modal-size-md'});

  }

  register(event) {
    this.showComp = false;
    const user: RegisterUser = {
      email: event.email,
      name: `${event.firstName} ${event.lastName}`,
      firstname: event.firstName,
      lastname: event.lastName,
      mobile: event.mobileNumber,
      phone: event.mobileNumber,
      password: sha256(event.password)
    };


    const company: RegisterCompany = {
      org_name: event.orgName,
      org_num: event.orgNum,
      billing_address: event.billingAddress,
      billing_zip: event.billingZip,
      billing_city: event.billingCity,
      visiting_address: event.billingAddress,
      visiting_zip: event.billingZip,
      visiting_city: event.billingCity
    };

    const registerRequest: RegisterRequest = {
      user: user,
      company: company
    };

    this.registerSubscription = this._startpage.register(registerRequest)
      .subscribe((res: RegisterResponse) => {
        if (res.responseCode === 200) {

          this.conversionLink = this.store.langJSON.component.register.conversion_link;

          const obj: Credentials =
            {
              username: user.email,
              password: user.password
            };

          this.loginSubscription = this._startpage.login(obj).subscribe(
            (res: LoginResponse) => {
              if (res.responseCode === 200) {
                const data = res.data;
                data["token"] = sha256(data["token"]);
                sessionStorage.setItem("userObj", JSON.stringify(data));
                this.store.user = data;
                this.store.selectedMenuItem = 0;
                if (!this._cd["destroyed"]) {
                  this._cd.detectChanges();
                }
                this.globalfunctionality.clearVariables();
                this.globalfunctionality.checkUser(() => {
                  this.router.navigate(['/dashboard/campaigns']);
                });
              }else{
                this.showComp = true;
              }
            });

        } else if (res.responseCode === 500) {
          this.setError(res);
          this.showComp = true;
        }else if(res.responseCode === 400){
          this.showComp = true;
          this.errorMessage = "Någonting gick fel";
        }
      });
  }

  openTermsOfUseDialog() {
    this.dialog.open(TandcDialogComponent, {panelClass: 'modal-size-md'});
  }

  setError(response) {
    if (response["responseMessage"] === "companyerror") {
      this.errorMessage = this.store.langJSON.component.register.error.companyerror;
    } else if (response["responseMessage"] === "usererror") {
      this.errorMessage = this.store.langJSON.component.register.error.usererror;
    }else{
      this.errorMessage = "Någonting gick fel";
    }
  }
}






