import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";

@Component({
  selector: 'app-searchword',
  templateUrl: './searchword.component.html',
  styleUrls: ['./searchword.component.css']
})
export class SearchwordComponent implements OnInit {

  @Output() searchWordIndicatorLoading = new EventEmitter();
  @Output() capacityChangeTo = new EventEmitter<number>();
  @Output() resetPageComplete = new EventEmitter();



  searchWordObj;
  categories;
  geos;
  selectedOption: string;

  constructor(
    private apiservice: ApiService,
    private store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.searchWordObj = this.globalfunctionality.getModuleValues('searchword');
    // Get content categories
    if (this.searchWordObj.hasOwnProperty("selectedOption")) {
      this.selectedOption = this.searchWordObj.selectedOption;
    } else {
      this.selectedOption = "0";
      this.searchWordObj.selectedOption = this.selectedOption;
      this.saveChange(this.searchWordObj);
    }
    this.apiservice
      .getJSON(this.store.apiURL + '/CustomTargetsServlet?context_content_categories=blocket')
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.categories = res.data.contentCategories;
          this.categories.forEach(cat => {
            let categoryName = cat.target_option;

            if (cat.children) {
              cat.children.forEach(sub => {
                sub['categoryName'] = categoryName;
                let subCategoryName = sub.target_option;
                if (sub.children) {
                  sub.children.forEach(prod => {
                    prod['subCategoryName'] = subCategoryName;
                  });
                }
              });
            }

          });

          this.geos = res.data.geoCategories;
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }

  triggerResetPage(){
    this.resetPageComplete.emit();
  }

  searchWordChange(){
    this.searchWordObj = Object.assign({}, this.globalfunctionality.getModuleValues('searchword'));
  }

  targetingChange(){

    this.searchWordObj.selectedOption = this.selectedOption;
    if (this.selectedOption === '0') {
      delete this.searchWordObj['freetext'];
      delete this.searchWordObj['category'];
    } else if (this.selectedOption === '1') {
      delete this.searchWordObj['freetext'];
    } else if (this.selectedOption === '2') {
      delete this.searchWordObj['category'];
    }

    this.saveChange(this.searchWordObj);
    this.searchWordObj = Object.assign({}, this.globalfunctionality.getModuleValues('searchword'));
  }


  indicatorLoading(e) {
    this.searchWordIndicatorLoading.emit(e);
  }


  saveChange(searchWordObj) {
    this.globalfunctionality.setModuleValue('searchword', searchWordObj);
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }
}
