import {Component, OnInit, Input, OnChanges, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {TableMetadataModel} from "./table-metadata.model";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Output() pageUpdate = new EventEmitter();
  globalPageObject: TableMetadataModel;

  @Input() pageSizes: number[] = [25, 50, 100];
  @Input()  selectedPageSize: number = 25;
  @Input()  maxNumberOptions: number = 5;
  @Input()  lastIndex: number = 4;
  @Input()  defaultLastIndex: number = 4;
  @Input()  firstIndex: number = 0;

  @Input() set pageObject(pageObject: TableMetadataModel) {
    this.globalPageObject = pageObject;
    this.generatePagination();
  }

  items: any[] = [];

  maxPage: number;

  nextTimeout;
  prevTimeout;

  constructor(iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'keyboard_arrow_right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/keyboard_arrow_right.svg'));
    iconRegistry.addSvgIcon(
      'keyboard_arrow_left',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/keyboard_arrow_left.svg'));
  }

  ngOnInit() {
    if (this.globalPageObject.length !== undefined) {
      this.generatePagination();
    }

  }

  generatePagination() {
    this.items = [];
    const {length, page_size} = this.globalPageObject;
    this.maxPage = Math.ceil(length / page_size);
    for (let i = 0; i < this.maxPage; i++) {
      this.items.push(i + 1);
    }
  }

  selectPage(page: number) {
    page -= 1;
    this.globalPageObject.page = page;
    if (this.items.length > this.maxNumberOptions) {
      const elementsInview = [];

      for (let i = this.firstIndex; i <= this.lastIndex; i++) {
        elementsInview.push(this.items[i]);
      }
      const mid = elementsInview[this.maxNumberOptions - 3];
      if ((page + 1) < mid) {
        const diff = (mid - page) - 1;
        if ((this.lastIndex > this.defaultLastIndex && diff === 1) || this.lastIndex > this.maxNumberOptions) {
          this.lastIndex -= diff;
          this.firstIndex -= diff;
        } else if (this.lastIndex > this.defaultLastIndex && diff === 2) {
          this.lastIndex -= 1;
          this.firstIndex -= 1;
        }
      }
      if ((page + 1) > mid) {
        const diff = (page - mid) + 1;
        if (((this.firstIndex < this.items.length - this.maxNumberOptions) && diff === 1) || (this.firstIndex < this.items.length - (this.maxNumberOptions + 2))) {
          this.lastIndex += diff;
          this.firstIndex += diff;
        }else if ((this.firstIndex < this.items.length - this.maxNumberOptions) && diff === 2){
          this.lastIndex += 1;
          this.firstIndex += 1;
        }
      }
    }
    this.pageUpdate.emit(this.globalPageObject);
  }

  pageSizeChanged() {
    // Check if page size has decreased or increased, if page size has decreased do nothing, if page size has increased divide selected page, ex page size = 25 max page = 4 if we change to page size 50 then max page is 2
    const {length, page_size} = this.globalPageObject;
    this.maxPage = Math.ceil(length / page_size);
    if (this.selectedPageSize > this.globalPageObject.page_size) {
      const divider = this.selectedPageSize / this.globalPageObject.page_size;
      const newMaxPage = Math.floor(this.maxPage / divider);
      if(this.globalPageObject.page > newMaxPage){
        this.globalPageObject.page = newMaxPage - 1;
        if ((this.firstIndex / divider) % 1 <= 0.5 && divider === 2) {
          this.firstIndex = Math.floor(this.firstIndex / divider) - 2;
        } else {
          this.firstIndex = Math.floor(this.firstIndex / divider) - 3;
        }
        this.lastIndex = Math.floor(this.lastIndex / divider);
      }
    }
    this.globalPageObject.page_size = this.selectedPageSize;
    this.generatePagination();
    this.pageUpdate.emit(this.globalPageObject);
  }

  firstPage() {
    this.lastIndex = this.defaultLastIndex;
    this.firstIndex = 0;
    this.globalPageObject.page = 0;
    this.pageUpdate.emit(this.globalPageObject);
  }

  lastPage() {
    this.lastIndex = this.items.length - 1;
    this.firstIndex = (this.items.length - 1) - this.defaultLastIndex;
    this.globalPageObject.page = this.items.length - 1;
    this.pageUpdate.emit(this.globalPageObject);
  }

  nextPage() {
    clearTimeout(this.nextTimeout);

    if (this.items.length > this.maxNumberOptions && this.globalPageObject.page > 1) {
      this.lastIndex += 1;
      this.firstIndex += 1;
    }
    if (this.globalPageObject.page < this.items[this.items.length - 1]) {
      this.globalPageObject.page += 1;
      this.nextTimeout = setTimeout(() => {
        this.pageUpdate.emit(this.globalPageObject);
      }, 500);
    }
  }

  prevPage() {
    clearTimeout(this.prevTimeout);
    if (this.items.length > this.maxNumberOptions && this.globalPageObject.page < this.items.length - 2) {
      this.lastIndex -= 1;
      this.firstIndex -= 1;
    }
    if (this.globalPageObject.page > 0) {
      this.globalPageObject.page -= 1;
      this.prevTimeout = setTimeout(() => {
        this.pageUpdate.emit(this.globalPageObject);
      }, 500);
    }
  }
}
