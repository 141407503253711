import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";
import {CreativemoduleService} from "../../../creativemodule.service";
import {ApiService} from "../../../../../../../../services/api.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {CreatecampaignComponent} from "../../../../../../createcampaign/createcampaign.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {forkJoin, Subscription} from "rxjs";
import set = Reflect.set;
import {ResponseBodyModel} from "../../../../../../../../models/response-body.model";
import {CampaignService} from "../../../../../../campaign.service";

@Component({
  selector: 'app-creative-image-direct-upload',
  templateUrl: 'image-direct-upload.component.html'
})

export class ImageDirectUploadComponent implements OnInit, OnDestroy {


  @Input() adMediaControl: FormControl;
  @Input() faceGroup: FormGroup;
  @Input() weatherOptions;
  @Input() productBackgroundOption: FormControl;
  // Used for deciding text
  @Input() logo: boolean = false;
  // removeHTMLWrapperElements Used for when this component is used as a sub choice within a form box will remove following parent nodes -> form-box-row -> form-box ->form-box__fields f-grid f-span
  @Input() removeHTMLWrapperClasses: boolean = false;
  @Input() recommended_size: string;
  @Input() fallbackImage: boolean = false;
  @Input() backgroundImage: boolean = false;
  @Input() template: string;
  @Input() feedOption: boolean = false;
  @Input() plugImageFrequencyControl: FormControl;
  @Input() adMediaHorizontalControl: FormControl;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();
  @Output() triggerCheckIfComplete: EventEmitter<any> = new EventEmitter();
  @Output() updateVal: EventEmitter<any> = new EventEmitter();
  @Output() videoExtensionHandler: EventEmitter<any> = new EventEmitter();

  @Input() optimizationOption: boolean = false;
  @Input() optimizationControlKey: string;
  optimizationOptionValue;

  loadPercentage: number = 0;
  loadPercentageHorizontal: number = 0;
  percentageIncrementor;
  percentageIncrementorHorizontal;

  illegalFiletype: boolean = false;

  noPreviewAvailable: boolean = false;

  imageHorizontalIllegalFiletype: boolean = false;
  maxFileWeight: number;
  data: any = {};
  dataLogo: any = {};
  dataHorizontal: any = {};
  showImageLoad: boolean = false;
  showImageLoadHorizontal: boolean = false;
  showImageWeightError: boolean = false;
  showImageWeightErrorHorizontal: boolean = false;
  videoDurationError: boolean = false;
  videoExtension: string;


  uploadNewImage: boolean = false;

  imageUploaded: boolean = false;
  frequencyOptions =
    [
      {
        value: '1',
        name: '1 produktbild'
      },
      {
        value: '2',
        name: '2 produktbilder'
      },
      {
        value: '3',
        name: '3 produktbilder'
      },
      {
        value: '4',
        name: '4 produktbilder'
      },
      {
        value: '5',
        name: '5 produktbilder'
      },
      {
        value: '6',
        name: '6 produktbilder'
      },
    ];

  subscriptions: Subscription[] = [];

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private creativeService: CreativemoduleService,
              private campaignService: CampaignService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/info.svg'));

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(elm => elm.unsubscribe());
  }

  ngOnInit(): void {

    if(this.adMediaControl?.value && this.optimizationOption){
      this.toggleWeatherOption(true);
    }

    if(this.optimizationOption) {
      this.optimizationOptionValue = this.optimizationControlKey.split('.')[0];
    }

    this.getFileMaxSize();

    if (this.productBackgroundOption) {
      this.subscriptions.push(this.creativeService.resetImageUpload.subscribe((reset: boolean) => {
        if (reset) {
          this.adMediaControl.reset();
        }
      }));
    }

    if (this.plugImageFrequencyControl) {
      this.plugImageFrequencyControl.setValue('off');
      this.plugImageFrequencyControl.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe(() => {
        this.triggerUpdatePreview.emit('fallback');
      });
    }
  }

  fileChangeListener($event) {
    this.store.showImageError = false;
    const image: any = new Image();
    const file: File = $event.target.files[0];

    if (file) {

      if (this.creativeService.allowed_types.indexOf(file.type) === -1) {
        let input: any = document.getElementById('file-upload');
        input.value = "";
        image.src = "";
        this.illegalFiletype = true;
      } else {
        this.illegalFiletype = false;
        const myReader: FileReader = new FileReader();
        let fileSize = file.size / 1024;

        if (fileSize <= this.maxFileWeight) {
          this.resetFileUpload();

          myReader.readAsDataURL(file);
          myReader.onloadend = (loadEvent: any) => {

            image.src = loadEvent.target.result;
            this.data.image = image.src;

            this.saveImage(image.src, (image) => {
              const values = this.globalfunctionality.getModuleValues("creatives");
              values["originalImg"] = image;
              this.uploadNewImage = false;
              sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
              this.selectImage();
            });
          };
        } else {
          this.showImageWeightError = true;
        }
      }
    }
  }


  getFileMaxSize() {
    if (this.store.bannerDetail) {
      const params = JSON.parse(this.store.bannerDetail.params);
      this.maxFileWeight = Number(params.bannerData.options.bannerBehavior.bannerOption.face_weight_limit ?
        params.bannerData.options.bannerBehavior.bannerOption.face_weight_limit :
        this.logo ? 100 : 150);
    } else {
      this.maxFileWeight = 100;
    }
  }


  resetFileUpload() {
    this.videoDurationError = false;
    this.showImageWeightError = false;
    this.noPreviewAvailable = false;
    this.incrementPercentage();
    this.showImageLoad = true;
  }

  incrementPercentage() {
    this.percentageIncrementor = setInterval(() => {
      if (this.loadPercentage < 95) {
        this.loadPercentage += 5;
      }
      this.detectChanges();
    }, 1000);
  }

  detectChanges() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }

  saveImage(image, callback) {
    const base64 = image;
    const fileType = image.split(';')[0].split('/')[1];

    const obj =
      {
        base64: base64,
        type: fileType,
        key: 'image'
      };

    this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
      .subscribe(res => {
        const image = res[0]["image"];
        callback(image);
      });
  }


  selectImage() {
    const base64 = this.data.image;
    const fileType = this.data.image.split(';')[0].split('/')[1];
    const obj =
      {
        base64: base64,
        type: fileType,
        key: 'image'
      };

    this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
      .subscribe(response => {

        const image = response[0]["image"];
        this.adMediaControl.setValue(image);

        this.imageUploaded = true;

        const previewIframe: any = document.getElementById('previewIframe');
        const iframe: any = document.getElementById('iframe');
        const initial: any = document.getElementById('initialPreviewIframe');
        if (iframe != null) {
          iframe.style.display = "block";
        }
        if (previewIframe != null) {
          previewIframe.style.display = "block";
        }
        if (initial != null) {
          initial.style.display = "none";
        }

        window.scrollTo(0, document.body.scrollHeight);
        this.detectChanges();
        this.stopIncrementPercentage();

        this.triggerUpdatePreview.emit();
      });
  }

  stopIncrementPercentage() {
    this.loadPercentage = 100;
    this.detectChanges();
    setTimeout(() => {
      this.showImageLoad = false;
      clearInterval(this.percentageIncrementor);
      this.loadPercentage = 0;
      this.detectChanges();
    }, 2000);
  }


  videoChangeListener($event) {
    const iframe: HTMLElement = document.getElementById("iframe");
    const previewIframe: HTMLElement = document.getElementById("previewIframe");

    if (iframe != null) {
      iframe.style.display = "none";
    }

    if (previewIframe != null) {
      previewIframe.style.display = "block";
    }

    this.store.showImageError = false;
    this.noPreviewAvailable = false;

    const video = document.createElement('video') as HTMLVideoElement;
    const file: File = $event.target.files[0];
    this.getFileMaxSize();

    const extension: string = file.name.split('.')[1];

    this.videoExtension = extension;
    this.videoExtensionHandler.emit(this.videoExtension);

    if (this.creativeService.allowed_types_video.indexOf(extension) === -1) {
      const input: any = document.getElementById('file-upload');
      input.value = "";
      video.src = "";
      this.illegalFiletype = true;
    } else {
      this.illegalFiletype = false;
      const fileSize = file.size / 1024;
      if (fileSize <= this.maxFileWeight) {

        if (this.creativeService.allowed_preview_video_types.indexOf(extension) !== -1) {
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.src = URL.createObjectURL(file);
          video.onloadedmetadata = () => {
            const duration = Math.round(video.duration);
            if (this.store.selectedSize.video_settings) {
              const setting = this.store.selectedSize.video_settings;
              if ((duration >= setting.video_min_duration) && (duration <= setting.video_max_duration)) {
                this.resetFileUpload();
                this.getFileSrc(file, extension, false, {width: video.videoWidth, height: video.videoHeight, duration});
              } else {
                this.videoDurationError = true;
                this.detectChanges();
              }
            } else {
              this.resetFileUpload();
              this.getFileSrc(file, extension, false, {width: video.videoWidth, height: video.videoHeight, duration});
            }
          };
        } else {
          this.incrementPercentage();
          this.showImageLoad = true;
          this.getFileSrc(file, extension, true);
        }
      } else {
        this.showImageWeightError = true;
      }
    }
  }


  getFileSrc(file, extension, noPreview: boolean = false, videoMetadata?) {
    this.updateVal.emit(noPreview);
    const fileReader: FileReader = new FileReader();
    const initial: any = document.getElementById('initialPreviewIframe');
    fileReader.readAsDataURL(file);
    fileReader.onloadend = (loadEvent: any) => {
      this.data.image = loadEvent.target.result;

      this.saveVideo(this.data.image, extension, (video) => {
        const values = this.globalfunctionality.getModuleValues("creatives");
        values["originalVideo"] = video;
        values['noPreview'] = noPreview;

        if (videoMetadata) {
          values['videoMetadata'] = videoMetadata;
        }

        if (noPreview) {
          values['videoExtension'] = extension;
        }
        this.uploadNewImage = false;
        if (initial != null) {
          initial.style.display = "none";
        }
        sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
        this.selectVideo(video, noPreview);
      });

    };
  }

  selectVideo(video, noPreview) {
    window.scrollTo(0, document.body.scrollHeight);
    this.detectChanges();
    this.stopIncrementPercentage();

    if (!noPreview) {
      const iframe: HTMLElement = document.getElementById("iframe");
      if (iframe != null) {
        iframe.style.display = "block";
      }
      this.triggerUpdatePreview.emit();
    } else {
      this.updateVal.emit(true);
    }

    this.triggerUpdatePreview.emit();
  }

  saveVideo(video, extension, callback) {
    const base64 = video;
    const obj =
      {
        base64: base64,
        type: extension,
        key: 'video'
      };

    this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
      .subscribe(res => {
        const video = res[0]["video"];
        this.adMediaControl.setValue(video);
        callback(video);
      });

  }


  fileChangeListenerHorizontol($event) {
    this.store.showHorizontalImageError = false;
    const image: any = new Image();
    const file: File = $event.target.files[0];

    if (this.creativeService.allowed_types.indexOf(file.type) === -1) {
      let input: any = document.getElementById('horizontal-image');
      input.value = "";
      image.src = "";
      this.imageHorizontalIllegalFiletype = true;
    } else {
      this.imageHorizontalIllegalFiletype = false;
      const myReader: FileReader = new FileReader();
      myReader.readAsDataURL(file);
      myReader.onloadend = (loadEvent: any) => {
        image.src = loadEvent.target.result;
        this.dataHorizontal.image = image.src;
        this.showImageWeightErrorHorizontal = false;

        this.saveImage(image.src, (image) => {
          const module = this.globalfunctionality.getModule("creatives");
          module["module_values"]["horizontalImage"] = image;
          sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
          this.selectImageHorizontal();
          this.stopIncrementPercentageHorizontal();
        });

      };
    }
  }

  selectImageHorizontal() {
    const base64 = this.dataHorizontal.image;
    let croppedWeight = (base64.length / 1.25) / 1024;

    if (croppedWeight <= 150) {
      this.showImageLoadHorizontal = true;
      this.incrementPercentageHorizontalImage();
      const fileType = this.dataHorizontal.image.split(';')[0].split('/')[1];

      const obj =
        {
          base64: base64,
          type: fileType,
          key: 'image'
        };

      this.apiservice.POST(this.store.imageServerUrl, {data: [obj]})
        .subscribe(res => {

          let response = res;
          let image = response[0]["image"];
          this.adMediaHorizontalControl.setValue(image);


          window.scrollTo(0, document.body.scrollHeight);
          this._cd.detectChanges();

          this.stopIncrementPercentageHorizontal();

          this.triggerCheckIfComplete.emit();
        });
    } else {
      this.showImageWeightErrorHorizontal = true;
    }

  }

  incrementPercentageHorizontalImage() {
    this.percentageIncrementorHorizontal = setInterval(() => {
      if (this.loadPercentage < 100) {
        this.loadPercentageHorizontal += 5;
      }
      this.detectChanges();
    }, 500);
  }

  // Used for impact flow 2nd image upload
  stopIncrementPercentageHorizontal() {
    this.loadPercentageHorizontal = 100;
    this.detectChanges();
    setTimeout(() => {
      this.showImageLoadHorizontal = false;
      clearInterval(this.percentageIncrementorHorizontal);
      this.loadPercentageHorizontal = 0;
      this.detectChanges();
    }, 2000);
  }

  translateOption(){
    switch (this.optimizationOptionValue) {
      case 'sun': return 'sol';
      case 'rain': return 'regn';
      case 'cloud': return 'moln';
      case 'snow': return 'snö';
    }
  }

  toggleWeatherOption(init: boolean){
    this.adMediaControl.enabled && !init ? this.adMediaControl.disable() : this.adMediaControl.enable();
    const replacedControlKey = this.optimizationControlKey.replace('imageSrc', 'clickLink');
    const clickLinkControl: FormControl = this.faceGroup.controls[replacedControlKey] as FormControl;
    this.adMediaControl.enabled ? clickLinkControl.enable() : clickLinkControl.disable();

    if(this.adMediaControl.disabled) {
      const libId = this.campaignService.structure.get('config').get('weatherLibId').value;
      this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${libId}`)
        .subscribe((res: ResponseBodyModel) => {
          const rows = res.data['rows'];
          const weather = this.optimizationControlKey.split('.')[0];
          const weatherMapping = this.weatherOptions.find(elm => elm.option === weather).mapping;
          const removeIds = [];
          weatherMapping.forEach(elm => {
            if(rows.find(row => row.name === elm)){
              removeIds.push(rows.find(row => row.name === elm)['id']);
            }
          });

          const getRequests = [];
          removeIds.forEach(rowId => {
            getRequests.push(
              {
                rowId: rowId,
                request: this.apiservice.getJSON(this.store.apiURL + `/ACMRowServlet?acmLibId=${libId}&rowId=${rowId}`)
              }
            );
          });
          forkJoin(getRequests.reduce((prev, cur) => {prev.push(cur.request); return prev;},[])).subscribe(res => {
            const putRequests = [];
            getRequests.forEach((req, index) => {
              // set cell value as nul
              if(res[index]['data'].cells.find(elm => elm.columnName === this.optimizationControlKey.replace(`${weather}.`, ''))){
                res[index]['data'].cells.find(elm => elm.type === 'image')['value'] = null;
                res[index]['data'].cells.find(elm => elm.type === 'clickLink')['value'] = null;
              }
              putRequests.push(
                {
                  acmLibId: libId,
                  acmRowId: req.rowId,
                  rowId: req.rowId,
                  name: req.name,
                  cells: res[index]['data'].cells
                }
              );
            });
            const putObj = {
              acmLibId: this.campaignService.structure.get('config').get('weatherLibId').value,
              rowArray: putRequests
            };
            this.apiservice.putJSON(this.store.apiURL + '/ACMRowServlet', putObj).subscribe(res => {
              if (res.responseCode === 200) {
                console.log(res);
              }
            });
          });

        });
    }
  }

}
