import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {StoreService} from "../../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../../services/globalfunctionality.service";
import {FormArray, FormControl} from "@angular/forms";
import {CampaignService} from "../../../../../campaign.service";
import {MatTableDataSource} from "@angular/material/table";
import {ACMUpdateCampaignRequest} from "../creative-form/feed/category-selector/category-selector.component";
import {ApiService} from "../../../../../../../services/api.service";
import {MatSort} from "@angular/material/sort";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatSelectChange} from "@angular/material/select";
import {TableMetadataModel} from "../../../../../../../models/table-metadata.model";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {CreativemoduleService} from "../../creativemodule.service";


@Component({
  selector: 'app-native-preview',
  templateUrl: 'native-preview.component.html',
  styleUrls: ['native-preview.component.css'],
})

export class NativePreviewComponent implements OnInit, OnChanges, OnDestroy {
  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 5,
      filter: ''
    };
  selectedPreviewIndex: number = 0;
  selectedPreviewSite;
  selectedPreviewScale =
    {
      scale: 100,
      width: 320,
      height: 320,
      value: '320x320'
    };
  subObjective;
  selectedOperatingSystem;
  @Input() adPreviews;
  @Input() dynamic_ad = false;
  @Input() facesArray: FormArray;
  @Input() feedSelectedControl: FormControl;
  @Input() feedOptionControl: FormControl;


  adDimensions =
    [
      {
        name: 'Panorama 320x320',
        value: '320x320',
        width: 320,
        height: 320,
        scale: 100,
        type: 'mobile',
        disabled: false
      },
      {
        name: 'Panorama 980x240',
        value: '980x240',
        width: 980,
        height: 240,
        scale: 50,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Modul 640x320',
        value: '640x320',
        width: 640,
        height: 320,
        scale: 75,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Widescreen 250x600',
        value: '250x600',
        width: 250,
        height: 600,
        scale: 50,
        type: 'desktop',
        disabled: false
      },
      {
        name: 'Insider 300x480',
        value: '300x480',
        width: 300,
        height: 480,
        scale: 75,
        type: 'desktop',
        disabled: false
      },
    ];

  displayedColumns: string[] = ['name', 'selected', 'priority'];
  priorities =
    [
      {name: 'Hög', key: 'high', value: 1},
      {name: 'Mellan', key: 'medium', value: 0},
      {name: 'Låg', key: 'low', value: -1}
    ];

  dataSource;
  products;
  selectedProducts;

  @ViewChild(MatSort) sort: MatSort;

  showPagination: boolean = false;
  orginalData: any;

  subscriptions: Subscription[] = [];

  constructor(
    public store: StoreService,
    private globalfunctionality: GlobalfunctionalityService,
    public campaignService: CampaignService,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private _creativeService: CreativemoduleService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.dataSource = new MatTableDataSource();
    iconRegistry.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/info.svg'));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(): void {
    if (this.adPreviews) {
      this.selectedPreviewSite = this.adPreviews[0];
    }
  }

  ngOnInit(): void {

    if(this.feedOptionControl){
      this.feedOptionControl.valueChanges.subscribe(change => {
        if(change){
          if(this.products){
            this.dataSource = new MatTableDataSource([]);
            this.detectChange();
          }
        }
      });
    }

    const objectiveId = this.campaignService.structure.get('objective').value.id;
    if (objectiveId === 6) {
      this.subObjective = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective;
      const deviceValue = this.globalfunctionality.getModuleValues('device');
      const id: number = this.subObjective.id;
      if (id === 2) {
        this.selectedOperatingSystem = deviceValue.selectedOSTargetOption;
      } else if (id === 3) {
        if (deviceValue.selectedDeviceIndex !== 0) {
          const filter = deviceValue.selectedTargetOption === 'Desktops & Laptops' ? 'desktop' : 'mobile';
          this.adDimensions.filter(elm => elm.type !== filter).forEach(elm => elm.disabled = true);
          this.selectedPreviewScale = this.adDimensions.filter(elm => elm.type === filter)[0];
        }
        this.handleFeed();
      }
    }else if(objectiveId === 2){

    }
  }

  /**
   * Obtain text depending on product selection
   */
  obtainText(): string {
    if (this.products) {
      const allSelected: boolean = this.products.length === this.products.filter(prod => prod.selected).length;
      return allSelected ? 'Ta bort alla' : 'Välj alla';
    }
  }

  /**
   * Obtain value depending on product selection
   */
  obtainAllCheckValue(): boolean {
    if (this.products) {
      const allSelected: boolean = this.products.length === this.products.filter(prod => prod.selected).length;
      return allSelected;
    }
  }

  getSelectedProductsLength(): number {
    return this.products.filter(prod => prod.selected).length;
  }

  toggleAllProducts(e: MatCheckboxChange) {
    this.products.forEach(prod => {
      prod.selected = e.checked;
    });

    this.toggleProductSelection();
  }


  handleFeed() {
    const demoPlaceholder = '<div id=\'p20101657_ad_div\'  style=\'padding:0px;margin-left:auto;margin-right:auto;margin-top:0px;margin-bottom:0px;\'><script>var p20101657dimensions = {width:\'100%\',height:\'100%\'}; p20101657Click = \'${CLICK_URL}\';var p20101657Geo = \'#{se-generic-geo}\';var p20101657ArtikelId = \'#{se-generic-articleid}\';var p20101657AdDiv = document.getElementById(\'p20101657_ad_div\');var p20101657NewScript = document.createElement(\'script\');p20101657NewScript.src = \'https://se5.pliing.com/pas/gs?app=271894_d1_715593&contid=p20101657_ad_div&id=1&no=\'+Math.floor((Math.random() * 10000) + 1) + \'&kwd=geo719_\' + p20101657getGlciKeyword();p20101657AdDiv.appendChild(p20101657NewScript);function p20101657getGlciKeyword() {var keyword = \'\';var keywords = p20101657Geo.split(\',\');for(var i = 0; i <keywords.length; i++) {if(keywords[i].indexOf(\'glci_\') > -1) {keyword = keywords[i];break;}}return keyword;}</script></div>';
    this.detectChange();
    const iframe: any = document.getElementById('adPreviewIframe');
    const url = this.getGeneratedPageURL(demoPlaceholder);
    if (iframe !== null) {
      iframe.src = url;
    }

    this.subscriptions.push(this.globalfunctionality.updateACMPreview
      .subscribe(update => {
        this._creativeService.categorySelectionLoadSubject.next(false);
        if (update) {
          if (this.feedSelectedControl.value) {
            this.showPagination = false;
            this.tableMetadata.page = 0;
            this.detectChange();
            this.products = this.feedSelectedControl.value.products;
            this.orginalData = this.products;

            if (this.products) {
              this.decideItemsToBeShowed();
              this.dataSource.sort = this.sort;
              this.showPagination = true;
            } else {
              this.dataSource = new MatTableDataSource([]);
            }
            this.detectChange();
            this.updatePreview();
          } else {
            this.dataSource = new MatTableDataSource([]);
            this.detectChange();
            this.updatePreview();
          }

        } else {
          if (this.feedOptionControl.value) {
            if (this.feedOptionControl.value.ad) {
              this.updatePreview();
            }
          }
        }
      }));

    this.subscriptions.push(this.globalfunctionality.updateACMPreviewOnly.subscribe(update => {
      if (update) {
        this.updatePreview();
      }
    }));
  }

  changePriority() {
    const obj: ACMUpdateCampaignRequest =
      {
        ACMCampaignId: this.feedOptionControl.value.campaign_id,
        objects: this.products.map(product => {
          return {
            id: product.id,
            prioPoints: product.priority
          };
        })
      };

    this.apiservice.postJSON(this.store.apiURL + `/ACMCatServlet`, obj)
      .subscribe(res => {
        if (res.responseCode === 201) {
          this.updatePreview();
        }
      });

  }


  updatePreview() {
    const iframe: any = document.getElementById('adPreviewIframe');
    if (this.feedOptionControl.value) {
      const url = this.getGeneratedPageURL(this.feedOptionControl.value.ad.demoTag);
      if (iframe !== null) {
        iframe.src = url;
      }
    }
  }


  getGeneratedPageURL = (html) => {

    const getBlobURL = (code, type) => {
      const blob = new Blob([code], {type});
      return URL.createObjectURL(blob);
    };

    const source = `
    <html>
      <head>
        
      </head>
      <body>
        ${html || ''}
      </body>
    </html>
  `;
    return getBlobURL(source, 'text/html');
  }


  toggleProductSelection() {
    const filters = this.products.filter(product => !product.selected).map(product => {
      return {
        name: 'idFromInFeedSupplier',
        title: 'Id',
        value: product.idFromSupplier,
        id: 6
      };
    });

    const obj: ACMUpdateCampaignRequest =
      {
        ACMCampaignId: this.feedOptionControl.value.campaign_id,
        categories: this.feedSelectedControl.value.categories,
        filters: filters
      };

    this.apiservice.postJSON(this.store.apiURL + `/ACMCatServlet`, obj)
      .subscribe(res => {
        if (res.responseCode === 201) {
          this.updatePreview();
        }
      });
  }

  applyFilter(event: Event) {
    this.showPagination = false;
    this.detectChange();
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableMetadata.page = 0;

    if (filterValue !== '') {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.tableMetadata.length = this.dataSource.filteredData.length;
    } else {
      this.tableMetadata.length = this.products.length;
    }
    this.showPagination = true;
    this.detectChange();
  }

  detectChange() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.decideItemsToBeShowed();
  }

  decideItemsToBeShowed() {
    const multiplier: number = (this.tableMetadata.page + 1);
    const amountToFetch: number = (this.tableMetadata.page_size * multiplier) - 1;
    const startNum: number = this.tableMetadata.page === 0 ? 0 : this.tableMetadata.page_size * this.tableMetadata.page;
    this.tableMetadata.length = this.products.length;
    const displayElements = [];
    for (let i = startNum; i <= amountToFetch; i++) {
      if (this.products[i]) {
        displayElements.push(this.products[i]);
      }
    }
    this.dataSource = new MatTableDataSource(displayElements);
  }

  compareValue(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.value === o2.value;
    }
  }
}
