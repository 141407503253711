import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {ApiService} from "../../../../services/api.service";
import {FormControl, Validators} from "@angular/forms";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";

@Component({
  selector: 'app-user-invite-dialog',
  templateUrl: './user-invite-dialog.component.html',
  styleUrls: ['./user-invite-dialog.component.css']
})
export class UserInviteDialogComponent implements OnInit {

  showError = false;

  inviteObj =
    [
      {"email": "", "success": false}
    ]

  constructor(
    public dialogRef: MatDialogRef<UserInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService) { }

  ngOnInit() {

  }

  addMore(){
    let obj = {
      email: "",
      success: false
    };

    this.inviteObj.push(obj);

  }

  removeEntry(index){
    this.inviteObj.splice(index, 1);

  }

  sendInvite(){

    let send = true;
    let regex = new RegExp(/(?:[a-ö0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-ö0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-ö0-9](?:[a-ö0-9-]*[a-ö0-9])?\.)+[a-ö0-9](?:[a-ö0-9-]*[a-ö0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

    this.inviteObj.forEach(elm => {
      if(!regex.test(elm.email)){
        send = false;
      }
    });

    if(send) {
      let arr = [];
      this.inviteObj.forEach(elm => {
        if(!elm.success) {
          arr.push(elm.email.toLowerCase().trim());
        }
      });




      let obj =
        {
          emails: arr,
          companyId: this.store.user.companies[0].id
        };

      this.apiservice.postJSON(this.store.apiURL + "/UserInviteServlet", obj)
        .subscribe(res => {


          if (res.responseCode === 200 && res.data.length < 1) {
            this.dialogRef.close(true);
          } else {
            res.data.forEach(elm => {
              this.inviteObj.forEach((email, index) => {
                if(elm === email.email){
                  email["error"] = true;
                }else{
                  email.success = true;
                  this.removeEntry(index);
                }
              });
            });
          }
        });
    }else{
      this.showError = true;
    }

  }

  cancel(){
    this.dialogRef.close(false);
  }

}
