import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-news-preview-dialog',
  templateUrl: './news-preview-dialog.component.html',
  styleUrls: ['./news-preview-dialog.component.css']
})
export class NewsPreviewDialogComponent{

  constructor(
    public dialogRef: MatDialogRef<NewsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }


  close() {
    this.dialogRef.close();
  }


}
