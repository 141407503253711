import { Component, OnInit } from '@angular/core';
import {StoreService} from "../../services/store.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.css']
})
export class StartpageComponent implements OnInit {

  constructor(private _meta: Meta,
              private _title: Title) { }

  ngOnInit() {

    this._meta.updateTag({name: 'description', content: 'Du når 8 av 10 svenskar, varje vecka | Du väljer vilka som ska se din annons och kan följa dina kampanjer i realtid.'});
    this._title.setTitle('Schibsted AdMarket | Annonsera på Sveriges största sajter');
    // check when routed to
    window.scroll(0,0);
  }

  register(){
    window.location.href = 'https://newadmarketbeta.schibsted.se/';
  }

}
