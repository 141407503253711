import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import { MatDialog } from "@angular/material/dialog";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-target-indicator',
  templateUrl: './target-indicator.component.html',
  styleUrls: ['./target-indicator.component.css']
})
export class TargetIndicatorComponent implements OnInit, OnDestroy {
  /*OnDestroy*/
  @Output() resetPageComplete = new EventEmitter();

  sites = [
    {text: "Aftonbladet", value: 'aftonbladet.png'},
    {text: "Blocket", value: 'blocket.png'},
    {text: "Omni", value: 'omni.png'},
    {text: "SvD", value: 'svd.png'},
    {text: "Tv.nu", value: 'tv.png'},
    {text: "Klart.se", value: 'klart.png'}
  ];

  check = true;
  divBars = Array(25);

  demographicValues;
  interestValues;
  geoValues;

  timeout;

  audienceObj;

  targetingData;

  position;


  targetDataSubscription: Subscription;
  geoChangeSubscription: Subscription;
  demographyChangeSubscription: Subscription;
  interestChangeSubscription: Subscription;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              public dialog: MatDialog) {
  }






  ngOnDestroy(){
    if(this.targetDataSubscription !== undefined){
      this.targetDataSubscription.unsubscribe();
    }
    if(this.geoChangeSubscription !== undefined){
      this.geoChangeSubscription.unsubscribe();
    }
    if(this.demographyChangeSubscription !== undefined){
      this.demographyChangeSubscription.unsubscribe();
    }
    if(this.interestChangeSubscription !== undefined){
      this.interestChangeSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    this.targetDataSubscription = this.globalfunctionality.targetDataIntialized.subscribe((dataChanged: boolean) => {

      if (dataChanged && this.check) {
        this.check = false;
        this.demographicValues = this.globalfunctionality.getModuleValues("demographic");
        this.interestValues = this.globalfunctionality.getModuleValues("interestanddemographic");
        this.geoValues = this.globalfunctionality.getModuleValues("geo");

      }

    });


    this.geoChangeSubscription = this.globalfunctionality.geoChange.subscribe((geoChange: boolean) => {
      if (geoChange) {
        this.geoValues = this.globalfunctionality.getModuleValues("geo");
        this.calculateAudience();
      }
    });

    this.demographyChangeSubscription = this.globalfunctionality.demographyChange.subscribe((demographyChange: boolean) => {
      if (demographyChange) {
        this.demographicValues = this.globalfunctionality.getModuleValues("demographic");
        this.calculateAudience();
      }
    });

    this.interestChangeSubscription = this.globalfunctionality.interestChange.subscribe((interestChange: boolean) => {
      if (interestChange) {
        this.interestValues = this.globalfunctionality.getModuleValues("interestanddemographic");
        this.calculateAudience();
      }
    });


    this.calculateAudience();

  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  removeGeo() {
    this.globalfunctionality.geoReset.next(true);
  }

  removeInterest() {
    this.globalfunctionality.interestReset.next(true);
  }

  removeDemography() {
    this.globalfunctionality.demographyReset.next(true);
  }

  calculateAudience() {

    this.resetPageComplete.emit();

    /*clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {*/
      let audienceObj = {};


      audienceObj["genders"] = [];
      if (this.store.forecast.genders.length > 0) {
        if (this.store.forecast.genders[0] !== "All") {
          audienceObj['genders'] = this.store.forecast.genders;
        }
      }

      audienceObj["ages"] = [];
      if (this.store.forecast.ages.length > 0) {
        if (this.store.forecast.ages[0] !== "All") {
          audienceObj['ages'] = this.store.forecast.ages;
        }
      }

      if (this.store.forecast.interests.length > 0) {
        audienceObj['interests'] = this.store.forecast.interests;
      } else {
        audienceObj["interests"] = [];
      }

      if (this.store.forecast.geo_locations.length > 0) {

        audienceObj['geo_locations'] = this.store.forecast.geo_locations;
      } else {
        audienceObj["geo_locations"] = [];
      }


      if(this.store.forecast.custom_targets.length > 0){
        audienceObj['custom_targets'] = this.store.forecast.custom_targets;
      }else{
        audienceObj['custom_targets'] = [];
      }


      this.audienceObj = audienceObj;


      let keys = Object.keys(this.audienceObj);

      let elementChosen = false;
      keys.forEach(key => {
        if (this.audienceObj[key].length > 0) {
          elementChosen = true;
        }
      });

      this.apiservice.postJSON(this.store.apiURL + "/AudienceTargetingServlet", this.audienceObj)
        .subscribe(res => {
          if (res.responseCode === 200) {
            this.targetingData = res.data;
            this.calculateInterval(elementChosen);
            this.detectChange();
          }
          this.checkIfComplete();
        });


   /* }, 1000);*/
  }

  calculateInterval(elementChosen) {

    if (!elementChosen) {
      this.position = null;
    }else{
      this.position = this.getIndicatorPosition(this.targetingData.result);
    }

    this.store.reachPosition = this.position;

    if((this.position > 3 && this.position < 23) || this.position === null){
      this.store.showReachWarning = false;
    }else{
      this.store.showReachWarning = true;
    }

    this.globalfunctionality.page2Checker.next(true);

  }

  getIndicatorPosition(val) {

    if (val < 251) {
      return 1;
    }
    else if (val > 250 && val < 751) {
      return 2;
    }
    else if (val > 750 && val < 1000) {
      return 3;
    }
    else if (val > 999 && val < 5001) {
      return 4;
    }
    else if (val > 5000 && val < 15001) {
      return 5;
    }
    else if (val > 15000 && val < 50001) {
      return 6;
    }
    else if (val > 50000 && val < 125001) {
      return 7;
    }
    else if (val > 125000 && val < 200001) {
      return 8;
    }
    else if (val > 200000 && val < 300001) {
      return 9;
    }
    else if (val > 300000 && val < 500001) {
      return 10;
    }
    else if (val > 500000 && val < 625001) {
      return 11;
    }
    else if (val > 625000 && val < 750001) {
      return 12;
    }
    else if (val > 750000 && val < 1000001) {
      return 13;
    }
    else if (val > 1000000 && val < 1300001) {
      return 14;
    }
    else if (val > 1300000 && val < 2000001) {
      return 15;
    }
    else if (val > 2000000 && val < 2700001) {
      return 16;
    }
    else if (val > 2700000 && val < 3400001) {
      return 17;
    }
    else if (val > 3400000 && val < 4100001) {
      return 18;
    }
    else if (val > 4100000 && val < 4800001) {
      return 19;
    }
    else if (val > 4800000 && val < 6000001) {
      return 20;
    }
    else if (val > 6000000 && val < 7200001) {
      return 21;
    }
    else if (val > 7200000) {
      return 22;
    }
  }

  checkIfComplete() {

    const values = this.globalfunctionality.getModuleValues('budget');
    values['targeting_data'] = this.targetingData;
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    this.globalfunctionality.page2Checker.next(true);
  }

}
