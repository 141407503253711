import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-login-form',
  templateUrl: 'login-form.component.html'
})

export class LoginFormComponent implements OnInit{
  @Input() errorMsg: string;
  @Input() translation: any;
  @Output() login: EventEmitter<any> = new EventEmitter();
  @Output() register: EventEmitter<any> = new EventEmitter();
  @Output() forgot: EventEmitter<any> = new EventEmitter();
  @Output() clearError: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }


  handleLogin(isValid: boolean){
    if(isValid) {
      this.login.emit(this.form.value);
    }
  }

  handleRegister(){
    this.register.emit();
  }

  handleForgotPassword(){
    this.forgot.emit();
  }


  resetForm(){
    if(this.errorMsg !== '') {
      this.clearError.emit();
    }
  }

}
