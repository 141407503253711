import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpaces'
})
export class NumberSpacesPipe implements PipeTransform {
  transform(digits: number): string {
    return digits.toString().replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }
}
