import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../../../../../services/api.service";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {CampaignService} from "../../../../../../../campaign.service";
import {forkJoin, Subscription} from "rxjs";
import {ResponseBodyModel} from "../../../../../../../../../models/response-body.model";
import {CreativemoduleService} from "../../../../creativemodule.service";

export class ACMCampaignRequest {
  name: string;
  description?: string;
  catalogId: number;
}

export class ACMUpdateCampaignRequest {
  ACMCampaignId: number;
  categories?: any[];
  filters?: any[];
  objects?: any[];
}

export class ACMAdRequest {
  ACMCampaignId: number;
  name: string;
  format: number;
  libReference: Object;
}

@Component({
  selector: 'app-category-selector',
  styleUrls: ['./category-selector.component.css'],
  templateUrl: './category-selector.component.html'
})

export class CategorySelectorComponent implements OnInit, OnDestroy {

  @Output() triggerProductsSelected: EventEmitter<any> = new EventEmitter();
  @Input() feedSelectedControl: FormControl;
  @Input() feedOptionControl: FormControl;

  categories;
  campaignId: number;

  susbcriptions: Subscription[] = [];

  loadComponent: boolean = true;

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private campaignService: CampaignService,
              private _creativeService: CreativemoduleService) {

  }


  ngOnDestroy(): void {
    this.susbcriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.initialize();
    this.susbcriptions.push(this.feedOptionControl.valueChanges.subscribe(change => {
      this.feedSelectedControl.reset();
      this.initialize();
    }));

    this.feedSelectedControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (value) {
        this._creativeService.categorySelectionLoadSubject.next(true);
        this.updateACMCampaign(value);
      }
    });
  }

  updateACMCampaign(value) {

    const parentCategories = [];
    const addedParents = [];

    value.categories.filter(category => category.allSelected).forEach(category => {
      if (addedParents.indexOf(category.parent) === -1) { // Check if parent category is not added
        parentCategories.push(category);
        addedParents.push(category.id);
      }else{ // If parent is added, ad the category id as an added parent
        addedParents.push(category.id);
      }
    });

    const urls = parentCategories.map(parent => {
      return this.apiservice.getJSON(this.store.apiURL + `/ACMCatalogueServlet?catalogId=${this.feedOptionControl.value.id}&categoryId=${parent.id}`);
    });

    const products = [];

    if (urls.length) {
      forkJoin(urls).subscribe((response) => {
        response.forEach(response => {
          if (response['data']['objects']) {
            response['data']['objects'].forEach(object => {
              if (!products.find(product => product.idFromSupplier === object.idFromSupplier)) {
                object['selected'] = true;
                object['priority'] = 0;
                products.push(object);
              }
            });
          }
        });

        value['products'] = products; // Used for displaying selected products in native-preview.component.ts

        const obj: ACMUpdateCampaignRequest =
          {
            ACMCampaignId: this.feedOptionControl.value.campaign_id,
            categories: value.categories
          };

        this.apiservice.getJSON(this.store.apiURL + `/ACMCatServlet?ACMCampaignId=${this.feedOptionControl.value.campaign_id}`)
          .subscribe(res => {
            if (res.responseCode === 200) {
              const resetCategories = res.data.categories.filter(elm => elm.allSelected).map(elm => {
                return {
                  idCategory: elm.idCatalogCategory,
                  id: elm.id,
                  allSelected: false
                };
              });
              if (resetCategories.length) {
                const resetObj: ACMUpdateCampaignRequest =
                  {
                    ACMCampaignId: this.feedOptionControl.value.campaign_id,
                    categories: resetCategories
                  };

                this.apiservice.postJSON(this.store.apiURL + `/ACMCatServlet`, resetObj)
                  .subscribe(res => {
                    if (res.responseCode === 201) {
                      this.updateCategories(obj);
                    }
                  });
              } else {
                this.updateCategories(obj);
              }
            }
          });
      });
    } else {
      this.feedSelectedControl.setValue(null);
      this.apiservice.getJSON(this.store.apiURL + `/ACMCatServlet?ACMCampaignId=${this.feedOptionControl.value.campaign_id}`)
        .subscribe(res => {
          if (res.responseCode === 200) {
            const resetCategories = res.data.categories.filter(elm => elm.allSelected).map(elm => {
              return {
                idCategory: elm.idCatalogCategory,
                id: elm.id,
                allSelected: false
              };
            });
            const resetObj: ACMUpdateCampaignRequest =
              {
                ACMCampaignId: this.feedOptionControl.value.campaign_id,
                categories: resetCategories
              };

            this.apiservice.postJSON(this.store.apiURL + `/ACMCatServlet`, resetObj)
              .subscribe(res => {
                if (res.responseCode === 201) {
                  this.globalfunctionality.updateACMPreview.next(true);
                }
              });
          }
        });
    }

  }

  updateCategories(obj) {
    this.apiservice.postJSON(this.store.apiURL + `/ACMCatServlet`, obj)
      .subscribe(res => {
        if (res.responseCode === 201) {
          this.globalfunctionality.updateACMPreview.next(true);
        }
      });
  }

  initialize() {
    this.loadComponent = true;

    if (!this.feedSelectedControl.value) {
      const campaignObj: ACMCampaignRequest =
        {
          name: `${this.campaignService.structure.get('name').value}_${this.store.selectedSize.size}_${this.store.selectedSize.set.id}`.replace(/\s/g, ''),
          catalogId: this.feedOptionControl.value.id,
          description: 'AdMarket',
        };

      this.apiservice.postJSON(this.store.apiURL + `/ACMCampaigns`, campaignObj)
        .subscribe(res => {
          if (res.responseCode === 201) {
            const data = res.data;
            this.feedOptionControl.value['campaign_id'] = data.campaignId;
            this.createACMAd(data.campaignId, campaignObj);
          }
        });
    } else {
      this.getCatalogById(true);
    }
  }

  createACMAd(campaignId, campaignObj) {
    const adObj: ACMAdRequest =
      {
        ACMCampaignId: campaignId,
        name: campaignObj.name,
        format: this.store.ACMFeedTransformerFormatId,
        libReference:
          {
            libId: this._creativeService.ACMLibrary.id,
            rowId: this._creativeService.ACMRowId
          }
      };

    this.apiservice.postJSON(this.store.apiURL + `/ACMAdServlet`, adObj)
      .subscribe(res => {
        if (res.responseCode === 201) {
          this.feedOptionControl.value['ad'] = res.data;
          this.globalfunctionality.updateACMPreview.next(false);
          this.getCatalogById();
        }
      });
  }

  getCatalogById(updatePreview: boolean = false) {
    this.apiservice.getJSON(this.store.apiURL + `/ACMCatalogueServlet?catalogId=${this.feedOptionControl.value.id}`)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.categories = res.data.categories;
          this.loadComponent = false;
          this._cd.detectChanges();
          if (updatePreview) {
            this.globalfunctionality.updateACMPreview.next(true);
          }
        }
      });
  }
}















