import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";

@Component({
  selector: 'app-creative-advertiser',
  templateUrl: 'advertiser.component.html'
})

export class AdvertiserComponent {

  @Input() adAdvertiserNameDT;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService){

  }


  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

}
