import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import { MatDialog } from "@angular/material/dialog";
import {ProductDescriptionDialogComponent} from "./product-description-dialog/product-description-dialog";
import {Subscription} from "rxjs";
import {CampaignService} from "../../../campaign.service";
import {TargetCategorySelectionDialogComponent} from "./target-category-selection-dialog/target-category-selection-dialog.component";

declare var $: any;

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.css']
})
export class ContextComponent implements OnInit, OnDestroy {

  selectedContextIndex;
  data;
  contexts;
  step;

  productType;
  selectedOption;


  adtypeSubscription: Subscription;
  showMoreSites: boolean = true;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private dialog: MatDialog,
              public campaignService: CampaignService,
  ) {

  }

  ngOnDestroy(): void {
    if (this.adtypeSubscription !== undefined) {
      this.adtypeSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    this.adtypeSubscription = this.globalfunctionality.adtypeChange.subscribe((changed: boolean) => {
      if (changed) {
        this.selectedContextIndex = undefined;
        this.getProductType();
        this.getContext(false, true);
        this.globalfunctionality.adtypeChange.next(false);
      }
    });

    const contextValue = this.globalfunctionality.getModuleValues("context");
    this.getProductType();

    if (contextValue) {
      if (!contextValue['selectedOption']) {
        this.selectedOption = '0';
      } else {
        this.selectedOption = contextValue['selectedOption'];
      }
    }

    // Enable video format when specific site is chosen
    if (this.selectedOption === '0') {
      this.globalfunctionality.disableVideoAdType.next(false);
    }


    this.detectChange();

    if (contextValue) {
      if (contextValue.contexts) {
        this.getContext(contextValue.contexts.length > 0);
      } else {
        this.getContext();
      }

    }

  }

  optionChanged() {

    const values = this.globalfunctionality.getModuleValues('context');
    values['contexts'] = []; // Clear select contexts
    values['selectedOption'] = this.selectedOption;

    // Clear adforsize object so that formats resets when user changed context choice
    const adforsize = this.globalfunctionality.getModuleValues("adforsize");
    adforsize['selected'] = undefined;

    this.store.loadPage = true;

    if (parseInt(this.selectedOption) === 0) {
      this.globalfunctionality.disableVideoAdType.next(false);
    } else if (parseInt(this.selectedOption) === 1) {
      this.globalfunctionality.disableVideoAdType.next(true);
    }

    // Reset objective object
    if (this.productType.toLowerCase() === 'bannerannons') {
      if (parseInt(this.selectedOption) === 1) {

        // Clear selected context if any was selected
        this.selectedContextIndex = undefined;

        // If RON selected remove sites not supported
        this.contexts = this.contexts.filter(elm => elm.context.toLowerCase() !== 'nwt' &&
          elm.context.toLowerCase() !== 'sla' && elm.context.toLowerCase() !== 'prisjakt' && elm.context.toLowerCase() !== 'bytbil' && elm.context.toLowerCase() !== 'na');

        // Get RON modules
        this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.campaignService.structure.get('company').value.id)
          .subscribe(res => {
            if (res.responseCode === 200) {
              let auxObjective = res.data.filter(elm => elm.id === 7)[0];
              this.campaignService.structure.get('objective').setValue(auxObjective);
              sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));


              this.apiservice.getJSON(this.store.apiURL + "/ModulesServlet?values=" + JSON.stringify(auxObjective.modules))
                .subscribe(res => {
                  res.forEach((elm) => {
                    elm.param = JSON.parse(elm.param);
                    elm.module_values = {};
                  });


                  this.copyModule(res);

                  this.campaignService.buildFlow();
                  this.store.loadPage = false;
                  this.globalfunctionality.detectChange.next(true);
                  this.checkIfComplete();
                });
            }
          });
      } else {
        this.setContextFlow(true);
      }
    } else if (this.productType.toLowerCase() === 'specialannons') {
      if (parseInt(this.selectedOption) === 1) {
        this.setContextFlow(false).then((complete) => {
          if (complete) {
            const contextIndex = this.contexts.findIndex(elm => elm.context.toLowerCase() === 'cross');
            this.activateContext(this.contexts.filter(elm => elm.context.toLowerCase() === 'cross')[0], contextIndex);
          }
        });
      } else {
        // Reset
        this.getContext();
      }
      this.store.loadPage = false;
    }
  }


  async setContextFlow(build?) {
    const promise = new Promise(resolve => {
      const company_id = this.campaignService.structure.get('company').value.id;
      this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + company_id)
        .subscribe(res => {
          if (res.responseCode === 200) {
            const auxObjective = res.data.find(elm => elm.id === 1);


            this.campaignService.structure.get('objective').setValue(auxObjective);
            sessionStorage.setItem("campaign_structure", JSON.stringify(this.campaignService.structure.value));

            this.apiservice.getJSON(this.store.apiURL + "/ModulesServlet?values=" + JSON.stringify(auxObjective.modules))
              .subscribe(res => {
                res.forEach((elm) => {
                  elm.param = JSON.parse(elm.param);
                  elm.module_values = {};
                });

                this.copyModule(res);

                if (build) {
                  this.campaignService.buildFlow();
                  this.getContext();
                }
                resolve(true);
              });
          }
        });
    });
    const res = await promise;
    return res;
  }

  /**
   *   // Copy adtype and context module to new objective
   * @param res
   */
  copyModule(res) {
    this.store.modules.forEach(oldModule => {
      res.forEach(newModule => {
        if (oldModule.type === newModule.type) {
          newModule['module_values'] = oldModule['module_values'];
        }
      });
    });
    this.store.modules = res;
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  readMore(site) {
    this.dialog.open(ProductDescriptionDialogComponent,
      {
        panelClass: 'modal-size-md',
        data: {site: site.context.toLowerCase()}
      }
    );
  }


  /**
   * Disabled contexts (sites) which do not have the same productType as user has selected, for instance takeover product does not support all sites as banner annons
   */
  disableContextAndCountSubContexts() {
    this.contexts.forEach(elm => {

      let counter: number = 0;

      if (this.productType === 'SpecialAnnons') {
        elm['inactive'] = !elm.mobileSpecialAd && !elm.desktopSpecialAd;
      }

      if (this.productType === 'BannerAnnons') {
        elm['inactive'] = !elm.mobileBannerAd && !elm.desktopBannerAd;
      }

      if (this.productType === 'VideoAnnons') {
        elm['inactive'] = !elm.mobileVideoAd && !elm.desktopVideoAd;
      }

      if (!elm['inactive'] && !elm.targeting_booking) {
        elm['sub_contexts'].forEach(elm => {
          if (elm.product_type === this.productType) {
            counter += 1;
          }
        });
      } else if (elm.targeting_booking) {
        counter = elm.all_category_targets.length - 1;
      }

      elm['amountOfProducts'] = counter;

    });


    // Sort contexts alphabetically
    this.contexts = this.contexts.sort((a, b) => {
      return (+a.inactive) - (+b.inactive) || a.context.localeCompare(b.context);
    });

    this.store.loadPage = false;
    this.detectChange();
  }


  getProductType() {
    const adtypeValue = this.globalfunctionality.getModuleValues('adtype');
    if (adtypeValue) {
      switch (adtypeValue['selectedOption']) {
        case '0':
          this.productType = 'BannerAnnons';
          break;
        case '1':
          this.productType = 'VideoAnnons';
          break;
        case '2':
          this.productType = 'SpecialAnnons';
          break;
      }
    }
  }

  activateContext(context, index?) {

    if (index !== undefined) {
      this.selectedContextIndex = index.toString();

      if (context.sub_contexts.length > 0) {
        this.contexts.forEach(context => {
          context['selected'] = false;
          context['active'] = false;
          context['indeterminate'] = false;
        });
      }

      this.contexts[this.selectedContextIndex]['active'] = true;
      this.contexts[this.selectedContextIndex]['selected'] = true;
      const values = this.globalfunctionality.getModuleValues("adforsize");

      /*START TARGETING BOOKED SITE*/
      // If targeting booked site, mark all sub context as selected, all products should be used.
      if (this.contexts[this.selectedContextIndex].targeting_booking) {
        this.contexts[this.selectedContextIndex]['sub_contexts'].forEach(elm => {
          elm['selected'] = true;
          elm['products'].forEach(elm => elm['selected'] = true);
        });
        // Clear adforsize object so that formats resets when user changed context choice
        values['selected'] = undefined;

      }

      // Reset dynamic ad var if it has been set
      values['dynamic_ad'] = undefined;
      /*END TARGETING BOOKED SITE*/
    }

    if (context.targeting_booking) {
      this.selectAllTargetingContext(this.contexts[this.selectedContextIndex]);
    } else {
      if (parseInt(this.selectedOption) === 0 || this.productType.toLowerCase() === 'specialannons') { // Takeover should run same logic as usual when picking "ALLA SAJTER", unlike Banner annons which changes objective to achieve RON requirments
        this.selectAll(this.contexts[this.selectedContextIndex]);
      }
    }
  }


  /**
   * Get contexts
   * company as parameter
   * takeover=true if its the takeover contexts that should be returned
   */
  getContext(replace?, triggerOptionChange?) {


    this.store.loadPage = true;

    this.apiservice.getJSON(this.store.apiURL + "/ContextServlet?brand=" + this.campaignService.structure.get('brand').get('id').value)
      .subscribe((res) => {
        if (res.responseCode === 200) {
          this.data = res.data;
          this.contexts = [];

          this.data.forEach(data => {
            data.contexts.forEach(elm => {
              elm['show'] = elm.context.toLowerCase() !== 'ron';

              // Check if context (site) exists in the array of context
              let push = true;
              this.contexts.forEach((site) => {
                site['active'] = false;
                site['sub_contexts'] = [];
                if (elm.context === site.context) { // If site already exists do not add it
                  push = false;
                  site.products = site.products.concat(elm.products);
                }
              });
              if (push) {
                this.contexts.push(elm);
              }
            });
          });


          this.contexts.forEach((elm) => {
            let obj = {};
            elm.products.forEach((product) => {
              this.setContextAdType(elm, product);
              this.setContextCustomTargets(elm, product);
              obj = this.mergeProductsPerContext(elm, product, obj);
            });
            // Place merged object as context sub_contexts, this object will now contain all products for a specific site (mobile, desktop, all)
            Object.keys(obj).forEach(key => {
              elm['sub_contexts'].push(obj[key]);
            });
            delete elm['products'];
          });

          this.removeCustomTargetsFromProductLevel();
          this.sortContext();

          // If RON has been selected
          if (parseInt(this.selectedOption) === 1) {
            this.optionChanged();
          }

          this.disableContextAndCountSubContexts();
          this.setContextDefault(replace);
        }

      });
  }

  /**
   * Backend returns custom targets on each product that has that option instead of returning it on site level
   * Remove custom targets from product level to avoid unneccesary data being transferred
   */
  removeCustomTargetsFromProductLevel() {
    this.contexts.filter(site => site.targeting_booking).forEach(site => {
      site.sub_contexts.forEach(sub => {
        sub.products.forEach(prod => {
          delete prod['custom_targets'];
        });
      });
    });
  }

  /**
   * Create new object which will contain all products for specific context, backend returns products for same site in different objects,
   * this function merges the products per context
   * @param context
   * @param product
   * @param obj
   */
  mergeProductsPerContext(context, product, obj) {
    const contextProductType = product.product_type;
    if (contextProductType && this.productType) {

      let remove: boolean = contextProductType.toUpperCase() !== this.productType.toUpperCase();

      if (contextProductType.toUpperCase() === 'RESPONSIVANNONS' && this.productType.toUpperCase() === 'BANNERANNONS') {
        remove = false;
      }

      // Remove unwanted products
      if (remove) {
        context.products = context.products.filter(prodAux => prodAux.id_products !== product.id_products);
      } else {
        if (!obj[product.subContext]) {
          obj[product.subContext] =
            {
              name: product.subContext,
              products: [product],
              product_type: product.product_type,
              local_site: product.subContextType === 'LocaleCategory'
            };
        } else {
          obj[product.subContext]['products'].push(product);
          if (contextProductType === this.productType) {
            obj[product.subContext]['product_type'] = this.productType;
          }
        }
      }
    }

    if (context.targeting_booking) {
      context.sub_contexts.forEach(subcontext => {
        subcontext.products.forEach(product => {
          delete product['custom_targets'];
        });
      });
    }

    return obj;
  }

  /**
   * Some contexts (sites) is booked with targeting and not by different products.
   * Check if particular context (site) hasBookingTargets = booked by target
   * If context (site) has booking targets in the product extract those targets to context level so it can be displayed and used in the UI
   * @param context
   * @param product
   */
  setContextCustomTargets(context, product) {
    // If site has no category targets added, check if custom targets exists for this particular site, in that case retrieve the category targets from one of the sites products.
    // All category targets will be the same for the different products within the site
    if (!context['all_category_targets']) {
      if (product.hasBookingTargets) {

        product.custom_targets =  product.custom_targets.sort((a, b) => {
          return a.target_option.localeCompare(b.target_option);
        });

        product.custom_targets.forEach(context => context['name'] = context['target_option'])
        product.custom_targets .unshift({name: 'Alla'});
        
        context['all_category_targets'] = product.custom_targets;
        context['amountOfProducts'] = product.custom_targets.length - 1;
        context['targeting_booking'] = true;
      }
    }
  }

  /**
   * Extract product information for particular context (site)
   * Used for displaying UI elements
   * @param context
   * @param product
   */
  setContextAdType(context, product) {

    const mobile: boolean = product.target_device.toUpperCase() === 'MOBILE';
    const desktop: boolean = product.target_device.toUpperCase() === 'DESKTOP';

    // If one product has mobile/desktop as target device set that the context supports that device, that is why the IF-Statement is needed, so it does not get overriden with the latest value
    if (mobile) {
      if (!context['mobile']) {
        context['mobile'] = mobile;
      }
      if (!context['mobileBannerAd']) {
        context['mobileBannerAd'] = product.product_type === 'BannerAnnons';
      }
      if (!context['mobileSpecialAd']) {
        context['mobileSpecialAd'] = product.product_type === 'SpecialAnnons';
      }
      if (!context['mobileVideoAd']) {
        context['mobileVideoAd'] = product.product_type === 'VideoAnnons';
      }
    }

    if (desktop) {
      if (!context['desktop']) {
        context['desktop'] = desktop;
      }
      if (!context['desktopBannerAd']) {
        context['desktopBannerAd'] = product.product_type === 'BannerAnnons';
      }
      if (!context['desktopSpecialAd']) {
        context['desktopSpecialAd'] = product.product_type === 'SpecialAnnons';
      }
      if (!context['desktopVideoAd']) {
        context['desktopVideoAd'] = product.product_type === 'VideoAnnons';
      }
    }

    if (product.subContextType === 'LocaleCategory') {
      context['has_local_site'] = true;
    }

  }

  sortContext() {
    this.contexts.forEach(context => {
      // Sort subcontext alphabetically
      context['sub_contexts'].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      // Place Alla & Startsida at the top of the list
      context['sub_contexts'].forEach((elm, index) => {
        if (elm.name.toLowerCase() === 'alla' || elm.name.toLowerCase() === 'startsida') {
          let auxObj = elm;
          context['sub_contexts'].splice(index, 1);
          context['sub_contexts'].unshift(auxObj);
        }

      });

    });
  }

  /**
   * If user already made a choice and navigates back to the page this function will replace the object with the previous objected so old selected items is visible
   * If no previous choice has been made and only 1 context (site) exists it will auto select that context (site)
   * @param replace
   */
  setContextDefault(replace) {
    const context = this.globalfunctionality.getModuleValues('context');
    if (replace && parseInt(context.selectedOption) === 0) {
      const selected = context.contexts[0];

      const index = this.contexts.findIndex(elm => elm.context === selected.context);
      this.contexts[index] = selected;
      this.selectedContextIndex = index.toString();

    } else {
      // AUTO SELECT CONTEXT IF ONLY 1 CONTEXT EXIST
      if (this.contexts.filter(elm => !elm.inactive).length === 1) {
        const context = this.contexts.filter(elm => !elm.inactive)[0];
        this.activateContext(context, 0);
      }
    }

    this.checkIfComplete();
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  /**
   * Selects a sub-contexts
   * @param product
   * @param context
   * Selecting a product in most cases mean that you actually select multiple products (one for mobile and one for desktop), this is hidden in the UI but in the background we must
   * select all product which has the same subContext name
   */
  selectProduct(product, context) {

    // Clear adforsize object so that formats resets when user changed context choice
    const values = this.globalfunctionality.getModuleValues("adforsize");
    values['selected'] = undefined;

    console.log('Warn users that creatives will be removed, if creatives was added');

    const adType = this.globalfunctionality.getModuleValues("adtype");

    // Clear site choice, meaning not whole site is selected
    context['selected'] = false;
    context.sub_contexts.filter(sub => sub.name.toUpperCase() === 'ALLA' || sub.name.toUpperCase() === 'STARTSIDA').forEach(sub => {
      sub['selected'] = false;
      sub.products.forEach(prod => prod['selected'] = false);
    });

    if (product['selected']) {
      // Check product as selected if product type matches type selected by user
      product.products.filter(elm => elm.product_type.toLowerCase() === adType.selectedType.toLowerCase()).forEach(elm => elm['selected'] = true);
    } else {
      product.products.forEach(elm => elm['selected'] = false);
    }


    this.checkIfComplete();
  }


  /**
   * Function for selecting the all context product, this function will unmark any other sub-contexts if any was selected
   * @param context
   */
  selectAll(context) {
    // Clear adforsize object so that formats resets when user changed context choice
    const values = this.globalfunctionality.getModuleValues("adforsize");
    values['selected'] = undefined;


    if (context["selected"]) {
      context.sub_contexts.forEach(elm => {
        if (elm.name === 'Alla' || elm.name === 'Startsida') {
          elm['selected'] = true;
          elm.products.forEach(elm => elm['selected'] = true);
        }
        if (elm.name !== 'Alla' && elm.name !== 'Startsida') {
          elm['selected'] = false;
          elm.products.forEach(elm => {
            elm['selected'] = false;
            elm['budget'] = undefined;
          });
        }
      });
    } else {
      context.sub_contexts.forEach(elm => {
        if (elm.name === 'Alla' || elm.name === 'Startsida') {
          elm.products.forEach(elm => {
            elm['selected'] = false;
            elm['budget'] = undefined;
          });
        }
      });
    }

    this.detectChange();
    this.checkIfComplete();
  }


  /**
   * Function for building default content_categories object,
   * @param context
   */
  selectAllTargetingContext(context) {

    context.all_category_targets.forEach(cat => {
      cat['selected'] = false;
      cat['indeterminate'] = false;
      cat['selected_categories'] = 0;
    });

    if (context.selected) {
      this.saveContentCategories([]);
    } else {
      context['indeterminate'] = false;
      this.checkIfComplete();
    }
  }

  /**
   * Function for building content_categories object depending on which category is selected
   * @param target, context
   */

  selectProductTargetingContext(category, context) {

    context['selected'] = false;
    category['indeterminate'] = false;
    category['selected_categories'] = 0;

    // Clear previously manipulated checkbox states
    if (category.children) {
      category.children.forEach(cat => {
        cat['selected'] = false;
        cat['indeterminate'] = false;
        cat['amount_selected'] = 0;
        if (cat.children) {
          cat.children.forEach(prod => {
            prod['selected'] = false;
            prod['indeterminate'] = false;
          });
        }
      });
    }

    // Check which categories has been selected, used for deciding "main" checkbox state
    const selectedCategories = context.all_category_targets.filter(elm => elm.id !== undefined && elm.selected).reduce((prev, curr) => {
      prev.push(curr);
      return prev;
    }, []);

    const indeterminateCategories = context.all_category_targets.filter(elm => elm.id !== undefined && elm.indeterminate).reduce((prev, curr) => {
      prev.push(curr);
      return prev;
    }, []);


    // -1 because of the "All" category that is manually added
    // Set inderterminate state if selected subcategories is > 0 and selected subcategories is < amount of total subcategories
    context['selected'] = selectedCategories.length === (context.all_category_targets.length - 1) || selectedCategories.length === 0;
    if (!context['selected']) {
      context['indeterminate'] = selectedCategories.length > 0 || indeterminateCategories.length > 0;
    } else {
      context['indeterminate'] = false;
    }


    this.saveContentCategories(this.calculateWhichCategoriesToBeUsed(context));

  }

  chooseUnderCategory(category, context, index) {

    const dialog = this.dialog.open(TargetCategorySelectionDialogComponent, {
      panelClass: ['modal-size-xl', 'modal-height-control'],
      data: {
        category: category,
        context: context
      }
    });

    dialog.afterClosed().subscribe(newObj => {
      if (newObj) {
        category = newObj.category;
        context = newObj.context;

        const selected_categories: number = category.children.filter(elm => elm.selected || elm.indeterminate).length;
        const selected: boolean = selected_categories === category.children.length;
        const indeterminate: number = category.children.filter(elm => elm.indeterminate).length;
        const semi_selected = selected_categories > 0 ? selected_categories !== category.children.length : false;
        category['indeterminate'] = indeterminate > 0 || semi_selected;
        category['selected'] = selected;
        category['selected_categories'] = selected_categories;

        this.contexts[index]['all_category_targets'] = context.all_category_targets;
        this.saveContentCategories(this.calculateWhichCategoriesToBeUsed(context));
      }
    });
  }


  calculateWhichCategoriesToBeUsed(context) {
    let contentCategories = [];
    context.all_category_targets.filter(category => category.selected || category.indeterminate).forEach(category => {
      if (category.selected) {
        contentCategories.push(category);
      } else {
        category.children.forEach(underCategory => {
          if (underCategory.selected) {
            contentCategories.push(underCategory);
          } else {
            if (underCategory.children) {
              underCategory.children.filter(productCategory => productCategory.selected).forEach(productCategory => {
                contentCategories.push(productCategory);
              });
            }
          }
        });
      }
    });

    return contentCategories;
  }


  saveContentCategories(categories) {
    this.contexts.filter(elm => elm.active)[0]['content_categories'] = categories;

    this.detectChange();
    this.checkIfComplete();
  }


  /**
   * Checks for different parameters to decide wether this particular module is complete
   * This particular one checks if a context has been selected
   */
  checkIfComplete() {
    const contextValue = this.globalfunctionality.getModuleValues("context");

    if (contextValue) {
      let complete: boolean = false;
      let selectedContexts = [];

      // If ROS is selected
      if (parseInt(this.selectedOption) === 0 || this.productType.toLowerCase() === 'specialannons') {
        this.contexts.filter(site => site.active).forEach(site => {
          site['sub_contexts'].filter(sub => sub.selected).forEach(sub => {
            complete = sub.products.filter(prod => prod.selected).length > 0;
          });
          if (site.active) {
            selectedContexts.push(site);
          }
        });

        // If targeting booked context check that atleast 1 category is chosen
        if (selectedContexts.length) {
          const context = selectedContexts[0];
          if (context.targeting_booking) {
            complete = context.selected || context.indeterminate;
          }
        }

      } else if (parseInt(this.selectedOption) === 1 && this.productType.toLowerCase() === 'bannerannons') { // If RON is selected
        complete = true;
      }

      contextValue["isComplete"] = complete;
      contextValue["contexts"] = selectedContexts;
      contextValue["selectedOption"] = this.selectedOption;

      this.store.GUIFlow[2].forEach((elm) => {
        if (elm.type === "context") {
          elm["module_values"].isComplete = complete;
        }

        // Set default value so page validation goes through, these modules are used for blocket searchword
        if (elm.type === "searchword") {
          elm["module_values"].isComplete = true;
        }
        if (elm.type === "addecision") {
          elm["module_values"].isComplete = true;
          elm["module_values"].selected = '0';
        }
      });

      this.globalfunctionality.setModuleValue('context', contextValue);
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

      this.globalfunctionality.page2Checker.next(true);
    }
  }
}
