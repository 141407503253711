import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {Router} from "@angular/router";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {FormControl, Validators} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {debounceTime} from "rxjs/operators";
import {ReadmoreDialogComponent} from "../../../dialogs/readmore-dialog/readmore-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";
import {CampaignService} from "../../../campaign.service";

declare var $: any;
declare var toastr: any;


@Component({
  selector: 'app-existingtagmodule',
  templateUrl: './existingtagmodule.component.html',
  styleUrls: ['./existingtagmodule.component.css']
})


export class ExistingtagmoduleComponent implements OnInit {

  script;
  macroWarning = false;


  adMMSDecisionControl = new FormControl('created', [Validators.required]);
  adMMSControl = new FormControl('', []);

  scriptControl = new FormControl('', [
    Validators.required
  ]);

  checked: boolean = false;

  updateCreatives: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private dialog: MatDialog,
              public creativeService: CreativemoduleService,
              public campaignService: CampaignService
  ) {

  }


  // Refactor
  ngOnInit() {

    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;

    this.store.modules = JSON.parse(sessionStorage.getItem("modules"));
    const moduleValue = this.globalfunctionality.getModuleValues("existingtag");

    this.adMMSDecisionControl.valueChanges.subscribe(option => {
      if (option === 'inputed') {
        this.adMMSControl.setValidators([Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10)]);
      } else {
        this.adMMSControl.setValidators([]);
        this.adMMSControl.setValue('');
      }

      moduleValue.MMSDecision = option;
    });

    this.adMMSControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(code => {
      this.updateMMS();
    });




    if (moduleValue["script"] !== undefined && moduleValue["script"] !== null) {
      this.scriptControl.setValue(moduleValue["script"]);
      this.checked = true;
      this.checkIfComplete();
    } else {
      this.scriptControl.setValue('');
      this.scriptControl.disable();
    }

    if(this.store.productType.toUpperCase() === 'VIDEOANNONS') {
      if (moduleValue['MMSCode']) {
        this.adMMSControl.setValue(moduleValue['MMSCode']);
      }
      if (moduleValue['MMSDecision'] !== undefined) {
        this.adMMSDecisionControl.setValue(moduleValue['MMSDecision']);
      } else {
        moduleValue.MMSDecision = 'created';
      }
    }

    this.checkIfComplete();
  }


  toggleRestriction(e: MatCheckboxChange) {
    this.checked = e.checked;
    if (e.checked) {
      this.scriptControl.enable();
    } else {
      this.scriptControl.disable();
    }
  }

  updateMMS() {
    const moduleValue = this.globalfunctionality.getModuleValues("existingtag");
    moduleValue.MMSCode = this.adMMSControl.value;
    moduleValue.MMSDecision = this.adMMSDecisionControl.value;
  }

  saveScriptForSize() {

    if (this.scriptControl.valid) {

      this.checkIfComplete();

      const existingtag = this.globalfunctionality.getModuleValues('existingtag');

      if (existingtag.isComplete) {
        const adForSize = this.globalfunctionality.getModuleValues('adforsize');
        const adcreation = this.globalfunctionality.getModuleValues('adcreation');

        let formatIndex;
        adForSize.selected.forEach((elm, index) => {
          if (elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format) {
            formatIndex = index;
          }
        });

        adForSize['selected'][formatIndex]['complete'] = true;

        let set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
        set['status'] = 'Pending';

        // If updating creatives set creative statuses
        if (set['complete']) {
          if (this.updateCreatives) {
            set['updated'] = true;
            toastr.info('Material uppdaterat');
          }
        } else {
          set['added'] = true;
          toastr.info('Material sparat');
        }

        // Remove creatives object if exists (user has first created own creative, then changed to third party)
        if (set['creatives']) {
          delete set['creatives'];
        }

        set['adcreation'] = adcreation;
        if (existingtag.acm_script) {
          set['script'] = existingtag;
        } else {
          set['script'] = existingtag;
        }

        set['complete'] = true;
        sessionStorage.setItem('modules', JSON.stringify(this.store.modules));

        this.reset(true);
        this.detectChange();
      }
    }
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  reset(trigger?) {

    $('.modal-backdrop').removeClass('is-active');
    let modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;

    const creationParam = this.globalfunctionality.getModule('adcreation');
    creationParam.param.output = '';

    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = '';
        const id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
      }
    });

    this.globalfunctionality.clearModuleValues();
    this.detectChange();
    if (trigger) {
      this.globalfunctionality.adCreated.next(true);
    }

  }

  readMore(){
    this.dialog.open(ReadmoreDialogComponent, {
      panelClass: 'modal-size-sm'
    });
  }

  checkIfComplete() {
    const moduleValue = this.globalfunctionality.getModuleValues("existingtag");

    if (!this.scriptControl.hasError("required") && this.adMMSDecisionControl.valid && this.adMMSControl.valid) {
      moduleValue.type = this.store.productType.toUpperCase() === 'VIDEOANNONS' ? 'VAST' : 'BANNER';
      moduleValue.script = this.scriptControl.value;
      moduleValue.isComplete = true;

      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if (elm.type === "existingtag") {
          elm["module_values"].isComplete = true;
        }
      });
    } else {
      moduleValue.isComplete = false;
      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if (elm.type === "existingtag") {
          elm["module_values"].isComplete = false;
        }
      });
    }

    this.globalfunctionality.detectChange.next(true);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }


}
