import { NativeDateAdapter } from '@angular/material/core';
import {Inject, Injectable} from "@angular/core";


/**
 * This class is used for setting the first day of week on the mddatepicker 1=monday
 */
@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}
