import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../../../services/store.service";


@Component({
  selector: 'app-product-description-dialog',
  templateUrl: './product-description-dialog.component.html'
})
export class ProductDescriptionDialogComponent implements OnInit {


  site;
  constructor(public dialogRef: MatDialogRef<ProductDescriptionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: StoreService) { }

  ngOnInit() {
    this.site = this.data.site;
  }


  close(){
    this.dialogRef.close();
  }

}
