import {Component, Input, OnInit} from "@angular/core";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";
import {CreativemoduleService} from "../../../creativemodule.service";


@Component({
  selector: 'app-cpc-klart-preview',
  templateUrl: 'cpc-klart-preview.component.html'
})

export class CpcKlartPreviewComponent implements OnInit {

  @Input() data: any;
  @Input() dynamic_ad: boolean;

  showMobile: boolean = false;
  showDesktop: boolean = false;

  constructor(private _creativeService: CreativemoduleService) {
  }

  ngOnInit(): void {
    const obj = this._creativeService.decidePreviewDevice();
    this.showMobile = obj.showMobile || this.dynamic_ad;
    this.showDesktop = obj.showDesktop || this.dynamic_ad;
  }
}
