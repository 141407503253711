import { Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from "@angular/router";

@Injectable()
export class  AuthGuard implements CanLoad, CanActivate{

  constructor(private authService: AuthService){
  }

  canLoad(route: Route) {
    return this.authService.checkPermissions(route.path);
  }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    if(state.url.includes('/dashboard/create')) {
      return this.authService.checkCampaignPage(route.params.page);
    }
    if(state.url.includes('/dashboard/management')) {
      return this.authService.checkManagementRoute(route.routeConfig.path);
    }

  }

}
