import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { ApiService } from '../../../services/api.service';
import { GlobalfunctionalityService } from '../../../services/globalfunctionality.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseBodyModel } from '../../../models/response-body.model';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  FormArray,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroup,
  FormControl
} from '@angular/forms';
import { CreateTargetComponent } from './create-target/create-target.component';
import { WarningConfirmationDialogComponent } from '../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { map } from 'rxjs/operators';
import { CustomTargetService } from '../../management/services/custom-target/custom-target.service';
import { of } from 'rxjs';
import {TableMetadataModel} from "../../../models/table-metadata.model";

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TargetComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('brandInput') brandInput: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  searchBrand;
  filteredItems = [];
  openMenu: boolean = false;

  loadBrandList: boolean = false;
  noResult: boolean = true;
  columns: any = [
    'mapped_option',
    'target_option',
    'description',
    'created_date'
  ];
  headers = {
    mapped_option: 'ID',
    target_option: 'Namn',
    description: 'Beskrivning',
    created_date: 'Skapad'
  };

  expandedElement;

  form: FormGroup;

  JSON;

  loadTargets: boolean = true;

  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  targets;

  constructor(
    public store: StoreService,
    private _fb: FormBuilder,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private router: Router,
    public dialog: MatDialog,
    private targetService: CustomTargetService
  ) {
    this.JSON = JSON;
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.globalfunctionality.initComplete.subscribe((complete: boolean) => {
      if (complete) {
        if(this.openMenu){
          this.triggerMenu(this.menuTrigger, this.brandInput);
        }
      }
    });


    this.form = this._fb.group({
      brands: [],
      /** TODO: Update to use form builder API after migration to Angular 7.1.0 or higher */
      target_option: new FormControl('', {
        validators: [Validators.required],
        asyncValidators: [this.isNameUnique.bind(this)],
        updateOn: 'blur'
      }),
      description: ['', [Validators.required]],
      mapped_option: ['', [Validators.required]],
      total: ['', [Validators.required]],
      id: ['', [Validators.required]]
    });

    if(!this.store.isAdmin.value){
      this.form.get('target_option').disable();
      this.form.get('description').disable();
      this.form.get('total').disable();
    }

    this.getCustomTargets();
  }

  isNameUnique(control: AbstractControl) {
    let validator = this.targetService.isNameUnique(control.value);

    if (this.expandedElement) {
      if (control.value === this.expandedElement.target_option) {
        validator = of(true);
      }
    }

    return validator.pipe(map(unique => (unique ? null : { notUnique: true })));
  }

  getCustomTargets() {
    this.apiservice
      .getJSON(
        this.store.apiURL + '/CustomTargetsServlet?custom_ate_segments=true'
      )
      .subscribe((res: ResponseBodyModel) => {
        if (res.responseCode === 200) {
          res.data.forEach(elm => {
            const ate_segment = JSON.parse(elm['mapped_option'])[0];
            const regex = /^(.*?)\-SE/g;
            elm['ate_segment_display'] = ate_segment.match(regex);
          });

          this.targets = res.data;
          this.decideItemsToBeShowed();
          this.dataSource.sort = this.sort;
          this.loadTargets = false;
          if (!this._cd['destroyed']) {
            this._cd.detectChanges();
          }
        }
      });
  }

  createTarget() {
    if(this.store.isAdmin.value) {
      const dialogRef = this.dialog.open(CreateTargetComponent, {
        panelClass: ['modal-size-md', 'modal-height-control']
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getCustomTargets();
        }
      });
    }
  }

  expandTarget(element) {
    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }

    this.replaceFormObj(element);
  }

  removeTarget() {
    const dialogRef = this.dialog.open(WarningConfirmationDialogComponent, {
      panelClass: 'modal-size-sm',
      data: {
        text: 'Vill du ta bort?',
        desc: 'Denna målgrupp kommer att försvinna.',
        abort_btn: 'Avbryt',
        confirm_btn: 'Ta bort',
        confirm_class: 'alert'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let deleteArr = [this.expandedElement.id];
        this.apiservice
          .deleteJSON(
            this.store.apiURL +
              '/CustomTargetsServlet?id=' +
              JSON.stringify(deleteArr)
          )
          .subscribe((res: ResponseBodyModel) => {
            if (res.responseCode === 200) {
              this.dataSource.data = this.dataSource.data.filter(
                elm => elm.id !== this.expandedElement.id
              );
              this.expandedElement = undefined;
            }
          });
      }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  replaceFormObj(obj) {
    this.form.reset();
    this.form.patchValue(obj);

    if (obj['brands'].length) {
      let controls = [];

      obj.brands.forEach(elm => {
        controls.push({ brand: elm });
      });

      this.form.get('brands').setValue(controls);
    }
  }

  submitForm(isValid: boolean) {
    const brands = this.form.value['brands'].map(brand => brand.brand);
    if (isValid) {
      this.form.value['brands'] = this.form.value['brands'].map(elm => elm.brand.id);

      this.apiservice
        .putJSON(this.store.apiURL + '/CustomTargetsServlet', this.form.value)
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            let orgObj = { ...this.form.value, brands };
            let data = this.dataSource.data;
            let index = data.findIndex(
              elm => elm.id === this.expandedElement.id
            );
            data[index] = { ...data[index], ...orgObj };

            this.expandedElement = undefined;
            this.replaceFormObj(orgObj);

            this.dataSource._updateChangeSubscription();

            toastr.info('Målgrupp uppdaterad');
          }
        });
    }
  }

  selectBrand(brand): void {
    brand['name'] = brand['brands_name'];
    brand['id'] = brand['brands_id'];
    const control = this.form.get('brands') as FormArray;

    let exists =
      control.value.findIndex(
        currentBrand => currentBrand.brand.id === brand.brands_id
      ) !== -1;

    if (!exists) {
      control.setValue([
        ...control.value,
        { brand: { id: brand.brands_id, name: brand.brands_name } }
      ]);
    }
  }

  deselectBrand(brand, index) {
    let brands = this.form.get('brands').value;
    brands.splice(index, 1);
    this.form.get('brands').setValue(brands);
  }

  filterItems(): void {
    this.loadBrandList = true;
    this.filteredItems = $.extend(true, [], this.store.companiesList);
    this.noResult = true;
    this.filteredItems.forEach(elm => {
      if (
        elm.companies_name
          .toLowerCase()
          .indexOf(this.searchBrand.toLowerCase()) !== -1
      ) {
        this.noResult = false;
        return;
      } else {
        elm.companyNotFound = true;
        elm.brands = elm.brands.filter(item => {
          if (
            item.brands_name !== undefined &&
            this.searchBrand !== undefined
          ) {
            return (
              item.brands_name
                .toLowerCase()
                .indexOf(this.searchBrand.toLowerCase()) !== -1
            );
          }
        });
      }
      if (elm.brands.length > 0) {
        this.noResult = false;
      }
    });
    this.loadBrandList = false;

   this.detectChange();
  }

  detectChange(){
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.decideItemsToBeShowed();
  }

  triggerMenu(menuTrigger, brandInput): void {
    this.openMenu = true;
    this.detectChange();
    if (!menuTrigger.menuOpen) {
      this.searchBrand = '';
      menuTrigger.toggleMenu();
      brandInput.focus();
    }
    this.filterItems();
  }

  restoreBrand(): void {
    this.searchBrand = '';
  }

  decideItemsToBeShowed(){
    const multiplier: number = (this.tableMetadata.page + 1);
    const amountToFetch: number = (this.tableMetadata.page_size * multiplier) - 1;
    const startNum: number = this.tableMetadata.page === 0 ? 0 : this.tableMetadata.page_size * this.tableMetadata.page;

    this.tableMetadata.length = this.targets.length;

    const displayElements = [];
    for(let i = startNum; i <= amountToFetch; i++){
      if(this.targets[i]) {
        displayElements.push(this.targets[i]);
      }
    }
    this.dataSource = new MatTableDataSource(displayElements);
  }

  openHelpMenu(){
    this.globalfunctionality.openHelpPopup.next(true);
  }

}
