import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {StoreService} from "../../services/store.service";
import {ApiService} from "../../services/api.service";
declare var moment: any;

export interface NewsData {
  activeTs: string;
  createdTs: string;
  data:
    {
      title: string;
      image: string;
      body: string;
    };
  id: number;
  status: string;
  type: string;
  show_more?: boolean;

}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})

export class NewsComponent implements OnInit {

  expandedIndex: number;
  news: NewsData[];
  showAll: boolean = false;
  constructor(public store: StoreService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.apiservice.getJSON(this.store.apiURL + "/NewsServlet?state=active")
      .subscribe(res => {
        if (res) {
          res.data.forEach(elm => {
            elm.data = JSON.parse(elm.data)
            elm.activeTs = moment(elm.activeTs).format("YYYY-MM-DD");
          });
          this.news = res.data.reverse();
          this.expandedIndex = 0;
          this._cd.detectChanges();
          this.checkShowMoreCondition();
        }
      });

    this.store.selectedMenuItem = undefined;
  }

  expandNews(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? undefined : index;
  }

  checkShowMoreCondition(check: boolean = true){
    if(check) {
      this._cd.detectChanges();
      this.news.forEach((elm, index) => {
        const newsContentDiv = document.getElementById('news-content-' + index);
        if (newsContentDiv !== null) {
          const divHeight = newsContentDiv.offsetHeight;
          if (divHeight > 46) {
            elm['show_more'] = true;
            newsContentDiv.classList.add('news-content__excerpt');
          }
        }
      });
      this._cd.detectChanges();
    }
  }

}
