import { NgModule } from '@angular/core';
import {GeographyComponent} from "./geography/geography.component";
import {FreetextComponent} from "./freetext/freetext.component";
import {CategoryComponent} from "./category/category.component";
import {IndicatorComponent} from "./indicator/indicator.component";
import {MaterialModule} from "../../../../../utils/MaterialModule";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  exports: [
    GeographyComponent,
    CategoryComponent,
    FreetextComponent,
    IndicatorComponent
  ],
  declarations: [
    GeographyComponent,
    CategoryComponent,
    FreetextComponent,
    IndicatorComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]

})
export class SearchwordModule {

}
