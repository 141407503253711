import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {FormcontrolService} from "../../../../../services/formcontrol/formcontrol.service";
import {HttpResponse} from "@angular/common/http";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-subobjective',
  templateUrl: './subobjective.component.html',
  styleUrls: ['./subobjective.component.css']
})
export class SubobjectiveComponent implements OnInit {


  subobjectives = [
    {
      id: 1,
      index: 0,
      name: "Klick till sajt",
      text: "Driv trafik till din sajt och betala per klick. Med våra annonsmallar skapar du enkelt din annons. Ladda upp bilder, budskap och länkar så skapas annonser till alla aktuella sajter och format.",
      formats: "Mobil & Desktop",
      active: true
    },
    {
      id: 2,
      index: 1,
      name: "Klick till appnedladdning",
      text: "Driv trafik till App Store™ och Google Play™ och betala per klick. Ladda upp app-ikon, budskap, länkar så skapas annonser till alla aktuella sajter och format.",
      formats: "Mobil",
      active: true
    },
    {
      id: 3,
      index: 2,
      name: "Klick till e-handel",
      text: "Driv trafik till din e-handel. Ladda upp din produktfeed och få dina annonser skapade automatiskt till alla aktuella sajter och format.",
      formats: "Mobil & desktop",
      active: true,
      feed: true
    }
  ];
  selectedSubobjective;
  selectedPlatformOption = '0';
  selectedSubobjectiveIndex = 0;

  minSiteSelected: boolean = true;

  showMore: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              public apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              public form: FormcontrolService,
              public _create: CreatecampaignComponent,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
     iconRegistry.addSvgIcon(
       'feed',
       sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/feed-ribbon.svg'));
  }

  ngOnInit() {
    const values = this.globalfunctionality.getModuleValues('subobjective');

    if (values) {
      if (values.selectedSubobjective) {
        this.selectedSubobjective = values.selectedSubobjective;
        this.selectedSubobjectiveIndex = values.selectedSubobjective.index;
        this.checkIfComplete();
      } else {
        this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet")
          .subscribe((res: ResponseBodyModel) => {
            if (res.responseCode === 200) {
              this.selectedSubobjective = this.subobjectives[0];
              this.selectedSubobjectiveIndex = this.subobjectives[0]['index'];
              res.data['sites'].forEach(elm => {
                elm['name'] = elm['target_option'];
                elm['selected'] = true;
              });
              this.selectedSubobjective.sites = res.data['sites'];
              this.checkIfComplete();
            }
          });
      }
    }

  }

  selectSubobjective(subobjective, i) {
    subobjective['index'] = i;
    this.selectedSubobjective = subobjective;
    this.selectedSubobjectiveIndex = i;

    // reset module
    this.globalfunctionality.setModuleValue('device', {});
    this.globalfunctionality.setModuleValue('adforsize', {});
    this.checkIfComplete();
  }


  checkIfComplete() {
    const values = this.globalfunctionality.getModuleValues('subobjective');

    if (values) {
      if (this.store.GUIFlow[2]) {
        this.store.GUIFlow[2].forEach(function (elm) {
          if (elm.type === "subobjective") {
            elm["module_values"].isComplete = true;
            values.isComplete = true;
          }
        });
      }

      values["selectedSubobjective"] = this.selectedSubobjective;
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.globalfunctionality.page2Checker.next(true);
    }

  }
}
