import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {StoreService} from "../services/store.service";
import {CampaignService} from "../dashboard/campaign/campaign.service";
import {GlobalfunctionalityService} from "../services/globalfunctionality.service";


@Injectable()
export class AuthService {

  constructor(
    private store: StoreService,
    private campaignService: CampaignService,
    private globalfunctionality: GlobalfunctionalityService
  ) {
  }

  checkPermissions(path) {
    if (path === 'management') {
      return Observable.of(this.store.isAdmin.value || this.store.isCompanyAdministrator.value || this.store.isTrustedPartner.value || this.store.isAdminCompanyManager.value);
    }
    if (path === 'task') {
      return Observable.of(this.store.isAdmin.value || this.store.isAdminCampaignManager.value || this.store.isAdminCompanyManager.value);
    }
  }


  checkCampaignPage(page) {
    if (!Object.keys(this.store.GUIFlow).length) {

      this.campaignService.structure.setValue(JSON.parse(sessionStorage.getItem('campaign_structure')));
      this.store.modules = JSON.parse(sessionStorage.getItem('modules'));
      const modules = this.store.modules;
      if (modules !== null) {
        const page2Elements = modules.filter(elm => elm.page === 2);
        const page4Elements = modules.filter(elm => elm.page === 4);

        this.campaignService.page1Complete = !this.campaignService.structure.get('name').errors && !this.campaignService.structure.get('objective').errors;
        this.campaignService.page2Complete = page2Elements.every(elm => elm['module_values'].isComplete === true);

        /*START PAGE 3 CHECK*/
        const addLaterValues = this.globalfunctionality.getModuleValues('addlater');
        const adForSizeValues = this.globalfunctionality.getModuleValues('adforsize');
        if (adForSizeValues && addLaterValues) {
          this.campaignService.page3Complete = adForSizeValues.isComplete || addLaterValues.isComplete;
        }
        if (this.campaignService.page3Complete === undefined) {
          this.campaignService.page3Complete = false;
        }
        /*END PAGE 3 CHECK*/

        /*START PAGE 4 CHECK*/
        if (page4Elements.length > 0) {
          page4Elements.forEach((elm) => {
            if (elm.type === 'complete') {
              if (elm.module_values.isComplete) {
                this.campaignService.page4Complete = true;
              }
            }
          });
        }
        /*END PAGE 4 CHECK*/
      }
    }

    switch (page) {
      case 'audience':
        return Observable.of(this.campaignService.page1Complete);
        break;
      case 'ads':
        return Observable.of(this.campaignService.page1Complete && this.campaignService.page2Complete);
        break;
      case 'budget':
        return Observable.of(this.campaignService.page1Complete && this.campaignService.page2Complete && this.campaignService.page3Complete);
        break;
      case 'review':
        return Observable.of(this.campaignService.page1Complete && this.campaignService.page2Complete && this.campaignService.page3Complete && this.campaignService.page3Complete);
        break;
      default:
        return Observable.of(true);
    }

  }


  checkManagementRoute(route) {
    switch (route) {
      case 'users':
        return Observable.of(this.store.isAdmin.value || this.store.isCompanyAdministrator.value || this.store.isAdminCompanyManager.value);
        break;
      case 'companies':
        return Observable.of(this.store.isAdmin.value || this.store.isAdminCompanyManager.value);
        break;
      case 'target':
        return Observable.of(this.store.isAdmin.value);
        break;
      case 'pin_brand':
        return Observable.of(this.store.isTrustedPartner.value);
        break;
      default:
        return Observable.of(true);
    }
  }
}
