import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { UserComponent } from './user/user.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PipeModule } from '../campaign/pipes/pipe.module';
import { CompanyChangeDialogComponent } from './company/company-change-dialog/company-change-dialog.component';
import { UserInviteDialogComponent } from './user/user-invite-dialog/user-invite-dialog.component';
import { BrandComponent } from './brand/brand.component';
import { CreateBrandDialogComponent } from './brand/create-brand-dialog/create-brand-dialog.component';
import { PinBrandComponent } from './pin-brand/pin-brand.component';
import { CommonModule } from '@angular/common';
import { CustomTargetService } from './services/custom-target/custom-target.service';
import {ManagementComponent} from "./management.component";
import {MaterialModule} from "../../utils/MaterialModule";
import {AuthModule} from "../../auth/auth.module";
import {PaginationModule} from "../../utils/pagination/pagination.module";
import {NewsComponent} from "../news/management/news/news.component";
import {CreateNewsDialogComponent} from "../news/management/news/create-news-dialog/create-news-dialog.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {NewsTypePipe} from "../news/management/news/news-type-pipe";
import {NewsStatusPipe} from "../news/management/news/news-status-pipe";

export const managementRoutes: Routes = [
  { path: 'companies', component: CompanyComponent },
  { path: 'users', component: UserComponent },
  { path: 'pin_brand', component: PinBrandComponent },
  { path: 'news', component: NewsComponent }
];

/*canActivate: [AuthGuard]*/

@NgModule({
  declarations: [
    UserComponent,
    CompanyComponent,
    CompanyChangeDialogComponent,
    UserInviteDialogComponent,
    BrandComponent,
    CreateBrandDialogComponent,
    PinBrandComponent,
    ManagementComponent,
    NewsComponent,
    CreateNewsDialogComponent,
    NewsTypePipe,
    NewsStatusPipe
  ],

  imports: [
    RouterModule.forChild(managementRoutes),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatChipsModule,
    AngularSvgIconModule,
    PipeModule,
    CommonModule,
    MaterialModule,
    AuthModule,
    PaginationModule,
    ImageCropperModule
  ],
  exports: [RouterModule],
  providers: [CustomTargetService],
  entryComponents: [
    UserInviteDialogComponent,
    CompanyChangeDialogComponent,
    CreateBrandDialogComponent,
    CreateNewsDialogComponent
  ]
})
export class ManagementModule {}
