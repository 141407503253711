import {ChangeDetectorRef, Injectable, OnInit} from "@angular/core";
import {StoreService} from "../../../../../services/store.service";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {CampaignService} from "../../../campaign.service";


@Injectable()
export class CreativemoduleService {

  allowed_types = ["jpeg", "tiff", "png", "jpg", "bmp", "gif", "svg", "image/png", "image/jpeg", "image/tiff", "image/jpg", "image/bmp", "image/gif", "image/svg+xml"];
  allowed_types_video = ["mp4", "mov", "webm", "mkv", "vob", "ogv", "ogg", "avi", "qt", "wmv", "rm", "asf", "m4p", "mpg", "mp2", "mpeg", "mpe", "mpv", "m2v", "m4v", "3gp", "3g2", "f4v", "f4p", "f4a", "f4b", "swf"]; /*"flv"*/
  allowed_preview_video_types = ["mp4", "webm", "ogg", "ogv", "mpeg", "mpg", "avi"];

  adNameControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(60)
  ]);

  /*feedOptionControl: FormControl =  new FormControl('', Validators.required);
  feedSelectedControl: FormControl =  new FormControl('', Validators.required);*/
  /* plugImageFrequencyControl: FormControl =  new FormControl('4');*/

  loadComponentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  load: Observable<boolean> = this.loadComponentSubject.asObservable();

  loadCreativeSaveBtnSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadCreativeSaveBtn: Observable<boolean> = this.loadCreativeSaveBtnSubject.asObservable();

  ACMLibrary;
  ACMRowId;
  ACMColumns;

  categorySelectionLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  categorySelectionLoad: Observable<boolean> = this.categorySelectionLoadSubject.asObservable();

  resetWeatherOpt = new BehaviorSubject(null);

  constructor(private _store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private _campaignService: CampaignService) {

  }


  get hasErrorControl() {
    return new FormControl(false);
  }


  get requiredFormControl() {
    return new FormControl('', [
      Validators.required,
    ]);
  }

  get nonRequiredFormControl() {
    return new FormControl('');
  }


  get adMediaControl() {
    return new FormControl('', [
      Validators.required,
    ]);
  }

  get adSelectorControl() {
    return new FormControl('false', [
      Validators.required,
    ]);
  }

  get adMediaTypeControl() {
    return new FormControl('', []);
  }

  get adMediaHorizontalControl() {
    return new FormControl('', [
      Validators.required,
    ]);
  }

  get adMediaLogoControl() {
    return new FormControl('', [
      Validators.required,
    ]);
  }

  get adMediaLogoTypeControl() {
    return new FormControl('', []);
  }

  get adHeaderControl() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(46)
    ]);
  }

  get adSubHeaderControl() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(26)
    ]);
  }

  get adCTAControl() {
    return new FormControl('', [
      Validators.required
    ]);
  }

  get adMMSControl() {
    return new FormControl('', []);
  }

  get adMMSDecisionControl() {
    return new FormControl('created', [
      Validators.required
    ]);
  }

  get adImpTrackerControl() {
    return new FormControl('', []);
  }

  get adURLControl() {
    return new FormControl('https://', [
      Validators.required,
      this.ValidateUrl,
      this.ValidateWholeUrl,
    ]);
  }

  get optimizationClickLink() {
    return new FormControl({value:'https://', disabled:true}, [
      Validators.required,
      this.ValidateUrl,
      this.ValidateWholeUrl,
    ]);
  }

  get optimizationImage() {
    return new FormControl({value: '', disabled: true}, [
      Validators.required,
    ]);
  }

  get appStoreURLControl() {
    return new FormControl('', [
      Validators.required
    ]);
  }

  get googlePlayURLControl() {
    return new FormControl('', [
      Validators.required
    ]);
  }

  get adSubHeaderControlDT() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(65)
    ]);
  }

  get adTitleControlDT() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(30)
    ]);
  }

  get adTitleControlDT50() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]);
  }

  get adAdvertiserNameDT() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(20)
    ]);
  }

  get adPriceControl() {
    return new FormControl('', [
      Validators.required,
      Validators.maxLength(30)
    ]);
  }

  get adCallToActionControl() {
    return new FormControl('Läs mer', [
      Validators.required,
      Validators.maxLength(15)
    ]);
  }

  detectChange = new BehaviorSubject(null);
  resetFormatType = new BehaviorSubject(null);
  resetImageUpload = new BehaviorSubject(null);


  clearControls() {
    /*this.adNameControl.reset();*/
    this.adURLControl.markAsUntouched();
    this.adMediaControl.reset();
    this.adMediaHorizontalControl.reset();
    this.adSubHeaderControlDT.reset();
    this.adAdvertiserNameDT.reset();
    this.adTitleControlDT.reset();
  }

  ValidateUrl(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if ((control.value.startsWith('https://') || control.value.startsWith('http://'))) {
      return null;
    }
    return {validUrl: true};
  }

  ValidateUrlHttps(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if ((control.value.startsWith('https://'))) {
      return null;
    }
    return {validUrl: true};
  }

  ValidateWholeUrl(control: AbstractControl) {
    if (typeof control.value === "undefined") {
      return null;
    }
    if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(control.value)) {
      return null;
    }
    return {validWholeUrl: true};
  }

  clearModuleValues() {
    this._store.modules.forEach((elm) => {
      if (elm.type === 'templates') {
        elm.param.output = '';
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }
      if (elm.type === 'adcreation') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.param.output = '';
      }

      if (elm.type === 'creatives') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

      if (elm.type === 'existingtag') {
        let id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
        elm.module_values.isComplete = false;
      }

    });

    sessionStorage.setItem('modules', JSON.stringify(this._store.modules));

  }

  decidePreviewDevice() {
    let showMobile: boolean = false;
    let showDesktop: boolean = false;
    if (this.globalfunctionality.getModuleValues('device')) {
      const selectedDevice = this.globalfunctionality.getModuleValues('device')['selectedTargetOption'];
      if (selectedDevice) {
        switch (selectedDevice.toLowerCase()) {
          case 'desktops & laptops':
            showDesktop = true;
            break;
          case 'mobiles':
            showMobile = true;
            break;
        }
      } else {
        showDesktop = true;
        showMobile = true;
      }
    } else {
      if (this._store.productType === 'FreeTextAnnons') {
        showDesktop = true;
        showMobile = true;
      }

      if (this._campaignService.structure.get('objective').value.id === 2) {
        const device = this.globalfunctionality.getModuleValues('adforsize').selectedDeviceOption;
        if (device) {
          showDesktop = device.toUpperCase() === 'DESKTOP' || device.toUpperCase() === 'ALL';
         showMobile = device.toUpperCase() === 'MOBILE' || device.toUpperCase() === 'ALL';
        } else {
          showDesktop = true;
          showMobile = true;
        }
      }
    }

    return {showDesktop, showMobile};
  }

}
