/**
 * Created by adssets on 2017-09-19.
 */
import {NgModule} from '@angular/core';
import {PaginationComponent} from "./pagination.component";
import {MaterialModule} from "../MaterialModule";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    PaginationComponent
  ],

  imports: [
    MaterialModule,
    CommonModule,
    FormsModule
  ],
  exports: [PaginationComponent],
  entryComponents: [],
  providers: []
})
export class PaginationModule {
}
