import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';

import {Router} from "@angular/router";

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {FormControl, Validators} from "@angular/forms";
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {UserChangeDialogComponent} from "./user-change-dialog/user-change-dialog.component";
import {UserInviteDialogComponent} from "./user-invite-dialog/user-invite-dialog.component";
import {animate, state, style, transition, trigger} from "@angular/animations";

declare let moment: any;

import {forkJoin} from "rxjs";
import {TableMetadataModel} from "../../../models/table-metadata.model";

declare var toastr: any;

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]

})
export class UserComponent implements OnInit {

  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  filterTimeout;
  filterLoad: boolean = false;
  initialLoad: boolean = true;
  showPaginator: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
  }

  rolesControl = new FormControl();

  columns: any = ['realname', 'email', 'role', 'company_name'];
  expandedElement: any;
  headers =
    {
      realname: "Namn",
      email: "Epostadress",
      role: "Roll",
      company_name: "Företag"
    };

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;


  labelPosition = 'after';
  allUsers = [];
  users = [];
  companies = [];
  roles = [];
  type = "user";
  role;


  selecteduser;

  name;
  firstname;
  lastname;
  email;
  phonenumber;
  mobilenumber;

  // Table variables
  column: string = 'name';
  direction: number;
  show = false;
  selectedIndex: number;
  p: number = 1;
  task;

  limit: number = 20;
  check = true;
  loadUsers = true;

  ngOnInit() {

    let user = this.apiservice.getJSON(this.store.apiURL + "/UserServlet" + this.getParameter());
    let roles = this.apiservice.getJSON(this.store.apiURL + "/RolesServlet");

    forkJoin([user, roles]).subscribe(([user, roles]) => {
      this.handleUserResponse(user);
      this.getRoles(roles);
    });
  }


  sortChange(event) {
    this.tableMetadata.sort = event.active;
    this.tableMetadata.sort_order = event.direction;
    this.getUsers();
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.getUsers();
  }


  getParameter() {
    let params;
    if (this.store.isAdmin.value || this.store.isAdminCompanyManager.value) {
      params = "?management=true";
    } else if (this.store.isCompanyAdministrator.value) {
      params = "?companyId=" + this.store.user.companies[0].id;
    }

    const obj = this.tableMetadata;
    params = params + '&filter=' + obj.filter;

    if (obj.page !== undefined) {
      params = params + '&pageNumber=' + obj.page;
    }
    if (obj.page_size !== undefined) {
      params = params + '&pageSize=' + obj.page_size;
    }
    if (obj.sort !== undefined && (obj.sort_order !== undefined && obj.sort_order !== '')) {
      params = params + '&sort=' + obj.sort + ':' + obj.sort_order;
    }
    return params;
  }

  applyFilter(filterValue: string) {
    clearTimeout(this.filterTimeout);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.filterLoad = true;
    this.showPaginator = false;

    this.filterTimeout = setTimeout(() => {
      this.tableMetadata.filter = filterValue.trim().toLowerCase();
      this.getUsers();
    }, 1000);
  }


  getUsers() {
    this.apiservice.getJSON(this.store.apiURL + "/UserServlet" + this.getParameter())
      .subscribe(res => {
        this.handleUserResponse(res);
      });
  }

  handleUserResponse(res) {
    if (res.responseCode === 200) {
      this.allUsers = res["data"];
      this.users = Object.assign([], res["data"]);
      this.tableMetadata.length = res.pagination.maxResult;

      this.users.forEach(elm => {
        if (elm.company.length > 0) {
          elm['company_name'] = elm.company[0].name;
        } else {
          elm['company_name'] = '';
        }

        if (elm.roles.length > 0) {
          elm['role'] = elm['roles'][0]['name'];
        } else {
          elm['role'] = 'ANVÄNDARE';
        }

      });

      this.dataSource = new MatTableDataSource(this.users);
      this.filterLoad = false;
      this.initialLoad = false;
      this.showPaginator = true;
      this.loadUsers = false;

      if (!this._cd["destroyed"]) {
        this._cd.detectChanges();
      }
    } else {
      this.globalfunctionality.logoutUser();
    }
  }


  // FORKJOIN
  getRoles(res) {
    if (res.responseCode === 200) {
      this.roles = res["data"];
    } else {
      this.globalfunctionality.logoutUser();
    }
  }

  selectRole(user, role) {
    let contains = false;
    for (let i = 0; i < user.roles.length; i++) {
      if (user.roles[i].name === role.name) {
        contains = true;
      }
    }
    if (!contains) {
      user.roles.push(role);
    }
    this.rolesControl.setValue(undefined);
  }

  deselectRole(user, index) {
    user.roles.splice(index, 1);
  }

  inviteUsers() {
    const dialogRef = this.dialog.open(UserInviteDialogComponent,
      {
        panelClass: 'modal-size-sm'
      });
    dialogRef.afterClosed().subscribe(complete => {
      if (complete === true) {
        toastr.info("Användare har blivit inbjuden");
      } else if (complete === false) {
        /*toastr.warning("Något gick fel!");*/
      }
    });
  }

  toggleCompanyAdmin(user) {
    const request = {
      company: this.store.user.companies[0].id,
      user: user.id,
      admin: user.company[0].isCompanyAdmin
    };

    this.apiservice.postJSON(this.store.apiURL + "/UserServlet?option=toggle_company_admin", request)
      .subscribe(res => {
        if (res.responseCode === 200) {
          toastr.info("Användare uppdaterad");
        }
      });
  }

  edit(user) {
    const dialogRef = this.dialog.open(UserChangeDialogComponent,
      {
        panelClass: 'modal-size-sm'

      });
    dialogRef.afterClosed().subscribe(complete => {
      if (complete) {
        user["updatekey"] = true;
        this.apiservice.postJSON(this.store.apiURL + "/UserServlet?management=true&companyId=" + user.company[0].id, user)
          .subscribe(res => {

            if (res.responseCode === 200) {
              this.toggleCompanyAdmin(user);

            } else {
              this.toggleCompanyAdmin(user);
            }
          });
      }
    });
  }

  expandUser(element) {
    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }
    this.selecteduser = this.expandedElement;
  }
}


