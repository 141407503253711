import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable()
export class FormcontrolService {

  creatives: FormGroup;

  timeschedulingStarthours: FormGroup = new FormGroup({});
  timeschedulingEndhours: FormGroup= new FormGroup({});

  constructor() { }

}
