import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import {Router} from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {forkJoin} from "rxjs";
import {TableMetadataModel} from "../../../models/table-metadata.model";
declare var toastr: any;


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CompanyComponent implements OnInit {


  companies = [];
  profiles = [];
  selectedCompany;
  type = "company";
  column: string = 'name';
  direction: number;
  show = false;
  selectedIndex: number;
  p: number = 1;
  limit: number = 20;
  loadCompanies = true;
  loadSync: boolean = false;

  columns: any = ['name', 'company_type', 'profile', 'org_num', 'sales_rep_name'];
  expandedElement: any;
  headers =
    {
      name: "Konto (Företag)",
      company_type: "Kontotyp",
      profile: "Profil",
      org_num: "ORG.NR.",
      sales_rep_name: "Säljansvarig"
    };

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;


  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  filterTimeout;
  filterLoad: boolean = false;
  showPaginator: boolean = false;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private router: Router,
              private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getCompaniesAndProfiles();
    this.globalfunctionality.highlightMenuItem("companies");
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.getCompanies();
  }

  expandRow(element){
    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }
    this.selectedCompany = this.expandedElement;
    if(this.selectedCompany) {
      if (this.selectedCompany.acm_status) {
        if (this.selectedCompany.acm_status.toUpperCase() === 'DISABLED') {
          this.selectedCompany.acmEnabled = false;
        } else if (this.selectedCompany.acm_status.toUpperCase() === 'ENABLED') {
          this.selectedCompany.acmEnabled = true;
        }
      }
    }
  }

  applyFilter(filterValue: string) {
    clearTimeout(this.filterTimeout);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.filterLoad = true;
    this.showPaginator = false;

    this.filterTimeout = setTimeout(() => {
      this.tableMetadata.filter = filterValue.trim().toLowerCase();
      this.getCompanies();
    }, 1000);

  }



  getCompanies(){
    let params = '?management=true';
    let obj = this.tableMetadata;
    params = params + '&filter=' + obj.filter;

    if(obj.page !== undefined){
      params = params + '&pageNumber=' + obj.page;
    }
    if(obj.page_size !== undefined){
      params = params + '&pageSize=' + obj.page_size;
    }
    if(obj.sort !== undefined && (obj.sort_order !== undefined && obj.sort_order !== '')){
      params = params + '&sort=' + obj.sort + ':' + obj.sort_order;
    }


    this.apiservice.getJSON(this.store.apiURL + "/CompaniesServlet" + params)
      .subscribe(companies => {
        this.handleCompanyResponse(companies);
      });

  }

  handleCompanyResponse(companies){
    if (companies.responseCode === 200) {
      this.companies = companies["data"];
      this.tableMetadata.length = companies.pagination.maxResult;
      this.companies.forEach(elm => {
        if(elm['sales_rep']) {
          elm['sales_rep_name'] = elm['sales_rep']['company_sales_rep'];
        }
      });

      this.dataSource = new MatTableDataSource(this.companies);
      this.filterLoad = false;
      this.showPaginator = true;
      this.loadCompanies = false;
      this._cd.detectChanges();
    }else{
      this.globalfunctionality.logoutUser();
    }
  }

  getCompaniesAndProfiles() {
    const params = '?management=true&filter';
    const companies = this.apiservice.getJSON(this.store.apiURL + "/CompaniesServlet" + params);
    const profiles = this.apiservice.getJSON(this.store.apiURL + "/ProfileServlet");
    forkJoin([companies, profiles]).subscribe(([companies, profiles]) => {
      this.handleCompanyResponse(companies);
      if (profiles.responseCode === 200) {
        this.profiles = profiles["data"];
      }else{
        this.globalfunctionality.logoutUser();
      }
    });
  }

  syncCompany(){
    this.loadCompanies = true;
    this.apiservice.getJSON(this.store.apiURL + `/CompaniesServlet?sync=${this.selectedCompany.id}`)
      .subscribe(res => {
        if(res.responseCode === 200){
          toastr.info("Synchronization complete");

          // DO NOT GET ALL COMPANIES AFTER A SYNC THE BACKEND SHOULD RETURN THE COMPANY OBJECT, THAT OBJECT SHOULD JUST BE REPLACED
          this.getCompaniesAndProfiles();
        }else{
          if(res.responseCode === 464){
            toastr.warning("Synchronization failed: Company not found");
          } else if(res.responseCode === 500 && res.responseMessage === 'missingids'){
            toastr.warning("Synchronization failed: Missing Operative One Id");
          } else if(res.responseCode === 500){
            toastr.warning("Synchronization failed: SalesForce response error");
          }
          this.loadCompanies = false;
        }

        if(!this._cd['destroyed']){
          this._cd.detectChanges();
        }
      });

  }

  sync(){
    this.loadSync = true;
    this.apiservice.getJSON(this.store.apiURL + "/SchibstedAdminInfoServlet?options=update_company_salesforce")
      .subscribe(res => {
        if(res.responseCode === 200) {
          this.loadSync = false;
        }
      });
  }

  edit(company) {
    const companyReq = this.apiservice.postJSON(this.store.apiURL + "/CompaniesServlet", company);
    const acmReq = this.apiservice.getJSON(this.store.apiURL + `/ACMAdvertiser?option=toggle_acm_access&company=${company.id}&value=${company.acmEnabled ? 'enabled' : 'disabled'}`);
    forkJoin([companyReq, acmReq]).subscribe(([companyRes, acmRes]) => {
      if(companyRes !== null || acmRes !== null){
        if (companyRes.responseCode === 200 || acmRes.responseCode === 200) {
          toastr.info("Företag uppdaterad");
        }else{
          toastr.warning("Någonting gick fel, kontakta admin");
        }
      }
    });
  }

}
