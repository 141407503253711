import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
declare var toastr: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {


  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private _router: Router
  ) { }

  ngOnInit() {
  }



  sendRequest(event){
      const obj = {
        email: event.username
      }
      this.apiservice.putJSON(this.store.apiURL + "/ForgotPasswordServlet", obj)
        .subscribe(res => {
          toastr.info("Ett mail för återställning av ditt lösenord har skickats till " + event.username);
        });
  }

  
  gotoLogin(){
    this._router.navigate(['/login']);
  }

}
