import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from "@angular/core";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../services/store.service";
import {CreativeRejectDialogComponent} from "../../dialogs/creative-reject-dialog/creative-reject-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {ManuallyAddAppnexusidComponent} from "./manually-add-appnexusid/manually-add-appnexusid.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

declare var moment: any;

@Component({
  selector: 'app-creative-task',
  templateUrl: 'creative-task.component.html',
  styleUrls: ['creative-task.component.css']
})


export class CreativeTaskComponent implements OnChanges {
  @Input() selectedTask: any;
  @Input() loadTask: any;
  @Input() material: any;
  @Input() objectiveId: any;
  @Input() productType: any;
  @Input() copyMsg: any;
  @Input() element: any;
  @Input() campaignName: any;
  @Input() reviewData: any;


  @Output() approveCreatives: EventEmitter<any> = new EventEmitter();
  @Output() rejectCreatives: EventEmitter<any> = new EventEmitter();
  @Output() missingCreatives: EventEmitter<any> = new EventEmitter();
  @Output() previewCreative: EventEmitter<any> = new EventEmitter();
  @Output() copyScript: EventEmitter<any> = new EventEmitter();

  moduleValue;

  allValidated: boolean = false;

  creativesCombinedStatus: boolean = false;

  setCreativesMissing: boolean = false;

  blocketSearchWord: boolean = false;

  mmsCode: string;

  manuallyAddAppnexusId: boolean = false;

  dynamicAd: boolean = false;

  ACMAd: boolean = false;

  selectedDevice: string = 'all';

  constructor(
    private globalfunctionality: GlobalfunctionalityService,
    private store: StoreService,
    private dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private _api: ApiService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'loader',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/loading-spinner.svg'));
  }

  ngOnChanges(): void {
    // Reset var
    this.ACMAd = false;

    if (this.store.modules) {
      const adforsize = this.globalfunctionality.getModuleValues('adforsize');

      if (this.objectiveId === 6) {
        const subObjective = this.globalfunctionality.getModuleValues('subobjective');
        this.ACMAd = subObjective.selectedSubobjective.id === 3;
        const deviceValue = this.globalfunctionality.getModuleValues('device');
        if (deviceValue.selectedDeviceIndex !== 0) {
          this.selectedDevice = deviceValue.selectedTargetOption === 'Desktops & Laptops' ? 'desktop' : 'mobile';
        }
      } else if (this.objectiveId === 2) {
        this.dynamicAd = this.globalfunctionality.getModuleValues('adtype').selectedType.toUpperCase() === 'RESPONSIVANNONS';
        if (this.dynamicAd && adforsize.selectedDeviceOption) {
          if (this.reviewData['device'] !== 'all') {
            this.reviewData['device'] = adforsize.selectedDeviceOption[0].toUpperCase() + adforsize.selectedDeviceOption.slice(1);
          }
        }
      } else if (this.objectiveId === 1) {
        this.dynamicAd = adforsize['dynamic_ad'];
      }

    }

    this.manuallyAddAppnexusId = this.selectedTask.status.toUpperCase() === 'ERROR';
    this.blocketSearchWord = this.reviewData.searchword;

    this.checkHandledSets(false);
  }


  getReplacedFormatName(template, size): string {
    let formattedTemplate: string = template.replace(/\s+/g, '_').toLowerCase();
    if (formattedTemplate.indexOf(size) !== -1) {
      const replaceString = `_${size}`;
      formattedTemplate = formattedTemplate.replace(replaceString, '');
    }
    return formattedTemplate;
  }

  checkHandledSets(update) {
    if (this.material) {
      const selectedFormats = this.material.filter(elm => elm.complete || elm.active);
      selectedFormats.forEach(format => {
        if (format.complete) {
          let handled: boolean = true;
          let status: string = 'Rejected';
          format.creative_set.filter(set => set.complete).forEach(set => {
            if (!set.status || set.status.toLowerCase() === 'pending') {
              handled = false;
            } else {
              if (set.status === 'Approved') {
                status = 'Approved';
              }
            }
          });
          format['creatives_handled'] = handled;
          format['creatives_status'] = status;
        } else if (!format.complete && format.active) {
          format['creatives_handled'] = true;
          format['creatives_status'] = format.uploaded_outside ? 'Approved' : 'Missing';
        }
      });

      this.allValidated = selectedFormats.filter(elm => elm.creatives_handled).length === selectedFormats.length;

      if (this.allValidated) {
        let status: string = 'Approved';
        const formats = selectedFormats.filter(elm => elm.creatives_handled);
        let missing = false;
        formats.forEach(elm => {
          if (elm.creatives_status === 'Rejected') {
            status = "Rejected";
          }
          if (elm.creatives_status === 'Missing') {
            missing = true;
          }
        });
        this.creativesCombinedStatus = status === 'Approved' ? true : false;
        this.setCreativesMissing = missing && this.creativesCombinedStatus; // Set creative missing only if rest of creatives is approved otherwise set rejected
      }
      if (update) {
        return this.updateModuleValues();
      }
    }
  }

  updateModuleValues() {
    const module = this.store.modules.filter(elm => elm.type === 'adforsize')[0];
    const postObj =
      {
        id: module.module_values.id,
        value: JSON.stringify(
          {
            isComplete: true,
            selected: this.material,
            dynamic_ad: module.module_values.dynamic_ad
          }
        )
      };
    return postObj;
  }

  handleCreative() {

    if (this.allValidated) {
      const postObj = this.checkHandledSets(true); // Check material obj to calculate status of task, checks if "uploaded outside" has been selected
      if (!this.setCreativesMissing) {
        if (this.creativesCombinedStatus) {
          this.handleApprove(postObj);
        } else if (!this.creativesCombinedStatus) {
          this.handleReject(postObj);
        }
      } else {
        this.handleMissing(postObj);
      }
    }

  }

  handleReject(postObj) {
    this.rejectCreatives.emit(postObj);
  }

  handleApprove(postObj) {
    this.approveCreatives.emit(postObj);
  }

  handleMissing(postObj) {
    this.missingCreatives.emit(postObj);
  }


  handleRejectCreative(format, set) {
    const dialogRef = this.dialog.open(CreativeRejectDialogComponent, {
        data: {ignoreUpdate: true},
        panelClass: 'modal-size-xs'
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        set['status'] = 'Rejected';

        if (!set['status_log']) {
          set['status_log'] = [];
        }

        const obj =
          {
            status_type: 'rejected',
            reviewed_by: this.store.user.user_username,
            reviewed_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            rejection_message: result.rejection_reason
          };

        set['status_log'].push(obj);
        set['reviewed_by'] = obj.reviewed_by;
        set['reviewed_date'] = obj.reviewed_date;
        set['rejection_message'] = obj.rejection_message;

        this.checkHandledSets(false);
        this._cd.detectChanges();
      }
    });


  }

  handleApproveCreative(format, set) {

    if (!this.manuallyAddAppnexusId) {
      this.markSetAsApproved(format, set);
    } else {
      const dialogRef = this.dialog.open(ManuallyAddAppnexusidComponent, {
          data: {
            task: this.selectedTask,
            set: set
          },
          panelClass: 'modal-size-xs'
        }
      );

      dialogRef.afterClosed().subscribe(complete => {
        if (complete) {
          this.markSetAsApproved(format, set);
        }
      });
    }
  }

  markSetAsApproved(format, set) {
    // Add inputed mms code in set object
    if (set.inputedMMSCode) {
      if (set.script) {
        set.script['adops_created_mmscode'] = true;
        set.script['MMSDecision'] = 'inputed';
        set.script['MMSCode'] = set.inputedMMSCode;
      } else if (set.creatives) {
        const face = set.creatives.selectedImages[0];
        face['adops_created_mmscode'] = true;
        face['MMSDecision'] = 'inputed';
        face['MMSCode'] = set.inputedMMSCode;
      }
    }

    set['status'] = 'Approved';

    if (set.rejection_message) {
      delete set.rejection_message;
    }

    if (!set['status_log']) {
      set['status_log'] = [];
    }

    const obj =
      {
        status_type: 'approved',
        reviewed_by: this.store.user.user_username,
        reviewed_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      };

    set['status_log'].push(obj);
    set['reviewed_by'] = obj.reviewed_by;
    set['reviewed_date'] = obj.reviewed_date;
    this.checkHandledSets(false);
  }

  handlePreview(set, format) {
    const obj = {
      set: set,
      format: format,
      objectiveId: this.objectiveId
    };
    this.previewCreative.emit(obj);
  }

  handleCopyScript(set, index, format, element) {
    const obj = {
      index: set,
      formatIndex: index,
      format: format,
      element: element
    };
    this.copyScript.emit(obj);
  }

}

