import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../../../../services/api.service";
import {StoreService} from "../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'app-freetext',
  templateUrl: './freetext.component.html',
  styleUrls: ['./freetext.component.css']
})
export class FreetextComponent implements OnInit, OnDestroy {
  @Output() searchWordChange = new EventEmitter();
  private changeLog: string[] = [];
  freeTextFormControl = new FormControl('', [
    this.checkIllegalChars
  ]);


  selectedWords = [];

  constructor(
    private apiservice: ApiService,
    private store: StoreService,
    private globalfunctionality: GlobalfunctionalityService
  ) {
  }

  ngOnInit() {
    let searchword = this.globalfunctionality.getModuleValues('searchword');
    if (searchword.freetext) {
      // this.selectedOption = searchword.freetext.selected;
      if (searchword.freetext.selectedFreeText) {
        this.selectedWords = searchword.freetext.selectedFreeText;
      } else {
        searchword.freetext["selectedFreeText"] = [];
      }
    } else {
      searchword.freetext = {};
      searchword.freetext.selectedFreeText = [];
    }
  }

  checkIllegalChars(control: AbstractControl) {

    let elm = control.value;
    if (elm.indexOf(';') !== -1 || elm.indexOf('#') !== -1 || elm.indexOf('%') !== -1 || elm.indexOf('&') !== -1
      || elm.indexOf('"') !== -1 || elm.indexOf('/') !== -1 || elm.indexOf('/') !== -1
      || elm.indexOf('+') !== -1 || elm.indexOf(':') !== -1 || elm.indexOf('´') !== -1 || elm.indexOf('\'') !== -1) {

      control.markAsTouched();
      return {invalidFreeText: true};


    } else {
      if (elm !== '') {
        let regex = new RegExp(/^(\*?)[^\*]+(\*?)$/);
        let error = false;
        let words = elm.split(',');
        words.forEach(elm => {
          if (elm !== '') {
            if (!regex.test(elm)) {
              error = true;
            }
          }
        });

        if (error) {
          control.markAsTouched();
          return {wildCardError: true};
        } else {
          return null;
        }
      }
      return null;
    }
  }


  addWord(value) {

    this.freeTextFormControl.markAsTouched();
    const words = value.split(',');

    words.forEach((elm) => {

      elm = elm.trim();

      const illegal: boolean = elm.indexOf(';') !== -1 || elm.indexOf('#') !== -1 || elm.indexOf('%') !== -1 || elm.indexOf('&') !== -1
        || elm.indexOf('"') !== -1 || elm.indexOf('/') !== -1 || elm.indexOf('/') !== -1 || elm.indexOf('+') !== -1
        || elm.indexOf(':') !== -1 || elm.indexOf('´') !== -1 || elm.indexOf('\'') !== -1;

      if (!illegal && elm !== '') {
        // Check if wildcard regex is fine
        const regex = new RegExp(/^(\*?)[^\*]+(\*?)$/);
        if (regex.test(elm)) {
          this.selectedWords.push(elm);
        }
      }
    });


    const searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    searchWordModuleObj['freetext']['selectedFreeText'] = this.selectedWords;

    this.searchWordChange.next(true);

    this.saveChange(searchWordModuleObj);
  }

  removeWord(word) {
    this.selectedWords.splice(this.selectedWords.indexOf(word), 1);
    const searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    searchWordModuleObj['freetext']['selectedFreeText'] = this.selectedWords;

    this.searchWordChange.next(true);

    this.saveChange(searchWordModuleObj);
  }

  ngOnDestroy(): void {
    this.changeSelection();
  }

  changeSelection() {
    // Save value in shared searchword module
    const searchWordModuleObj = this.globalfunctionality.getModuleValues('searchword');
    if (!searchWordModuleObj['freetext']) {
      searchWordModuleObj['freetext'] = {};
    }
    this.saveChange(searchWordModuleObj);
  }

  saveChange(obj) {
    this.globalfunctionality.setModuleValue('searchword', obj);
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }
}
