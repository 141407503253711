import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {GlobalfunctionalityService} from '../../../../../services/globalfunctionality.service';
import {StoreService} from '../../../../../services/store.service';
import {MatDialog} from '@angular/material/dialog';
import {PreviewDialogComponent} from './preview-dialog/preview-dialog.component';
import {RemoveCreativeSetWarningComponent} from '../../../dialogs/remove-creative-set-warning/remove-creative-set-warning.component';
import {Subscription} from 'rxjs';
import {CampaignService} from "../../../campaign.service";
import {DeliveryInfoDialogComponent} from "../../shared_components/budgetmodule/delivery-info-dialog/delivery-info-dialog.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import {MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {ProductResponseModel} from "../../../models/product-response.model";

declare var $: any;

@Component({
  selector: 'app-ad-for-size',
  templateUrl: './ad-for-size.component.html',
  styleUrls: ['./ad-for-size.component.css']
})
export class AdForSizeComponent implements OnInit, OnDestroy {
  @Output() sizeUpdated = new EventEmitter();
  formats;
  selected = [];
  check = true;
  format;
  size;
  selectedIndex;
  uniqueFormats;

  selectedProduct;

  minFormatsChosen: boolean = true;
  minSitesChosen: boolean = true;


  guaranteedDelivery: boolean = true;
  dynamicProduct: boolean = false;

  hasDiscount: boolean = false;

  Math;

  selectedSite;
  selectedSubcontexts = [];
  isNaN;
  amountOfSelectedFormats = 0;

  loadComponent: boolean = false;


  subscriptions: Subscription[] = [];

  selectedFormat;

  targetingBooked: boolean = false;

  updateCreatives: boolean = false;

  selectedContext;

  feedOption: boolean = false;
  selectedFormatType: string = 'standardannonser';

  // Used when device-option component is usedd
  selectedDeviceOption: string = 'all';

  displaySites =
    [
      {
        name: 'Aftonbladet',
        visible_on: {
          '320x320': true,
          '320x400': true,
          '640x320': true,
          '300x480': true,
          '1x1': true,
          '250x600': true,
          '980x240': true,
        }
      },
      {
        name: 'Blocket',
        visible_on: {
          '320x320': true,
          '1x1': true,
          '250x600': true,
          '980x240': true,
        }
      },
      {
        name: 'Klart.se',
        visible_on: {
          '320x320': true,
          '1x1': true,
          '250x600': true,
          '980x240': true,
        }
      },
      {
        name: 'Omni',
        visible_on: {
          '320x320': true,
        }
      },
      {
        name: 'SvD',
        visible_on: {
          '320x320': true,
          '300x480': true,
          '1x1': true,
          '250x600': true,
          '980x240': true,
        }
      },
      {
        name: 'Tv.nu',
        visible_on: {
          '320x320': true,
          '250x600': true,
          '980x240': true,
        }
      }
    ];

  constructor(
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    public apiservice: ApiService,
    public globalfunctionality: GlobalfunctionalityService,
    public dialog: MatDialog,
    public campaignService: CampaignService,
    public _creativeService: CreativemoduleService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.Math = Math;
    this.isNaN = isNaN;
    iconRegistry.addSvgIcon(
      'keyboard_arrow_right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/keyboard_arrow_right.svg'));
    iconRegistry.addSvgIcon(
      'keyboard_arrow_left',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/keyboard_arrow_left.svg'));
    iconRegistry.addSvgIcon(
      'chevron-left-narrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-left-narrow.svg'));
    iconRegistry.addSvgIcon(
      'chevron-right-narrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-right-narrow.svg'));
  }


  ngOnDestroy() {
    this.check = false;
    $('body').removeClass('modal-open');
    $($('#modal-append-container')[0].childNodes).each((index, elm) => {
      if (!$(elm).hasClass('modal-backdrop')) {
        $(elm).remove();
      }
    });

    if (document.querySelector('.adformat-slider') !== null) {
      document
        .querySelector('.adformat-slider')
        .removeEventListener('scroll', this.slideshowScrollBehavior);
    }

    this.subscriptions.forEach(elm => elm.unsubscribe());
  }


  ngOnInit() {

    const adForSizeValues = this.globalfunctionality.getModuleValues('adforsize');
    if (adForSizeValues.formatType) {
      this.selectedFormatType = adForSizeValues.formatType;
    } else {
      adForSizeValues['formatType'] = this.selectedFormatType;
    }

    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;
    this.addSubscriptions();
    this.clearPreviousValues();
    this.getSelectedAdType();

    if (this.campaignService.structure.get('objective').value.id === 7) {
      const values = this.globalfunctionality.getModuleValues('adforsize');
      this.guaranteedDelivery = values.guaranteed ? true : false;
      values['guaranteed'] = this.guaranteedDelivery;
    } else if (this.campaignService.structure.get('objective').value.id === 1 && this.store.productType !== 'FreeTextAnnons') {
      if (adForSizeValues.dynamic_ad) {
        this.dynamicProduct = true;
      }
      // Check if targeting booked site, in that case only show site choice in UI
      const contextValues = this.globalfunctionality.getModuleValues("context");
      const context = contextValues.contexts[0];
      this.targetingBooked = context.targeting_booking;
      this.selectedContext = context.context.toUpperCase();
      if ((this.selectedContext === 'PRISJAKT')
        && adForSizeValues.dynamic_ad === undefined) { /* this.selectedContext === 'AFTONBLADET' || this.selectedContext === 'KLART.SE' || this.selectedContext === 'BLOCKET' || this.selectedContext === 'SVD'*/
        this.dynamicProduct = true;
      }
      this.uniqueFormats = this.getUniqueFormats();
    } else if (this.campaignService.structure.get('objective').value.id === 6) {
      this.feedOption = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective.id === 3;
    }

    this.getFormats();
    this.globalfunctionality.page3Checker.next(true);
  }

  addSubscriptions() {
    this.subscriptions.push(this.globalfunctionality.resetAdforsize.subscribe(
      (reset: boolean) => {
        if (reset) {
          this.resetAndGetFormats();
        }
      }
    ));

    this.subscriptions.push(this.globalfunctionality.deviceChange.subscribe(
      (changed: boolean) => {
        if (changed) {
          // Clear adforsize object so that formats resets when user changed device choice
          this.resetAndGetFormats();
          this.globalfunctionality.deviceChange.next(false);
        }
      }
    ));

    this.subscriptions.push(this.globalfunctionality.deviceOptionChange.subscribe(
      (option: string) => {
        if (option) {
          this.selectedDeviceOption = option;
          this.apiservice.getJSON(this.store.apiURL + "/ProductsServlet?brand=" + this.campaignService.structure.get('brand').get('id').value
            + "&objective=" + this.campaignService.structure.get('objective').value.id)
            .subscribe((res: ProductResponseModel) => {
              if (res.responseCode === 200) {
                const responsiveProducts = res.data.filter(product => product['product_type'] === 'ResponsivAnnons');
                const filter = this.selectedDeviceOption ? this.selectedDeviceOption : 'all';
                const auxProduct = responsiveProducts.find(product => product['target_device'].toUpperCase() === filter.toUpperCase());
                const product = this.store.creativesForSize[0];
                product['maxCPM'] = parseInt(auxProduct.pricing);
                product['maxGrossCPM'] = parseInt(auxProduct.pricing);
                product['minGrossCPM'] = parseInt(auxProduct.pricing);
                product['minCPM'] = parseInt(auxProduct.pricing);
                this._cd.detectChanges();
              }
            });

        }
      }
    ));

    this.subscriptions.push(this.globalfunctionality.siteChange.subscribe(
      (changed: boolean) => {
        if (changed) {
          this.getCPCPreviewSites(this.store.creativesForSize);
        }
      }
    ));

    this.subscriptions.push(this.globalfunctionality.adtypeChange.subscribe(
      (changed: boolean) => {
        if (changed) {
          this.getFormats();
        }
      }
    ));
    this.subscriptions.push(this._creativeService.resetFormatType.subscribe((reset: boolean) => {
      if (reset) {
        const values = this.globalfunctionality.getModuleValues('adforsize');
        this.selectedFormatType = 'standardannonser';
        values['formatType'] = this.selectedFormatType;
      }
    }));

    // UPDATE CREATED AD MATERIAL
    this.subscriptions.push(this.globalfunctionality.adCreated.subscribe((created: boolean) => {
      const values = this.globalfunctionality.getModuleValues('adforsize');
      if (created) {
        if (values) {
          this.store.creativesForSize = values['selected'];
          if (values['dynamic_ad']) {
            this.dynamicProduct = true;
          }
          // Check if user should be able to add more material for a specific format
          this.formatAdMoreMaterial();
          this.checkIfAllRejected();
          this.checkIfComplete();
        }
      }
    }));
  }

  clearPreviousValues() {
    this.store.selectedSize = undefined;
    $('body').removeClass('modal-open');
    $('.modal-backdrop').removeClass('is-active');
    let modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');
    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;
    this.store.creativesForSize = undefined;
  }

  resetAndGetFormats() {
    // If site is changed fields can have been added/removed within the creative itself, therefore we reset the creatives if site is changed
    if (this.globalfunctionality.getModuleValues('adforsize')) {
      this.globalfunctionality.getModuleValues('adforsize')['selected'] = undefined;
    }
    this.store.creativesForSize = undefined;
    this.getFormats();
  }

  getSelectedAdType(): string {
    const adtypeValues = this.globalfunctionality.getModuleValues('adtype');
    let type = 'BannerAnnons';
    if (adtypeValues) {
      if (adtypeValues.selectedType) {
        type = adtypeValues.selectedType;
      }
    }
    type = this.dynamicProduct ? 'ResponsivAnnons' : type;
    this.store.productType = type;
    return type;
  }

  formatChange(event) {
    this.store.creativesForSize.forEach(elm => elm.active = false);
    event.active = true;
  }

  changeDynamicOption() {
    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    this.uniqueFormats = this.getUniqueFormats();
    this.resetAndGetFormats();
    adforsize['dynamic_ad'] = this.dynamicProduct;
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }

  changeOption() {
    const values = this.globalfunctionality.getModuleValues('adforsize');
    values['guaranteed'] = this.guaranteedDelivery;
    this.getFormatPrice();
    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
  }

  getFormats() {
    this.loadComponent = true;
    this.store.creativesForSize = [];
    const values = this.globalfunctionality.getModuleValues('adforsize');

    if (values) {
      if (!values['selected'] || values['selected'].length < 1) {

        const type: string = this.getSelectedAdType();
        const objectiveIdFormatted = JSON.stringify([this.campaignService.structure.get('objective').value.id]);

        this.apiservice.getJSON(`${this.store.apiURL}/DeviceServlet?objectives=${objectiveIdFormatted}&freeCampaign=${this.store.freeCampaign}&productType=${type}&advertisementCategory=${this.selectedFormatType}`)
          .subscribe(res => {
            if (res.responseCode === 200) {
              // If context flow, remove all formats that should not be used
              if (this.campaignService.structure.get('objective').value.id === 1 && this.store.productType !== 'FreeTextAnnons') { // WORKAROUND FOR BLOCKET SEARCH
                const aux = [];
                res.data.forEach(elm => {
                  this.uniqueFormats.forEach(unique => {
                    if (unique['format'] === elm.format) {
                      elm['maxCPM'] = unique['maxCPM'];
                      elm['minCPM'] = unique['minCPM'];
                      elm['maxGrossCPM'] = unique['maxGrossCPM'];
                      elm['minGrossCPM'] = unique['minGrossCPM'];
                      elm['product_name'] = unique['product_name'];
                      elm['video_settings'] = unique['video_settings'];
                      elm['sub_contexts'] = unique['sub_contexts'];
                      aux.push(elm);
                    }
                  });
                });

                this.store.creativesForSize = aux;
                this.loadComponent = false;
              } else {
                res.data.forEach(elm => {
                  if (elm.format.toLowerCase().indexOf('desktop') !== -1) {
                    elm.device = 'Desktop';
                  }
                  if (elm.format.toLowerCase().indexOf('mobile') !== -1) {
                    elm.device = 'Mobile';
                  }
                });

                if (this.campaignService.structure.get('objective').value.id === 6) {
                  this.getCPCPreviewSites(res.data);
                  this.setSelectedDevice(res);
                }
                this.store.creativesForSize = res.data;
              }

              // Gets product name sets minimum required format to active
              this.buildCreativesDefaultObj();

              // Add logic for product slider
              this.slideshowScrollBehaviorListener();

              // Check if module is complete
              this.checkIfComplete();
            }
          });
      } else {
        if (this.campaignService.structure.get('objective').value.id === 6) {
          this.getCPCPreviewSites(values['selected']);
        }

        // WORKAROUND FOR BLOCKET SEARCH
        if (this.store.productType === 'FreeTextAnnons') {
          values.selected.forEach(elm => {
            elm['product_name'] = this.globalfunctionality.getProductNameBySize(elm.format);
          });
        }
        this.store.creativesForSize = values['selected'];

        // If audience track and video ad is selected, set selectedFormat var to true used for radio button
        if (this.campaignService.structure.get('objective').value.id === 2) {
          if (this.store.productType.toUpperCase() === 'VIDEOANNONS') {
            this.selectedFormat = this.store.creativesForSize.filter(elm => elm.active)[0];
          }
        }
        this.checkIfAllRejected();
        this.slideshowScrollBehaviorListener();
        this.loadComponent = false;
        this.formatAdMoreMaterial();
        this.checkIfComplete();
      }
    }
  }

  checkIfAllRejected() {
    if (this.updateCreatives) {
      if (this.store.creativesForSize) {
        this.store.creativesForSize.filter(elm => elm.complete && elm.creative_set).forEach(elm => {
          let allRejected: boolean = true;
          elm.creative_set.filter(set => set.complete).forEach(set => {
            if (set.status) {
              if (
                set.status.toLowerCase() === 'approved' ||
                set.status.toLowerCase() === 'pending'
              ) {
                allRejected = false;
              }
            } else {
              allRejected = false;
            }
          });
          elm['all_rejected'] = allRejected;
        });
      }
    }
  }

  setSelectedDevice(res) {
    const selectedDevice = this.globalfunctionality.getModuleValues('device').selectedTargetOption;
    if (selectedDevice) {
      let value;
      if (selectedDevice.toLowerCase() === 'desktops & laptops') {
        value = 'Desktop';
      } else if (selectedDevice.toLowerCase() === 'mobiles') {
        value = 'Mobile';
      }
      res.data.forEach(elm => {
        elm['selected_device'] = value;
      });
    }
  }

  getCPCPreviewSites(obj) {
    if (this.campaignService.structure.get('objective').value.id === 6) {
      const device = this.globalfunctionality.getModuleValues('device');
      if (device) {
        if (device.sites) {
          const selectedSites = device.sites
            .filter(elm => elm.selected)
            .map(elm => elm.name);
          if (obj) {
            obj.forEach(elm => {
              elm['selectedSites'] = selectedSites;
            });
          }
          this.minSitesChosen = selectedSites.length > 0;
        }
      }
    }
  }

  buildCreativesDefaultObj() {

    this.store.creativesForSize.forEach(elm => {
      elm['complete'] = false;

      if (this.campaignService.structure.get('objective').value.id === 2) {
        if (this.store.productType.toUpperCase() === 'VIDEOANNONS') {
          elm['active'] = elm.format === 'MobilePreRoll';
        } else {
          if (this.selectedFormatType === 'standardannonser') {
            elm['active'] = this.store.productType.toUpperCase() === 'RESPONSIVANNONS' ? elm.format === '1x1' : elm.format === '320x320';
          } else if (this.selectedFormatType === 'premiumannonser') {
            elm['active'] = elm.format === '320x400';
          }
        }
      } else {
        if (this.campaignService.structure.get('objective').value.id === 1
          && (this.selectedContext === 'PRISJAKT')
        ) { /*||this.selectedContext === 'AFTONBLADET' || this.selectedContext === 'KLART.SE' || this.selectedContext === 'BLOCKET' || this.selectedContext === 'SVD'*/
          if (this.dynamicProduct) {
            elm['active'] = elm.format === '1x1' ? true : false;
          } else {
            elm['active'] = elm.format !== '1x1' ? true : false;
          }
        } else {
          elm['active'] = elm.format !== '1x1' && elm.product_name?.toUpperCase() !== 'WALLPAPER' ? true : false;

          if(elm.product_name?.toUpperCase() === 'WALLPAPER'){
            this.setProductSelection(elm, false);
          }
        }
      }

      elm['add_later'] = false;
      // TODO: change logic, add empty set if user decides to add more material
      elm['creative_set'] = [
        {id: 1, visible: true, complete: false},
        {id: 2, visible: false, complete: false},
        {id: 3, visible: false, complete: false},
        {id: 4, visible: false, complete: false},
        {id: 5, visible: false, complete: false},
        {id: 6, visible: false, complete: false},
        {id: 7, visible: false, complete: false},
        {id: 8, visible: false, complete: false}
      ];

      if (this.store.productType.toUpperCase() !== 'VIDEOANNONS') {
        elm['product_name'] = this.globalfunctionality.getProductNameBySize(elm.format);
      }
    });


    if (this.dynamicProduct) {
      this.setSelectedProduct();
    }

    this.sortFormats();
    this.getFormatPrice();
  }

  setSelectedProduct() {
    const contextValues = this.globalfunctionality.getModuleValues('context');
    contextValues.contexts.filter(site => site.active).forEach(site => {
      site.sub_contexts.filter(sub => sub.selected).forEach(sub => {
        sub.products.filter(prod => prod.selected).forEach(prod => {
          const format = JSON.parse(prod.formats)[0];
          prod.selected = this.dynamicProduct ? format === '1x1' : format !== '1x1';
        });
      });
    });
  }

  /**
   * Special cases, sort formats depending on requirements
   */
  sortFormats() {
    if (this.campaignService.structure.get('objective').value.id === 1) {
      if (this.store.productType.toUpperCase() === 'VIDEOANNONS') {
        this.store.creativesForSize.sort((a) => {
          if (a.format.includes('PreRoll') && a.device === 'Mobile') {
            return -1;
          }
        });
        this.store.creativesForSize.sort((a) => {
          if (a.format.includes('PreRoll') && a.device === 'Desktop') {
            return -1;
          }
        });
      } else if (this.store.productType.toUpperCase() === 'SPECIALANNONS') {
        this.store.creativesForSize.sort((a, b) => {
          return a.product_name.localeCompare(b.product_name);
        });
      } else {
        // Special case for prisjakt targeting booked context, sort first on mobile then 250x600 format should come after
        const targetBooked = this.globalfunctionality.getModuleValues('context').contexts[0].targeting_booking;
        if (targetBooked) {
          this.store.creativesForSize.sort((a) => {
            if (a.format.includes('250x600')) {
              return -1;
            }
          });
          this.store.creativesForSize.sort((a) => {
            if (a.device === 'Mobile') {
              return -1;
            }
          });
        }
      }
    } else if (this.campaignService.structure.get('objective').value.id === 2 && this.store.productType.toUpperCase() === 'VIDEOANNONS') {
      this.store.creativesForSize.sort((a) => {
        if (a.format.includes('BumperRoll') && a.device === 'Mobile') {
          return -1;
        }
      });
      this.store.creativesForSize.sort((a) => {
        if (a.format.includes('PreRoll') && a.device === 'Desktop') {
          return -1;
        }
      });
      this.store.creativesForSize.sort((a) => {
        if (a.format.includes('PreRoll') && a.device === 'Mobile') {
          return -1;
        }
      });
    }
  }

  // Scroll event listener
  slideshowScrollBehaviorListener() {
    setTimeout(() => {
      if (document.querySelector('.adformat-slider') !== null) {
        document
          .querySelector('.adformat-slider')
          .addEventListener('scroll', this.slideshowScrollBehavior);
      }
    }, 100);
  }

  // Ad Formats Slideshow button states
  slideshowScrollBehavior() {
    const slideshowArea = document.querySelector('.adformat-slider');
    const maxScroll = slideshowArea.scrollWidth - slideshowArea.clientWidth;
    const btnNext = document.querySelector('.adformat-slider__next');
    const btnBack = document.querySelector('.adformat-slider__back');

    if (slideshowArea.scrollLeft === maxScroll) {
      btnNext.classList.remove('is-active');
    }

    if (slideshowArea.scrollLeft !== maxScroll) {
      btnNext.classList.add('is-active');
    }

    if (slideshowArea.scrollLeft === 0) {
      btnBack.classList.remove('is-active');
    }

    if (slideshowArea.scrollLeft !== 0) {
      btnBack.classList.add('is-active');
    }
  }

  // Ad Formats Slideshow trigger scroll buttons
  slideshowSlide(slideRight = true) {
    const slideshowArea = document.querySelector('.adformat-slider');
    const cardSize = (<HTMLElement>(
      document.querySelector('.card-adformat-container')
    )).offsetWidth;

    // TODO: Smooth scrolling not working for Safari, possibily change to use jQuery animate later.
    if (slideRight) {
      slideshowArea.scrollBy({
        top: 0,
        left: cardSize,
        behavior: 'smooth'
      });
    } else {
      slideshowArea.scrollBy({
        top: 0,
        left: -cardSize,
        behavior: 'smooth'
      });
    }
  }

  /**
   * Fetch format price for being able to visualize it for the user, price can differ depending on combinations of products that are selected
   */
  getFormatPrice() {
    const formats = Array.from(
      this.store.creativesForSize.filter(elm => elm.active),
      elm => elm['format']
    );
    const type: string = this.getSelectedAdType();
    const guaranteed: boolean = this.guaranteedDelivery === false ? false : true;

    const obj = {
      objectives: [this.campaignService.structure.get('objective').value.id],
      brand: [this.campaignService.structure.get('brand').get('id').value],
      freeCampaign: this.store.freeCampaign,
      productType: type,
      guaranteed: guaranteed,
      advertisementCategory: this.selectedFormatType
    };

    // Workaround for video product in audience track, use device servlet to obtain product price for each individual format
    if (this.store.productType.toUpperCase() === 'VIDEOANNONS' && this.campaignService.structure.get('objective').value.id === 2) {
      this.store.creativesForSize.forEach(elm => {
        obj['formats'] = [elm.format];
        this.apiservice
          .postJSON(this.store.apiURL + '/DeviceServlet', obj)
          .subscribe(res => {
            elm['data_fetched'] = true;
            const product = res.data[0];
            elm = this.setFormatValues(elm, product);
            elm['product_name'] = product.product_name;
            if (this.store.creativesForSize.length === this.store.creativesForSize.filter(elm => elm.data_fetched).length) {
              const values = this.globalfunctionality.getModuleValues('adforsize');
              values['selected'] = this.store.creativesForSize;
              sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
              this.loadComponent = false;
              this.detectChange();
            }
          });
      });
    } else {
      obj['formats'] = formats;
      this.apiservice
        .postJSON(this.store.apiURL + '/DeviceServlet', obj)
        .subscribe(res => {
          if (res.responseCode === 200) {
            this.selectedProduct = res.data[0];
            if (this.selectedProduct) {
              this.store.creativesForSize.forEach(elm => {
                elm = this.setFormatValues(elm, this.selectedProduct);
                if (this.selectedProduct.product_type.toUpperCase() === 'VIDEOANNONS') {
                  elm['product_name'] = this.selectedProduct.product_name;
                }
              });
              const values = this.globalfunctionality.getModuleValues('adforsize');
              values['selected'] = this.store.creativesForSize;
              sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
            }
            this.loadComponent = false;
            this.detectChange();
          }
        });
    }
  }

  setFormatValues(elm, product) {
    if (this.campaignService.structure.get('objective').value.id === 6) {
      if (!this.feedOption) {
        product.product_pricing = 5;
        product.gross_product_pricing = 5;
      }
    }

    elm['minCPM'] = product.product_pricing;
    elm['maxCPM'] = product.product_pricing;
    elm['minGrossCPM'] = product.gross_product_pricing;
    elm['maxGrossCPM'] = product.gross_product_pricing;
    elm['product_type'] = product.product_type;
    elm['video_settings'] = product.video_settings;
    return elm;
  }

  getUniqueFormats() {

    let type: string = this.getSelectedAdType();
    /* if(type === 'ResponsivAnnons'){
       type = 'BannerAnnons';
     }*/

    const contextValues = this.globalfunctionality.getModuleValues('context');
    let selectedContext: any = {};
    let getAllFormats = false;

    // Set selected context to the one that is "active", selected site will have the active attribute set to true
    contextValues.contexts.filter(elm => elm.active).forEach(elm => {
      selectedContext = elm;
      this.selectedSite = selectedContext.context;
    });

    let uniqueFormats = [];

    selectedContext.sub_contexts.forEach(product => {
      if (product.name === 'Alla' || product.name === 'Startsida') {
        if (product.selected) {
          getAllFormats = true;
        }
      }
    });

    // If !all is selected check which subcontexts that was selected, this is used to display the subcontexts under the products
    if (!getAllFormats) {
      this.selectedSubcontexts = selectedContext.sub_contexts
        .filter(elm => elm.selected)
        .map(elm => elm.name);
    }

    selectedContext.sub_contexts.filter(productObj => productObj.selected).forEach(productObj => {
      productObj.products.forEach(product => {
        if (product['product_type'].toLowerCase() === type.toLowerCase()) {
          // If "ALL" is selected append all formats
          if (getAllFormats) {
            let push = true;
            uniqueFormats.filter(elm => (elm['format'] === JSON.parse(product.formats)[0])).forEach(elm => {
              if (elm.sub_contexts) {
                if (elm.sub_contexts.indexOf(product.subContext) === -1 && (product.subContext.toUpperCase() !== 'ALLA' && product.subContext.toUpperCase() === 'STARTSIDA')) {
                  elm.sub_contexts.push(product.subContext);
                }
              }
              push = false;
            });

            if (push) {
              uniqueFormats.push({
                format: JSON.parse(product.formats)[0],
                minCPM: parseInt(product.pricing),
                minGrossCPM: parseInt(product.gross_pricing),
                maxCPM: parseInt(product.pricing),
                maxGrossCPM: parseInt(product.gross_pricing),
                product_name: product.productName,
                video_settings: product.video_settings,
                sub_contexts:
                  product.subContext.toUpperCase() === 'ALLA' || product.subContext.toUpperCase() === 'STARTSIDA'
                    ? []
                    : [product.subContext]
              });
            } else {
              const index = uniqueFormats.findIndex(elm => elm.format === JSON.parse(product.formats)[0]);

              if (parseInt(product.pricing) < parseInt(uniqueFormats[index]['minCPM'])) {
                uniqueFormats[index]['minCPM'] = parseInt(product.pricing);
                uniqueFormats[index]['minGrossCPM'] = parseInt(product.gross_pricing);
              }
              if (parseInt(product.pricing) > parseInt(uniqueFormats[index]['maxCPM'])) {
                uniqueFormats[index]['maxCPM'] = parseInt(product.pricing);
                uniqueFormats[index]['maxGrossCPM'] = parseInt(product.gross_pricing);
              }
            }
          } else {

            let push: boolean = true;
            uniqueFormats.filter(elm => elm['format'] === JSON.parse(product.formats)[0]).forEach(elm => {
              if (elm.sub_contexts) {
                if (elm.sub_contexts.indexOf(product.subContext) === -1) {
                  elm.sub_contexts.push(product.subContext);
                }
              }
              push = false;
            });

            if (product.selected) {
              // DUPLICATE
              if (push) {
                uniqueFormats.push({
                  format: JSON.parse(product.formats)[0],
                  minCPM: parseInt(product.pricing),
                  maxCPM: parseInt(product.pricing),
                  minGrossCPM: parseInt(product.gross_pricing),
                  maxGrossCPM: parseInt(product.gross_pricing),
                  product_name: product.productName,
                  video_settings: product.video_settings,
                  sub_contexts: [product.subContext]
                });
              } else {
                const index = uniqueFormats.findIndex(elm => elm.format === JSON.parse(product.formats)[0]);

                if (parseInt(product.pricing) < parseInt(uniqueFormats[index]['minCPM'])) {
                  uniqueFormats[index]['minCPM'] = parseInt(product.pricing);
                  uniqueFormats[index]['minGrossCPM'] = parseInt(product.gross_pricing);
                }
                if (product.pricing > uniqueFormats[index]['maxCPM']) {
                  uniqueFormats[index]['maxCPM'] = parseInt(product.pricing);
                  uniqueFormats[index]['maxGrossCPM'] = parseInt(product.gross_pricing);
                }
              }
            }
          }
        }
      });
    });

    this.hasDiscount = uniqueFormats.filter(elm => elm.minGrossCPM > elm.minCPM && elm.maxGrossCPM > elm.maxCPM).length > 0;
    return uniqueFormats;
  }

  /**
   * Check if user should be able to add more material for a specific format
   */
  formatAdMoreMaterial() {
    if (this.store.creativesForSize) {
      this.store.creativesForSize.forEach(elm => {
        elm.add_more = elm.creative_set.filter(elm => elm.visible).length < 8;
      });
    }
  }

  checkMinFormatsChosen() {
    if (this.store.creativesForSize) {
      this.minFormatsChosen = this.store.creativesForSize.filter(elm => elm.active).length > 0;
      this.globalfunctionality.formatChange.next(true);
    }
  }

  toggleFormat(format) {
    if (!format.active && format.complete) {
      const dialogRef = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'Vill du välja bort detta format?',
          desc: 'Ditt skapade material för detta format kommer försvinna',
          abort_btn: 'Nej',
          confirm_btn: 'Ja',
          confirm_class: 'primary'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          format.complete = false;
          format['creative_set'] = [
            {id: 1, visible: true, complete: false},
            {id: 2, visible: false, complete: false},
            {id: 3, visible: false, complete: false},
            {id: 4, visible: false, complete: false},
            {id: 5, visible: false, complete: false},
            {id: 6, visible: false, complete: false},
            {id: 7, visible: false, complete: false},
            {id: 8, visible: false, complete: false}
          ];
          this.continueToggleFormat(format);
        } else {
          format.active = true;
          this._cd.detectChanges();
        }
      });
    } else {
      this.continueToggleFormat(format);
    }
  }

  continueToggleFormat(format) {
    if (this.campaignService.structure.get('objective').value.id === 1) {
      if (!format.active) {
        format['add_later'] = false;
        format['complete'] = false;
        this.setProductSelection(format, false);
      } else {
        format['add_later'] = true;
        this.setProductSelection(format, true);
      }
    }

    this.checkMinFormatsChosen();

    if (this.campaignService.structure.get('objective').value.id === 7) {
      this.getFormatPrice();
    } else if (this.campaignService.structure.get('objective').value.id === 2) {
      if (this.store.productType.toUpperCase() === 'VIDEOANNONS') {
        this.store.creativesForSize.forEach(elm => elm.active = false);
        if (!format.active) {
          format['active'] = true;
          this.selectedFormat = format;
        }
      } else {
        this.getFormatPrice();
      }
    }
    this.checkIfComplete();
  }

  setProductSelection(format, value) {
    const contextValues = this.globalfunctionality.getModuleValues('context');
    contextValues.contexts.filter(context => context.active).forEach(context => {
      context.sub_contexts.filter(sub => sub.selected).forEach(sub => {
        sub.products.forEach(prod => {
          if (format.format === JSON.parse(prod.formats)[0]) {
            prod['selected'] = value;
            if (!value) { // If deselected
              prod['budget'] = undefined;
            }
          }
        });
      });
    });
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  removeSet(format, set, setIndex, formatIndex) {
    // Check that atleast 1 creative exists

    let visibleSets: number = 0;
    let complete: boolean = false;
    this.store.creativesForSize[formatIndex]['creative_set'].forEach(elm => {
      if (elm.visible && elm.complete) {
        visibleSets += 1;
      }
      if (elm.complete) {
        complete = true;
      }
    });

    const method = this.store.creativesForSize[formatIndex]['creative_set'][setIndex]['adcreation']['selectedMethod'];
    let oldScript;
    if (method === 'existing_tag') {
      oldScript = this.store.creativesForSize[formatIndex]['creative_set'][setIndex]['script']['script'];
    } else if (method === 'use_ad_template') {
      oldScript = this.store.creativesForSize[formatIndex]['creative_set'][setIndex]['banners'][0]['adScript'];
    }

    if (visibleSets > 1) {
      // Set a default object, set appnexus id back (backend needs it)
      this.resetSet(formatIndex, setIndex, set, oldScript, false);

      // Reorder set, if first set was removed set the new index
      this.store.creativesForSize[formatIndex]['creative_set'].sort(
        (a, b) => b.visible - a.visible
      );
      this.formatAdMoreMaterial();
    } else if (this.campaignService.structure.get('config').get('copy').value || !this.updateCreatives) {
      complete = false;
      this.resetSet(formatIndex, setIndex, set, oldScript, true);
    } else {
      // Prompt message that states that last creative cant be removed
      this.dialog.open(RemoveCreativeSetWarningComponent, {
        panelClass: 'modal-size-sm'
      });
    }
    this.store.creativesForSize[formatIndex]['complete'] = complete;
    this.checkIfComplete();
  }

  hideSet(format, set) {
    format.add_more = true;
    format.activeSets -= 1;
    set.visible = false;
  }

  resetSet(formatIndex, setIndex, set, oldScript, visible) {

    // Reduce number of active sets
    this.store.creativesForSize[formatIndex].activeSets -= 1;
    this.store.creativesForSize[formatIndex]['creative_set'][setIndex] = {
      id: set.id,
      visible: visible,
      complete: false,
      removed:
        this.updateCreatives &&
        (set.status && set.status.toLowerCase() !== 'pending'), // Set removed attribute only when updating creative, otherwise task manager will display removed creatives even though the creative might not yet have been booked (removing before booking will display a creative in the task manager)
      oldScript: oldScript,
      app_nexus_id: this.store.creativesForSize[formatIndex]['creative_set'][setIndex]['app_nexus_id'],
      appNexusCreativeId: this.store.creativesForSize[formatIndex]['creative_set'][setIndex]['appNexusCreativeId'],
    };
  }

  previewCreative(set, format) {
    set['size'] = format.format;
    set['device'] = format.device;
    this.dialog.open(PreviewDialogComponent, {
      data: set,
      panelClass: 'modal-size-auto'
    });
  }

  creativeForSize(format, set, setIndex) {
    const type: string = this.getSelectedAdType();
    this.store.productType = type;
    format['device'] = this.globalfunctionality.getDeviceByFormat(format);

    const data = {
      format: format.device,
      size: format.format,
      set: set,
      setIndex: setIndex,
      video_settings: format.video_settings
    };

    if (this.store.productType.toUpperCase() !== 'VIDEOANNONS') {
      let width = format.format.split('x')[0];
      let height = format.format.split('x')[1];
      data['width'] = width + 'px';
      data['height'] = height + 'px';
    }

    this.store.selectedSize = data;

    this.sizeUpdated.emit(data);
  }

  activateNextSet(format) {

    // Set next !visible set to visible
    let i: number = 0;
    for (let elm of format.creative_set) {
      i++;
      if (!elm.visible) {
        elm.visible = true;
        break;
      }
    }

    format['activeSets'] = format.creative_set.filter(elm => elm.visible).length;

    this.formatAdMoreMaterial();
    this.checkIfComplete();
  }

  checkIfComplete() {
    // CHANGE CHECKIFOCMPLETE THAT IT CHECKS THAT ALL SETS THAT ARE VISIBLE IS COMPLETE
    // Check if min amount of formats is selected
    this.checkMinFormatsChosen();

    const values = this.globalfunctionality.getModuleValues('adforsize');
    let complete: boolean = true;

    values['selected'] = this.store.creativesForSize;
    values['dynamic_ad'] = this.dynamicProduct;

    if (values) {
      if (values['selected']) {
        this.amountOfSelectedFormats = 0;
        values['selected'].filter(elm => elm.active).forEach(elm => {
          this.amountOfSelectedFormats += 1;
          if (!elm.complete) {
            complete = false;
          }
        });
        values['isComplete'] = complete;
      } else {
        values['isComplete'] = false;
      }
    }

    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.globalfunctionality.page3Checker.next(true);
  }

  openDeliveryInfoModal() {
    this.dialog.open(DeliveryInfoDialogComponent,
      {
        panelClass: ['modal-size-md']
      });
  }

  changeFormatType(e: MatRadioChange) {
    this.selectedFormatType = e.value;
    const values = this.globalfunctionality.getModuleValues('adforsize');
    values['formatType'] = this.selectedFormatType;

    if (values.isComplete) {
      const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'OBS!',
          desc: 'Om du ändrar ditt val försvinner dina skapade annonser och måste återskapas.',
          confirm_btn: 'Fortsätt',
          abort_btn: 'Avbryt'
        }
      });

      dialog.afterClosed().subscribe(reset => {
        if (reset) {
          values['selected'] = [];
          this.getFormats();
        } else {
          this.selectedFormatType = 'standardannonser';
        }
      });
    } else {
      values['selected'] = [];
      this.getFormats();
    }
  }
}
