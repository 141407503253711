import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {ApiService} from "../../../services/api.service";
import * as sha256 from "sha256";
import {UserInviteResponse} from "../../models/user-invite-response.model";
import {RegisterInvite} from "../../models/register-invite.model";
import {Credentials} from "../../models/credentials.model";
import {StartpageService} from "../../startpage.service";
import {LoginResponse} from "../../models/login-response.model";
import {Subscription} from "rxjs";


declare var toastr: any;

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit, OnDestroy {


  registerControlGroup: FormGroup;


  email;
  firstname;
  lastname;
  phonenumber;
  mobilenumber;
  password;

  org_num;


  billingAddress;
  billingCity;
  billingZip;

  showExpiredMsg = false;
  data;

  loginSubscription: Subscription;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private _startpage: StartpageService
  ) {
  }

  ngOnDestroy() {
    if (this.loginSubscription !== undefined) {
      this.loginSubscription.unsubscribe();
    }
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: Params) => {
      this.apiservice.GET(this.store.apiURL + "/UserInviteServlet?verification=" + params['id'])
        .subscribe((res: UserInviteResponse) => {
          if (Object.keys(res).length > 0) {
            this.data = res;
          } else {
            this.showExpiredMsg = true;
          }
        });
    });

  }

  routeToStartPage() {
    this.router.navigate(["/startpage"]);
  }

  registerInvite(event: RegisterInvite) {

    const obj = {
      email: this.data.invite.email,
      firstname: event.firstname,
      lastname: event.lastname,
      phonenumber: event.mobilenumber,
      mobilenumber: event.mobilenumber,
      password: sha256(event.password),
      companyId: this.data.company.id
    };



    this.apiservice.putJSON(this.store.apiURL + "/UserInviteServlet", obj)
      .subscribe(res => {
        const response = res;
        if (response["responseCode"] === 200 || response["responseCode"] === 500) {

          const loginObj: Credentials =
            {
              username: obj.email,
              password: obj.password
            };

          this.loginSubscription = this._startpage.login(loginObj)
            .subscribe((res: LoginResponse) => {

              if (res.responseCode === 200) {
                const data = res.data;
                data["token"] = sha256(data["token"]);
                sessionStorage.setItem("userObj", JSON.stringify(data));
                this.store.user = data;
                this.store.selectedMenuItem = 0;
                this.router.navigate(['/dashboard/campaigns']);
              }
            });
        } else {
          toastr.warning("Misslyckad registrering, kontakta admin");
        }
      });

  }

}
