import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";


@Component({
  selector: 'app-format-detailed-view',
  templateUrl: 'format-detailed-view.component.html'
})

export class FormatDetailedViewComponent implements OnInit {
  @Input() formats: any;

  constructor() {
  }

  ngOnInit(): void {
    if (this.formats) {
      this.formats.forEach(elm => {
        elm['activeSets'] = elm.creative_set.filter(elm => elm.visible && elm.complete).length;

        if (elm.creatives_status) {
          if (elm.creatives_status.toUpperCase() === 'APPROVED') {
            elm['handled_count'] = elm.creative_set.filter((set) => {
                if (set.status) {
                  return set.visible && set.complete && set.status.toUpperCase() === 'APPROVED';
                }
              }
            ).length;
          } else if (elm.creatives_status.toUpperCase() !== 'PENDING') {
            elm['handled_count'] = elm.creative_set.filter((elm) => {
                if (elm.status) {
                  return elm.visible && (elm.status.toUpperCase() === 'REJECTED' || elm.status.toUpperCase() === 'PENDING');
                }
              }
            ).length;
          }
        }

      });
    }
  }

  checkMinRequirment(format): boolean {
    return format.creative_set.filter(elm => elm.status === 'Approved').length > 0;
  }

}



