import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-campaign-change-warning',
  templateUrl: './campaign-change-warning.component.html',
  styleUrls: ['./campaign-change-warning.component.css']
})
export class CampaignChangeWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CampaignChangeWarningComponent>
  ) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close(false);
  }


  accept(){
    this.dialogRef.close(true);
  }

}
