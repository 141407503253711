import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {PreviewDialogComponent} from "../ad-for-size/preview-dialog/preview-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {AcmAdlistResponseModel} from "../../../models/acm-adlist-response.model";
import {AcmAdlistAdsModel} from "../../../models/acm-adlist-ads.model";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import {ExistingtagmoduleComponent} from "../existingtagmodule/existingtagmodule.component";
import {CampaignService} from "../../../campaign.service";
import {CreativemoduleService} from "../creativemodule/creativemodule.service";
import {MatSelectChange} from "@angular/material/select";

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-ad-archive',
  templateUrl: './ad-archive.component.html',
  styleUrls: ['./ad-archive.component.css']
})
export class AdArchiveComponent implements OnInit {

  adName;
  loading: boolean = false;
  selectedOption = "0";

  fetchingACMAds: boolean = false;
  acmAdList: AcmAdlistAdsModel[];
  brandIndex: number;
  campaignIndex: number;
  brandAndCampaignObj;
  Object;
  selectedCampaignOption = 'showAllCampaigns_g4aF4';
  originalAcmAdList: AcmAdlistAdsModel[];
  selectedBannerId: number;

  updateCreatives: boolean = false;
  adLibrary = [];
  filteredAds = [];

  selectedTemplate = 'SINGLE IMAGE';
  templates = ['SINGLE IMAGE', 'DISPLAY VIDEO', 'SPINCUBE', 'SLIDER', 'FLIPBOARD', 'DECK OF CARDS', 'SHIFTER'];

  constructor(
    public store: StoreService,
    private apiservice: ApiService,
    private _cd: ChangeDetectorRef,
    private globalfunctionality: GlobalfunctionalityService,
    private dialog: MatDialog,
    public campaignService: CampaignService,
    public creativeService: CreativemoduleService
  ) {
    this.Object = Object;
  }

  ngOnInit() {
    this.updateCreatives = this.campaignService.structure.get('config').get('update_creatives').value;
    const existingtag = this.globalfunctionality.getModuleValues('existingtag');

    if (existingtag) {
      if (existingtag.isComplete && existingtag.script !== '') {
        if (existingtag.acm_script) {
          this.changeSelection("1");
        }
      } else {
        this.loading = true;
        this.apiservice.getJSON(this.store.apiURL + '/CreativesServlet?companyId=' + this.campaignService.structure.get('company').value.id +
          '&brandsId=' + this.campaignService.structure.get('brand').get('id').value +
          '&format=' + this.store.selectedSize.size)
          .subscribe(res => {
            if (res.responseCode === 200) {
              res.data.forEach(creative => {
                if(creative['json']) {
                  creative['json'] = JSON.parse(creative['json']);
                }
              });
              this.adLibrary = res.data;

              this.adLibrary = this.adLibrary.filter((ad) => {
                if(!ad.json.media.find(elm => elm.optimization_option === 'weather')){
                  return ad;
                }
              });

              this.filteredAds = this.adLibrary.filter(ad =>
                ad.json.template?.selectedTemplate?.toUpperCase() === this.selectedTemplate  ||
                ad.json.templates?.selectedTemplate?.toUpperCase()  === this.selectedTemplate );
            }
            this.loading = false;
            this.detectChange();
          });
      }
    }

  }

  searchTemplateChange(){
    this.filteredAds = this.adLibrary.filter(ad =>
      ad.json.template?.selectedTemplate?.toUpperCase().includes(this.selectedTemplate)  ||
      ad.json.templates?.selectedTemplate?.toUpperCase().includes(this.selectedTemplate));
  }

  campaignChange() {
    this.acmAdList = this.originalAcmAdList;

    if (this.selectedCampaignOption !== 'showAllCampaigns_g4aF4') {
      this.acmAdList = this.acmAdList.filter((elm: AcmAdlistAdsModel) => elm.grouping[this.campaignIndex] === this.selectedCampaignOption);
    }
    /*if(!this._cd["destroyed"]){
      this._cd.detectChanges();
    }*/
  }

  changeSelection(option?: string): void {

    if (option) {
      this.selectedOption = option;
    }

    if (this.selectedOption === "1") {
      this.loading = false;
      this.fetchingACMAds = true;
      this.apiservice.GET(this.store.apiURL + `/ACMCampaigns?format=${this.store.selectedSize.size}`)
        .subscribe((res: AcmAdlistResponseModel) => {
          res = res['data'];
          // Get brand index, get campaign index, build brand/campaign obj
          this.brandIndex = res.metaData['grouping'].indexOf('Advertiser');
          this.campaignIndex = res.metaData['grouping'].indexOf('Campaign');
          this.brandAndCampaignObj = {};
          res.ads.forEach((elm: AcmAdlistAdsModel) => {
            if (this.selectedBannerId !== undefined) {
              if (this.selectedBannerId === elm.bannerId) {
                elm.selected = true;
              }
            }

            if(!elm.image.includes('https://')){
              elm.image = undefined;
            }

            if (this.brandAndCampaignObj[elm.grouping[this.brandIndex]]) {
              if (this.brandAndCampaignObj[elm.grouping[this.brandIndex]].indexOf(elm.grouping[this.campaignIndex]) === -1) {
                this.brandAndCampaignObj[elm.grouping[this.brandIndex]].push(elm.grouping[this.campaignIndex]);
              }
            } else {
              this.brandAndCampaignObj[elm.grouping[this.brandIndex]] = [];
              this.brandAndCampaignObj[elm.grouping[this.brandIndex]].push(elm.grouping[this.campaignIndex]);
            }
          });

          // Place selected firs tin list
          res.ads.forEach((elm: AcmAdlistAdsModel, index) => {
            if (elm.selected) {
              res.ads.splice(index, 1);
              res.ads.unshift(elm);
            }
          });

          this.acmAdList = res.ads;
          this.originalAcmAdList = Object.assign([], this.acmAdList);
          this.fetchingACMAds = false;
          this.detectChange();
        });
    }
  }


  previewAd(ad) {

    const obj =
      {
        archieveScript: ad.json.script,
        size: ad.size,
        device: ad.device,
        libraryMMSCode: ad.json.creatives.selectedImages ? ad.json.creatives.selectedImages[0].MMSCode : ''
      };

    this.dialog.open(PreviewDialogComponent,
      {
        data: obj,
        panelClass: 'modal-size-auto'
      }
    );

  }

  /**
   * Todo: REFACTOR REMOVE adcreationValues variable
   *
   */
  copyAd(ad) {

    let adcreationValues = this.globalfunctionality.getModuleValues('adcreation');
    adcreationValues = ad.json.adcreation;

    const template = ad.json.template === undefined ? ad.json.templates : ad.json.template;
    this.globalfunctionality.setModuleValue('adcreation', ad.json.adcreation);
    this.globalfunctionality.setModuleValue('templates', template);
    this.globalfunctionality.setModuleValue('creatives', ad.json.creatives);

    this.store.GUIFlow['3'].forEach(elm => {
      if (elm.type === 'adcreation') {
        elm['param']['options'].forEach((option, index) => {
          if (option.value === adcreationValues['selectedMethod']) {
            this.globalfunctionality.adcreationIndexChange.next(index);
          }
        });
        elm['param']['output'] = adcreationValues['selectedMethod'];
        elm['module_values'] = adcreationValues;
      }
    });

    sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
    this.globalfunctionality.detectChange.next(true);

  }

  chooseAd(ad) {

    this.loading = true;
    let data = ad.json;
    const template = ad.json.template === undefined ? ad.json.templates : ad.json.template;
    let width = this.store.selectedSize.size.split("x")[0];
    let height = this.store.selectedSize.size.split("x")[1];

    this.adName = data.creatives.adName;

    const adForSize = this.globalfunctionality.getModuleValues('adforsize');
    adForSize.selected.forEach((elm) => {
      if (elm.device === this.store.selectedSize.format) {
        if (elm.format === this.store.selectedSize.size) {
          elm['complete'] = true;
          elm['creative_set'][this.store.selectedSize.setIndex]['creatives'] = data.creatives;
          elm['creative_set'][this.store.selectedSize.setIndex]['adcreation'] = data.adcreation;
          elm['creative_set'][this.store.selectedSize.setIndex]['templates'] = data.templates;


          // If updating creatives set creative statuses
          if (elm['creative_set'][this.store.selectedSize.setIndex]['complete']) {
            if (this.updateCreatives) {
              elm['creative_set'][this.store.selectedSize.setIndex]['updated'] = true;
            }
          } else {
            elm['creative_set'][this.store.selectedSize.setIndex]['added'] = true;
          }

          elm['creative_set'][this.store.selectedSize.setIndex]['complete'] = true;
          elm['creative_set'][this.store.selectedSize.setIndex]['status'] = 'Pending';
          sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
        }
      }
    });
    this.getAdTemplate(template.selectedTemplate, width, height, data);
  }

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }


  getAdTemplate(selectedTemplate, width, height, data) {

    if (this.store.productType.toUpperCase() !== 'VIDEOANNONS') {
      if (this.campaignService.structure.get('objective').value.id !== 6) {
        if (this.store.selectedSize.size !== '320x320') {
          if (!selectedTemplate.includes(this.store.selectedSize.size)) {
            selectedTemplate = selectedTemplate + " " + this.store.selectedSize.size;
          }
        }
      }
    } else {
      selectedTemplate = 'Video';
    }

    this.apiservice.getJSON(this.store.templateURL + encodeURIComponent(selectedTemplate))
      .subscribe(res => {
        this.store.bannerDetail = res.data[0];

        const start = this.store.bannerDetail.script.indexOf("/**<AdInitParams>**/") + 20;
        const end = this.store.bannerDetail.script.indexOf("/**</AdInitParams>**/");

        const params = JSON.parse(this.store.bannerDetail.params);

        if(this.obtainTemplateName(selectedTemplate) !== 'DISPLAY VIDEO') {
          params.bannerData.media.faces = data.media;
        }else{
          params.bannerData = data.creatives.creative_data.adParams;
        }

        const bannerData = this.store.bannerDetail.script.substring(start, end);
        this.store.bannerDetail.script = this.store.bannerDetail.script.replace(bannerData, JSON.stringify(params));
        this.store.bannerDetail.params = JSON.stringify(params);
        this.createAd();
      });
  }


  createAd() {
    const obj = this.createPostObj();
    const adForSize = this.globalfunctionality.getModuleValues('adforsize');
    this.apiservice.POST(this.store.adServer + '/createBannerLEServlet', obj)
      .subscribe(res => {
        const body = res;
        const data = JSON.parse(body['data']);
        const formatIndex = adForSize.selected.findIndex(elm => elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format);
        const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
        const obj =
          {
            adScript: body.script,
            demoLink: data.demoUrl,
            bannerId: body.bannerId,
            adsscoreUrl: data.adsscoreUrl,
            name: this.adName,
            adFormatName: this.globalfunctionality.getModuleValues('templates').selectedTemplate,
            selectedSize: this.store.selectedSize

          };

        set['banners'] = [obj];

        this.store.creativesForSize = adForSize['selected'];
        this.adName = '';

        // Save the adscript in ad for size module
        adForSize.selected.forEach((elm) => {
          if (elm.device === this.store.selectedSize.format) {
            if (elm.format === this.store.selectedSize.size) {
              elm['creative_set'][this.store.selectedSize.setIndex]['creatives']['script'] = body.script;
              delete elm['creative_set'][this.store.selectedSize.setIndex]['script'];
            }
          }
        });

        sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
        this.detectChange();
        toastr.info("Annons vald");
        this.reset();

      });
  }

  getReplacedFormatName(template): string {
    return template.replace(/\s+/g, '_').toLowerCase();
  }

  createPostObj() {
    const obj = this.store.bannerDetail;

    obj['name'] = this.adName;
    obj['publisher'] = 146;
    obj['tags'] = ['SSISP'];

    obj['name'] = obj['name'] ? obj['name'] : ' ';

    if (obj['vast'] === null) {
      delete obj['vast'];
    }
    if (obj['vpaid'] === null) {
      delete obj['vpaid'];
    }
    if (obj['form'] === null) {
      delete obj['form'];
    }
    if (obj['style'] === null) {
      delete obj['style'];
    }
    return obj;
  }


  reset() {
    $('.modal-backdrop').removeClass('is-active');
    let modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');
    this.store.selectedSize = undefined;

    this.globalfunctionality.adCreated.next(true);
  }

  chooseACMAd(ad) {

    const moduleValue = this.globalfunctionality.getModuleValues("existingtag");
    if (ad.script) {
      moduleValue.isComplete = true;
      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if (elm.type === "existingtag") {
          elm["module_values"].isComplete = true;
        }
      });

      moduleValue["acm_script"] = ad.script;
      moduleValue["product_type"] = ad.script;
      moduleValue["accept_supplier"] = true;
      moduleValue["preview_image"] = ad.image;
      moduleValue["banner_id"] = ad.bannerId;
    } else {
      moduleValue.isComplete = false;
      this.store.GUIFlow[this.store.page].forEach(function (elm) {
        if (elm.type === "existingtag") {
          elm["module_values"].isComplete = false;
        }
      });
    }

    this.globalfunctionality.detectChange.next(true);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

    this.saveScriptForSize();
  }


  saveScriptForSize() {

    const existingtag = this.globalfunctionality.getModuleValues('existingtag');

    if (existingtag.isComplete) {
      const adForSize = this.globalfunctionality.getModuleValues('adforsize');
      const adcreation = this.globalfunctionality.getModuleValues('adcreation');
      const formatIndex = adForSize.selected.findIndex(elm => elm.format === this.store.selectedSize.size && elm.device === this.store.selectedSize.format);

      adForSize['selected'][formatIndex]['complete'] = true;

      const set = adForSize['selected'][formatIndex]['creative_set'][this.store.selectedSize.setIndex];
      set['status'] = 'Pending';

      // If updating creatives set creative statuses
      if (set['complete']) {
        if (this.updateCreatives) {
          set['updated'] = true;
          toastr.info('Material uppdaterat');
        }
      } else {
        set['added'] = true;
        toastr.info('Material sparat');
      }

      // Remove creatives object if exists (user has first created own creative, then changed to third party)
      if (set['creatives']) {
        delete set['creatives'];
      }

      set['adcreation'] = adcreation;
      if (existingtag.acm_script) {
        set['script'] = existingtag;
      } else {
        set['script'] = existingtag;
      }
      set['complete'] = true;
      sessionStorage.setItem('modules', JSON.stringify(this.store.modules));
      this.resetScript(true);
      this.detectChange();
    }

  }

  resetScript(trigger?) {

    $('.modal-backdrop').removeClass('is-active');
    let modal_area: any = document.getElementById('modal-area');
    modal_area.classList.remove('is-active');

    this.store.selectedSize = undefined;
    this.store.selectedMethod = undefined;
    const creationParam = this.globalfunctionality.getModule('adcreation');
    creationParam.param.output = '';

    this.store.GUIFlow[3].forEach((elm) => {
      if (elm.type === 'adcreation') {
        elm.param.output = '';
        const id = elm.module_values.id;
        elm.module_values = {};
        // Save the ID for the module
        elm.module_values['id'] = id;
      }
    });
    this.globalfunctionality.clearModuleValues();
    this.detectChange();
    if (trigger) {
      this.globalfunctionality.adCreated.next(true);
    }

  }

  obtainTemplateName(selectedTemplate) {
    if (selectedTemplate.indexOf(this.store.selectedSize.size) !== -1) {
      return selectedTemplate.replace(this.store.selectedSize.size, '').trim().toUpperCase();
    } else {
      return selectedTemplate.toUpperCase();
    }
  }

  previewACMAd(ad) {
    let obj = {
      script: {
        acm_script: ad.script
      },
      size: this.store.selectedSize.size,
      device: this.store.selectedSize.format
    };

    this.dialog.open(PreviewDialogComponent,
      {
        data: obj,
        panelClass: 'modal-size-auto'
      }
    );
  }

}


