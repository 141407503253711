import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";

@Component({
  selector: 'app-creative-call-to-action',
  templateUrl: 'call-to-action.component.html'
})

export class CallToActionComponent {

  @Input() adCallToActionControl;
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  constructor(public store: StoreService){

  }

  updatePreview(){
    this.triggerUpdatePreview.emit();
  }

}
