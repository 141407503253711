import {Component, Inject, OnInit} from '@angular/core';
import {StoreService} from "../../../../../../services/store.service";
import {ApiService} from "../../../../../../services/api.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public store: StoreService,
    private apiservice: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
  }


  continue(){
      this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }

}
