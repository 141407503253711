import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute, Params} from "@angular/router";
import {StoreService} from "../../../services/store.service";
import {GlobalfunctionalityService} from "../../../services/globalfunctionality.service";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.css']
})
export class UnsubscribeEmailComponent implements OnInit {
  data;
  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,)
  { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.apiservice.GET(this.store.apiURL + "/UnsubscribeCreativesReminderServlet?value=" + encodeURIComponent(params['id']))
        .subscribe(res => {

          if(res !== null) {
            if (Object.keys(res).length > 0) {
              this.data = res;
              if (!this._cd["destroyed"]) {
                this._cd.detectChanges();
              }
            }
          }
        });
    });
  }


  routeToLoginPage(){
    this.router.navigate(["/login"]);
  }

}
