import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StoreService} from "../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import {CreatecampaignComponent} from "../../../../createcampaign/createcampaign.component";
import {TandcDialogComponent} from "../tandc-dialog/tandc-dialog.component";
import {Router} from "@angular/router";
import {PreviewDialogComponent} from "../../../creative_components/ad-for-size/preview-dialog/preview-dialog.component";
import {CampaignService} from "../../../../campaign.service";

declare var moment: any;

@Component({
  selector: 'app-review-ads',
  templateUrl: './review-ads.component.html',
  styleUrls: ['./review-ads.component.css']
})
export class ReviewAdsComponent implements OnInit {

  values;
  sites;

  selectedSite;
  selectedSubcontexts = [];

  productType;

  selectedDeviceOption: string = 'all';

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              public dialog: MatDialog,
              private _create: CreatecampaignComponent,
              private router: Router,
              public campaignService: CampaignService
  ) {
    /*this.Math = Math;*/
  }

  ngOnInit() {
    this.values = this.globalfunctionality.getModuleValues('adforsize');
    const device = this.globalfunctionality.getModuleValues('device');
    this.sites = device ? [...device.sites.map(s => s && s.selected ? s.name : '')].filter(s => s).join(', ') : '';

    if(this.values.selectedDeviceOption){
      this.selectedDeviceOption = this.values.selectedDeviceOption;
    }

    // If context flow obtain selected contexts
    if (this.campaignService.structure.get('objective').value) {
      if (this.campaignService.structure.get('objective').value.id === 1) {
        let contextValues = this.globalfunctionality.getModuleValues("context");
        this.selectedSite = contextValues.contexts.filter(elm => elm.active)[0].context;
        this.selectedSubcontexts = contextValues.contexts.filter(elm => elm.active)[0].sub_contexts.filter(elm => elm.selected);
        if (this.selectedSubcontexts.length === 1) {
          if (this.selectedSubcontexts[0].name.toLowerCase() === 'alla' || this.selectedSubcontexts[0].name.toLowerCase() === 'startsida') {
            this.selectedSubcontexts = [];
          } else {
            this.selectedSubcontexts = this.selectedSubcontexts.reduce((aux, cur) => {
              aux.push(cur.name);
              return aux;
            }, []);
          }
        } else {
          this.selectedSubcontexts = this.selectedSubcontexts.reduce((aux, cur) => {
            aux.push(cur.name);
            return aux;
          }, []);
        }
      }
    }


    if (!this.store.productType) {
      if (this.globalfunctionality.getModuleValues('adtype')) {
        const type = this.globalfunctionality.getModuleValues('adtype')['selectedOption'];
        this.store.productType = type === '0' ? 'BannerAnnons' : type === '2' ? 'SpecialAnnons' : type === '4' ? 'ResponsivAnnons' : 'VideoAnnons';
      }

    }
  }

  changeRoute(link, param) {
    this.router.navigate([link, param]);
  }


  previewCreative(set, format) {
    set['size'] = format.format;
    set['device'] = format.device;
    this.dialog.open(PreviewDialogComponent,
      {
        data: set,
        panelClass: 'modal-size-auto'
      }
    );
  }

  /*
    checkValue(value) {
      this.store.trustedPartnerTakesBill = value;
    }*/


  showDialog() {
    const dialogRef = this.dialog.open(TandcDialogComponent,
      {
        panelClass: 'modal-size-md'
      }
    );
  }
}
