import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-tandc-dialog',
  templateUrl: './tandc-dialog.component.html',
  styleUrls: ['./tandc-dialog.component.css']
})
export class TandcDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<TandcDialogComponent>
  ) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close();
  }

}
