import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {GlobalfunctionalityService} from "../../../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../../../services/store.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

declare var moment: any;

@Component({
  selector: 'app-budget-split-dialog',
  templateUrl: './budget-split-dialog.component.html',
  styleUrls: ['./budget-split-dialog.component.css']
})
export class BudgetSplitDialogComponent implements OnInit {

  selectedContext;
  showFormatSplit = false;
  selectedIndex;
  selectedProduct;

  budgetFormGroup: FormGroup = new FormGroup({});
  subcontextBudgetFormGroup: FormGroup = new FormGroup({});

  minStartDate = new Date();
  maxDate = new Date();
  initialStartDate = new Date();
  minEndDate = new Date();
  initialEndDate = new Date();
  startDateValue = new Date();
  endDateValue = new Date();
  endDateParsed;
  startDateParsed;

  wrongStartDate = false;

  values;

  copyObj;
  Math;

  contexts;

  timeout;

  hasError = false;

  subcontextLastValidValue = {};
  productLastValidValue = {};

  constructor(
    public dialog: MatDialogRef<BudgetSplitDialogComponent>,
    private globalfunctionality: GlobalfunctionalityService,
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public productMinBudget: number
  ) {
    this.Math = Math;
  }

  ngOnInit() {


    this.values = this.globalfunctionality.getModuleValues("budget");
    this.minEndDate = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");
    this.endDateValue = this.values.end === undefined ? this.initialEndDate : moment(this.values.end).format("YYYY-MM-DD");
    this.startDateValue = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");
    this.contexts = this.globalfunctionality.getModuleValues('context');

    // Create deep copy of object so we can reset the user choice if they abort
    this.copyObj = JSON.parse(JSON.stringify(this.contexts));
    this.hasError = this.copyObj['contexts'][0]['sub_contexts'].filter(elm => elm.selected && elm.hasError).length > 0;


    this.getSelectedContext();

  }


  getSelectedContext() {
    this.copyObj.contexts.forEach(elm => {
      if (elm.active) {
        this.selectedContext = elm;
      }
    });

    // Set validation on the products/subcontexts
    this.selectedContext.sub_contexts.forEach((subContext, index) => {

      if (subContext.selected) {
        let subcontextMaxBudget = 0;
        let selectedProducts = 0;

        subContext.products.forEach(product => {
          if (product.selected) {
            product.budget = isNaN(product.budget) ? 0 : product.budget;
            product.impressions = isNaN(product.impressions) ? 0 : product.impressions;
            product.budget_percent = isNaN(product.budget_percent) ? 0 : product.budget_percent;

            selectedProducts += 1;
            product['formats_parsed'] = JSON.parse(product['formats']);

            this.productLastValidValue[product.id_products] = product.budget;
            if(this.budgetFormGroup.controls[product.id_products]){
              this.budgetFormGroup.controls[product.id_products].setValue(product.budget);
            }else {
              this.budgetFormGroup.addControl(product.id_products, new FormControl({
                value: product.budget,
                disabled: this.productMinBudget > Math.round(product.maxBudget)
              }, [
                Validators.required,
                Validators.max(Math.round(product.maxBudget)),
                Validators.min(this.productMinBudget),
                Validators.maxLength(11)
              ]));
            }
            subcontextMaxBudget += product.maxBudget;
          }
        });

        subContext['min_budget'] = this.productMinBudget * subContext['products'].filter(elm => elm.selected && elm.budget > 0).length;
        subContext['max_budget'] = Math.round(subcontextMaxBudget);

        this.subcontextLastValidValue[subContext.name] = subContext.total_budget_for_subcontext;

        this.subcontextBudgetFormGroup.addControl(subContext.name, new FormControl(
          {value: subContext.total_budget_for_subcontext, disabled: (subContext.min_budget > subContext.max_budget) || subContext.min_budget  === 0}, [
          Validators.required,
          Validators.min(subContext.min_budget),
          Validators.max(subContext.max_budget)
        ]));
      }

    });

    this.calTotalForSite();
  }

  calTotalForSite(){
    let total = 0;
    let imps = 0;
    this.selectedContext.sub_contexts.forEach(elm => {
      if(elm.selected) {
        elm.total_budget_for_subcontex = Number(isNaN(elm.total_budget_for_subcontext) ? 0 : elm.total_budget_for_subcontext);
        elm.total_impressions_for_subcontext = isNaN(elm.total_impressions_for_subcontext) ? 0 : elm.total_impressions_for_subcontext;

        total += elm.total_budget_for_subcontext;
        imps += elm.total_impressions_for_subcontext;

        this.subcontextBudgetFormGroup.value[elm.name] = elm.total_budget_for_subcontext;
      }
    });
    this.subcontextBudgetFormGroup.setValue(this.subcontextBudgetFormGroup.value);
    this.selectedContext.total_budget = total;
    this.selectedContext.total_impressions = imps;


    if(this.hasError){
      this.checkFormGroups();
    }

    this.detectChange();
  }

  detectChange(){
    if(!this._cd['destroyed']){
      this._cd.detectChanges();
    }
  }

  subcontextBudgetModified(subcontext, newBudget) {
      newBudget = newBudget.replace(/\s/g, '');
      if(Number(newBudget) >= subcontext.min_budget) {
        this.copyObj = this.globalfunctionality.updateBudgetSubContext(this.copyObj, subcontext.name, Number(newBudget), this.productMinBudget, {
          mobile: 0.5,
          desktop: 0.5
        }, 1, Number(this.subcontextLastValidValue[subcontext.name]));

        this.subcontextLastValidValue[subcontext.name] = Number(newBudget);
        this.getSelectedContext();
      }
  }

  productBudgetModified(product, newBudget) {
      newBudget = newBudget.replace(/\s/g, '');
      if (Number(newBudget) >= this.productMinBudget) {
        this.copyObj = this.globalfunctionality.updateBudgetProduct(this.copyObj, product.id_products, Number(newBudget), this.productLastValidValue[product.id_products]);
        this.productLastValidValue[product.id_products] = Number(newBudget);
        this.getSelectedContext();
      }
  }


  toggleFormatSplit(product, index) {
    if (this.selectedIndex === index) {
      this.selectedIndex = undefined;
    } else {
      this.selectedIndex = index;
    }

    this.selectedProduct = product;
    this.showFormatSplit = !this.showFormatSplit;
  }

  setSoonAsPossible() {

  }


  placeCustomButton() {
    setTimeout(() => {
      let calendar: any = document.getElementsByTagName('mat-datepicker-content');
      let soonAsBtn: any = document.createElement('div');
      soonAsBtn.innerText = "Så snart som möjligt";
      soonAsBtn.setAttribute("id", "soon_as_button");
      soonAsBtn.classList.add("mt-datepicker-btn-asap");
      /*soonAsBtn.addEventListener('click', this.setSoonAsPossible());*/
      soonAsBtn.onclick = () => {
        this.setSoonAsPossible();
      };
      calendar[0].append(soonAsBtn);
    }, 1000);
  }

  changeStartDate() {

  }


  changeEndDate() {


  }

  checkIfComplete() {
    this._cd.detectChanges();
  }


  checkFormGroups(): boolean{
    let complete = true;
    for (let i in this.budgetFormGroup.controls) {
      this.budgetFormGroup.controls[i].markAsTouched();
      this.budgetFormGroup.controls[i].markAsPristine();
      if (this.budgetFormGroup.controls[i].errors && !this.budgetFormGroup.controls[i].disabled) {
        complete = false;
      }
    }

    for (let i in this.subcontextBudgetFormGroup.controls) {
      this.subcontextBudgetFormGroup.controls[i].markAsTouched();
      this.subcontextBudgetFormGroup.controls[i].markAsPristine();
      if (this.subcontextBudgetFormGroup.controls[i].errors && !this.subcontextBudgetFormGroup.controls[i].disabled) {
        complete = false;
      }
    }

      return complete;
  }


  save() {

    if(this.checkFormGroups()){
      this.globalfunctionality.setModuleValue('context', this.copyObj);
      this.dialog.close(true);
    }
  }

  abort() {
    this.dialog.close(false);
  }

}
