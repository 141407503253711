import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {ApiService} from "../../../../services/api.service";
declare var toastr: any;
@Component({
  selector: 'app-mail-dialog',
  templateUrl: './mail-dialog.component.html',
  styleUrls: ['./mail-dialog.component.css']
})
export class MailDialogComponent implements OnInit {

  subject;
  message;
  readyToSend = false;

  constructor(
    public dialogRef: MatDialogRef<MailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: StoreService,
    private apiservice: ApiService) { }

  ngOnInit() {
  }

  /*"send_to": "johny.premanantham@adssets.com"*/
  /*"send_to": "selfservice@schibsted.com"*/
  sendMail(){
    let obj = {
      "mail_subject": this.subject,
      "mail_html_body": "<div>Support request from: [user]</div><br><div>Message: \n [message]</div>",
      "mail_from": this.store.user.user_email,
      "send_to": "admarket@schibsted.se"
    };
    /*"send_to": "selfservice@schibsted.com"*/
    obj.mail_html_body = obj.mail_html_body.replace("[message]", this.message);
    obj.mail_html_body = obj.mail_html_body.replace("[user]", this.store.user.user_email);

    this.apiservice.postJSON(this.store.apiURL + "/MailServlet", obj)
      .subscribe(res => {
        toastr.info(this.store.langJSON.component.mail_dialog.toastrmsg);
        this.dialogRef.close();
      });
  }

  checkIfComplete(){
    let complete = false;
    if(this.subject !== undefined && this.subject !== ""){
      if(this.message !== undefined && this.message !== ""){
        complete = true;
      }
    }
    this.readyToSend = complete;

  }

}
