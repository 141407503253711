import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {StoreService} from "../../../../../../../../services/store.service";
import {CreativemoduleService} from "../../../creativemodule.service";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";
import {FormControl, FormGroup} from "@angular/forms";
import {CampaignService} from "../../../../../../campaign.service";
import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'app-creative-image-cropped-upload',
  templateUrl: 'image-cropped-upload.component.html'
})

export class ImageCroppedUploadComponent implements AfterViewInit, OnInit {

  @ViewChild('croppedImageElm') croppedImageElm;
  @ViewChild('croppedLogoElm') croppedLogoElm;

  hideImpAndClickTracker: boolean = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  croppedImageQuality = 100;
  croppedLogoQuality = 100;

  imageChangedEventLogo: any = '';
  croppedImageLogo: any = '';

  uploadedCropperLogoType;
  uploadedCropperImageType;

  croppedImageIncrementor;
  croppedLogoIncrementor;

  croppedImageLoad: boolean = false;
  croppedImageLoadPercentage = 0;

  croppedLogoLoad: boolean = false;
  croppedLogoLoadPercentage = 0;


  showCroppedImageWeightError: boolean = false;
  showCroppedLogoWeightError: boolean = false;

  showBlocketLogoWidthWarning: boolean = false;

  croppedImageIllegalFiletype: boolean = false;
  croppedLogoIllegalFiletype: boolean = false;

  showGIFAnimatedWarning = false;
  showGIFAnimatedWarningLogo = false;

  videoExtension: string;

  @Input() pictureA;
  @Input() pictureB;
  @Input() subObjective;
  @Input() faceGroup: FormGroup;

  @Output() triggerCheckIfComplete: EventEmitter<any> = new EventEmitter();
  @Output() triggerUpdatePreview: EventEmitter<any> = new EventEmitter();

  dynamicAd: boolean = false;
  selectedSite: string;

  constructor(public store: StoreService,
              public creativeService: CreativemoduleService,
              private _globalfunctionality: GlobalfunctionalityService,
              private _cd: ChangeDetectorRef,
              public campaignService: CampaignService) {

  }

  ngOnInit(): void {
    if(this.campaignService.structure.get('objective').value.id === 1){
      this.dynamicAd = this._globalfunctionality.getModuleValues('adforsize').dynamic_ad;
      this.selectedSite = this._globalfunctionality.getModuleValues('context')?.contexts[0]?.context?.toUpperCase();
    }
  }

  ngAfterViewInit(): void {
    const values = this._globalfunctionality.getModuleValues("creatives");
    if (values.videoExtension) {
      this.videoExtension = values.videoExtension;
    }
    const previewArea: HTMLElement = document.getElementById("previewIframe");
    if (previewArea !== null) {
      previewArea.style.display = "block";
    }

  }

  fileChangeEvent(event: any): void {

    this.showCroppedImageWeightError = false;
    this.imageChangedEvent = null;
    const image: any = new Image();
    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();

    if (this.creativeService.allowed_types.indexOf(file.type) === -1) {
      let input: any = document.getElementById('picture-a');
      if (input) {
        input.value = "";
      }
      image.src = "";
      this.croppedImageIllegalFiletype = true;
    } else {
      this.croppedImageIllegalFiletype = false;
      this.croppedImageQuality = 100;
      this.croppedImageLoad = true;
      this.showCroppedImageWeightError = false;

      let incrementor = setInterval(() => {
        if (this.croppedImageLoadPercentage < 90) {
          this.croppedImageLoadPercentage += 5;
        }
        this.detectChanges();
      }, 100);
      this.uploadedCropperImageType = file.type.split('/')[1];
      myReader.readAsDataURL(file);
      myReader.onloadend = (loadEvent: any) => {


        image.src = loadEvent.target.result;
        this.showGIFAnimatedWarning = false;
        if (file.type === 'image/gif') {
          if (this.isAnimated(image.src)) {
            this.showGIFAnimatedWarning = true;
            this.croppedImageLoadPercentage = 100;
            clearInterval(incrementor);
            this.croppedImageLoad = false;
            this.croppedImageLoadPercentage = 0;
          } else {
            this.imageChangedEvent = event;
          }
        } else {
          this.imageChangedEvent = event;
        }
        this.detectChanges();
      };
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    const file = this.base64ToFile(event.base64);
    const fileSize: number = file.size / 1024;
    if (fileSize <= 150) {
      this.store.showImageError = false;
      this.croppedImage = event.base64;
      this.faceGroup.get('imageSrc').setValue(this.croppedImage);
      this.faceGroup.get('imageSrcType').setValue(file.type);
    } else {
      this.showCroppedImageWeightError = true;
      this.croppedImageQuality -= 1;
      this.detectChanges();
      this.croppedImageElm.crop();
    }
    this.triggerCheckIfComplete.emit();
  }

  croppedImageLoaded() {
    this.croppedImageLoadPercentage = 100;
    clearInterval(this.croppedImageIncrementor);
    this.croppedImageLoad = false;
    this.croppedImageLoadPercentage = 0;
  }


  fileChangeEventLogo(event: any): void {
    this.showCroppedLogoWeightError = false;
    this.imageChangedEventLogo = null;

    // reset width warning
    this.showBlocketLogoWidthWarning = false;

    const image: any = new Image();
    const file: File = event.target.files[0];
    const myReader: FileReader = new FileReader();

    if (this.creativeService.allowed_types.indexOf(file.type) === -1) {
      let input: any = document.getElementById('picture-b ');
      if (input) {
        input.value = "";
      }
      image.src = "";
      this.croppedLogoIllegalFiletype = true;
    } else {
      this.croppedLogoIllegalFiletype = false;
      this.croppedLogoLoad = true;
      this.croppedLogoQuality = 100;
      this.showCroppedLogoWeightError = false;

      this.croppedLogoIncrementor = setInterval(() => {
        if (this.croppedLogoLoadPercentage < 90) {
          this.croppedLogoLoadPercentage += 5;
        }
        this.detectChanges();
      }, 100);

      this.uploadedCropperLogoType = file.type.split('/')[1];
      myReader.readAsDataURL(file);

      // Blocket searchword set aspect ratio to the same as the picture
      if (this.store.productType === 'FreeTextAnnons') {
        myReader.onload = () => {
          image.onload = () => {
            this.pictureB.aspect_ratio = image.width / image.height;
            if (image.width < 300) {
              this.showBlocketLogoWidthWarning = true;
            }
          };
        };
      }

      myReader.onloadend = (loadEvent: any) => {
        image.src = loadEvent.target.result;
        this.showGIFAnimatedWarningLogo = false;

        if (file.type === 'image/gif') {
          if (this.isAnimated(image.src)) {
            this.showGIFAnimatedWarningLogo = true;
            this.croppedLogoLoadPercentage = 100;
            clearInterval(this.croppedLogoIncrementor);
            this.croppedLogoLoad = false;
            this.croppedLogoLoadPercentage = 0;
          } else {
            this.imageChangedEventLogo = event;
          }
        } else {
          this.imageChangedEventLogo = event;
        }
        this.detectChanges();
      };
    }
  }

  imageCroppedLogo(event: ImageCroppedEvent) {
    const file = this.base64ToFile(event.base64);
    const fileSize = file.size / 1024;
    if (fileSize <= 150) {
      this.store.showLogoError = false;
      this.croppedImageLogo = event.base64;
      this.faceGroup.get('logoSrc').setValue(this.croppedImageLogo);
      this.faceGroup.get('logoSrcType').setValue(file.type);
      this.triggerCheckIfComplete.emit();
    } else {
      this.showCroppedLogoWeightError = true;
      this.croppedLogoQuality -= 1;
      this.detectChanges();
      this.croppedLogoElm.crop();
    }
  }


  base64ToFile(data, filename = 'test') {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

 /* stepped_scale(img, width, step, type) {
    const canvas = document.createElement('canvas'),
      ctx = canvas.getContext("2d"),
      oc = document.createElement('canvas'),
      octx = oc.getContext('2d');


    canvas.width = width; // destination canvas size
    canvas.height = canvas.width * img.height / img.width;

    if (img.width * step >= width) { // For performance avoid unnecessary drawing
      var mul = 1 / step;
      var cur = {
        width: Math.floor(img.width * step),
        height: Math.floor(img.height * step)
      };

      oc.width = cur.width;
      oc.height = cur.height;

      let paddingPixels = 6;
      let padding = {
        vertical: Math.max(paddingPixels, Math.floor(paddingPixels * (img.height / img.width))),
        horizontal: Math.max(paddingPixels, Math.floor(paddingPixels * (img.width / img.height)))
      };
      octx.beginPath();
      octx.rect(0, 0, cur.width, cur.height);
      octx.fillStyle = this.pictureB.background_color;
      octx.fill();
      octx.drawImage(img, padding.horizontal, padding.vertical, cur.width - (padding.horizontal * 2), cur.height - (padding.vertical * 2));


      while (cur.width * step > width) {
        cur = {
          width: Math.floor(cur.width * step),
          height: Math.floor(cur.height * step)
        };
        octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
      }
      ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }

    const resampledImage = new Image();
    resampledImage.src = canvas.toDataURL();
    this.faceGroup.get('logoSrc').setValue(resampledImage.src);
    this.faceGroup.get('logoSrcType').setValue(type);
    this.triggerCheckIfComplete.emit();
  }*/


  croppedLogoLoaded() {
    this.croppedLogoLoadPercentage = 100;
    clearInterval(this.croppedLogoIncrementor);
    this.croppedLogoLoad = false;
    this.croppedLogoLoadPercentage = 0;
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  isAnimated(b64) {
    var b64 = b64.split(',')[1];
    /*var img: any = new Uint8Array(this._base64ToArrayBuffer(b64));*/
    var arr: any = new Uint8Array(this._base64ToArrayBuffer(b64));
    var i, len, length = arr.length, frames = 0;


    for (i = 0, len = length - 3; i < len && frames < 2; ++i) {
      if (arr[i] === 0x00 && arr[i + 1] === 0x21 && arr[i + 2] === 0xF9) {
        let blocklength = arr[i + 3];
        let afterblock = i + 4 + blocklength;
        if (afterblock + 1 < length &&
          arr[afterblock] === 0x00 &&
          (arr[afterblock + 1] === 0x2C || arr[afterblock + 1] === 0x21)) {
          frames++;
        }
      }
    }

    // if frame count > 1, it's animated
    return (frames > 1);
  }

  detectChanges() {
    if (!this._cd["destroyed"]) {
      this._cd.detectChanges();
    }
  }

}
