import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {StoreService} from "../../../../../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../../../../../services/api.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-catalog-selector',
  templateUrl: 'catalog-selector.component.html'
})

export class CatalogSelectorComponent implements OnInit{
  @Input() feedOptionControl: FormControl;
  @Input() disabled: boolean;
  @Output() addNewFeed: EventEmitter<any> = new EventEmitter();
  feed_options = [];

  constructor(public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef){

  }

  ngOnInit(): void {
    this.apiservice.getJSON(this.store.apiURL + "/ACMCatalogueServlet")
      .subscribe(res => {
        if(res.responseCode === 200){
          this.feed_options = res.data.catalogs;
          this._cd.detectChanges();
        }
      });
  }

  redirect(){
    this.addNewFeed.emit();
  }

  compareValue(o1: any, o2: any): boolean {
      return o1.id === o2.id;
  }
}
