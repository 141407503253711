import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {ApiService} from "../../../../../services/api.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import { MatDialog } from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {CampaignService} from "../../../campaign.service";
import {CreativemoduleService} from "../../creative_components/creativemodule/creativemodule.service";

@Component({
  selector: 'app-adtype',
  templateUrl: './adtype.component.html',
  styleUrls: ['./adtype.component.css']
})
export class AdtypeComponent implements OnInit, OnDestroy {

  @Input() set addecision(value: string){

    let adtypeVal = this.globalfunctionality.getModuleValues("adtype");

    this.selectedAdDecision = value;

    // If addecision is blocket searchword set adtype to 3 === 'blocket searchword'
    if(value === '1'){
      this.selectedOption = '3';
    }

    // If addecsion changes back to 'standardannons' set default adtyp value 'banner'
    if(!adtypeVal.selectedOption) {
      if (value === '0') {
        this.selectedOption = '0';
      }
    }else{
      this.selectedOption = adtypeVal.selectedOption;
    }

    this.checkIfComplete();

  }


  disableVideoAdType: boolean = false;
  selectedOption;
  selectedAdDecision: string;


  videoAdTypeSubscription: Subscription;

  constructor(public store: StoreService,
              private _cd: ChangeDetectorRef,
              private apiservice: ApiService,
              private globalfunctionality: GlobalfunctionalityService,
              private _creativeService: CreativemoduleService,
              public dialog: MatDialog,
              public campaignService: CampaignService,
              ) {
  }

  ngOnDestroy(): void {
    if(this.videoAdTypeSubscription) {
      this.videoAdTypeSubscription.unsubscribe();
    }
  }

  ngOnInit() {
   this.videoAdTypeSubscription =  this.globalfunctionality.disableVideoAdType
      .subscribe((hide: boolean) => {
        if(hide !== null) {
          this.disableVideoAdType = hide;
          this.detectChange();
        }
      });

    /**
     * If variable was true force it to false when on audience track, user can have navigated from another track to audience track
     * If not will result in bug where option is disabled because it previously was disabled on another track
     */
   if(this.campaignService.structure.get('objective').value.id === 2){
     this.globalfunctionality.disableVideoAdType.next(false);
   }

    this.checkIfComplete(false);
  }


  detectChange(){
    if(!this._cd['destroyed']){
      this._cd.detectChanges();
    }
  }

  optionChanged(){
    // Clear context choices
    this.detectChange();
    this.globalfunctionality.setModuleValue('context', {});
    if(this.campaignService.structure.get('objective').value.id === 2){
      this._creativeService.resetFormatType.next(true);
    }
    this.checkIfComplete(true);
  }

  checkIfComplete(change?){
    /*This module is used in both the local flow and context flow, it needs to be handled differently depending on which flow, because it is used on different pages depending on flow*/
    const values = this.globalfunctionality.getModuleValues("adtype");

    if(values) {
      if(this.selectedOption !== undefined) {
        values['selectedOption'] = this.selectedOption;
      }else{
        if(values['selectedOption']){
          this.selectedOption = values['selectedOption'];
        }else{
          this.selectedOption = '0';
          values['selectedOption'] = this.selectedOption;
        }
      }

      switch (this.selectedOption) {
        case '0':
          values['selectedType'] = 'Bannerannons';
          break;
        case '1':
          values['selectedType'] = 'Videoannons';
          break;
        case '2':
          values['selectedType'] = 'SpecialAnnons';
          break;
        case '3':
          values['selectedType'] = 'FreeTextAnnons';
          break;
        case '4':
          values['selectedType'] = 'ResponsivAnnons';
          break;
      }

      const page = (this.campaignService.structure.get('objective').value.id === 1 || this.campaignService.structure.get('objective').value.id === 7) ? 2 : 3;

      if (this.store.GUIFlow[page]) {
        this.store.GUIFlow[page].forEach(function (elm) {
          if (elm.type === "adtype") {
            elm["module_values"].isComplete = true;
            values.isComplete = true;
          }
        });
      }
    }

    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

    if(change){
      this.globalfunctionality.setModuleValue('adforsize', {});
      this.globalfunctionality.adtypeChange.next(true);
    }

    if(this.campaignService.structure.get('objective').value.id === 1 || this.campaignService.structure.get('objective').value.id === 7){
      this.globalfunctionality.page2Checker.next(true);
    }
    if(this.campaignService.structure.get('objective').value.id === 2){
      this.globalfunctionality.page3Checker.next(true);
    }
  }

}
