import {Injectable} from '@angular/core';
import {AbstractControl, Form, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';
import {ObjectiveByCompanyResponseModel} from '../dashboard/campaign/models/objective-by-company-response.model';

@Injectable()
export class StoreService {
  /* START ENDPOINT SERVER CONFIG */
  /*!!!!!!!!!! DONT FORGOT TO CHANGE GOOGLE MAPS API KEY ON INDEX.HTML !!!!!!!!!!!!!!*/

  // compodoc -p src/tsconfig.app.json -s

  // ng serve -c production
  // ng serve -c dev
  // ng serve -c legacy
  // ng serve -c local

  // ng build -c production
  // ng build -c dev
  // ng build -c legacy
  // ng build -c local

  // PROD BUILD
  // ng build -c production
  // ng build -c dev-deployment
  // ng build -c legacy-deployment

  /*ADMARKET REST ENDPOINT*/
  apiURL = environment.endpoint;

  /*AD TEMPLATE ENDPOINT*/
  templateURL = environment.template_endpoint;

  /*AD CREATION SERVER*/
  adServer = environment.adServer;

  /*SAVE IMAGE SERVER*/
  imageServerUrl = environment.imageServerUrl;

  ACMFeedTransformerFormatId = environment.ACMFeedTransformerFormatId;

  /*ng build --prod --env=prod*/
  /*ng build --prod --build-optimizer*/

  /* END ENDPOINT SERVER CONFIG */

  user: any;
  admin: boolean = false;
  client: boolean = false;

  showApplication = true;

  showComponent = true;
  showLoadingIcon = false;


  // Holds the product type that the user has chosen e.g. BannerAnnons, VideoAnnons, SpecialAnnons
  productType;


  // Objective promp message if user has continued
  prompChangeMessage = false;

  // Audience reach warning
  showReachWarning = false;
  reachPosition;


  noteText;

  showImageError: boolean = false;
  showLogoError: boolean = false;

  // Used for deciding which file upload for CPC that should be visible, depending on user targeting selection
  showImageA: boolean = false;
  showImageB: boolean = false;

  showHorizontalImageError = false;
  freeCampaign;

  // Management tab index
  selectedManagementTabIndex: any = 0;


  /* FOR OVERRIDING TOP NAV Z INDEX SO THAT SCHEDULE DOES NOT GET PLACED ABOVE TOP NAV, SHOULD BE ACTIVE WHEN SCHEDULE IS OPEN */
  overrideTopNavZIndex: boolean = false;

  showAdblockWarningOnce = true;

  // Build creatives for multiple sizes
  selectedSize;

  // Languages
  selectedLangCode = 'sv';
  allLanguages;
  languageList = [];
  selectedLanguage = 'Svenska';
  langJSON;

  loadObjectiveComponenentOnce = true;

  // Forcast
  forecast = {
    date_from: '',
    date_to: '',
    placements: [],
    geo_locations: [],
    genders: [],
    interests: [],
    ages: [],
    impressions: '',
    frequency: [],
    products: [],
    days: [],
    hours: [],
    custom_targets: []
  };

  contextForecast = {
    date_from: '',
    date_to: '',
    frequency: [],
    days: [],
    hours: []
  };


  trustedPartnerTakesBill = 1;
  billingPartner = 1;

  moduleValues;

  modules: any[] | null;
  GUIFlow = {};


  // Companies/brands list, left navigation
  companiesList = [];

  userObj;

  userCompany;
  selectedBrand;

  // Used for displaying the sizes that has been "selected" through the device module
  creativesForSize; // Change to selectedFormats

  /* selectedMethodIndex;*/
  selectedMethod;

  // App level is used for knowing which menu to display, different levels of the app has different side menus
  appLevel;

  // For highlighting the selected menu item
  selectedMenuItem = 0;


  // Creatives module variables
  adName;
  bannerDetail;
  bannerId;

  // For storing current page
  page;

  // For showing loading icon
  loadPage: boolean = false;

  // For loading sync
  syncLoading: boolean = false;

  checkOnce = true;
  className = '';

  searchBrand: any = {};


  // Brand list loader
  brandListLoading: boolean = true;

  activeTasks: number = 0;


  // Blocks feed creation until feed thats uploaded is done (response is returned)
  blockFeedCreation: boolean = false;


  /*START USER/COMPANY TYPES*/

  // User types
  isAdmin = {value: false}; // isAdmin = Schibsted admin
  isSalesRep = {value: false}; // isAdmin = Schibsted admin
  isAdminCompanyManager = {value: false}; // isAdminCompanyManager = Schibsted company manager
  isAdminCampaignManager = {value: false}; // isAdminCampaignManager = Schibsted campaign manager
  isUser = {value: false}; // isUser = "normal user"
  isCompanyAdministrator = {value: false}; //  isCompanyAdministrator = "normal user's" company admin
  isBooking = {value: false}; //  Can book campaign on users available companies

  // Company types

  isTrustedPartner = {value: false}; // Has ability to create a campaign for any company of Schibsted's company pool, can decide if they want the bill or bill the selected company
  isMediaAgency = {value: false}; // Will only se a subset of companies from Schibsted's company pool, can not decide who takes the bill, the media agency will always be billed
  isAgency = {value: false}; // "Normal" user that will only be able to create a campaign for itself.

  /*END USER/COMPANY TYPES*/
  managementMenu = [
    {
      name: 'Users',
      icon: 'assets/icons/flat-icons/group.svg',
      function: 'users',
      key: 'users',
      show:
        this.isAdmin.value ||
        this.isCompanyAdministrator.value ||
        this.isAdminCompanyManager.value
    },
    {
      name: 'Brand',
      icon: 'assets/icons/flat-icons/tag.svg',
      function: 'pin_brand',
      key: 'pin_brand',
      show: this.isCompanyAdministrator.value && this.isTrustedPartner.value
    },
    {
      name: 'Companies',
      icon: 'assets/icons/flat-icons/cubes.svg',
      function: 'companies',
      key: 'companies',
      show: this.isAdmin.value || this.isAdminCompanyManager.value
    },
    {
      name: 'News',
      icon: 'assets/icons/flat-icons/cubes.svg',
      function: 'news',
      key: 'news',
      show: this.isAdmin.value
    }
  ];


  mainMenu = [
    {
      id: 1,
      name: 'Campaigns',
      icon: 'assets/icons/flat-icons/megaphone.svg',
      function: 'campaigns',
      key: 'campaigns',
      show: true
    },
    {
      id: 5,
      name: 'Data Management',
      icon: 'assets/icons/flat-icons/megaphone.svg',
      function: 'data_management',
      key: 'data_management',
      show: true
    },
    {
      id: 4,
      name: 'Management',
      icon: 'assets/icons/flat-icons/suitcase.svg',
      function: 'management',
      key: 'management',
      show:
        this.isAdmin.value ||
        this.isCompanyAdministrator.value ||
        this.isAdminCompanyManager.value
    },
    {
      id: 3,
      name: 'Tasks',
      icon: 'assets/icons/flat-icons/checked.svg',
      function: 'tasks',
      key: 'task',
      show: false
       /* this.isAdmin.value ||
        this.isAdminCampaignManager.value ||
        this.isAdminCompanyManager.value*/
    }
  ];

  constructor() {

  }


}
