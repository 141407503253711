import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {StoreService} from "../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../services/api.service";

@Component({
  selector: 'app-removecampaign-dialog',
  templateUrl: './removecampaign-dialog.component.html',
  styleUrls: ['./removecampaign-dialog.component.css']
})
export class RemovecampaignDialogComponent implements OnInit {


  authorized = false;
  showFreeCampaignMessage = false;

  constructor(public dialogRef: MatDialogRef<RemovecampaignDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private globalfunctionality: GlobalfunctionalityService,
              public store: StoreService,
              private apiservice: ApiService) {
  }


  ngOnInit() {
    this.store.companiesList.forEach(comp => {
      comp.brands.forEach(brand => {
        if(brand.brands_id === this.data.campaign.brand){
          if(brand.write){
            this.authorized = true;
          }
        }
      });
    });

   /* this.authorized = this.globalfunctionality.checkIfBrandIsAuthorized(this.data.campaign.brand);*/



  }


  remove(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }


}
