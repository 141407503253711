import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {ApiService} from "../../../../../services/api.service";
import {StoreService} from "../../../../../services/store.service";
import {WarningConfirmationDialogComponent} from "../../../../../services/dialogs/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  displaySites = [
    {name: "Aftonbladet", value: 'aftonbladet.png', selected: true},
    {name: "Blocket", value: 'blocket.png', selected: true},
    {name: "Omni", value: 'omni.png', selected: true},
    {name: "SvD", value: 'svd.png', selected: true},
    {name: "Tv.nu", value: 'tv.png', selected: true},
    {name: "Klart.se", value: 'klart.png', selected: true}
  ];
  selectedDevice: number = 0;
  selectedOperatingSystem: number = 0;

  deviceOptions = [
    {
      name: 'Alla',
      id_custom_target: 0,
      disabled: false
    }
  ];

  operatingSystemOptions = [
    {
      name: 'Båda',
      id_custom_target: 0
    }
  ];

  minSiteSelected: boolean = true;
  sites;

  selectedSubObjective;

  previousValue = {value: 0};


  constructor(
    private globalfunctionality: GlobalfunctionalityService,
    private apiservice: ApiService,
    private store: StoreService,
    private _cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }


  ngOnInit() {
    const values = this.globalfunctionality.getModuleValues('device');
    this.selectedSubObjective = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective;

    if (values) {
      this.apiservice.getJSON(this.store.apiURL + "/CustomTargetsServlet")
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {


            if (values.sites) {
              this.sites = values.sites;
            } else {
              let sites = [];
              res.data['sites'].forEach(elm => {
                if ((elm.target_option.toLowerCase() === 'aftonbladet' && this.selectedSubObjective.id === 2) || this.selectedSubObjective.id !== 2) {
                  elm['name'] = elm['target_option'];
                  elm['selected'] = true;
                  sites.push(elm);
                }
              });
              this.sites = sites;
            }

            // Click to site
            if (this.selectedSubObjective.id === 1 || this.selectedSubObjective.id === 3) {
              res.data['devices'].forEach((elm, index) => {
                switch (elm.target_option.toLowerCase()) {
                  case 'desktops & laptops':
                    elm['name'] = 'Desktop';
                    this.deviceOptions.push(elm);
                    break;
                  case 'mobiles':
                    elm['name'] = 'Mobil';
                    this.deviceOptions.push(elm);
                    break;
                  case 'tablets':
                    res.data['devices'].splice(index, 1);
                }
              });
            }

            // App download
            if (this.selectedSubObjective.id === 2) {
              // Set device to mobile
              res.data['devices'].forEach((elm) => {
                if (elm.target_option.toLowerCase() === 'mobiles') {
                  elm['name'] = 'Mobil';
                  this.deviceOptions.push(elm);
                }
              });
              this.selectedDevice = this.deviceOptions[1].id_custom_target;

              // If device has already been intialized do not trigger these events, they will reset values
              if (!values.selectedTargetOption) {
                this.deviceChange(0);
                this.triggerDeviceSubscription();
              }

              // Get operating systems
              res.data['operating_system'].forEach(elm => {
                switch (elm.target_option.toLowerCase()) {
                  case 'android':
                    elm['name'] = 'Google Play';
                    this.operatingSystemOptions.push(elm);
                    break;
                  case 'ios':
                    elm['name'] = 'App Store';
                    this.operatingSystemOptions.push(elm);
                    break;
                }
              });
            }


            if (values.selectedDeviceIndex) {
              this.selectedDevice = values.selectedDeviceIndex;
            }

            if (values.selectedOSIndex) {
              this.selectedOperatingSystem = values.selectedOSIndex;
            }


            this.checkIfComplete();
            this.detectChange();
          }


        });

    }
  }


  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  triggerDeviceSubscription() {
    this.globalfunctionality.deviceChange.next(true);
  }

  triggerSiteSubscription() {
    this.globalfunctionality.siteChange.next(true);
  }

  triggerOperatingSystemSubscription() {
    this.globalfunctionality.resetAdforsize.next(true);
  }


  operatingSystemChange(value) {


    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    if (adforsize.isComplete) {
      const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'OBS!',
          desc: 'Om du ändrar ditt val försvinner dina skapade annonser och måste återskapas.',
          confirm_btn: 'Fortsätt',
          abort_btn: 'Avbryt'
        }
      });

      dialog.afterClosed().subscribe(reset => {
        if (reset) {
          this.changeOS(value);
        } else {
          this.selectedOperatingSystem = this.previousValue.value;
          this.detectChange();
        }
      });

    } else {
      this.changeOS(value);
    }


  }


  changeOS(value) {
    this.previousValue = {value: value};
    this.checkIfComplete();
    this.triggerOperatingSystemSubscription();
  }


  changeSiteSelection(site) {

    let adforsize = this.globalfunctionality.getModuleValues('adforsize');
    if (adforsize.isComplete) {
      const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'OBS!',
          desc: 'Om du ändrar ditt val försvinner dina skapade annonser och måste återskapas.',
          confirm_btn: 'Fortsätt',
          abort_btn: 'Avbryt'
        }
      });

      dialog.afterClosed().subscribe(reset => {
        if (reset) {
          this.siteChange();
        } else {
          site.selected = !site.selected;
          this.detectChange();
        }
      });
    } else {
      this.siteChange();
    }
  }

  siteChange() {

    // Check if blocket is the only site that is selected, in that case remove the desktop and all choice
    const selectedSites = this.sites.filter(elm => elm.selected);

   /* if (selectedSites.length === 1) {
      const blocketSelected: boolean = selectedSites[0].name.toUpperCase() === 'BLOCKET';

      if (blocketSelected) {
        this.deviceOptions.filter(elm => elm.name.toUpperCase() !== 'MOBIL').forEach(elm => {
          elm['disabled'] = true;
        });
        this.selectedDevice = this.deviceOptions.filter(elm => elm.name.toUpperCase() === 'MOBIL')[0].id_custom_target;
      } else {
        this.deviceOptions.forEach(elm => elm.disabled = false);
      }
    } else {
      this.deviceOptions.forEach(elm => elm.disabled = false);
    }*/

    this.checkIfComplete();
    this.triggerSiteSubscription();
    this.globalfunctionality.resetAdforsize.next(true);
  }

  deviceChange(value) {
    const adforsize = this.globalfunctionality.getModuleValues('adforsize');
    if (adforsize.isComplete) {
      const dialog = this.dialog.open(WarningConfirmationDialogComponent, {
        panelClass: 'modal-size-sm',
        data: {
          text: 'OBS!',
          desc: 'Om du ändrar ditt val försvinner dina skapade annonser och måste återskapas.',
          confirm_btn: 'Fortsätt',
          abort_btn: 'Avbryt'
        }
      });

      dialog.afterClosed().subscribe(reset => {
        if (reset) {
          this.changeDevice(value);
        } else {
          this.selectedDevice = this.previousValue.value;
          this.detectChange();
        }
      });

    } else {
      this.changeDevice(value);
    }

  }


  changeDevice(value) {
    this.previousValue = {value: value};
    /*let target_option = this.deviceOptions.filter(elm => elm.id_custom_target === this.selectedDevice)[0]['target_option'];*/

    // Disable blocket if desktop option is selected
  /*  const blocket = this.sites.filter(elm => elm.name.toUpperCase() === 'BLOCKET')[0];
    if (blocket) {
      blocket.disabled = false;
    }
    if (target_option) {
      if (target_option.toLowerCase() === 'desktops & laptops') {
        blocket.selected = false;
        blocket.disabled = true;
      }
    }*/

    this.checkIfComplete();
    this.triggerDeviceSubscription();
  }


  checkIfComplete() {
    const values = this.globalfunctionality.getModuleValues('device');

    if (values) {
      values.isComplete = true;
      if (this.selectedDevice !== undefined) {
        let target_option = this.deviceOptions.filter(elm => elm.id_custom_target === this.selectedDevice)[0]['target_option'];
        values['selectedDeviceIndex'] = this.selectedDevice;
        values['selectedTargetOption'] = target_option;
        values['sites'] = this.selectedSubObjective.id === 3 ? this.displaySites : this.sites;
        this.minSiteSelected = this.sites.filter(elm => elm.selected).length > 0;

        if (this.selectedSubObjective.id === 2) {
          let target_option = this.operatingSystemOptions.filter(elm => elm.id_custom_target === this.selectedOperatingSystem)[0]['target_option'];
          values['selectedOSIndex'] = this.selectedOperatingSystem;
          values['selectedOSTargetOption'] = target_option;
        }

        // Trigger site change so on refresh that check will occur
        this.globalfunctionality.siteChange.next(true);
      }


      if (this.store.GUIFlow[3]) {
        this.store.GUIFlow[3].forEach(function (elm) {
          if (elm.type === "device") {
            elm["module_values"].isComplete = true;
          }
        });
      }
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
    }
  }

}
