import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {TableMetadataModel} from "../../../models/table-metadata.model";
import {CreateFeedDialogComponent} from "./create-feed-dialog/create-feed-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {Observable} from "rxjs";
declare var toastr: any;
@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})


export class FeedComponent implements OnInit {

  headers =
    {
      name: "Namn",
      type: "Typ",
      url: "Källa"
    };
  columns: any = ['name', 'type', 'url'];
  expandedElement: any;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;


  tableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  filterTimeout;
  filterLoad: boolean = false;
  showPaginator: boolean = true;
  filtered: boolean = false;
  originalData;

  loadComponent: boolean = true;


  constructor(public dialog: MatDialog,
              public store: StoreService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.getCatalogs();
  }

  getCatalogs(){
    this.apiservice.getJSON(this.store.apiURL + "/ACMCatalogueServlet")
      .subscribe(res => {
        if (res) {
          if (res.responseCode === 200) {
            if (res.data) {
              this.originalData = res.data.catalogs;
              this.dataSource = new MatTableDataSource(res.data.catalogs);
            } else {
              this.dataSource = new MatTableDataSource([]);
            }
            this.decideItemsToBeShowed(res.data.catalogs);
            if(!this._cd['destroyed']) {
              this._cd.detectChanges();
            }
          }else{
            this.dataSource = new MatTableDataSource([]);
          }
          this.loadComponent = false;
        }
      });
  }

  applyFilter(filterValue: string) {
    this.showPaginator = false;
    this.filterLoad = true;

    const data = Object.assign([], this.originalData);
    const filtered = data.filter(elm => elm.name.toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1);

    const arr = [];
    for (let i = 0; i < this.tableMetadata.page_size; i++) {
      if (filtered[i]) {
        arr.push(filtered[i]);
      }
    }

    this.dataSource = new MatTableDataSource(arr);
    this.tableMetadata.length = filtered.length;
    this.tableMetadata.page = 0;

    this.showPaginator = true;
    this.filterLoad = false;
  }

  expandRow(element) {
    if (!this.expandedElement) {
      this.expandedElement = element;
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
    }
  }

  addFeed() {
    if(!this.store.blockFeedCreation) {
      const dialogRef = this.dialog.open(CreateFeedDialogComponent, {
        panelClass: ['modal-size-md', 'modal-height-control']
      });
      dialogRef.afterClosed().subscribe((creationRequest: Observable<any>) => {
        if (creationRequest) {
          this.store.blockFeedCreation = true;
          this._cd.detectChanges();
          creationRequest.subscribe(res => {
            if (res.responseCode === 201) {
              this.store.blockFeedCreation = false;
              this.getCatalogs();
            } else {
              toastr.warning('Något gick fel, kontakta support.');
            }
          });
        }
      });
    }
  }

  pageChange(event) {
    this.tableMetadata.page = event.page;
    this.tableMetadata.page_size = event.page_size;
    this.decideItemsToBeShowed(this.originalData);
  }

  decideItemsToBeShowed(data) {
    const multiplier: number = (this.tableMetadata.page + 1);
    const amountToFetch: number = (this.tableMetadata.page_size * multiplier) - 1;
    const startNum: number = this.tableMetadata.page === 0 ? 0 : this.tableMetadata.page_size * this.tableMetadata.page;

    this.tableMetadata.length = data.length;

    const displayElements = [];
    for (let i = startNum; i <= amountToFetch; i++) {
      if (data[i]) {
        displayElements.push(data[i]);
      }
    }
    this.dataSource = new MatTableDataSource(displayElements);
  }

}
