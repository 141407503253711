import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {StoreService} from "../../../../services/store.service";
import {concat} from "rxjs";

@Component({
  selector: 'app-creative-approve-dialog',
  templateUrl: './creative-approve-dialog.component.html',
  styleUrls: ['./creative-approve-dialog.component.css']
})
export class CreativeApproveDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CreativeApproveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiservice: ApiService,
              public store: StoreService) {
  }

  ngOnInit() {
  }


  submit() {


    let data = [];
    const obj =
      {
        id_tasks: this.data.id_tasks,
        task_status: this.data.status,
        rejection_reason: ''
      };

    data.push(obj);

    const moduleReq = this.apiservice.postJSON(this.store.apiURL + '/ModulesValuesServlet', [this.resetMaterialStatus(this.data.event)]);
    const taskReq = this.apiservice.postJSON(this.store.apiURL + "/TaskManagerServlet", data);

    concat(
      moduleReq,
      taskReq
    ).subscribe((res) => {
      this.dialogRef.close(true);
    });
  }

  resetMaterialStatus(event){
    const selected = JSON.parse(event.value).selected;
    selected.forEach(format => {
      if(format.active) {
        format.creative_set.forEach(set => {
          if (set.added) {
            set.added = false;
          }
          if (set.updated) {
            set.updated = false;
          }
          if (set.removed) {
            set.removed = false;
          }
        });
      }
    });

    const value = JSON.parse(event['value']);
    value['selected'] = selected;
    event['value'] = JSON.stringify(value);
    return event;
  }

  cancel() {

    this.dialogRef.close(false);
  }

}
