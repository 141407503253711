import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {GlobalfunctionalityService} from '../../../../services/globalfunctionality.service';
import {StoreService} from '../../../../services/store.service';
import {ApiService} from '../../../../services/api.service';
import {PreviewDialogComponent} from '../../modules/creative_components/ad-for-size/preview-dialog/preview-dialog.component';
import {FlatTreeControl} from "@angular/cdk/tree";
import {DomSanitizer} from "@angular/platform-browser";
import {FormControl, Validators} from "@angular/forms";
import {CampaignService} from "../../campaign.service";


declare var moment: any;
declare var toastr: any;

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.css']
})

export class EditCampaignComponent implements OnInit, OnDestroy {
  loadDialog: boolean = true;

  reviewData: any = {};
  campaignObj: any = {};
  objectiveKey;
  totalValue;
  totalAudience = 0;
  showNoAdsMessage = false;
  showAds = false;
  banners = [];
  selectedContext;
  campaignProducts = [];

  editCampaign = true;
  showBudgetSplit: boolean = false;

  formats: any;

  JSON: JSON;
  Math: Math;
  RONSites;
  productType;

  auxCPCData = {};

  takeOverCrossChosen: boolean = false;

  addecision;
  selectedContentCategories;

  outsideAdmarketData;

  changeBillingInfo: boolean = false;
  savedBillingInfo: boolean = false;

  billingInfoControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);

  treeControl = new FlatTreeControl<any>(
    node => node.level, node => node.expandable);


  loadBillingInfoUpdate: boolean = false;

  targetingBooked: boolean = false;


  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.target_option,
      level: level,
      indeterminate: node.indeterminate,
      selected: node.selected,
    };
  };

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectedDeviceOption: string = 'all';

  deviceOptionNames = {
    desktop: 'Desktop',
    mobile: 'Mobil'
  };

  showDetailView: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalfunctionality: GlobalfunctionalityService,
    public store: StoreService,
    private apiservice: ApiService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public campaignService: CampaignService,
  ) {
    this.JSON = JSON;
    this.Math = Math;

    iconRegistry.addSvgIcon(
      'chevron-right',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-right.svg'));
    iconRegistry.addSvgIcon(
      'chevron-down',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-icons/chevron-down.svg'));

  }

  ngOnDestroy() {
    // this.store.blockEditCampaign = false;
  }

  hasChild = (_: number, node: any) => node.expandable;
  indeterminate = (_: number, node: any) => node.indeterminate;
  selected = (_: number, node: any) => node.selected;

  ngOnInit() {


    // Reset GUIFlow var so that authentication guard will reevaluate pages
    this.store.GUIFlow = {};

    if (!this.data.outside_admarket && !this.data.order_line) {
      if (!this.data.updateCampaign && !this.data.updateCreatives) {
        this.editCampaign = false;
      }

      const creationDate = new Date(this.data.row.create_date.split(" ")[0]);
      this.showDetailView = moment(new Date(moment(creationDate).format('YYYY-MM-DD'))).isAfter('2020-01-01');

      // Get products and their budget
      this.apiservice
        .getJSON(
          this.store.apiURL +
          '/CampaignServlet?getProductList=' +
          this.data.row.id
        )
        .subscribe(res => {
          if (res.responseCode === 200) {
            this.campaignProducts = res.data;
          } else {
            this.globalfunctionality.logoutUser();
          }
        });

      if (!this.campaignService.structure.get('objective').value.id) {
        this.campaignService.structure.get('objective').value.reset();
      }

      const objective = this.campaignService.structure.get('objective').value;
      objective['id'] = this.data.row.objective;
      this.campaignService.structure.get('objective').setValue(objective);

      // BREAK OUT TO GLOBALFUNCTIONALITY FUNCTION USED IN SEVERAL PLACES
      this.globalfunctionality.getCampaignValues(
        this.data.row.order_lines,
        () => {
          this.campaignObj['objective_name'] = this.data.row.objective_name;
          this.objectiveKey = this.data.row.objective_name;

          // Check if its a blocket searchword campaign
          if (this.globalfunctionality.getModuleValues('addecision')) {
            this.addecision = parseInt(this.globalfunctionality.getModuleValues('addecision').selected);
          }

          // Used when device-option component is used
          const adforsize = this.globalfunctionality.getModuleValues('adforsize');
          if (adforsize.selectedDeviceOption) {
            this.selectedDeviceOption = adforsize.selectedDeviceOption;
          }


          this.store.modules.forEach(elm => {

            if (elm.type === "searchword") {
              if (this.addecision) {
                this.reviewData["searchword"] = elm.module_values;

                // Build category tree structure
                this.selectedContentCategories = [];

                let selectedContentCategory = this.reviewData["searchword"].category;
                if (selectedContentCategory) {
                  if (selectedContentCategory.selectedCategories) {
                    selectedContentCategory.selectedCategories.forEach(cat => {
                      cat['selected_subcategories'] = [];
                      this.selectedContentCategories.push(cat);
                    });
                  }
                  this.selectedContentCategories.forEach(cat => {
                    selectedContentCategory.selectedSubCategories.forEach(sub => {
                      if (cat.target_option === sub.categoryName) {
                        sub['selected_productcategories'] = [];
                        cat['selected_subcategories'].push(sub);
                      }
                    });

                    if (cat.children) {
                      if (cat['selected_subcategories'].length !== cat.children.length) {
                        cat['showSub'] = true;
                        cat['children'] = cat['selected_subcategories'];
                      }
                    }
                  });

                  this.selectedContentCategories.forEach(cat => {
                    cat['selected_subcategories'].forEach(sub => {
                      selectedContentCategory.selectedProductCategories.forEach(prod => {
                        if (sub.target_option === prod.subCategoryName) {
                          sub['selected_productcategories'].push(prod);
                        }
                      });

                      if (sub.children) {
                        if (sub['selected_productcategories'].length !== sub.children.length) {
                          sub['children'] = sub['selected_productcategories'];
                          cat['children'] = cat['selected_subcategories'];
                          sub['showProduct'] = true;
                          cat['showProduct'] = true;
                          cat['showSub'] = true;
                        }
                      }
                    });
                  });

                  this.selectedContentCategories.forEach(cat => {
                    if (!cat.showSub) {
                      delete cat['children'];
                    } else {
                      cat.children.forEach(sub => {
                        if (!sub.showProduct) {
                          delete sub['children'];
                        }
                      });
                    }
                  });
                }
                this.dataSource.data = this.selectedContentCategories;
              }
            }

            if (elm.type === 'adtype') {
              this.productType = elm.module_values.selectedType;
              this.store.productType = this.productType;
            }

            if (elm.type === 'adforsize') {
              this.formats = elm.module_values;
              // Sort place rejected formats first
              this.formats.selected.sort((a, b) => {
                  if (a.status && b.status) {
                    if (a.creatives_status.toUpperCase().includes('REJECTED') > b.creatives_status.toUpperCase().includes('REJECTED')) {
                      return -1; // places infront
                    }
                    return 0;
                  }
                }
              );

              this.formats.selected.forEach(elm => {
                let allRejected = true;
                elm.creative_set.forEach(set => {
                  if (set.complete) {
                    if (set.status) {
                      if (
                        set.status.toLowerCase() === 'approved' ||
                        set.status.toLowerCase() === 'pending'
                      ) {
                        allRejected = false;
                      }
                    } else {
                      allRejected = false;
                    }
                  }
                });
                elm['all_rejected'] = allRejected;

                // Sort place rejected creatives first
                elm.creative_set.sort((a, b) => {
                    if (a.status && b.status) {
                      if (a.status.toUpperCase().includes('REJECTED') > b.status.toUpperCase().includes('REJECTED')) {
                        return -1; // places infront
                      }
                      return 0;
                    }
                  }
                );

              });
            }

            if (elm.type === 'geo') {
              this.reviewData['geo'] = elm.module_values.selectedGeo;
              elm.module_values.selectedGeo.forEach(elm1 => {
                this.totalAudience += elm1.web;
              });
            }

            if (elm.type === 'budget') {
              if (elm.module_values) {
                if (!this.reviewData['budget']) {
                  this.reviewData['budget'] = {};
                }

                this.reviewData['budget']['value'] = elm.module_values.budget;
                this.reviewData['budget']['option'] = elm.module_values.option;
                this.reviewData['budget']['start'] = moment(
                  elm.module_values.start
                ).format('YYYY-MM-DD');
                this.reviewData['budget']['end'] = moment(
                  elm.module_values.end
                ).format('YYYY-MM-DD');
                this.reviewData['budget']['freqDecisionOption'] =
                  elm.module_values.freqDecisionOption;


                this.reviewData['budget']['days'] = elm.module_values.days;

                this.totalValue = elm.module_values.budget;

                if (elm.module_values.guaranteedDelivery !== undefined) {
                  this.reviewData["budget"]["guaranteedDelivery"] = elm.module_values.guaranteedDelivery;
                }

                if (elm.module_values.impressions) {
                  this.reviewData['budget']['impressions'] = elm.module_values.impressions;
                }

                if (elm.module_values.freqAmount) {
                  this.reviewData['budget']['freqAmount'] = elm.module_values.freqAmount['index'];
                }

                if (elm.module_values.selectedFreqOption) {
                  this.reviewData['budget']['selectedFreqOption'] = elm.module_values.selectedFreqOption;
                }

                if (typeof elm.module_values.soon_as_possible !== 'undefined') {
                  if (elm.module_values.soon_as_possible) {
                    if (
                      moment(
                        new Date(
                          moment(elm.module_values.start).format('YYYY-MM-DD')
                        )
                      ).isAfter(new Date())
                    ) {
                      this.reviewData['budget']['soon_as_possible'] = true;
                    }
                  } else {
                    this.reviewData['budget']['soon_as_possible'] = false;
                  }
                }

                if (elm.module_values.billing_information) {
                  this.reviewData['budget']['billing_information'] =
                    elm.module_values.billing_information;
                  this.store.noteText = elm.module_values.billing_information;
                  this.billingInfoControl.setValue(elm.module_values.billing_information);
                }
                if (elm.module_values.trusted_takes_bill !== undefined) {
                  this.reviewData['budget']['trusted_takes_bill'] =
                    elm.module_values.trusted_takes_bill;
                }

                if (elm.module_values.showTimescheduling) {

                  if (elm.module_values.selectedDays) {
                    this.reviewData['budget']['selectedWeekDays'] = elm.module_values.selectedDays.filter(elm => elm.selected).map(elm => elm.value);
                  }

                  if (elm.module_values.hoursSets) {
                    this.reviewData['budget'][
                      'hours'
                      ] = elm.module_values.hoursSets
                      .filter(elm => elm.complete)
                      .sort((a, b) => {
                        if (a.startHour.i < b.startHour.i) {
                          return -1; // places infront a is less
                        }
                        if (a.startHour.i > b.startHour.i) {
                          return 1; // places behind a is greater
                        }
                        return 0;
                      });
                  }
                }

                if (this.campaignService.structure.get('objective').value.id === 6) {
                  this.reviewData['budget']['click_price'] =
                    elm.module_values.click_price;
                  this.reviewData['budget']['clicks'] = elm.module_values.clicks;
                } else if (
                  this.campaignService.structure.get('objective').value.id === 2 ||
                  this.campaignService.structure.get('objective').value.id === 7
                ) {

                  if (this.reviewData['budget']['gross_cpm'] = this.campaignService.structure.get('order_lines').value) {
                    this.reviewData['budget']['gross_cpm'] = this.campaignService.structure.get('order_lines').value[0].gross_pricing;
                  }

                  this.reviewData['budget']['cpm'] = Math.round(
                    elm.module_values.budget /
                    (elm.module_values.impressions / 1000)
                  );
                }
              }
            }

            // If the selected method was "add_later" then show ad warning message
            if (elm.type === 'adcreation') {
              if (elm.module_values.selectedMethod === 'add_later') {
                this.showNoAdsMessage = true;
              } else {
                this.showNoAdsMessage = false;
              }
            }

            if (elm.type === 'interestanddemographic') {
              if (elm.module_values !== undefined) {
                this.reviewData['interest'] = {};
                this.reviewData['interest'] = elm.module_values;
              }
            }

            if (elm.type === 'demographic') {
              if (elm.module_values !== undefined) {
                this.reviewData['demographic'] = {};
                this.reviewData['demographic']['selectedGender'] =
                  elm.module_values.selectedGender;
                this.reviewData['demographic']['selectedAge'] =
                  elm.module_values.selectedAge;
              }
            }

            if (elm.type === 'device') {
              if (elm.module_values.sites) {
                this.reviewData['sites'] = {};
                this.reviewData['sites'] = elm.module_values.sites.filter(
                  elm => elm.selected
                );
              }

              this.reviewData['device'] = {};
              switch (elm.module_values.selectedTargetOption) {
                case 'Desktops & Laptops':
                  this.reviewData['device'] = 'Desktop';
                  break;
                case 'Mobiles':
                  this.reviewData['device'] = 'Mobil';
                  break;
                case 'Tablets':
                  this.reviewData['device'] = 'Desktop';
                  break;
                default:
                  this.reviewData['device'] = 'Mobil & Desktop';
                  break;
              }

              if (elm.module_values.selectedOSIndex !== undefined) {
                this.reviewData['os'] = elm.module_values.selectedOSIndex === 0 ? 'Android & iOS' : elm.module_values.selectedOSTargetOption;
              }


            }

            if (elm.type === 'subobjective') {
              const sub = elm.module_values.selectedSubobjective;
              if (sub) {
                this.auxCPCData['device'] = sub.formats;
                if (sub.id === 1) {
                  if (sub.sites) {
                    this.auxCPCData['sites'] = sub.sites?.reduce((aux, cur) => {
                      aux.push({name: cur.text});
                      return aux;
                    }, []);
                  } else { // backwards compatibility
                    this.auxCPCData['sites'] = [{name: sub.site}];
                  }
                }
              }
            }

            if (elm.type === 'context') {
              if (this.campaignService.structure.get('objective').value.id === 1) {


                // Filter out selected categories
                this.targetingBooked = elm.module_values.contexts[0].targeting_booking;
                if (this.targetingBooked) {
                  const selectedCategories = elm.module_values.contexts[0].all_category_targets.filter(elm => elm.selected || elm.indeterminate);
                  selectedCategories.forEach(cat => {
                    if (cat.children) {
                      cat.children = cat.children.filter(sub => sub.selected || sub.indeterminate);
                      cat.children.forEach(sub => {
                        if (sub.children) {
                          sub.children = sub.children.filter(prod => prod.selected || prod.indeterminate);
                        }
                      });
                    }
                  });
                  this.dataSource.data = selectedCategories;
                  this.treeControl.expandAll();
                }

                if (elm.module_values !== undefined) {
                  this.selectedContext = elm.module_values.contexts;
                  this.selectedContext.forEach(context => {
                    context.sub_contexts = context.sub_contexts.filter(
                      elm => elm.selected
                    );
                    context.sub_contexts.forEach(sub => {
                      sub.products.forEach(prod => {
                        prod[
                          'product_name'
                          ] = this.globalfunctionality.getProductNameBySize(
                          JSON.parse(prod['formats'])[0]
                        );
                      });
                    });
                  });

                  if (this.selectedContext[0].context.toLowerCase() === 'cross') {
                    this.takeOverCrossChosen = true;
                  }

                  if (!this.reviewData['budget']) {
                    this.reviewData['budget'] = {};
                  }
                  this.reviewData['budget']['impressions'] = 0;
                  this.selectedContext[0].sub_contexts.forEach(elm => {
                    if (elm.total_impressions_for_subcontext) {
                      this.reviewData['budget']['impressions'] +=
                        elm.total_impressions_for_subcontext;
                    }
                  });
                }
              }
            }

            if (this.campaignService.structure.get('objective').value.id === 7) {
              if (elm.type === 'adforsize') {

                // Get active formats
                let activeFormats = elm.module_values.selected.filter(
                  elm => elm.active
                );

                let auxSites = [];
                activeFormats.forEach(elm => {
                  if (elm.format === '320x320') {
                    auxSites.push('Aftonbladet');
                    auxSites.push('Blocket');
                    auxSites.push('Klart.se');
                    auxSites.push('Omni');
                    auxSites.push('SvD');
                    auxSites.push('Tv.nu');
                  }
                  if (elm.format === '640x320' || elm.format === '300x480') {
                    auxSites.push('Aftonbladet');
                  }
                  if (elm.format === '300x480') {
                    auxSites.push('Aftonbladet');
                    auxSites.push('SvD');
                  }
                  if (elm.format === '980x240' || elm.format === '250x600') {
                    auxSites.push('Aftonbladet');
                    auxSites.push('Blocket');
                    auxSites.push('Klart.se');
                    auxSites.push('SvD');
                    auxSites.push('Tv.nu');
                  }
                });

                this.RONSites = Array.from(new Set(auxSites));
              }
            }
          });


          // START CPC BACKWARDSCOMPABILITY
          if (!this.reviewData.device) {
            this.reviewData.device = this.auxCPCData['device'];
          }
          if (!this.reviewData.sites) {
            this.reviewData.sites = this.auxCPCData['sites'];
          }
          // END CPC BACKWARDSCOMPABILITY

          this.loadDialog = false;

        },
        false,
        false
      );
    } else {

      // reset selectedObjective object (when selecting campaign outside admarket no objective is selected
      this.campaignService.structure.get('objective').setValue({id: undefined, name: undefined});
      this.reviewData['budget'] = {};


      let showOrderLineData: boolean = false;
      if (this.data.outside_admarket) {
        if (this.data.row.order_lines) {
          if (this.data.row.order_lines.length === 1) {
            showOrderLineData = true;
          }
        }

      }

      if (showOrderLineData && this.data.outside_admarket) {
        this.outsideAdmarketData = this.data.row.order_lines[0];
      } else {
        this.outsideAdmarketData = this.data.row;
      }


      const data = this.outsideAdmarketData;

      if (this.data.outside_admarket && !showOrderLineData) {
        this.reviewData['budget']['value'] = data.budget;
        this.reviewData['budget']['impressions'] = data.reporting_data.total.bookedImpressions;
      } else if (this.data.order_line || showOrderLineData) {
        this.reviewData['geo'] = data.orderline_has_geo_localities.concat(data.orderline_has_geo_disctricts).concat(data.orderline_has_geo_counties);
        this.reviewData['budget']['impressions'] = data.orderline_booked_impressions;
        this.reviewData['budget']['cpm'] = data.pricing;
        this.reviewData['budget']['value'] = (data.orderline_booked_impressions / 1000) * data.pricing;
      }

      this.reviewData['budget']['start'] = moment(data.start_date).format('YYYY-MM-DD');
      this.reviewData['budget']['end'] = moment(data.end_date).format('YYYY-MM-DD');
      this.loadDialog = false;
    }

  }


  specificMunicipalitiesChosen(geo) {
    return geo.children.length !== geo.children.filter(elm => elm.checked).length;
  }

  close() {
    this.dialogRef.close();
  }


  changeRoute(link, param) {
    this.globalfunctionality.startAppLoad();
    this.dialogRef.close();
    this.globalfunctionality.getCampaignValues(
      this.data.row.order_lines,
      'edit',
      this.data.updateCreatives,
      this.data.updateCampaign,
      [link, param]
    );
  }

  changeRouteObj(obj) {
    this.globalfunctionality.startAppLoad();
    this.dialogRef.close();
    this.globalfunctionality.getCampaignValues(
      this.data.row.order_lines,
      'edit',
      this.data.updateCreatives,
      this.data.updateCampaign,
      [obj.link, obj.param]
    );
  }

  saveBillingInfo() {
    this.loadBillingInfoUpdate = true;

    if (!this.billingInfoControl.valid) {
      return;
    }

    const obj = {
      id: this.data.row.id,
      poInformation: this.billingInfoControl.value,
    };

    this.apiservice.postJSON(this.store.apiURL + '/CampaignServlet', [obj])
      .subscribe(res => {
        if (res.responseCode === 200) {
          toastr.info('FAKTURAINFORMATION HAR UPPDATERATS');
        } else {
          toastr.warning('NÅGOT GICK FEL MED UPPDATERINGEN');
        }
        this.changeBillingInfo = false;
        this.loadBillingInfoUpdate = false;
        this.savedBillingInfo = true;
      });

  }

  copyCampaign() {
    this.dialogRef.close('copy');
  }

}
