import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delivery-info-dialog',
  templateUrl: './delivery-info-dialog.component.html',
  styleUrls: ['./delivery-info-dialog.component.css']
})
export class DeliveryInfoDialogComponent{

  constructor(
    public dialogRef:MatDialogRef<DeliveryInfoDialogComponent>) { }

  close(){
    this.dialogRef.close();
  }

  approve(){
    this.dialogRef.close(true);
  }
}
