import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {StoreService} from "../../../../../services/store.service";
import {GlobalfunctionalityService} from "../../../../../services/globalfunctionality.service";
import {ApiService} from "../../../../../services/api.service";
import {CreatecampaignComponent} from "../../../createcampaign/createcampaign.component";
import { DateAdapter } from "@angular/material/core";
import {BudgetModuleObjModel} from "../../../models/budget-module-obj.model";
import {ResponseBodyModel} from "../../../models/response-body.model";
import {ObjectiveByCompanyResponseModel} from "../../../models/objective-by-company-response.model";
import {CampaignService} from "../../../campaign.service";
import {MatDatepicker} from "@angular/material/datepicker";

const NUMBER_REGEX = /^[1-9][0-9]*$/;
declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-display-content-budget',
  templateUrl: './display-content-budget.component.html',
  styleUrls: ['./display-content-budget.component.css']
})
export class DisplayContentBudgetComponent implements OnInit, AfterViewInit {

  @ViewChild('startdate') startDateRef: MatDatepicker<Date>;
  @ViewChild('enddate') endDateRef: MatDatepicker<Date>;

  moduleParams;
  minBudget;
  maxBudget;

  clickControl = new FormControl('', [
    Validators.required,
    Validators.pattern(NUMBER_REGEX),
    Validators.min(500),
    Validators.max(10000)

  ]);

  minStartDate = new Date();
  maxDate = new Date('2020-12-31');
  initialStartDate = new Date();
  minEndDate = new Date();
  initialEndDate = new Date();
  startDateValue = new Date();
  endDateValue = new Date();
  budget: any;
  originalBudget: any;
  startDateParsed;
  endDateParsed;
  days;
  clickPrice;
  customFormat = false;


  minClicks: any;
  maxClicks: any;


  module;
  values;

  Math: any;

  showForecast = false;

  timer;

  isNaN: any;

  amountOfClicks;

  minCampaignDuration;
  maxCampaignDuration;

  soonAsPossible = false;

  weekDates = [];
  weekNumberTimeout;
  showMore: boolean = false;

  constructor(private router: Router,
              public store: StoreService,
              private globalfunctionality: GlobalfunctionalityService,
              private apiservice: ApiService,
              private _cd: ChangeDetectorRef,
              private _create: CreatecampaignComponent,
              private _dateAdapter: DateAdapter<Date>,
              public campaignService: CampaignService) {

    if (this.store.selectedLangCode !== undefined) {
      this._dateAdapter.setLocale(this.store.selectedLangCode);
    } else {
      this._dateAdapter.setLocale("en");
    }
    this.Math = Math;
    this.isNaN = isNaN;
  }

  ngAfterViewInit(): void {
    // ViewChild start date datepicker, subscribe to open event
    this.startDateRef.openedStream
      .subscribe((opened) => {
        this.getNextAndPrevDatepickerElements();
        this.getWeekNumbers();
      });

    this.endDateRef.openedStream.subscribe(() => {
      this.getNextAndPrevDatepickerElements();
      this.getWeekNumbers();
    });

  }

  ngOnInit() {
    /*this.globalfunctionality.localeChange
      .subscribe((value: boolean) => {
        if (value) {
          this._dateAdapter.setLocale(this.store.selectedLangCode);
          if (!this._cd['destroyed']) {
            this._cd.detectChanges();
          }
        }
      });*/

    // Check if selectedObjective is defined else check if its in sessionstorage, otherwise navigate user to objective page

    this.minStartDate = new Date();
    this.maxDate = new Date('2020-12-31');
    this.initialStartDate = new Date();
    this.minEndDate = new Date();
    this.initialEndDate = new Date();
    this.startDateValue = new Date();
    this.endDateValue = new Date();


    this.store.modules = JSON.parse(sessionStorage.getItem("modules"));

    this.values = this.globalfunctionality.getModuleValues("budget");

    if (this.values.soon_as_possible) {
      this.soonAsPossible = true;
    } else {
      this.soonAsPossible = false;
    }

    // Get module params to get the maximum and minimum values for the budget;
    this.moduleParams = this.globalfunctionality.getModuleParams("budget");

    if (this.moduleParams !== undefined) {
      const defaultValue = this.moduleParams.defaultValue;
      this.minCampaignDuration = defaultValue.min_campaign_duration;
      this.maxCampaignDuration = defaultValue.max_campaign_duration;

      this.minClicks = defaultValue.min_clicks === undefined ? 100 : Number(defaultValue.min_clicks);
      this.maxClicks = defaultValue.max_clicks === undefined ? 10000 : Number(defaultValue.max_clicks);

      this.minStartDate.setDate(this.minStartDate.getDate() + (defaultValue.minStartDate === undefined ? 3 : defaultValue.minStartDate));
     /* this.maxDate.setDate(this.maxDate.getDate() + (defaultValue.maxEndDate === undefined ? 30 : defaultValue.maxEndDate));*/
      this.initialStartDate.setDate(this.initialStartDate.getDate() + (defaultValue.startDateOffset === undefined ? 7 : defaultValue.startDateOffset));
      this.initialEndDate.setDate(this.initialEndDate.getDate() + (defaultValue.endDateOffset === undefined ? 21 : defaultValue.endDateOffset));


      this.minEndDate = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");
      this.endDateValue = this.values.end === undefined ? this.initialEndDate : moment(this.values.end).format("YYYY-MM-DD");
      this.startDateValue = this.values.start === undefined ? this.initialStartDate : moment(this.values.start).format("YYYY-MM-DD");


      this.startDateParsed = moment(this.startDateValue).format("YYYY-MM-DD");
      this.endDateParsed = moment(this.endDateValue).format("YYYY-MM-DD");
      this.days = moment(this.endDateValue).diff(moment(this.startDateValue), "days") + 1;


      const subObjective = this.globalfunctionality.getModuleValues('subobjective');
      const feedOption: boolean = subObjective.selectedSubobjective.id === 3;

      this.clickPrice = feedOption ? 7 : defaultValue.click_price;

      // Get the default budget if none was previously selected, depending on the option get corresponding default budget
      if (this.values.clicks !== undefined) {
        this.amountOfClicks = this.values.clicks;
      } else {
        this.amountOfClicks = defaultValue.clicks;
      }

    }

    this.budget = this.amountOfClicks * this.clickPrice;

    this.clickControl = new FormControl(this.amountOfClicks, [
      Validators.required,
      Validators.pattern(NUMBER_REGEX),
      Validators.min(this.minClicks),
      Validators.max(this.maxClicks)
    ]);

    this.minStartDate = this.campaignService.calculateMinStartDate(this.minStartDate);

    // workaround get product price
    if (this.campaignService.structure.get('objective').value['products'] === undefined) {
      this.apiservice.getJSON(this.store.apiURL + "/ObjectivesServlet?company=" + this.campaignService.structure.get('company').value.id)
        .subscribe((res: ResponseBodyModel) => {
          if (res.responseCode === 200) {
            res.data.forEach((elm: ObjectiveByCompanyResponseModel) => {
              if (elm.id === this.campaignService.structure.get('objective').value.id) {
                this.campaignService.structure.get('objective').setValue(elm);
                this.setDefaultProduct();
              }
            });
          }
        });

    } else {
      this.setDefaultProduct();
    }
  }


  getNextAndPrevDatepickerElements() {
    this.detectChange();
    const nextElm = document.getElementsByClassName("mat-calendar-next-button mat-icon-button mat-button-base");
    const prevElm = document.getElementsByClassName("mat-calendar-previous-button mat-icon-button mat-button-base");
    nextElm[0].addEventListener("click", this.getWeekNumbers);
    prevElm[0].addEventListener("click", this.getWeekNumbers);
  }

  getWeekNumbers() {

    clearTimeout(this.weekNumberTimeout);

    this.weekNumberTimeout = setTimeout(() => {

      // Retrieve year inview
      this.weekDates = [];
      const yearElm = document.getElementsByClassName("mat-calendar-period-button mat-button mat-button-base");
      const yearText = yearElm[0]['innerText'].split(' ')[1];
      const year = parseInt(yearText);

      // January 4 is always in week 1, start by creating an object containg January 4 as week 1
      const jan4 = new Date(year, 0, 4);
      let week: number = 1;
      this.weekDates.push({
        week: week,
        firstDate: moment(jan4).format('YYYY-MM-DD')
      });
      week += 1;

      // By the January 4th date we know the next week will be the next monday, calculate when the next monday from January 4 will be
      // Retrieve next monday date and place it in the object as week 2
      const dayOfWeek = jan4.getDay(); // 0 = Sunday, 1 = Monday etc
      const diff = (7 - dayOfWeek) + 1;
      const firstNextMonday = new Date(jan4.setDate(jan4.getDate() + diff));
      this.weekDates.push({
        week: week,
        firstDate: moment(firstNextMonday).format('YYYY-MM-DD')
      });
      week += 1;


      // When the first date for week 1 and 2 has been decided we now know that the next first date for each new week will be on the next monday
      // So we iterate through 53 weeks adding 7 days to obtain the date corresponding first date for each new week
      for (let i = week; i <= 53; i++) {
        const nextMonday = new Date(firstNextMonday.setDate(firstNextMonday.getDate() + 7));
        this.weekDates.push({
          week: i,
          firstDate: moment(nextMonday).format('YYYY-MM-DD')
        });
      }

      // weekDates will now contain each first date for each new week of the year


      // Retrieve month in view
      const monthElm = document.getElementsByClassName('mat-calendar-period-button mat-button mat-button-base');
      const monthText = monthElm[0]['innerText'].split(' ')[0];

      // Used for obtaining month number from text displayed in date picker
      const monthNameToNum =
        {
          'JAN.': 0,
          'FEB.': 1,
          'MARS': 2,
          'APR.': 3,
          'MAJ': 4,
          'JUNI': 5,
          'JULI': 6,
          'AUG.': 7,
          'SEP.': 8,
          'OKT.': 9,
          'NOV.': 10,
          'DEC.': 11
        };
      const monthNum = monthNameToNum[monthText];

      // Retrieve day, used for obtaining correct HTML element, retrieves each day for month/year inview
      const filteredDates = this.weekDates.filter(elm => new Date(elm.firstDate).getMonth() === monthNum && new Date(elm.firstDate).getFullYear() === year);
      const days = [];
      filteredDates.forEach(elm => {
        const day = parseInt(elm.firstDate.split('-')[2]);
        elm['day'] = day;
        days.push(day);
      });


      // Obtain all week day HTML elements
      const dayElm = document.getElementsByClassName('mat-calendar-body-cell-content');
      const firstDayElm = [];

      // Retrieve HTML elements which corresponds to a monday
      for (let i = 0; i < dayElm.length; i++) {
        if (days.indexOf(parseInt(dayElm[i]['innerText'])) !== -1) {
          firstDayElm.push(dayElm[i]);
        }
      }


      // If not first day of the week is a new week, calculate week number for first day of the week so we can show all week numbers for all dates visible on the datepicker
      /*if(!filteredDates.filter(elm => elm.day === 1).length){
        firstDayElm.push(dayElm[0]);
          const obj =
            {
              week: filteredDates[0].week - 1,
              day: 1,
              firstDate: moment(new Date(year, monthNum, 1)).format('YYYY-MM-DD')
            };
          filteredDates.push(obj);
      }*/


      // Obtain HTML element firstDayElm will contain each HTML element corresponding a monday (new week)
      for (let i = 0; i < firstDayElm.length; i++) {
        const parent = firstDayElm[i].parentElement;
        const weekDiv = document.createElement('div');
        weekDiv.classList.add('mt-calendar-body-cell-content-weeknumber');
        weekDiv.innerText = filteredDates[i].week;
        parent.prepend(weekDiv);
      }
    }, 0);
  }

  setDefaultProduct() {

    const structure = this.campaignService.structure;
    const subObjectiveId: number = this.globalfunctionality.getModuleValues('subobjective').selectedSubobjective.id;
    const objective = structure.get('objective').value;

    if(subObjectiveId === 3){
      structure.get('products').setValue(objective['feed_products']);
      const selectedDeviceIndex = this.globalfunctionality.getModuleValues('device').selectedDeviceIndex;
      this.apiservice.getJSON(this.store.apiURL + "/ProductsServlet?values=" + JSON.stringify(objective.feed_products)
        + "&brand=" + this.campaignService.structure.get('brand').get('id').value + "&objective=" + this.campaignService.structure.get('objective').value.id)
        .subscribe(res => {
          const data = res.data;
          if(selectedDeviceIndex === 0){
            structure.get('selected_product').setValue(data.find(elm => elm.target_device.toLowerCase() === 'all').id);
          }else if(selectedDeviceIndex === 52){
            structure.get('selected_product').setValue(data.find(elm => elm.target_device.toLowerCase() === 'desktop').id);
          }else if(selectedDeviceIndex === 53){
            structure.get('selected_product').setValue(data.find(elm => elm.target_device.toLowerCase() === 'mobile').id);
          }
        });
    }else{
      structure.get('products').setValue(objective['products']);
      if (subObjectiveId === 2) {
        structure.get('selected_product').setValue(objective['products'][1]);
      } else{
        structure.get('selected_product').setValue(objective['products'][0]);
      }
    }


    this.checkIfComplete();
  }


  placeCustomButton(startdate) {

    if (this.store.user.companies[0].status.toLowerCase() === 'approved') {
      let materialAdded: boolean = Boolean(this.globalfunctionality.getModuleValues('adforsize')['isComplete']);
      setTimeout(() => {
        let result = this.campaignService.setSoonAsPossibleButton(startdate, materialAdded);
        if (result.addBtn) {
          result.btnElm.onclick = () => {
            this.setSoonAsPossible(startdate);
          };
        }
      }, 200);
    }
  }

  setSoonAsPossible(startdate) {
    this.soonAsPossible = true;
    this.startDateValue = this.minStartDate;
    let values = this.globalfunctionality.getModuleValues("budget");
    values['soon_as_possible'] = true;
    startdate.close();
    this.changeStartDate(true);
  }


  // TODO PLACE FUNCTIONS IN GLOBAL FUNCTIONALITY USED IN BUDGET COMPONENT ASWELL
  checkValue(value) {
    this.store.trustedPartnerTakesBill = value;
    let budget = this.globalfunctionality.getModuleValues("budget");
    budget['trusted_takes_bill'] = this.store.trustedPartnerTakesBill;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  setBillingPartner(value) {
    this.store.billingPartner = value;
    let budget = this.globalfunctionality.getModuleValues("budget");
    budget['billing_partner'] = this.store.billingPartner;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

  saveBillingInfo() {
    const budget = this.globalfunctionality.getModuleValues("budget");

    if (this.store.noteText === "") {
      this.store.noteText = undefined;
    }

    budget['billing_information'] = this.store.noteText;
    this.globalfunctionality.setModuleValue('budget', budget);
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
  }

// TODO PLACE FUNCTIONS IN GLOBAL FUNCTIONALITY USED IN BUDGET COMPONENT ASWELL

  detectChange() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  changeAmountOfClicks() {

    this.amountOfClicks = this.clickControl.value;

    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if (!parseInt(this.amountOfClicks)) {
        if (this.amountOfClicks !== "" && this.amountOfClicks !== null) {
          this.amountOfClicks = undefined;
        }
      }
      if (this.amountOfClicks !== "" && this.amountOfClicks !== null && this.amountOfClicks !== undefined) {
        this.budget = this.amountOfClicks * this.clickPrice;
      }
      this.checkIfComplete();
    }, 300);

  }


  checkIfComplete() {
    // Do not show forecast while calculation is ongoing
    let values: BudgetModuleObjModel = this.globalfunctionality.getModuleValues("budget");

    this.clickControl.markAsPristine();
    this.clickControl.markAsTouched();

    if (values) {
      if (!this.clickControl.hasError("min") && !this.clickControl.hasError("max") &&
        !this.clickControl.hasError("pattern") && this.startDateValue !== null && this.endDateValue !== null
        && ((this.days) <= this.maxCampaignDuration && this.days >= this.minCampaignDuration)
      ) {

        if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {
          this.store.GUIFlow[4].forEach((elm) => {
            if (elm.type === "budget") {
              elm["module_values"].isComplete = true;
            }
          });

          values.clicks = Math.floor(this.budget / this.clickPrice);
          values.click_price = this.clickPrice;
          values.budget = this.budget;
          values.start = this.startDateParsed;
          values.end = this.endDateParsed;
          values.days = this.days;
          values.isComplete = true;
        }
      }
      // If fields are invalid
      else {
        if (this.store.GUIFlow[4]) {
          this.store.GUIFlow[4].forEach((elm) => {
            if (elm.type === "budget") {
              elm["module_values"].isComplete = false;
            }
          });
          values.click_price = this.clickPrice;
          values.clicks = Math.floor(this.budget / this.clickPrice);
          values.budget = this.budget;
          values.start = this.startDateParsed;
          values.end = this.endDateParsed;
          values.days = this.days;
          values.isComplete = false;
        }
      }
      sessionStorage.setItem("modules", JSON.stringify(this.store.modules));
      this.detectChange();
      this.globalfunctionality.page4Checker.next(true);
    }
  }


  changeStartDate(soonAsPossible?) {
    let values = this.globalfunctionality.getModuleValues("budget");
    if (!soonAsPossible) {
      // If any start date has been chose, reset soon as possible variable
      this.soonAsPossible = false;
      values['soon_as_possible'] = false;
    }


    if (this.startDateValue !== null && this.endDateValue !== null) {
      if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {
        this.minEndDate = this.startDateValue;
        if (moment(this.startDateValue).format("YYYY-MM-DD") > moment(this.endDateValue).format("YYYY-MM-DD")) {
          this.endDateValue = this.startDateValue;
          /* this.values.end = this.endDateValue;*/
          values.end = moment(this.endDateValue).format("YYYY-MM-DD");
          this.endDateParsed = moment(this.endDateValue).format("YYYY-MM-DD");

        }
        /*this.values.start = this.startDateValue;*/
        values.start = moment(this.startDateValue).format("YYYY-MM-DD hh:mm:ss");
        this.startDateValue = moment(this.startDateValue).format("YYYY-MM-DD");
        this.startDateParsed = this.startDateValue;

        // Forecast
        this.store.forecast.date_from = this.startDateParsed;
        this.store.forecast.date_to = this.endDateParsed;

        this.calculateDays();

        this.saveToLocalStorage();
      }
    }

  }

  changeEndDate() {
    let module = this.globalfunctionality.getModule("budget");
    let values = module["module_values"];

    if (this.startDateValue !== null && this.endDateValue !== null) {
      if (this.startDateValue.toString() !== "Invalid date" && this.endDateValue.toString() !== "Invalid date") {

        values.end = moment(this.endDateValue).format("YYYY-MM-DD hh:mm:ss");
        this.endDateValue = moment(this.endDateValue).format("YYYY-MM-DD");
        this.endDateParsed = this.endDateValue;

        // Forecast
        this.store.forecast.date_from = this.startDateParsed;
        this.store.forecast.date_to = this.endDateParsed;


        this.calculateDays();
        this.saveToLocalStorage();
      }
    }
  }

  calculateDays() {
    let module = this.globalfunctionality.getModule("budget");
    let values = module["module_values"];
    const start = moment(this.startDateValue);
    const end = moment(this.endDateValue);
    this.days = end.diff(start, "days") + 1;

    // Update impression when days has changed
    values["days"] = this.days;
    this.checkIfComplete();
  }

  saveToLocalStorage() {
    this._cd.detectChanges();
    sessionStorage.setItem("modules", JSON.stringify(this.store.modules));

  }

  checkWidth(className) {
    return $("." + className).width() < 27;
  }


}
