import {Component, Input, OnInit} from "@angular/core";
import {GlobalfunctionalityService} from "../../../../../../../../services/globalfunctionality.service";
import {StoreService} from "../../../../../../../../services/store.service";
import {CampaignService} from "../../../../../../campaign.service";
import {CreativemoduleService} from "../../../creativemodule.service";


@Component({
  selector: 'app-cpc-blocket-preview',
  templateUrl: 'cpc-blocket-preview.component.html'
})

export class CpcBlocketPreviewComponent implements OnInit {

  @Input() data: any;
  showMobile: boolean = false;
  showDesktop: boolean = false;
  @Input() dynamic_ad: boolean;


  constructor(
    private globalfunctionality: GlobalfunctionalityService,
    public store: StoreService,
    private _campaignService: CampaignService,
    private _creativeService: CreativemoduleService
  ) {
  }

  ngOnInit(): void {
    const obj = this._creativeService.decidePreviewDevice();
    this.showMobile = obj.showMobile || this.dynamic_ad;
    this.showDesktop = obj.showDesktop || this.dynamic_ad;
  }
}
