import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './services/service.module';
import { HttpClientModule } from '@angular/common/http';
import { ClickOutsideElmDirective } from './services/directives/click-outside-elm.directive';
import { StartpageComponent } from './startpage/startpage/startpage.component';
import { ImageCropperModule as imagecropper } from 'ngx-image-cropper';
import { StartpageModule } from './startpage/startpage.module';
import { NotFoundComponent } from './not-found.component';
import { httpInterceptorProviders } from './services/http-interceptor/http-interceptor.service';
import {MaterialModule} from "./utils/MaterialModule";

const appRoutes: Routes = [
  { path: '', component: StartpageComponent, pathMatch: 'full' },
  { path: 'startpage', component: StartpageComponent, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [
    DashboardComponent,
    AppComponent,
    ClickOutsideElmDirective,
    StartpageComponent,
    NotFoundComponent
  ],
  imports: [
    // Angular modules
    BrowserModule,
    RouterModule.forRoot(appRoutes, {useHash: true}), /*, enableTracing: true*/
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    imagecropper,

    // Custom modules
    StartpageModule,
    DashboardModule,
    MaterialModule,

  ],
  exports: [RouterModule],
  entryComponents: [],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
