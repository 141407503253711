import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-readmore-dialog',
  templateUrl: './readmore-dialog.component.html'
})
export class ReadmoreDialogComponent{

  constructor(
    public dialogRef:MatDialogRef<ReadmoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  close(){
    this.dialogRef.close();
  }

}
