import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import 'rxjs/add/observable/merge';

import {StoreService} from '../../services/store.service';
import {ApiService} from '../../services/api.service';
import {GlobalfunctionalityService} from '../../services/globalfunctionality.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RemovecampaignDialogComponent} from './dialogs/removecampaign-dialog/removecampaign-dialog.component';
import {FreecampaignDialogComponent} from './dialogs/freecampaign-dialog/freecampaign-dialog.component';
import {EditCampaignComponent} from './dialogs/edit-campaign/edit-campaign.component';
import {ResponseBodyModel} from '../../models/response-body.model';
import {TableMetadataModel} from "../../models/table-metadata.model";
import {CampaignStatusNumbersModel} from "./models/campaign-status-numbers.model";
import {CopyCampaignComponent} from "./dialogs/copy-campaign/copy-campaign.component";
import {FormControl} from "@angular/forms";
import {CampaignService} from "./campaign.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {BehaviorSubject, Observable} from "rxjs";


declare var moment: any;
declare var $: any;
declare var toastr: any;
declare var Highcharts: any;
declare var exporting: any;

export interface NewsData {
  activeTs: string;
  createdTs: string;
  data: string;
  id: number;
  status: string;
  type: string;
}

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CampaignComponent implements OnInit, OnDestroy {
  @ViewChild('campaignFilter') campaignFilter: ElementRef;
  @ViewChild('reportFilter') reportFilter: ElementRef;
  @ViewChild('brandInput') brandInput: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('optionsTrigger') optionsTrigger: MatMenuTrigger;

  campaignColumns: any = [
    'name',
    'id',
    'brand_name',
    'combined_status_value',
    'displayStartDate',
    'displayEndDate',
    'budget',
    'options'
  ];

  campaignColumnRules =
    {
      name: 'sort',
      id: 'sort',
      brand_name: 'filter',
      combined_status_value: 'filter',
      displayStartDate: 'sort',
      displayEndDate: 'sort',
      budget: 'sort',
      options: 'hidden'
    };

  reportColumns: any = [
    'name',
    'id',
    'brand_name',
    'combined_status_value',
    'displayStartDate',
    'displayEndDate',
    'impressions',
    'click',
    'ctr'
  ];


  reportColumnRules =
    {
      name: 'sort',
      id: 'sort',
      brand_name: 'filter',
      combined_status_value: 'filter',
      displayStartDate: 'sort',
      displayEndDate: 'sort',
      impressions: 'sort',
      click: 'sort',
      ctr: 'sort'
    };


  expandedElement: any;
  campaignHeaders = {
    name: 'Namn',
    brand_name: 'Annonsör',
    combined_status_value: 'Status',
    delivered: 'Levererat',
    displayStartDate: 'Start',
    displayEndDate: 'Slut',
    budget: 'Budget (SEK)',
    id: 'ID',
    edit: '',
    remove: ''
  };

  reportHeaders = {
    name: 'Namn',
    brand_name: 'Annonsör',
    combined_status_value: 'Status',
    displayStartDate: 'Start',
    displayEndDate: 'Slut',
    id: 'ID',
    impressions: 'Visningar',
    click: 'Klick',
    ctr: 'CTR'
  };


  statuses =
    [
      {
        name: 'Utkast',
        checked: false
      },
      {
        name: 'Behandlas',
        checked: false
      },
      {
        name: 'Saknar mtrl',
        checked: false
      },
      {
        name: 'Godkänd',
        checked: false
      },
      {
        name: 'Live',
        checked: false
      },
      {
        name: 'Slutförd',
        checked: false
      }
    ];

  reportStatuses =
    [
      {
        name: 'Live',
        checked: false
      },
      {
        name: 'Slutförd',
        checked: false
      }
    ];


  campaign = {data: []};
  dataSource: MatTableDataSource<any>;
  reportDataSource: MatTableDataSource<any>;
  @ViewChild('campaignPaginator') paginator: MatPaginator;
  @ViewChild('reportPaginator') reportPaginator: MatPaginator;
  @ViewChild('listSort') listSort: MatSort;
  @ViewChild('reportSort') reportSort: MatSort;
  @ViewChild('filter') filter: ElementRef;


  filteredItems = [];

  loadBrandList = false;

  campaignListLoading: boolean = true;
  reportListLoading: boolean = true;
  timeout;
  noResult: boolean;

  selectedTabIndex = 0;

  openMenu: boolean = false;

  campaigns = [];
  reportCampaigns = [];

  selectedElement;

  seriesOptions = [];
  Math;
  Number;
  fetchingBrands: boolean = true;

  campaignFilterTimeout;
  reportFilterTimeout;


  campaignTableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  reportTableMetadata: TableMetadataModel =
    {
      page: 0,
      page_size: 25,
      filter: ''
    };

  campaignStatusNumbers: CampaignStatusNumbersModel;


  campaignFilterLoad: boolean = false;
  reportFilterLoad: boolean = false;

  noCampaignsFound: boolean = false;

  filters = [];

  latestNews: NewsData;


  loadNewsComponentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loadNews: Observable<boolean> = this.loadNewsComponentSubject.asObservable();

  constructor(
    public store: StoreService,
    private _cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private globalfunctionality: GlobalfunctionalityService,
    private router: Router,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    public campaignService: CampaignService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {

    iconRegistry.addSvgIcon(
      'thrash',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/thrash.svg'));
    iconRegistry.addSvgIcon(
      'garbage',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/garbage.svg'));
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/copy.svg'));
    iconRegistry.addSvgIcon(
      'more',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/more.svg'));
    iconRegistry.addSvgIcon(
      'view_blue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/view_blue.svg'));
    iconRegistry.addSvgIcon(
      'edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/edit.svg'));

    this.dataSource = new MatTableDataSource();
    this.reportDataSource = new MatTableDataSource();

    this.Math = Math;
    this.Number = Number;

    iconRegistry.addSvgIcon(
      'filter-active',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/filter_default.svg'));
    iconRegistry.addSvgIcon(
      'filter-inactive',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/s-icons/filter_activated.svg'));

  }

  ngOnDestroy(): void {
    $('body').attr('id', '');
  }


  ngOnInit() {
    $('body').attr('id', 'campaignList');

    this.activeRoute.params.subscribe(param => {
      if (param.refresh) {
        this.store.showApplication = false;
        window.location.href = window.location.href.replace('/refresh', '');
        window.location.reload();
      }
    });


    this.globalfunctionality.brandInit.subscribe((init: boolean) => {
      if (init) {
        this.detectChange();
      }
    });

    this.globalfunctionality.brandChange.subscribe((value: boolean) => {
      if (value) {
        if (this.selectedTabIndex === 0) {
          if (this.paginator) {
            this.paginator.firstPage();
          }
        } else {
          if (this.reportPaginator) {
            this.reportPaginator.firstPage();
          }
        }

        this.getCampaignStatusAmounts();
        this.getCampaignsByBrand();
        this.detectChange();
        this.globalfunctionality.brandChange.next(false);
      }
    });

    this.globalfunctionality.detectChange.subscribe((value: boolean) => {
      if (value && !this._cd['destroyed']) {
        this._cd.detectChanges();
      }
    });

    this.globalfunctionality.initComplete.subscribe((complete: boolean) => {
      if (complete) {
        this.fetchingBrands = false;
        if (this.openMenu && this.filteredItems.length === 0) {
          this.triggerMenu(this.menuTrigger, this.menuTrigger, true);
        }
        this.detectChange();

      }
    });

    if (!this.store.user) {
      this.globalfunctionality.checkUser(() => {
        this.initPage();
      });
    } else {
      if (!this.store.isTrustedPartner.value && !this.store.isMediaAgency.value) {
        this.store.freeCampaign = this.store.user.companies[0].free_campaign; // reset free campaign var if page is reinitalized
      }
      this.initPage();
    }
  }


  applyFilter(filterValue: string): void {
    clearTimeout(this.campaignFilterTimeout);
    this.campaignListLoading = true;
    this.campaignFilterTimeout = setTimeout(() => {
      this.noCampaignsFound = false;
      this.campaignTableMetadata.filter = filterValue.trim().toLowerCase();
      this.campaignTableMetadata.page = 0;
      this.getCampaignsByBrand();
      this.detectChange();
    }, 1000);

  }

  applyFilterReport(filterValue: string): void {
    clearTimeout(this.reportFilterTimeout);
    this.reportListLoading = true;
    this.reportFilterTimeout = setTimeout(() => {
      this.noCampaignsFound = false;
      this.reportTableMetadata.filter = filterValue.trim().toLowerCase();
      this.reportTableMetadata.page = 0;
      this.getCampaignsByBrand();
      this.detectChange();
    }, 1000);
  }

  campaignListChange(event) {
    this.campaignTableMetadata.page = event.pageIndex;
    this.campaignTableMetadata.page_size = event.page_size;
    this.getCampaignsByBrand();
  }

  // Remove? use same function
  reportListChange(event) {
    this.reportTableMetadata.page = event.pageIndex;
    this.reportTableMetadata.page_size = event.page_size;
    this.getCampaignsByBrand();
  }

  campaignSortChange(event) {
    this.campaignTableMetadata.sort = event.active;
    this.campaignTableMetadata.sort_order = event.direction;
    this.getCampaignsByBrand();
  }

  campaignFilterChange(event) {
    this.campaignListLoading = true;
    let exists: boolean = false;
    this.filters.forEach((elm, index) => {
      if (elm['columnKey'] === event.columnKey) {
        exists = true;
        if (elm['values'].length) {
          elm['values'] = event.values;
        } else {
          this.filters.splice(index, 1);
        }
      }
    });


    if (event.values) {
      if (!exists && event.values.length) {
        this.filters.push(event);
      }
    }
    this.getCampaignsByBrand();
  }

  // Remove? use same function
  reportSortChange(event) {
    this.reportTableMetadata.sort = event.active;
    this.reportTableMetadata.sort_order = event.direction;
    this.getCampaignsByBrand();
  }


  initPage(): void {
    this.store.appLevel = 0;
    this.globalfunctionality.highlightMenuItem('campaigns');

    // clear variable so that when user navigates back to campaign list, default settings will be set
    this.store.selectedBrand = undefined;
    this.store.searchBrand = undefined;


    if (!this.store.selectedBrand) {
      this.store.selectedBrand = this.store.user.companies[0].default_brand;
      this.store.searchBrand = this.store.selectedBrand;
      if (!this.store.isAdmin.value && !this.store.isTrustedPartner.value && !this.store.isMediaAgency.value && !this.store.isSalesRep.value) {
        this.store.searchBrand = this.store.selectedBrand;
      } else {
        this.store.searchBrand = {};
        this.store.searchBrand.brands_name = "Alla annonsörer";
        this.store.searchBrand.brands_id = 0;
      }
    }

    this.store.selectedBrand = this.store.searchBrand;

    this.getNews();
    this.getCampaignStatusAmounts();
    this.getCampaignsByBrand();
  }

  getNews() {
    this.apiservice.getJSON(this.store.apiURL + "/NewsServlet?state=active")
      .subscribe(res => {
        if (res) {
          if (res.responseCode === 200) {
            const data: NewsData[] = res.data;
            if (data.length) {
              this.latestNews = data[data.length - 1];
              this.latestNews.data = JSON.parse(this.latestNews.data);
              this.latestNews['formatted_date'] = moment(this.latestNews.activeTs).format("YYYY-MM-DD");

            }
          }
        }

        this.loadNewsComponentSubject.next(false);
      });
  }

  goToNewsPage() {
    this.router.navigate(['dashboard/news']);
  }

  /**
   * Gets the different campaign status numbers that should be visible in the UI
   */
  getCampaignStatusAmounts(): void {
    let param = '';

    if (this.store.searchBrand !== undefined) {
      if (this.store.searchBrand.brands_name) {
        if (this.store.searchBrand.brands_name.toLowerCase() !== 'alla annonsörer') {
          if (this.store.searchBrand.brands_id !== 0) {
            param = '&brand=' + this.store.searchBrand.brands_id;
          }
        }
      }
    }

    this.apiservice.getJSON(this.store.apiURL + '/CampaignServlet?countCampaigns=true' + param)
      .subscribe((res: ResponseBodyModel) => {
        this.campaignStatusNumbers = res.data as CampaignStatusNumbersModel;
      });
  }

  parseBudget(budget): string {
    return parseInt(budget).toLocaleString('sv');
  }

  getCampaignsByBrand(): void {

    let params = '?getAllCampaigns=true';

    if (this.store.searchBrand !== undefined) {
      if (this.store.searchBrand.brands_name) {
        if (this.store.searchBrand.brands_name.toLowerCase() !== 'alla annonsörer') {
          if (this.store.searchBrand.brands_id !== 0) {
            params = params + '&brand=' + this.store.searchBrand.brands_id;
          }
        }
      }
    }


    let obj;
    if (this.selectedTabIndex === 0) {
      obj = this.campaignTableMetadata;
    } else {
      obj = this.reportTableMetadata;
    }

    params = params + '&filter=' + obj.filter;

    params = params + '&filters=' + JSON.stringify(this.filters);

    if (obj.page !== undefined) {
      params = params + '&pageNumber=' + obj.page;
    }
    if (obj.page_size !== undefined) {
      params = params + '&pageSize=' + obj.page_size;
    }
    if (obj.sort !== undefined && (obj.sort_order !== undefined && obj.sort_order !== '')) {
      params = params + '&sort=' + obj.sort + ':' + obj.sort_order;
    }

    if (this.selectedTabIndex === 1) {
      params = params + '&report=true';
    }

    this.apiservice
      .getJSON(this.store.apiURL + '/CampaignServlet' + params)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.campaignTableMetadata.length = res.pagination.maxResults;
          this.reportTableMetadata.length = res.pagination.maxResults;


          if (obj.filter !== undefined && obj.filter !== '') {
            this.noCampaignsFound = res.data.length === 0;
            this.detectChange();
          }

          this.reportCampaigns = [];

          // Parse the date object from the servlet for displaying month and day on the interface
          res['data'].forEach(elm => {
            if (!elm['asap']) {
              elm['displayStartDate'] = this.setDisplayDate(elm.start_date);
            } else {
              if (
                moment(new Date(moment(elm.start_date).format('YYYY-MM-DD'))).isAfter(new Date())) {
                elm['displayStartDate'] = 'ASAP';
              } else {
                elm['displayStartDate'] = this.setDisplayDate(elm.start_date);
              }
            }

            elm['displayEndDate'] = this.setDisplayDate(elm.end_date);
            elm['displayCreateDate'] = this.setDisplayDate(elm.create_date);


            /* Calculate CTR*/
            if (elm['reporting_data'] !== undefined && elm['reporting_data'] !== null) {
              if (elm['reporting_data']['total']) {
                let total_data = elm['reporting_data']['total'][0];
                total_data['ctr'] = ((total_data['clicks'] / total_data['impressions']) * 100).toFixed(2);
                if (isNaN(total_data['ctr'])) {
                  total_data['showCtr'] = false;
                } else {
                  total_data['showCtr'] = true;
                }

                elm['click'] = Number(total_data['clicks']);
                elm['impressions'] = Number(total_data['impressions']);
                elm['viewability'] = ((Number(total_data['viewedImpressions']) / Number(total_data['viewMeasuredImpressions'])) * 100).toFixed(0);
                elm['ctr'] = isNaN(total_data['ctr']) ? '0.00' + '%' : total_data['ctr'] + '%';

                if (elm.objective !== 6) {
                  elm['delivered'] = isNaN(
                    Math.round(
                      parseInt(elm['impressions']) /
                      total_data['bookedImpressions']
                    )
                  ) ? 0 : Math.min(Math.round((parseInt(elm['impressions']) / total_data['bookedImpressions']) * 100), 100);
                } else {
                  elm['delivered'] = isNaN(Math.round(parseInt(elm['click']) / total_data['bookedImpressions']))
                    ? 0
                    : Math.min(Math.round((parseInt(elm['click']) / total_data['bookedImpressions']) * 100),
                      100
                    );
                }
              }
            }

            if (elm['combined_status']) {
              elm['combined_status_value'] = elm['combined_status']['status'];
              elm['color'] = this.setStatusColor(elm);

            }

            // GET ERROR CAMPAIGNS
            if (elm['combined_status']) {
              if (elm['combined_status']['warning']) {

                if (elm['combined_status']['status'].toLowerCase() === 'godkänd med varning') {
                  elm['combined_status_value'] = 'Godkänd';
                  elm['rejected_warning'] = true;
                }

                if (elm['combined_status']['status'].toLowerCase() === 'material godkänd med varning') {
                  elm['combined_status_value'] = 'Mtrl godkänt';
                  elm['rejected_warning'] = true;
                }

                if (elm['combined_status']['status'].toLowerCase() === 'saknar mtrl') {
                  elm['combined_status']['warning_text'] =
                    'Saknar annonsmaterial för';
                }
                if (elm['combined_status']['status'].toLowerCase() === 'nekad') {
                  elm['combined_status']['warning_text'] = 'Nekad kampanj';
                }
                if (elm['combined_status']['status'].toLowerCase() === 'mtrl nekat') {
                  elm['combined_status']['warning_text'] =
                    'Nekat annonsmaterial för';
                }

                elm['days_til_start'] = moment(elm['start_date']).diff(
                  moment(new Date()),
                  'days'
                );
                elm['days_from_creation'] = moment(new Date()).diff(
                  moment(elm['create_date']),
                  'days'
                );

              }
            }

            switch (elm.campaign_owner_company_type) {
              case 'Partner':
                elm['campaign_owner_company_type_formatted'] = '(partner)';
                break;
              case 'Agency':
                elm['campaign_owner_company_type_formatted'] = '(mediebyrå)';
                break;
            }

          });

          if (this.selectedTabIndex === 0) {
            // Assign the data to the data source for the table to render
            this.dataSource = new MatTableDataSource(res['data']);

            this.dataSource.filterPredicate = (data, filter: string): boolean => {
              return (
                data.brand_name.toLowerCase().includes(filter) ||
                data.id.toString().includes(filter) ||
                data.name.toLowerCase().includes(filter)
              );
            };
            this.campaignListLoading = false;
          }

          if (this.selectedTabIndex === 1) {
            this.reportDataSource = new MatTableDataSource(res['data']); // this.reportCampaigns
            this.reportDataSource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'displayStartDate':
                  return new Date(moment(item.start_date).format('YYYY-MM-DD'));
                case 'displayEndDate':
                  return new Date(moment(item.end_date).format('YYYY-MM-DD'));
                default:
                  return item[property];
              }
            };

            this.reportDataSource.filterPredicate = (
              data,
              filter: string
            ): boolean => {
              return (
                data.brand_name.toLowerCase().includes(filter) ||
                data.id.toString().includes(filter) ||
                data.name.toLowerCase().includes(filter)
              );
            };

            this.reportListLoading = false;
          }

          this.campaignFilterLoad = false;
          this.reportFilterLoad = false;

          if (!this._cd['destroyed']) {
            this._cd.detectChanges();
          }
        } else {
          this.globalfunctionality.logoutUser();
        }
      });
  }


  setDisplayDate(date): string {
    if (date !== '' && date !== undefined) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return '-';
    }
  }

  calculateBudgetFromImpressions(order_lines, price): number {
    return (order_lines / 1000) * price;
  }

  changeTab(e): void {
    this.selectedTabIndex = e.index;
    /**
     * Set pagination on first page when changing tab, prevents other list to show an empty table when no values are in the same page as the previous table
     *
     */
    this.campaignTableMetadata.filter = '';
    this.noCampaignsFound = false;
    this.filters = [];

    this.campaignListLoading = true;
    this.reportListLoading = true;

    if (this.selectedTabIndex === 0) {
      this.applyFilter('');
      this.campaignTableMetadata.page = 0;
    } else if (this.selectedTabIndex === 1) {
      this.applyFilterReport('');
      this.reportTableMetadata.page = 0;
    }

    // Used for clearing filter when tab is changed
    this.store.brandListLoading = true;
    this.globalfunctionality.clearColumnFilterSelection.next(true);

    this.getCampaignsByBrand();
  }

  setStatusColor(elm): string {

    if (elm['combined_status']['active']) {
      if (elm.created_outside_admarket || elm.MULE_order_line_id) {
        return 'accent';
      } else {
        return 'grey';
      }
    } else if (elm['combined_status']['warning']) {
      return 'alert';
    }

    if (elm['combined_status']['status']) {
      const status = elm['combined_status']['status'].toLowerCase();
      if (elm['combined_status']['status']) {
        if (status === 'behandlas') {
          return 'secondary';
        } else if (status === 'godkänd') {
          return 'accent';
        } else if (status === 'slutförd') {
          return 'stroked-accent';
        } else if (status === 'planerad') {
          return 'grey';
        } else if (status === 'utkast') {
          return 'dark-grey';
        } else if (status === 'planerad') {
          return 'dark-grey';
        } else if (status === 'live') {
          return 'accent';
        } else {
          return elm['color'];
        }
      }
    }

  }

  showCampaignOrderlines(campaign, index) {

    if (campaign['created_outside_admarket'] && campaign['order_lines'].length > 1) {
      if (!campaign.opened) {
        this.formatOrderlines(campaign);
        this.dataSource.data.splice.apply(this.dataSource.data, [index + 1, 0].concat(campaign.order_lines));
      } else {
        this.dataSource.data.splice(index + 1, campaign.order_lines.length);
      }
      this.dataSource.connect().next(this.dataSource.data);
      campaign['opened'] = !campaign['opened'];
    }
  }

  formatOrderlines(campaign) {
    campaign.order_lines.forEach(elm => {
      elm['combined_status_value'] = elm.combined_status['status'];
      elm['color'] = this.setStatusColor(elm);
      elm['billing_holder'] = campaign.billing_holder;
      elm['brand_name'] = campaign.brand_name;
      elm['displayEndDate'] = this.setDisplayDate(elm.end_date);
      elm['displayStartDate'] = this.setDisplayDate(elm.start_date);
      elm['budget'] = this.calculateBudgetFromImpressions(elm.orderline_booked_impressions, elm.pricing);
    });
  }


  expandRow(element): void {
    element.reporting_data.days = element.reporting_data.days.filter(
      elm => elm.date !== ''
    );
    this.selectedElement = element;

    if (!this.expandedElement) {
      this.expandedElement = element;
      this.addChart(element);
    } else if (element.id === this.expandedElement.id) {
      this.expandedElement = undefined;
    } else {
      this.expandedElement = element;
      this.addChart(element);
    }
  }


  openCampaignFlow(): void {

    this.campaignService.structure.reset();

    // Clear storeservice variables
    this.campaignService.structure.reset();
    this.store.productType = undefined;
    this.store.page = undefined;


    sessionStorage.removeItem('modules');
    sessionStorage.removeItem('campaign');

    const company = this.store.user.companies[0];
    const brand = company.default_brand;
    this.campaignService.structure.get('company').setValue(company);

    sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));

    this.apiservice.getJSON(`${this.store.apiURL}/CampaignServlet?hasCompanyFreeCampaign=${company.id}`)
      .subscribe(res => {
        if (res.responseCode === 200) {
          this.store.freeCampaign = res.data.hasFreeCampaign;

          if (!this.store.freeCampaign) {
            this.globalfunctionality.createCampaign();
          } else {
            let dialogRef = this.dialog.open(FreecampaignDialogComponent, {
              panelClass: 'modal-size-xs',
              autoFocus: false
            });
            dialogRef.afterClosed().subscribe(decision => {
              if (decision !== undefined) {
                this.store.freeCampaign = decision;
                this.globalfunctionality.detectChange.next(true);
                this.globalfunctionality.createCampaign();
              }
            });
          }
        }
      });
  }

  changeBrand(brand?, comp?): void {
    this.campaignListLoading = true;
    this.store.searchBrand = brand;

    if (this.campaignFilter) {
      this.campaignFilter.nativeElement.value = '';
      this.campaignTableMetadata.filter = '';
    }
    if (this.reportFilter) {
      this.reportFilter.nativeElement.value = '';
      this.reportTableMetadata.filter = '';
    }

    this.globalfunctionality.brandChange.next(true);
  }

  triggerMenu(menuTrigger, brandInput, open?): void {

    if (this.filteredItems.length === 0) {
      this.openMenu = true;
    }

    if (!this.fetchingBrands) {
      if (!open) {
        if (!menuTrigger.menuOpen) {
          menuTrigger.toggleMenu();
        }
      } else {
        if (menuTrigger) {
          menuTrigger.openMenu();
          this.openMenu = false;
        }
      }
      brandInput.focus();
      this.filterItems();
    }
  }

  filterItems(): void {

    this.loadBrandList = true;
    this.filteredItems = $.extend(true, [], this.store.companiesList);
    this.noResult = true;
    this.filteredItems.forEach(elm => {
      if (
        elm.companies_name
          .toLowerCase()
          .indexOf(this.store.searchBrand.brands_name.toLowerCase()) !== -1
      ) {
        this.noResult = false;
        return;
      } else {
        elm.companyNotFound = true;
        elm.brands = elm.brands.filter(item => {
          if (
            item.brands_name !== undefined &&
            this.store.searchBrand.brands_name !== undefined
          ) {
            return (
              item.brands_name
                .toLowerCase()
                .indexOf(this.store.searchBrand.brands_name.toLowerCase()) !== -1
            );
          }
        });
      }
      if (elm.brands.length > 0) {
        this.noResult = false;
      }
    });

    this.loadBrandList = false;
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  detectChange(): void {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }

  removeCampaign(campaign): void {
    const dialogRef = this.dialog.open(RemovecampaignDialogComponent, {
      panelClass: 'modal-size-xs',
      data: {
        campaign: campaign
      }
    });
    dialogRef.afterClosed().subscribe(remove => {
      if (remove) {
        const aux = [campaign.id];
        this.apiservice
          .deleteJSON(
            this.store.apiURL + '/CampaignServlet?values=' + JSON.stringify(aux)
          )
          .subscribe(res => {
            if (res.responseCode === 200) {
              this.getCampaignsByBrand();
              toastr.success('Kampanjen ' + campaign.name + ' är borttagen.');
            }
          });
      }
    });
  }

  /**
   *
   * @param row
   * @param updateCreatives  Is used for deciding if only the creatives should be editable
   */
  editCampaign(row, updateCreatives, updateCampaign, allowEdit): void {
    if (!this.dialog.openDialogs.length) {

      const obj = {
        row: row,
        updateCreatives: updateCreatives,
        updateCampaign: updateCampaign,
        allowEdit: allowEdit
      };

      const dialogRef = this.dialog.open(EditCampaignComponent, {
        panelClass: ['modal-size-xl', 'modal-height-control'],
        data: obj
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'copy') {
          this.copyCampaign(row);
        }
      });
    }
  }

  copyCampaign(row): void {
    this.campaignService.structure.reset();
    this.apiservice.getJSON(this.store.apiURL + '/BrandsServlet?brand=' + row.brand)
      .subscribe((res: ResponseBodyModel) => {
        const brand = res.data[0];
        this.setBrandInStructure(brand);

        sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));
        this.dialog.open(CopyCampaignComponent, {
          data: row,
          panelClass: 'modal-size-xs'
        });
      });
  }

  checkIfCampaignCanBeEdited(campaign) {
    if (campaign.legacy_campaign) {
      if (campaign.objective === 5 || campaign.objective === 1) {
        return false;
      } else {
        if (
          campaign.combined_status_value.toLowerCase() === 'saknar mtrl' ||
          campaign.combined_status_value.toLowerCase() === 'mtrl nekat'
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  setBrandInStructure(brand) {
    this.campaignService.structure.get('brand').get('name').setValue(brand['name']);
    this.campaignService.structure.get('brand').get('id').setValue(brand['id']);
    this.campaignService.structure.get('brand').get('city').setValue(brand['brand_city']);
  }

  /**
   *
   * @param row
   * @param editCampaign(row, updateCreatives, updateCampaign)
   */
  selectCampaign(row): void {

    this.campaignService.structure.reset();

    if (!row.created_outside_admarket && row.MULE_order_line_id === undefined) { // If not a campaign booked outside from admarket
      if (!this.dialog.openDialogs.length) {
        const allowEdit = this.checkIfCampaignCanBeEdited(row);

        const company = this.store.user.companies[0];
        this.campaignService.structure.get('company').setValue(company);

        this.apiservice.getJSON(this.store.apiURL + '/BrandsServlet?brand=' + row.brand)
          .subscribe((res: ResponseBodyModel) => {
            const brand = res.data[0];
            this.setBrandInStructure(brand);

            row['brand_city'] = brand['brand_city'];

            // Check if the campaigns created brand has write access
            if (brand['write']) {
              const campaignName = this.campaignService.structure.get('name') as FormControl;
              campaignName.setValue(row.name);

              this.store.freeCampaign = row.free_campaign === true ? true : false;

              const objective = this.campaignService.structure.get('objective') as FormControl;
              objective.setValue({id: row.objective});

              this.campaignService.structure.get('id').setValue(row.id);

              const ids = row['order_lines'].map(elm => elm.id);
              this.apiservice.getJSON(this.store.apiURL + '/OrderLinesServlet?values=' + JSON.stringify(ids))
                .subscribe(res => {

                  this.campaignService.structure.get('selected_product').setValue(res[0].product);
                  this.campaignService.structure.get('order_lines').setValue(res);

                  this.apiservice.getJSON(this.store.apiURL + '/ObjectivesServlet?company=' + this.store.user.companies[0].id)
                    .subscribe(res => {
                      res.data.forEach(elm => {
                        if (elm.id === row.objective) {
                          this.campaignService.structure.get('objective').setValue(elm);
                          this.apiservice
                            .getJSON(this.store.apiURL + '/ProductsServlet?values=' + JSON.stringify(elm.products) + '&brand=' + this.campaignService.structure.get('brand').get('id').value + '&objective=' + elm.id)
                            .subscribe(res => {

                              this.campaignService.structure.get('products').setValue(res.data);
                              sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));
                            });
                        }
                      });


                      this.decideIfCampaignOrCreativeShouldBeEditable(row.status, row.creatives_status, row, allowEdit);
                    });
                });
            } else {
              sessionStorage.setItem('campaign_structure', JSON.stringify(this.campaignService.structure.value));
              // Get objective
              this.apiservice.getJSON(this.store.apiURL + '/ObjectivesServlet?company=' + this.store.user.companies[0].id)
                .subscribe(res => {
                  res.data.forEach(elm => {
                    if (elm.id === row.objective) {

                      this.campaignService.structure.get('objective').setValue(elm);

                      let obj = {
                        row: row,
                        updateCreatives: false,
                        updateCampaign: false,
                        allowEdit: true
                      };
                      if (!this.dialog.openDialogs.length) {
                        this.dialog.open(EditCampaignComponent, {
                          panelClass: ['modal-size-xl', 'modal-height-control'],
                          data: obj
                        });
                      }
                    }
                  });
                });
            }
          });
      }

    } else {

      let obj = {};
      if (row.MULE_order_line_id !== undefined) {
        obj =
          {
            row: row,
            order_line: true
          };
      } else {
        obj = {
          row: row,
          outside_admarket: true
        };
      }
      this.dialog.open(EditCampaignComponent, {
        panelClass: ['modal-size-xl', 'modal-height-control'],
        data: obj
      });
    }
  }

  decideIfCampaignOrCreativeShouldBeEditable(status, creativesStatus, row, allowEdit) {

    if (status === 'Ended') {
      this.editCampaign(row, false, false, true);
    } else if (status === 'Draft') {
      if (
        creativesStatus !== 'Schibsted Review' &&
        creativesStatus !== 'Queued'
      ) {
        this.editCampaign(row, true, true, allowEdit);
      } else {
        this.editCampaign(row, false, true, allowEdit);
      }
    } else if (status === 'Rejected') {
      if (creativesStatus !== 'Schibsted Review') {
        this.editCampaign(row, true, true, allowEdit);
      } else {
        this.editCampaign(row, false, true, allowEdit);
      }
    } else if (status === 'Approved') {
      if (
        creativesStatus !== 'Schibsted Review' &&
        creativesStatus !== 'Queued'
      ) {
        this.editCampaign(row, true, false, allowEdit);
      } else {
        this.editCampaign(row, false, false, allowEdit);
      }
    } else if (status === 'Schibsted Review') {
      if (
        creativesStatus !== 'Schibsted Review' &&
        creativesStatus !== 'Queued'
      ) {
        this.editCampaign(row, true, false, allowEdit);
      } else {
        this.editCampaign(row, false, false, allowEdit);
      }
    } else if (status === 'Running') {
      if (
        creativesStatus !== 'Schibsted Review' &&
        creativesStatus !== 'Queued'
      ) {
        this.editCampaign(row, true, false, allowEdit);
      } else {
        this.editCampaign(row, false, false, allowEdit);
      }
    } else {
      this.editCampaign(row, false, false, allowEdit);
    }
  }


  createChart(elm): void {
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
      var path = [
        // Arrow stem
        'M',
        x + w * 0.5,
        y,
        'L',
        x + w * 0.5,
        y + h * 0.7,
        // Arrow head
        'M',
        x + w * 0.3,
        y + h * 0.5,
        'L',
        x + w * 0.5,
        y + h * 0.7,
        'L',
        x + w * 0.7,
        y + h * 0.5,
        // Box
        'M',
        x,
        y + h * 0.9,
        'L',
        x,
        y + h,
        'L',
        x + w,
        y + h,
        'L',
        x + w,
        y + h * 0.9
      ];
      return path;
    };

    Highcharts.setOptions({
      lang: {
        months: [
          'Januari',
          'Februari',
          'Mars',
          'April',
          'Maj',
          'Juni',
          'Juli',
          'Augusti',
          'September',
          'Oktober',
          'November',
          'December'
        ],
        weekdays: [
          'Söndag',
          'Måndag',
          'Tisdag',
          'Onsdag',
          'Torsdag',
          'Fredag',
          'Lördag'
        ],
        shortMonths: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Maj',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dec'
        ],
        rangeSelectorZoom: 'Tidsperiod:'
      }
    });

    Highcharts.stockChart('container-' + elm.id, {
      navigator: {
        handles: {
          lineWidth: 1,
          width: 10,
          height: 22
        }
      },

      navigation: {
        menuStyle: {
          background: '#fff',
          borderWidth: '0px'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#333'
        },
        buttonOptions: {
          symbolFill: 'transparent',
          theme: {
            fill: 'transparent'
          }
        },

        menuItemStyle: {
          padding: '10px 20px',
          fontSize: '12px'
        }
      },

      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
          }
        }
      },

      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },

      rangeSelector: {
        allButtonsEnabled: true,
        selected: 0,
        inputBoxBorderColor: 'gray',
        inputBoxWidth: 120,
        inputBoxHeight: 18,
        inputStyle: {
          color: '#999999'
        },
        labelStyle: {
          color: '#999999'
        },
        inputEnabled: false,
        buttonSpacing: 40,
        buttonPosition: {
          align: 'center'
        },

        buttonTheme: {
          width: 60,
          padding: 5,
          fill: 'none',
          stroke: 'none',
          'stroke-width': 0,
          r: 6,
          style: {
            color: '#333',
            fontWeight: 'bold'
          },
          states: {
            hover: {
              style: {
                color: '#999999'
              }
            },
            select: {
              /* fill: '#458fff', */
              style: {
                /* color: '#fff' */
              }
            }
            // disabled: { ... }
          }
        },

        buttons: [
          {
            type: 'week',
            count: 1,
            text: '7 dagar',
            dataGrouping: {
              forced: true,
              units: [['day', [1]]]
            }
          },
          {
            type: 'month',
            text: '30 dagar',
            count: 1,
            dataGrouping: {
              forced: true,
              units: [['day', [1]]]
            }
          },
          {
            type: 'year',
            text: '365 dagar',
            count: 1,
            dataGrouping: {
              forced: true,
              units: [['week', [1]]]
            }
          }
        ]
      },
      yAxis: [
        {
          labels: {
            align: 'left'
          },
          opposite: false,
          title: {
            // text: 'Annonsvisningar',
            text: '<div class="hc-xaxis-label"></div>',
            rotation: 0,
            align: 'high',
            // x: 50,
            y: -20,
            useHTML: true
          },
          // height: '60%',
          plotLines: [
            {
              value: 0,
              width: 2,
              color: 'silver'
            }
          ]
        },
        {
          labels: {
            align: 'left'
          },
          title: {
            // text: 'Klick',
            text: '<div class="hc-yaxis-label"></div>',
            rotation: 0,
            align: 'high',
            x: -20,
            y: -20,
            useHTML: true
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: 'silver'
            }
          ]
        }
      ],

      exporting: {
        buttons: {
          contextButton: {
            enabled: false
          },
          exportButton: {
            symbol: 'download',
            text: 'Exportera',
            menuItems: ['downloadCSV', 'downloadXLS'],
            x: 10
          }
        },
        menuItemDefinitions: {
          downloadCSV: {
            text: 'Ladda ner CSV'
          },
          downloadXLS: {
            text: 'Ladda ner XLS'
          }
        }
      },
      series: this.seriesOptions
    });
  }

  addChart(elm): void {
    let data = elm.reporting_data;
    if (Number(data.total[0].impressions) > 0) {

      this.seriesOptions = [];


      let impsdata = data.days.map(function (elem) {
          return [new Date(elem.date).getTime(), Number(elem.impressions)];
        }),
        clickdata = data.days.map(function (elem) {
          /*return [(new Date(elem.date)).getTime(),Number((Number(elem.clicks)/Number(elem.impressions)).toFixed(2))];*/
          return [new Date(elem.date).getTime(), Number(elem.clicks)];
        });


      this.seriesOptions.push(
        {
          type: 'line',
          yAxis: 1,
          name: 'Klick',
          zIndex: 1,
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>'
          },
          marker: {
            enabled: true,
            symbol: 'circle',
            fillColor: 'rgba(69, 143, 255, 1)',
            radius: 5
          },
          data: clickdata
        }
      );

      this.seriesOptions.push(
        {
          type: 'column',
          yAxis: 0,
          name: 'Annonsvisningar',
          zIndex: 0,
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>'
          },
          data: impsdata
        });
      this.createChart(elm);
    }
  }


  updatePage(pageObj): void {
    pageObj.pageIndex = pageObj.page;
    this.campaignListChange(pageObj);
  }

  updateReportPage(pageObj): void {
    pageObj.pageIndex = pageObj.page;
    this.reportListChange(pageObj);
  }

}
