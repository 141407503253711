import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {CampaignService} from "../../campaign.service";
import {PreviewDialogComponent} from "../../modules/creative_components/ad-for-size/preview-dialog/preview-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {GlobalfunctionalityService} from "../../../../services/globalfunctionality.service";


@Component({
  selector: 'app-creative-overview',
  templateUrl: 'creative-overview.component.html'
})

export class CreativeOverviewComponent implements OnInit{
  @Input() material: any;
  @Input() productType: any;
  @Input() data: any;
  @Input() objectiveId: number;
  @Input() campaign: any;

  @Output() changeRouteHandler = new EventEmitter();

  selectedDeviceOption: string = 'all';
  constructor(
    public campaignService: CampaignService,
    private dialog: MatDialog,
    private globalfunctionality: GlobalfunctionalityService
  ) {}


  ngOnInit(): void {
    const adforsizeValues = this.globalfunctionality.getModuleValues('adforsize');
    if(adforsizeValues.selectedDeviceOption){
      this.selectedDeviceOption = adforsizeValues.selectedDeviceOption;
    }

    this.material.forEach(format => {
      if(format.complete){
        format['visible_sets'] = format.creative_set.filter(set => set.visible).length;
      }
    });
  }

  previewCreative(set, format) {
    set['device'] = format.device;
    set['size'] = format.format;
    this.dialog.open(PreviewDialogComponent, {
      data: set,
      panelClass: 'modal-size-auto'
    });
  }

  changeRoute(link, param) {
      this.changeRouteHandler.emit({link, param});
  }

}



