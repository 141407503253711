import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'newsStatus'
})
export class NewsStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value.toLowerCase()) {
      case 'disabled': return 'Inaktiverad';
      case 'approved': return 'Aktiverad';
      default: return value;
    }
  }
}
